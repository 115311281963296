import gql from 'graphql-tag';

export const getWhitelabelsByChannelId = gql`
  query getWhitelabelsByChannelId($channelId: UUID!) {
    getWhitelabelsByChannelId(channelId: $channelId) {
      _id
      name
    }
  }
`;
