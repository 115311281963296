import gql from 'graphql-tag';

export const getContentsOnChannels = gql`
  query getContentsOnChannels($channelIds: [UUID]!) {
    me {
      contentsOnChannels(channelIds: $channelIds) {
        _id
        _created
        _updated
        name
        name_l10n
        description
        description_l10n
        subtitle
        subtitle_l10n
        slug
        type
        tags
        category
        reportingGroup
        logo
        color
        geo
        isInteractive
        backgroundImage
        icon
        iconSet
        iconWeight
        backgroundColor
        liveDate
        startDate
        endDate
        unpublishDate
        interactionEndDate
        state
        # version
        renderer

        externalUrl {
          _id
          url
          openingMode
        }

        signedExternalUrl

        deepLink {
          _id
          deepLinkUrl
          appStoreUrl
          googlePlayStoreUrl
        }

        features {
          _id
          type
          feature
        }

        channel {
          _id
          settings {
            requirements {
              profilePictureRequired
              fullNameRequired
              phoneNumberRequired
              companyNameRequired
              multiLanguageEnabled
              channelLanguages
              enabledLanguagesForMembers
              contentInteractionRequired {
                _id
                content {
                  _id
                }
                header
                description
              }
            }

            effectiveParentRequirements {
              profilePictureRequired
              fullNameRequired
              phoneNumberRequired
              companyNameRequired
              multiLanguageEnabled
              channelLanguages
              enabledLanguagesForMembers
              contentInteractionRequired {
                _id
                content {
                  _id
                }
                header
                description
              }
            }
            # channelLanguages
            # multiLanguageEnabled
            # enabledLanguagesForMembers
          }
        }

        card {
          _id
        }

        integration {
          _id
          settings
          integration {
            _id
            name
            settings
            platforms
          }
        }

        generator {
          _id
        }

        info {
          # _id
          # rating
          # viewed
          # details
          hidden
          deleted
          pinned
          # starred
          liked
        }

        publishedBy {
          _id
          # _created
          # name
          # online
          # lastSeen

          # role {
          #   _id
          #   name
          #   description
          #   groupRoleName
          # }

          profile {
            _id
            name
            phone
            email
            logo
            image
          }
        }
      }
    }
  }
`;
