import React from 'react';

import { useTranslation } from 'react-i18next';

import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { GuestInviteGuestType } from 'lane-shared/renderers/v5/features/types/GuestInviteGuestType';
import { PublicProfileType, PublicUserType } from 'lane-shared/types/User';

import Input from 'components/form/Input';
import CircleListView from 'components/lane/CircleListView';
import { H5 } from 'components/typography';

import styles from './GuestInviteMemberSearch.scss';

type User = Pick<PublicUserType, '_id' | 'name'> & {
  profile: Pick<PublicProfileType, 'name' | 'image' | 'logo'>;
};

type GuestInviteMemberSearchProps = {
  onUserSelected: (user: User) => void;
  selectedGuests: GuestInviteGuestType[];
  users: User[];
  search: string;
  setSearch: (value: string) => void;
};

export default function GuestInviteMemberSearch({
  onUserSelected,
  selectedGuests,
  users,
  search,
  setSearch,
}: GuestInviteMemberSearchProps) {
  const { t } = useTranslation();
  const selectedIds = selectedGuests?.map(i => i?.user?._id);

  return (
    <div className={styles.GuestInviteMemberSearch}>
      <div className={styles.searchBar}>
        <Input
          showClear
          icon="search"
          iconSet="FontAwesome"
          iconType="fal"
          value={search}
          onChange={value => setSearch(value)}
          placeholder={t('Search…')}
        />
      </div>
      {users.map(user => (
        <CircleListView
          key={user._id}
          className={styles.listView}
          image={imageUrl(user.profile?.image)}
          logo={imageUrl(user.profile?.logo)}
          name={getDisplayName(user)}
          onClick={() => onUserSelected(user)}
          selected={selectedIds?.some(id => id === user?._id)}
          isSelectable
        >
          <H5 bold>{getDisplayName(user)}</H5>
        </CircleListView>
      ))}
    </div>
  );
}
