import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  CONTENT_LOCATION_NAMES,
  CONTENT_TYPES,
} from 'lane-shared/helpers/constants/content';
import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { useContentForCardQuery } from 'lane-shared/hooks';
import {
  ContentCardEnum,
  ContentOptionsType,
} from 'lane-shared/renderers/v5/features/types/ContentInteractionRequirementType';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { PropertyOptions } from 'lane-shared/types/properties/Property';

import ValidationMessage from 'components/general/ValidationMessage';

import { Button } from '..';
import CompactContentCard from '../cards/CompactContentCard';
import ContentCard from '../cards/ContentCard';
import IconButton from '../general/IconButton';
import ModalBackground from '../general/ModalBackground';
import ResizableWindow from '../general/ResizableWindow';
import ContentPin from './ContentPin';
import ContentSelector from './ContentSelector';

import styles from './ContentSelectorButton.scss';

type Options = PropertyOptions<ContentOptionsType>;

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  showSearch?: boolean;
  contentId?: string | undefined | null;
  channelId?: string | undefined | null;
  onContentSelected: (content: DocumentType | null | undefined) => void;
  searchDrafts?: boolean;
  error?: string[];
  contentTypes?: ContentTypeEnum[];
  contentSearchLocations?: (
    | 'Content Center'
    | 'Page Center'
    | 'From a Section'
  )[];
  storageKey?: string;
  isInteractiveContent?: boolean;
  options?: Options;
  disabled?: boolean;
};

type AddContentButtonProps = {
  contentId?: string | undefined | null;
  disabled: boolean;
  showSearch: boolean;
  options?: Options;
  onClick: () => void;
};

function AddContentButton({
  contentId,
  options,
  disabled,
  showSearch,
  onClick,
}: AddContentButtonProps) {
  const { t } = useTranslation();

  if (disabled || !showSearch) {
    return null;
  }

  if (options?.label) {
    return (
      <Button
        interfaceStyle="light"
        variant="outlined"
        size="small"
        color="tertiary"
        startIcon={options?.icon && <Icon name={options.icon} />}
        onClick={onClick}
        testId="addRequiredContent"
      >
        {t(options.label)}
      </Button>
    );
  }

  return (
    <IconButton
      className={contentId ? styles.searchContentPresent : styles.searchEmpty}
      icon={options?.icon || 'search'}
      data-test="page-picker"
      onClick={onClick}
    />
  );
}

type Props = OwnProps;

export default function ContentSelectorButton({
  className,
  style,
  contentId = null,
  channelId = null,
  showSearch = true,
  onContentSelected = () => null,
  contentTypes = Object.values(ContentTypeEnum),
  searchDrafts = false,
  contentSearchLocations = [...CONTENT_LOCATION_NAMES],
  options,
  storageKey = 'ContentSelectorButton',
  disabled = false,
  error,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { content } = useContentForCardQuery({ contentId });

  function renderIconButton(
    handleClick: (content: DocumentType | null | undefined) => void
  ) {
    return (
      !disabled && (
        <IconButton
          className={styles.remove}
          iconSet={ICON_SET_FONTAWESOME}
          type={FONT_AWESOME_REGULAR}
          icon="times-circle"
          data-test="pageRemover"
          onClick={() => handleClick(null)}
        />
      )
    );
  }

  function renderContent() {
    if (!content) {
      return null;
    }

    if (content.type === CONTENT_TYPES.STATIC) {
      return (
        <>
          <ContentPin content={content} className={styles.content} />
          {renderIconButton(onContentSelected)}
        </>
      );
    }

    return (
      <>
        {options?.view === ContentCardEnum.Compact ? (
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentForCardType' is not assignable to typ... Remove this comment to see the full error message
          <CompactContentCard content={content} style={{ marginRight: 70 }} />
        ) : (
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentForCardType' is not assignable to typ... Remove this comment to see the full error message
          <ContentCard content={content} className={styles.content} />
        )}
        {renderIconButton(onContentSelected)}
      </>
    );
  }

  return (
    <div className={cx(styles.ContentSelectorButton, className)} style={style}>
      {contentId && renderContent()}
      {!content ? (
        <AddContentButton
          onClick={() => setIsOpen(true)}
          disabled={disabled}
          showSearch={showSearch}
          options={options}
        />
      ) : null}
      <ValidationMessage withoutIcon errors={error} />

      <ModalBackground
        onClose={() => setIsOpen(false)}
        isOpen={isOpen || (!contentId && !showSearch)}
        className={styles.background}
      >
        <ResizableWindow
          showHeader
          name="ContentSelectorButton"
          className={styles.window}
          onClose={() => setIsOpen(false)}
          defaultPosition={ResizableWindow.fullScreen()}
        >
          <ContentSelector
            storageKey={storageKey}
            channelId={channelId}
            onContentSelected={(content: any) => {
              onContentSelected(content);
              setIsOpen(false);
            }}
            availableTypes={contentTypes}
            searchDrafts={searchDrafts}
            contentSearchLocations={contentSearchLocations}
            isOnlyInteractiveContent={options?.isOnlyInteractive}
          />
        </ResizableWindow>
      </ModalBackground>
    </div>
  );
}
