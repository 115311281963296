import { useState, useEffect, useContext, useMemo } from 'react';

import { ChannelsContext } from '../contexts';
import useChannelsQuery from './useChannelsQuery';

export default function useChannelSelector({
  channelId,
  skip,
}: {
  channelId: string;
  location?: number[];
  skip?: boolean;
  showAllLocations?: boolean;
  search?: string;
}) {
  const { primaryChannel, focusOnChannels } = useContext(ChannelsContext);
  const [selectedChannelId, setSelectedChannelId] = useState<string | null>(
    null
  );

  const channelsQuery = useChannelsQuery({
    skip,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null | un... Remove this comment to see the full error message
    defaultHierarchyUp: channelId,
    includeParents: true,
  });

  useEffect(() => {
    if (!selectedChannelId) {
      // if no channel is selected, try to find the current primary channel,
      if (
        primaryChannel &&
        channelsQuery.channels?.some(
          channel => (channel as any)._id === primaryChannel!._id
        )
      ) {
        setSelectedChannelId(primaryChannel!._id);

        return;
      }

      // otherwise try to find any channel the user is looking at
      const found = focusOnChannels.find(focusedChannel =>
        channelsQuery.channels?.some(
          channel => (channel as any)._id === focusedChannel._id
        )
      );

      if (found) {
        setSelectedChannelId(found._id);
      }
    }
  }, [channelsQuery.channels, selectedChannelId]);

  const channels = useMemo(
    () => channelsQuery.channels.filter(({ _id }) => _id !== channelId),
    [channelsQuery.channels]
  );

  return {
    channels,
    called: channelsQuery.called,
    loading: channelsQuery.loading,
    selectedChannelId,
    setSelectedChannelId,
  };
}
