import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Icon, Button, Flex, Loading } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { UserDataContext, ChannelsContext } from 'lane-shared/contexts';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { UserInfo } from 'lane-web/src/domains/accessControl/types/AccessControl';
import { AdminPage, PageHeader } from 'components/layout';
import { H4 } from 'components/typography';

import { ErrorModal } from '../../components/user-access/ErrorModal';
import { UnsavedChangesPrompt } from '../../components/user-access/UnsavedChangesPrompt';
import { UserAccessMultiselect } from '../../components/user-access/UserAccessMultiselect';
import { computeSelectedACGs } from '../../helpers/computeSelectedACGs';
import {
  AccessControlGroup,
  AccessGroupSelectItem,
} from '../../types/AccessControl';

import styles from './manageUserAccessPage.scss';
import { convertToUserAccessGroupSelectItem } from '../../helpers/convertToUserAccessGroupSelectItem';
import { Dropdown } from 'design-system-web/components/Dropdown/Dropdown';
import { MobileAccessChipSelect } from '../../components/user-access/MobileAccessChipSelect';
import {
  AccessRole,
  AccessUser,
  Company,
  UserAccessDetails,
  useUserAccessDetails,
} from '../../hooks/useUserAccessDetails';
import { hasPermission } from 'lane-shared/helpers';
import {
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_DISABLE,
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE,
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
} from 'lane-shared/helpers/constants/permissions';
import { SPECIAL_GROUP_ROLES } from 'lane-shared/helpers/constants/channel';
import {
  getChannelAccessControlGroups,
  updateUserAccessControlGroups,
} from 'lane-shared/graphql/accessControl';

import { AssignAccessManagerModal } from '../../components/user-access/AssignAccessManagerModal';
import { RevokeAccessManagerModal } from '../../components/user-access/RevokeAccessManagerModal';
import convertToUUID from 'lane-shared/helpers/convertId/convertToUUID';
import { roleMapping } from '../../mappers/AccessControl';

export function ManageUserAccessPage({ channel }: { channel: ChannelType }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();

  const [selectedChannel, setSelectedChannel] = useState<string>(channel?._id);
  const [selectedACGs, setSelectedACGs] = useState<AccessGroupSelectItem[]>([]);
  const [channelACGs, setChannelACGs] = useState<
    Map<string, AccessControlGroup>
  >(new Map());

  const [canEditACGs, setCanEditACGs] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false);
  const [saveChangesLoading, setSaveChangesLoading] = useState(false);

  const { user: currentUserContext } = useContext(UserDataContext);
  const { channels: contextChannels } = useContext(ChannelsContext);

  const {
    data: user,
    loading: userLoading,
    refetch: refetchUserAccessDetails,
  } = useUserAccessDetails(userId);
  const { data: admin, loading: adminLoading } = useUserAccessDetails(
    currentUserContext?._id || ''
  );

  const [accessUsers, setAccessUsers] = useState<Map<string, AccessUser>>(
    new Map()
  );

  const [modalStates, setModalStates] = useState({
    AssignAccessManagerModal: false,
    RevokeAccessManagerModal: false,
  });

  const currentChannelAccessUser = useMemo(() => {
    return accessUsers.get(selectedChannel) || null;
  }, [selectedChannel, accessUsers]);

  // WARNING: only handling users with existing mobile access
  const authorizedChannels = user?.accessUsers?.map(
    (accessUser: AccessUser) => accessUser.channelId
  );

  const channels = contextChannels?.filter(channel =>
    authorizedChannels?.includes(channel._id)
  );

  const fetchAccessControlGroups = async (channelId: string) => {
    const acgMap = new Map<string, AccessControlGroup>([]);

    try {
      const { data } = await getClient().query({
        query: getChannelAccessControlGroups,
        variables: {
          channelId,
        },
        fetchPolicy: 'no-cache',
      });

      data.getChannelAccessControlGroups.forEach((acg: AccessControlGroup) => {
        acgMap.set(acg.id, acg);
      });

      return acgMap;
    } catch (err) {
      console.error(err);
    }

    return acgMap;
  };

  useEffect(() => {
    if (user) {
      const accessUsersMap = new Map<string, AccessUser>(
        user.accessUsers.map((accessUser: AccessUser) => [
          accessUser.channelId,
          accessUser,
        ])
      );

      setAccessUsers(accessUsersMap);
    }
  }, [user]);

  useEffect(() => {
    if (channel?._id) {
      setSelectedChannel(channel._id);
    }
  }, [channel]);

  useEffect(() => {
    if (selectedChannel) {
      fetchAccessControlGroups(selectedChannel).then(acgMap => {
        setChannelACGs(acgMap);
      });
    }

    setCanEditACGs(false);
  }, [selectedChannel]);

  useEffect(() => {
    if (currentChannelAccessUser && channelACGs) {
      setSelectedACGs(getUserACGsAsSelectItem());
    }
  }, [currentChannelAccessUser, channelACGs]);

  const getUserACGIds = (user: UserAccessDetails) => {
    return (
      user?.accessUsers
        ?.find(accessUser => accessUser.channelId === selectedChannel)
        ?.accessControlGroups.map(acg => acg.id) ?? []
    );
  };

  const getUserACGsAsSelectItem = (): AccessGroupSelectItem[] => {
    const userAccessGroups =
      currentChannelAccessUser?.accessControlGroups ?? [];

    return userAccessGroups
      .filter(group => channelACGs.has(group.id))
      .map(group => {
        const acgInfo = channelACGs.get(group.id);

        return {
          label: acgInfo?.name ?? '',
          value: group.id,
          provider: acgInfo?.provider ?? '',
          channelId: acgInfo?.channelId ?? '',
        } as AccessGroupSelectItem;
      });
  };

  const handleSaveACGUpdate = async () => {
    if (!user) return;

    const {
      assignedAccessControlGroups,
      revokedAccessControlGroups,
    } = computeSelectedACGs(channelACGs, selectedACGs, {
      accessControlGroupIds:
        currentChannelAccessUser?.accessControlGroups?.map(acg => acg.id) ?? [],
    });

    if (
      !assignedAccessControlGroups.length &&
      !revokedAccessControlGroups.length
    )
      return;

    setSaveChangesLoading(true);

    try {
      await getClient().mutate({
        mutation: updateUserAccessControlGroups,
        variables: {
          channelId: selectedChannel,
          userId,
          assignedAccessControlGroups,
          revokedAccessControlGroups,
        },
      });

      setAccessUsers(
        prevState =>
          new Map(
            prevState.set(selectedChannel, {
              ...currentChannelAccessUser,
              accessControlGroups: selectedACGs.map(acg => ({
                id: acg.value,
              })),
            } as AccessUser)
          )
      );

      window.Toast.show(
        t(
          'web.admin.accessControl.manageUserAccess.updateAccessControlGroupsSuccess',
          { userName: user?.name }
        )
      );
    } catch (e) {
      setHasError(true);
    } finally {
      setSaveChangesLoading(false);
      setCanEditACGs(false);
    }
  };

  const handleCancelACGUpdate = () => {
    const initialUserACGs = getUserACGsAsSelectItem();

    setSelectedACGs(initialUserACGs);
    setCanEditACGs(false);
  };

  const getUserInfo = (): UserInfo => {
    return {
      ...user,
      roles: user?.accessRole
        ?.filter(
          (accessRole: AccessRole) =>
            accessRole.channelId === convertToUUID(selectedChannel)
        )
        .map(
          (accessRole: AccessRole) =>
            roleMapping[accessRole.accessRole] || accessRole.accessRole
        ),
      email: user?.primaryEmail,
      _id: user?.id,
    };
  };

  const isAccessAdminOrChannelAccessAdmin = () => {
    if (!selectedChannel || !user) return;

    const userAccessRoles = getUserInfo()?.roles;

    if (
      userAccessRoles?.length &&
      (userAccessRoles.includes(SPECIAL_GROUP_ROLES.ACCESS_MANAGER) ||
        userAccessRoles.includes(SPECIAL_GROUP_ROLES.COMPANY_ACCESS_ADMIN))
    ) {
      return true;
    }

    return false;
  };

  const handleModalOpen = (modalName: string) => {
    setModalStates(prevState => ({
      ...prevState,
      [modalName]: true,
    }));
  };

  const handleCloseModal = (modalName: string) => {
    setModalStates(prevState => ({
      ...prevState,
      [modalName]: false,
    }));
  };

  const pageHeaderProps = {
    header: user
      ? t('web.admin.accessControl.manageUserAccess.userMobileAccess', {
          userName: user.name,
        })
      : t('web.admin.accessControl.manageUserAccess.breadcrumb.title'),
    breadcrumbs: [
      {
        label: t('web.admin.accessControl.userAccess.table.mobileAccess'),
        url: routes.channelAdminUserAccess.replace(':id', channel?._id),
      },
      {
        label: t('web.admin.accessControl.manageUserAccess.breadcrumb.title'),
      },
    ],
    actionButtons: [
      {
        label: t(
          'web.admin.accessControl.manageUserAccess.navigation.toUserProfile.text'
        ),
        onClick: () => {
          history.push(
            routes.channelAdminTeamMember
              .replace(':id', channel._id)
              .replace(':userId', userId)
          );
        },
        type: 'text' as const,
        testId: 'viewUserProfileButton',
        startIcon: <Icon name="ExternalLink" />,
      },
      {
        label: t(
          isAccessAdminOrChannelAccessAdmin()
            ? 'web.admin.accessControl.manageUserAccess.removeAsAdminButton'
            : 'web.admin.accessControl.manageUserAccess.assignAsAdminButton'
        ),
        onClick: () =>
          isAccessAdminOrChannelAccessAdmin()
            ? handleModalOpen('RevokeAccessManagerModal')
            : handleModalOpen('AssignAccessManagerModal'),

        type: 'secondary' as const,
        testId: 'assignAccessManagersRole',
      },
    ],
    promotedMetadata: user?.companies.length
      ? `${user.companies
          .map((company: Company) => company.name)
          .join(', ')} | ${user.primaryEmail}`
      : user?.primaryEmail,
  };

  const hasSelectedChannelPermission = (permissions: string[]) =>
    hasPermission(
      currentUserContext?.roles,
      permissions,
      selectedChannel,
      true
    );

  const getMobileAccessChipSelectPermissions = () => ({
    enableMobileAccess: hasSelectedChannelPermission([
      PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE,
      PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
    ]),
    disableMobileAccess: hasSelectedChannelPermission([
      PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_DISABLE,
      PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
    ]),
  });

  const isACGListSame = () => {
    const initialACGList = currentChannelAccessUser?.accessControlGroups?.map(
      acg => acg.id
    );
    const selectedACGList = selectedACGs.map(acg => acg.value);

    return (
      initialACGList?.length === selectedACGList.length &&
      initialACGList.every(acg => selectedACGList.includes(acg))
    );
  };

  const onButtonPress = (type: 'save' | 'discard') => {
    if (type === 'save') {
      handleSaveACGUpdate();
    }
  };

  const handleErrorModalRetry = () => {
    setHasError(false);
    handleSaveACGUpdate();
  };

  return !userLoading && !adminLoading ? (
    <AdminPage className={cx(styles.ManageUserAccessPage)}>
      <PageHeader
        {...pageHeaderProps}
        externalPadding={[0, 0]}
        headerLevel="h3"
        controls={
          <Dropdown
            className={cx(styles.CustomDropdownStyle)}
            label={t(
              'web.admin.accessControl.manageUserAccess.accessLocationSelector.label'
            )}
            fixedLabel
            doTranslation={false}
            items={channels.map(channel => ({
              label: channel?.name || '',
              value: channel?._id || '',
            }))}
            value={selectedChannel || ''}
            onChange={({ value }: any) => setSelectedChannel(value)}
          />
        }
      />
      {selectedChannel && currentChannelAccessUser && (
        <Flex direction="column" gap={5}>
          <Flex direction="column">
            <span className={cx(styles.Label)}>
              {t('web.admin.accessControl.manageUserAccess.mobileAccess.label')}
            </span>
            <MobileAccessChipSelect
              key={`mobile-access-chip-${selectedChannel}`}
              user={currentChannelAccessUser}
              userPermissions={getMobileAccessChipSelectPermissions()}
              disableInteraction
              size="md"
            />
          </Flex>
          <Flex direction="column" gap={5} className={cx(styles.CardContainer)}>
            <Flex
              justify="space-between"
              align="center"
              className={cx(styles.AccessDetailsHeader)}
            >
              <H4>
                {t(
                  'web.admin.accessControl.manageUserAccess.accessDetails.title'
                )}
              </H4>
              {!canEditACGs && (
                <Button
                  variant="secondary"
                  size="large"
                  testId="viewUserProfileButton"
                  onClick={() => setCanEditACGs(true)}
                >
                  {t('web.admin.accessControl.manageUserAccess.acgAction.edit')}
                </Button>
              )}
            </Flex>
            <Flex direction="column">
              <span className={styles.Label}>
                {t('web.admin.accessControl.manageUserAccess.acgViewLabel')}
              </span>
              {!canEditACGs && (
                <span className={styles.ViewOnlyValue}>
                  {selectedACGs.map(acg => acg.label).join(', ')}
                </span>
              )}
              {canEditACGs && (
                <Flex direction="column" gap={5}>
                  <UserAccessMultiselect
                    items={convertToUserAccessGroupSelectItem(
                      Array.from(channelACGs.values()),
                      getUserACGIds(admin),
                      currentUserContext?.roles || [],
                      selectedChannel,
                      selectedACGs as any
                    )}
                    onChange={selected => setSelectedACGs(selected)}
                    values={selectedACGs ?? []}
                    isFullWidth
                  />
                  <Flex gap={4}>
                    <Button
                      variant="primary"
                      size="large"
                      testId="viewUserProfileButton"
                      disabled={isACGListSame()}
                      onClick={handleSaveACGUpdate}
                      loading={saveChangesLoading}
                    >
                      {t(
                        'web.admin.accessControl.manageUserAccess.acgAction.save'
                      )}
                    </Button>
                    <Button
                      variant="secondary"
                      size="large"
                      testId="viewUserProfileButton"
                      onClick={handleCancelACGUpdate}
                    >
                      {t(
                        'web.admin.accessControl.manageUserAccess.acgAction.cancel'
                      )}
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
      <UnsavedChangesPrompt
        when={!isACGListSame()}
        onButtonPress={onButtonPress}
        path={routes.channelAdminUserAccess.replace(':id', channel?._id)}
      />
      <ErrorModal
        title={t(
          'web.admin.accessControl.manageUserAccess.updateAccessControlGroups.error.title'
        )}
        description={t(
          'web.admin.accessControl.manageUserAccess.updateAccessControlGroupsFailure',
          {
            userName: user.name,
          }
        )}
        buttonTitle={t('Retry')}
        iconName="x-circle"
        iconColor="#CD4747"
        isOpen={hasError}
        onClose={() => setHasError(false)}
        onClick={handleErrorModalRetry}
      />
      {modalStates.AssignAccessManagerModal && user && (
        <AssignAccessManagerModal
          channelId={selectedChannel}
          isOpen={modalStates.AssignAccessManagerModal}
          user={getUserInfo()}
          onClose={async (triggerRefresh: boolean = false) => {
            handleCloseModal('AssignAccessManagerModal');

            if (triggerRefresh) {
              refetchUserAccessDetails();
            }
          }}
        />
      )}

      {modalStates.RevokeAccessManagerModal && user && (
        <RevokeAccessManagerModal
          channelId={selectedChannel}
          isOpen={modalStates.RevokeAccessManagerModal}
          user={getUserInfo()}
          onClose={async (triggerRefresh: boolean = false) => {
            handleCloseModal('RevokeAccessManagerModal');

            if (triggerRefresh) {
              refetchUserAccessDetails();
            }
          }}
        />
      )}
    </AdminPage>
  ) : (
    <AdminPage className={cx(styles.ManageUserAccessPage)}>
      <Loading
        testId="manage-user-access-spinner-icon"
        className={styles.loading}
      />
    </AdminPage>
  );
}
