/**
 * Makes a file download link for a given blob, or for contents and type.
 */

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

export default function makeFileDownload({
  blob,
  name,
  contents,
  type,
}: {
  blob?: Blob;
  name: string;
  contents?: any;
  type: string;
}): void {
  const data = blob || new Blob([contents], { type });

  // @ts-expect-error ts-migrate(2774) FIXME: This condition will always return true since the f... Remove this comment to see the full error message
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob?.(data, name);
  } else {
    const elem = window.document.createElement('a');

    elem.href = window.URL.createObjectURL(data);
    elem.download = name;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}
