import _ from 'lodash';

import { JsonObject, JsonSerializable } from '../../JSONType';
import { IPropertiesInterfaceOptions, PropertiesInterface } from '../Property';
import { PropertiesInterfaceDependenciesNotSatisifiedError } from './errors';
import { PropertyDependency } from './propertyDependency';

export class PropertiesInterfaceDependencies implements JsonSerializable {
  static MAX_DEPTH = 5;

  constructor(
    public readonly propertiesInterface: PropertiesInterface,
    public dependencies: Map<string, PropertyDependency>
  ) {}

  static fromJsonData(
    properties: PropertiesInterface,
    dependenciesData: IPropertiesInterfaceOptions['dependencies']
  ): PropertiesInterfaceDependencies {
    return new PropertiesInterfaceDependencies(
      properties,
      new Map(
        dependenciesData.map(propertyDependencyData => [
          propertyDependencyData.propertyRef,
          PropertyDependency.fromJsonData(properties, propertyDependencyData),
        ])
      )
    );
  }

  addDependency(ref: string, propertyDependency: PropertyDependency) {
    this.dependencies.set(ref, propertyDependency);
  }

  removeDependency(ref: string) {
    this.dependencies.delete(ref);

    for (const propertyDependency of this.dependencies.values()) {
      propertyDependency.rules.delete(ref);
    }
  }

  getBestReadableFieldName(ref: string) {
    const property = this.propertiesInterface[ref];

    return property?.friendlyName || property?.name || ref;
  }

  getAncestorFieldsRecursive(
    ref: string,
    ancestors: Array<string> = [],
    depth = 1
  ): Array<string> {
    if (depth > PropertiesInterfaceDependencies.MAX_DEPTH) {
      throw new Error(
        `Creating data dependencies that exceeds ${PropertiesInterfaceDependencies.MAX_DEPTH} levels is forbidden.`
      );
    }

    const propertyDependency = this.dependencies.get(ref);

    if (!propertyDependency || propertyDependency.rules.size <= 0) {
      return [...ancestors, ref];
    }

    if (ancestors.includes(ref)) {
      throw new Error(
        `Circular dependency detected: ${ancestors
          .map(ancestorRef => this.getBestReadableFieldName(ancestorRef))
          .join(' -> ')} !! -> ${this.getBestReadableFieldName(ref)}`
      );
    }

    return Array.from(propertyDependency.rules.values()).flatMap(rule =>
      this.getAncestorFieldsRecursive(
        rule.targetPropertyRef,
        [...ancestors, ref],
        depth + 1
      )
    );
  }

  // For each property dependency, traverses the dependency tree recursively (following the rules) until reaching
  // the root node. Throws if circular dependency is detected.
  validate() {
    for (const propertyDependency of this.dependencies.values()) {
      this.getAncestorFieldsRecursive(propertyDependency.propertyRef);
    }
  }

  isSatisfied(inputData: JsonObject): boolean {
    for (const dependency of this.dependencies.values()) {
      if (
        dependency.isSatisfied(inputData) &&
        _.isEmpty(inputData[dependency.propertyRef])
      ) {
        throw new Error(
          `Input missing for '${this.getBestReadableFieldName(
            dependency.propertyRef
          )}'`
        );
      }

      if (
        !dependency.isSatisfied(inputData) &&
        !_.isEmpty(inputData[dependency.propertyRef])
      ) {
        throw new PropertiesInterfaceDependenciesNotSatisifiedError(
          `Input value for '${this.getBestReadableFieldName(
            dependency.propertyRef
          )}' must be cleared because it does not meet its data dependencies ${this.getAncestorFieldsRecursive(
            dependency.propertyRef
          )
            .map(fieldName => this.getBestReadableFieldName(fieldName))
            .map(name => `'${name}'`)
            .join(' -> ')}.`
        );
      }
    }

    return true;
  }

  serialize(): IPropertiesInterfaceOptions['dependencies'] {
    return Array.from(this.dependencies.values())
      .filter(propertyDependency => propertyDependency.rules.size > 0)
      .map(propertyDependency => propertyDependency.serialize());
  }
}
