import { CustomFilterType, NativeFilterTypes } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { getChipStatus } from '../components/user-access/MobileAccessChipSelect';
import {
  AccessControlChipSelectEnum,
  FiltersListType,
  ChannelListType,
} from 'lane-shared/types/AccessControlType';

function useUserAccessFilters<TDataShape>(
  acgFilterLabels: FiltersListType[],
  channelFilterLabels: FiltersListType[]
): CustomFilterType<TDataShape>[] {
  const { t } = useTranslation();

  return [
    {
      key: 'mobileAccess',
      type: NativeFilterTypes.Multiselect,
      label: t(
        'web.admin.accessControl.userAccess.search.mobileAccess.placeholder'
      ),
      options: [
        {
          label: t(
            'web.admin.accessControl.userAccess.table.chipSelect.status.enabled'
          ),
          value: AccessControlChipSelectEnum.ENABLED,
        },
        {
          label: t(
            'web.admin.accessControl.userAccess.table.chipSelect.status.disabled'
          ),
          value: AccessControlChipSelectEnum.DISABLED,
        },
        {
          label: t(
            'web.admin.accessControl.userAccess.table.chipSelect.status.processing'
          ),
          value: AccessControlChipSelectEnum.PROCESSING,
        },
        {
          label: t(
            'web.admin.accessControl.userAccess.table.chipSelect.status.error'
          ),
          value: AccessControlChipSelectEnum.ERROR,
        },
      ],
      customFilterFn: (
        row: any,
        columnId: string,
        filterValue: any[]
      ): boolean => {
        if (!filterValue || !filterValue.length) {
          return true;
        }

        const mobileAccessStatus = getChipStatus(row?.original);

        if (!filterValue.includes(mobileAccessStatus)) {
          return false;
        }

        return true;
      },
    },
    {
      key: 'accessControlGroupIds',
      label: t(
        'web.admin.accessControl.userAccess.search.title.accessControlGroups'
      ),
      type: NativeFilterTypes.Multiselect,
      options: acgFilterLabels,
      customFilterFn: (row: any, columnId: string, filterValue: any[]) => {
        if (!filterValue || !filterValue.length) {
          return true;
        }

        const cellValue = row.getValue(columnId);

        if (!cellValue.some((value: string) => filterValue.includes(value))) {
          return false;
        }

        return true;
      },
    },
    {
      key: 'companies',
      label: t('web.admin.accessControl.userAccess.search.title.company'),
      type: NativeFilterTypes.Multiselect,
      options: channelFilterLabels,
      customFilterFn: (row: any, columnId: string, filterValue: any[]) => {
        if (!filterValue || !filterValue.length) {
          return true;
        }

        const cellValue = row.getValue(columnId);

        if (
          !cellValue?.some((company: ChannelListType) =>
            filterValue.includes(company.id)
          )
        ) {
          return false;
        }

        return true;
      },
    },
  ];
}

export default useUserAccessFilters;
