import React, { useMemo, useRef } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import colors from 'lane-shared/config/colors';
import { useVisitorPassServiceData } from 'lane-shared/domains/visitorManagement/hooks/useVisitorPassServiceData';
import {
  getCountryByGeoLocation,
  getPaymentTotalWithQuote,
} from 'lane-shared/helpers';
import { MEDIUM_DATE_TIME } from 'lane-shared/helpers/constants/dates';
import { INTERACTION_CREATED } from 'lane-shared/helpers/constants/interactions';
import {
  getInteractionIntegrationProvider,
  getFriendlyInteractionDate,
} from 'lane-shared/helpers/content';
import explodeFeatures from 'lane-shared/helpers/features/explodeFeatures';
import { longDate } from 'lane-shared/helpers/formatters';
import dateFormatter from 'lane-shared/helpers/formatters/dateFormatter';
import { getFormattedInteraction } from 'lane-shared/helpers/interactions';
import { useInteractionTimeZone } from 'lane-shared/hooks';
import { useInteractionAnalytics } from 'lane-shared/hooks/analytics';
import Types from 'lane-shared/properties/Types';
import { UserContentInteractionFeaturesType } from 'lane-shared/types/UserContentInteraction';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';
import { ContentType } from 'lane-shared/types/content/Content';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';
import { FeatureQuoteType } from 'lane-shared/types/payment/FeatureQuoteType';

import { Thumbnail } from 'lane-web/src/components/general';
import Pill from 'lane-web/src/components/general/Pill';
import GuestOrUserThumbnail from 'lane-web/src/components/lane/GuestOrUserThumbnail';
import { H5, H6, S } from 'lane-web/src/components/typography';

import {
  VisitorManagementStatusChip,
  VisitorManagementVisitorListItems,
} from '../../domains/visitorManagement';
import RequestCard from '../integrations/AngusServiceRequests/components/RequestCard';
import ParkWhizReceipt from '../integrations/ParkWhiz/ParkWhizReceipt';
import MeetingCard from '../integrations/ProxyClick/components/MeetingCard';
import ServiceRequestCard from '../integrations/ServiceRequest/components/ServiceRequestCard';
import ThreeTenServRequestCard from '../integrations/ThreeTenServ/components/ThreeTenServRequestCard';
import UserThumbnail from './UserThumbnail';
import { getInteractionColor } from 'helpers/getInteractionColor';
import useUserLocale from 'hooks/useUserLocale';

import styles from './UserContentInteractionCard.scss';

type UserContentInteractionCardProps = {
  className?: string;
  style?: React.CSSProperties;
  forAdmin?: boolean;
  interaction: {
    _id: LaneType.UUID;
    _created: string;
    startDate: string | Date | null;
    endDate: string | Date | null;
    status: string;
    user: {
      _id: LaneType.UUID;
      name: string;
    };
    geo: GeoCoordinateType;
    features: UserContentInteractionFeaturesType;
    contentData: ContentType;
  };
  backgroundColorOfUCI?: string;
  hideLocation?: boolean;
};

const {
  dragonfruitLight,
  melonLight,
  cantaloupeLight,
  interactiveBlueLight,
  honeyLight,
  laneWhite,
} = colors;

const colorsPalette = [
  dragonfruitLight,
  melonLight,
  cantaloupeLight,
  interactiveBlueLight,
  honeyLight,
  laneWhite,
];

const MAX_GUEST_TO_SHOW = 1;

export default function UserContentInteractionCard({
  className,
  style,
  interaction: initialInteraction,
  backgroundColorOfUCI,
  hideLocation,
}: UserContentInteractionCardProps) {
  const interaction = useMemo(
    () => getFormattedInteraction(initialInteraction),
    [initialInteraction]
  );

  const types = Types.getTypes();
  const ifSelectUserType =
    interaction?.contentData?.data &&
    (Object as any).values(interaction?.contentData?.data)[0]?.type ===
      types.SelectUser!.name;

  useInteractionAnalytics('view', interaction);
  const { t } = useTranslation();
  const locale = useUserLocale();
  const timeZone = useInteractionTimeZone(interaction);
  const interactionData =
    interaction?.data && Object.values(interaction?.data)?.[0];
  const guests = ifSelectUserType
    ? interactionData?.users
    : interaction?.features?.GuestInvite?.guests;

  const randomColor = useRef(
    colorsPalette[Math.floor(Math.random() * colorsPalette.length)]
  );

  const location = useMemo(() => {
    if (interaction?.contentData?.geo && interaction?.contentData?.geo.length) {
      const latitude = interaction?.contentData?.geo[1];
      const longitude = interaction?.contentData?.geo[0];

      return getCountryByGeoLocation({ latitude, longitude })?.name;
    }

    return '';
  }, [interaction]);

  const provider = getInteractionIntegrationProvider(interaction);

  const integrationData = interaction?.data?.[`_${provider}`];
  const {
    paymentFeature,
    quantityFeature,
    statusesFeature,
    guestInviteFeature,
    visitorManagementFeature,
  } = explodeFeatures(interaction?.contentData?.features);

  const { data: visitorManagementServiceData } = useVisitorPassServiceData({
    interactionId: interaction._id,
    skip: !visitorManagementFeature,
  });

  switch (provider) {
    case IntegrationProviderEnum.AngusServiceRequests:
      return (
        <RequestCard
          className={className}
          style={style}
          interaction={interaction}
        />
      );
    case IntegrationProviderEnum.ParkWhiz:
      return (
        <ParkWhizReceipt
          className={className}
          style={style}
          parking={integrationData}
        />
      );
    case IntegrationProviderEnum.ProxyClick:
      return (
        <MeetingCard
          className={className}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: string | undefined; style: CSSP... Remove this comment to see the full error message
          style={style}
          meeting={integrationData}
          status={interaction?.status}
        />
      );
    case IntegrationProviderEnum.ThreeTenServ:
      return (
        <ThreeTenServRequestCard
          className={className}
          style={style}
          interaction={interaction}
        />
      );
    case IntegrationProviderEnum.ServiceRequest:
      return (
        <ServiceRequestCard
          className={className}
          style={style}
          interaction={interaction}
        />
      );
    default:
      break;
  }

  function formattedAmount() {
    if (!interaction?.features?.Payment?.quote) {
      return null;
    }

    return getPaymentTotalWithQuote(
      interaction?.features?.Payment?.quote as FeatureQuoteType,
      locale
    );
  }

  const hasDates = !!interaction?.startDate;

  const hasSubtitle = Boolean(
    interaction?.contentData?.subtitle?.trim().length
  );

  const formattedDate = dateFormatter(interaction?._created, MEDIUM_DATE_TIME);

  const subtitle = hasSubtitle && interaction?.contentData?.subtitle;
  const isWorkOrderInteraction =
    interaction?.contentData?.type === ContentTypeEnum.WorkOrder;

  return (
    <div
      className={cx(styles.UserContentInteractionCard, className)}
      style={style}
      data-test="interactionCard"
    >
      <div
        className={styles.info}
        style={{ backgroundColor: backgroundColorOfUCI || randomColor.current }}
      >
        <H6>
          {isWorkOrderInteraction
            ? formattedDate
            : (!hideLocation && location) || subtitle || formattedDate}
        </H6>
        <H5>
          {isWorkOrderInteraction
            ? t('mobile.workOrders.serviceRequests.newRequest.serviceTitle')
            : interaction?.contentData?.name}
        </H5>
        {!isWorkOrderInteraction && hasDates && (
          <S className={styles.interactionDate}>
            {getFriendlyInteractionDate(interaction, timeZone, t, locale)}
          </S>
        )}

        {!isWorkOrderInteraction && !hasDates && (
          <S>
            <label>{`${t('Created')} `}</label>
            <span>{longDate(interaction?._created, timeZone, locale)}</span>
          </S>
        )}
        {visitorManagementFeature && (
          <VisitorManagementVisitorListItems.ThumbnailView
            visitors={initialInteraction?.features?.VisitorManagement?.visitors}
            properties={visitorManagementFeature}
          />
        )}
      </div>
      {(ifSelectUserType || guestInviteFeature) && guests?.length ? (
        <div
          className={styles.guests}
          style={
            (guests?.length && { borderBottom: '0px' },
            { backgroundColor: backgroundColorOfUCI || randomColor.current })
          }
        >
          {(guests?.length > MAX_GUEST_TO_SHOW
            ? guests?.slice(0, MAX_GUEST_TO_SHOW)
            : guests
          ).map((guest: LaneType.GuestInviteGuestType, key: string) => (
            <GuestOrUserThumbnail key={key} guest={guest} />
          ))}
          {guests?.length > MAX_GUEST_TO_SHOW && (
            // number of guests more than 5 -> if 8 guests, we show "+ 3"
            <Thumbnail
              text
              className={styles.guest}
              name={`+${guests?.length - MAX_GUEST_TO_SHOW}`}
            />
          )}
        </div>
      ) : null}
      <div className={styles.userContainer}>
        <div className={styles.users}>
          <UserThumbnail
            className={styles.user}
            user={interaction?.user}
            labelPosition="right"
          />
        </div>
        {(statusesFeature || interaction?.status !== INTERACTION_CREATED) && (
          <Pill
            className={styles.pill}
            color={getInteractionColor(interaction?.status)}
            text={t(interaction?.status)}
          />
        )}
        {visitorManagementFeature && (
          <VisitorManagementStatusChip
            visitorManagementServiceData={visitorManagementServiceData}
          />
        )}
      </div>

      <div className={styles.features}>
        {paymentFeature && <Pill color="gray" text={formattedAmount() || ''} />}
        <div className={styles.spacer} />
        {quantityFeature && (
          <Pill
            color="gray"
            text={`${interaction?.features?.Quantity?.quantity}x`}
          />
        )}
      </div>
    </div>
  );
}
