/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { format } from 'date-fns';

import { LaneType } from 'common-types';
import {
  AttachmentPreview,
  AttachmentResponse,
  AttachmentImageContentTypeEnum,
  AttachmentDocumentContentTypeEnum,
} from 'lane-shared/types/attachment';

import { S } from 'components/typography';

import crossIcon from 'static/img/xmark-large.svg';

import styles from './AttachmentThumbnail.scss';

type Props = {
  attachment: AttachmentPreview | AttachmentResponse;
  deleteAttachmentHandler: (attachmentId: LaneType.UUID) => void;
  preventDelete?: boolean;
  attachmentThumbnailStyle?: string;
  disableDeleteWarning?: boolean;
  onPreviewModal?: any;
};

export function AttachmentThumbnail({
  attachment,
  deleteAttachmentHandler,
  preventDelete = false,
  disableDeleteWarning = false,
  attachmentThumbnailStyle,
  onPreviewModal,
}: Props) {
  const attachmentObj = attachment as any;

  const attachmentDate =
    attachmentObj.created &&
    format(Date.parse(attachmentObj.created), "PP 'at' p");

  const generateFileType = (type: string) => {
    if (
      Object.values(AttachmentImageContentTypeEnum).toString().includes(type)
    ) {
      return 'Image';
    }

    if (type === AttachmentDocumentContentTypeEnum.pdf) {
      return 'PDF';
    }

    if (type === AttachmentDocumentContentTypeEnum.csv) {
      return 'CSV';
    }

    return null;
  };

  async function onDeleteAttachment() {
    try {
      if (!disableDeleteWarning) {
        await window.Alert.confirm({
          title: `Delete this attachment?`,
          message: `Are you sure you want to delete this? Once deleted you cannot get it back.`,
          confirmText: 'Yes',
        });
      }
    } catch (err) {
      return;
    }

    deleteAttachmentHandler(attachment.id);
  }

  return (
    <div
      data-test="attachmentThumbnail"
      className={cx(styles.AttachmentThumbnail, attachmentThumbnailStyle)}
    >
      <div
        className={styles.imageWrapper}
        style={{
          backgroundImage: attachmentObj.thumbnailSignedUrl
            ? `url(${attachmentObj.thumbnailSignedUrl})`
            : `url(${attachmentObj.fileUrl})`,
        }}
        onClick={() => onPreviewModal(attachment.id)}
        data-test="attachmentThumbnailImage"
      >
        {Object.values(AttachmentDocumentContentTypeEnum).includes(
          attachmentObj.file.type
        ) ? (
          <Icon name="file" style={{ fontSize: '2em', color: '#52565B' }} />
        ) : null}
      </div>
      <div className={styles.thumbnailData}>
        <S className={styles.thumbnailName} id="attachmentName">
          {attachmentObj.file.name}
          {/* {(attachment.file as any).name} */}
        </S>
        <S className={styles.thumbnailType} id="attachmentType">
          {generateFileType(attachmentObj.file.type)}
        </S>
        {attachmentObj.created && (
          <S className={styles.thumbnailType}>{attachmentDate}</S>
        )}
      </div>
      {!preventDelete && (
        <div
          data-test="deleteAttachment"
          role="button"
          className={styles.deleteIcon}
          onClick={onDeleteAttachment}
          tabIndex={0}
        >
          <img src={crossIcon} className={styles.icon} alt="crossIcon" />
        </div>
      )}
    </div>
  );
}
