import React, { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ChannelSearchByGeoLocation } from 'components';

import { getDisplayProfileName } from 'lane-shared/helpers/getDisplayName';
import LocationType from 'lane-shared/properties/baseTypes/Location';
import {
  ChannelRelationshipTypeEnum,
  ChannelTypeEnum,
} from 'lane-shared/types/ChannelType';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import { pause } from 'lane-shared/helpers';
import { getClient } from 'lane-shared/apollo';
import { addChannelRelationship } from 'lane-shared/graphql/mutation';
import { TENANT_TYPES } from 'lane-shared/helpers/constants/channel';
import { errorCodes } from 'activate-errors';

import styles from './ChannelRelationshipsSearch.scss';

const DEFAULT_CHANNEL_TYPE = 'Retail';

type SearchType = {
  name: string;
  type: ChannelTypeEnum | `${typeof DEFAULT_CHANNEL_TYPE}`;
  page: number;
  perPage: number;
};

type OwnProps = {
  setIsOpen: (isOpen: boolean) => void;
  setLoading: (loading: boolean) => void;
  setErrorMessage: (message: string) => void;
};

type Props = OwnProps;

const TRANSLATION_KEYS = {
  addNewConfirmTitle:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewConfirm.title',
  addNewConfirmMessage:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewConfirm.message',
  addNewConfirmText:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewConfirm.confirmText',
  addNewSuccessToast:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewSuccessToast',
  addNewErrorTitle:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewError.title',
  addNewErrorMessage:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addNewError.message',
  addTenantAlreadyExistError:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addTenantAlreadyExistError.message',
};

export default function AddTenantRelationship({
  setIsOpen = () => null,
  setLoading = () => null,
  setErrorMessage = () => null,
}: Props) {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const [search, setSearch] = useState<SearchType>({
    name: '',
    type: DEFAULT_CHANNEL_TYPE,
    page: 0,
    perPage: 25,
  });

  function updateSearch(props: Partial<SearchType>) {
    if (!props.page) {
      props.page = 0;
    }

    setSearch({
      ...search,
      ...props,
    });
  }

  async function addNewRelationship(newChannel: any) {
    try {
      const name = getDisplayProfileName(newChannel);

      await window.Alert.confirm({
        title: t(TRANSLATION_KEYS.addNewConfirmTitle, {
          channelName: name,
        }),
        message: t(TRANSLATION_KEYS.addNewConfirmMessage, {
          channelName: newChannel.name,
          targetChannel: channel?.name,
        }),
        confirmText: t(TRANSLATION_KEYS.addNewConfirmText),
      });
    } catch (err) {
      // user cancelled
      return;
    }

    const relationship = {
      channel: {
        _id: newChannel._id,
        parent: { _id: newChannel.parent._id },
      },
      relatedTo: {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        _id: channel._id,
      },
      type: ChannelRelationshipTypeEnum.Tenant,
    };

    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        mutation: addChannelRelationship,
        variables: { relationship },
        refetchQueries: ['ChannelsByRelationship'],
      });
      window.Toast.show(
        <p>
          {t(TRANSLATION_KEYS.addNewSuccessToast, {
            channelName: newChannel.name,
          })}
        </p>
      );
    } catch (err) {
      let errorMessage = err?.message || t(TRANSLATION_KEYS.addNewErrorMessage);

      if(err?.message?.includes(errorCodes.alreadyExistsError.code)) {
        errorMessage = t(TRANSLATION_KEYS.addTenantAlreadyExistError);
      }

      setErrorMessage(errorMessage);
    }

    setIsOpen(false);
    setLoading(false);
  }

  return (
    <div
      data-test="channelSearchResults"
      className={cx(styles.ChannelRelationshipsSearch)}
    >
      {TENANT_TYPES.length > 1 && (
        <menu data-test="channelTypeMenu">
          {TENANT_TYPES.filter(type => TENANT_TYPES.includes(type)).map(
            type => (
              <button
                key={type}
                data-is-selected={type === search.type}
                onClick={() => updateSearch({ type })}
              >
                {t(
                  `web.components.lane.ChannelRelationshipsSearch.ChannelTypeEnum.${type}`
                )}
              </button>
            )
          )}
        </menu>
      )}
      <ChannelSearchByGeoLocation
        defaultDistance={200}
        type={search.type as ChannelTypeEnum}
        excludeParentChannels
        location={{
          latitude: channel?.address?.geo?.[1] ?? LocationType.default.latitude,
          longitude:
            channel?.address?.geo?.[0] ?? LocationType.default.longitude,
        }}
        onChannelSelected={channel => addNewRelationship(channel)}
      />
    </div>
  );
}
