import { useEffect, useState } from 'react';

import { getChannelAccessControlGroups } from 'lane-shared/graphql/accessControl';
import { getClient } from 'lane-shared/apollo';
import { AccessControlGroup } from 'graphql-query-contracts';

export const useAccessGroups = (
  channelId: string,
  accessGroupIds?: string[]
) => {
  const [accessGroups, setAccessGroups] = useState<AccessControlGroup[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      if (channelId === '') return;

      try {
        setLoading(true);
        const { data } = await getClient().query({
          query: getChannelAccessControlGroups,
          variables: {
            channelId,
            acgIds: accessGroupIds || [],
          },
          fetchPolicy: 'no-cache',
        });

        setAccessGroups(data.getChannelAccessControlGroups);
      } catch (error) {
        console.error('Error fetching access groups:', error);
      } finally {
        setLoading(false);
      }
    })();
  }, [channelId, accessGroupIds]);

  return { accessGroups, loading };
};
