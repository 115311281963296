import { MENU_FEATURE_DEFAULT_PREPARATION_TIME } from '../../renderers/v5/features/types/MenuFeatureItem';

export default function getMenuFeatureMaxPreparationTime({
  feature,
  order,
}: any) {
  // find the highest preparation time in this order.
  return order.items.reduce(
    (maxTime: any, orderItem: any) => {
      const item = feature.items.find(
        (item: any) => item._id === orderItem.itemId
      );

      return Math.max(
        item.preparationMinutes || MENU_FEATURE_DEFAULT_PREPARATION_TIME,
        maxTime
      );
    },
    [MENU_FEATURE_DEFAULT_PREPARATION_TIME]
  );
}
