import React from 'react';

import { Icon, Popup } from 'design-system-web';
import cs from 'classnames';
import { Loading } from 'components';
import { useTranslation } from 'react-i18next';

import type { ChipListItem } from './types';
import { ChipStyle } from './types';

import styles from './ChipSelect.scss';

const emptyChipItem = {
  label: '',
  value: '',
  type: ChipStyle.Red,
};

export type Size = 'xs' | 'sm' | 'md';

const ActionChip = ({
  item,
  listButtonClassName,
  size,
}: {
  item: ChipListItem;
  listButtonClassName: string;
  size: Size;
}) => (
  <span
    className={cs(
      styles.listItem,
      styles[item.type],
      styles[size],
      styles.withStatusIcon,
      listButtonClassName
    )}
  >
    <span className={cs(styles.statusIcon)} />
    {item.label}
  </span>
);

function NoActionChip({
  children,
  size,
}: {
  children: React.ReactNode;
  size: Size;
}) {
  return (
    <span
      onClick={() => {}}
      onKeyDown={() => {}}
      role="option"
      aria-selected={false}
      className={cs(
        styles.listItem,
        styles[ChipStyle.Grey],
        styles[size],
        styles.withStatusIcon
      )}
      tabIndex={0}
    >
      <span className={cs(styles.statusIcon)} />
      {children}
    </span>
  );
}

export function ChipSelect({
  anchorClassName = '',
  testId = 'chipSelect',
  list,
  listButtonClassName = '',
  onChange,
  value,
  loading = false,
  loadingText = '',
  zIndex = 6,
  size = 'sm',
  isDisabled = false,
}: {
  anchorClassName?: string;
  testId?: string;
  list: ChipListItem[];
  listButtonClassName?: string;
  onChange: (value: string) => void;
  value: string;
  loading?: boolean;
  loadingText?: string;
  zIndex?: number;
  size?: Size;
  isDisabled?: boolean;
}) {
  const { t } = useTranslation();

  const valueItem = list.find(item => item.value === value) ?? emptyChipItem;
  const valueList = list.filter(item => item.value !== value);

  // Classnames and styles
  const appliedChipClassname = cs(
    styles.chip,
    styles[valueItem.type],
    styles[size],
    styles.withStatusIcon,
    anchorClassName
  );

  const loadingChipClassname = cs(
    styles.chip,
    styles.loadingChip,
    styles[size],
    anchorClassName
  );

  const handleChange = (value: string, close: () => void) => () => {
    onChange(value);
    close();
  };

  const renderLoadingState = () => (
    <div className={loadingChipClassname}>
      <Loading testId="loadingSpinner" />
      {loadingText}
    </div>
  );

  return (
    <>
      {loading && renderLoadingState()}
      {!loading && !isDisabled && (
        <Popup
          trigger={({ isOpen, ...referenceProps }) => (
            <div
              className={appliedChipClassname}
              data-open={isOpen}
              data-test={testId}
              role="button"
              tabIndex={0}
              {...referenceProps}
            >
              <span className={cs(styles.statusIcon, styles[valueItem.type])} />

              {valueItem.label}

              <Icon
                name={isOpen ? 'chevron-up' : 'chevron-down'}
                set="Feather"
              />
            </div>
          )}
          zIndex={zIndex}
          listNavigation
        >
          {({ getItemProps, listRef, close }) => (
            <ul role="listbox" className={styles.listWrapper}>
              {valueList.length > 0 ? (
                valueList.map((item, index) => (
                  <li
                    {...getItemProps()}
                    ref={node => {
                      listRef.current[index] = node;
                    }}
                    key={item.value}
                    className={styles.listItemWrapper}
                    tabIndex={0}
                    role="option"
                    aria-selected={item.value === value}
                    onClick={handleChange(item.value, close)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleChange(item.value, close)();
                      }
                    }}
                  >
                    <ActionChip
                      listButtonClassName={listButtonClassName}
                      item={item}
                      size={size}
                    />
                  </li>
                ))
              ) : (
                <li className={styles.listItemWrapper} {...getItemProps()}>
                  <NoActionChip size={size}>
                    {t('No Actions Available')}
                  </NoActionChip>
                </li>
              )}
            </ul>
          )}
        </Popup>
      )}
      {!loading && isDisabled && (
        <Chip
          value={valueItem.label ? valueItem.label : value}
          type={list.find(item => item.value === value)?.type || ChipStyle.Red}
          size={size}
          testId={testId}
        />
      )}
    </>
  );
}

export function Chip({
  value,
  type,
  testId = 'nonInteractiveChip',
  doTranslate = true,
  size = 'sm',
  withStatusIcon = true,
}: {
  value: string;
  type: ChipStyle;
  testId?: string;
  doTranslate?: boolean;
  size?: Size;
  withStatusIcon?: Boolean;
}) {
  const { t } = useTranslation();

  return (
    <div
      className={cs(
        styles.nonInteractiveChip,
        styles[type],
        styles[size],
        withStatusIcon ? styles.withStatusIcon : ''
      )}
      data-test={testId}
    >
      {withStatusIcon && <span className={cs(styles.statusIcon)} />}
      {doTranslate ? t(value) : value}
    </div>
  );
}

ChipSelect.NonInteractive = Chip;

export default ChipSelect;
