import React, { useEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useModalPosition } from 'hooks';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { parseDateTime } from 'lane-shared/helpers/dates';
import * as dateRangeFormatters from 'lane-shared/helpers/formatters/dateRange';
import { useDateRangePicker } from 'lane-shared/hooks';
import { TimeUnitEnum } from 'lane-shared/types/TimeUnitEnum';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import ValidationMessage from 'components/general/ValidationMessage';

import ModalBackground from '../../general/ModalBackground';
import Calendar from './components/Calendar';
import MonthCalendar from './components/MonthCalendar';

import styles from './DatePickers.scss';

type OwnProps = {
  /** function to be called when user sets new date */
  onChange: (dateRange: DateRangeType) => void;
  /** start Date as an JS object */
  startDate: Date;
  /** end date as an JS object */
  endDate: Date;
  /** the minimum date allowed to be selected */
  minDate?: Date;
  /** the maximum date allowed to be selected */
  maxDate?: Date;
  placeholder?: string;
  /** an array of date ranges that are not available */
  unavailableDateRanges?: DateRangeType[];
  /** include a time picker? or just for date range */
  includeTime?: boolean;
  timeUnit?: TimeUnitEnum;
  timeZone?: string;
  /** the minimum range size in days, i.e. you must select 2 days */
  minRangeSize?: number;
  /** the maximum range size in days, i.e. you can only select 7 days */
  maxRangeSize?: number;
  error?: string[];
  className?: string;
  style?: React.CSSProperties;
  weekdayOnly?: boolean;
  disabled?: boolean;
  shouldShowDefaultButtonText?: boolean;
};

type Props = OwnProps;

/**
 * @deprecated use DateRangePickerButton in design-system-web instead.
 */
export default function DateRangePickerButton({
  className,
  style,
  placeholder: placeholderKey = 'web.admin.content.metatag.tabItem.edit.datePicker.selectDate',
  startDate,
  endDate,
  onChange,
  includeTime = false,
  timeUnit = TimeUnitEnum.Day,
  timeZone,
  minDate,
  maxDate,
  unavailableDateRanges = [],
  minRangeSize,
  maxRangeSize,
  weekdayOnly,
  disabled = false,
  error,
  shouldShowDefaultButtonText = false,
}: Props) {
  const {
    buttonRef,
    modalRef,
    isOpen,
    onOpen,
    onClose,
    position,
  } = useModalPosition();

  const [internalRange, setDate, resetRange] = useDateRangePicker({
    startDate,
    endDate,
    minRangeSize,
    maxRangeSize,
    timeZone,
  });

  const { t, i18n } = useTranslation();
  const placeholder = t(placeholderKey);

  useEffect(() => {
    // reset date range after unmounting component
    return () => resetRange();
  }, [isOpen]);

  // Declaring display values, depending on props and internal values
  // Prefer internal range over props
  const _startDate = internalRange.startDate || startDate;
  const _endDate = internalRange.endDate || endDate;

  useEffect(() => {
    if (startDate && endDate) {
      resetRange();
      setDate(startDate);
      setDate(endDate);
    }
  }, [startDate, endDate]);

  function onDateClicked(date: any) {
    setDate(date);
  }

  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  function renderCalendar() {
    switch (timeUnit) {
      case TimeUnitEnum.Year:
        break;
      case TimeUnitEnum.Quarter:
      case TimeUnitEnum.Month:
        return (
          <MonthCalendar
            className={styles.calendar}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(date: any) => void' is not assignable to ty... Remove this comment to see the full error message
            onChange={onDateClicked}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '() => void' is not assignable to type 'Date ... Remove this comment to see the full error message
            onSubmit={onSubmit}
            minDate={minDate}
            maxDate={maxDate}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null | un... Remove this comment to see the full error message
            startDate={_startDate}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null | un... Remove this comment to see the full error message
            endDate={_endDate}
            unavailableDateRanges={unavailableDateRanges}
            minRangeSize={minRangeSize}
            maxRangeSize={maxRangeSize}
          />
        );
      case TimeUnitEnum.Week:
      case TimeUnitEnum.Day:
      case TimeUnitEnum.Hour:
      default:
        return (
          <Calendar
            className={styles.calendar}
            onChange={onDateClicked}
            onSubmit={onSubmit}
            includeTime={includeTime}
            minDate={minDate}
            maxDate={maxDate}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Date | nu... Remove this comment to see the full error message
            startDate={_startDate}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Date | nu... Remove this comment to see the full error message
            endDate={_endDate}
            unavailableDateRanges={unavailableDateRanges}
            minRangeSize={minRangeSize}
            maxRangeSize={maxRangeSize}
            weekdayOnly={weekdayOnly}
            timeZone={timeZone}
          />
        );
    }
  }

  function onSubmit() {
    onChange({
      startDate:
        parseDateTime(internalRange.startDate, timeZone)
          ?.startOf('day')
          .toJSDate() || null,
      endDate:
        parseDateTime(internalRange.endDate, timeZone)
          ?.endOf('day')
          .toJSDate() || null,
    });
    onClose();
  }

  function getButtonText(startDate: Date, endDate: Date) {
    return dateRangeFormatters[timeUnit]({
      _start: startDate,
      _end: endDate,
      timeZone,
      locale: i18n.language,
      i18n,
    });
  }

  let buttonText = placeholder;

  if (startDate && endDate && !shouldShowDefaultButtonText) {
    buttonText = getButtonText(startDate, endDate);

    if (endDate === _endDate) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
      buttonText = getButtonText(_startDate, _endDate);
    }
  }

  return (
    <span
      ref={buttonRef}
      className={cx(styles.DatePickerButtons, className)}
      style={style}
    >
      <button className={styles.button} onClick={onOpen} disabled={disabled}>
        <span>{buttonText}</span>
        <Icon
          name="calendar"
          className={cx(styles.icon, {
            [styles.disabled]: disabled,
          })}
        />
      </button>
      <ModalBackground
        className={styles.background}
        onClose={onClose}
        isOpen={isOpen}
      />
      {isOpen &&
        ReactDOM.createPortal(
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<HTMLElement>' is not assignable to... Remove this comment to see the full error message
          <div ref={modalRef} className={styles.container} style={position}>
            {renderCalendar()}
          </div>,
          document.body
        )}
      {error ? <ValidationMessage withoutIcon errors={error} /> : null}
    </span>
  );
}
