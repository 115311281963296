export function getOrdinal(n: number): string {
  const tens = n % 10;
  const hundreds = n % 100;

  if (tens === 1 && hundreds !== 11) {
    return `${n}st`;
  }

  if (tens === 2 && hundreds !== 12) {
    return `${n}nd`;
  }

  if (tens === 3 && hundreds !== 13) {
    return `${n}rd`;
  }

  return `${n}th`;
}
