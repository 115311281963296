import React, { ReactElement } from 'react';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { getDisplayProfileName } from 'lane-shared/helpers/getDisplayName';

import { M, S } from 'components/typography';
import { colors } from 'lane-shared/config';

import CircleListView from './CircleListView';

type ChannelSearchCircleListViewProps = {
  className?: string;
  style?: React.CSSProperties;
  channel: any;
  iconName?: string;
  description?: ReactElement | string;
  hideDescription?: boolean;
  onClick?: (channel: any) => void;
};

// POD-247 This is a temporary hack because some parent channels are created with the string " PARENT"
// Needs a better solution/data cleanup but this works as intended, forgive me
function trimParentChannelNamesFromDisplay(channelName: any) {
  return channelName.replace(/( PARENT)$/g, '');
}

export default function ChannelSearchCircleListView({
  channel,
  className,
  style,
  description,
  onClick,
  hideDescription = false,
}: ChannelSearchCircleListViewProps) {
  const isDescriptionComponent = typeof description !== 'string';
  let descriptionText = !isDescriptionComponent ? description : undefined;

  if (descriptionText === undefined) {
    if (!channel?.address?.street1) {
      descriptionText = '';
    } else {
      const { street1, city, state, code } = channel.address;
      const values = [street1, city, state, code].filter(value => !!value);

      descriptionText = values.join(', ');
    }
  }

  return (
    <CircleListView
      image={imageUrl(channel?.profile?.image)}
      logo={imageUrl(channel?.profile?.logo)}
      className={className}
      style={style}
      onClick={() => onClick && onClick(channel)}
    >
      <M size="large">
        {trimParentChannelNamesFromDisplay(getDisplayProfileName(channel))}
      </M>
      {!hideDescription && (
        <>
          {description}
          {!!descriptionText && (
            <S size="default" style={{ color: colors.subduedText }}>
              {descriptionText}
            </S>
          )}
        </>
      )}
    </CircleListView>
  );
}
