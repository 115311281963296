import React from 'react';
import { Icon, Popup, Button, Checkbox } from 'design-system-web';

import styles from './EmailOptionsDropdown.scss';
import { useTranslation } from 'react-i18next';
import { getEmailBuilderOptionsDropdownLabel } from '../helpers';
import { EmailEditableEnum } from '../helpers/constants';

type OptionsType = {
  [key: string]: boolean;
};

const EXTRA_OPTIONS = [
  EmailEditableEnum.ShowQRCode,
  EmailEditableEnum.ShowContentName,
  EmailEditableEnum.ShowDateTime,
] as string[];

export function EmailOptionsDropdown({
  options,
  label,
  checkEmailOption,
  hideExtraSelectables,
  isInteractionBasedWorkflow,
  onClose,
  onOpen,
}: {
  options: OptionsType;
  label: string;
  checkEmailOption: (option: any, bool: boolean) => void;
  hideExtraSelectables: boolean;
  isInteractionBasedWorkflow: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.EmailOptionsDropdown}>
      <Popup
        trigger={({ isOpen, ...referenceProps }) => (
          <div
            data-open={isOpen}
            role="button"
            tabIndex={0}
            {...referenceProps}
          >
            <Button
              variant="secondary"
              size="large"
              className={styles.optionsButton}
            >
              {label}
              <Icon
                className={styles.arrowIcon}
                name={isOpen ? 'chevron-up' : 'chevron-down'}
                set="Feather"
              />
            </Button>
          </div>
        )}
        listNavigation
        zIndex={5}
        onClose={onClose}
        onOpen={onOpen}
      >
        <div className={styles.emailOptions}>
          {Object.entries(options || {})
            .filter(([option]) => {
              if (hideExtraSelectables) {
                return !EXTRA_OPTIONS.includes(option);
              }

              if (isInteractionBasedWorkflow) {
                return option !== EmailEditableEnum.Intro;
              }

              return true;
            })
            .map(([option, bool]) => {
              return (
                <div key={option} className={styles.emailOption}>
                  <Checkbox
                    className={styles.checkBox}
                    selected={bool}
                    value={option}
                    text={getEmailBuilderOptionsDropdownLabel(option, t)}
                    onChange={() => checkEmailOption(option, !bool)}
                  />
                </div>
              );
            })}
        </div>
      </Popup>
    </div>
  );
}
