import { gql, useQuery } from '@apollo/client';

const GET_USER_ACCESS_DETAILS = gql`
  query UserAccessDetails($userId: UUID!) {
    userAccessDetails(userId: $userId) {
      id
      name
      primaryEmail
      image
      logo
      accessUsers {
        syncStatus
        channelId
        mobileAccess
        accessControlGroups {
          id
        }
      }
      companies {
        id
        name
      }
      accessRole {
        userId
        channelId
        accessRole
      }
    }
  }
`;

interface AccessControlGroup {
  id: string;
}

export interface AccessUser {
  syncStatus: string;
  channelId: string;
  mobileAccess: boolean;
  accessControlGroups: AccessControlGroup[];
}

export interface Company {
  id: string;
  name: string;
}

export interface AccessRole {
  userId: string;
  channelId: string;
  accessRole: string;
}

export interface UserAccessDetails {
  id: string;
  name: string;
  primaryEmail: string;
  image: string;
  logo: string;
  accessUsers: AccessUser[];
  companies: Company[];
  accessRole: AccessRole[];
}

export const useUserAccessDetails = (userId: string) => {
  const { data, loading, error, refetch } = useQuery(GET_USER_ACCESS_DETAILS, {
    variables: { userId },
  });

  return {
    data: data?.userAccessDetails,
    loading,
    error,
    refetch,
  };
};
