import { useEffect, useState } from 'react';

import * as Sentry from '@sentry/browser';
import { useApolloClient } from '@apollo/client';

import { getPassCredential } from 'lane-shared/graphql/visitorManagement';
import { convertImageToBase64 } from '../../helpers';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import {
  DymoStatus,
  PrinterInfo,
  PassCredentialResponse,
  VisitorPassQueryResponse,
} from '../../types';
import { DateTime } from 'luxon';

import {
  SHORT_TIME,
  SHORT_TIME_WITH_TZ,
} from '../../../../helpers/constants/dates';
import { dateFormatter } from '../../../../helpers/formatters';
import { getTimeZoneByGeoLocation } from '../../../../helpers';
import { ChannelType } from '../../../../types/ChannelType';
import { useTranslation } from 'react-i18next';
import { LAST_USED_VISTORS_PASS_PRINTER } from 'lane-shared/domains/visitorManagement/constants';

/**
 * Declares the global `dymo` object on the `Window` interface.
 */
declare global {
  interface Window {
    dymo_status: DymoStatus;
    dymo: any;
  }
}

export function useDymoPrinter(channel: ChannelType, canPrintBadge: boolean) {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const timezone = getTimeZoneByGeoLocation({
    latitude: channel?.address?.geo[1],
    longitude: channel?.address?.geo[0],
  });
  const [connectedPrinters, setConnectedPrinters] = useState<PrinterInfo[]>([]);

  const isPrinterSelectionEnabled = useFlag(
    FeatureFlag.VisitorManagementPrinterSelectionForPrinterPass,
    false
  );

  useEffect(() => {
    if (!canPrintBadge) {
      window.dymo_status = DymoStatus.PERMISSION_MISSING;

      return;
    }

    if (window?.dymo) {
      window.dymo_status = DymoStatus.SDK_ALREADY_LOADED;

      return;
    }

    const script = document.createElement('script');

    script.async = true;
    script.type = 'text/javascript';
    script.crossOrigin = 'anonymous';
    script.integrity =
      'sha512-K8ynnffnBxL9VXtaFCJoIBso83kGLt2Ghu+1RduYuplU05ur4hzq4SaSTcK3/xQXuollPhlzqWW36467YiuTQg==';
    script.src =
      'https://cdn.jsdelivr.net/gh/dymosoftware/dymo-connect-framework@43e069cb39b4fc96dcf6b6543b85bb88f1f6dee8/dymo.connect.framework.js';
    script.onload = () => {
      window.dymo_status = DymoStatus.SDK_LOADED;
    };

    script.onerror = error => {
      window.dymo_status = DymoStatus.ERROR_LOADING_SDK;
      console.error('Error loading DYMO SDK:', error);
    };

    document.body.appendChild(script);
  }, [canPrintBadge]);

  /**
   * Returns a formatted time window string based on the start and end datetimes.
   *
   * @param startDatetime - The start datetime string.
   * @param endDatetime - The end datetime string.
   * @returns The formatted time window string; ex: 5:00 PM - 9:00 PM EDT
   */
  const getTimeWindow = (
    startDatetime: string | undefined,
    endDatetime: string | undefined
  ): string => {
    const startTime = startDatetime
      ? dateFormatter(startDatetime, SHORT_TIME, timezone)
      : '-';
    const endTime = endDatetime
      ? dateFormatter(endDatetime, SHORT_TIME_WITH_TZ, timezone)
      : '-';

    return `${startTime} - ${endTime}`;
  };

  /**
   * Generates an XML string for a DieCutLabel.
   * @returns The generated XML string.
   */
  const generateVisitorXML = (): string => {
    return `<?xml version="1.0" encoding="utf-8"?>
    <DieCutLabel Version="8.0" Units="twips">
      <PaperOrientation>Landscape</PaperOrientation>
      <Id>NameBadge</Id>
      <IsOutlined>false</IsOutlined>
      <PaperName>30256 Shipping</PaperName>
      <DrawCommands>
        <Path>
          <FillMode>EvenOdd</FillMode>
          <RoundRectangle X="0" Y="0" Width="3331" Height="5760" Rx="180" Ry="180" />
          <RoundRectangle X="2880" Y="2520" Width="180" Height="720" Rx="120" Ry="120" />
        </Path>
      </DrawCommands>
      <ObjectInfo>
        <TextObject>
          <Name>GUEST</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
          <LinkedObjectName />
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <GroupID>-1</GroupID>
          <IsOutlined>False</IsOutlined>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>ShrinkToFit</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String xml:space="preserve">GUEST</String>
              <Attributes>
                <Font Family="Arial" Size="26" Bold="True" Italic="False" Underline="False" Strikeout="False" />
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="360" Y="647.5" Width="5075.5" Height="570" />
      </ObjectInfo>
      <ObjectInfo>
        <TextObject>
          <Name>HOSTNAME</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
          <LinkedObjectName />
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <GroupID>-1</GroupID>
          <IsOutlined>False</IsOutlined>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>ShrinkToFit</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String xml:space="preserve">HOSTNAME</String>
              <Attributes>
                <Font Family="Arial" Size="10" Bold="True" Italic="False" Underline="False" Strikeout="False" />
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="360" Y="1500" Width="3485.5" Height="292.5" />
      </ObjectInfo>
      <ObjectInfo>
        <TextObject>
          <Name>FLOOR</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
          <LinkedObjectName />
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <GroupID>-1</GroupID>
          <IsOutlined>False</IsOutlined>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>ShrinkToFit</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String xml:space="preserve">FLOOR</String>
              <Attributes>
                <Font Family="Arial" Size="10" Bold="True" Italic="False" Underline="False" Strikeout="False" />
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="360" Y="1853.5" Width="3485.5" Height="292.5" />
      </ObjectInfo>
      <ObjectInfo>
        <TextObject>
          <Name>DATE</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
          <LinkedObjectName />
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <GroupID>-1</GroupID>
          <IsOutlined>False</IsOutlined>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>ShrinkToFit</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String xml:space="preserve">DATE</String>
              <Attributes>
                <Font Family="Arial" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="360" Y="2287.5" Width="3470.5" Height="292.5" />
      </ObjectInfo>
      <ObjectInfo>
        <TextObject>
          <Name>TIME</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
          <LinkedObjectName />
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <GroupID>-1</GroupID>
          <IsOutlined>False</IsOutlined>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>ShrinkToFit</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String xml:space="preserve">TIME</String>
              <Attributes>
                <Font Family="Arial" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="360" Y="2692.5" Width="3493" Height="292.5" />
      </ObjectInfo>
      <ObjectInfo>
        <ImageObject>
          <Name>QRCODE</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
          <LinkedObjectName />
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <GroupID>-1</GroupID>
          <IsOutlined>False</IsOutlined>
          <Image>iVBORw0KGgoAAAANSUhEUgAAAYUAAAGFCAYAAAASI+9IAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxMAAAsTAQCanBgAAARcSURBVHhe7dWxDYAwEARBQ/89Q2KtS4CXZpLr4HYBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAF+79k7x7AWYYtTP3nsBQBQAOEQBgIgCABEFACIKAEQUAIgoABBRACCiAEBEAYCIAgARBQAiCgBEFACIKAAQUQAgogBARAGAiAIAEQUAIgoARBQAiCgAEFEAIKIAQEQBgIgCABEFACIKAEQUAIgoABBRACCiAEBEAYCIAgARBQAiCgBEFACIKAAQUQAgogBARAGAiAIAEQUAIgoARBQAiCgAEFEAIKIAQEQBgIgCABEFACIKAEQUAIgoABBRACCiAEBEAYCIAgARBQAiCgBEFACIKAAQUQAgogBARAGAiAIAEQUAIgoARBQAiCgAEFEAIKIAQEQBgIgCABEFACIKAEQUAIgoABBRACCiAEBEAYCIAgARBQAiCgBEFACIKAAQUQAgogBARAGAiAIAEQUAIgoARBQAiCgAEFEAIKIAQEQBgIgCABEFACIKAEQUAIgoABBRACCiAEBEAYCIAgARBQAiCgBEFACIKAAQUQAgogBARAGAiAIAEQUAIgoARBQAiCgAEFEAIKIAQEQBgIgCABEFACIKAEQUAIgoABBRACCiAEBEAYCIAgARBQAiCgBEFACIKAAQUQAgogBARAGAiAIAEQUAIgoARBQAiCgAEFEAIKIAQEQBgIgCABEFACIKAEQUAIgoABBRACCiAEBEAYCIAgARBQAiCgBEFACIKAAQUQAgogBARAGAiAIAEQUAIgoARBQAiCgAEFEAIKIAQEQBgIgCABEFACIKAEQUAIgoABBRACCiAEBEAYCIAgARBQAiCgBEFACIKAAQUQAgogBARAGAiAIAEQUAIgoARBQAiCgAEFEAIKIAQEQBgIgCABEFACIKAEQUAIgoABBRACCiAEBEAYCIAgARBQAiCgBEFACIKAAQUQAgogBARAGAiAIAEQUAIgoARBQAiCgAEFEAIKIAQEQBgIgCABEFACIKAEQUAIgoABBRACCiAEBEAYCIAgARBQAiCgBEFACIKAAQUQAgogBARAGAiAIAEQUAIgoARBQAiCgAEFEAIKIAQEQBgIgCABEFACIKAEQUAIgoABBRACCiAEBEAYCIAgARBQAiCgBEFACIKAAQUQAgogBARAGAiAIAEQUAIgoARBQAiCgAEFEAIKIAQEQBgIgCABEFACIKAEQUAIgoABBRACCiAEBEAYCIAgARBQAiCgBEFACIKAAQUQAgogBARAGAiAIAEQUAIgoARBQAiCgAEFEAIKIAQEQBgIgCABEFACIKAEQUAIgoABBRACCiAEBEAYCIAgARBQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD4nbVeNPoDXFk2AfwAAAAASUVORK5CYII=</Image>
          <ScaleMode>Uniform</ScaleMode>
          <BorderWidth>0</BorderWidth>
          <BorderColor Alpha="255" Red="0" Green="0" Blue="0" />
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Center</VerticalAlignment>
        </ImageObject>
        <Bounds X="3926.5" Y="1546.5" Width="1656" Height="1656" />
      </ObjectInfo>
      <ObjectInfo>
        <TextObject>
          <Name>TEXT</Name>
          <ForeColor Alpha="255" Red="27" Green="27" Blue="27" />
          <BackColor Alpha="255" Red="200" Green="200" Blue="200" />
          <LinkedObjectName />
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <GroupID>-1</GroupID>
          <IsOutlined>False</IsOutlined>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>None</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String xml:space="preserve">VISITOR</String>
              <Attributes>
                <Font Family="Arial" Size="10" Bold="True" Italic="False" Underline="False" Strikeout="False" />
                <ForeColor Alpha="255" Red="27" Green="27" Blue="27" HueScale="100" />
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="3439" Y="172.5" Width="2055" Height="330" />
      </ObjectInfo>
    </DieCutLabel>`;
  };

  /**
   * Retrieves the list of available printers.
   *
   * @returns An array of `PrinterInfo` objects representing the available printers.
   */
  const getPrinters = (): PrinterInfo[] => {
    const printers = window?.dymo?.label.framework.getPrinters() || [];

    setConnectedPrinters(printers);

    return printers;
  };

  /**
   * Checks if the Dymo printer is installed.
   * @returns {boolean} True if the Dymo printer is installed, false otherwise.
   */
  const isDymoPrinterInstalled = (): boolean => {
    const printers = getPrinters();

    return printers?.length > 0;
  };

  /**
   * Prints a visitor pass using the provided payload.
   *
   * @param payload - The visitor pass query response containing the necessary information for printing the pass.
   * @returns A promise that resolves when the pass is printed successfully, or rejects if there is an error.
   */
  const printPass = async (
    payload: VisitorPassQueryResponse,
    printer?: PrinterInfo
  ): Promise<void> => {
    try {
      if (!payload || Object.keys(payload).length <= 0) {
        throw new Error(
          t('web.admin.channel.visitor.log.printVisitorPass.guestError')
        );
      }

      const now = DateTime.now();
      const {
        id,
        floor,
        hostName,
        endDatetime,
        startDatetime,
        visitor: { firstName, lastName },
      } = payload;
      const guest = `${firstName} ${lastName ?? ''}`;
      const date = `Printed on: ${now.toLocaleString(DateTime.DATE_FULL)}`;
      const time = getTimeWindow(startDatetime, endDatetime);

      const { data } = await apolloClient.query<PassCredentialResponse>({
        query: getPassCredential,
        variables: { visitorId: id },
      });

      if (!data || !data.credential) {
        throw new Error(
          t('web.admin.channel.visitor.log.printVisitorPass.credentialError')
        );
      }

      const qrcode = await convertImageToBase64(data.credential.uri);

      if (!qrcode) {
        throw new Error(
          t('web.admin.channel.visitor.log.printVisitorPass.qrcodeError')
        );
      }

      let printerName;
      const printers = getPrinters();

      if (!isPrinterSelectionEnabled || printers.length === 1) {
        const labelPrinter = printers.find(
          ele => ele.printerType === 'LabelWriterPrinter'
        );

        if (!labelPrinter) {
          throw new Error(
            t('web.admin.channel.visitor.log.printVisitorPass.printerError')
          );
        }

        printerName = labelPrinter?.name;
      } else {
        printerName = printer?.name;
      }

      const xml = generateVisitorXML();
      const labelXml = window?.dymo?.label.framework.openLabelXml(xml);

      labelXml.setObjectText('GUEST', guest);
      labelXml.setObjectText('FLOOR', floor);
      labelXml.setObjectText('DATE', date);
      labelXml.setObjectText('TIME', time);
      labelXml.setObjectText('HOSTNAME', hostName);
      labelXml.setObjectText('QRCODE', qrcode);

      labelXml.print(printerName);
      console.log(t('web.admin.channel.visitor.log.printVisitorPass.success'));

      if (printerName) {
        const lastUsedPrinterFromStorage = localStorage.getItem(
          LAST_USED_VISTORS_PASS_PRINTER
        );

        if (lastUsedPrinterFromStorage !== printerName) {
          localStorage.setItem(LAST_USED_VISTORS_PASS_PRINTER, printerName);
        }
      }
    } catch (e) {
      console.error(e);
      Sentry.captureException(new Error('Print Visitor Pass failed'), {
        extra: { e },
      });

      return alert(e?.message);
    }
  };

  return {
    isDymoPrinterInstalled,
    printPass,
    connectedPrinters,
    getPrinters,
  };
}
