import React, { useContext, useMemo, useState } from 'react';
import * as yup from 'yup';
import {
  Icon,
  H2,
  H3,
  H4,
  P,
  S,
  TimePicker,
  TimeIntervals,
  TagInput,
} from 'design-system-web';
import {
  Button,
  Checkbox,
  Input,
  Loading,
  Thumbnail,
  Tooltip,
} from 'components';
import { useIsAdminView } from 'hooks';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { AppContext } from 'lane-shared/contexts';
import {
  END_HOUR,
  START_HOUR,
} from 'lane-shared/domains/visitorManagement/features/VisitorManagementFeatureValues';
import { createDefaultDateTime } from 'lane-shared/domains/visitorManagement/helpers';
import {
  EventStatus,
  VisitorPassQueryResponse,
} from 'lane-shared/domains/visitorManagement/types';
import {
  getRecurrenceVisitorPassDetailsById,
  getVisitorPassDetailsById,
} from 'lane-shared/graphql/visitorManagement';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import {
  SHORT_TIME_WITH_TZ,
  LONG_DAY_LONG_DATE,
  SHORT_TIME,
  SIMPLE_DATE,
  SHORT_DATE,
} from 'lane-shared/helpers/constants/dates';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { explodeFeatures } from 'lane-shared/helpers/features';
import {
  dateFormatter,
  addressFormatter,
  imageUrl,
} from 'lane-shared/helpers/formatters';
import { formatIncompletePhoneNumber } from 'lane-shared/helpers/phoneNumbers';
import { useFlag, useContentQuery } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { ChipSelect } from 'components/ads';
import TextArea from 'components/form/TextArea';
import { AdminPage } from 'components/layout';
import { BreadCrumbs, Alert, AlertType, Modal } from 'components/lds';

import { VisitorPassStatusSelect } from '../../components';
import DatePickerButton from '../../components/DatePickers/DatePickerButton';
import { useEditVisitorPassDetails } from '../../hooks/useEditVisitorPassDetails';
import { useStatusSelector } from '../../hooks/useStatusSelector';
import { StatusListItems } from '../../types/EventStatus';

import styles from './styles.scss';

import { rrulestr } from 'rrule';
import { useOrganizationSettingsData } from 'lane-shared/domains/visitorManagement/hooks/useOrganizationSettingsData';

const combineNames = (names: (string | undefined)[]): string => {
  return names.filter(name => Boolean(name)).join(' ');
};

const invalidStatusList = [
  EventStatus.EVENT_STATUS_CANCELLED.toString(),
  EventStatus.EVENT_STATUS_EXPIRED.toString(),
  EventStatus.EVENT_STATUS_PENDING.toString(),
  EventStatus.EVENT_STATUS_ARCHIVED.toString(),
  EventStatus.EVENT_STATUS_INVALID.toString(),
];

type RecurrenceData = {
  schedule: string;
  recurrenceId?: string;
};

type QueryResponse = {
  visitorPass: VisitorPassQueryResponse;
  recurrence?: RecurrenceData;
};

const TRANSLATION_KEYS = {
  recurrenceDetails:
    'web.admin.channel.visitor.passDetails.visitDetails.recurrence.details',
  recurrenceSchedule:
    'web.admin.channel.visitor.passDetails.visitDetails.recurrence.schedule',
  recurrenceEditWarning:
    'web.admin.channel.visitor.passDetails.visitDetails.recurrence.edit.warning',
};

export function VisitorPassDetailsPage({ channel }: { channel: any }) {
  const { t } = useTranslation();
  const { whitelabel } = useContext(AppContext);
  const [, channelSlug] = useIsAdminView();
  const timezone = getTimeZoneByGeoLocation({
    latitude: channel.address.geo[1],
    longitude: channel.address.geo[0],
  });

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const { visitorPassId } = useParams<{ visitorPassId: string }>();
  const passUUID = convertToUUID(visitorPassId);
  const visitorNotesEnabled = useFlag(
    FeatureFlag.VisitorManagementVisitorNotes,
    false
  );
  const multipleNotificationRecipientsEnabled = useFlag(
    FeatureFlag.VisitorManagementMultipleNotificationRecipients,
    false
  );
  const history = useHistory();

  const { data, loading, error, refetch } = useQuery<QueryResponse>(
    getVisitorPassDetailsById,
    {
      variables: {
        id: passUUID,
        channelId: channel._id,
      },
      fetchPolicy: 'network-only',
    }
  );

  const {
    content,
    loading: isContentLoading,
    error: contentError,
  } = useContentQuery({
    contentId: data?.visitorPass?.sourceId,
  });

  const { loadingStates, updateStatus } = useStatusSelector();

  const {
    isLoading,
    startTime,
    endTime,
    isAllDaySelected,
    isAllDaySelectable,
    isDateEditable,
    isStartTimeEditable,
    isEndTimeEditable,
    isPristine,
    staffNote,
    visitorNote,
    updateDate,
    updateStartTime,
    updateEndTime,
    toggleAllDaySelection,
    updateVisitorNote,
    updateStaffNote,
    reset,
    onSave,
    additionalRecipients,
    updateAdditionalRecipients,
    handleAdditionalRecipientsErrorChange,
  } = useEditVisitorPassDetails({ pass: data?.visitorPass, timezone });

  const { organizationSettings } = useOrganizationSettingsData(
    convertToUUID(channel?._id)
  );

  const maxDate = useMemo(() => {
    let date;

    if (content?.features) {
      const result = explodeFeatures(content?.features);

      if (result.visitorManagementFeature) {
        date = new Date();
        date.setMonth(
          date.getMonth() +
            result.visitorManagementFeature?.scheduleInAdvanceAmount
        );
      }
    }

    return date;
  }, [content?.features]);

  const isAllDayPassEnabled = useMemo(() => {
    if (content?.features) {
      const result = explodeFeatures(content?.features);

      return result.visitorManagementFeature?.allDayPassEnabled || false;
    }

    return false;
  }, [content?.features]);

  const shouldRender = useMemo(() => {
    return (
      !loading &&
      !isContentLoading &&
      !error &&
      !contentError &&
      Boolean(data?.visitorPass)
    );
  }, [loading, isContentLoading, error, contentError, data]);

  const shouldDisplayNotFound = useMemo(() => {
    return (
      !loading &&
      !isContentLoading &&
      !error &&
      !contentError &&
      !data?.visitorPass
    );
  }, [loading, isContentLoading, error, contentError, data]);

  const isPassEditable = useMemo(() => {
    return data?.visitorPass
      ? !invalidStatusList.includes(data.visitorPass.status) &&
          Boolean(data.visitorPass.validStateTransitions.length)
      : false;
  }, [data?.visitorPass]);

  // Fetch recurrence visitor pass details if a recurrenceId is present
  const recurrenceId = data?.visitorPass?.recurrenceId;
  const {
    data: recurrenceData,
    loading: recurrenceLoading,
  } = useQuery<QueryResponse>(getRecurrenceVisitorPassDetailsById, {
    skip: !recurrenceId,
    variables: { id: recurrenceId },
    fetchPolicy: 'network-only',
  });

  const isRecurrenceVisit = useMemo(() => {
    return (
      data?.visitorPass?.recurrenceId &&
      recurrenceData?.recurrence?.recurrenceId
    );
  }, [data?.visitorPass, recurrenceData]);

  const handleUpdateStatus = async (
    status: string,
    pass: VisitorPassQueryResponse
  ): Promise<void> => {
    if (EventStatus.EVENT_STATUS_CANCELLED.toString() === status) {
      setIsCancelModalOpen(true);

      return;
    }

    try {
      await updateStatus(status, pass);
      await refetch();
    } catch (err) {
      const error = err as Error;

      window.Toast.show(error.message, 5000);
    }
  };

  const handleModalCancelOnClick = async () => {
    if (!shouldRender) return;

    try {
      await updateStatus(
        EventStatus.EVENT_STATUS_CANCELLED.toString(),
        data!.visitorPass
      );
      await refetch();
      const message = t(
        'web.admin.channel.visitor.passDetails.cancelation.success'
      );

      window.Toast.show(message, 5000);
    } catch (error) {
      const message = t(
        'web.admin.channel.visitor.passDetails.cancelation.failure'
      );

      window.Toast.show(message, 5000);
    }

    setIsCancelModalOpen(false);
  };

  const validateOnEditSave = (): string => {
    const startTimeMillis = DateTime.fromISO(
      startTime ?? new Date().toISOString()
    ).toMillis();
    const endTimeMillis = DateTime.fromISO(
      endTime ?? new Date().toISOString()
    ).toMillis();
    const currentTime = new Date().getTime();

    if (isEndTimeEditable && currentTime >= endTimeMillis) {
      return t(
        'web.admin.channel.visitor.passDetails.validationError.currentTimeEndTime'
      );
    }

    if (startTimeMillis >= endTimeMillis) {
      return t(
        'web.admin.channel.visitor.passDetails.validationError.startTimeEndTime'
      );
    }

    return '';
  };

  const emailValidator = yup.string().email().nullable();
  const validateEmail = (email: string) => {
    try {
      emailValidator.validateSync(email);

      return true;
    } catch (err) {
      return false;
    }
  };

  const onEditCancel = () => {
    setIsEditMode(false);
    reset();
  };

  const onEditSave = async () => {
    const validationErrorMessage = validateOnEditSave();

    if (validationErrorMessage) {
      window.Toast.show(validationErrorMessage, 5000);

      return;
    }

    try {
      await onSave();

      const visitorName = combineNames([
        data?.visitorPass.visitor.firstName,
        data?.visitorPass.visitor.lastName,
      ]);
      const message = t(
        'web.admin.channel.visitor.passDetails.update.success',
        {
          visitorName,
        }
      );

      window.Toast.show(message, 5000);
      setIsEditMode(false);
      refetch();
    } catch (err) {
      const message = t('web.admin.channel.visitor.passDetails.update.failure');

      window.Toast.show(message, 5000);
    }
  };

  const getTitle = (): string => {
    const visitorName = combineNames([
      data?.visitorPass.visitor.firstName,
      data?.visitorPass.visitor.lastName,
    ]);

    return t('web.admin.channel.visitor.passDetails.title', {
      visitorName,
    });
  };

  const getCreatedOnInfo = (): string => {
    const createdDate = data!.visitorPass.createdAtDatetime
      ? dateFormatter(
          data!.visitorPass.createdAtDatetime,
          LONG_DAY_LONG_DATE,
          timezone
        )
      : '-';
    const createdTime = data!.visitorPass.createdAtDatetime
      ? dateFormatter(
          data!.visitorPass.createdAtDatetime,
          SHORT_TIME_WITH_TZ,
          timezone
        )
      : '-';
    const createdBy = data!.visitorPass.submittedBy;

    return t('web.admin.channel.visitor.passDetails.createdOn', {
      createdDate,
      createdTime,
      createdBy,
    });
  };

  const getUpdatedOnInfo = (): string => {
    const updatedDate = data!.visitorPass.updatedAtDatetime
      ? dateFormatter(
          data!.visitorPass.updatedAtDatetime,
          LONG_DAY_LONG_DATE,
          timezone
        )
      : '-';
    const updatedTime = data!.visitorPass.updatedAtDatetime
      ? dateFormatter(
          data!.visitorPass.updatedAtDatetime,
          SHORT_TIME_WITH_TZ,
          timezone
        )
      : '-';
    const updatedBy = data!.visitorPass.updatedBy;

    if (!updatedBy) {
      // means the most recent update was automated
      return t('web.admin.channel.visitor.passDetails.automatedUpdate', {
        updatedDate,
        updatedTime,
        whiteLabelName: whitelabel.name,
      });
    }

    const createdDate = data!.visitorPass.createdAtDatetime
      ? dateFormatter(
          data!.visitorPass.createdAtDatetime,
          LONG_DAY_LONG_DATE,
          timezone
        )
      : '-';
    const createdTime = data!.visitorPass.createdAtDatetime
      ? dateFormatter(
          data!.visitorPass.createdAtDatetime,
          SHORT_TIME_WITH_TZ,
          timezone
        )
      : '-';
    const createdBy = data!.visitorPass.submittedBy;

    if (
      updatedDate === createdDate &&
      updatedTime === createdTime &&
      updatedBy === createdBy
    ) {
      return '';
    }

    return t('web.admin.channel.visitor.passDetails.updatedOn', {
      updatedDate,
      updatedTime,
      updatedBy,
    });
  };

  const getPassDate = (dateTime: string | undefined): string => {
    return dateTime
      ? dateFormatter(dateTime, LONG_DAY_LONG_DATE, timezone)
      : '-';
  };

  const getTimeWindow = (
    startDatetime: string | undefined,
    endDatetime: string | undefined
  ): string => {
    const startTime = startDatetime
      ? dateFormatter(startDatetime, SHORT_TIME, timezone)
      : '-';
    const endTime = endDatetime
      ? dateFormatter(endDatetime, SHORT_TIME_WITH_TZ, timezone)
      : '-';

    return `${startTime} - ${endTime}`;
  };

  const getAllDayTimeWindow = (): string => {
    const allDayStartTime = createDefaultDateTime(
      START_HOUR,
      0,
      timezone
    ).toISOString();
    const allDayEndTime = createDefaultDateTime(
      END_HOUR,
      0,
      timezone
    ).toISOString();

    return getTimeWindow(allDayStartTime, allDayEndTime);
  };

  const getHostEmailAndPhone = (): string => {
    const emailAndPhoneList: string[] = [];
    const joinPattern = ' | ';

    if (data?.visitorPass.host?.profile.email)
      emailAndPhoneList.push(data.visitorPass.host.profile.email);

    if (data?.visitorPass.host?.profile.phone)
      emailAndPhoneList.push(
        formatIncompletePhoneNumber(data.visitorPass.host.profile.phone)
      );

    return emailAndPhoneList.length ? emailAndPhoneList.join(joinPattern) : '';
  };

  const renderStatus = (): React.ReactElement | null => {
    const statusListItem = StatusListItems.find(
      item => item.value === data!.visitorPass.status
    );

    if (!statusListItem) {
      return null;
    }

    if (isEditMode || !isPassEditable) {
      return (
        <ChipSelect.NonInteractive
          value={statusListItem.label}
          type={statusListItem.type}
        />
      );
    }

    return (
      <VisitorPassStatusSelect
        visitorPass={data!.visitorPass}
        loading={loadingStates[data!.visitorPass.id]}
        updateStatus={handleUpdateStatus}
        bufferTime={organizationSettings?.bufferTime || 0}
      />
    );
  };

  const ReturnButton = (
    <Button
      key="1"
      onClick={() => setIsCancelModalOpen(false)}
      fullWidth
      testId="returnToPageButton"
    >
      {t('web.admin.channel.visitor.passDetails.cancelModal.returnButton')}
    </Button>
  );

  const CancelButton = (
    <Button
      key="2"
      variant="contained"
      onClick={handleModalCancelOnClick}
      fullWidth
      testId="cancelPassButton"
      loading={loadingStates[data?.visitorPass?.id || '']}
    >
      {t('web.admin.channel.visitor.passDetails.cancelModal.cancelButton')}
    </Button>
  );

  const getDates = (schedule: string) => {
    const rruleOptions = rrulestr(schedule).options;
    const rrule = rrulestr(schedule);
    let scheduleText = rrule.toText().split('until')[0];

    scheduleText = scheduleText.charAt(0).toUpperCase() + scheduleText.slice(1);

    const recurrenceStartDate = rruleOptions.dtstart;
    const recurrenceEndDate = rruleOptions.until;

    const startDateFormatted = recurrenceStartDate
      ? dateFormatter(recurrenceStartDate, SHORT_DATE, timezone)
      : '';
    const endDateFormatted = recurrenceEndDate
      ? dateFormatter(recurrenceEndDate, SIMPLE_DATE, timezone)
      : '';
    const scheduleString = `${scheduleText} between ${startDateFormatted} to ${endDateFormatted}`;

    return scheduleString;
  };

  return (
    <AdminPage>
      <BreadCrumbs
        links={[
          {
            label: t(
              'web.admin.channel.visitor.passDetails.breadcrumb.visitorLog'
            ),
            url: routes.visitorManagementVisitorLog.replace(
              ':id',
              channelSlug || ''
            ),
          },
          {
            label: t(
              'web.admin.channel.visitor.passDetails.breadcrumb.passDetails'
            ),
          },
        ]}
      />
      {(loading || isContentLoading) && <Loading />}
      {(error || contentError) && (
        <Alert type={AlertType.error} fullWidth>
          {t('web.admin.channel.visitor.passDetails.error')}
        </Alert>
      )}
      {shouldDisplayNotFound && (
        <Alert type={AlertType.error} fullWidth>
          {t('web.admin.channel.visitor.passDetails.notFound')}
        </Alert>
      )}
      {shouldRender && (
        <>
          <Modal
            isOpen={isCancelModalOpen}
            onClose={() => setIsCancelModalOpen(false)}
            actions={[ReturnButton, CancelButton]}
            testId=""
            className={styles.modal}
          >
            <H3 mb={4}>
              {t('web.admin.channel.visitor.passDetails.cancelModal.title')}
            </H3>
            <S className={styles.text} mb={4}>
              {t('web.admin.channel.visitor.passDetails.cancelModal.message')}
            </S>
            <S className={styles.text} mb={4}>
              {t('web.admin.channel.visitor.passDetails.cancelModal.warning')}
            </S>
          </Modal>
          <div className={styles.passDetailsPageContainer}>
            <div className={styles.title}>
              <H2>{getTitle()}</H2>
              <S className={styles.info}>{getCreatedOnInfo()}</S>
              {data?.visitorPass.createdAtDatetime !==
                data?.visitorPass.updatedAtDatetime && (
                <S className={styles.info}>{getUpdatedOnInfo()}</S>
              )}
            </div>
            <div className={styles.status}>
              <span className={styles.statusHeader}>
                {t('web.admin.channel.visitor.passDetails.status.title')}
              </span>
              {renderStatus() || '-'}
            </div>
            <div className={styles.host}>
              <H4>{t('web.admin.channel.visitor.passDetails.host.title')}</H4>
              <div className={styles.itemContainer}>
                <div className={styles.item}>
                  <Thumbnail
                    className={styles.thumbnail}
                    name={data?.visitorPass.host?.name || '-'}
                    src={imageUrl(data?.visitorPass.host?.profile.image)}
                  />
                  <div className={styles.textWrapper}>
                    <P>{data?.visitorPass.host?.name || '-'}</P>
                    <S>{getHostEmailAndPhone()}</S>
                  </div>
                </div>
                <div className={styles.item}>
                  <Thumbnail
                    className={styles.thumbnail}
                    name={data?.visitorPass.tenantName || '-'}
                    src={imageUrl(
                      data?.visitorPass.tenant?.profile?.logo ||
                        data?.visitorPass.tenant?.profile?.image
                    )}
                  />
                  <div className={styles.textWrapper}>
                    <P>{data?.visitorPass.tenantName || '-'}</P>
                    <S>
                      {addressFormatter(data?.visitorPass.tenant?.address, [
                        'name',
                        'street1',
                        'street2',
                        'street3',
                        'city',
                        'state',
                      ])}
                    </S>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.details}>
              <div className={styles.detailsTitleContainer}>
                <H4>
                  {t(
                    'web.admin.channel.visitor.passDetails.visitDetails.title'
                  )}
                </H4>
                {!isEditMode && isPassEditable && (
                  <Button
                    onClick={() => setIsEditMode(true)}
                    testId="editButton"
                    size="small"
                    startIcon={<Icon name="pen" set="FontAwesome" type="far" />}
                  >
                    {t('Edit')}
                  </Button>
                )}
              </div>
              <div>
                {isEditMode && isPassEditable && isRecurrenceVisit && (
                  <Alert type={AlertType.warning}>
                    {t(TRANSLATION_KEYS.recurrenceEditWarning)}
                  </Alert>
                )}
              </div>

              {isRecurrenceVisit && (
                <div className={styles.recurrenceScheduleContainer}>
                  <div className={styles.recurrenceScheduleRow}>
                    <div className={styles.scheduleText}>
                      {t(TRANSLATION_KEYS.recurrenceSchedule)}
                    </div>
                  </div>
                  <div className={styles.recurrenceScheduleRow}>
                    <div className={styles.icon}>
                      <Icon
                        name="repeat"
                        set={ICON_SET_FONTAWESOME}
                        type="far"
                      />
                    </div>
                    <div className={styles.dateText}>
                      {recurrenceLoading ? (
                        <Loading />
                      ) : (
                        <>
                          {recurrenceData?.recurrence?.schedule &&
                            getDates(recurrenceData?.recurrence?.schedule)}
                        </>
                      )}
                    </div>
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <div
                    className={styles.viewRecurrenceDetails}
                    onClick={() =>
                      history.push({
                        pathname: `/l/channel/${channel?.slug}/admin/visitor-management/recurrencedetail/${data?.visitorPass?.recurrenceId}`,
                        state: { data },
                      })
                    }
                  >
                    {t(TRANSLATION_KEYS.recurrenceDetails)}
                  </div>
                </div>
              )}

              <div className={styles.rowsContainer}>
                <div className={styles.row}>
                  <div className={styles.rowItem}>
                    <S mb={1}>
                      {t(
                        'web.admin.channel.visitor.passDetails.visitDetails.visitorName'
                      )}
                    </S>
                    <P>
                      {combineNames([
                        data?.visitorPass.visitor.firstName,
                        data?.visitorPass.visitor.lastName,
                      ])}
                    </P>
                  </div>
                  <div className={styles.rowItem}>
                    <S mb={1}>
                      {t(
                        'web.admin.channel.visitor.passDetails.visitDetails.visitorEmail'
                      )}
                    </S>
                    <P>{data?.visitorPass.visitor.email || '-'}</P>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.rowItem}>
                    <S mb={1}>
                      {t(
                        'web.admin.channel.visitor.passDetails.visitDetails.visitorCompany'
                      )}
                    </S>
                    <P>{data?.visitorPass.visitor.company || '-'}</P>
                  </div>
                  <div className={styles.rowItem}>
                    <S mb={1}>
                      {t(
                        'web.admin.channel.visitor.passDetails.visitDetails.visitorPhoneNumber'
                      )}
                    </S>
                    <P>
                      {data?.visitorPass.visitor.phone
                        ? formatIncompletePhoneNumber(
                            data.visitorPass.visitor.phone
                          )
                        : '-'}
                    </P>
                  </div>
                </div>
                {!isEditMode && (
                  <div className={styles.row}>
                    <div className={styles.rowItem}>
                      <S mb={1}>
                        {t(
                          'web.admin.channel.visitor.passDetails.visitDetails.date'
                        )}
                      </S>
                      <P>{getPassDate(startTime)}</P>
                    </div>
                    <div className={styles.rowItem}>
                      <S mb={1}>
                        {t(
                          'web.admin.channel.visitor.passDetails.visitDetails.timeWindow'
                        )}
                      </S>
                      <P>{getTimeWindow(startTime, endTime)}</P>
                    </div>
                  </div>
                )}
                {!isEditMode && visitorNotesEnabled && (
                  <div className={styles.row}>
                    <div className={styles.rowItem}>
                      <S mb={1}>
                        {t(
                          'web.admin.channel.visitor.passDetails.visitDetails.noteToStaff'
                        )}
                      </S>
                      <P className={styles.notes}>
                        {data?.visitorPass?.staffNote || '-'}
                      </P>
                    </div>
                    <div className={styles.rowItem}>
                      <S mb={1}>
                        {t(
                          'web.admin.channel.visitor.passDetails.visitDetails.noteToVisitor'
                        )}
                      </S>
                      <P className={styles.notes}>
                        {data?.visitorPass?.visitorNote || '-'}
                      </P>
                    </div>
                  </div>
                )}
                {!isEditMode && multipleNotificationRecipientsEnabled && (
                  <div className={styles.row}>
                    <div className={styles.rowItem}>
                      <S mb={1}>
                        {t(
                          'web.admin.channel.visitor.passDetails.visitDetails.additionalRecipients'
                        )}
                      </S>
                      <P className={styles.notes}>
                        {data?.visitorPass?.additionalRecipients?.length
                          ? data?.visitorPass?.additionalRecipients.join(', ')
                          : '-'}
                      </P>
                    </div>
                  </div>
                )}
                {isEditMode && (
                  <>
                    {isAllDayPassEnabled && (
                      <div className={styles.row}>
                        <div className={styles.rowItem}>
                          <div className={styles.allDayLabelContainer}>
                            <S mb={1}>
                              {t(
                                'web.admin.channel.visitor.passDetails.visitDetails.passValidFrom'
                              )}
                            </S>
                            <Tooltip
                              TooltipComponent={t(
                                'web.admin.channel.visitor.passDetails.visitDetails.passValidFrom.tooltip'
                              )}
                              placement="right"
                            >
                              <Icon
                                name="info-circle"
                                className={styles.icon}
                                set={ICON_SET_FONTAWESOME}
                                type="far"
                                size="medium"
                              />
                            </Tooltip>
                          </div>
                          {isAllDaySelectable && (
                            <Checkbox
                              selected={isAllDaySelected}
                              value={isAllDaySelected}
                              onChange={toggleAllDaySelection}
                              className={styles.allDayCheckbox}
                              text={t(
                                'web.admin.channel.visitor.passDetails.visitDetails.allDay',
                                {
                                  allDayTime: getAllDayTimeWindow(),
                                }
                              )}
                              testId="allDayCheckbox"
                            />
                          )}
                          {!isAllDaySelectable && (
                            <Checkbox
                              selected={false}
                              disabled
                              value={false}
                              onChange={() => {}}
                              className={styles.disabledAllDayCheckbox}
                              text={t(
                                'web.admin.channel.visitor.passDetails.visitDetails.allDay',
                                {
                                  allDayTime: getAllDayTimeWindow(),
                                }
                              )}
                              testId="disabledAllDayCheckbox"
                            />
                          )}
                        </div>
                        <div className={styles.rowItem} />
                      </div>
                    )}
                    <div className={styles.row}>
                      <div className={styles.rowItem}>
                        <S mb={1}>
                          {t(
                            'web.admin.channel.visitor.passDetails.visitDetails.date'
                          )}
                        </S>
                        {isDateEditable && (
                          <DatePickerButton
                            wrapperClassName={styles.datePickerButtonWrapper}
                            buttonClassName={styles.datePickerButton}
                            value={new Date(startTime!)}
                            className={styles.datePicker}
                            onChange={() => {}}
                            hideLabel
                            onSubmit={updateDate}
                            minDate={new Date()}
                            maxDate={maxDate}
                            timeZone={timezone}
                          />
                        )}
                        {!isDateEditable && (
                          <Tooltip
                            TooltipComponent={t(
                              'web.admin.channel.visitor.passDetails.visitDetails.date.disabledTooltip'
                            )}
                            placement="bottom"
                          >
                            <Input
                              className={styles.disabledInput}
                              value={getPassDate(startTime)}
                              onChange={() => null}
                              showClear={false}
                              disabled
                              testId="disabledDateInput"
                            />
                          </Tooltip>
                        )}
                      </div>
                      <div className={styles.rowItem}>
                        <div className={styles.flexDisplay}>
                          <div className={styles.rowItem}>
                            <S mb={1}>
                              {t(
                                'web.admin.channel.visitor.passDetails.visitDetails.startTime'
                              )}
                            </S>
                            {isStartTimeEditable && (
                              <TimePicker
                                hideLabel
                                buttonClassName={styles.timePickerButton}
                                value={startTime}
                                onChange={updateStartTime}
                                showClear={false}
                                showIcon={false}
                                timeZone={timezone}
                                unit={TimeIntervals.QUARTER_HOUR}
                                testId="startTimeInput"
                              />
                            )}
                            {!isStartTimeEditable && (
                              <Tooltip
                                TooltipComponent={t(
                                  'web.admin.channel.visitor.passDetails.visitDetails.startTime.disabledTooltip'
                                )}
                                placement="bottom"
                              >
                                <Input
                                  className={styles.disabledInput}
                                  value={dateFormatter(
                                    startTime,
                                    SHORT_TIME_WITH_TZ,
                                    timezone
                                  )}
                                  onChange={() => null}
                                  showClear={false}
                                  disabled
                                  testId="disabledStartTimeInput"
                                />
                              </Tooltip>
                            )}
                          </div>
                          <div className={styles.rowItem}>
                            <S mb={1}>
                              {t(
                                'web.admin.channel.visitor.passDetails.visitDetails.endTime'
                              )}
                            </S>
                            {isEndTimeEditable && (
                              <TimePicker
                                hideLabel
                                buttonClassName={styles.timePickerButton}
                                value={endTime}
                                onChange={updateEndTime}
                                showClear={false}
                                showIcon={false}
                                unit={TimeIntervals.QUARTER_HOUR}
                                timeZone={timezone}
                                testId="endTimeInput"
                              />
                            )}
                            {!isEndTimeEditable && (
                              <Tooltip
                                TooltipComponent={t(
                                  'web.admin.channel.visitor.passDetails.visitDetails.endTime.disabledTooltip'
                                )}
                                placement="bottom"
                              >
                                <Input
                                  className={styles.disabledInput}
                                  value={dateFormatter(
                                    endTime,
                                    SHORT_TIME_WITH_TZ,
                                    timezone
                                  )}
                                  onChange={() => null}
                                  showClear={false}
                                  disabled
                                  testId="disabledEndTimeInput"
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {visitorNotesEnabled && (
                        <div>
                          <div className={styles.row}>
                            <div className={styles.rowItem}>
                              <S mb={1}>
                                {t(
                                  'web.admin.channel.visitor.passDetails.visitDetails.noteToStaff'
                                )}
                              </S>
                              <TextArea
                                className={styles.textArea}
                                value={staffNote || ''}
                                onChange={updateStaffNote}
                                showClear={false}
                                maxLength={200}
                                showLengthIndicator
                                testId="staffNoteInput"
                              />
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.rowItem}>
                              <S mb={1}>
                                {t(
                                  'web.admin.channel.visitor.passDetails.visitDetails.noteToVisitor'
                                )}
                              </S>
                              <TextArea
                                className={styles.textArea}
                                value={visitorNote || ''}
                                onChange={updateVisitorNote}
                                showClear={false}
                                maxLength={200}
                                showLengthIndicator
                                testId="visitorNoteInput"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {multipleNotificationRecipientsEnabled && (
                        <div>
                          <div className={styles.row}>
                            <div className={styles.rowItem}>
                              <S mb={1}>
                                {t(
                                  'web.admin.channel.visitor.passDetails.visitDetails.additionalRecipients'
                                )}
                              </S>
                              <TagInput
                                tags={additionalRecipients || []}
                                setTags={updateAdditionalRecipients}
                                validateTag={validateEmail}
                                onValidationChange={
                                  handleAdditionalRecipientsErrorChange
                                }
                                placeholder={t(
                                  'shared.content.feature.visitorManagement.additional.recipients.notify.input.placeholder'
                                )}
                                maxTags={20}
                                maxTagInfo={t(
                                  'shared.content.feature.visitorManagement.additional.recipients.notify.input.maximum.email'
                                )}
                                errorText={t(
                                  'shared.content.feature.visitorManagement.additional.recipients.notify.input.error'
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              {isEditMode && (
                <div className={styles.buttonContainer}>
                  <Button
                    onClick={onEditSave}
                    testId="saveButton"
                    variant="activate-contained"
                    loading={isLoading}
                    disabled={isPristine}
                  >
                    {t('Save')}
                  </Button>
                  <Button onClick={onEditCancel} testId="cancelButton">
                    {t('Cancel')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </AdminPage>
  );
}
