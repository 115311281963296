import gql from 'graphql-tag';

import { LaneType } from 'common-types';
import { ChannelType } from '../types/ChannelType';

export type SwitchChannelResult = {
  me: {
    switchChannel: {
      primaryId: LaneType.UUID;
      secondaryId: LaneType.UUID | null;
      focusOnChannels: ChannelType[];
    };
  };
};

export const switchChannelQuery = gql`
  fragment SectionFragment on Section {
    _id
    _created
    _updated
    name
    name_l10n
    channel {
      _id
      settings {
        multiLanguageEnabled
        channelLanguages
        enabledLanguagesForMembers
      }
    }
    description
    type
    query
    tags
    backgroundImage
    backgroundColor
    icon
    iconSet
    iconWeight
    logo
    color
    filters
    sorts
    groupBy
  }

  fragment PublicUserFragment on PublicUser {
    _id
    _created
    name
    online
    lastSeen

    role {
      _id
      name
      description
      groupRoleName
    }

    profile {
      _id
      name
      phone
      email
      logo
      image
    }
  }

  fragment ChannelSettingsRequirementsFragment on RequirementsFeatureProperties {
    profilePictureRequired
    fullNameRequired
    phoneNumberRequired
    companyNameRequired
    multiLanguageEnabled
    channelLanguages
    enabledLanguagesForMembers
    contentInteractionRequired {
      _id
      content {
        _id
      }
      header
      description
    }
  }

  fragment ContentFragment on Content {
    _id
    _created
    _updated
    name
    name_l10n
    description
    description_l10n
    subtitle
    subtitle_l10n
    slug
    type
    tags
    category
    reportingGroup
    logo
    color
    geo
    isInteractive
    backgroundImage
    icon
    iconSet
    iconWeight
    backgroundColor
    liveDate
    startDate
    endDate
    unpublishDate
    interactionEndDate
    state
    version
    renderer
    externalUrl {
      _id
      url
      openingMode
    }
    signedExternalUrl
    deepLink {
      _id
      deepLinkUrl
      appStoreUrl
      googlePlayStoreUrl
    }
    features {
      _id
      type
      feature
    }
    channel {
      _id
      settings {
        requirements {
          ...ChannelSettingsRequirementsFragment
        }
        effectiveParentRequirements {
          ...ChannelSettingsRequirementsFragment
        }
        channelLanguages
        multiLanguageEnabled
        enabledLanguagesForMembers
      }
    }
    card {
      _id
    }

    integration {
      _id
      settings
      integration {
        _id
        name
        settings
        platforms
      }
    }

    generator {
      _id
    }

    info {
      _id
      rating
      viewed
      details
      hidden
      deleted
      pinned
      starred
      liked
    }

    publishedBy {
      ...PublicUserFragment
    }
  }

  query switchChannel($channelId: UUID!, $search: ChannelSearch) {
    me {
      switchChannel(channelId: $channelId, search: $search) {
        primaryId
        secondaryId
        focusOnChannels {
          _id
          _created
          _updated
          name
          description
          slug
          type
          isPublic
          isSub

          settings {
            hasPerksEnabled
            areUserTemplatesAllowed
            channelLanguages
            enabledLanguagesForMembers
            multiLanguageEnabled
          }

          profile {
            image
            logo
          }

          parent {
            _id
          }

          notices {
            ...ContentFragment
          }

          content {
            ...ContentFragment
          }

          pages {
            _id
            _created
            _updated
            _order
            name
            slug
            icon
            iconSet
            iconWeight
            label
            label_l10n
            content {
              _id
              _created
              _updated
              integration {
                _id
                integration {
                  _id
                  name
                }
              }
            }
          }

          interactions {
            _id
            _created
            _updated
            status
            startDate
            endDate
            geo
            data
            features
            version
            contentData
            user {
              _id
              name
              profile {
                _id
                image
                name
              }
            }
          }

          sections {
            ...SectionFragment
          }
        }
      }
    }
  }
`;
