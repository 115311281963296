import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  getFilteredRowModel,
  ColumnFiltersState,
  FilterFn,
  OnChangeFn,
} from '@tanstack/react-table';
import { ReactNode } from 'react';

import { useTableColumns } from './useTableColumns';
import { useTableState } from './useTableState';
import { usePersistedSetting } from '../../../hooks/usePersistedSetting';
import { PaginationProps } from '../../Pagination/Pagination';
import { BaseTableProps } from '../BaseTable';
import {
  FiltersProps,
  FilterType,
  CustomFilterType,
} from '../../Filters/Filters';
import type { DataViewControlsProps } from '../../DataViewControls/DataViewControls';
import type { BulkAction } from '../../DataViewControls/BulkActions';
import type { ExportOption } from '../../DataViewControls/ExportButton';
import type { ImportAction } from '../../DataViewControls/ImportButton';

export type VisualizationType = 'table' | 'graph';

export enum RenderType {
  text = 'text',
  link = 'link',
  currency = 'currency',
  date = 'date',
}

declare module '@tanstack/table-core' {
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

interface BaseColumn {
  key: string;
  header?: string | ReactNode;
  disableSorting?: boolean;
  disableVisibilityToggle?: boolean;
}

export interface DefaultRenderColumn extends BaseColumn {
  type: keyof typeof RenderType;
}

interface CustomRenderColumn<TDataShape> extends BaseColumn {
  renderCell: (cell: any, row: TDataShape) => ReactNode;
  renderForCSV?: (cell: any, row: TDataShape) => any;
}

export type Column<TDataShape = Record<string, any>> =
  | BaseColumn
  | DefaultRenderColumn
  | CustomRenderColumn<TDataShape>;

type RowSelection = string[];

export type Sort =
  | {
      id: string;
      direction: 'asc' | 'desc';
    }
  | Record<PropertyKey, never>;

export type PageSizeType = 10 | 25 | 50 | 100;

export type RowSelectionState = Record<string, boolean>;

export type CustomKeywordFilterFn<TDataShape> = (
  row: TDataShape,
  keyword: string
) => boolean;

export type RowAction<TDataShape> = {
  label: string;
  onClick: (row: TDataShape) => void;
  isHidden?: boolean | ((row: TDataShape) => boolean);
  isDisabled?: boolean | ((row: TDataShape) => boolean);
  tooltipText?: string | ((row: TDataShape) => string | undefined);
  icon?: string;
};

export type ColumnVisibilityState = Record<string, boolean>;
export type ColumnOrderState = string[];

type CoreTableProps<TDataShape> = {
  columns: Column<TDataShape>[];
  data: TDataShape[];
  filters?: Array<CustomFilterType<TDataShape> | FilterType>;
  disableSorting?: boolean;
  isSelectable?: boolean;
  isLoading?: boolean;
  getRowId?: (row: TDataShape) => string;
  onSortChange?: (sort: Sort) => void;
  onSelectionChange?: (selectedRows: RowSelection) => void;
  pagination?: 'server' | 'client';
  totalRows?: number;
  rowSelection?: RowSelection;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: PageSizeType) => void;
  onFilterChange?: (filters: ColumnFiltersState) => void;
  keywordFilter?: string;
  customKeywordFilterFn?: CustomKeywordFilterFn<TDataShape>;
  onKeywordFilterChange?: (keywordFilter: string) => void;
  hasKeywordFilter?: boolean;
  keywordFilterLabel?: string;
  customControls?: ReactNode;
  visualizationType?: VisualizationType;
  visualizationTypes?: VisualizationType[];
  onVisualizationTypeChange?: (visualizationType: VisualizationType) => void;
  rowActions?: RowAction<TDataShape>[];
  rowActionsLabel?: string;
  bulkActions?: BulkAction[];
  exportOptions?: ExportOption<TDataShape>[];
  importAction?: ImportAction<TDataShape>;
  onRefreshClick?: () => void;
  showColumnVisibility?: boolean;
  columnVisibility?: ColumnVisibilityState;
  onColumnVisibilityChange?: (columnVisibility: ColumnVisibilityState) => void;
  columnOrder?: ColumnOrderState;
  onColumnOrderChange?: (columnOrder: ColumnOrderState) => void;
  emptyMessage?: string;
  tableKey?: string;
  primaryActionLabel?: string;
  onPrimaryAction?: () => void;
  keywordFilterTooltip?: string;
  defaultPageSize?: PageSizeType;
  showTotalItemsCount?: boolean;
};

type ExternalTableStateProps = {
  queryStringsEnabled?: false;
  page?: number;
  pageSize?: PageSizeType;
  sorting?: Sort;
  keywordFilter?: string;
  activeFilters?: ColumnFiltersState;
};

type QueryStringTableProps = {
  queryStringsEnabled: true;
  page?: never;
  pageSize?: never;
  sorting?: never;
  keywordFilter?: never;
  activeFilters?: never;
};

export type TableProps<TDataShape = Record<string, any>> =
  | (CoreTableProps<TDataShape> & ExternalTableStateProps)
  | (CoreTableProps<TDataShape> & QueryStringTableProps);

type KeywordFilter<TDataShape> = (
  customKeywordFilterFn: CustomKeywordFilterFn<TDataShape> | undefined
) => FilterFn<TDataShape>;

export const useTable = <TDataShape>({
  columns,
  data,
  disableSorting,
  isSelectable,
  pagination,
  page: externalPage,
  totalRows,
  onPageChange,
  onSelectionChange,
  onPageSizeChange,
  onSortChange,
  onFilterChange,
  onColumnVisibilityChange,
  sorting: externalSorting,
  pageSize: externalPageSize,
  activeFilters: externalActiveFilters,
  keywordFilter: externalKeywordFilter,
  rowSelection: externalRowSelection,
  onKeywordFilterChange,
  defaultPageSize: externalDefaultPageSize,
  getRowId,
  isLoading = false,
  filters,
  hasKeywordFilter,
  keywordFilterLabel,
  customKeywordFilterFn,
  rowActions,
  rowActionsLabel,
  bulkActions,
  exportOptions,
  importAction,
  onRefreshClick,
  columnVisibility: externalColumnVisibility,
  showColumnVisibility,
  columnOrder: externalColumnOrder,
  onColumnOrderChange,
  visualizationTypes,
  visualizationType,
  onVisualizationTypeChange,
  emptyMessage,
  tableKey,
  onPrimaryAction,
  primaryActionLabel,
  keywordFilterTooltip,
  queryStringsEnabled = false,
  showTotalItemsCount = false,
}: TableProps<TDataShape>) => {
  const {
    page,
    pageSize,
    sorting,
    keywordFilter,
    activeFilters,
    rowSelection,
    setPage,
    setPageSize,
    setSorting,
    setKeywordFilter,
    setActiveFilters,
    setRowSelection,
  } = useTableState<TDataShape>({
    queryStringsEnabled,
    values: {
      page: externalPage,
      pageSize: externalPageSize,
      sorting: externalSorting,
      activeFilters: externalActiveFilters,
      keywordFilter: externalKeywordFilter,
      defaultPageSize: externalDefaultPageSize,
      rowSelection: externalRowSelection,
    },
    filters,
  });

  const [columnVisibility, setColumnVisibility] =
    usePersistedSetting<ColumnVisibilityState>({
      cacheKey: tableKey,
      settingKey: 'columnVisibility',
      initialValue:
        externalColumnVisibility ??
        columns.reduce<ColumnVisibilityState>(
          (acc, column) => ({ ...acc, [column.key]: true }),
          {}
        ),
    });

  const [columnOrder, setColumnOrder] = usePersistedSetting<ColumnOrderState>({
    cacheKey: tableKey,
    settingKey: 'columnOrder',
    initialValue: externalColumnOrder ?? columns.map(column => column.key),
  });

  const isPaginated = !!pagination;
  const isServerPaginated = pagination === 'server';

  const memoizedColumns = useTableColumns(columns, {
    disableSorting,
    isSelectable,
    filters,
    rowActions,
    rowActionsLabel,
  });

  const fuzzyFilter: KeywordFilter<TDataShape> =
    customGlobalFilterFn => (row, columnId, value, addMeta) => {
      if (customGlobalFilterFn) {
        return customGlobalFilterFn(row.original, value);
      }
      const itemRank = rankItem(row.getValue(columnId), value, {
        threshold: 3,
      });
      addMeta({
        itemRank,
      });
      return itemRank.passed;
    };

  const handleSelectionChange: OnChangeFn<
    RowSelectionState
  > = rowSelectionUpdater => {
    if (onSelectionChange) {
      if (rowSelectionUpdater instanceof Function) {
        onSelectionChange(Object.keys(rowSelectionUpdater(rowSelection)));
      } else {
        onSelectionChange(Object.keys(rowSelectionUpdater));
      }
    }
    setRowSelection(rowSelectionUpdater);
  };

  const handleSortingChange: OnChangeFn<SortingState> = sortingUpdater => {
    if (onSortChange) {
      if (sortingUpdater instanceof Function) {
        const sortingState = sortingUpdater(sorting);
        onSortChange(
          sortingState.length
            ? {
                id: sortingState[0].id,
                direction: sortingState[0].desc ? 'desc' : 'asc',
              }
            : {}
        );
      } else {
        onSortChange(
          sortingUpdater.length
            ? {
                id: sortingUpdater[0].id,
                direction: sortingUpdater[0].desc ? 'desc' : 'asc',
              }
            : {}
        );
      }
    }
    setSorting(sortingUpdater);
  };

  const table = useReactTable<TDataShape>({
    data,
    getRowId: (row, index) => {
      return getRowId ? getRowId(row) : index.toString();
    },
    columns: memoizedColumns,
    initialState: {
      ...(isPaginated && {
        pagination: {
          pageSize,
          pageIndex: page ?? 0,
        },
      }),
      columnPinning: { right: ['actions'] },
    },
    globalFilterFn: fuzzyFilter(customKeywordFilterFn),
    state: {
      sorting,
      ...(isSelectable && { rowSelection }),
      ...(isPaginated && {
        pageIndex: page,
        pageSize,
      }),
      globalFilter: keywordFilter,
      columnFilters: activeFilters,
      columnVisibility,
      columnOrder: ['select', ...columnOrder, 'actions'],
    },
    manualPagination: isServerPaginated,
    manualSorting: isServerPaginated,
    manualFiltering: isServerPaginated,
    sortDescFirst: false,
    ...(isSelectable && {
      enableRowSelection: true,
      onRowSelectionChange: handleSelectionChange,
    }),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    ...(isPaginated && { getPaginationRowModel: getPaginationRowModel() }),
    onSortingChange: handleSortingChange,
  });

  const handlePageSizeChange = (pageSize: PageSizeType) => {
    setPageSize(pageSize);
    setPage(0);
    table.setPageIndex(0);
    table.setPageSize(pageSize);
    if (onPageSizeChange) {
      onPageSizeChange(pageSize);
    }
    if (onPageChange) {
      onPageChange(0);
    }
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    table.setPageIndex(page);
    if (onPageChange) {
      onPageChange(page);
    }
  };

  const handleActiveFiltersChange = (filters: ColumnFiltersState) => {
    setActiveFilters(filters);
    setPage(0);
    table.setPageIndex(0);
    if (onFilterChange) {
      onFilterChange(filters);
    }
    if (onPageChange) {
      onPageChange(0);
    }
  };

  const handleKeywordFilterChange = (filter: string) => {
    setKeywordFilter(filter);
    if (onKeywordFilterChange) {
      onKeywordFilterChange(filter);
    }
    setPage(0);
    table.setPageIndex(0);
    if (onPageChange) {
      onPageChange(0);
    }
  };

  const handleColumnVisibilityChange = (
    columnVisibility: ColumnVisibilityState
  ) => {
    setColumnVisibility(columnVisibility);
    if (onColumnVisibilityChange) {
      onColumnVisibilityChange(columnVisibility);
    }
  };
  const handleColumnOrderChange = (columnOrder: ColumnOrderState) => {
    setColumnOrder(columnOrder);
    if (onColumnOrderChange) {
      onColumnOrderChange(columnOrder);
    }
  };
  const showPagination = isPaginated && data.length > 0;
  const showFilters = hasKeywordFilter || !!filters?.length;

  const tableProps: BaseTableProps<TDataShape> = {
    isLoading,
    table,
    rowSelection,
    emptyMessage,
  };

  const paginationProps: PaginationProps = {
    showPagination,
    perPage: pageSize,
    perPageOptions: [10, 25, 50, 100],
    page,
    total: totalRows ?? table.getFilteredRowModel().rows.length,
    onPage: handlePageChange,
    onPerPage: pageSize => handlePageSizeChange(pageSize as PageSizeType),
    loading: isLoading,
  };

  const filtersProps: FiltersProps = {
    showFilters,
    filters,
    activeFilters,
    setActiveFilters: handleActiveFiltersChange,
    cacheKey: tableKey,
    onSearchFilterChange: hasKeywordFilter
      ? handleKeywordFilterChange
      : undefined,
    searchFilterValue: keywordFilter,
    searchFilterTooltip: keywordFilterTooltip,
    searchFilterLabel: keywordFilterLabel,
  };

  const dataViewControlsProps: DataViewControlsProps<TDataShape> = {
    onRefreshClick,
    primaryActionLabel,
    onPrimaryAction,
    ...(visualizationTypes && {
      visualizationTypes,
      visualizationType,
      onVisualizationTypeChange,
    }),
    ...(bulkActions && { bulkActions, rowSelection }),
    ...(importAction && {
      data: table.getRowModel().rows.map(row => row.original),
      importAction,
    }),
    ...(exportOptions && {
      data: table.getRowModel().rows.map(row => row.original),
      exportOptions,
    }),
    ...(showColumnVisibility && {
      columnVisibility,
      setColumnVisibility: handleColumnVisibilityChange,
      columns,
      columnOrder,
      setColumnOrder: handleColumnOrderChange,
    }),
    showBorderTop: showFilters,
    ...(showTotalItemsCount && {
      totalRows: totalRows ?? table.getFilteredRowModel().rows.length,
    }),
  };

  return {
    tableProps,
    paginationProps,
    filtersProps,
    dataViewControlsProps,
  };
};
