import React from 'react';

import { Route } from 'react-router-dom';

import { routes } from 'lane-shared/domains/visitorManagement/index';
import { hasPermission } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  PERMISSION_ADMIN,
  PERMISSION_VISITOR_MANAGEMENT_LOGS_READ,
  PERMISSION_VISITOR_MANAGEMENT_LOGS_READ_ALL,
} from 'lane-shared/helpers/constants/permissions';
import { SIDE_BAR_LINKS_ORDER } from 'lane-shared/helpers/constants/sidebarLinksOrder';
import { useFlag } from 'lane-shared/hooks';
import i18n from 'localization';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { UserType } from 'lane-shared/types/User';

import { VisitorConfigurationPage } from '../pages/visitor-configuration';
import { VisitorFormsPage } from '../pages/visitor-forms';
import { VisitorLogPage } from '../pages/visitor-log';
import { VisitorPassDetailsPage } from '../pages/visitorpass-details';
import { VisitorRecurrenceDetailsPage } from '../pages/visitorrecurrencepass-details';
import { SidebarLinkType } from 'hooks/useChannelSidebarLinks';
import { ChannelType } from 'lane-shared/types/ChannelType';

enum LinkOrder {
  Log,
  Forms,
  Configuration,
  PassDetails,
}

export enum VisitorManagementPageName {
  VisitorLog = 'visitorLog',
  VisitorForms = 'visitorForms',
  VisitorConfiguration = 'visitorConfiguration',
  VisitorPassDetails = 'visitorPassDetails',
  VisitorRecurrenceDetails = 'visitorRecurrenceDetails',
}

type VisitorManagementPage = {
  key: VisitorManagementPageName;
  name: () => string;
  headerMessage: () => string;
  order: LinkOrder;
  Page: React.FC<{ channel: ChannelType | any; user: UserType }>;
  path: string;
};

export const VisitorLogsPageConfig: VisitorManagementPage = {
  key: VisitorManagementPageName.VisitorLog,
  name: () => i18n.t('web.admin.visitorManagement.navigation.visitorLog'),
  headerMessage: () =>
    i18n.t('web.admin.visitorManagement.navigation.headerMessage'),
  order: LinkOrder.Log,
  Page: VisitorLogPage,
  path: routes.visitorLog,
};

const VisitorFormsPageConfig: VisitorManagementPage = {
  key: VisitorManagementPageName.VisitorForms,
  name: () =>
    i18n.t('web.admin.visitorManagement.navigation.registrationForms'),
  headerMessage: () =>
    i18n.t('web.admin.visitorManagement.navigation.headerMessage'),
  order: LinkOrder.Forms,
  Page: VisitorFormsPage,
  path: routes.registrationForm,
};

export const VisitorConfigurationPageConfig: VisitorManagementPage = {
  key: VisitorManagementPageName.VisitorConfiguration,
  name: () =>
    i18n.t('web.admin.visitorManagement.navigation.visitorConfiguration'),
  headerMessage: () =>
    i18n.t('web.admin.visitorManagement.navigation.headerMessage'),
  order: LinkOrder.Configuration,
  Page: VisitorConfigurationPage,
  path: routes.visitorConfiguration,
};

export const VisitorPassDetailsPageConfig: VisitorManagementPage = {
  key: VisitorManagementPageName.VisitorPassDetails,
  name: () => i18n.t('web.admin.visitorManagement.navigation.passDetails'),
  headerMessage: () =>
    i18n.t('web.admin.visitorManagement.navigation.headerMessage'),
  order: LinkOrder.PassDetails,
  Page: VisitorPassDetailsPage,
  path: routes.visitorPassDetails,
};

export const VisitorRecurrenceDetailsPageConfig: VisitorManagementPage = {
  key: VisitorManagementPageName.VisitorRecurrenceDetails,
  name: () =>
    i18n.t('web.admin.visitorManagement.navigation.recurrenceDetails'),
  headerMessage: () =>
    i18n.t('web.admin.visitorManagement.navigation.headerMessage'),
  order: LinkOrder.PassDetails,
  Page: VisitorRecurrenceDetailsPage,
  path: routes.recurrenceDetails,
};

export const visitorManagementPages: Record<
  VisitorManagementPageName,
  VisitorManagementPage
> = {
  [VisitorManagementPageName.VisitorLog]: VisitorLogsPageConfig,

  [VisitorManagementPageName.VisitorForms]: VisitorFormsPageConfig,

  [VisitorManagementPageName.VisitorConfiguration]: VisitorConfigurationPageConfig,

  [VisitorManagementPageName.VisitorPassDetails]: VisitorPassDetailsPageConfig,

  [VisitorManagementPageName.VisitorRecurrenceDetails]: VisitorRecurrenceDetailsPageConfig,
};

const hasAnyPermission = (
  user: UserType,
  permissions: string[],
  channelId: string
) => {
  return (
    user.isSuperUser ||
    (channelId && hasPermission(user.roles, permissions, channelId))
  );
};

export const VisitorManagementRoutes = ({
  urlPath,
  channel,
  user,
}: {
  urlPath: string;
  channel?: ChannelType | any;
  user: UserType;
}): JSX.Element | null => {
  const visitorManagementFlag = useFlag(FeatureFlag.VisitorManagement, false);
  const settingsFlag = useFlag(
    FeatureFlag.VisitorManagementModuleSettings,
    false
  );

  if (!visitorManagementFlag) return null;

  if (!channel?.settings?.hasVisitorManagementEnabled) return null;

  const entries: [string, VisitorManagementPage][] = [];

  if (
    hasAnyPermission(
      user,
      [
        PERMISSION_VISITOR_MANAGEMENT_LOGS_READ,
        PERMISSION_VISITOR_MANAGEMENT_LOGS_READ_ALL,
      ],
      channel._id
    )
  ) {
    entries.push([VisitorManagementPageName.VisitorLog, VisitorLogsPageConfig]);
    entries.push([
      VisitorManagementPageName.VisitorPassDetails,
      VisitorPassDetailsPageConfig,
    ]);
    entries.push([
      VisitorManagementPageName.VisitorRecurrenceDetails,
      VisitorRecurrenceDetailsPageConfig,
    ]);
  }

  if (hasAnyPermission(user, [PERMISSION_ADMIN], channel._id)) {
    // these links will be later controlled by specific permissions once they are defined
    entries.push([
      VisitorManagementPageName.VisitorForms,
      VisitorFormsPageConfig,
    ]);

    if (settingsFlag) {
      entries.push([
        VisitorManagementPageName.VisitorConfiguration,
        VisitorConfigurationPageConfig,
      ]);
    }
  }

  return (
    <>
      {entries.map(([key, { Page, path }]) => {
        return (
          <Route key={key} exact path={`${urlPath}/${path}`}>
            <Page channel={channel} user={user} />
          </Route>
        );
      })}
    </>
  );
};

export const visitorManagementNavigation = (
  url: string,
  channelId: string,
  user: UserType,
  includeConfig = true
) => {
  const links: SidebarLinkType[] = [];

  if (
    hasAnyPermission(
      user,
      [
        PERMISSION_VISITOR_MANAGEMENT_LOGS_READ,
        PERMISSION_VISITOR_MANAGEMENT_LOGS_READ_ALL,
      ],
      channelId
    )
  ) {
    links.push({
      exact: true,
      route: `${url}/${VisitorLogsPageConfig.path}`,
      name: VisitorLogsPageConfig.name(),
      headerMessage: VisitorLogsPageConfig.headerMessage(),
      order: VisitorLogsPageConfig.order,
    });
  }

  if (hasAnyPermission(user, [PERMISSION_ADMIN], channelId)) {
    // these links will be later controlled by specific permissions once they are defined
    links.push({
      exact: true,
      route: `${url}/${VisitorFormsPageConfig.path}`,
      name: VisitorFormsPageConfig.name(),
      headerMessage: VisitorFormsPageConfig.headerMessage(),
      order: VisitorFormsPageConfig.order,
    });

    if (includeConfig) {
      links.push({
        exact: true,
        route: `${url}/${VisitorConfigurationPageConfig.path}`,
        name: VisitorConfigurationPageConfig.name(),
        headerMessage: VisitorConfigurationPageConfig.headerMessage(),
        order: VisitorConfigurationPageConfig.order,
      });
    }
  }

  return {
    icon: 'id-card-alt',
    iconSet: ICON_SET_FONTAWESOME,
    name: i18n.t('web.admin.visitorManagement.navigation.headerMessage'),
    isCategory: true,
    order: SIDE_BAR_LINKS_ORDER.VISITOR_MANAGEMENT,
    links,
  };
};
