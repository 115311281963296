import { errorCodes, GallagherInvalidFieldError } from 'activate-errors';

const forge = require('node-forge');

export async function getClientCertificateAndPrivateKey(
  pfxFile: File,
  pfxPassword: string
) {
  const pfxData = await readFileAsString(pfxFile);
  // get pkcs12File as ASN.1 object
  const pkcs12File = forge.asn1.fromDer(pfxData);

  try {
    // decrypt pkcs12File using the pfxPassword
    const decryptedPkcs12 = forge.pkcs12.pkcs12FromAsn1(
      pkcs12File,
      pfxPassword
    );

    const cert = decryptedPkcs12.getBags({ bagType: forge.pki.oids.certBag })[
      forge.pki.oids.certBag
    ][0];
    const pvtKey = decryptedPkcs12.getBags({
      bagType: forge.pki.oids.pkcs8ShroudedKeyBag,
    })[forge.pki.oids.pkcs8ShroudedKeyBag][0];

    const clientCertificate = forge.pki.certificateToPem(cert.cert);
    const privateKey = forge.pki.privateKeyToPem(pvtKey.key);

    return {
      clientCertificate,
      privateKey,
    };
  } catch (error: any) {
    if (error.message.includes('wrong password')) {
      throw new GallagherInvalidFieldError(
        errorCodes.gallagherInvalidCertificatePassphraseError
      );
    }

    throw new GallagherInvalidFieldError(
      errorCodes.gallagherClientCertificateValidationError
    );
  }
}

function readFileAsString(file: File): Promise<ArrayBuffer> {
  const fileReader = new FileReader();

  try {
    fileReader.readAsBinaryString(file);
  } catch (error: any) {
    if (error.message.includes("not of type 'Blob'")) {
      throw new GallagherInvalidFieldError(
        errorCodes.gallagherInvalidClientCertificateError
      );
    } else {
      throw new GallagherInvalidFieldError(
        errorCodes.gallagherClientCertificateValidationError
      );
    }
  }

  return new Promise((resolve, reject) => {
    fileReader.onloadend = () => {
      if (!fileReader.result) {
        throw new GallagherInvalidFieldError(
          errorCodes.gallagherClientCertificateValidationError
        );
      }

      resolve(fileReader.result as ArrayBuffer);
    };

    fileReader.onerror = reject;
  });
}
