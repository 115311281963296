import { DataStatus } from 'lane-web/src/domains/accessControl/components/user-access/MixedStateList/types';

import { SelectedAccessGroupsToChannel } from '../types';
import { computeAccessGroupsDiff } from './operators';

export const validateAccessGroupSelection = (
  accessGroupsToChannel: SelectedAccessGroupsToChannel[]
) => {
  for (const channel of accessGroupsToChannel) {
    const { assignAccessGroups } = computeAccessGroupsDiff(
      channel.selectedValues,
      channel.initialValues
    );

    const hasAssignedACGs =
      channel.selectedValues.some(acg => acg.status === DataStatus.selected) ||
      assignAccessGroups.length > 0;

    if (!hasAssignedACGs) {
      return false;
    }
  }

  return true;
};
