import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
} from 'libphonenumber-js';

import mapCountryCodeToCountry from './countryCodes.json';

interface Item {
  label: string;
  value: CountryCode;
}

export const dropdownItems: Item[] = getCountries()
  .flatMap(regionCode => {
    const countryName = (mapCountryCodeToCountry as { [key: string]: string })[
      regionCode
    ];

    if (!countryName) return [];

    return [
      {
        label: `${countryName} +${getCountryCallingCode(regionCode)}`,
        value: regionCode,
      },
    ];
  })
  .sort((countryA, countryB) => {
    const a = countryA.label.toUpperCase();
    const b = countryB.label.toUpperCase();

    return a < b ? -1 : 1;
  });
