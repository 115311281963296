import React, { ReactNode, useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { explodePresetContentFilters } from 'lane-shared/helpers/content';
import useSectionSearchOptionsMenu from 'lane-shared/hooks/useSectionSearchOptionsMenu';
import { MetatagFilterUI } from 'lane-shared/types/filters/MetatagFilter';
import { PresetContentFilter } from 'lane-shared/types/filters/PresetContentFilter';
import { SearchOptions } from 'lane-shared/types/filters/SearchOptions';

import TimeZoneDropdown from 'components/lane/TimeZoneDropdown';
import { H5 } from 'components/typography';

import DateRangePicker from '../form/DatePickers/DateRangePicker';
import TimeRangePicker from '../form/DatePickers/TimeRangePicker';
import DistanceSlider from '../form/DistanceSlider';
import PriceSlider from '../form/PriceSlider';
import Slider from '../form/Slider';
import Toggle from '../form/Toggle';
import MetatagFilterMenu from './MetatagFilterMenu';

import styles from './SectionSearchOptionsMenu.scss';
import { DateRangePickerButton } from 'design-system-web';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  metatags?: MetatagFilterUI[];
  filters: PresetContentFilter[] | null | undefined;
  timeZone?: string;
  searchOptions: SearchOptions | null | undefined;
  allowDisableFilter?: boolean;
  onSearchOptionsUpdated: (update: Partial<SearchOptions>) => void;
};

export default function SectionSearchOptionsMenu({
  className,
  style,
  metatags,
  filters,
  timeZone,
  searchOptions,
  allowDisableFilter = false,
  onSearchOptionsUpdated,
}: Props) {
  const { t } = useTranslation();
  const presetFilters: ReactNode[] = [];
  const presetSorts: any = [];

  const {
    filterTimeRange,
    filterLocation,
    filterQuantity,
    filterAvailable,
    filterPrice,
    filterByEventDate,
  } = explodePresetContentFilters(searchOptions?.filters);

  const {
    timeRangeChangeHandler,
    availableNowChangeHandler,
    anyTimeChangeHandler,
    quantityChangeHandler,
    distanceChangeHandler,
    dateRangePickerHandler,
    areTimeOptionsEnabled,
    isAnyTimeSelected,
    isAvailableNowSelected,
    isTimeRangeDisabled,
  } = useSectionSearchOptionsMenu({
    searchOptions: searchOptions ?? undefined,
    onSearchOptionsUpdated,
  });

  const [selectedTimeZone, setSelectedTimeZone] = useState(timeZone);
  const isNewSectionToggleEnabled = useFlag(
    FeatureFlag.SectionContentRework,
    false
  );

  if (filterLocation) {
    presetFilters.push(
      <DistanceSlider
        key={PresetContentFilter.Location}
        className={styles.locationFilter}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
        value={filterLocation.distance}
        min={0}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
        max={filterLocation.maxDistance}
        onChange={distanceChangeHandler}
      />
    );
  }

  if (filterPrice) {
    presetFilters.push(
      <PriceSlider
        label="Price Range"
        key={PresetContentFilter.FeaturePaymentPrice}
        min={filterPrice.lowestPrice}
        max={filterPrice.highPrice}
        values={{ min: filterPrice.minPrice, max: filterPrice.maxPrice }}
        onChange={({ min, max }) => {
          if (min !== undefined) {
            filterPrice.minPrice = min;
          }

          if (max !== undefined) {
            filterPrice.maxPrice = max;
          }

          onSearchOptionsUpdated({
            filters: [...searchOptions!.filters],
          });
        }}
      />
    );
  }

  if (filterQuantity) {
    presetFilters.push(
      <div
        className={styles.sliderControl}
        key={PresetContentFilter.FeatureQuantityRemaining}
      >
        <H5>{t('Quantity available')}</H5>
        <Slider
          className={styles.slider}
          min={1}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
          max={filterQuantity.maxQuantity}
          value={filterQuantity.quantity}
          onChange={quantityChangeHandler}
        />
      </div>
    );
  }

  if (areTimeOptionsEnabled) {
    presetFilters.push(
      <div key="Available anytime" className={styles.toggleRow}>
        <Toggle
          doTranslate
          value={isAnyTimeSelected}
          text="Available anytime"
          onChange={anyTimeChangeHandler}
        />
      </div>
    );
  }

  if (filterAvailable) {
    presetFilters.push(
      <div key={PresetContentFilter.AvailableNow} className={styles.toggleRow}>
        <Toggle
          doTranslate
          value={isAvailableNowSelected}
          text="Show only available now"
          onChange={availableNowChangeHandler}
        />
      </div>
    );
  }

  if (filterByEventDate && !isNewSectionToggleEnabled) {
    presetFilters.push(
      <div
        className={styles.dateRangePicker}
        key={PresetContentFilter.ByEventDate}
      >
        <DateRangePickerButton
          startDate={filterByEventDate.startDate || undefined}
          endDate={filterByEventDate.endDate || undefined}
          startDateLabel="web.admin.channel.content.layout.editor.components.sectionSearchOptionsMenu.eventStart"
          endDateLabel="web.admin.channel.content.layout.editor.components.sectionSearchOptionsMenu.eventEnd"
          className={styles.dateRangePicker}
          onChange={dateRangePickerHandler}
        />
      </div>
    );
  }

  if (filterTimeRange) {
    if (
      filters?.includes(PresetContentFilter.FeatureReservableAvailableMinutes)
    ) {
      presetFilters.push(
        <div
          className={styles.timeControl}
          key={PresetContentFilter.FeatureReservableAvailableMinutes}
        >
          <TimeZoneDropdown
            value={selectedTimeZone || null}
            onValueChange={tz => setSelectedTimeZone(tz)}
          />
          <br />
          <TimeRangePicker
            disabled={isTimeRangeDisabled}
            value={filterTimeRange}
            slotSize={30}
            timeZone={selectedTimeZone}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: { startDate: Date; endDate: Date; })... Remove this comment to see the full error message
            onChange={timeRangeChangeHandler}
          />
        </div>
      );
    }

    if (filters?.includes(PresetContentFilter.FeatureReservableAvailableDays)) {
      presetFilters.push(
        <div
          className={styles.timeControl}
          key={PresetContentFilter.FeatureReservableAvailableDays}
        >
          <DateRangePicker
            disabled={isTimeRangeDisabled}
            timeZone={selectedTimeZone}
            startDate={filterTimeRange?.startDate}
            endDate={filterTimeRange?.endDate}
            minRangeSize={1}
            maxRangeSize={30}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: { startDate: Date; endDate: Date; })... Remove this comment to see the full error message
            onChange={timeRangeChangeHandler}
          />
        </div>
      );
    }
  }

  if (!searchOptions) {
    return null;
  }

  return (
    <div
      className={cx(styles.SectionSearchOptionsMenu, className)}
      style={style}
    >
      {presetFilters}
      {presetSorts}

      <MetatagFilterMenu
        filters={searchOptions.metatagFilters}
        metatags={metatags}
        allowDisableFilter={allowDisableFilter}
        onFiltersUpdated={metatagFilters => {
          onSearchOptionsUpdated({ metatagFilters });
        }}
      />
    </div>
  );
}
