import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { imageUrl } from 'lane-shared/helpers/formatters';
import { getDisplayProfileName } from 'lane-shared/helpers/getDisplayName';
import { PublicUserType } from 'lane-shared/hooks/useMyPublicUsersQuery';
import { SecurityRuleTypeEnum } from 'lane-shared/types/properties/PropertySecurity';

import { Button } from 'components/general';
import CircleListView from 'components/lane/CircleListView';
import { Flex } from 'components/layout';
import { H5, XS } from 'components/typography';

import SelectUserModal from './SelectUserModal';
import { useModalContext } from 'contexts/ModalContext';

import styles from './SelectUser.scss';

type Props = {
  buttonPlaceholder?: string;
  value?: {
    users: PublicUserType[];
  };
  maxCapacity: number;
  required?: boolean;
  onChange: ({ users }: { users: PublicUserType[] }) => void;
  maxCapacityAlertTitle?: string;
  maxCapacityToastTitle?: string;
  groupRoles: {
    type: SecurityRuleTypeEnum;
    value: string | null;
  };
  disabled?: boolean;
};

export default function SelectUser({
  buttonPlaceholder = 'Select User(s)',
  value = {
    users: [],
  },
  onChange,
  maxCapacity,
  groupRoles,
  disabled,
}: Props) {
  const attendees = value?.users || [];
  const { t } = useTranslation();
  const { showModal, closeModal } = useModalContext();

  function updateUsers(users: PublicUserType[]) {
    onChange({ users });
    closeModal();
  }

  function removeUser(user: PublicUserType) {
    onChange({
      users: attendees.filter(attendee => attendee._id !== user._id),
    });
  }

  const groupIds = useMemo(() => {
    if (
      groupRoles?.type === SecurityRuleTypeEnum.GroupRole &&
      groupRoles?.value
    ) {
      return [groupRoles.value];
    }

    return null;
  }, [groupRoles]);

  function openUsersModal() {
    showModal(
      <SelectUserModal
        modalTitle="Select User(s)"
        actionButtonTitle="Select User(s)"
        onClose={closeModal}
        updateGuestList={updateUsers}
        attendees={attendees}
        maxCapacity={maxCapacity}
        groupRoles={groupIds}
        isAllUsersSelected={groupRoles?.type === 'All'}
      />
    );
  }

  return (
    <div>
      <Flex justify="center">
        <Button onClick={openUsersModal} disabled={disabled}>
          {t(buttonPlaceholder)}
        </Button>
      </Flex>
      {attendees.length ? (
        <Flex
          direction="column"
          align="center"
          m={[5, 0]}
          gap={4}
          className={styles.guestList}
        >
          {attendees.map(attendee => (
            <CircleListView
              key={attendee._id}
              image={imageUrl(attendee.profile.image)}
              logo={imageUrl(attendee.profile.logo)}
              name={getDisplayProfileName(attendee)}
              className={styles.guest}
              onClick={() => removeUser(attendee)}
              isCancellable
            >
              <H5 bold>{getDisplayProfileName(attendee)}</H5>
              <XS variant="secondary">{attendee.profile.email}</XS>
            </CircleListView>
          ))}
        </Flex>
      ) : null}
    </div>
  );
}
