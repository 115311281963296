export function removeLocalePrefixOnAmount(amount: string): string {
  const matchAllCharactersBeforeDollarSign = /.+?(?=\$)/g;

  return amount.replace(matchAllCharactersBeforeDollarSign, '');
}

export function toAmountNumber(amount: string): number {
  // matches anything that is not a digit, period (for decimals) or - for negative
  const matcher = /[^\d.-]/g;

  return Number(amount.replace(matcher, ''));
}
