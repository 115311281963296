import React from 'react';

import { Button } from 'components';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { Flatfile, FlatfileRecord } from '@flatfile/sdk';

import { getClient } from 'lane-shared/apollo';
import { isRequired } from 'lane-shared/domains/visitorManagement/features/VisitorManagement';
import { VisitorType } from 'lane-shared/domains/visitorManagement/types/VisitorType';
import { FlatfileToken } from 'lane-shared/graphql/flatfile';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';
import { VisitorManagementFields } from 'lane-shared/types/upload/VisitorManagementFields';

import styles from '../content-blocks/AddVisitorsBlock.scss';

const isEmpty = (val: any) => val === null || val === undefined || val === '';

export function VisitorInviteBulkUpload({
  properties,
  handleBulkAdd,
  isPreview = false,
}: {
  properties: VisitorManagementFeatureProperties;
  handleBulkAdd: (visitors: VisitorType[]) => void;
  isPreview?: boolean;
}): React.ReactElement {
  const { t } = useTranslation();
  const getFlatfilePortal = (): string => {
    if (
      isRequired(properties.emailSetting) &&
      isRequired(properties.phoneSetting) &&
      isRequired(properties.companySetting)
    ) {
      return VisitorManagementFields.ALL_FIELDS_REQUIRED;
    }

    if (
      isRequired(properties.emailSetting) &&
      isRequired(properties.phoneSetting)
    ) {
      return VisitorManagementFields.NAME_EMAIL_CELL_REQUIRED;
    }

    if (
      isRequired(properties.emailSetting) &&
      isRequired(properties.companySetting)
    ) {
      return VisitorManagementFields.NAME_EMAIL_COMPANY_REQUIRED;
    }

    if (
      isRequired(properties.phoneSetting) &&
      isRequired(properties.companySetting)
    ) {
      return VisitorManagementFields.NAME_CELL_COMPANY_REQUIRED;
    }

    if (isRequired(properties.emailSetting)) {
      return VisitorManagementFields.NAME_EMAIL_REQUIRED;
    }

    if (isRequired(properties.phoneSetting)) {
      return VisitorManagementFields.NAME_CELL_REQUIRED;
    }

    if (isRequired(properties.companySetting)) {
      return VisitorManagementFields.NAME_COMPANY_REQUIRED;
    }

    return VisitorManagementFields.NAME_REQUIRED;
  };

  const flatfilePortal = getFlatfilePortal();

  const { data } = useQuery(FlatfileToken, {
    // @ts-ignore (known TS issue re apollo client)
    client: getClient(),
    fetchPolicy: 'no-cache',
    variables: {
      portal: flatfilePortal,
    },
  });
  const jwtToken: string | null | undefined = data?.flatfileToken?.token;

  const mapBulkRecords = (record: FlatfileRecord): VisitorType => {
    return {
      email: record.data.visitorEmail as string | undefined,
      firstName: record.data.visitorFirstName as string | undefined,
      lastName: record.data.visitorLastName as string | undefined,
      company: record.data.visitorCompany as string | undefined,
      phone:
        !isEmpty(record.data.visitorCountryCode) ||
        !isEmpty(record.data.visitorCellNumber)
          ? `+${record.data.visitorCountryCode}${record.data.visitorCellNumber}`
          : '',
    };
  };

  const importData = () => {
    Flatfile.requestDataFromUser({
      embedId: data?.flatfileToken?.embedId,
      token: jwtToken || undefined,
      onData(chunk, next) {
        const records: FlatfileRecord[] = chunk.records;
        const recordsToAdd = records.map(mapBulkRecords);

        handleBulkAdd(recordsToAdd);

        next();
      },
      onComplete() {},
      onError({ error }) {
        window.Toast.show(error.userMessage, 5000);
      },
    }).catch(error => {
      window.Toast.show(error.message, 5000);
    });
  };

  const handleOnClick = () => {
    importData();
  };

  return (
    <Button
      onClick={handleOnClick}
      className={styles.bulkUploadButton}
      disabled={isPreview}
      testId="inviteBulkUpload"
    >
      {t('web.content.feature.visitorManagement.visitorInvite.bulkUpload')}
    </Button>
  );
}
