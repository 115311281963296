import { TargetingRule } from '../../../types/audiences/TargetingRule';

export const targetingRuleCollectables = (targetingRule?: TargetingRule) => {
  if (!targetingRule) return {};

  return {
    id: targetingRule.id,
    name: targetingRule.name,
    groupRoleId: targetingRule.groupRoleId,
  };
};
