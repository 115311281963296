import currencyCodes from 'currency-codes';

import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from '../../../types/features/FeatureNameEnum';
import { PaymentProviderEnum } from '../../../types/payment/PaymentProviderEnum';
import { PaymentTypeEnum } from '../../../types/payment/PaymentTypeEnum';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';
import PaymentFeaturePaymentAmount from './types/PaymentFeaturePaymentAmount';
import './types/PaymentFeaturePlacePayData';
import './types/PaymentFeatureRule';
import './types/PaymentFeatureStripeData';
import PaymentFeatureWeekRule from './types/PaymentFeatureWeekRule';

const Payment: FeatureDefinition = {
  name: FeatureNameEnum.Payment,
  friendlyName: 'web.content.features.payment.friendlyName',
  requiresInteraction: true,
  description: 'web.content.features.payment.description',
  properties: {
    taxes: {
      friendlyName: 'Taxes (%)',
      description: 'Taxes (%) to apply to the total amount.',
      type: 'Option',
      default: [],
      editable: true,
      isArray: true,
    },
    paymentProvider: {
      friendlyName: 'Payment type',
      description: 'What kind payment methods are accepted for this payment?',
      type: 'String',
      default: PaymentProviderEnum.Cash,
      // todo: we will not surface this UI/UX yet.
      editable: false,
      showControl: false,
      isArray: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'In',
          value: Object.values(PaymentProviderEnum),
        },
      ],
    },
    amounts: {
      friendlyName: 'Payment Amounts',
      description: 'Setup different accepted payment types and amounts here',
      type: 'PaymentFeaturePaymentAmount',
      isArray: true,
      default: [PaymentFeaturePaymentAmount.default],
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'ArrayMin',
          value: 1,
        },
      ],
    },
    weekTimeRulesEnabled: {
      type: 'Boolean',
      default: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    weekTimeRules: {
      type: 'PaymentFeatureWeekRule',
      default: PaymentFeatureWeekRule.default,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    rules: {
      type: 'PaymentFeatureRule',
      isArray: true,
      default: [],
    },
    data: {
      type: 'JSON',
      showControl: false,
      friendlyName: 'Additional Data',
      description: 'Stores additional data for specific kinds of payments.',
      default: null,
    },
  },
  interactionData: {
    type: {
      friendlyName: 'Payment Type',
      description: 'The kind of payment type used to create this interaction',
      type: 'String',
      default: PaymentTypeEnum.Cash,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'In',
          value: Object.values(PaymentTypeEnum),
        },
      ],
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    amount: {
      friendlyName: 'Payment Amount',
      description: 'The total amount that was charged for this payment.',
      type: 'Currency',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    overriddenAmount: {
      friendlyName: 'Normal Payment Amount',
      description:
        'The total amount that would have been charged for this payment, if it was not overriden.',
      type: 'Currency',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    quote: {
      friendlyName: 'Original Quote',
      description: 'The original quote that was used to generate this payment.',
      type: 'JSON',
      forDisplay: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    currency: {
      friendlyName: 'Payment Currency',
      description: 'The currency this payment was made in.',
      type: 'String',
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'In',
          value: currencyCodes.codes(),
        },
      ],
    },
    overridePrice: {
      friendlyName: 'Price Override',
      description: 'The purchase price was overridden from its usual price.',
      type: 'Boolean',
      default: false,
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    paymentAccountId: {
      forDisplay: false,
      friendlyName: 'Payment Account Id',
      description:
        'The Payment account that was used to purchase this content.',
      type: 'String',
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.Source,
          },
        ],
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    receiptEmail: {
      friendlyName: 'Receipt Email',
      description:
        'The email the receipt was sent to from the successful transaction.',
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
      type: 'Email',
    },
    stripeData: {
      forDisplay: false,
      friendlyName: 'Stripe Payment Data',
      type: 'PaymentFeatureStripeData',
      description: 'was sent to from the successful transaction.',
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    placePayData: {
      forDisplay: false,
      friendlyName: 'Place Pay Data',
      description: 'Data for a place pay transaction.',
      type: 'PaymentFeaturePlacePayData',
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    transactionId: {
      forDisplay: false,
      friendlyName: 'Payment transaction id',
      description:
        'The transaction id for the payment attached to this interaction',
      type: 'String',
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
  },
};

export default Payment;
