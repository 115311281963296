/**
 * Takes any data object and recursively retrieves any column name with a matching _l10n column
 * Returns an array of column names
 *
 * Example:
 *
 * getColumnNamesHavingL10nSuffix(
 *  {
 *    name: 'name EN',
 *    name_l10n: {
 *      'en-US': 'name EN',
 *       'fr-FR': 'name FR'
 *    },
 *    nestedThing: [
 *      {
 *        columnName: 'thing EN',
 *        columnName_l10n: {
 *          'en-US': 'thing EN'
 *          'fr-FR': 'thing FR'
 *        }
 *      }
 *    ]
 *  })
 *
 * returns:
 *  ["name", "nestedThing[0].columnName"]
 */

// @ts-expect-error ts-migrate(7023) FIXME: 'getColumnNamesHavingL10nSuffix' implicitly has return type... Remove this comment to see the full error message
export function getColumnNamesHavingL10nSuffix(
  obj: any,
  currentPath = '',
  paths: string[] = []
) {
  if (Array.isArray(obj)) {
    return obj.map((itemObject, index) =>
      getColumnNamesHavingL10nSuffix(
        itemObject,
        `${currentPath}[${index.toString()}]`,
        paths
      )
    );
  }

  for (const key in obj) {
    if (key.endsWith('l10n')) {
      const primaryKey = key.replace(`_l10n`, '');
      const newPath = currentPath ? `${currentPath}.${primaryKey}` : primaryKey;

      paths.push(newPath);
    }

    if (typeof obj[key] === 'object') {
      const newPath = currentPath ? `${currentPath}.${key}` : key;

      getColumnNamesHavingL10nSuffix(obj[key], newPath, paths);
    }
  }

  return paths;
}
