import { Liquid, TokenKind } from 'liquidjs';

export const defaultLiquidEngine = new Liquid();

export const BLACKLISTED_TEMPLATE_VARIABLE_NAMES = ['_id'];

export class LiquidEngineService {
  constructor(protected liquidEngine = defaultLiquidEngine) {}

  getTemplateVariableNameWithBlacklist(
    templateValue: string | null,
    blacklist: Array<string> = BLACKLISTED_TEMPLATE_VARIABLE_NAMES
  ) {
    const templateVariablename = this.getTemplateVariableName(templateValue);

    if (blacklist.includes(templateVariablename)) {
      throw new Error(
        `Template variable name "${templateVariablename}" is not allowed.`
      );
    }

    return templateVariablename;
  }

  getTemplateVariableName(templateValue: string | null) {
    if (!templateValue) {
      return '';
    }

    const templates = this.liquidEngine.parse(templateValue);
    const variableTokens = templates.filter(
      tpl => tpl.token.kind === TokenKind.Output
    );

    if (variableTokens.length === 0) {
      return '';
    }

    if (variableTokens.length > 1) {
      throw new Error(
        `Multiple template variables not supported. Detected variables: ${variableTokens
          .map(token => token.token.getText())
          .join(', ')}`
      );
    }

    const variableOutput = variableTokens[0];

    const templateVariableNameWithoutBrackets = (variableOutput.token as any)
      .content;

    return templateVariableNameWithoutBrackets;
  }
}
