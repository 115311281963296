import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import { Key } from 'ts-key-enum';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import byName from 'lane-shared/helpers/sort/byName';

import MiniContentCard from 'components/cards/MiniContentCard';

import styles from './TemplateCategorySelector.scss';
import { useTranslation } from 'react-i18next';

function TemplateCards({
  templates,
  onTemplateSelected,
  selectedTemplate,
}: any) {
  if (!templates) return null;

  return (
    <div className={styles.contentCardsContainer}>
      {templates
        .sort((a: any, b: any) => byName(a, b))
        .map((template: any) => {
          const isSelectedTemplate = template === selectedTemplate;

          return (
            <div
              key={template._id}
              onClick={() => onTemplateSelected(template)}
              className={styles.contentCard}
              role="button"
              tabIndex={0}
              onKeyPress={e =>
                e.key === Key.Enter && onTemplateSelected(template)
              }
            >
              <MiniContentCard
                isSelected={isSelectedTemplate}
                content={template}
              />
            </div>
          );
        })}
    </div>
  );
}

export default function TemplateCategorySelector({
  templates,
  onTemplateSelected,
  selectedTemplate,
}: any) {
  const [selectedCategory, setSelectedCategory] = useState<null | string>(null);
  const { t } = useTranslation();

  function toggleCategoryMenu(category: any) {
    setSelectedCategory(selectedCategory === category ? null : category);
  }

  const categories = Object.keys(templates);

  if (categories.length === 1) {
    return (
      <TemplateCards
        onTemplateSelected={onTemplateSelected}
        templates={templates[categories[0]]}
        selectedTemplate={selectedTemplate}
      />
    );
  }

  return (
    <>
      {categories.sort().map(category => {
        const isActive = selectedCategory === category;

        return (
          <div
            data-test="category"
            key={category}
            className={styles.categoryHeaderContainer}
          >
            <div
              className={styles.categoryHeader}
              onClick={() => toggleCategoryMenu(category)}
              role="button"
              tabIndex={0}
              onKeyPress={e =>
                e.key === Key.Enter && toggleCategoryMenu(category)
              }
            >
              <h5>{t(category)}</h5>
              <Icon
                name={isActive ? 'chevron-up' : 'chevron-down'}
                set={ICON_SET_FONTAWESOME}
                className={styles.chevronIcon}
              />
            </div>
            {isActive && selectedCategory && (
              <TemplateCards
                onTemplateSelected={onTemplateSelected}
                templates={templates[selectedCategory]}
                selectedTemplate={selectedTemplate}
              />
            )}
          </div>
        );
      })}
    </>
  );
}
