import React from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import { CSSProperties } from '@material-ui/styles';

import { routes } from 'lane-shared/config';
import { BORDER_INTERACTION_COLORS } from 'lane-shared/helpers/constants/interactions';
import { groupInteractionsByStatus } from 'lane-shared/helpers/features';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import { Flex } from 'components/layout';
import { L } from 'components/typography';

import { useIsAdminView } from '../../hooks';
import UserContentInteractionCard from '../lane/UserContentInteractionCard';

import styles from './ContentUCIList.scss';
import { useTranslation } from 'react-i18next';

const integrationsWithGroupByStatusDisabled = [
  IntegrationProviderEnum.ShopifyBurst,
];

export default function ContentUCIList({
  className,
  style,
  items,
  statusesFeature,
  integrationProvider,
}: any) {
  const [, channelSlug] = useIsAdminView();
  const { t } = useTranslation();
  const enableGroupByStatus =
    statusesFeature &&
    !integrationsWithGroupByStatusDisabled.includes(integrationProvider);

  const groups = enableGroupByStatus ? groupInteractionsByStatus(items) : null;

  return (
    <Flex
      direction={enableGroupByStatus ? 'column' : 'row'}
      className={cx(styles.ContentUCIList, className)}
      style={style}
    >
      {groups
        ? groups.map((group, index) => {
            if (group.data.length === 0) {
              return null;
            }

            return (
              <Flex direction="column" key={index} mb={5}>
                <L mb={4} bold className={styles.groupTitle}>
                  <span
                    className={styles.statusColor}
                    style={
                      {
                        '--status-color':
                          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                          BORDER_INTERACTION_COLORS[group.title],
                      } as CSSProperties
                    }
                  />
                  {t(group.title)}
                </L>
                <div className={styles.group} key={group.title}>
                  {group.data.map(interaction => (
                    <Link
                      to={routes.channelAdminInteraction
                        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                        .replace(':id', channelSlug)
                        .replace(':interactionId', interaction._id)}
                      key={interaction._id}
                    >
                      <UserContentInteractionCard
                        forAdmin
                        interaction={interaction}
                        className={styles.interactionCard}
                      />
                    </Link>
                  ))}
                </div>
              </Flex>
            );
          })
        : items.map((interaction: any) => (
            <Link
              to={routes.channelAdminInteraction
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                .replace(':id', channelSlug)
                .replace(':interactionId', interaction._id)}
              key={interaction._id}
            >
              <UserContentInteractionCard
                forAdmin
                className={styles.interactionCard}
                interaction={interaction}
              />
            </Link>
          ))}
    </Flex>
  );
}
