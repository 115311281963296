import React, { useContext, useEffect, useMemo, useState } from 'react';

import { cloneDeep } from 'lodash';

import { useTranslation } from 'react-i18next';

import { AppContext } from 'lane-shared/contexts';
import { BLOCK_FOR } from 'lane-shared/helpers/constants/blocks';
import {
  SubtitleOptions,
  isSubtitleCustom,
  isSubtitleDate,
} from 'lane-shared/helpers/content/subtitleHelpers';
import { ContentForCards } from 'lane-shared/types/content/ContentForCards';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { MultiLanguageWrapper } from 'components/general/MultiLanguageWrapper';
import IconBatchVariableInfo from 'components/lane/IconBatchVariableInfo';
import {
  StandardContentCard,
  HorizontalContentCard,
  HeroContentCard,
} from 'components/cards';
import { getValidationMessages } from 'lane-shared/helpers';
import { Box, Flex } from 'components/layout';
import { Input } from 'components/form';
import ButtonStrip from 'components/general/ButtonStrip';
import { H2, H5 } from 'components/typography';
import { ValidationError } from 'yup';
import { LanguagePreviewSelector } from 'components/general/LanguagePreviewSelector';

import styles from './ContentCardPreview.scss';
import { useFlag, useMultiLanguage } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

type Props = {
  content: ContentForCards;
  forCards?: string[];
  onContentUpdated: (data: any) => void;
  channel?: ChannelType | null;
  validation?: ValidationError[] | ValidationError | null;
  isGenerator?: boolean;
};

export default function ContentCardPreview({
  content,
  forCards = [BLOCK_FOR.CONTENT],
  onContentUpdated = () => null,
  channel,
  validation,
  isGenerator,
}: Props) {
  const { t } = useTranslation();
  const { cards } = useContext(AppContext);
  const { translate } = useMultiLanguage();
  const channelDefaultLanguage = channel?.settings?.multiLanguageEnabled
    ? (channel?.settings?.channelLanguages?.primary as string)
    : '';
  const [previewLanguage, setPreviewLanguage] = useState<string>(
    channelDefaultLanguage
  );

  useEffect(() => {
    setPreviewLanguage(channelDefaultLanguage);
  }, [channelDefaultLanguage]);

  const isMLSLanguagePreviewSelectorEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportLanguagePreviewSelector,
    false
  );
  const shouldDisplayLanguagePreviewSelector =
    channel?.settings?.multiLanguageEnabled &&
    isMLSLanguagePreviewSelectorEnabled;

  const buttons = useMemo(
    () =>
      cards
        .filter(card => forCards.includes(card.for))
        .map(card => ({
          name: card?.name,
          value: card?._id,
        })),
    [forCards]
  );

  const selectedCard = cards.find(card => card._id === content?.card?._id);
  const selectedCardName = selectedCard?.name;

  let subtitleOptionsDisplayText = Object.keys(
    SubtitleOptions
  ) as (keyof typeof SubtitleOptions)[];

  if (!content.isEvent) {
    // if content is not an event, remove the subtitle Date option
    subtitleOptionsDisplayText = subtitleOptionsDisplayText.filter(
      subtitleDisplayText =>
        !isSubtitleDate(SubtitleOptions[subtitleDisplayText])
    );
  }

  const translatedSubtitleOptions = subtitleOptionsDisplayText.map(
    subtitleDisplayText => ({
      name: t(
        `web.admin.content.draftContent.subtitleOptions.${subtitleDisplayText.toLowerCase()}`
      ),
      value: SubtitleOptions[subtitleDisplayText],
    })
  );

  let contentForPreview: ContentForCards | undefined;

  if (shouldDisplayLanguagePreviewSelector && previewLanguage) {
    contentForPreview = translate({
      model: cloneDeep(content),
      previewLanguage,
      columns: ['name', 'description', 'subtitle'],
    });
  }

  return (
    <section className={styles.ContentCardPreview}>
      <H2 className={styles.header} mb={4}>
        {t('Card preview')}
      </H2>
      {shouldDisplayLanguagePreviewSelector && (
        <Box m={[1, 0]}>
          <LanguagePreviewSelector
            previewLanguage={previewLanguage}
            setPreviewLanguage={setPreviewLanguage}
          />
        </Box>
      )}
      {selectedCardName === 'Standard Card' && (
        <StandardContentCard
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentForCards' is not assignable to type '... Remove this comment to see the full error message
          content={contentForPreview ?? content}
          mode="preview"
          className={styles.card}
        />
      )}
      {selectedCardName === 'Hero Card' && (
        <HeroContentCard
          content={contentForPreview ?? content}
          mode="preview"
          className={styles.card}
        />
      )}
      {selectedCardName === 'Horizontal Card' && (
        <HorizontalContentCard
          content={contentForPreview ?? content}
          mode="preview"
          className={styles.card}
        />
      )}
      <div className={styles.formField}>
        <label>{t('Card layout')}</label>
        <ButtonStrip
          buttons={buttons}
          selected={content?.card?._id}
          onClick={_id => onContentUpdated({ card: { _id } })}
          className={styles.buttonStrip}
        />
      </div>
      <div className={styles.formField}>
        <label>{t(`Type of subtitle`)}</label>
        <ButtonStrip
          className={styles.buttonStrip}
          buttons={translatedSubtitleOptions}
          selected={
            isSubtitleCustom(content.subtitle)
              ? ''
              : (content.subtitle as '\t' | null)
          }
          onClick={value => onContentUpdated({ subtitle: value })}
        />
      </div>
      {isSubtitleCustom(content.subtitle) && (
        <MultiLanguageWrapper
          model={content}
          channel={channel!}
          column="subtitle"
        >
          {({ labelMaker, onChangeUpdates, valueGetter, isPrimary }) => (
            <div className={styles.formField}>
              <H5>
                {labelMaker({
                  label: t(
                    'web.admin.content.draftContent.info.generalInfo.customSubtitle.inputLabel'
                  ),
                  required: true,
                })}
              </H5>
              <Flex>
                <Input
                  value={valueGetter()}
                  maxLength={34}
                  onChange={subtitle => {
                    const updates = onChangeUpdates(subtitle);

                    onContentUpdated(updates);
                  }}
                  error={
                    isPrimary
                      ? getValidationMessages(validation, 'subtitle')
                      : null
                  }
                  testId={isPrimary ? 'subtitle' : 'subtitle_l10n'}
                  showClear={false}
                  placeholder={t(
                    'web.admin.content.draftContent.info.generalInfo.customSubtitle.inputPlaceholder'
                  )}
                  className={styles.textInput}
                />
                {isGenerator && <IconBatchVariableInfo />}
              </Flex>
            </div>
          )}
        </MultiLanguageWrapper>
      )}
    </section>
  );
}
