export default function getSimpleMetatagValueByKey(
  metatags: any[],
  key: string,
  type: string
) {
  const matchingMetatag = metatags?.find(
    (metaTag: { metatag: { properties: { key: string } } }) => {
      return metaTag.metatag?.properties?.key === key;
    }
  );

  return matchingMetatag?.value?.[type];
}
