// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'is-u... Remove this comment to see the full error message
import isUrl from 'is-url';
import { Transforms, Editor, Range } from 'slate';

import { RichTextBlockEnum } from 'lane-shared/properties/baseTypes/RichText';

export function insertLink(editor: any, url: any) {
  if (editor.selection) {
    wrapLink(editor, url);
  }
}

export function isLinkActive(editor: any) {
  const [link] = Editor.nodes(editor, {
    match: n => n.type === RichTextBlockEnum.Link,
  });

  return !!link;
}

export function unwrapLink(editor: any) {
  Transforms.unwrapNodes(editor, {
    match: n => n.type === RichTextBlockEnum.Link,
  });
}

export function wrapLink(editor: any, url: any) {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
}

export default function withLinks(editor: any) {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element: any) => {
    return element.type === RichTextBlockEnum.Link ? true : isInline(element);
  };

  editor.insertText = (text: any) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data: any) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
}
