import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { DocumentType } from 'lane-shared/types/DocumentType';
import { PropertyType } from 'lane-shared/types/properties/Property';
import { SecurityRuleTypeEnum } from 'lane-shared/types/properties/PropertySecurity';

import IconButton from '../../../general/IconButton';
import FieldSecurityItem from './FieldSecurityItem';

import styles from './FieldSecurity.scss';

type OwnProps = {
  channel: DocumentType;
  field: PropertyType;
  onFieldUpdated: (field: Partial<PropertyType>) => void;
  className?: string;
  style?: React.CSSProperties;
};

type Props = OwnProps;

export default function FieldSecurity({
  className,
  style,
  channel,
  field,
  onFieldUpdated = () => null,
}: Props) {
  const { t } = useTranslation();

  function addSecurity(type: 'create' | 'read' | 'update' | 'delete') {
    const secure = { ...field.secure };

    if (!secure[type]) {
      secure[type] = [];
    }

    secure[type]!.push({
      _id: uuid(),
      type: SecurityRuleTypeEnum.Owner,
    });

    onFieldUpdated({ secure });
  }

  function renderSecurity(operation: any) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return field.secure?.[operation]?.map((security: any) => (
      <FieldSecurityItem
        key={security._id}
        channel={channel}
        field={field}
        operation={operation}
        security={security}
        onFieldUpdated={onFieldUpdated}
      />
    ));
  }

  return (
    <section className={cx(styles.FieldSecurity, className)} style={style}>
      <section>
        <h1>
          <span>{t('Create')}</span>
          <IconButton icon="plus" onClick={() => addSecurity('create')} />
        </h1>
        <ul>{renderSecurity('create')}</ul>
      </section>

      <section>
        <h1>
          <span>{t('Read')}</span>
          <IconButton icon="plus" onClick={() => addSecurity('read')} />
        </h1>
        <ul>{renderSecurity('read')}</ul>
      </section>

      <section>
        <h1>
          <span>{t('Update')}</span>
          <IconButton icon="plus" onClick={() => addSecurity('update')} />
        </h1>
        <ul>{renderSecurity('update')}</ul>
      </section>

      <section>
        <h1>
          <span>{t('Delete')}</span>
          <IconButton icon="plus" onClick={() => addSecurity('delete')} />
        </h1>
        <ul>{renderSecurity('delete')}</ul>
      </section>
    </section>
  );
}
