import { commonInteractionQueries } from 'lane-shared/helpers/constants';
import { createUserContentInteraction } from 'lane-shared/helpers/content';
import {
  ContentQueryInteraction,
  getContent,
} from 'lane-shared/hooks/useContentQuery';
import { ContentType } from 'lane-shared/types/content/Content';
import { PaymentTypeEnum } from 'lane-shared/types/payment/PaymentTypeEnum';
import * as Sentry from '@sentry/browser';
import { FeatureQuoteType } from 'lane-shared/types/payment/FeatureQuoteType';

type PaymentId =
  | {
      transactionId: string;
      subscriptionId?: never;
    }
  | {
      transactionId?: never;
      subscriptionId: string;
    };

export async function processUciSubmission(
  interaction: ContentQueryInteraction,
  contentId: ContentType['_id'],
  primaryId: string,
  paymentQuote: FeatureQuoteType,
  paymentId: PaymentId,
  errorMessageWithRefund?: boolean
) {
  try {
    // fetch the actual content
    const content = await getContent(contentId, false);

    await createUserContentInteraction({
      refetchQueries: commonInteractionQueries,
      content,
      interaction: {
        ...interaction,
        features: {
          ...interaction?.features,
          Payment: {
            ...interaction?.features?.Payment,
            type: PaymentTypeEnum.Cash,
            quote: paymentQuote,
            ...(paymentId.transactionId
              ? { transactionId: paymentId.transactionId }
              : { subscriptionId: paymentId.subscriptionId }),
          },
        },
      },
      meChannelId: primaryId,
    });
  } catch (err) {
    Sentry.captureException(
      'BPError - UCI failed after transaction processed',
      {
        contexts: {
          uciInfo: {
            contentId,
            channelId: primaryId,
            error: err,
          },
        },
      }
    );

    if (errorMessageWithRefund) {
      throw new Error('abp.errors.payment.refundEnabled');
    }

    throw new Error(`abp.errors.payment.partialTransaction`);
  }
}
