import gql from 'graphql-tag';

export type GetUnitQueryResponse = {
  unit: {
    id: string;
    name: string;
    description: string;
    floors: {
      id: string;
      name: string;
    }[];
    people: {
      id: string;
      name: string;
      email: string;
      userId: string | undefined;
      user: {
        _id: string;
        name: string;
        profile: {
          email: string;
        };
        lastSeen: Date;
        _created: Date;
      };
    }[];
    tenants: {
      id: string;
      name: string;
      type: string;
      logo: string;
      image: string;
    }[];
  };
};

export const getUnit = gql`
  query GetUnit($propertyId: UUID!, $unitId: UUID!) {
    unit(propertyId: $propertyId, unitId: $unitId) {
      id
      name
      description
      floors {
        id
        name
      }
      people {
        id
        name
        email
        userId
        user {
          _id
          name
          profile {
            email
          }
          lastSeen
          _created
        }
      }
      tenants {
        id
        name
        type
        logo
        image
      }
    }
  }
`;
