import React from 'react';

import { DocumentType } from 'lane-shared/types/DocumentType';

import Dropdown from '../form/Dropdown';
import useCurrentChannelIntegrations from 'hooks/useCurrentChannelIntegrations';

type Props = {
  onChannelIntegrationSelected: (
    channelIntegration: DocumentType | undefined
  ) => void;
  disabled?: boolean;
  channelIntegration?: DocumentType;
  placeholder?: string;
  className?: string;
  style?: React.CSSProperties;
};

export default function ChannelIntegrationSelector({
  className,
  style,
  disabled,
  channelIntegration,
  placeholder,
  onChannelIntegrationSelected,
}: Props) {
  const channelIntegrations = useCurrentChannelIntegrations();
  const items = channelIntegrations.map(ci => ({
    label: ci.integration.name || '',
    value: ci._id,
  }));

  return (
    <div className={className} style={style}>
      <Dropdown
        disabled={disabled}
        items={items}
        placeholder={placeholder}
        value={channelIntegration?._id}
        onValueChange={id => {
          const channelIntegration = channelIntegrations.find(
            channelIntegration => channelIntegration._id === id
          );

          onChannelIntegrationSelected(channelIntegration);
        }}
      />
    </div>
  );
}
