import { useEffect, useState } from 'react';

import { LaneType } from 'common-types';
import { getClient } from '../apollo';
import { getContentFeatureReservableAvailability } from '../graphql/content';
import DateRange from '../properties/baseTypes/DateRange';
import { DateRangeType } from '../types/baseTypes/DateRangeType';

export enum ReservableEventTypeEnum {
  Buffer = 'Buffer',
  Reservation = 'Reservation',
  TimeUnavailable = 'TimeUnavailable',
}

export type ReservableUnavailabilityRange = {
  interval: DateRangeType;
  eventType: ReservableEventTypeEnum | null;
};

type Props = {
  contentId?: LaneType.UUID;
  startDate?: Date | null;
  endDate?: Date | null;
};

export default function useReservableAvailabilityByRange({
  contentId,
  startDate,
  endDate,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [unavailableDateRanges, setUnavailableDateRanges] = useState<
    ReservableUnavailabilityRange[]
  >([]);

  async function getReservableAvailability() {
    if (!startDate || !endDate) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const result = await getClient().query<{
        contentFeatureReservableAvailability: {
          startDate: string;
          endDate: string;
          eventType: ReservableEventTypeEnum | null;
        }[];
      }>({
        query: getContentFeatureReservableAvailability,
        fetchPolicy: 'network-only',
        variables: {
          id: contentId,
          startDate,
          endDate,
        },
      });

      setUnavailableDateRanges(
        result.data.contentFeatureReservableAvailability.map(
          ({ startDate, endDate, eventType }) => ({
            interval: DateRange.parseValue({ startDate, endDate }),
            eventType,
          })
        )
      );
    } catch (err) {
      setError(err);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (!contentId || !startDate || !endDate || loading) {
      return;
    }

    getReservableAvailability();
  }, [contentId, startDate?.toString(), endDate?.toString()]);

  return { unavailableDateRanges, loading, error, getReservableAvailability };
}
