import type { LaneType } from 'common-types';

import { useEffect, useState } from 'react';
import { getChannelIdsHavingPages } from '../helpers/channel/getChannelIdsHavingPages';

export const usePagesExistOnChannels = ({
  channelIds = [],
}: {
  channelIds: Array<LaneType.UUID>;
}) => {
  const [channelIdsFiltered, setChannelIdsFiltered] = useState<
    Array<LaneType.UUID>
  >([]);
  const [loadingResult, setLoadingResult] = useState<boolean>(false);
  const stringifiedChannelIds = JSON.stringify(channelIds);

  useEffect(() => {
    async function makeApiCall() {
      let results = [] as string[];

      try {
        results = await getChannelIdsHavingPages(channelIds);
      } catch (e) {
        console.error(e);
      }

      setLoadingResult(false);
      setChannelIdsFiltered(results);
    }

    if (channelIds.length > 0) {
      setLoadingResult(true);
      makeApiCall();
    }

    // not using `channelIds` as dependency as it was triggering an infinite useEffect
    // a stringified version seemed to solve this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedChannelIds]);

  return { channelIds: channelIdsFiltered, loading: loadingResult };
};
