import { ChannelSettingsType } from '../../types/ChannelType';
import { LocalizationColumnType } from '../../types/LocalizationColumnType';
import { TranslationMethodEnum } from './types';

type GetDynamicTranslationFunction = {
  userSelectedChannelLanguage: string | null;
  userLocale: string;
  textInPrimaryLanguage: string;
  channelSettings: Pick<
    ChannelSettingsType,
    'enabledLanguagesForMembers' | 'channelLanguages'
  >;
  previewLanguageKey?: string;
};

/**
 * This function will go over the data, deriving which language to use
 * for dynamic translation. This function will return the translation key, and
 * a translation function that can be called with the localization column data
 * and will return the correct text.
 */
export const getDynamicTranslationFunction = ({
  userSelectedChannelLanguage,
  userLocale,
  textInPrimaryLanguage,
  channelSettings,
  previewLanguageKey,
}: GetDynamicTranslationFunction) => {
  let userPreference: string;
  let translationMethod: TranslationMethodEnum | null = null;
  let translationError: string | null = null;
  const enabledLanguagesForMembers = Object.entries(
    channelSettings.enabledLanguagesForMembers || {}
  )
    .filter(([_languageCode, isEnabled]) => isEnabled)
    .map(([languageCode, _isEnabled]) => languageCode);

  const languagesAvailableForAdmin = Object.values(
    channelSettings.channelLanguages || {}
  );

  if (
    previewLanguageKey &&
    languagesAvailableForAdmin.includes(previewLanguageKey)
  ) {
    userPreference = previewLanguageKey;
    translationMethod = TranslationMethodEnum.Preview;
  } else if (userSelectedChannelLanguage) {
    userPreference = userSelectedChannelLanguage;
    translationMethod = TranslationMethodEnum.UserPreference;
  } else if (enabledLanguagesForMembers.includes(userLocale)) {
    userPreference = userLocale;
    translationMethod = TranslationMethodEnum.FullUserLocaleMatch;
  } else {
    // try at least the same language category second
    const topLevel = (userSelectedChannelLanguage || userLocale || '').split(
      '-'
    )[0]!;
    const locale =
      topLevel?.length &&
      enabledLanguagesForMembers.find(value => value.includes(topLevel));

    if (locale) {
      userPreference = locale;
      translationMethod = TranslationMethodEnum.PartialUserLocaleMatch;
    } else {
      userPreference = TranslationMethodEnum.None;
      translationMethod = TranslationMethodEnum.None;
      translationError = `No match found. User selected channel language is ${userSelectedChannelLanguage}, user locale is ${userLocale} and enabled languages for members are ${enabledLanguagesForMembers}`;
    }
  }

  if (!enabledLanguagesForMembers.includes(userPreference)) {
    userPreference = TranslationMethodEnum.None;
    translationMethod = TranslationMethodEnum.None;
    translationError = `Preference is unavailable or missing in enabled languages for members ${enabledLanguagesForMembers}`;
  }

  return {
    translationKey: userPreference,
    translationError,
    translationMethod,
    translateFunc: (options: LocalizationColumnType) =>
      (options || {})[userPreference] || textInPrimaryLanguage,
  };
};
