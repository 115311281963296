import * as yup from 'yup';

import { InvalidTypeValueError } from 'activate-errors';
import { NumberTypeBase } from '../../types/baseTypes/NumberTypeBase';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_NUMBER } from '../constants';

export class PercentageType extends NumberTypeBase {
  group = TYPE_GROUP_NUMBER;

  contexts = [
    TypeContextEnum.Block,
    TypeContextEnum.Content,
    TypeContextEnum.Data,
    TypeContextEnum.Properties,
  ];

  name = 'Percentage';

  friendlyName = 'Percentage';

  get example() {
    return Math.floor(Math.random() * 100);
  }

  get default(): any {
    return 0;
  }

  buildSchema() {
    return yup.number().min(0).max(100).nullable();
  }

  parseValue = (value: any) => {
    if (value === undefined || value === null) {
      return value;
    }

    if (typeof value === 'number') {
      return `${value}%`;
    }

    if (typeof value === 'string') {
      return `${Number.parseFloat(value)}%`;
    }

    throw new InvalidTypeValueError(`${value} for Percentage is not valid.`);
  };

  formatter = (value: any) => `${value}%`;
}

const definition = new PercentageType();

Types.addBaseType(definition);
export default definition;
