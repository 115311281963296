import React, { useEffect, useState } from 'react';

import { Pagination } from 'components';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { getClient } from 'lane-shared/apollo';
import { getAudiences } from 'lane-shared/graphql/query';
import { useAudienceAnalytics } from 'lane-shared/hooks/analytics';
import type { TargetingRule } from 'lane-shared/types/audiences/TargetingRule';
import type { ChannelType } from 'lane-shared/properties/baseTypes/Channel';
import type { Audience } from 'lane-shared/types/audiences/Audience';

import { H3, H5, S } from 'lane-web/src/components/typography';

import Input from 'components/form/Input';
import CircleListView from 'components/lane/CircleListView';
import { Modal } from 'components/lds';

import styles from './AudienceModal.scss';

const DEFAULT_PER_PAGE = 10;
const DEFAULT_START_PAGE = 0;
const DEBOUNCE_THROTTLE = 500;

export type AudienceTargeting = Pick<Audience, 'id' | 'name'> & {
  Channel: Pick<ChannelType, 'name'>;
  channelTargetingRules: Pick<TargetingRule, 'id'>[];
  groupRoleTargetingRules: Pick<TargetingRule, 'id'>[];
  reachSize: number;
};

type AudiencesResponse = {
  items: AudienceTargeting[];
  pageInfo: {
    total: number;
  };
};

type AudienceSearch = {
  sortBy: {
    key: 'name';
    dir: 'asc' | 'desc';
  };
  name?: string;
};

export function AudienceItem({
  audience,
  addAudience,
}: {
  audience: AudienceTargeting;
  addAudience: (audienceId: string) => void;
}) {
  const {
    id,
    name,
    channelTargetingRules,
    groupRoleTargetingRules,
    reachSize,
    Channel,
  } = audience;
  const { t } = useTranslation();
  const { audienceTracker } = useAudienceAnalytics({
    audience: audience as any,
  });
  const audienceMetrics = `${Channel.name}・${t(
    'web.admin.content.draftContent.audienceModal.audienceItem.channel',
    {
      count: channelTargetingRules.length,
    }
  )}, ${t('web.admin.content.draftContent.audienceModal.audienceItem.team', {
    count: groupRoleTargetingRules.length,
  })}, ${t('web.admin.content.draftContent.audienceModal.audienceItem.person', {
    count: reachSize,
  })}
  `;

  return (
    <CircleListView
      key={id}
      image=""
      logo=""
      name={name}
      className={styles.audienceListItemWrapper}
      onClick={() => {
        addAudience(id);
        audienceTracker.Target.Select(audience as any);
      }}
    >
      <H5>{name}</H5>
      <S>{audienceMetrics}</S>
    </CircleListView>
  );
}

export function AudienceModal({
  isModalOpen,
  setIsModalOpen,
  channelId,
  addAudience,
}: {
  addAudience: (audience: string) => void;
  channelId: string;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
}) {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string | undefined>();
  const [perPage, setPerPage] = useState<number>(DEFAULT_PER_PAGE);
  const [page, setPage] = useState<number>(DEFAULT_START_PAGE);
  const [audiences, setAudiences] = useState<AudiencesResponse | undefined>();

  const fetchAudiences = async () => {
    if (!channelId) {
      return;
    }

    const variables = {
      channelId,
      pagination: {
        start: page * perPage,
        perPage,
      },
      search: {
        sortBy: {
          key: 'name',
          dir: 'asc',
        },
      } as AudienceSearch,
    };

    if (search) {
      variables.search.name = search;
    }

    const { data } = await getClient().query({
      fetchPolicy: 'network-only',
      query: getAudiences,
      variables,
    });

    if (data?.audiences.items) {
      setAudiences(data.audiences);
    }
  };

  const debouncedFetchAudience = useDebouncedCallback(
    () => fetchAudiences(),
    DEBOUNCE_THROTTLE
  ).callback;

  useEffect(() => {
    debouncedFetchAudience();
  }, [channelId, search, page, perPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className={styles.modalContainer}
    >
      <div className={styles.modalContent}>
        <H3 mb={2}>
          {t('web.admin.content.draftContent.audienceModal.title')}
        </H3>
        <S mb={5} variant="secondary">
          {t('web.admin.content.draftContent.audienceModal.description')}
        </S>
        <Input
          className={styles.serchInput}
          icon="Search"
          value={search}
          type="text"
          placeholder={t(
            'web.admin.content.draftContent.audienceModal.searchText'
          )}
          onChange={queryText => setSearch(queryText)}
          testId="audienceSearchInput"
        />
        {audiences?.items?.map((audience: AudienceTargeting) => (
          <AudienceItem
            key={audience.id}
            audience={audience}
            addAudience={addAudience}
          />
        ))}
        <Pagination
          perPage={perPage}
          page={page}
          onPage={page => setPage(page)}
          onPerPage={perPage => setPerPage(perPage)}
          total={audiences?.pageInfo.total!}
          className={styles.pagination}
        />
      </div>
    </Modal>
  );
}
