import { useEffect, useState, useMemo } from 'react';

import gql from 'graphql-tag';

import { WatchQueryFetchPolicy, useLazyQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { getClient } from '../apollo';
import { addSectionContent, removeSectionContent } from '../graphql/mutation';
import { useMultiLanguage } from './useMultiLanguage';

import { SearchOptions } from 'lane-shared/types/filters/SearchOptions';

import type { SectionType } from 'lane-shared/types/sections/SectionType';
import type { DocumentType } from 'lane-shared/types/DocumentType';
import type { ContentType } from 'lane-shared/types/content/Content';
import type { ChannelSettingsType } from 'lane-shared/types/ChannelType';

const sectionQuery = gql`
  fragment SectionMetatagFragment on Metatag {
    _id
    _created
    _updated
    name
    type
    description
    properties
  }

  fragment SectionObjectMetatagFragment on ObjectMetatag {
    _id
    metatag {
      ...SectionMetatagFragment
    }
    value
  }

  fragment SectionAdminContentFragment on Content {
    _id
    _created
    _updated
    name
    name_l10n
    description
    description_l10n
    subtitle
    slug
    type
    tags
    category
    reportingGroup
    logo
    color
    isInteractive
    isSyndicateable
    geo
    backgroundImage
    icon
    iconSet
    iconWeight
    backgroundColor
    liveDate
    startDate
    endDate
    interactionEndDate
    resetPeriod
    lastReset
    nextReset
    version
    renderer
    block
    channel {
      _id
      settings {
        multiLanguageEnabled
        channelLanguages
        enabledLanguagesForMembers
      }
    }
    properties
    externalUrl {
      _id
      url
      openingMode
    }
    card {
      _id
    }
    features {
      _id
      type
      feature
    }
    data
    state
    contentMetatags {
      ...SectionObjectMetatagFragment
    }
    integration {
      _id
      settings
      integration {
        _id
        name
        settings
      }
    }
  }

  query getSectionForAdmin($id: UUID!, $searchOptions: SearchOptionsInput) {
    section(_id: $id, searchOptions: $searchOptions) {
      _id
      _created
      _updated
      name
      name_l10n
      description
      type
      object
      query
      renderType
      tags
      backgroundImage
      logo
      icon
      iconSet
      iconWeight
      color
      filters
      groupBy
      sorts
      backgroundColor
      channel {
        _id
        settings {
          multiLanguageEnabled
          channelLanguages
          enabledLanguagesForMembers
        }
      }
      sectionMetatags {
        ...SectionObjectMetatagFragment
      }
      sectionContent {
        _id
        _created
        _updated
        _order
        isPinned
        content {
          ...SectionAdminContentFragment
        }
      }
    }
  }
`;

type SectionContentType = {
  _id: string;
  isPinned: boolean;
  content: ContentType;
};

type HookReturnValue = {
  section: SectionWithSectionContentType;
  sectionContent: SectionContentType[];
  error: Error | null;
  loading: boolean;
  addContent: (content: DocumentType) => void;
  removeContent: (content: { _id: string }) => void;
  refetch: () => void;
};

type SectionContentForAdminProps = {
  sectionId: string | undefined | null;
  searchOptions: Partial<SearchOptions>;
  fetchPolicy?: WatchQueryFetchPolicy;
};

type SectionWithSectionContentType = SectionType & {
  sectionContent: SectionContentType[];
  channel?: {
    settings?: Pick<
      ChannelSettingsType,
      'multiLanguageEnabled' | 'channelLanguages' | 'enabledLanguagesForMembers'
    >;
  };
};

export default function useSectionContentForAdmin({
  sectionId,
  searchOptions,
  fetchPolicy = 'cache-and-network',
}: SectionContentForAdminProps): HookReturnValue {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fetchSection, sectionResults] = useLazyQuery(sectionQuery, {
    client: getClient(),
    fetchPolicy,
  });
  const { translate } = useMultiLanguage();

  async function doFetch() {
    if (sectionId && searchOptions) {
      await fetchSection({
        variables: { id: sectionId, searchOptions },
      });
    }
  }

  useEffect(() => {
    doFetch();
  }, [sectionId, JSON.stringify(searchOptions)]);

  const section = useMemo(() => cloneDeep(sectionResults?.data?.section), [
    sectionResults?.data?.section,
  ]);

  const sectionContent = useMemo(
    () => cloneDeep(sectionResults?.data?.section?.sectionContent || []),
    [sectionResults?.data?.section?.sectionContent]
  );

  const translatedSection = translate({ model: section, columns: ['name'] });

  const translatedSectionContent = translate({
    model: sectionContent.map((sc: any) => ({
      ...sc,
      channel: sc.content.channel,
    })),
    columns: ['content.name', 'content.description'],
  });

  async function addContent(content: any) {
    setLoading(true);
    setError(null);

    const sectionContent = {
      _order: 0,
      content: {
        _id: content._id,
      },
      section: {
        _id: sectionId,
      },
    };

    try {
      await getClient().mutate({
        mutation: addSectionContent,
        variables: { sectionContent },
      });

      doFetch();
      setLoading(false);

      return true;
    } catch (err: any) {
      setError(err);
      setLoading(false);

      throw err;
    }
  }

  async function removeContent(sectionContent: any) {
    setLoading(true);
    setError(null);

    try {
      await getClient().mutate({
        mutation: removeSectionContent,
        variables: {
          id: sectionContent._id,
        },
      });

      doFetch();
      setLoading(false);

      return true;
    } catch (err: any) {
      setError(err);
      setLoading(false);

      throw err;
    }
  }

  return {
    section: translatedSection,
    sectionContent: translatedSectionContent,
    error: sectionResults?.error || error,
    loading: sectionResults?.loading || loading,
    addContent,
    removeContent,
    refetch: doFetch,
  };
}
