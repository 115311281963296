import React from 'react';

import {
  RichTextAlignEnum,
  RichTextBlockEnum,
  RichTextNodeType,
} from '../properties/baseTypes/RichText';

function RichTextNode({ node }: { node?: RichTextNodeType }) {
  function renderChildren() {
    return node?.children?.map((node, i) => (
      <RichTextNode key={`${node}${i}`} node={node} />
    ));
  }

  if (node?.text) {
    return (
      <span
        style={{
          fontWeight: node.bold ? 'bold' : 'normal',
          fontStyle: node.italic ? 'italic' : 'normal',
          textDecoration: node.underlined ? 'underline' : 'none',
        }}
      >
        {node.text}
      </span>
    );
  }

  const alignStyle = { textAlign: node?.align || RichTextAlignEnum.left };

  switch (node?.type) {
    case RichTextBlockEnum.H1:
      return <h1 style={alignStyle}>{renderChildren()}</h1>;
    case RichTextBlockEnum.H2:
      return <h2 style={alignStyle}>{renderChildren()}</h2>;
    case RichTextBlockEnum.H3:
      return <h3 style={alignStyle}>{renderChildren()}</h3>;
    case RichTextBlockEnum.H4:
      return <h4 style={alignStyle}>{renderChildren()}</h4>;
    case RichTextBlockEnum.H5:
      return <h5 style={alignStyle}>{renderChildren()}</h5>;
    case RichTextBlockEnum.H6:
      return <h6 style={alignStyle}>{renderChildren()}</h6>;
    case RichTextBlockEnum.BlockQuote:
      return <blockquote>{renderChildren()}</blockquote>;
    case RichTextBlockEnum.BulletedList:
      return <ul>{renderChildren()}</ul>;
    case RichTextBlockEnum.ListItem:
      return <li>{renderChildren()}</li>;
    case RichTextBlockEnum.NumberedList:
      return <ol>{renderChildren()}</ol>;
    case RichTextBlockEnum.Code:
      return <code>{renderChildren()}</code>;
    case RichTextBlockEnum.Link:
      return (
        <a href={node.url} target="_blank" rel="noopener noreferrer">
          {renderChildren()}
        </a>
      );
    case RichTextBlockEnum.Paragraph:
    default:
      return <p style={alignStyle}>{renderChildren()}</p>;
  }
}

export default function RichTextHtmlRenderer({
  value,
}: {
  value?: RichTextNodeType[];
}) {
  // note: returning these fragments keeps tsc happy, don't remove them
  if (!value) {
    return <></>;
  }

  return (
    <>
      {value.map((node, i) => (
        <RichTextNode key={`${node}${i}`} node={node} />
      ))}
    </>
  );
}
