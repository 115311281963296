/* eslint-disable @nx/enforce-module-boundaries */
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentPage, HorizontalContentCard } from 'lane-web/src/components';
import styles from './VisitCreatePanel.scss';
import { ViewSubmissionHistory } from '../ViewSubmissionHistory/ViewSubmissionHistory';
import { H5, SidePanel } from 'design-system-web';
import { useContentQuery } from 'lane-shared/hooks';
import { ContentType } from 'lane-shared/types/content/Content';

interface VisitCreatePanelProps {
  isPanelOpen: boolean;
  onPanelClose: () => void;
  visitorForms: ContentType[];
}

type ScreenType = 'formSelector' | 'formView' | 'submissionHistory';

const SCREEN_TYPE = {
  FORM_SELECTOR: 'formSelector',
  FORM_VIEW: 'formView',
  SUBMISSION_HISTORY: 'submissionHistory',
} as const;

export function VisitCreatePanel({
  isPanelOpen,
  onPanelClose,
  visitorForms,
}: VisitCreatePanelProps) {
  const { t } = useTranslation();
  const [selectedForm, setSelectedForm] = useState<ContentType | null>(null);
  const singleForm = visitorForms?.length === 1 ? visitorForms[0] : null;
  const [currentScreen, setCurrentScreen] = useState<ScreenType>(
    singleForm ? SCREEN_TYPE.FORM_VIEW : SCREEN_TYPE.FORM_SELECTOR
  );

  const { content } = useContentQuery({
    contentId: selectedForm?._id || singleForm?._id,
  });

  if (content?.block?.properties) {
    content.block.properties.fullWidth = true;
  }

  useEffect(() => {
    if (isPanelOpen && singleForm) {
      setSelectedForm(singleForm);
      setCurrentScreen(SCREEN_TYPE.FORM_VIEW);
    } else if (isPanelOpen) {
      setCurrentScreen(SCREEN_TYPE.FORM_SELECTOR);
    }
  }, [isPanelOpen, singleForm]);

  const onClose = useCallback(() => {
    onPanelClose();

    if (singleForm) {
      setCurrentScreen(SCREEN_TYPE.FORM_VIEW);
    } else {
      setSelectedForm(null);
      setCurrentScreen(SCREEN_TYPE.FORM_SELECTOR);
    }
  }, [onPanelClose, singleForm]);

  const handleFormSelect = useCallback((form: ContentType) => {
    setSelectedForm(form);
    setCurrentScreen(SCREEN_TYPE.FORM_VIEW);
  }, []);

  const screenProps = useMemo(
    () => ({
      [SCREEN_TYPE.FORM_SELECTOR]: {
        children: (
          <FormSelector
            visitorForms={visitorForms}
            handleFormSelect={handleFormSelect}
          />
        ),
      },
      [SCREEN_TYPE.FORM_VIEW]: {
        onBack: !singleForm
          ? () => {
              setSelectedForm(null);
              setCurrentScreen(SCREEN_TYPE.FORM_SELECTOR);
            }
          : undefined,
        menuOptions: [
          {
            label: t(
              'web.admin.channel.visitor.forms.viewSubmissionHistory.button'
            ),
            onSelect: () => setCurrentScreen(SCREEN_TYPE.SUBMISSION_HISTORY),
          },
        ],
        children: (
          <ContentPage
            className={styles.sidePanelContent}
            content={content!}
            shouldNavigateToNextPage={false}
            onInteractionCreated={onClose}
          />
        ),
      },
      [SCREEN_TYPE.SUBMISSION_HISTORY]: {
        onBack: () => setCurrentScreen(SCREEN_TYPE.FORM_VIEW),
        children: <ViewSubmissionHistory contentId={content?._id} />,
      },
    }),
    [visitorForms, handleFormSelect, singleForm, t, content, onClose]
  );

  return (
    <SidePanel
      id="add-visit-panel"
      isOpen={isPanelOpen}
      onClose={onClose}
      size="medium"
      header={t('web.admin.channel.visitor.log.create.new.visit.title')}
      backdropEnabled
      zIndex={5}
      {...screenProps[currentScreen]}
    />
  );
}

const FormSelector = ({
  visitorForms,
  handleFormSelect,
}: {
  visitorForms: ContentType[];
  handleFormSelect: (form: ContentType) => void;
}) => {
  const { t } = useTranslation();

  if (visitorForms.length === 0) {
    return null;
  }

  return (
    <div className={styles.FormSelection}>
      <H5 className={styles.FormSelectionTitle}>
        {t('web.admin.channel.visitor.forms.modal.title')}
      </H5>
      {visitorForms?.map(form => (
        <a
          role="button"
          onClick={() => handleFormSelect(form)}
          key={form._id}
          tabIndex={0}
        >
          <HorizontalContentCard
            className={styles.ContentCard}
            key={form._id}
            content={form}
          />
        </a>
      ))}
    </div>
  );
};
