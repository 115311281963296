/**
 * Generates the Authorization header given a token.
 *
 * @param token
 * @returns {*}
 */
export default function authorizationHeader(token: any) {
  if (!token) {
    return '';
  }

  if (token.token && token.jti) {
    return `JWT token="${token.token}", jti="${token.jti}"`;
  }

  return `Bearer ${token}`;
}
