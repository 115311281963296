import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import Input from 'components/form/Input';
import Toggle from 'components/form/Toggle';
import Button from 'components/general/Button';
import Label from 'components/general/Label';
import TimeRangeInput from 'components/lane/TimeRangeInput';

import styles from './DayPriceTimeRange.scss';

type DayPriceTimeRangeProps = {
  baseAmount: number;
  isOpen: boolean;
  isEditing: boolean;
  priceTimeRanges: any;
  dayKey: string;
  dayObject: any;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onAddTimeRange: (any) => void;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onRemoveTimeRange: (any) => void;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onTimeRangeSelect: (any) => void;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onChangePriceRatio: (any) => void;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onToggleOpen: (any) => void;
};

const TRANSLATION_KEYS: { [key: string]: string } = {
  Sunday: 'web.content.features.components.DayPriceTimeRange.sunday',
  Monday: 'web.content.features.components.DayPriceTimeRange.monday',
  Tuesday: 'web.content.features.components.DayPriceTimeRange.tuesday',
  Wednesday: 'web.content.features.components.DayPriceTimeRange.wednesday',
  Thursday: 'web.content.features.components.DayPriceTimeRange.thursday',
  Friday: 'web.content.features.components.DayPriceTimeRange.friday',
  Saturday: 'web.content.features.components.DayPriceTimeRange.saturday',
  addHours: 'web.content.features.components.DayPriceTimeRange.addHours',
};

export default function DayPriceTimeRange({
  baseAmount,
  isOpen,
  isEditing,
  priceTimeRanges,
  dayKey,
  dayObject,
  onAddTimeRange,
  onRemoveTimeRange,
  onTimeRangeSelect,
  onChangePriceRatio,
  onToggleOpen,
}: DayPriceTimeRangeProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.dayHourRanges}>
      <Label h4 className={styles.dayLabel}>
        {t(TRANSLATION_KEYS[dayObject.name] || '')}
      </Label>
      {onToggleOpen && isEditing && (
        <Label>
          <Toggle
            className={styles.toggleButton}
            onChange={value => onToggleOpen({ dayKey, value })}
            value={isOpen}
          />
        </Label>
      )}
      {isOpen && (
        <div className={styles.dayTimeRange}>
          <div>
            {priceTimeRanges.map(
              ({ _id, startTime, endTime, priceRatio }: any, index: any) => {
                if (!isEditing) {
                  const displayPrice =
                    priceRatio && baseAmount
                      ? (
                          (priceRatio * baseAmount) /
                          CURRENCY_CONVERSION
                        ).toFixed(2)
                      : null;

                  return (
                    <div key={_id} className={styles.displayTimeRange}>
                      {startTime} - {endTime}
                      {displayPrice && (
                        <span style={{ marginLeft: '1em' }}>
                          Price: ${displayPrice}
                        </span>
                      )}
                    </div>
                  );
                }

                return (
                  <div key={_id} className={styles.timeRangeInputContainer}>
                    <TimeRangeInput
                      className={styles.timeRangeInput}
                      onChange={value =>
                        onTimeRangeSelect({ _id, value, dayKey, index })
                      }
                      value={{
                        startTime: startTime || '9:00 AM',
                        endTime: endTime || '5:00 AM',
                      }}
                    />
                    <Input
                      value={priceRatio}
                      fieldName="priceRatio"
                      type="number"
                      icon="Percent"
                      iconRight
                      className={styles.priceRatio}
                      onChange={value =>
                        onChangePriceRatio({
                          index,
                          dayKey,
                          value: Math.max(0, Number(value)),
                        })
                      }
                    />
                    {/* Add / Remove buttons */}
                    {(index !== 0 || priceTimeRanges.length > 1) && (
                      <Icon
                        name="times"
                        set={ICON_SET_FONTAWESOME}
                        className={styles.icon}
                        onClick={() =>
                          onRemoveTimeRange({
                            dayKey,
                            removeIndex: index,
                          })
                        }
                      />
                    )}
                    {index === priceTimeRanges.length - 1 && (
                      <Button
                        className={styles.addButton}
                        onClick={() => onAddTimeRange({ dayKey })}
                      >
                        {t(TRANSLATION_KEYS.addHours || '')}
                        <Icon
                          name="plus"
                          set={ICON_SET_FONTAWESOME}
                          onClick={() => onAddTimeRange({ dayKey })}
                        />
                      </Button>
                    )}
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}
    </div>
  );
}
