export default function arrayReorder(
  arr: any[],
  startIndex: number,
  endIndex: number
) {
  const newList = [...arr];
  const [removed] = newList.splice(startIndex, 1);

  newList.splice(endIndex, 0, removed);
  newList.forEach((item, i) => (item._order = i));

  return newList;
}
