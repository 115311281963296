import React, { useRef } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Thumbnail } from 'components';
import { useRippleEffect } from 'hooks';

import { colors } from 'lane-shared/config';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import { S } from 'components/typography';

import styles from './Chip.scss';

interface Avatar {
  src?: string;
  highlight: boolean;
  className?: string;
  style?: React.CSSProperties;
  name: string;
}

type Props = {
  interfaceStyle?: 'dark' | 'light';
  selected?: boolean;
  active?: boolean;
  label: string;
  iconSet?: 'Feather' | 'Ion' | 'Material' | 'FontAwesome';
  onClick?: (e: any) => void;
  onDelete?: (e: any) => void;
  deleteIcon?: React.ReactElement;
  avatar?: Avatar;
  fixed?: boolean;
  disabled?: boolean;
  className?: string;
  selectedIcon?: string | null;
};

export default function Chip({
  interfaceStyle = 'light',
  selected = false,
  selectedIcon = 'check',
  deleteIcon,
  onDelete,
  onClick,
  avatar,
  iconSet = ICON_SET_FONTAWESOME,
  fixed = false,
  disabled = false,
  active = false,
  label,
  className,
}: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const rippleContainerRef = useRef<HTMLDivElement | null>(null);
  const rippleColor =
    interfaceStyle === 'light' ? colors.charcoal[9] : '#D8D8D8';
  const withAvatar = Boolean(avatar);

  function handleDelete(event: any) {
    if (onDelete) {
      event.stopPropagation();
      onDelete(event);
    }
  }

  useRippleEffect(containerRef, rippleContainerRef, rippleColor);

  return (
    <div
      ref={containerRef}
      className={cx(styles.ChipContainer, className)}
      onClick={onClick}
      role="button"
      tabIndex={0}
      data-fixed={fixed}
      data-disabled={disabled}
      data-interface-style={interfaceStyle}
      data-selected={selected}
      data-active={active}
      data-without-icons={!withAvatar && !selected && !deleteIcon}
      data-with-selected-icon={Boolean(selectedIcon)}
      data-with-delete-icon={Boolean(deleteIcon)}
      data-with-avatar={withAvatar}
    >
      <div className={styles.Chip}>
        {(selectedIcon && selected) || avatar ? (
          <div className={styles.avatarContainer}>
            {avatar ? (
              <Thumbnail
                src={avatar?.src}
                name={avatar?.name}
                highlight={avatar?.highlight}
                className={styles.avatar}
              />
            ) : null}
            {selected && selectedIcon ? (
              <div className={styles.iconWrapper}>
                <Icon name={selectedIcon} set={iconSet} />
              </div>
            ) : null}
          </div>
        ) : null}
        <S>{label}</S>
        {deleteIcon ? (
          <div
            className={styles.deleteIcon}
            role="button"
            tabIndex={0}
            onClick={handleDelete}
          >
            {deleteIcon}
          </div>
        ) : null}
        <div ref={rippleContainerRef} className={styles.RippleContainer} />
      </div>
    </div>
  );
}
