import React, { useState } from 'react';

import { AddNewCardAutopaymentModal } from './AddNewCardAutopaymentModal';
import { UseExistingCardForAutopayment } from './UseExistingCardForAutopayment';
import styles from './invoiceAutopayment.scss';
import { StripeCardElement } from '@stripe/stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Checkbox } from 'design-system-web';
import { ErrorMessage, Line } from 'components';
import { M } from 'components/typography';
import { useTranslation } from 'react-i18next';
import { PaymentSource } from 'graphql-query-contracts';
import { useForm } from 'react-hook-form';
import { CardFormInputs } from 'lane-web/src/components/features/Payment/PaymentSummaryV2/PaymentOptions/types';
import { useBillingAndPaymentEuComplianceEnabled } from 'lane-shared/domains/billingPayments/hooks/useBillingAndPaymentEuComplianceEnabled';
import {
  validateStripeCardForm,
  validateBillingDetails,
  validateBillingAddress,
} from 'lane-shared/validation/paymentV2/validateStripeCardForm';

interface Props {
  paymentSources: PaymentSource[];
  saveAutopaymentMethod: (paymentMethod: string) => Promise<void>;
  closeModal: () => void;
}

export function TurnOnAutopaymentModal({
  saveAutopaymentMethod,
  closeModal,
  paymentSources,
}: Props) {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm<CardFormInputs>();
  const PaymentEuComplianceEnabled = useBillingAndPaymentEuComplianceEnabled();

  const [errorMessage, setErrorMessage] = useState<Error | string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentSources[0]?.paymentMethodId || ''
  );
  const [isAddNewCardClicked, setIsAddNewCardClicked] = useState(false);
  const isExistingCardView =
    Boolean(paymentSources.length) && !isAddNewCardClicked;
  const stripe = useStripe();
  const elements = useElements();

  async function customValidation(data: CardFormInputs) {
    try {
      if (PaymentEuComplianceEnabled) {
        await validateBillingDetails.validate(data);
        await validateBillingAddress.validate(JSON.parse(data.billingAddress));
      } else {
        await validateStripeCardForm.validate({
          firstName: data.firstName,
          lastName: data.lastName,
        });
      }

      return null;
    } catch (validationError) {
      return validationError;
    }
  }

  const handleOnSave = async (data: CardFormInputs) => {
    try {
      let paymentMethodId = selectedPaymentMethod;

      if (!isExistingCardView) {
        const validationError = await customValidation(data);

        if (validationError) {
          if (PaymentEuComplianceEnabled) {
            setErrorMessage(t('abp.payment.billingDetailsRequired'));
          } else {
            setErrorMessage(t('abp.payment.allFieldsRequired'));
          }

          return;
        }

        setErrorMessage('');
        setIsLoading(true);
        const fullName = PaymentEuComplianceEnabled
          ? `${JSON.parse(data.billingAddress).name}`
          : `${data.firstName} ${data.lastName}`;

        if (stripe && elements) {
          const payload = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement('card') as StripeCardElement,
            billing_details: {
              name: fullName,
            },
            metadata: {
              billingAddress: data.billingAddress,
              companyName: data.companyName,
              taxId: data.taxId,
            },
          });

          if (payload.error) {
            throw new Error(payload.error.message);
          }

          paymentMethodId = payload.paymentMethod!.id;
        }
      }

      await saveAutopaymentMethod(paymentMethodId);
      window.Toast.show(
        t('abp.routes.accountInvoices.autopayment.modal.autopaymentAdded')
      );
      closeModal();
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <M level="h3">
        {t(
          'abp.routes.accountInvoices.autopayment.modal.turnOnAutomaticPayments'
        )}
      </M>
      {isExistingCardView ? (
        <UseExistingCardForAutopayment
          paymentSources={paymentSources}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          setIsAddNewCardClicked={setIsAddNewCardClicked}
        />
      ) : (
        <AddNewCardAutopaymentModal
          isAddNewCardClicked={isAddNewCardClicked}
          setIsAddNewCardClicked={setIsAddNewCardClicked}
          InputController={{ control, setValue }}
        />
      )}

      <Checkbox
        value="acceptAutopayment"
        selected={isCheckboxChecked}
        onChange={() => setIsCheckboxChecked(prev => !prev)}
        testId="TurnOnAutopaymentModalCheckbox"
        text={t('abp.routes.accountInvoices.autopayment.modal.authorization')}
      />
      <Line className={styles.buttonDivider} />
      <div className={styles.turnOnAutopaymentButtonGroup}>
        <Button
          testId="TurnOnAutopaymentModalCancelButton"
          onClick={() => closeModal()}
          variant="secondary"
          size="large"
        >
          {t('Cancel')}
        </Button>
        <Button
          testId="TurnOnAutopaymentModalSaveButton"
          onClick={handleSubmit(handleOnSave)}
          disabled={!isCheckboxChecked}
          size="large"
          loading={isLoading}
        >
          {t('Save')}
        </Button>
      </div>
      <div className={styles.errorMessageContainer}>
        <ErrorMessage error={errorMessage} fullWidth />
      </div>
    </div>
  );
}
