import { useState, useEffect, useMemo, useRef } from 'react';

import { useDebounce } from 'use-debounce';

import calculateBoundingBox from '@turf/bbox';
import calculateBearing from '@turf/bearing';
import calculateCentroid from '@turf/centroid';
import ellipse from '@turf/ellipse';
import { polygon, point, lineString, BBox } from '@turf/helpers';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';
import length from '@turf/length';

import { appUrl } from '../../config';
import Storage from '../../helpers/Storage';
import { FloorMapsSettingsType } from '../../helpers/integrations/FloorMaps/definition';
import isFloorMapValid from '../../helpers/integrations/FloorMaps/isFloorMapValid';
import mapToGeoJSONPolygon from '../../helpers/mapBox/mapToGeoJSONPolygon';
import { GeoCoordinateType } from '../../types/baseTypes/GeoTypes';
import { ContentType } from '../../types/content/Content';

export type MapPositionType = {
  center?: GeoCoordinateType;
  zoom?: number;
  pitch: number;
  bearing: number;
  isReady: boolean;
};

type FloorMapsProps = {
  content?: ContentType | null;
  disableShowLastPosition?: boolean;
  defaultZoom?: number;
  defaultBearing?: number;
  defaultPitch?: number;
};

type Data = MapPositionType & { selectedFloorId: string | null };

const DEBOUNCE_THROTTLE = 500;

/**
 * a cross-platform (Web and mobile) hook to use in the
 * FloorMapsContentRenderer.  This will save on code duplication across the
 * platforms
 */
export default function useFloorMaps({
  content,
  disableShowLastPosition,
  defaultZoom = 16,
  defaultBearing = 0,
  defaultPitch = 0,
}: FloorMapsProps = {}) {
  const lastMapPosition = useRef<MapPositionType>({
    center: undefined,
    zoom: undefined,
    pitch: defaultPitch,
    bearing: defaultBearing,
    isReady: false,
  }).current;
  const storageKey = `mapbox-floormaps-${content?._id}`;

  const [lastRender, setLastRender] = useState(Date.now());
  const [debouncedLastRender] = useDebounce(lastRender, DEBOUNCE_THROTTLE);

  const channelIntegration = useMemo(() => content?.integration, [
    content?._id,
  ]);
  const settings: FloorMapsSettingsType | undefined =
    channelIntegration?.settings;

  // get the floormaps, or use an empty array if there are none
  const floorMaps = settings?.floorMaps || [];

  // the floor the user is looking at right now
  const [selectedFloor, setSelectedFloor] = useState(settings?.floorMaps?.[0]);

  async function storeLastState() {
    await Storage.setItem(storageKey, {
      ...lastMapPosition,
      selectedFloorId: selectedFloor?._id,
    });
  }

  async function getStoredFloor() {
    try {
      const storedData = (await Storage.getItem(storageKey)) as Data;

      if (storedData.selectedFloorId) {
        selectFloor(storedData.selectedFloorId);
      }
    } catch (e) {
      // ignore error
    }
  }

  async function getStoredPosition() {
    try {
      const storedPosition = (await Storage.getItem(
        storageKey
      )) as MapPositionType;

      if (storedPosition.zoom) {
        lastMapPosition.zoom = storedPosition.zoom;
      }

      const { bearing } = storedPosition;

      if (bearing) {
        lastMapPosition.bearing = bearing;
      }

      if (storedPosition.center) {
        lastMapPosition.center = storedPosition.center;
      }

      if (storedPosition.pitch) {
        lastMapPosition.pitch = storedPosition.pitch;
      }
    } catch (err) {
      // ignore error
    } finally {
      lastMapPosition.isReady = true;
      setLastRender(Date.now());
    }
  }

  useEffect(() => {
    if (content?._id && !lastMapPosition.isReady && !disableShowLastPosition) {
      getStoredPosition();
    }

    if (content?._id) {
      getStoredFloor();
    }
  }, [content?._id, disableShowLastPosition]);

  useEffect(() => {
    if (lastMapPosition.isReady) {
      storeLastState();
    }
  }, [debouncedLastRender]);

  const bearing = useMemo(() => {
    // for some reason negative bearing is not working on iOS
    return (calculateMapBearing() + 360) % 360;
  }, [selectedFloor?._id]);

  function calculateMapCenter(): GeoCoordinateType {
    if (selectedFloor && isFloorMapValid(selectedFloor)) {
      try {
        const centroid = calculateCentroid(
          polygon([mapToGeoJSONPolygon(selectedFloor.placement.coordinates)])
        );

        return centroid.geometry.coordinates as GeoCoordinateType;
      } catch (err) {
        // ignore error
      }
    }

    if (content?.geo) {
      return content.geo;
    }

    return [0, 0];
  }

  function calculateMapBearing(): number {
    if (!selectedFloor || !isFloorMapValid(selectedFloor)) {
      return 0;
    }

    const { coordinates } = selectedFloor!.placement;

    if (!(coordinates[0] && coordinates[1])) {
      return 0;
    }

    // We need to figure out the longer side of the image to then find it's angle in comparison to the north line (0 degrees)
    // this angle will allow us to align the image vertically
    const lineA = lineString([coordinates[0], coordinates[1]]);
    const lineB = lineString([coordinates[1], coordinates[2]]);

    const horizontalLine = length(lineB);

    const longerSide = Math.max(length(lineA), length(lineB));

    return calculateBearing(
      point(coordinates[longerSide === horizontalLine ? 2 : 1]),
      point(coordinates[longerSide === horizontalLine ? 1 : 0])
    );
  }

  useEffect(() => {
    if (selectedFloor?._id) {
      lastMapPosition.center = calculateMapCenter();
      lastMapPosition.bearing = bearing;
      lastMapPosition.pitch = defaultPitch;
      lastMapPosition.zoom = defaultZoom;
      setLastRender(Date.now());
    }
  }, [selectedFloor?._id]);

  function updateCenter(center: GeoCoordinateType) {
    if (
      center[0] !== lastMapPosition.center?.[0] &&
      center[1] !== lastMapPosition.center?.[1]
    ) {
      lastMapPosition.center = center;
      setLastRender(Date.now());
    }
  }

  function updateZoom(zoom: number) {
    if (zoom !== lastMapPosition.zoom) {
      lastMapPosition.zoom = zoom;
      setLastRender(Date.now());
    }
  }

  function updatePitch() {
    // forcing pitch to always be 0
    if (lastMapPosition.pitch !== 0) {
      lastMapPosition.pitch = 0;
      setLastRender(Date.now());
    }
  }

  function updateBearing(bearing: number) {
    if (bearing !== lastMapPosition.bearing) {
      lastMapPosition.bearing = bearing;
      setLastRender(Date.now());
    }
  }

  // we want to limit the bounds of the map to the size of the floor map polygon
  // plus a bit at the edges.
  const mapBounds: BBox = useMemo(() => {
    const SCALE_MULTIPLIER = 1.5;
    let diameter = 1;

    if (selectedFloor && isFloorMapValid(selectedFloor)) {
      const { coordinates } = selectedFloor.placement;

      const A = lineString([coordinates[0], coordinates[1]]);
      const B = lineString([coordinates[1], coordinates[2]]);

      const longerSide = Math.max(length(A), length(B));

      diameter = SCALE_MULTIPLIER * longerSide;
    }

    const rectangle = ellipse(calculateMapCenter(), diameter, diameter, {
      steps: 4,
    });

    return calculateBoundingBox(polygon(rectangle.geometry.coordinates));
  }, [selectedFloor?._id]);

  const selectedFloorBounds: BBox2d | undefined = useMemo(() => {
    if (!selectedFloor || !isFloorMapValid(selectedFloor)) {
      return;
    }

    const { coordinates } = selectedFloor.placement;

    return [
      Math.max(...coordinates.map(item => item[0])),
      Math.max(...coordinates.map(item => item[1])),
      Math.min(...coordinates.map(item => item[0])),
      Math.min(...coordinates.map(item => item[1])),
    ];
  }, [selectedFloor]);

  // when the channel integration has fully loaded, choose a default floor
  // so the user is looking at something
  useEffect(() => {
    if (!selectedFloor && channelIntegration?._id && settings) {
      selectFloor(settings.floorMaps?.[0]?._id);
    }
  }, [channelIntegration?._id]);

  // the tile sources object will will memo-ify because we don't want the map
  // constantly re-rendering.
  const tileSources = useMemo(
    () =>
      floorMaps.map(floorMap =>
        isFloorMapValid(floorMap)
          ? {
              type: 'raster',
              tiles: [
                `${appUrl}/api/v5/floormaps/${channelIntegration?._id}/${floorMap._id}/{z}/{x}/{y}`,
              ],
              tileSize: 256,
            }
          : null
      ),
    [floorMaps]
  );

  // we also don't want the floor map dropdown constantly re-rendering, so
  // memo-ify the dropdown items as well
  const floorMapItems = useMemo(
    () =>
      floorMaps.map(floorMap => ({
        label: floorMap.name,
        value: floorMap._id,
      })),
    [floorMaps]
  );

  // select a floor
  function selectFloor(floorId: string | undefined) {
    setSelectedFloor(floorMaps.find(floorMap => floorMap._id === floorId));
  }

  return {
    mapBounds,
    bearing,
    settings,
    tileSources,
    floorMaps,
    floorMapItems,
    selectFloor,
    selectedFloor,
    selectedFloorBounds,
    updateBearing,
    updateZoom,
    updatePitch,
    updateCenter,
    lastMapPosition,
  };
}
