import React, { useState } from 'react';

import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import Autocomplete from '@mui/material/Autocomplete';

import MuiAutocompleteCustomInput from './MuiAutocompleteCustomInput';

import styles from './PaginatedTypeAhead.scss';

interface Props<T> {
  isDisabled?: boolean;
  onSubmit: (value: T) => void;
  loadNext: () => void;
  isLoading?: boolean;
  data: Array<T>;
  onChange: (text: string) => void;
  getOptionLabel?: (option: T) => string;
  getOptionDisabled?: (option: T) => boolean;
}

export default function PaginatedTypeAhead<T>({
  data,
  onSubmit,
  onChange,
  loadNext,
  isDisabled = false,
  isLoading = false,
  getOptionLabel,
  getOptionDisabled,
}: Props<T>) {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<T | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const debouncedSetSearchText = useDebouncedCallback((value: string) => {
    onChange(value);
  }, 300).callback;

  const addButtonClickHandler = () => {
    if (selectedValue) {
      onSubmit(selectedValue);
      setSelectedValue(null);
    }
  };

  const handleSelectionChange = (value: T | null) => {
    if (value) {
      setSelectedValue(value);
      setIsDropdownOpen(false);
    }
  };

  const handleOnScroll = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;

    if (
      listboxNode.scrollTop + listboxNode.clientHeight ===
      listboxNode.scrollHeight
    ) {
      loadNext();
    }
  };

  const handleDropdownToggle = () => setIsDropdownOpen(!isDropdownOpen);

  const handleDropdownClear = () => setSelectedValue(null);

  return (
    <div className={styles.paginatedTypeAhead}>
      <Autocomplete
        id="paginated-type-ahead"
        open={isDropdownOpen}
        value={selectedValue}
        options={data}
        loading={isLoading}
        disabled={isDisabled}
        onChange={(_e, newValue) => handleSelectionChange(newValue)}
        renderInput={params =>
          MuiAutocompleteCustomInput({
            params,
            loading: isLoading,
            onClick: handleDropdownToggle,
            onClear: handleDropdownClear,
            showClearIcon: selectedValue !== null,
            isDropdownOpen,
          })
        }
        ListboxProps={{ onScroll: handleOnScroll }}
        onInputChange={(_e, value) => debouncedSetSearchText(value)}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
      />
      <Button
        color="inherit"
        style={{ color: 'white' }}
        variant="contained"
        onClick={addButtonClickHandler}
        disabled={selectedValue === null}
        className={styles.button}
      >
        {t('web.components.form.PaginatedTypeAhead.add')}
      </Button>
    </div>
  );
}
