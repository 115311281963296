import React, { Component } from 'react';

import * as Sentry from '@sentry/browser';

import { LaneType } from 'common-types';
import config from 'lane-shared/config';
import env from 'lane-shared/config/getEnv';
import { ENVIRONMENTS } from 'activate-constants';
import { getUserForSentry } from 'lane-shared/helpers/sentry';
import { UserType } from 'lane-shared/types/User';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import FriendlyErrorPage from 'components/errors/FriendlyErrorPage';

import styles from './ErrorBoundary.scss';

/**
 * Component that catches Javascript errors that are thrown in the
 * child component tree. Renders a stack strace in development or
 * a more user-friendly page for end users.
 *
 *  Sentry: https://sentry.io/organizations/lane-next/issues/
 *
 */

type Props = {
  user: UserType;
  sessionId: LaneType.UUID | null;
  integration?: IntegrationProviderEnum;
};
type State = {
  error: Error | null;
  eventId: string;
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, eventId: '' };
  }

  componentDidMount() {
    if ([ENVIRONMENTS.STAGING, ENVIRONMENTS.PRODUCTION].includes(env)) {
      Sentry.init({
        dsn: config.sentryDsn.web,
        environment: env,
        release: config.laneVersion,
        ignoreErrors: ['Non-Error promise rejection captured'],
      });
    }
  }

  componentDidUpdate() {
    const sentryUser = getUserForSentry(this.props.user);

    if (sentryUser) {
      Sentry.setUser(sentryUser);
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const mixpanelSessionUrl = config.mixpanel.sessionIdFilterUrl;

    Sentry.setTag('sessionId', this.props.sessionId);
    const eventId = Sentry.captureException(error, {
      level: Sentry.Severity.Fatal,
      contexts: { errorInfo },
      user: getUserForSentry(this.props.user),
      tags: this.props.integration && { integration: this.props.integration },
      extra: {
        mixpanelSessionUrl: mixpanelSessionUrl.replace(
          '<sessionId>',
          String(this.props.sessionId)
        ),
      },
    });

    this.setState({ eventId });
  }

  handleShowReportDialog = () => {
    Sentry.showReportDialog({
      eventId: this.state.eventId,
      user: getUserForSentry(this.props.user),
    });
  };

  renderStackStrace(error: Error) {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorContent}>
          <h3>{error.message}</h3>
          <ul>
            {error.stack?.split('\n').map((line, index) => (
              <li key={index}>{line}</li>
            ))}
          </ul>
          <button onClick={() => window.location.reload()}>Refresh</button>
        </div>
      </div>
    );
  }

  render() {
    const { error } = this.state;

    if (error) {
      if ([ENVIRONMENTS.LOCAL, ENVIRONMENTS.DEVELOPMENT].includes(env)) {
        return this.renderStackStrace(error);
      }

      return (
        <FriendlyErrorPage handleSendReport={this.handleShowReportDialog} />
      );
    }

    // @ts-expect-error
    return this.props.children;
  }
}
