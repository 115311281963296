import { getInteractionIntegrationProvider } from 'lane-shared/helpers/content';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

function shopifyBurstToUci(interaction: any) {
  const burst = interaction.data[`_${IntegrationProviderEnum.ShopifyBurst}`];
  const interactionData = {
    ...interaction,
    contentData: {
      ...interaction.contentData,
      subtitle: burst.port?.name,
      name: burst.name,
      geo: null,
    },
  };

  return interactionData;
}

const converters: {
  [key in IntegrationProviderEnum]?: (interaction: any) => any;
} = {
  [IntegrationProviderEnum.ShopifyBurst]: shopifyBurstToUci,
};

export default function getFormattedInteraction(interaction: any) {
  const provider = getInteractionIntegrationProvider(interaction);

  if (!provider) return interaction;

  return converters[provider]
    ? converters[provider]!(interaction)
    : interaction;
}
