import { ServiceRequestStatusEnum } from 'lane-shared/domains/workOrder/constants';
import {
  EVENT_CONTENT_INTERACTION_STATUSCHANGE,
  EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION,
} from 'lane-shared/helpers/constants/events';
import {
  WorkflowActionEnum,
  WorkflowTargetEnum,
  WorkflowWhenEnum,
} from 'lane-shared/types/Workflows';
import { Workflow, WorkflowTypeEnum } from 'lane-shared/types/workflow';

import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import {
  actionLabelsV2,
  eventLabelsV2,
  prefixLabelsV2,
  targetLabelsV2,
  whenContextLabels,
  whenContextLabelsV2,
  whenLabelsV2,
} from '../workflowLabels';
import { TimeUnitEnum } from '../useTimeUnits';
import { QRCodeCheckinFeatureProperties } from 'lane-shared/types/features/QRCodeCheckinFeatureProperties';

type Props = {
  workflow: Workflow;
  content: { type: ContentTypeEnum };
  hasCustomText: boolean;
  qrCodeCheckinFeature: QRCodeCheckinFeatureProperties | undefined;
  channelName: string;
  groupRoleName: string;
  userName: string;
  webhookName: string;
  timeUnit: TimeUnitEnum;
  timeValue: number;
  targetSelectUserTypeName: string;
  t: (key: string, object?: {}) => string;
};

export const getDisplayNameFromWorkflow = ({
  workflow,
  content,
  hasCustomText,
  qrCodeCheckinFeature,
  timeUnit,
  timeValue,
  channelName,
  groupRoleName,
  userName,
  webhookName,
  targetSelectUserTypeName,
  t,
}: Props) => {
  const { when, event, whenContext, action, target, payload } = workflow;

  const strArr = [];

  if (workflow.type === WorkflowTypeEnum.ServiceRequestEscalation) {
    strArr.push(t(prefixLabelsV2[whenContext]));
    strArr.push(t(workflow.workflow?.value!));
    strArr.push(t(whenContextLabelsV2[whenContext]));
    strArr.push(`${timeValue} ${timeUnit}`);
    strArr.push(t(actionLabelsV2[action]));
    strArr.push(t(targetLabelsV2[target]));

    return strArr.join(' ');
  }

  if (when === WorkflowWhenEnum.After || when === WorkflowWhenEnum.Before) {
    strArr.push(`${timeValue} ${timeUnit}`);
  }

  if (event !== EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION) {
    strArr.push(t(whenLabelsV2[when]));
  }

  strArr.push(t(whenContextLabels[whenContext]));

  if (event !== EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION) {
    strArr.push(t(eventLabelsV2[event]));
  }

  if (event === EVENT_CONTENT_INTERACTION_STATUSCHANGE) {
    strArr.push(
      `${workflow.workflow?.statusFrom} ${t(
        'web.admin.channel.content.workflow.transition'
      )} ${workflow.workflow?.statusTo}`
    );
  }

  if (event === EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION) {
    strArr.push(
      workflow.workflow?.value === ServiceRequestStatusEnum.Created
        ? ServiceRequestStatusEnum.Open
        : workflow.workflow?.value
    );
  }

  if (event && event !== EVENT_WORK_ORDER_SERVICE_REQUEST_ESCALATION) {
    strArr.push(t(actionLabelsV2[action]));

    if (action === WorkflowActionEnum.Webhook) {
      strArr.push(
        t(
          'web.admin.channel.content.workflow.editor.v2.workflow.label.webhookName',
          { webhookName }
        )
      );
    }

    if (
      [
        WorkflowActionEnum.SMS,
        WorkflowActionEnum.Email,
        WorkflowActionEnum.Notification,
        WorkflowActionEnum.NotificationAndEmail,
      ].includes(action)
    ) {
      if (content?.type !== ContentTypeEnum.WorkOrder) {
        strArr.push(targetSelectUserTypeName || t(targetLabelsV2[target]));

        if (target === WorkflowTargetEnum.User) {
          strArr.push(
            t(
              'web.admin.channel.content.workflow.editor.v2.workflow.label.userName',
              { userName }
            )
          );
        }

        if (target === WorkflowTargetEnum.GroupRole) {
          strArr.push(
            t(
              'web.admin.channel.content.workflow.editor.v2.workflow.label.groupRoleName',
              { channelName, groupRoleName }
            )
          );
        }
      }

      if (
        [WorkflowActionEnum.SMS, WorkflowActionEnum.Notification].includes(
          action
        ) &&
        hasCustomText
      ) {
        strArr.push(
          `${t('web.admin.channel.content.workflow.custom-action.using')} ${t(
            action === WorkflowActionEnum.SMS
              ? 'web.admin.channel.content.workflow.custom-action.sms'
              : 'web.admin.channel.content.workflow.custom-action.notification'
          )}`
        );
      }

      if (
        action === WorkflowActionEnum.Email &&
        (hasCustomText || payload?.intro || payload?.outtro || payload?.body)
      ) {
        strArr.push(
          `${t('web.admin.channel.content.workflow.custom-action.with')} ${t(
            'web.admin.channel.content.workflow.custom-action.email'
          )}`
        );
      }

      if (payload?.qrCodeCheckIn && qrCodeCheckinFeature) {
        strArr.push(
          t('web.admin.channel.content.workflow.custom-action.qr-check')
        );
      }
    }
  }

  return strArr.join(' ');
};
