import React, { useContext, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';
import { EssensysSubmitOnBehalfOfError } from 'activate-errors';
import { getPaymentFeatureCurrency } from 'lane-shared/helpers/features';
import { PaymentDetailsType } from 'lane-shared/helpers/getPaymentDetailsWithQuote';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';
import { PaymentFeatureProperties } from 'lane-shared/types/features/PaymentFeatureProperties';
import { PaymentAccountType } from 'lane-shared/types/payment/PaymentAccount';
import {
  EssensysPaymentSettingsType,
  PaymentFeatureQuoteType,
} from 'lane-shared/types/payment/PaymentFeatureQuoteType';
import { PaymentProviderEnum } from 'lane-shared/types/payment/PaymentProviderEnum';

import { CurrencyInput, Toggle } from 'components/form';
import { Button, ErrorMessage } from 'components/general';
import { Typography } from 'components/lds';
import EssensysPaymentMethodSelect from 'components/payments/EssensysPaymentMethodSelect';
import PaymentMethodSelectV2 from 'components/payments/PaymentMethodSelectV2';

import PlacePayPaymentModal from '../../payments/PlacePayPaymentModal';
import PaymentFeatureQuote from '../PaymentFeatureQuote';
import useUserLocale from 'hooks/useUserLocale';

type ActionProps = {
  onSubmit: () => void;
  loading: boolean;
  purchaseDisabled: boolean;
};

export function PaymentFeatureModalConfirmationMainActions({
  onSubmit,
  loading,
  purchaseDisabled,
}: ActionProps) {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      loading={loading}
      disabled={purchaseDisabled}
      onClick={onSubmit}
      fullWidth
      testId="purchaseNonMenuButton"
    >
      {t('Purchase')}
    </Button>
  );
}

type Props = {
  content: any;
  interaction: any;
  isPlacePayOpen: boolean;
  setIsPlacePayOpen: React.Dispatch<React.SetStateAction<boolean>>;
  paymentAccounts: PaymentAccountType[];
  selectedPaymentAccount: PaymentAccountType | null;
  setSelectedPaymentAccount: React.Dispatch<
    React.SetStateAction<PaymentAccountType | null>
  >;
  essensysPaymentSettings?: EssensysPaymentSettingsType;
  selectedCard: any;
  setSelectedCard: (...args: any) => void;
  isEssensys: boolean;
  isQuoteReady: boolean;
  paymentFeature: PaymentFeatureProperties;
  paymentDetails: PaymentDetailsType;
  paymentMethods?: any[];
  selectedPaymentMethod: any;
  setSelectedPaymentMethod: React.Dispatch<any>;
  quote: PaymentFeatureQuoteType;
  setModalHistory: React.Dispatch<any>;
  onPaymentCancelled: () => void;
  onPaymentFailed: (err: any) => void;
  onPaymentFeatureUpdated: (...args: any) => void;
  essensysError: Error | null;
  error: Error | null;
  onUpdateItem: (props: any) => void;
  setInitialQuote: (props: any) => void;
};

export default function PaymentFeatureModalConfirmationMain({
  content,
  interaction,
  quote,
  isPlacePayOpen,
  setIsPlacePayOpen,
  setModalHistory,
  onPaymentCancelled,
  onPaymentFailed,
  onPaymentFeatureUpdated,
  essensysPaymentSettings,
  selectedCard,
  setSelectedCard,
  selectedPaymentAccount,
  setSelectedPaymentAccount,
  isEssensys,
  paymentFeature,
  paymentDetails,
  paymentAccounts,
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  essensysError,
  error,
  onUpdateItem,
  setInitialQuote,
}: Props) {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const { user } = useContext(UserDataContext);
  const placePayRef = useRef({
    reject: () => null,
    resolve: () => null,
  });

  // get any applicable rules for this team role
  const applicableRules =
    paymentFeature?.rules?.filter(rule =>
      user?.roles.some(role => role.groupRole?._id === rule.groupRole?._id)
    ) || [];

  const canOverridePrice = applicableRules.some(rule => rule.canOverridePrice);

  const userName =
    interaction.features[FeatureNameEnum.SubmitOnBehalfOf]?.user?.name || '';

  const invalidSubmitOnBehalfOf =
    interaction.features[FeatureNameEnum.SubmitOnBehalfOf]?.user?._id &&
    essensysPaymentSettings &&
    !essensysPaymentSettings.isOnAccount;

  const contentChannel = {
    channel: {
      _id: content?.channelId || '',
      name: content?.channel?.name || '',
      address: content?.channel?.address || {
        street1: '',
        street2: '',
        city: '',
        country: '',
        code: '',
        state: '',
      },
    },
  };

  // setInitialQuote will update the quote if it changes
  // onUpdateItem will make changes to the item data
  return (
    <>
      <ErrorMessage error={error} />
      {invalidSubmitOnBehalfOf && (
        <ErrorMessage error={new EssensysSubmitOnBehalfOfError()} />
      )}
      {userName && (
        <Typography type="m" mode="primary">
          {t('Submitting on behalf of {{userName}}', { userName })}
        </Typography>
      )}
      <PaymentFeatureQuote
        quote={quote}
        setInitialQuote={setInitialQuote}
        onUpdateItem={onUpdateItem}
        content={content}
        channel={contentChannel}
      />
      {canOverridePrice && (
        <>
          <Toggle
            value={Boolean(interaction.features?.Payment?.overridePrice)}
            onChange={() =>
              onPaymentFeatureUpdated({
                overridePrice: !interaction.features?.Payment?.overridePrice,
                overriddenAmount: quote?.total,
                amount: quote?.total,
              })
            }
            text={t('Override price')}
            doTranslate
          />
          {interaction.features?.Payment?.overridePrice && (
            <CurrencyInput
              value={interaction.features?.Payment?.amount || 0}
              locale={locale}
              currency={getPaymentFeatureCurrency(paymentFeature)}
              onValueChange={amount =>
                onPaymentFeatureUpdated({
                  amount,
                })
              }
            />
          )}
        </>
      )}
      {isEssensys && paymentDetails ? (
        <EssensysPaymentMethodSelect
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'EssensysPaymentSettingsType | null' is not a... Remove this comment to see the full error message
          essensysInfo={quote?.data}
          essensysError={essensysError}
          selectedCard={selectedCard}
          paymentDetails={paymentDetails}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'PaymentAccountType | null' is not assignable... Remove this comment to see the full error message
          selectedPaymentAccount={selectedPaymentAccount}
          onPaymentAccountSelected={paymentAccount =>
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'PaymentAccountTypeSummary' is no... Remove this comment to see the full error message
            setSelectedPaymentAccount(paymentAccount)
          }
          onCardSelected={card => setSelectedCard(card)}
        />
      ) : (
        <PaymentMethodSelectV2
          paymentAccounts={paymentAccounts}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'PaymentAccountType | null' is not assignable... Remove this comment to see the full error message
          selectedPaymentAccount={selectedPaymentAccount}
          onSelectPaymentAccount={setSelectedPaymentAccount}
          paymentMethods={paymentMethods}
          selectedPaymentMethod={selectedPaymentMethod}
          onSelectPaymentMethod={setSelectedPaymentMethod}
          onConfigure={(configureProvider, options) => {
            switch (configureProvider) {
              case PaymentProviderEnum.Stripe: {
                if (options.flow != null) {
                  setModalHistory((prevModalHistory: any) => [
                    ...prevModalHistory,
                    options.flow,
                  ]);
                }
              }
            }
          }}
        />
      )}

      <PlacePayPaymentModal
        isOpen={isPlacePayOpen}
        paymentAccountId={selectedPaymentAccount?._id}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        payeeId={essensysPaymentSettings?.orgpayeeid}
        amount={
          quote?.data?.overage
            ? quote?.data?.overage?.finalPriceInCents
            : quote?.total + quote?.tax
        }
        description={content?.name}
        onClose={() => {
          setIsPlacePayOpen(false);
          onPaymentCancelled();
          placePayRef.current.reject();
        }}
        onPaymentSuccess={data => {
          setIsPlacePayOpen(false);
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          placePayRef.current.resolve(data);
        }}
        onPaymentFailed={err => {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          placePayRef.current.reject(err);
          onPaymentFailed(err);
          setIsPlacePayOpen(false);
        }}
      />
    </>
  );
}
