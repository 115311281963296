import React, { useMemo, useState, useContext, useRef } from 'react';

import { Icon, Input } from 'design-system-web';
import { useIsAdminView, useSwitchChannel } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import { UserDataContext, ChannelsContext } from 'lane-shared/contexts';
import { buildUserChannelHierarchy } from 'lane-shared/helpers/user';
import { useChannelProfileQuery } from 'lane-shared/hooks';
import useLocation from 'lane-shared/hooks/location/useLocation';

import ContextMenu from '../general/ContextMenu';
import ChannelCircleListView from '../lane/ChannelCircleListView';
import ChannelMenuItem from './ChannelMenuItem';
import StaleChannelDataNotification from './StaleChannelDataNotification';
import ViewSwitchButton from './ViewSwitchButton';

import styles from './ChannelSwitcher.scss';
import { useUnderConstructionAnalytics } from 'lane-shared/hooks/analytics';

export default function ChannelSwitcher() {
  const { t } = useTranslation();

  const { user } = useContext(UserDataContext);
  const [isAdminView, channelSlug] = useIsAdminView();
  const { location } = useLocation();
  const {
    channels,
    primaryChannel,
    secondaryChannel,
    switchChannel,
    pages,
    isReady,
  } = useContext(ChannelsContext);

  const { channel: adminChannel } = useChannelProfileQuery({
    channelId: channelSlug,
  });

  const { underConstructionTracker } = useUnderConstructionAnalytics();

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 350);
  const searchRef = useRef<HTMLInputElement>(null);

  const parents = useMemo(
    () =>
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ roles: UserGroupRoleType[] | u... Remove this comment to see the full error message
      buildUserChannelHierarchy({
        roles: user?.roles,
        channels,
        location,
        search,
      }),
    [user, channels, location, debouncedSearch]
  );

  const isCurrentlyViewingUnderConstruction =
    user &&
    isReady &&
    channels.length &&
    !pages.length &&
    window.location.pathname === '/l/home';

  const { onChannelChange } = useSwitchChannel({ user, switchChannel });

  const items: React.ReactNode = [
    <div
      key="button"
      className={styles.searchWrapper}
      role="presentation"
      onClick={e => e.stopPropagation()}
    >
      <Input
        ref={searchRef}
        className={styles.searchInput}
        icon="search"
        placeholder={t('Search')}
        value={search}
        onChange={search => setSearch(search)}
        showClear
      />
    </div>,
    ...parents.map(parent => (
      <ChannelMenuItem
        key={parent._id}
        channel={parent}
        className={styles.parent}
        onChannelChange={channel => {
          if (isCurrentlyViewingUnderConstruction) {
            underConstructionTracker.Exit.ChannelSwitcher({
              availableChannels: channels,
            });
          }

          onChannelChange(channel);
        }}
        forAdmin={!!isAdminView}
        isTopLevel
      />
    )),
  ];

  return (
    <ContextMenu
      menuStyle={{ left: 'unset', right: 0 }}
      id="channel-switcher"
      menuClassName={styles.contextMenu}
      items={items}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '"dropdown"' is not assignable to type 'Modal... Remove this comment to see the full error message
      align="dropdown"
      onMenuOpen={() => {
        setTimeout(() => {
          if (searchRef.current) {
            searchRef.current.focus();
          }
        }, 250);
      }}
      fixedItem={
        <>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType | null' is not assignable to typ... Remove this comment to see the full error message */}
          <ViewSwitchButton channel={primaryChannel} />
          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'style' is missing in type '{ channel: an... Remove this comment to see the full error message */}
          {!isAdminView && <ViewSwitchButton channel={secondaryChannel} />}
          <StaleChannelDataNotification />
        </>
      }
      autoFocus={false}
      autoClose
    >
      <div
        className={styles.switcher}
        data-is-admin={!!isAdminView}
        role="button"
      >
        <div className={styles.clickableArea}>
          {adminChannel && (
            <ChannelCircleListView
              channel={adminChannel}
              className={styles.channelList}
            />
          )}

          {!adminChannel && primaryChannel && (
            <ChannelCircleListView
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType' is not assignable to type '{ _h... Remove this comment to see the full error message
              channel={primaryChannel}
              className={styles.channelList}
            />
          )}
          <Icon name="chevron-down" className={styles.chevronDown} />
        </div>
      </div>
    </ContextMenu>
  );
}
