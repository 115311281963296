import { ReservableUnitTypesEnum } from 'lane-shared/types/features/ReservableFeatureProperties';

type ReservationType = {
  unitType: string;
  units: number;
};

export default function convertToHourly(
  reservation: ReservationType | undefined,
  price: number
): number {
  if (
    !reservation ||
    reservation.unitType !== ReservableUnitTypesEnum.Minutes ||
    reservation.units <= 0
  ) {
    return price;
  }

  const multiplier = 60 / reservation.units;

  return price * multiplier;
}
