import React from 'react';

import { i18n } from 'i18next';
import { z } from 'zod';

import { Mjml, MjmlBody } from 'lane-shared/emails/MjmlComponentsV2';
import {
  EmailHorizontalRule,
  EmailSpacer,
} from 'lane-shared/emails/components';

import {
  EmailVisitorHeader,
  EmailVisitorFooter,
  EmailVisitorHero,
  EmailVisitorGreeting,
  EmailVisitorHostDetails,
  EmailVisitorNotesSummary,
} from './components';
import { AlertType, EmailVisitorAlert } from './components/EmailVisitorAlert';
import { EmailSubmissionVisitorsDetails } from './components/EmailSubmissionVisitorsDetails';
import {
  buildingSchema,
  hostSchema,
  visitorSchema,
  whitelabelSchema,
  visitorPassSchema,
} from './schema';
import { RecurrenceEventStatus } from '../types';

const ActivateVisitorSubmissionParams = z.object({
  building: buildingSchema,
  host: hostSchema,
  visitors: z.array(visitorSchema),
  submissionDetails: visitorPassSchema.pick({
    startDate: true,
    endDate: true,
    startTime: true,
    endTime: true,
    notesToVisitor: true,
    notesToStaff: true,
  }),
  organizer: hostSchema.optional(),
  whitelabel: whitelabelSchema.optional(),
  schedule: z.string().optional(),
  passDates: z.array(z.string()).optional(),
  recurrenceStatus: z.nativeEnum(RecurrenceEventStatus),
  additionalRecipients: z.array(z.string()).optional(),
});

export type EmailHostSubmissionProps = {
  title: string;
  params: z.infer<typeof ActivateVisitorSubmissionParams>;
  i18n: i18n;
};

export function EmailHostSubmission({
  title,
  params,
  i18n,
}: EmailHostSubmissionProps) {
  try {
    params = ActivateVisitorSubmissionParams.parse(params);
  } catch (error) {
    console.error(error);

    throw error;
  }

  const targetName = params.organizer?.firstName || params.host.firstName;

  const recurrenceIsCancelled: Boolean = [
    RecurrenceEventStatus.RECURRENCE_EVENT_STATUS_CANCELLED,
  ].includes(params.recurrenceStatus);

  const greetingDetailsTranslation =
    params.visitors.length > 1
      ? 'shared.email.visitorManagement.hostSubmission.greeting.multiple'
      : 'shared.email.visitorManagement.hostSubmission.greeting';

  let details: JSX.Element | undefined;

  switch (params.recurrenceStatus) {
    case RecurrenceEventStatus.RECURRENCE_EVENT_STATUS_CANCELLED:
      details = undefined;
      break;
    default:
      details = (
        <>
          {i18n.t(greetingDetailsTranslation, {
            buildingName: params.building.name,
          })}
        </>
      );
  }

  return (
    <Mjml>
      <EmailVisitorHeader
        title={title}
        primaryColor={params.building.primaryColor}
      />
      <MjmlBody background-color="#F5F6F7">
        <EmailSpacer />

        <EmailVisitorHero backgroundUrl={params.building.visitorHeroUri} />

        <EmailVisitorGreeting
          salutation={i18n.t('shared.email.visitorManagement.greeting', {
            firstName: targetName,
          })}
          details={details}
        />

        {recurrenceIsCancelled && (
          <EmailVisitorAlert type={AlertType.error}>
            <b style={{ color: '#0F121F' }}>
              {i18n.t(
                'shared.email.visitorManagement.hostSubmission.recurrence.cancelled'
              )}
            </b>
          </EmailVisitorAlert>
        )}

        <EmailHorizontalRule />

        <EmailSubmissionVisitorsDetails
          i18n={i18n}
          visitors={params.visitors}
          submissionDetails={params.submissionDetails}
          building={params.building}
          schedule={params.schedule}
          passDates={params.passDates}
        />

        <EmailHorizontalRule />

        <EmailVisitorHostDetails i18n={i18n} host={params.host} />

        <EmailHorizontalRule />

        <EmailVisitorNotesSummary
          i18n={i18n}
          visitorPass={params.submissionDetails}
        />
        <EmailVisitorFooter
          i18n={i18n}
          primaryColor={params.building.primaryColor}
          building={params.building}
          whitelabel={params.whitelabel}
        />
        <EmailSpacer />
      </MjmlBody>
    </Mjml>
  );
}
