import React, { useState } from 'react';
import { BulkUsers } from './BulkUsers';
import { InputType, InputTypeEnum, AddUserType } from './types';
import { useTranslation } from 'react-i18next';
import { useChannelGroupRolesQuery } from 'lane-shared/hooks';
import { UserStatusEnum } from 'lane-shared/types/User';
import { routes } from 'lane-shared/config';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getClient } from 'lane-shared/apollo';
import { createUserAndAddToTeams } from 'lane-shared/graphql/user';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { useQuery } from '@apollo/client';
import { getBuildingTenantChannels } from 'lane-shared/graphql/query';
import { BuildingTenantChannel } from 'graphql-resolver-contracts';

export function UserBulkAdd(channelInput: { channel: any }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const history = useHistory();
  const match = useRouteMatch();

  const { groupRoles } = useChannelGroupRolesQuery({
    channelId: channelInput.channel._id,
    includeCounts: true,
    includeWorkplaceMember: true,
  });
  const channelId = channelInput.channel._id;
  const isPropertyChannel =
    channelInput.channel.type === ChannelTypeEnum.Property;

  const {
    data: buildingTenants,
  }: {
    data?: { getBuildingTenantChannels: BuildingTenantChannel[] };
  } = useQuery(getBuildingTenantChannels, {
    variables: { channelId },
    skip: !isPropertyChannel,
  });

  const tenants = buildingTenants?.getBuildingTenantChannels ?? [];

  const translationPrefix = 'web.admin.channel.teamManagement.team.bulkAdd';

  const initialColumns: AddUserType = {
    name: '',
    email: '',
    phoneNumber: '',
    marketingOptIn: false,
    teams: [],
    userStatus: UserStatusEnum.Active,
    tenant: '',
  };

  const inputs: InputType[] = [
    {
      label: `${translationPrefix}.name.label`,
      placeholder: `${translationPrefix}.name.placeholder`,
      key: 'name',
      type: InputTypeEnum.String,
    },
    {
      label: `${translationPrefix}.email.label`,
      placeholder: `${translationPrefix}.email.placeholder`,
      key: 'email',
      type: InputTypeEnum.String,
    },
    {
      label: `${translationPrefix}.phoneNumber.label`,
      placeholder: `${translationPrefix}.phoneNumber.placeholder`,
      key: 'phoneNumber',
      type: InputTypeEnum.String,
    },
    {
      label: `${translationPrefix}.teams.label`,
      placeholder: `${translationPrefix}.teams.placeholder`,
      key: 'teams',
      type: InputTypeEnum.MultiSelect,
      items: groupRoles.map(role => {
        return { label: role.name, value: role._id };
      }),
    },
    {
      label: `${translationPrefix}.marketingOptIn.label`,
      placeholder: `${translationPrefix}.marketingOptIn.placeholder`,
      key: 'marketingOptIn',
      type: InputTypeEnum.Boolean,
    },
    {
      label: `${translationPrefix}.userStatus.label`,
      placeholder: `${translationPrefix}.userStatus.placeholder`,
      key: 'userStatus',
      type: InputTypeEnum.Dropdown,
      items: Object.keys(UserStatusEnum)
        .filter(status => status !== UserStatusEnum.System)
        .map(status => {
          return {
            label: `${translationPrefix}.userStatus.${status.toLowerCase()}`,
            value: status,
          };
        }),
    },
    ...(isPropertyChannel
      ? [
          {
            label: `${translationPrefix}.tenant.label`,
            placeholder: `${translationPrefix}.tenant.placeholder`,
            key: 'tenant',
            type: InputTypeEnum.Dropdown,
            items: tenants.map(tenant => {
              const channelName = tenant?.name ?? '';

              return {
                label: channelName,
                value: channelName,
              };
            }),
          },
        ]
      : []),
  ];

  function onCancel() {
    const cancelRoute =
      match.path === routes.channelAdminTeamsBulkAdd
        ? routes.channelAdminTeams
        : routes.channelAdminUsersList;

    history.push(cancelRoute.replace(':id', channelId));
  }

  async function onSubmit(rows: AddUserType[]) {
    setLoading(true);

    try {
      for (const row of rows) {
        // Add user to teams within this channel
        await getClient().mutate({
          mutation: createUserAndAddToTeams,
          variables: {
            name: row.name,
            email: row.email,
            phoneNumber: row.phoneNumber,
            emailOptIn: row.marketingOptIn,
            userStatus: row.userStatus,
            teamsToAdd: row.teams.map(team => team.label),
            channelId: convertToUUID(channelId),
            tenant: row.tenant,
          },
        });
      }

      // Redirect to main page
      onCancel();

      window.Toast.show(
        t('web.pages.portal.admin.channel.team.bulkAdd.toast.success', {
          count: rows.length,
        })
      );
    } catch (err) {
      console.error(err);
      await window.Alert.alert({
        title: t(`Failed`),
        message: t(`Adding users failed`),
        error: err,
      });
    }

    setLoading(false);
  }

  return (
    <BulkUsers
      loading={loading}
      channelInput={channelInput}
      initialColumns={initialColumns}
      title={t('web.pages.portal.admin.channel.team.bulkAdd.title', {
        channelName: channelInput.channel.name,
      })}
      buttonTitle="web.admin.channel.teamManagement.team.bulkAdd.importActionButton"
      onSubmit={onSubmit}
      onCancel={onCancel}
      inputs={inputs}
      url={routes.channelAdminTeamsView.replace(':id', channelId)}
      breadcrumbTitle={t(
        'web.admin.channel.teamManagement.team.bulkAdd.breadCrumbTitle'
      )}
    />
  );
}
