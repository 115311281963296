import { getDisplayName } from '../index';

type User = {
  name: string;
  profile: {
    image: string;
    logo: string;
    name: string;
  };
};

export function nameFormatter(user: User) {
  const nameArr = getDisplayName(user).split(' ');

  if (!nameArr.length) {
    return '';
  }

  const formattedName = nameArr
    .reduce((acc, next, index) => {
      if (index === 0) {
        return acc;
      }

      return `${acc} ${next}`;
    }, '')
    .trim();

  return `${nameArr[0]}\n${formattedName}`;
}
