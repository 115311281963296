import { useEffect, useContext, useMemo, useState } from 'react';

import { ChannelsContext } from '../../contexts';
import { getTimeZoneByGeoLocation } from '../../helpers';
import constructSearchOptions from '../../helpers/constructSearchOptions';
import { parseDate } from '../../helpers/dates';
import { PresetContentFilter } from '../../types/filters/PresetContentFilter';
import { SearchOptions } from '../../types/filters/SearchOptions';
import useStoredState from '../useStoredState';
import storedSearchOptionsParser from './storedSearchOptionsParser';
import {
  UseSectionSearchOptionsReturn,
  UseSectionSearchOptionsProps,
  SectionChannelType,
  SectionType,
  ChannelUpdateType,
  ChannelTransactionState,
  ChannelTransactionData,
} from './types';
import cloneDeep from 'lodash/cloneDeep';
import useFlag from '../useFlag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

const DUMMY_UPDATED_DATE = new Date();

export default function useSectionSearchOptions({
  sectionId,
  section,
  channels,
  location,
  autoApply,
  defaultSearchOptions,
}: UseSectionSearchOptionsProps): UseSectionSearchOptionsReturn {
  const searchOptions = useMemo<{
    searchOptions: SearchOptions | null;
    editingSearchOptions: SearchOptions | null;
    initialSearchOptions: SearchOptions | null;
    hasDoneFirstLoad: boolean;
    selectedChannel: SectionChannelType | null;
  }>(
    () => ({
      searchOptions: null,
      editingSearchOptions: null,
      initialSearchOptions: null,
      hasDoneFirstLoad: false,
      selectedChannel: null,
    }),
    [sectionId]
  );

  const [
    channelTransaction,
    setChannelTransaction,
  ] = useState<ChannelTransactionState>({
    type: ChannelUpdateType.initial,
    data: null,
  });
  const [blockId, setBlockId] = useState('');
  const { primaryChannel, secondaryChannel } = useContext(ChannelsContext);
  const [lastRender, setLastRender] = useState(Date.now());
  const [
    selectedChannel,
    setSelectedChannel,
    isStoredChannelReady,
  ] = useStoredState<SectionChannelType | null>(
    `channel-${sectionId}${blockId ? `-${blockId}` : ''}`,
    null
  );

  const sectionUpdated = parseDate(section?._updated) || DUMMY_UPDATED_DATE;

  const [
    storedSearchOptions,
    setSearchOptions,
    isSearchOptionsReady,
  ] = useStoredState<SearchOptions | null>(`options-${sectionId}`, null, {
    parser: storedSearchOptionsParser,
    disableStorage: true,
  });

  useEffect(() => {
    if (section) {
      searchOptions.initialSearchOptions = remakeSearchOptions(section!);
    }
  }, [section?._id]);

  useEffect(() => {
    if (location && !location.noLocation) {
      updateSearchOptions({ geo: [location.longitude, location.latitude] });
      applySearchOptions();
    }
  }, [location?.noLocation]);

  useEffect(() => {
    if (isStoredChannelReady && !searchOptions.selectedChannel) {
      updateSelectedChannel(selectedChannel);
    }
  }, [isStoredChannelReady]);

  const isMLSSearchEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportSearch,
    false
  );

  // Starting the channel update
  function updateSelectedChannel(
    channel: SectionChannelType | null | undefined = null,
    blockId?: string | undefined
  ) {
    setBlockId(blockId ?? '');
    setChannelTransaction({
      type: ChannelUpdateType.start,
      data: {
        channel,
        blockId,
      },
    });
  }

  // Making the channel state update
  function commitChannelUpdate(props: ChannelTransactionData | null) {
    if (props?.channel) {
      searchOptions.selectedChannel = props?.channel;
    }

    setSelectedChannel(props?.channel ?? null);

    updateSearchOptions({
      channelId: props?.channel?._id || null,
    });

    if (
      isStoredChannelReady &&
      isSearchOptionsReady &&
      searchOptions.hasDoneFirstLoad
    ) {
      applySearchOptions();
    }
  }

  /*
   * We are seeing weird behaviour with stale state inside the channel update function
   *
   * To overcome this, we will separate:
   *
   * 1. [start] The action to start channel update (ie filter toggle)
   * 2. [commit] making the state update
   * 3. [reset] reset to original state
   *
   * Moving that logic into useEffect in the local component helps to prevent this issue.
   *
   * useEffect only runs after update has been completed (or after a render).
   *
   * * */
  useEffect(() => {
    // Skip effect is it is in default state
    if (channelTransaction?.type === ChannelUpdateType.initial) return;

    if (channelTransaction?.type === ChannelUpdateType.start) {
      commitChannelUpdate(channelTransaction.data);
    }

    // Reset to default
    setChannelTransaction({ type: ChannelUpdateType.initial, data: null });
  }, [channelTransaction, setChannelTransaction, commitChannelUpdate]);

  const timeZone: string | null = useMemo(() => {
    if (selectedChannel) {
      const [latitude = 0, longitude = 0] = selectedChannel.address?.geo ?? [];

      return getTimeZoneByGeoLocation({ latitude, longitude });
    }

    if (section?.channel) {
      const [latitude = 0, longitude = 0] = section.channel.address?.geo ?? [];

      return getTimeZoneByGeoLocation({ latitude, longitude });
    }

    return null;
  }, []);

  function remakeSearchOptions(resetSection: SectionType) {
    return {
      ...constructSearchOptions(
        {
          timeZone,
          content: [],
          filters: resetSection.filters,
          sorts: resetSection.sorts,
          contentTags: [],
          metatags: resetSection.sectionMetatags?.map(
            sectionMetatag => sectionMetatag.metatag
          ),
          location: location ? [location.longitude, location.latitude] : null,
          channelId: selectedChannel?._id || undefined,
        },
        defaultSearchOptions
      ),
      _updated: sectionUpdated,
    };
  }

  function resetSearchOptions(
    resetSection: SectionType | null | undefined = section
  ) {
    if (!resetSection || !resetSection?._id) {
      return;
    }

    const options: Record<string, any> = {
      ...searchOptions.editingSearchOptions,
    };

    // we want to keep some previous values from SearchOptions, but not all
    // of them.  Filters, sort, metatags may have changed, we want to
    // preserve some stuff like search, channelId that the user already set.

    const keepOptionKeys = ['search', 'sort', 'sortDirection', 'channelId'];

    const oldOptions: Partial<SearchOptions> & Record<string, any> = {};

    keepOptionKeys.forEach(key => {
      if (options?.[key]) {
        oldOptions[key] = options[key];
      }
    });

    const newOptions = remakeSearchOptions(resetSection);

    searchOptions.editingSearchOptions = {
      ...newOptions,
      ...oldOptions,
    };

    setLastRender(Date.now());
  }

  const hasChannelSelector = Boolean(
    section?.filters?.includes(PresetContentFilter.ByChannel)
  );

  const hasChannelLocationsSelector = Boolean(
    section?.filters?.includes(PresetContentFilter.ByChannelLocations)
  );

  const hasEventDateSelector = Boolean(
    section?.filters?.includes(PresetContentFilter.ByEventDate)
  );

  useEffect(() => {
    if (
      !searchOptions.hasDoneFirstLoad ||
      !isSearchOptionsReady ||
      !isStoredChannelReady ||
      searchOptions.selectedChannel ||
      channels.length === 0
    ) {
      return;
    }

    // if no channel is selected, try to find the current primary channel,
    if (
      primaryChannel &&
      channels?.some(channel => channel._id === primaryChannel?._id)
    ) {
      updateSelectedChannel(primaryChannel);
    } else if (
      secondaryChannel &&
      channels?.some(channel => channel._id === secondaryChannel?._id)
    ) {
      updateSelectedChannel(secondaryChannel);
    } else if (section?.channel) {
      // otherwise set to the current top level channel.
      updateSelectedChannel(section.channel);
    }
  }, [
    channels,
    hasChannelSelector,
    primaryChannel?._id,
    secondaryChannel?._id,
    section?.channel?._id,
    selectedChannel?._id,
    isStoredChannelReady,
    isSearchOptionsReady,
    searchOptions.hasDoneFirstLoad,
  ]);

  // does this section have a search box?
  const hasSearch = Boolean(
    section?.filters?.includes(PresetContentFilter.SearchBox)
  );

  // does this section has a filters button? SearchBox, By Channel Location and By Channel aren't
  // in a filter popup.
  const hasFilters = Boolean(
    (section?.sectionMetatags?.length ?? 0) > 0 ||
      section?.filters?.some(
        filter =>
          ![
            PresetContentFilter.SearchBox,
            PresetContentFilter.ByChannelLocations,
            PresetContentFilter.ByChannel,
            PresetContentFilter.ByEventDate,
          ].includes(filter)
      )
  );

  function updateSearchOptions(props: Partial<SearchOptions>) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _updated?: Date | undefined; search?: stri... Remove this comment to see the full error message
    searchOptions.editingSearchOptions = {
      ...(searchOptions.editingSearchOptions || {}),
      ...props,
    };

    // delete the "includedMLSColumns" logic when deleting the feature flag.
    // we can just check for the _l10n columns only in the backend.
    if (
      searchOptions &&
      searchOptions.editingSearchOptions &&
      isMLSSearchEnabled
    ) {
      searchOptions.editingSearchOptions.includedMLSColumns = ['name_l10n'];
    }

    setLastRender(Date.now());
  }

  function applySearchOptions() {
    searchOptions.searchOptions = cloneDeep(searchOptions.editingSearchOptions);

    setSearchOptions(searchOptions.searchOptions);
  }

  useEffect(() => {
    if (
      section?._id &&
      isSearchOptionsReady &&
      (autoApply || !searchOptions.hasDoneFirstLoad)
    ) {
      if (
        !searchOptions.hasDoneFirstLoad &&
        !searchOptions?.editingSearchOptions?.filters?.length
      ) {
        const storedSearchOptionsExpired =
          !storedSearchOptions?._updated ||
          storedSearchOptions?._updated! < sectionUpdated;

        if (storedSearchOptionsExpired) {
          // the section has been updated since these stored search options
          // were created, reset them to all defaults.
          searchOptions.editingSearchOptions = remakeSearchOptions(section!);
        } else {
          searchOptions.editingSearchOptions = cloneDeep(storedSearchOptions);
        }

        searchOptions.hasDoneFirstLoad = true;
      }

      applySearchOptions();
    }
  }, [autoApply, lastRender, isSearchOptionsReady, section?._id]);

  return {
    searchOptions: searchOptions.searchOptions,
    editingSearchOptions: searchOptions.editingSearchOptions,
    initialSearchOptions: searchOptions.initialSearchOptions,
    hasChannelSelector,
    hasChannelLocationsSelector,
    hasEventDateSelector,
    isSearchOptionsReady,
    selectedChannel,
    hasSearch,
    hasFilters,
    updateSelectedChannel,
    updateSearchOptions,
    resetSearchOptions,
    applySearchOptions,
  };
}
