import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query getMostRecentMeterReadingValues($equipmentId: UUID!) {
    getMostRecentMeterReadingValues(equipmentId: $equipmentId) {
      createdAt
      value
      meterReadingSettingId
    }
  }
`);
