import * as yup from 'yup';

import { objectToArray } from 'lane-shared/helpers';
import Features from 'lane-shared/renderers/v5/features';
import { FeatureDefinition } from 'lane-shared/types/FeatureDefinition';
import { FeatureInstance } from 'lane-shared/types/FeatureInstance';

import { createShapeFromProperties } from '../properties';

const FEATURES_ARRAY: FeatureDefinition[] = objectToArray(Features);

type AmbiguousContent = {
  features: FeatureInstance[];
};

type GetFeatureValidationMapOptions = {
  nextFeatureState?: any;
  feature?: FeatureDefinition;
};

export default function getFeatureValidationErrorMapByContent(
  content: AmbiguousContent,
  {
    nextFeatureState = undefined,
    feature = undefined,
  }: GetFeatureValidationMapOptions = {}
): Map<string, yup.ValidationError | null> {
  if (feature && !nextFeatureState) {
    throw new Error(
      'If a `feature` is provided a `nextFeatureState` must be provided as well.'
    );
  }

  const validationMap = new Map<string, yup.ValidationError | null>();

  for (const featureInstance of content.features) {
    const currentFeature = FEATURES_ARRAY.find(
      f => f.name === featureInstance.type
    );

    if (currentFeature) {
      const featureValidatorShape = yup
        .object()
        .shape(createShapeFromProperties(currentFeature.properties));

      const value =
        currentFeature.name === feature?.name
          ? nextFeatureState
          : featureInstance.feature;

      try {
        featureValidatorShape.validateSync(value, { abortEarly: false });
        validationMap.set(currentFeature.name, null);
      } catch (err) {
        if (!(err instanceof yup.ValidationError)) {
          throw err;
        }

        validationMap.set(currentFeature.name, err);
      }
    }
  }

  return validationMap;
}
