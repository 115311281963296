import { NotFoundError, OAuthConfigError } from 'activate-errors';
import { OAuthProvidersEnum } from 'lane-shared/helpers/constants/user';
import { oAuthConfigScheme, OAuthConfigShape } from 'lane-shared/helpers/oAuth';
import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';

import OAuthService, { AuthorizeResult } from '../OAuth.service';
import { getAppleIDClientSecret } from '../helpers';
import {
  getItemFromStorage,
  removeItemFromStorage,
  setItemInStorage,
} from './storage';

type GetAppleAccessTokenProps = {
  oAuthConfig: OAuthConfigType | undefined;
  options?: {
    scopes?: string[];
    skipStorage?: boolean;
  };
};

const DEFAULT_SCOPES = ['name', 'email', 'openid'];

const APPLE_AUTH_STATE = `${OAuthProvidersEnum.APPLE}:AUTH_STATE`;

async function getAppleOAuthConfig(
  oAuthConfig: OAuthConfigType | any = {},
  scopes: string[]
): Promise<NonNullable<OAuthConfigShape>> {
  const {
    webClientId: clientId,
    webRedirectUrl: redirectUrl,
    provider,
  } = oAuthConfig;

  try {
    return oAuthConfigScheme.validateSync({
      ...oAuthConfig,
      issuer: 'https://appleid.apple.com',
      clientId,
      redirectUrl,
      scopes,
      provider,
    })!;
  } catch (err) {
    throw new OAuthConfigError();
  }
}

export async function getAppleAccessToken({
  oAuthConfig,
  options: { scopes = DEFAULT_SCOPES, skipStorage = false } = {
    scopes: DEFAULT_SCOPES,
    skipStorage: false,
  },
}: GetAppleAccessTokenProps): Promise<AuthorizeResult> {
  const appleConfig = await getAppleOAuthConfig(oAuthConfig, scopes);

  const appleOAuthService = new OAuthService({
    config: appleConfig,
    getClientSecret: async () => getAppleIDClientSecret(appleConfig.clientId),
  });

  if (!skipStorage) {
    try {
      const knownState = await getItemFromStorage(APPLE_AUTH_STATE);

      const accessTokenExpirationDate = new Date(
        knownState.accessTokenExpirationDate
      );

      if (new Date() < accessTokenExpirationDate) {
        return knownState;
      }

      if (knownState.refreshToken) {
        const refreshResult = await appleOAuthService.refresh(
          knownState.refreshToken
        );

        if (refreshResult) {
          setItemInStorage(APPLE_AUTH_STATE, refreshResult);

          return refreshResult;
        }

        // NOTE: Indicates failure to refresh, fallback
        // to `authorize` flow, remove known storage object
        // to prevent repeat events.
        await removeItemFromStorage(APPLE_AUTH_STATE);
      }
    } catch (err) {
      // NOTE: Swallow NotFoundError thrown by `getItem`
      // it will be set by `setItem` below.
      if (!(err instanceof NotFoundError)) {
        throw err;
      }
    }
  }

  const authState = await appleOAuthService.authorize();

  await setItemInStorage(APPLE_AUTH_STATE, authState);

  return authState;
}
