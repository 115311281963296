import { useEffect } from 'react';

import { PaymentDetailsType } from '../helpers/getPaymentDetailsWithQuote';
import { EssensysPaymentSettingsType } from '../types/payment/PaymentFeatureQuoteType';
import { PaymentProviderEnum } from '../types/payment/PaymentProviderEnum';
import useCreditCards from './useCreditCards';
import useMyPaymentAccountsQuery, { // @ts-expect-error ts-migrate(2614) FIXME: Module '"./useMyPaymentAccountsQuery"' has no expo... Remove this comment to see the full error message
  PaymentAccountTypeSummary,
} from './useMyPaymentAccountsQuery';

type Props = {
  essensysInfo: EssensysPaymentSettingsType | undefined;
  paymentDetails: Pick<PaymentDetailsType, 'total'> | undefined;
  onCardSelected?: (card: any) => void;
  onPaymentAccountSelected: (paymentAccount: PaymentAccountTypeSummary) => void;
  paymentAccounts?: PaymentAccountTypeSummary[];
  forInvoice: boolean;
  translate: any; // todo: get a type for the xlation function
};

export default function useEssensysPaymentMethodSelect({
  essensysInfo,
  paymentDetails,
  paymentAccounts,
  onPaymentAccountSelected,
  onCardSelected,
  forInvoice = false,
  translate = (value: any) => value,
}: Props) {
  const {
    myPaymentAccounts,
    loading: myPaymentAccountsLoading,
    error: myPaymentAccountsError,
  } = useMyPaymentAccountsQuery(PaymentProviderEnum.Essensys);

  const preferredPaymentAccounts = paymentAccounts ?? myPaymentAccounts;

  const stripeAccount = preferredPaymentAccounts?.find(
    paymentAccount => paymentAccount.type === PaymentProviderEnum.Stripe
  );

  const paymentAccountIds: string[] = [];

  if (stripeAccount?._id) {
    paymentAccountIds.push(stripeAccount._id);
  }

  const { cards, loading: stripeCardsLoading } = useCreditCards(
    paymentAccountIds
  );

  // Generate translation string and replacement key object
  let rawMessage;
  const translationKeys: any = {};

  if (essensysInfo?.isOnAccount) {
    rawMessage =
      'You are paying on account. You will be billed later via invoice.';
  }

  // Overage is only relevant when paying with credits
  if (essensysInfo?.isCredits) {
    rawMessage = 'You have {{creditsRemaining}} credit(s) remaining.';
    translationKeys.creditsRemaining = Math.max(
      Number(essensysInfo.creditsRemaining),
      0
    );

    if (essensysInfo?.overage) {
      const { overage } = essensysInfo;

      rawMessage +=
        ' You’ll be charged ${{overageFinalPrice}} for {{overageNumUnits}} additional credit(s) at ${{overagePricePerUnit}} each.';
      translationKeys.overageFinalPrice = overage.finalPrice.toFixed(2);
      translationKeys.overageNumUnits = Math.round(Number(overage.numUnits));
      translationKeys.overageProductName = overage.productName;
      translationKeys.overagePricePerUnit = overage.pricePerUnit.toFixed(2);
    } else {
      rawMessage += ' {{paymentTotal}} will be deducted from your account.';
      translationKeys.paymentTotal = paymentDetails?.total;
    }
  }

  // Automatically select payment account (or card) if only 1 available
  useEffect(() => {
    if (preferredPaymentAccounts?.length) {
      onPaymentAccountSelected(preferredPaymentAccounts[0]);
    }
  }, [preferredPaymentAccounts]);

  useEffect(() => {
    if (onCardSelected && cards?.length === 1) {
      onCardSelected(cards[0]);
    }
  }, [cards?.length]);

  // only show payment accounts if there is more than one option
  const showPaymentAccounts =
    preferredPaymentAccounts && preferredPaymentAccounts.length > 1;

  // does this require payment? or is this paid on account.
  const requiresPayment =
    forInvoice ||
    (!essensysInfo?.isOnAccount && essensysInfo?.isCash) ||
    (!essensysInfo?.isOnAccount &&
      essensysInfo?.isCredits &&
      essensysInfo?.overage);

  const message = translate(rawMessage, translationKeys);

  return {
    error: myPaymentAccountsError,
    loading: stripeCardsLoading || myPaymentAccountsLoading,
    paymentAccounts: preferredPaymentAccounts,
    stripeAccount,
    message,
    showPaymentAccounts,
    requiresPayment,
    cards,
  };
}
