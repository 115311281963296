import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { DateTime, Interval } from 'luxon';

import { UserDataContext } from '../contexts';
import { parseDateTime } from '../helpers/dates';
import { getMonthUnavailableDateRangesFromTimeAvailability } from '../helpers/features';
import {
  DAY_IN_MINUTES,
  getPositionFromTimeRange,
  mapOptions,
} from '../helpers/timeRangeSlider';
import { DateRangeType } from '../types/baseTypes/DateRangeType';
import { TimeAvailabilityFeatureProperties } from '../types/features/TimeAvailabilityFeatureProperties';

export type TimeRangePickerProps = {
  // a date range object that represents this time range
  value?: DateRangeType;
  // a date range object that represents an existing value, ie. maybe the
  // control is being used to change an existing selection.
  existingValue?: DateRangeType | null;
  // when the user is looking at a new day
  onDayChange?: (date: Date) => void;
  // when the user has selected a new time range
  onChange: (range: DateRangeType) => void;
  // the unit size in minutes that can be selected
  slotSize?: number;
  // the minimum time in minutes that can be selected
  minRangeSize?: number;
  // the maximum time in minutes that can be selected
  maxRangeSize?: number;
  // the earliest time that can be selected in minutes
  minTime?: number;
  // the latest time that can be selected in minutes
  maxTime?: number;
  // the earliest date that can be selected
  minDate?: Date;
  // the latest date that can be selected
  maxDate?: Date;
  // show the time slider?
  showTimeSlider?: boolean;
  // show a date picker to change days?
  showDatePicker?: boolean;
  // is the control currently disabled
  disabled?: boolean;
  // if options aren't available, do we bother showing them to the user?
  // i.e. maybe 12am to 8am isn't available, don't even show those
  displayAllOptions?: boolean;
  //  should the control show a loading state
  loading?: boolean;
  // an array of date ranges that are unavailable
  unavailableDateRanges?: DateRangeType[];
  // time availability feature if applicable
  timeAvailabilityFeature?: TimeAvailabilityFeatureProperties;
  // time zone to show dates in
  timeZone?: string;
  disabledWeekDays?: number[];
};

export default function useTimeRangePicker({
  existingValue,
  defaultOptionWidth,
  onChange,
  onDayChange,
  slotSize,
  minTime,
  maxTime,
  minRangeSize,
  maxRangeSize,
  unavailableDateRanges,
  timeAvailabilityFeature,
  displayAllOptions,
  timeZone,
}: any) {
  const { user } = useContext(UserDataContext);

  const minSlot = Math.floor((minTime || 0) / slotSize);
  const maxSlot = Math.ceil((maxTime || DAY_IN_MINUTES) / slotSize);

  // if there are no slots available at all, better to show the user all
  // slots as unavailable vs. nothing
  const shouldDisplayAllOptions = minSlot === maxSlot;

  const [optionWidth, setOptionWidth] = useState(defaultOptionWidth);
  const [isInvalid, setIsInvalid] = useState(false);

  const sliderInfoRef = useRef<{
    startDate: DateTime;
    endDate: DateTime;
    lastStartDate: DateTime | null;
    lastEndDate: DateTime | null;
    isDragging: boolean;
    isReady: boolean;
    optionWidth: number;
    lastX: number;
    side: 'right' | 'left' | 'slider' | null;
    position: {
      width: number;
      left: number;
    };
    lastPosition: {
      width: number;
      left: number;
    };
    beforeSnap: {
      startMinutes: number;
      endMinutes: number;
      width: number;
      left: number;
    };
    lastTap: number;
    lastDx: number;
    scrollX: number;
    scrollWidth: number;
    minSlot: number;
    maxSlot: number;
  }>({
    startDate: DateTime.local().setZone(timeZone).startOf('hour'),
    endDate: DateTime.local()
      .setZone(timeZone)
      .startOf('hour')
      .plus({ minutes: slotSize }),
    lastStartDate: null,
    lastEndDate: null,
    isDragging: false,
    isReady: false,
    lastX: 0,
    side: null,
    optionWidth,
    lastTap: 0,
    scrollX: 0,
    scrollWidth: optionWidth,
    lastDx: 0,
    beforeSnap: {
      startMinutes: 0,
      endMinutes: 0,
      width: 0,
      left: 0,
    },
    position: {
      width: optionWidth,
      left: 0,
    },
    lastPosition: {
      width: optionWidth,
      left: 0,
    },
    minSlot,
    maxSlot,
  });

  // store this in the ref, these maybe accessed later by a callback
  // pointing to the wrong values.
  sliderInfoRef.current.minSlot = minSlot;
  sliderInfoRef.current.maxSlot = maxSlot;

  // to reduce constant re-renders, only change the reference date when the
  // actual day value changes. (i.e. user is looking at a new day).
  const referenceDate: DateTime = useMemo(
    () => sliderInfoRef.current.startDate,
    [sliderInfoRef.current.startDate?.day]
  );

  const [calendarReferenceDate, setCalendarReferenceDate] = useState<DateTime>(
    referenceDate
  );

  const [unavailableMonthlyRanges, setUnavailableMonthlyRanges] = useState<
    DateRangeType[]
  >([]);

  function updateUnavailableRanges(day: Date) {
    const ranges = getMonthUnavailableDateRangesFromTimeAvailability(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateTime | null' is not assignab... Remove this comment to see the full error message
      parseDateTime(day),
      timeAvailabilityFeature,
      timeZone,
      user?.roles?.map(({ groupRole }) => groupRole._id) || []
    );

    setUnavailableMonthlyRanges(ranges);
  }

  useEffect(() => {
    if (calendarReferenceDate) {
      updateUnavailableRanges(calendarReferenceDate.toJSDate());
    }
  }, [calendarReferenceDate?.toMillis()]);

  // re-calculate the total # of slots only when slotSize changes.
  const slots = useMemo(() => Math.ceil(DAY_IN_MINUTES / slotSize), [slotSize]);

  // generate the time slider options only as certain values change.
  const allOptions = useMemo(
    () =>
      mapOptions({
        slotSize,
        slots,
        unavailableDateRanges,
        minSlot,
        maxSlot,
        date: referenceDate,
      }),
    [slotSize, slots, unavailableDateRanges, minSlot, maxSlot, referenceDate]
  );

  // get the time slider option slots based on the visible slots
  const options = useMemo(
    () =>
      displayAllOptions || shouldDisplayAllOptions
        ? allOptions
        : allOptions.filter(({ slot }) => slot >= minSlot && slot < maxSlot),
    [displayAllOptions, shouldDisplayAllOptions, allOptions]
  );

  function calcMinWidth(optionWidth: any) {
    return ((minRangeSize || slotSize) / slotSize) * optionWidth;
  }

  function calcMaxWidth(optionWidth: any) {
    return ((maxRangeSize || maxTime - minTime) / slotSize) * optionWidth;
  }

  // what is the min possible width of the slider
  const minWidth = calcMinWidth(optionWidth);
  // what is the maximum possible width of the slider
  const maxWidth = calcMaxWidth(optionWidth);

  function enforcePositionConstraints(position: any) {
    const minSlot = sliderInfoRef.current.minSlot;
    const maxSlot = sliderInfoRef.current.maxSlot;

    const enforcedPosition = {
      ...position,
    };

    // this copy of optionWidth will always point to the correct value.
    const { optionWidth } = sliderInfoRef.current;

    const minWidth = ((minRangeSize || slotSize) / slotSize) * optionWidth;
    const maxWidth =
      ((maxRangeSize || maxTime - minTime) / slotSize) * optionWidth;

    // stop from going outside of the left bounds
    if (enforcedPosition.left < 0) {
      enforcedPosition.left = 0;
    }

    // enforce min width
    if (enforcedPosition.width < minWidth) {
      enforcedPosition.width = minWidth;
    }

    // enforce max width
    if (enforcedPosition.width > maxWidth) {
      enforcedPosition.width = maxWidth;
    }

    // stop from going outside of the right points.
    if (
      enforcedPosition.width + enforcedPosition.left >
      optionWidth * (maxSlot - minSlot)
    ) {
      enforcedPosition.left =
        optionWidth * (maxSlot - minSlot) - enforcedPosition.width;
    }

    return enforcedPosition;
  }

  // make sure the start date is always before the end date
  function enforceDates(startDate: any) {
    const _startDate = parseDateTime(startDate, timeZone) as DateTime;
    const duration = sliderInfoRef.current.endDate.diff(
      sliderInfoRef.current.startDate
    );

    return {
      startDate: _startDate.toJSDate(),
      endDate: _startDate.plus(duration).toJSDate(),
    };
  }

  function updateCalendarReferenceDate(date: Date | DateTime) {
    if (!date) {
      return;
    }

    setCalendarReferenceDate(parseDateTime(date, timeZone) as DateTime);
  }

  // when the user changes the day they are looking at.
  function changeDay(newDate: any) {
    const _newDate = parseDateTime(newDate, timeZone) as DateTime;

    const startDate = _newDate.set({
      hour: sliderInfoRef.current.startDate.hour,
      minute: sliderInfoRef.current.startDate.minute,
    });

    const endDate = _newDate.set({
      hour: sliderInfoRef.current.endDate.hour,
      minute: sliderInfoRef.current.endDate.minute,
    });

    sliderInfoRef.current.lastStartDate = sliderInfoRef.current.startDate;
    sliderInfoRef.current.lastEndDate = sliderInfoRef.current.endDate;

    sliderInfoRef.current.startDate = startDate;
    sliderInfoRef.current.endDate = endDate;

    onChange(
      enforceDates(
        sliderInfoRef.current.startDate,
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        sliderInfoRef.current.endDate
      )
    );

    onDayChange(_newDate.toJSDate());
  }

  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  const existingValuePosition = useMemo(() => {
    if (existingValue?.startDate && existingValue?.endDate) {
      // does this value fall on this day? we will only show it if it does
      const interval = Interval.fromDateTimes(
        referenceDate.startOf('day'),
        referenceDate.endOf('day')
      );

      const startDate = parseDateTime(existingValue.startDate, timeZone);
      const endDate = parseDateTime(existingValue.endDate, timeZone);

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateTime | null' is not assignab... Remove this comment to see the full error message
      if (interval.overlaps(Interval.fromDateTimes(startDate, endDate))) {
        return getPositionFromTimeRange({
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'DateTime | null' is not assignable to type '... Remove this comment to see the full error message
          startDate,
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'DateTime | null' is not assignable to type '... Remove this comment to see the full error message
          endDate,
          optionWidth,
          slotSize,
          minWidth,
          maxWidth,
          minSlot,
        });
      }
    }
  }, [existingValue, optionWidth, slotSize, minSlot, referenceDate]);

  return {
    sliderInfo: sliderInfoRef.current,
    referenceDate,
    calendarReferenceDate,
    updateCalendarReferenceDate,
    optionWidth,
    setOptionWidth,
    isInvalid,
    setIsInvalid,
    slots,
    options,
    calcMinWidth,
    minWidth,
    calcMaxWidth,
    maxWidth,
    minSlot,
    maxSlot,
    enforceDates,
    enforcePositionConstraints,
    changeDay,
    unavailableMonthlyRanges,
    existingValuePosition,
  };
}
