import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { ApolloError } from '@apollo/client';

import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import {
  useUpdateAutoPay,
  usePlacePayModal,
  useEssensysOnlinePlanPaymentFeatureConfirmationModal,
} from 'lane-shared/hooks';
import OnlinePlanQuote from 'lane-shared/hooks/integrations/essensys/types/OnlinePlanQuote';

import { Input } from 'components/form';
import { Button, Loading, ErrorMessage } from 'components/general';
import { Alert, AlertType, Modal } from 'components/lds';
import PlacePayPaymentModal from 'components/payments/PlacePayPaymentModal';
import PlacePayLogo from 'components/svg/PlacePayLogo';
import { M } from 'components/typography';

import PaymentFeatureConfirmationModalProps from '../PaymentFeatureConfirmationModalProps';
import OnlinePlanQuoteSummary from './OnlinePlanQuoteSummary';

import styles from './EssensysOnlinePlanPaymentFeatureConfirmationModal.scss';

function OnlinePlanQuoteSummaryWrapper({
  onlinePlanQuote,
  loading,
  error,
  content,
}: {
  onlinePlanQuote?: OnlinePlanQuote;
  loading: boolean;
  error: ApolloError | undefined;
  content: any;
}) {
  if (loading) {
    return <Loading />;
  }

  if (error || !onlinePlanQuote) {
    return null;
  }

  return (
    <OnlinePlanQuoteSummary
      onlinePlanQuote={onlinePlanQuote}
      content={content}
    />
  );
}

export default function EssensysOnlinePlanPaymentFeatureConfirmationModal({
  isOpen,
  onClose,
  content,
  interaction,
  onPaymentSuccess,
  onPaymentFailed,
}: PaymentFeatureConfirmationModalProps) {
  const { t } = useTranslation();

  const {
    placePayModalOpen,
    setPlacePayModalOpen,
    handlePaymentStart,
    handlePaymentSuccess,
    handlePaymentFailed,
    handleCreateInteractionSuccess,
  } = usePlacePayModal({
    onClose,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '((...args: any[]) => void) | undefined' is n... Remove this comment to see the full error message
    onPaymentFailed,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '((...args: any[]) => void) | undefined' is n... Remove this comment to see the full error message
    onPaymentSuccess,
  });

  const {
    setPlacePayError,
    accountContactPair,
    submittingInteraction,
    operateOnlinePlanPaymentSettings,
    email,
    setEmail,
    companyName,
    setCompanyName,
    onlinePlanQuote,
    onlinePlanQuoteLoading,
    onlinePlanQuoteError,
    errors,
    purchaseDisabled,
    loading,
    createOperateOnlinePlanInteraction,
    collectPayment,
  } = useEssensysOnlinePlanPaymentFeatureConfirmationModal({
    content,
    interaction,
    onPaymentStart: handlePaymentStart,
    onPaymentSuccess: handlePaymentSuccess,
    onPaymentFailed: handlePaymentFailed,
    onCreateInteractionSuccess: handleCreateInteractionSuccess,
    onCreateInteractionFailed: () => undefined,
  });

  const paymentAccountId: string =
    operateOnlinePlanPaymentSettings?.paymentAccount?._id ?? '';

  const autoPay = useUpdateAutoPay({
    paymentAccountId: paymentAccountId ? convertToUUID(paymentAccountId) : '',
    accountId: accountContactPair?.account?.accountid ?? '',
  });

  useEffect(() => {
    if (submittingInteraction && isOpen && !placePayModalOpen) {
      const EXTRA_LONG_DURATION_TO_OVERIDE_DEFAULT = 100000;

      window.Toast.show(
        'Finalizing your purchase. Please do not refresh page',
        EXTRA_LONG_DURATION_TO_OVERIDE_DEFAULT
      );

      return;
    }

    window.Toast.hide();
  }, [submittingInteraction, isOpen, placePayModalOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="small"
      title={t('Complete Online Plan Purchase')}
      actions={
        <Button
          fullWidth
          disabled={purchaseDisabled || submittingInteraction}
          variant="contained"
          onClick={createOperateOnlinePlanInteraction}
        >
          {t(submittingInteraction ? 'Finalizing...' : 'Purchase')}
        </Button>
      }
      style={submittingInteraction ? { backgroundColor: '#dbdbdb' } : undefined}
    >
      <>
        {submittingInteraction ? (
          <div className={styles.disableModalContainer} />
        ) : null}
        {loading ? (
          <div className={styles.loadingContainer}>
            <M>{t('Preparing transaction details...')} </M>
            <Loading />
          </div>
        ) : (
          <>
            <OnlinePlanQuoteSummaryWrapper
              onlinePlanQuote={onlinePlanQuote}
              content={content}
              loading={onlinePlanQuoteLoading}
              error={onlinePlanQuoteError}
            />

            <Input
              disabled={loading || submittingInteraction}
              type="text"
              placeholder="email"
              onChange={setEmail}
              value={email}
              className={styles.input}
            />
            <Input
              disabled={loading || submittingInteraction}
              type="text"
              placeholder="company name"
              onChange={setCompanyName}
              value={companyName}
            />
            <div className={styles.paymentTypeDetails}>
              <PlacePayLogo />
              <M>
                {t(
                  'Placepay will be automatically selected to complete your purchase.'
                )}
              </M>
            </div>
            {collectPayment.PlacePay && onlinePlanQuote ? (
              <PlacePayPaymentModal
                autopay
                isOpen={placePayModalOpen}
                paymentAccountId={paymentAccountId}
                payeeId={operateOnlinePlanPaymentSettings.organizationPayeeId!}
                amount={onlinePlanQuote.totals.total * CURRENCY_CONVERSION}
                description={content.name}
                onClose={() => {
                  setPlacePayModalOpen(false);
                }}
                onPaymentSuccess={collectPayment.PlacePay.handlePaymentSuccess}
                onPaymentFailed={err => {
                  setPlacePayError(err);
                }}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '(data: { autopay: boolean; }) => void' is no... Remove this comment to see the full error message
                onAutoPayChanged={autoPay.update}
              />
            ) : null}

            {autoPay.error && <ErrorMessage error={autoPay.error} />}

            {errors.map((err: Error, i: number) => (
              <Alert key={i} type={AlertType.error} fullWidth>
                <M>{t(err.message)}</M>
              </Alert>
            ))}
          </>
        )}
      </>
    </Modal>
  );
}
