import { useEffect, useState } from 'react';
import {
  AddProductToChargeDetails,
  ExternalPayerType,
  MarkupAmountType,
  Product,
  ProductCategory,
  ProductType,
  SupportedPaymentCurrency,
  TaxAmountType,
  AddItemType,
} from 'lane-shared/domains/billingPayments/types';
import { EMPTY_PRODUCT_DETAILS } from 'lane-shared/domains/billingPayments/constants';
import { v4 as uuid } from 'uuid';
import { SupportedLocaleEnum } from 'localization';
import { getPricing } from '../../productCatalog/helpers';
import { priceInputToComponents, setFinalPriceOnProduct } from '../helpers';
import { useProductListQuery } from './useProductListQuery';
import { useCategoryListQuery } from './useCategoryListQuery';
import { ChannelType } from '../../../types/ChannelType';
import { useFinalPriceQuery } from './useFinalPriceQuery';
import { convertToUUID } from '../../../helpers/convertId';

export const EMPTY_TABLE_ROW_PRODUCT_DETAILS = {
  ...EMPTY_PRODUCT_DETAILS,
  tableRowId: '',
  tableProductType: AddItemType.EXISTING,
};

interface Item {
  label: string;
  value: string;
}

export function useAddProductForm({
  currency,
  locale,
  channel,
  payerId,
  payerType,
}: {
  currency: SupportedPaymentCurrency;
  locale: SupportedLocaleEnum;
  channel: ChannelType | null;
  payerId: string;
  payerType: ExternalPayerType;
}) {
  const [
    productDetails,
    setProductDetails,
  ] = useState<AddProductToChargeDetails>(() => {
    return {
      ...EMPTY_TABLE_ROW_PRODUCT_DETAILS,
      tableRowId: uuid(),
    };
  });

  const [productType, setProductType] = useState(
    ProductType.PRODUCT_TYPE_SERVICE_LABOUR
  );

  const productsList = useProductListQuery({
    page: 0,
    pageSize: 100,
    productCategoryId: productDetails.productCategoryId,
    channel,
  });

  const { data: categoryList } = useCategoryListQuery({
    page: 0,
    pageSize: 100,
    productType,
    channel,
  });

  const handleProductDetailsUpdate = (value: any, field?: string) => {
    if (!field) {
      setProductDetails({ ...productDetails, ...value });

      return;
    }

    setProductDetails({ ...productDetails, [field]: value });
  };

  const resetProductDetails = () => {
    setProductDetails({
      ...EMPTY_TABLE_ROW_PRODUCT_DETAILS,
      tableRowId: uuid(),
    });
  };

  useEffect(() => {
    const priceComponents = priceInputToComponents(productDetails);
    const { listPrice, formatPrice } = getPricing({
      currency,
      locale,
      priceComponents,
    });

    setProductDetails(prev => ({
      ...prev,
      netPrice: String(listPrice),
      total: formatPrice(listPrice * Number(productDetails.quantity)),
    }));
  }, [
    productDetails.quantity,
    productDetails.rate,
    productDetails.tax,
    productDetails.markup,
    productDetails.productId,
    currency,
    locale,
  ]);

  const { finalPriceList } = useFinalPriceQuery({
    productIds: [
      productDetails.productId ? convertToUUID(productDetails.productId) : '',
    ],
    payer: { id: payerId, type: payerType },
    channel,
  });
  const finalPriceProductId = finalPriceList[0]?.productId;

  useEffect(() => {
    if (
      finalPriceList &&
      finalPriceList.length > 0 &&
      finalPriceList[0].exceptionId &&
      finalPriceList[0].productId === convertToUUID(productDetails.productId)
    ) {
      const finalPrice = finalPriceList[0].finalPrice;

      setProductDetails(prev => setFinalPriceOnProduct(finalPrice, prev));
    }
  }, [channel?._id, finalPriceProductId]);

  const updateProductType = (value: string) => {
    setProductType(prev => {
      if (value !== prev) {
        resetProductDetails();
      }

      return value as ProductType;
    });
  };

  function getCategoryItems(): Item[] {
    const listOfCategories = categoryList?.listProductCategories
      .productCategory as ProductCategory[] | undefined;

    if (listOfCategories) {
      const dropdownCategoryItems = listOfCategories.map(productCategory => {
        return {
          label: productCategory.name,
          value: productCategory.productCategoryId,
        };
      });

      return dropdownCategoryItems;
    }

    return [];
  }

  const onCategorySelection = (item: Item) => {
    const listOfCategories = categoryList?.listProductCategories
      .productCategory as ProductCategory[];
    const selectedCategory = listOfCategories.find(
      productCategory => productCategory.productCategoryId === item.value
    );

    if (selectedCategory) {
      handleProductDetailsUpdate({
        ...EMPTY_TABLE_ROW_PRODUCT_DETAILS,
        productType,
        productCategoryId: selectedCategory.productCategoryId,
        productCategoryName: selectedCategory.name,
        productCategoryType: productType,
      });
    }
  };

  function getProductItems(): Item[] {
    const listOfProducts = productsList.data?.listProducts.products;

    if (listOfProducts) {
      const dropdownProductItems = listOfProducts.map((product: Product) => {
        return {
          label: product.name,
          value: product.id,
        };
      });

      return dropdownProductItems;
    }

    return [];
  }

  const onProductSelection = (item: Item) => {
    const selectedProduct = productsList.data?.listProducts.products.find(
      (product: Product) => product.id === item.value
    );

    if (selectedProduct) {
      handleProductDetailsUpdate({
        ...productDetails,
        productId: selectedProduct.id,
        productGroupId: selectedProduct.groupId,
        name: selectedProduct.name,
        description: selectedProduct.description,
        rate: selectedProduct.amount.toString(),
        markup: {
          type:
            selectedProduct?.markup?.markupAmountType ||
            MarkupAmountType.MarkupAmountTypePercentage,
          value: selectedProduct?.markup?.value.toString() || '',
        },
        tax: {
          type:
            selectedProduct.tax?.taxAmountType ||
            TaxAmountType.TaxAmountTypePercentage,
          value: selectedProduct?.tax?.value.toString() || '',
        },
        originalPrice: undefined,
        chargeCode: selectedProduct.chargeCode?.chargeCode || '',
        chargeCodeId: selectedProduct.chargeCode?.id || '',
      });
    }
  };

  return {
    productType,
    productDetails,
    updateProductType,
    handleProductDetailsUpdate,
    getCategoryItems,
    onCategorySelection,
    getProductItems,
    onProductSelection,
    resetProductDetails,
  };
}
