import GeoJSON from 'geojson';

export function createGeoJsonSource(
  data: any
): {
  type: 'geojson';
  data: GeoJSON.FeatureCollection<GeoJSON.Point>;
} {
  return {
    type: 'geojson',
    data,
  };
}

export function createFeature({
  id,
  coordinates,
}: {
  id?: string;
  coordinates?: GeoJSON.Position;
}): GeoJSON.Feature<GeoJSON.Point> {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: coordinates || [],
    },
    properties: { id },
  };
}

export function createFeatureCollection(
  content: any,
  metatagId: any
): GeoJSON.FeatureCollection<GeoJSON.Point> {
  return {
    type: 'FeatureCollection',
    features: [
      ...content.map((item: any) => {
        const contentMetatag = item?.contentMetatags?.find(
          (contentMetatag: any) => contentMetatag.metatag._id === metatagId
        );

        return createFeature({
          id: item._id,
          coordinates: contentMetatag?.value,
        });
      }),
    ],
  };
}

export const availableFeaturesHelper = (
  placedContent: any,
  metatagId: any,
  preselectedContentId: any
): GeoJSON.FeatureCollection<GeoJSON.Point> | null => {
  const availableContent = placedContent.filter(
    (item: any) =>
      (item.isAvailable || item.isAvailable === undefined) &&
      item._id !== preselectedContentId
  );

  return availableContent
    ? createFeatureCollection(availableContent, metatagId)
    : null;
};

export const unAvailableFeaturesHelper = (
  placedContent: any,
  metatagId: any,
  preselectedContentId: any
): GeoJSON.FeatureCollection<GeoJSON.Point> | null => {
  const unAvailableContent = placedContent.filter(
    (item: any) =>
      item.isAvailable === false && item._id !== preselectedContentId
  );

  return unAvailableContent
    ? createFeatureCollection(unAvailableContent, metatagId)
    : null;
};

export const preselectedFeatureHelper = (
  placedContent: any,
  metatagId: any,
  preselectedContentId: any
): GeoJSON.Feature<GeoJSON.Point> | null => {
  const selectedContent = placedContent.find(
    (item: any) => item._id === preselectedContentId
  );
  const selectedMetatag = selectedContent?.contentMetatags?.find(
    (contentMetatag: any) => contentMetatag.metatag._id === metatagId
  );

  return selectedMetatag
    ? createFeature({
        id: preselectedContentId,
        coordinates: selectedMetatag.value,
      })
    : null;
};
