import React from 'react';

import {
  InternalScheduleType,
  ScheduleAction,
} from 'lane-shared/domains/visitorManagement/reducers';
import {
  IntervalTypeEnum,
  MonthIntervalTypeEnum,
} from 'lane-shared/domains/visitorManagement/types';

import { MonthlyInterval } from './MonthlyInterval/MonthlyInterval';
import { WeeklyInterval } from './WeeklyInterval/WeeklyInterval';

type Props = {
  state: InternalScheduleType;
  dispatch: (value: ScheduleAction) => void;
  defaultIntervalCount: number;
  defaultMonthIntervalType: MonthIntervalTypeEnum;
  timeZone?: string;
  className?: string;
};

const IntervalContentMap = Object.freeze({
  [IntervalTypeEnum.Weekly]: {
    Component: WeeklyInterval,
  },
  [IntervalTypeEnum.Monthly]: {
    Component: MonthlyInterval,
  },
});

const getIntervalContent = (
  activeInterval: keyof typeof IntervalContentMap
): (({
  state,
  dispatch,
  defaultIntervalCount,
  defaultMonthIntervalType,
  timeZone,
  className,
}: Props) => JSX.Element) => IntervalContentMap[activeInterval].Component;

export function IntervalContent({
  state,
  dispatch,
  defaultIntervalCount,
  defaultMonthIntervalType,
  timeZone,
  className,
}: Props) {
  if (!state.intervalType) return null;

  if (
    state.intervalType === IntervalTypeEnum.EveryDay ||
    state.intervalType === IntervalTypeEnum.EveryWeekday
  )
    return null;

  const CurrentIntervalContent = getIntervalContent(state.intervalType);

  return (
    <CurrentIntervalContent
      state={state}
      dispatch={dispatch}
      defaultIntervalCount={defaultIntervalCount}
      defaultMonthIntervalType={defaultMonthIntervalType}
      timeZone={timeZone}
      className={className}
    />
  );
}
