import React from 'react';

import { useTranslation } from 'react-i18next';

import FeatureOptionToggle from '../components/FeatureOptionToggle';
import ReservableFeatureTimeUnitSlider from './ReservableFeatureTimeUnitSlider';
import { minUnits } from './reservableFeatureValues';

type Props = {
  onChange: () => any;
  settings: any;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onFeatureUpdated: (any) => any;
};

const TRANSLATION_KEYS = {
  maxBookingWindow:
    'web.content.features.reservable.ReservableReservationEnd.maxBookingWindow',
  maxBookingWindowDescription:
    'web.content.features.reservable.ReservableReservationEnd.maxBookingWindow.description',
  maxBookingWindowTooltip:
    'web.content.features.reservable.ReservableReservationEnd.maxBookingWindow.tooltip',
};

export default function ReservableReservationEnd({
  onChange,
  settings,
  onFeatureUpdated,
}: Props) {
  const { t } = useTranslation();

  return (
    <FeatureOptionToggle
      title={t(TRANSLATION_KEYS.maxBookingWindow)}
      description={t(TRANSLATION_KEYS.maxBookingWindowDescription)}
      onChange={onChange}
      isExpanded={settings.mainRule.unitsBeforeStart !== 0}
      TooltipComponent={t(TRANSLATION_KEYS.maxBookingWindowTooltip)}
    >
      <ReservableFeatureTimeUnitSlider
        onUnitTypeUpdated={unitsBeforeStartType =>
          onFeatureUpdated({
            mainRule: {
              ...settings.mainRule,
              unitsBeforeStartType,
              unitsBeforeNow: minUnits[unitsBeforeStartType],
            },
          })
        }
        onUnitsUpdated={unitsBeforeStart =>
          onFeatureUpdated({
            mainRule: { ...settings.mainRule, unitsBeforeStart },
          })
        }
        units={settings.mainRule.unitsBeforeStart}
        unitType={settings.mainRule.unitsBeforeStartType}
      />
    </FeatureOptionToggle>
  );
}
