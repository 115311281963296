import React from 'react';

import cx from 'classnames';
import { Input } from 'components';
import { useTranslation } from 'react-i18next';

import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import { currencyFormatter } from 'lane-shared/helpers/formatters';

import Slider from 'components/form/Slider';
import { ErrorMessage } from 'components/general';
import { S } from 'components/typography';

import useUserLocale from '../../hooks/useUserLocale';

import styles from './PriceSlider.scss';

type PriceSliderProps = {
  label?: string;
  className?: string | undefined;
  style?: React.CSSProperties | undefined;
  values: { min: number; max: number };
  min: number | undefined;
  max: number | undefined;
  currency?: string;
  disabled?: boolean;
  loading?: boolean;
  onChange: (value: { min?: number; max?: number }) => void;
  currencyConversion?: number;
};

function PriceSlider({
  label = '-',
  className,
  style,
  values,
  onChange,
  min = 0,
  max = 1000000,
  currency = 'USD',
  disabled,
  loading,
  currencyConversion = CURRENCY_CONVERSION,
}: PriceSliderProps) {
  const locale = useUserLocale();
  const { t } = useTranslation();
  const displayValues = {
    min: min / currencyConversion,
    max: max / currencyConversion,
    values: {
      min: values.min / currencyConversion,
      max: values.max / currencyConversion,
    },
  };

  function valdiatePriceInput(val: string, prevVal: number): number {
    if (val === '') {
      return 0;
    }

    if (Number.isNaN(val)) {
      return prevVal;
    }

    const intVal = Number(val);

    if (intVal < displayValues.min) {
      return displayValues.min;
    }

    if (intVal > displayValues.max) {
      return displayValues.max;
    }

    return intVal;
  }

  const numberFormat = currencyFormatter({
    locale,
    currency,
  });

  // TO-DO: When studio implements a roomBookingAverage CC: Yasser + Sean
  const datasetAverage = null; // numberFormat(roombookingAverage / currencyConversion);

  const mobilePriceDisplay = (
    <S className={styles.mobilePriceDisplay}>
      {`${numberFormat(displayValues.values.min)} - ${numberFormat(
        displayValues.values.max
      )}`}
    </S>
  );

  const webPriceDisplayInput = (
    <div className={styles.inputContainer}>
      <Input
        type="text"
        className={styles.inputField}
        label={t('Min price')}
        onChange={val => {
          onChange({
            min:
              valdiatePriceInput(val, displayValues.values.min) *
              currencyConversion,
          });
        }}
        value={displayValues.values.min.toString()}
      />
      <S>-</S>
      <Input
        type="text"
        className={styles.inputField}
        label={t('Max price')}
        onChange={val => {
          onChange({
            max:
              valdiatePriceInput(val, displayValues.values.max) *
              currencyConversion,
          });
        }}
        value={displayValues.values.max.toString()}
      />
    </div>
  );

  return (
    <div className={cx(styles.PriceSlider, className)} style={style}>
      <h1>{t(label)}</h1>
      {datasetAverage ? (
        <S mt={2}>
          {t('The average price for this is {{datasetAverage}}', {
            datasetAverage,
          })}
        </S>
      ) : null}
      {mobilePriceDisplay}
      <Slider
        className={styles.slider}
        min={displayValues.min}
        max={displayValues.max}
        value={{
          min: displayValues.values.min,
          max: displayValues.values.max,
        }}
        onChange={({ min, max }: any) =>
          onChange({
            min: min * currencyConversion,
            max: max * currencyConversion,
          })
        }
        disabled={disabled || loading}
        formatLabel={numberFormat}
      />
      {webPriceDisplayInput}
      {values.min > values.max || values.max < values.min ? (
        <ErrorMessage error={new Error(t('Invalid Range'))} />
      ) : null}
    </div>
  );
}

export default PriceSlider;
