export function convertToDate(date: string | Date | undefined) {
  if (typeof date === 'string') {
    return new Date(date);
  }

  if (date instanceof Date) {
    return date;
  }

  return undefined;
}
