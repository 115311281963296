import { DateTime } from 'luxon';

import { ContentType } from '../../types/content/Content';
import { parseDateTime } from '../dates';

export default function isContentBetweenInteractionTimes(
  content: ContentType,
  isAdminView?: boolean
) {
  if (isAdminView) return true;

  if (content.liveDate && parseDateTime(content.liveDate)! > DateTime.local()) {
    return false;
  }

  if (
    content.interactionEndDate &&
    parseDateTime(content.interactionEndDate)! < DateTime.local()
  ) {
    return false;
  }

  return true;
}
