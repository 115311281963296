import { useEffect, useState, useRef } from 'react';

import qs from 'query-string';
import { useHistory } from 'react-router-dom';

import { getQueryString } from 'lane-shared/helpers';

export type QueryStringResult<T> = [
  T,
  (props: any) => void,
  (props: any) => string
];

export interface QueryObject {
  [key: string]: Date | string | number | boolean | null;
}
/**
 * Listens for a changes to the query string, and updates the state with a
 * parsed query object.
 */
/**
 * Custom hook for managing query strings in the URL.
 *
 * @template T - The type of the query object.
 * @param {QueryObject} defaultQuery - The default query object.
 * @param {string[]} [keepedKeys] - An optional array of keys to keep in the query object.
 *                                 One use case is cleanup query string when switch between different tabStrips
 * @returns {QueryStringResult<T>} - An array containing the query object, a function to update the query object, and a function to generate the URL with the updated query object.
 */
export default function useQueryString<
  T extends {
    [x: string]:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean)[]
      | null
      | undefined;
  }
>(defaultQuery: QueryObject = {}, keepedKeys?: string[]): QueryStringResult<T> {
  const history = useHistory();

  const searches = qs.parse(history.location.search, {});
  const filteredQuery = keepedKeys
    ? Object.keys(searches)
        .filter(key => keepedKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = searches[key];

          return obj;
        }, {} as { [key: string]: any })
    : searches;

  const initialQuery = useRef({
    ...defaultQuery,
    ...filteredQuery,
  }).current;

  const [query, setQuery] = useState(initialQuery);

  useEffect(() => {
    goToUrl(initialQuery);
  }, []);

  useEffect(() => {
    const listener = history.listen(location => {
      if (
        (location.pathname.includes('content-center') ||
          location.pathname.includes('page-center') ||
          location.pathname.includes('perk-center')) &&
        location.search === ''
      ) {
        setQuery({
          ...initialQuery,
        });
        goToUrl(initialQuery);
      } else {
        setQuery({
          ...qs.parse(location.search),
        });
      }
    });

    return () => {
      if (listener) {
        listener();
      }
    };
  }, []);

  function makeUrl(props: any) {
    return (
      history.location.pathname +
      getQueryString({
        ...query,
        ...props,
      })
    );
  }

  function goToUrl(props: any) {
    history.replace(makeUrl(props), history?.location?.state);
  }

  return [(query as any) as T, goToUrl, makeUrl];
}
