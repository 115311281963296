import i18next from 'i18next';
import * as yup from 'yup';
import { getAttachmentType } from '../helpers/attachment/getAttachmentType';

export const DEFAULT_MAX_FILE_SIZE = 25 * 1000 * 1024;
export const DEFAULT_MAX_NUM_FILE = 25;

export function validateUploadableAsset(
  translator: (key: string, params?: any) => string = i18next.t
) {
  const MAX_FILES_ERROR = translator(
    'web.pages.portal.admin.attachments.create.maxFileError'
  );

  const MAX_FILE_SIZE_ERROR = translator(
    'web.pages.portal.admin.attachments.create.maxFileSizeError'
  );

  const ATTACHMENT_TYPE_ERROR = translator(
    'web.pages.portal.admin.attachments.create.attachmentTypeError'
  );

  const attachmentMaxFilesValidator = yup
    .array()
    .max(DEFAULT_MAX_NUM_FILE, MAX_FILES_ERROR);

  const uploadableAssetMaxFileSizeValidator = yup
    .number()
    .max(DEFAULT_MAX_FILE_SIZE, MAX_FILE_SIZE_ERROR);

  const attachmentFileTypeValidator = yup
    .string()
    .test('is-valid-attachment-type', ATTACHMENT_TYPE_ERROR, value => {
      try {
        getAttachmentType(value ?? '');

        return true;
      } catch (error) {
        return false;
      }
    });

  return {
    uploadableAssetMaxFileSizeValidator,
    attachmentMaxFilesValidator,
    attachmentFileTypeValidator,
  };
}
