import { DateTime } from 'luxon';

/**
 * Opposite of @function fromLocalDate.
 * Converts the given date from the timezone to the local date.
 * It will always return the start of the date of the local timezone.
 * @param date
 * @param timeZone
 * @returns
 */
export function toLocalDate(date?: Date, timeZone?: string) {
  if (!date || !timeZone) return date;

  const tzDate = DateTime.fromJSDate(date).setZone(timeZone);
  const localDate = DateTime.fromObject({
    year: tzDate.year,
    month: tzDate.month,
    day: tzDate.day,
  }).startOf('day');

  return localDate.toJSDate();
}
