import React, { useState, useEffect } from 'react';

import cx from 'classnames';

import {
  CountryCode,
  parsePhoneNumber,
  ENCLOSED_PARENTHESIS_REGEX,
  formatIncompletePhoneNumber,
  dropdownItems,
} from 'lane-shared/helpers/phoneNumbers';

import { Input, Dropdown } from 'components';
import { Flex } from 'components/layout';
import { S } from 'components/typography';

import styles from './PhoneNumberInput.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  displayName?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  error?: string[] | null;
  isHidden?: boolean;
  isRequired?: boolean;
  testId?: string;
  isDisabled?: boolean;
};

const DEFAULT_REGION = 'US';

export function PhoneNumberInput({
  className,
  style,
  displayName,
  placeholder = '',
  value,
  onChange,
  error,
  isRequired = false,
  testId,
  isDisabled = false,
}: Props) {
  const [regionCode, setRegionCode] = useState<CountryCode>(DEFAULT_REGION);
  const [newlyInputtedNumber, setNewlyInputtedNumber] = useState(value || '');

  useEffect(() => {
    // load national phone number on initial render if passed
    if (value) {
      const number = parsePhoneNumber(value)?.nationalNumber;

      if (number) {
        setNewlyInputtedNumber(number.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value) {
      const country = parsePhoneNumber(value)?.country;

      if (country) {
        setRegionCode(country);
      }
    }

    if (!value) {
      setNewlyInputtedNumber(value);
    }
  }, [value]);

  // Prevent formatting when deleting closing parenthesis
  const formattedNumber = newlyInputtedNumber.match(ENCLOSED_PARENTHESIS_REGEX)
    ? newlyInputtedNumber
    : formatIncompletePhoneNumber(newlyInputtedNumber ?? '', regionCode);

  function handleChange(value: string) {
    setNewlyInputtedNumber(value);
    const phoneNumber = parsePhoneNumber(value, regionCode);

    if (phoneNumber) {
      onChange(phoneNumber.format('E.164'));
    } else {
      onChange(value);
    }
  }

  return (
    <div className={cx(styles.phoneInput, className)} style={style}>
      <S>
        {displayName}
        {isRequired ? <span className={styles.required}>*</span> : ''}
      </S>
      <Flex className={styles.form}>
        <div className={styles.dropdownWrapper}>
          <Dropdown
            items={dropdownItems}
            placeholder="web.admin.channel.users.phoneNumberInput.placeholder"
            value={regionCode}
            onChange={code => setRegionCode(code.value)}
            className={styles.dropdown}
            disabled={isDisabled}
            testId="phoneDropdown"
          />
        </div>
        <Input
          testId={testId}
          className={styles.inputField}
          value={formattedNumber}
          onChange={handleChange}
          maxLength={16}
          type="tel"
          placeholder={placeholder}
          error={error}
          disabled={isDisabled}
        />
      </Flex>
    </div>
  );
}
