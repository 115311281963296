import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { usePaymentV2Feature } from 'lane-shared/domains/billingPayments/hooks';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks/useBillingPaymentSettingsForChannel';
import { GroupSettingContext } from 'lane-shared/domains/billingPayments/contexts/groupSettingContext';
import { ContentType } from 'lane-shared/types/content/Content';
import { useFlag, useUserDataContext } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import * as Sentry from '@sentry/browser';

import { Loading } from 'lane-web/src/components/general';
import useUserLocale from 'lane-web/src/hooks/useUserLocale';

import { OrderSummary } from './OrderSummary';
import { PaymentMethod } from 'graphql-resolver-contracts';

interface OrderSummaryProps {
  content: ContentType;
  interaction: any;
}

export function ContentOrderSummary({
  content,
  interaction,
}: OrderSummaryProps) {
  const history = useHistory();
  const { t } = useTranslation();

  const locale = useUserLocale();

  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [paymentFinished, setPaymentFinished] = useState(false);
  const { user } = useUserDataContext();

  const {
    orderSummaryData,
    orderSummaryWithProducts,
    paymentV2Error,
    initiatePaymentAndUciSubmission,
    initiateSubscriptionAndUciSubmission,
  } = usePaymentV2Feature({ content, interaction, locale });

  const {
    paymentSettingsError,
    failureRefund,
    cancellationRefund,
    updateFailureRefund,
    updateCancellationRefund,
  } = useBillingPaymentSettingsForChannel({
    channelId: content?.channel?._id || '',
  });

  const subscriptionsInContentFlag = useFlag(
    FeatureFlag.SubscriptionsInContent,
    false
  );
  const isSubscriptionContent =
    subscriptionsInContentFlag && orderSummaryWithProducts?.subscriptionDetails;
  const refundEnabled = useFlag(FeatureFlag.PaymentRefund, false);
  const errorMessageWithRefund = refundEnabled && failureRefund;

  useEffect(() => {
    // handle close browser
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (loading) {
        // Custom logic to handle the user action
        // For example, show a warning message or perform cleanup tasks
        event.returnValue = ''; // Required for some browsers to show the alert
      }

      event.preventDefault();

      return event.returnValue; // Required for older browsers
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [loading]);

  function showProcessingPaymentDialog() {
    window.Alert.show({
      title: t('abp.payment.orderSummary.processingPayment'),
      message: t('abp.payment.orderSummary.pleaseDontCloseThisWindow'),
      type: 'loading',
      children: (
        <Loading
          style={{
            marginTop: '0.5em',
            marginBottom: '0.5em',
            fontSize: '5em',
            alignItems: 'center',
          }}
        />
      ),
    });
  }

  useEffect(() => {
    if (paymentFinished) {
      history.push(routes.contentInteractions.replace(':id', content?._id));
      window.Toast.show(t('abp.payment.orderSummary.purchaseCompleted'));
    }
  }, [paymentFinished]);

  const onSubmit = useCallback(
    async (
      accountsUserId: string | null,
      selectedPaymentMethod: string | undefined
    ) => {
      if (!content?._id) {
        return;
      }

      setLoading(true);
      showProcessingPaymentDialog();

      try {
        if (isSubscriptionContent) {
          await initiateSubscriptionAndUciSubmission(user?._id);
        } else {
          await initiatePaymentAndUciSubmission(
            accountsUserId,
            selectedPaymentMethod,
            PaymentMethod.PaymentMethodCreditCard,
            errorMessageWithRefund
          );
        }

        setLoading(false);
        setPaymentFinished(true);
        window.Alert.hide();
      } catch (err: any) {
        window.Alert.hide();
        Sentry.captureException(
          'BPError - payment or UCI failure in checkout',
          {
            contexts: {
              uciInfo: {
                contentId: content._id,
                userId: user?._id,
                error: err,
              },
            },
          }
        );
        setError(new Error(err.message));
      } finally {
        setLoading(false);
      }
    },
    [content?._id, initiatePaymentAndUciSubmission]
  );

  const backgroundImage: string =
    content.backgroundImage || content.backgroundImageId || '';

  return (
    <GroupSettingContext.Provider
      value={{
        paymentSettingsError,
        refund: {
          failureRefund,
          cancellationRefund,
          updateFailureRefund,
          updateCancellationRefund,
        },
      }}
    >
      <OrderSummary
        orderSummaryData={orderSummaryData}
        orderSummaryWithProducts={orderSummaryWithProducts}
        content={content}
        interaction={interaction}
        backgroundImage={backgroundImage}
        loading={loading}
        error={error}
        paymentV2Error={paymentV2Error}
        onSubmit={onSubmit}
      />
    </GroupSettingContext.Provider>
  );
}
