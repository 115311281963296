export const SESSIONSTORAGE_ESSENSYS_RESERVABLE_VALUE =
  'Essensys_Reservable_Value';

/*
 * Regarding to TM-3477
 * If essensys reservable is stored in session storage
 * Insert the value into the appropriate ReservableInput and where reservation.startDate && reservation.endDate are null
 * */
export default function getCachedEssensysReservableValue(): {
  reservation: { startDate: Date; endDate: Date };
} | null {
  const essensysReservableValue = window?.sessionStorage?.getItem(
    SESSIONSTORAGE_ESSENSYS_RESERVABLE_VALUE
  );

  if (essensysReservableValue) {
    const result = { ...JSON.parse(essensysReservableValue) };

    result.reservation.startDate = new Date(result.reservation.startDate);
    result.reservation.endDate = new Date(result.reservation.endDate);

    return result;
  }

  return null;
}
