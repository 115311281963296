import { useEffect, useRef, useState } from 'react';

import gql from 'graphql-tag';

import { AccountContactPair } from 'lane-shared/types/Essensys';

import { createOperateAccountAndContactMutation } from '../../../helpers';
import usePollingQuery from '../../usePollingQuery';

export type OperateAccountContactPair = {
  loading: boolean;
  error: Error | null;
  accountContactPair: AccountContactPair | undefined;
  accountContactPairs: Array<AccountContactPair> | undefined;
  selectAccountContactPair: (accountContactPair: AccountContactPair) => void;
  createAccountContactPair: (
    companyName: string,
    email: string,
    contentId?: string
  ) => Promise<AccountContactPair>;
};

type UseOperateAccountContactPairsOptions = {
  skip?: boolean;
};

const accountContactPairQuery = gql`
  query AccountContactPairs($locationId: ID) {
    essensys {
      accountContactPairs(locationId: $locationId) {
        account
        contact
      }
    }
  }
`;
export default function useOperateAccountContactPairs(
  locationId?: string,
  { skip = false }: UseOperateAccountContactPairsOptions = {}
): OperateAccountContactPair {
  const [accountContactPair, setAccountContactPair] = useState<
    AccountContactPair | undefined
  >(undefined);
  const [accountContactPairs, setAccountContactPairs] = useState<
    Array<AccountContactPair> | undefined
  >(undefined);
  const contactIdRef = useRef<string | undefined>(undefined);

  const accountContactPairPollingQuery = usePollingQuery({
    query: accountContactPairQuery,
    fetchPolicy: 'network-only',
    pollInterval: 2000,
    onAfterFetch: data => {
      setAccountContactPairs(data?.essensys.accountContactPairs);
    },
  });

  useEffect(() => {
    if (!skip) {
      accountContactPairPollingQuery.startPolling({ locationId });
    }

    return () => {
      accountContactPairPollingQuery.stopPolling();
    };
  }, []);

  useEffect(() => {
    if (!accountContactPairs || !accountContactPairs?.length) {
      return;
    }

    accountContactPairPollingQuery.stopPolling();

    if (contactIdRef.current) {
      const preferredAccountContactPair = accountContactPairs.find(acp => {
        return acp.contact.contactid === contactIdRef.current;
      });

      if (preferredAccountContactPair) {
        selectAccountContactPair(preferredAccountContactPair);

        return;
      }
    }

    // TODO: will revisit this accountContactPairs logic once fix for clientTypeId is figured out
    const preferredAccountContactPair = accountContactPairs![0];

    if (accountContactPair !== preferredAccountContactPair) {
      contactIdRef.current = preferredAccountContactPair.contact.contactid;
      selectAccountContactPair(preferredAccountContactPair);
    }
  }, [accountContactPairs]);

  function selectAccountContactPair(accountContactPair: AccountContactPair) {
    setAccountContactPair(accountContactPair);
  }

  async function createAccountContactPair(
    companyName: string,
    email: string,
    contentId?: string
  ): Promise<AccountContactPair> {
    const accountContactPair = await createOperateAccountAndContactMutation({
      email,
      companyName,
      contentId,
    });

    contactIdRef.current = accountContactPair.contact.contactid;

    try {
      await accountContactPairPollingQuery.startPolling({ locationId });
    } catch (_err) {
      // Swallow, sometimes refetch can be unmounted - that's fine though.
    }

    return accountContactPair;
  }

  return {
    accountContactPair,
    accountContactPairs,
    selectAccountContactPair,
    createAccountContactPair,
    loading: accountContactPairPollingQuery.loading,
    error: accountContactPairPollingQuery.error,
  };
}
