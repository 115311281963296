import React, { useState } from 'react';

import cx from 'classnames';
import { useScrollToSelectedChild } from 'design-system-web';
import { DateTime, Interval } from 'luxon';

import { parseDateTime } from 'lane-shared/helpers/dates';

import Button from 'components/general/Button';

import MonthCell from './MonthCell';

import styles from './MonthCalendar.scss';

const MIN_DATE = DateTime.local().set({ year: 2014 }).startOf('year');
const MAX_DATE = DateTime.local().plus({ years: 2 }).endOf('year');

type OwnProps = {
  // callback for date has changed
  onChange?: ((date?: any) => null);
  // submit callback for when date is set
  onSubmit?: ((date?: any) => null);
  // start date
  startDate: Date | null;
  // end date
  endDate: Date | null;
  // max date allowed
  maxDate?: Date;
  // min date allowed
  minDate?: Date;
  // limits the range available to be selected, in months
  rangeLimit: number | null;
  className?: string;
  style?: React.CSSProperties;
};

type Props = OwnProps;

export default function MonthCalendar({
  className,
  style,
  startDate = null,
  endDate = null,
  rangeLimit = null,
  onSubmit = () => null,
  onChange = () => null,
  maxDate = MAX_DATE.toJSDate(),
  minDate = MIN_DATE.toJSDate(),
}: Props) {
  const [currentMonth, setCurrentMonth] = useState(DateTime.local());
  const startDateTime = parseDateTime(startDate);
  const endDateTime = parseDateTime(endDate);
  const minDateTime = parseDateTime(minDate) || MIN_DATE;
  const maxDateTime = parseDateTime(maxDate) || MAX_DATE;

  const month = startDateTime || currentMonth;
  const [elementRef] = useScrollToSelectedChild(month.toISO());

  function enforceDate(newDate: DateTime) {
    if (
      (maxDateTime && newDate > maxDateTime) ||
      (minDateTime && newDate < minDateTime)
    ) {
      return false;
    }

    setCurrentMonth(newDate);

    return true;
  }

  function enforceRangeLimit(newDate: DateTime) {
    if (!rangeLimit || !startDateTime) {
      return true;
    }

    return (
      Interval.fromDateTimes(newDate, startDateTime).count('months') <
      rangeLimit
    );
  }

  function renderMonthCells() {
    const yearStart = minDateTime.startOf('year');
    const yearEnd = maxDateTime.endOf('year');

    const months: React.ReactNode[] = [];

    let m: DateTime = yearStart;

    while (m <= yearEnd) {
      const formattedDate = m.toFormat('MMM-yy');

      months.push(
        <MonthCell
          key={m.toISO()}
          className={styles.monthCell}
          startDate={startDateTime}
          endDate={endDateTime}
          month={m}
          onClick={date => {
            if (enforceDate(date) && enforceRangeLimit(date)) {
              onChange(date.toJSDate());
            }
          }}
          text={formattedDate}
        />
      );

      m = m.plus({ month: 1 });
    }

    return months;
  }

  return (
    <div className={cx(styles.MonthCalendar, className)} style={style}>
      <div className={styles.calendarWrapper} ref={elementRef}>
        {renderMonthCells()}
      </div>
      {onSubmit && (
        <Button
          className={styles.button}
          onClick={onSubmit}
          variant="contained"
        >
          Select
        </Button>
      )}
    </div>
  );
}
