export function HardwareFormValidator() {
  function nameValidator(name: string, isSubmitQuery: boolean) {
    if (!name?.trim() && isSubmitQuery) {
      return ['web.admin.hardware.management.hardware.add.name.required'];
    }

    if (name?.length > 255) {
      return [
        'web.admin.hardware.management.hardware.add.name.error.charLimit',
      ];
    }

    return [];
  }

  function locationValidator(location: string) {
    if (location?.trim().length > 255) {
      return [
        'web.admin.hardware.management.hardware.add.location.error.charLimit',
      ];
    }

    return [];
  }

  function notesValidator(notes: string) {
    if (notes?.trim()?.length > 200) {
      return [
        'web.admin.hardware.management.hardware.add.notes.error.charLimit',
      ];
    }

    return [];
  }

  function isFormInvalid(name: string, location: string, notes: string) {
    const validNameError = nameValidator(name, true);
    const validLocationError = locationValidator(location);
    const ValidNotesError = notesValidator(notes);

    if (
      (Array.isArray(validNameError) && validNameError?.length) ||
      (Array.isArray(validLocationError) && validLocationError?.length) ||
      (Array.isArray(ValidNotesError) && ValidNotesError?.length)
    ) {
      return true;
    }

    return false;
  }

  return {
    nameValidator,
    locationValidator,
    notesValidator,
    isFormInvalid,
  };
}
