import React, { ReactNode, useEffect, useState } from 'react';

import { GoogleMap, GoogleMapProps, withGoogleMap } from 'react-google-maps';
import { useTranslation } from 'react-i18next';

import styles from './Map.scss';

declare global {
  interface Window {
    google: any;
  }
}

type MapProps = GoogleMapProps & {
  children: ReactNode;
  byRef?: (ref: any) => void;
};

const GOOGLE_MAPS_SCRIPT_ID = 'google-maps-script';

function Map({ children, byRef = () => {}, ...otherProps }: MapProps) {
  return (
    // @ts-expect-error
    <GoogleMap ref={byRef} {...otherProps}>
      {children}
    </GoogleMap>
  );
}

const GoogleMapComponent = withGoogleMap(Map);

type Props = GoogleMapProps & {
  children: React.ReactNode;
};

function MapComponent({ children, ...otherProps }: Props) {
  const { t } = useTranslation();
  // because google maps script is async we may have it before or after react initialization
  const [isLoading, setIsLoading] = useState(!window.google);
  const [isLoadingError, setIsLoadingError] = useState(false);

  useEffect(() => {
    const googleMapsScript = document.getElementById(GOOGLE_MAPS_SCRIPT_ID)!;
    const onGoogleMapLoaded = function () {
      setIsLoading(false);
      setIsLoadingError(!window.google);
    };

    // this listener waits for google map script to load if it haven't been loaded already by that point
    if (!window.google && googleMapsScript) {
      googleMapsScript.addEventListener('load', onGoogleMapLoaded);
    }

    return () => {
      if (googleMapsScript) {
        googleMapsScript.removeEventListener('load', onGoogleMapLoaded);
      }
    };
  }, []);

  if (isLoading) {
    return <div>{t('Map is loading')}</div>;
  }

  if (isLoadingError) {
    return <div>{t('No maps available')}</div>;
  }

  return (
    // @ts-expect-error
    <GoogleMapComponent
      containerElement={<div className={styles.container} />}
      mapElement={<div className={styles.map} />}
      {...otherProps}
    >
      {children}
    </GoogleMapComponent>
  );
}

export default MapComponent;
