import React from 'react';

import { useTranslation } from 'react-i18next';

import { FeatureDefinition } from 'lane-shared/types/FeatureDefinition';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import { H5, S } from 'components/typography';

import VisitorSchedulerSettings from '../VisitorSchedulerSettings/VisitorSchedulerSettings';

import styles from './VisitorSchedulerTab.scss';

export default function VisitorSchedulerTab({
  feature,
  settings,
  onFeatureUpdated,
}: {
  feature: FeatureDefinition;
  settings: VisitorManagementFeatureProperties;
  onFeatureUpdated: (
    feature: Partial<VisitorManagementFeatureProperties>
  ) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.visitorSchedulerTab}>
      <H5>
        {t(
          'web.admin.content.features.visitor.management.general.scheduling.title'
        )}
      </H5>
      <S>
        {t(
          'web.admin.content.features.visitor.management.general.scheduling.description'
        )}
      </S>
      <VisitorSchedulerSettings
        feature={feature}
        settings={settings}
        onFeatureUpdated={onFeatureUpdated}
      />
    </div>
  );
}
