import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query getReservablesOnChannel(
    $channelId: UUID
    $pageSize: Int!
    $startDate: DateTime!
    $endDate: DateTime!
    $reservablesSearch: ReservablesOnChannelSearchInput
  ) {
    channel(_id: $channelId) {
      _id
      reservablesOnChannel(first: $pageSize, search: $reservablesSearch) {
        edges {
          cursor
          node {
            id
            name
            type
            timeZone
            tags
            features {
              _id
              type
              feature
            }
            reservableBookings(startDate: $startDate, endDate: $endDate) {
              id
              startDate
              endDate
              status
              adminNote
              companies {
                id
                name
                joinedAt
              }
              user {
                _id
                name
              }
              features
              contentData
            }
          }
        }
      }
    }
  }
`);
