import { RefObject } from 'react';

import { useTranslation } from 'react-i18next';

import { ContentForCards } from 'lane-shared/types/content/ContentForCards';

type TextLines = {
  title?: number;
  desc?: number;
};

type TitleProps<T extends keyof ContentForCards = keyof ContentForCards> = {
  content: Pick<ContentForCards, T>;
  mode?: 'default' | 'preview';
};

type LineProps = {
  titleRef: RefObject<HTMLElement>;
  descriptionRef?: RefObject<HTMLSpanElement>;
  setLines: ({ title, desc }: TextLines) => void;
};

const TITLE_HEIGHT = 27;
const DESC_HEIGHT = 22;

export function useTitleText({ mode, content }: TitleProps<'name'>) {
  const { t } = useTranslation();

  return mode === 'preview' && !content?.name
    ? `${t('Title')}...`
    : content?.name;
}

export function useDescriptionText({
  mode,
  content,
}: TitleProps<'description'>) {
  const { t } = useTranslation();

  return mode === t('preview') && !content?.description
    ? `${t('Description')}...`
    : content?.description;
}

export function renderTextLines({
  titleRef,
  descriptionRef,
  setLines,
}: LineProps) {
  if (!titleRef || !descriptionRef) return;

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const titleHeight = titleRef.current?.clientHeight / TITLE_HEIGHT;
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const descriptionHeight = descriptionRef?.current?.clientHeight / DESC_HEIGHT;

  setLines({
    title: Math.round(titleHeight),
    desc: Math.round(descriptionHeight),
  });
}
