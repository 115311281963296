import React from 'react';

import Storage, { BE_PRISM_TOKEN } from 'lane-shared/helpers/Storage';

export async function getToken(): Promise<string | undefined> {
  const item = await Storage.getItemMaybe(BE_PRISM_TOKEN);

  if (item) {
    const expiresPart = item.split('.')[1];

    if (expiresPart) {
      const info = JSON.parse(atob(expiresPart));
      const expires = new Date(info.exp * 1000);

      if (expires > new Date()) {
        return item;
      }
    }

    await Storage.removeItem(BE_PRISM_TOKEN);
  }

  return undefined;
}

export async function removeToken(): Promise<void> {
  await Storage.removeItem(BE_PRISM_TOKEN);
}

export async function storeToken(token: string): Promise<void> {
  await Storage.setItem(BE_PRISM_TOKEN, token);
}

export function subscribeToToken(callback: () => void): () => void {
  return Storage.subscribe(BE_PRISM_TOKEN, callback);
}

export function useToken({
  callback = () => {},
}: {
  callback?: () => void;
} = {}): {
  token: string | undefined;
  tokenLoading: boolean;
} {
  const [token, setToken] = React.useState<undefined | string>(undefined);
  const [tokenLoading, setTokenLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    (async () => {
      setToken(await getToken());
      setTokenLoading(false);
    })();

    const unsubFn = subscribeToToken(() => {
      getToken().then(newToken => {
        setToken(newToken);
        setTokenLoading(false);
        callback();
      });
    });

    return unsubFn;
  }, [callback]);

  return { token, tokenLoading };
}
