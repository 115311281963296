import React from 'react';

import { Button } from 'components';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';

import styles from './HardwareRegenerateAPIkey.scss';

const regenerateHardwareApiKey = gql`
  mutation regenerateHardwareApiKey($id: ID!) {
    regenerateHardwareApiKey(id: $id) {
      id
      apiKey
    }
  }
`;

type HardwareRegenerateAPIkeyProps = {
  hardwareId: string;
  hardwareName: string;
  setRegenerateAPIkeyError: (error: string | null) => void;
};

export function HardwareRegenerateAPIkey({
  hardwareId,
  hardwareName,
  setRegenerateAPIkeyError,
}: HardwareRegenerateAPIkeyProps) {
  const { t } = useTranslation();

  async function handleRegenerateApiKeyButton() {
    // to cleared the error state
    setRegenerateAPIkeyError(null);

    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.hardware.management.hardware.profile.alert.regenerate.title'
        ),
        message: t(
          'web.admin.hardware.management.hardware.profile.alert.regenerate.message',
          { hardwareName }
        ),
        confirmText: t(
          'web.admin.hardware.management.hardware.profile.button.regenerate'
        ),
      });
    } catch (err) {
      return;
    }

    try {
      await getClient().mutate({
        mutation: regenerateHardwareApiKey,
        variables: {
          id: hardwareId,
        },
      });
      window.Toast.show(
        t(
          'web.admin.hardware.management.hardware.profile.regenerateAPIkey.success.toast'
        )
      );
    } catch (err) {
      setRegenerateAPIkeyError(
        t(
          'web.admin.hardware.management.hardware.profile.regenerateAPIkey.error.text'
        )
      );
    }
  }

  return (
    <div>
      <Button className={styles.button} onClick={handleRegenerateApiKeyButton}>
        {t('web.admin.hardware.management.hardware.profile.button.regenerate')}
      </Button>
    </div>
  );
}
