/**
 * Gets messages from a GraphQL error for friendly display.
 */
export default function getMessagesFromError(error: unknown): string[] {
  const messages: string[] = [];

  if (!error) {
    return messages;
  }

  if (typeof error === 'string') {
    return [error];
  }

  if (Array.isArray(error)) {
    return error.map(getMessagesFromError).flat();
  }

  if (isError(error) && error.name === 'ValidationError') {
    if ((error as any).inner.length > 0) {
      return (error as any).inner.map((err: any) => err.message);
    }

    return [error.message];
  }

  if ((error as any).networkError?.result?.errors) {
    (error as any).networkError.result.errors.forEach((err: any) =>
      messages.push(err.message)
    );

    return messages;
  }

  if ((error as any)?.graphQLErrors?.length > 0) {
    (error as any).graphQLErrors.forEach((err: any) => {
      if (err.extensions?.exception) {
        const ex = err.extensions?.exception;

        if (ex.inner?.length > 0) {
          ex.inner.forEach((err2: any) => messages.push(err2.message));
        } else if (ex.message) {
          messages.push(ex.message);
        } else if (ex.raw?.message) {
          messages.push(ex.raw.message);
        } else {
          messages.push(err.message);
        }
      } else {
        messages.push(err.message);
      }
    });

    return messages;
  }

  if ((error as any).inner?.length > 0) {
    return (error as any).inner.map((err: any) => err.message);
  }

  if (hasMessage(error)) {
    return [error.message];
  }

  return [String(error)];
}

function isError(val: unknown): val is Error {
  if (typeof val !== 'object') {
    return false;
  }

  const error = val as Error;

  return (
    typeof error.name === 'string' &&
    typeof error.message === 'string' &&
    ['string', 'undefined'].includes(typeof error.stack)
  );
}

function hasMessage(val: unknown): val is { message: string } {
  if (typeof val !== 'object') {
    return false;
  }

  const error = val as { message: string };

  return typeof error.message === 'string';
}
