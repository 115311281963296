import React, { useState, useEffect } from 'react';

import cx from 'classnames';

import { isSupportedCountry } from 'libphonenumber-js';
import prettyPhoneNumber from 'lane-shared/helpers/formatters/prettyPhoneNumber';
import {
  CountryCode,
  parsePhoneNumber,
  ENCLOSED_PARENTHESIS_REGEX,
  formatIncompletePhoneNumber,
  dropdownItems,
} from 'lane-shared/helpers/phoneNumbers';

import { Flex } from 'components/layout';

import Dropdown from '../Dropdown/index';
import { Input } from 'design-system-web';

import styles from './styles.scss';

type Props = {
  onChange: (value: string) => void;
  label?: string;
  value: string;
  testId?: string;
  style?: React.CSSProperties;
  className?: string;
  isVerticallyStacked?: boolean;
  placeholder?: string;
  disabled?: boolean;
  error?: string[];
};

/**
 * Input that formats internation phone-numbers
 */
export default function PhoneNumberInput({
  onChange,
  label,
  value,
  testId,
  className,
  style,
  isVerticallyStacked,
  placeholder,
  disabled,
  error,
}: Props) {
  const browserLanguage = window.navigator.language;
  const browserRegion = new Intl.Locale(browserLanguage).region || '';
  const defaultRegion = isSupportedCountry(browserRegion)
    ? (browserRegion as CountryCode)
    : 'US';
  const [regionCode, setRegionCode] = useState<CountryCode>(defaultRegion);
  const [formattedNumber, setFormattedNumber] = useState('');
  const [newlyInputtedNumber, setNewlyInputtedNumber] = useState('');

  useEffect(() => {
    if (value) {
      const country = parsePhoneNumber(value)?.country;

      if (country) {
        setRegionCode(country);
      }
    }
  }, [value]);

  useEffect(() => {
    /* Prevent formatting when deleting closing parenthesis */
    const formatted = newlyInputtedNumber.match(ENCLOSED_PARENTHESIS_REGEX)
      ? newlyInputtedNumber
      : formatIncompletePhoneNumber(newlyInputtedNumber ?? '', regionCode);

    setFormattedNumber(formatted);
  }, [newlyInputtedNumber, regionCode]);

  function handleChange(value: string) {
    setNewlyInputtedNumber(value);
    const phoneNumber = parsePhoneNumber(value, regionCode);

    if (phoneNumber) {
      onChange(phoneNumber.format('E.164'));
    } else {
      onChange(value);
    }
  }

  return (
    <Flex
      gap={4}
      direction={isVerticallyStacked ? 'column' : 'row'}
      align={isVerticallyStacked ? 'flex-start' : 'center'}
      style={style}
      className={cx(
        styles.container,
        {
          [styles.stackedContainer]: isVerticallyStacked,
        },
        className
      )}
    >
      <div className={styles.dropdownWrapper}>
        <Dropdown
          items={dropdownItems}
          placeholder="Country"
          value={regionCode}
          onChange={code => setRegionCode(code.value)}
          className={cx(styles.dropdown, {
            [styles.stackedDropdown]: isVerticallyStacked,
          })}
          disabled={disabled}
        />
      </div>
      <Input
        className={cx(
          isVerticallyStacked ? styles.stackedInput : styles.input,
          styles.inputForm
        )}
        label={label}
        onChange={handleChange}
        value={formattedNumber || prettyPhoneNumber(value)}
        maxLength={16}
        showClear
        type="tel"
        testId={testId}
        fieldName="phoneNumber"
        placeholder={placeholder}
        disabled={disabled}
        error={error}
      />
    </Flex>
  );
}
