const format = new Intl.NumberFormat().format;

/**
 * convert bytes into string.
 */
function convert(b: number): string {
  const tb = (1 << 30) * 1024;
  const gb = 1 << 30;
  const mb = 1 << 20;
  const kb = 1 << 10;
  const abs = Math.abs(b);

  if (abs >= tb) return `${format(Math.round((b / tb) * 10) / 10)}Tb`;

  if (abs >= gb) return `${format(Math.round((b / gb) * 10) / 10)}Gb`;

  if (abs >= mb) return `${format(Math.round((b / mb) * 10) / 10)}Mb`;

  if (abs >= kb) return `${format(Math.round(b / kb))}Kb`;

  return `${format(b)}b`;
}

export default function getFriendlyBytes(
  size: number | string | null | undefined
): string {
  if (size === undefined || size === null) {
    return '';
  }

  return convert(typeof size === 'number' ? size : parseFloat(size));
}
