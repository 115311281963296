import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import Types from '../../../properties/Types';
import createShapeFromProperties from '../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../types/properties/Property';

export interface VisitorType {
  _id?: string; // id is generated by the service
  email?: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  phone?: string;
  tenantId?: string;
  _identifier?: number; // to manage editing and deleting before pushing to the service since the id won't be generated yet
}

export interface ActivateVisitor {
  id: string;
  channelId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  company?: string;
  createdAtDatetime?: string;
  updatedAtDatetime?: string;
  createdByUserId: string;
  updatedByUserId?: string;
}

export interface NewActivateVisitor {
  channelId: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  company?: string;
  tenantId?: string;
}

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    editable: false,
    default: null,
    validators: [],
  },
  email: {
    type: 'Email',
    default: null,
    validators: [],
  },
  firstName: {
    type: 'String',
    default: '',
    validators: [],
  },
  lastName: {
    type: 'String',
    default: '',
    validators: [],
  },
  company: {
    type: 'String',
    default: '',
    validators: [],
  },
  phone: {
    type: 'String',
    default: null,
    validators: [],
  },
};

export class VisitorObjectType extends ObjectTypeBase {
  name = 'VisitorType';

  friendlyName = 'A visitor invited into an interaction on this item.';

  properties = properties;

  contexts = [];

  get example(): VisitorType {
    return {
      _id: uuid(),
      email: 'john.smith@email.com',
      firstName: 'John',
      lastName: 'Smith',
      company: 'Company Inc.',
      phone: '5555555555',
    };
  }

  get default(): any {
    return {};
  }

  buildSchema() {
    const shape = createShapeFromProperties(properties);

    return yup.object().shape(shape).nullable();
  }
}

const definition = new VisitorObjectType();

Types.addType(definition);
export default definition;
