import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { OrderSummaryItemType } from 'lane-shared/domains/billingPayments/types';

import styles from './OrderSummaryItem.scss';

/**
 * @deprecated 5.190.0 - use OrderSummaryWithProducts.
 */
export function OrderSummaryItem({
  name,
  description,
  quantity,
  dollarAmount,
}: OrderSummaryItemType) {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.orderSummaryItemContainer)}>
      <div className={cx(styles.orderSummaryItemMainDetails)}>
        <p className={cx(styles.orderSummaryItemName)}>{name}</p>
        <div className={cx(styles.orderSummaryItemSecondaryDetails)}>
          {quantity && (
            <p className={cx(styles.orderSummaryItemQuantity)}>
              {t('abp.payment.orderSummary.quantity', { quantity })}
            </p>
          )}
          <p className={cx(styles.orderSummaryItemDescription)}>
            {description}
          </p>
        </div>
      </div>
      <p>{dollarAmount}</p>
    </div>
  );
}
