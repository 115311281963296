import { Password } from '../../../properties/baseTypes';
import { PackagedTypeEnum } from '../../../types/properties/PackagedTypeEnum';
import { SecurityRuleTypeEnum } from 'lane-shared/types/properties/PropertySecurity';
import { PERMISSION_INTEGRATION_READ } from 'lane-shared/helpers/constants/permissions';
import {
  PropertiesInterface,
  PropertyOptionType,
  PropertyType,
} from '../../../types/properties/Property';

export enum AccessControlServiceEntity {
  CCure = 'CCURE_2_8__2_9',
  Braxos = 'BRAXOS_1_0',
  Genea = 'GENEA_2_0',
  Openpath = 'OPENPATH_1_0',
  Amag = 'AMAG_9_0',
  Brivo = 'BRIVO_1_0',
  Datawatch = 'DATAWATCH_1_0',
  Maxxess = 'MAXXESS_6_0',
  Genetec = 'GENETEC_5_9',
  Gallagher = 'GALLAGHER_8_9',
  Dsx = 'DSX_6_0',
  SaltoSvn = 'SALTO_SVN_1_35',
  Sipass = 'SIPASS_1_0',
  Kastle = 'KASTLE_1_0',
}

export type AccessControlServiceOption = {
  _id: string;
  _order: number;
  name: string;
  value: AccessControlServiceEntity;
};

// Add integration specific fields here
export type IntegrationPropertyType<
  TOptions = PropertyOptionType
> = PropertyType<TOptions> & {
  accessGroupIDs?: any;
  vtsTenantId?: any;
  geneaTenantId?: any;
  geneaTenantIDs?: any;
  vtsTenantIDs?: any;
  kastleBuildings?: any;
  acsTenantId?: any;
};

export interface IntegrationsPropertiesInterface<
  TOptions = IntegrationPropertyType
> {
  [key: string]: IntegrationPropertyType<TOptions>;
}

export const AccessControlServiceDropdownOptions: Array<AccessControlServiceOption> = [
  {
    _id: '55de6e09-e434-465d-9d1e-eb565c5824ee',
    _order: 0,
    name: 'CCure',
    value: AccessControlServiceEntity.CCure,
  },
  {
    _id: '2712ba10-d9c2-4bd3-b57f-e34d577826a2',
    _order: 0,
    name: 'Braxos',
    value: AccessControlServiceEntity.Braxos,
  },
  {
    _id: 'c4735137-8afb-4fef-8a83-5832c128bec4',
    _order: 0,
    name: 'web.admin.channel.integrations.access.access-control-settings.genea',
    value: AccessControlServiceEntity.Genea,
  },
  {
    _id: '285b7573-4874-4284-ac95-98446b870e40',
    _order: 0,
    name: 'Openpath',
    value: AccessControlServiceEntity.Openpath,
  },
  {
    _id: '4fe575f9-1f28-4e75-8b72-2a45189c3dee',
    _order: 0,
    name: 'AMAG',
    value: AccessControlServiceEntity.Amag,
  },
  {
    _id: '5f3604d0-63a3-45ae-ba71-5e433ab7184c',
    _order: 0,
    name: 'Brivo',
    value: AccessControlServiceEntity.Brivo,
  },
  {
    _id: '065b6c54-6678-11ee-8cfe-325096b39f47',
    _order: 0,
    name: 'Datawatch',
    value: AccessControlServiceEntity.Datawatch,
  },
  {
    _id: '065b6c54-6678-11ee-8cfe-325096b39892',
    _order: 0,
    name: 'Maxxess',
    value: AccessControlServiceEntity.Maxxess,
  },
  {
    _id: 'b2089751-b613-4ba0-9b49-3eb9953ad50c',
    _order: 0,
    name: 'Genetec',
    value: AccessControlServiceEntity.Genetec,
  },
  {
    _id: 'c03d477c-7713-11ee-b962-0242ac120002',
    _order: 0,
    name: 'Gallagher',
    value: AccessControlServiceEntity.Gallagher,
  },
  {
    _id: 'c03d477c-7713-11ee-b962-0242ac120003',
    _order: 0,
    name: 'DSX',
    value: AccessControlServiceEntity.Dsx,
  },
  {
    _id: 'af456aeb-7554-4fbz-9e88-cff1fde30c4q',
    _order: 0,
    name: 'Salto SVN',
    value: AccessControlServiceEntity.SaltoSvn,
  },
  {
    _id: 'ecd0451b-85d1-4a15-8a53-dccb79a5af63',
    _order: 0,
    name: 'Sipass',
    value: AccessControlServiceEntity.Sipass,
  },
  {
    _id: '0036bba5-550f-4c1b-9215-e514d5cdc355',
    _order: 0,
    name: 'Kastle',
    value: AccessControlServiceEntity.Kastle,
  },
];

export const enum CCureVersionOptions {
  V2_8 = '2.8',
  V2_9 = '2.9',
  V3_0 = '3.0',
}

export const CCureClientVersionOptions = [
  {
    _id: '2.8',
    _order: 0,
    name: '2.8',
    value: CCureVersionOptions.V2_8,
  },
  {
    _id: '2.9',
    _order: 1,
    name: '2.9',
    value: CCureVersionOptions.V2_9,
  },
  {
    _id: '3.0',
    _order: 3,
    name: '3.0',
    value: CCureVersionOptions.V3_0,
  },
];

const MaxxessUserEmailSyncOptions = [
  {
    _id: '0',
    _order: 0,
    name: 'mobileEmail',
    value: 'mobileEmail',
  },
  {
    _id: '1',
    _order: 1,
    name: 'UDF Field',
    value: 'udfField',
  },
];

export const CCureVisitorConfigProperties: IntegrationsPropertiesInterface = {
  heading: {
    type: String.name,
    validators: [],
    friendlyName: 'Visitor Access Configuration',
  },
  visitorCardFormat: {
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    friendlyName: 'Visitor CHUID Format',
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  visitorCredentialRange: {
    type: String.name,
    validators: [],
    friendlyName: 'Credential range',
  },
  visitorFacilityCodeEnabled: {
    type: 'Boolean',
    friendlyName: 'Enable Visitor Facility Code',
    default: false,
  },
  visitorFacilityCode: {
    type: Number.name,
    friendlyName: 'Visitor Facility Code',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Min',
        value: 0,
      },
    ],
  },
  visitorAccessGroupMappings: {
    friendlyName: 'Access control mapping',
    type: String.name,
    tags: ['metadataFieldResponse'],
    validators: [],
    accessGroupIDs: [],
    vtsTenantIDs: [],
  },
};

export const CCureServiceProperties: PropertiesInterface<PropertyType> = {
  apiUrl: {
    type: String.name,
    friendlyName: 'Server URL',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  username: {
    type: String.name,
    friendlyName: 'Username',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  password: {
    type: Password.name,
    friendlyName: 'Password',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  clientVersion: {
    friendlyName: 'Client Version',
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: CCureClientVersionOptions,
      },
    ],
  },
  loadSettingsButton: {
    friendlyName: 'Load Settings',
    type: String.name,
    validators: [],
  },
  chuIdFormat: {
    friendlyName: 'CHUID Format',
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  mobileAccessUdf: {
    friendlyName: 'UDF for mobile access',
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    tags: ['metadataFieldResponse'],
    validators: [],
  },
  sendInviteEmailUdf: {
    friendlyName: 'UDF for onboarding email',
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    tags: ['metadataFieldResponse'],
    validators: [],
  },
  facilityCodeEnabled: {
    type: 'Boolean',
    friendlyName: 'Enable Facility Code',
    default: false,
  },
  facilityCode: {
    type: String.name,
    friendlyName: 'Facility Code',
  },
  partitionIdEnabled: {
    type: 'Boolean',
    friendlyName: 'Enable Partition Ids',
    tags: ['metadataFieldResponse'],
    default: false,
  },
  partitionId: {
    friendlyName: 'Partition Id',
    packagedType: PackagedTypeEnum.Dropdown,
    tags: ['metadataFieldResponse'],
    type: 'Option',
    validators: [],
  },
  visitorManagementEnabled: {
    type: 'Boolean',
    friendlyName: 'Visitor management',
    tags: ['metadataFieldResponse'],
    default: false,
  },
  ...CCureVisitorConfigProperties,
};

export const BraxosServiceProperties: PropertiesInterface<PropertyType> = {
  connectionId: {
    type: String.name,
    friendlyName: 'Connection ID',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  facilityCode: {
    type: String.name,
    friendlyName: 'Facility Code',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  loadSettingsButton: {
    friendlyName: 'Load Settings',
    type: String.name,
    validators: [],
  },
  cardFormat: {
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    friendlyName: 'Card Format',
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  serverUrl: {
    type: String.name,
    friendlyName: 'Server URL',
    tags: ['metadataFieldResponse'],
    validators: [],
    forDisplay: false,
  },
};

export const SipassVisitorConfigProperties: IntegrationsPropertiesInterface = {
  heading: {
    type: String.name,
    validators: [],
    friendlyName: 'Visitor access configuration',
  },
  visitorCardFormats: {
    packagedType: PackagedTypeEnum.MultiSelect,
    type: 'Option',
    friendlyName: 'Visitor Card Formats',
    isMultiSelect: true,
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'ArrayMin',
        value: 1,
      },
    ],
  },
  visitorCredentialRange: {
    type: String.name,
    validators: [],
    friendlyName: 'Credential range',
  },
  visitorAccessGroupMappings: {
    friendlyName: 'Access control mapping',
    type: String.name,
    tags: ['metadataFieldResponse'],
    validators: [],
    accessGroupIDs: [],
    vtsTenantIDs: [],
  },
};

export const SipassServiceProperties: PropertiesInterface<PropertyType> = {
  connectionId: {
    type: String.name,
    friendlyName: 'Connection ID',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  facilityCode: {
    type: String.name,
    friendlyName: 'Facility Code',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  loadSettingsButton: {
    friendlyName: 'Load Settings',
    type: String.name,
    validators: [],
  },
  cardFormats: {
    type: 'Option',
    friendlyName: 'Card Formats',
    packagedType: PackagedTypeEnum.MultiSelect,
    isMultiSelect: true,
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'ArrayMin',
        value: 1,
      },
    ],
  },
  serverUrl: {
    type: String.name,
    friendlyName: 'Server URL',
    tags: ['metadataFieldResponse'],
    validators: [],
    forDisplay: false,
  },
  visitorManagementEnabled: {
    type: 'Boolean',
    friendlyName: 'Visitor management',
    tags: ['metadataFieldResponse'],
    default: false,
  },
  ...SipassVisitorConfigProperties,
};

export const GeneaVisitorConfigProperties: IntegrationsPropertiesInterface = {
  heading: {
    type: String.name,
    validators: [],
    friendlyName: 'Visitor access configuration',
  },
  visitorCardFormat: {
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    friendlyName: 'Visitor Card Format',
    tags: ['metadataFieldResponse'],
    validators: [],
  },
  visitorCredentialRange: {
    type: String.name,
    validators: [],
    friendlyName: 'Credential range',
  },
  tenantMappings: {
    friendlyName: 'Tenant mapping',
    type: String.name,
    tags: ['metadataFieldResponse'],
    validators: [],
    geneaTenantId: [],
    vtsTenantId: [],
  },
  visitorAccessGroupMappings: {
    friendlyName: 'Access control mapping',
    type: String.name,
    tags: ['metadataFieldResponse'],
    validators: [],
    accessGroupIDs: [],
    geneaTenantIDs: [],
  },
};

export const GeneaServiceProperties: PropertiesInterface<PropertyType> = {
  apiKey: {
    type: String.name,
    friendlyName: 'Api Key',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  loadSettingsButton: {
    friendlyName: 'Load Settings',
    type: String.name,
    validators: [],
  },
  locationUuid: {
    packagedType: PackagedTypeEnum.Dropdown,
    tags: ['metadataFieldResponse'],
    friendlyName: 'Location',
    type: 'Option',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  cardFormat: {
    packagedType: PackagedTypeEnum.Dropdown,
    tags: ['metadataFieldResponse'],
    friendlyName: 'Card Formats',
    type: 'Option',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  facilityCode: {
    type: String.name,
    friendlyName: 'Facility Code',
    validators: [],
    default: '0',
  },
  customerUuid: {
    type: String.name,
    friendlyName: 'Customer ID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    forDisplay: false,
  },
  locationUdfEnabled: {
    type: 'Boolean',
    friendlyName: 'Enable Location UDF',
    tags: ['metadataFieldResponse'],
    default: false,
  },
  locationUdf: {
    packagedType: PackagedTypeEnum.Dropdown,
    tags: ['metadataFieldResponse'],
    friendlyName: 'Location UDF',
    type: 'Option',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  visitorManagementEnabled: {
    type: 'Boolean',
    friendlyName: 'Visitor management',
    tags: ['locationSelected'],
    default: false,
  },
  ...GeneaVisitorConfigProperties,
};

export const KastleVisitorConfigProperties: IntegrationsPropertiesInterface = {
  tenantMappings: {
    friendlyName: 'Tenant mapping',
    type: String.name,
    tags: ['metadataFieldResponse'],
    validators: [],
    vtsTenantId: [],
    kastleBuildings: [],
    acsTenantId: [],
  },
};

export const KastleServiceProperties: PropertiesInterface<PropertyType> = {
  heading: {
    type: String.name,
    validators: [],
    friendlyName: 'Visitor Access Configuration',
  },
  visitorManagementEnabled: {
    type: 'Boolean',
    friendlyName: 'Visitor management',
    default: false,
  },
  visitorConnectUsername: {
    type: String.name,
    friendlyName: 'Username',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  visitorConnectPassword: {
    type: Password.name,
    friendlyName: 'Password',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  visitorConnectOwnerGuid: {
    type: String.name,
    friendlyName: 'Owner Guid',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  loadSettingsButton: {
    friendlyName: 'Load Settings',
    type: String.name,
    validators: [],
  },
  buildingId: {
    packagedType: PackagedTypeEnum.Dropdown,
    tags: ['metadataFieldResponse'],
    friendlyName: 'Building',
    type: 'Option',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  configurableInputField: {
    friendlyName: 'Building GUID',
    type: String.name,
    validators: [],
    editable: false
  },
  ...KastleVisitorConfigProperties,
};

export const OpenpathServiceProperties: PropertiesInterface<PropertyType> = {
  serverUrl: {
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    friendlyName: 'Regional Url',
    validators: [
      {
        name: 'In',
        value: ['https://api.openpath.com', 'https://api.eu.openpath.com'],
      },
    ],
  },
  username: {
    type: String.name,
    friendlyName: 'Email',
    tags: ['metadataFieldRequest'],

    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  password: {
    type: Password.name,
    friendlyName: 'Password',
    tags: ['metadataFieldRequest'],

    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  organizationId: {
    type: String.name,
    friendlyName: 'Organization ID',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  facilityCode: {
    type: String.name,
    friendlyName: 'Facility Code',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  loadSettingsButton: {
    friendlyName: 'Load Settings',
    type: String.name,
    validators: [],
  },
  cardFormat: {
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    friendlyName: 'Card Format',
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  credentialType: {
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    friendlyName: 'Credential Type',
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export const AmagServiceProperties: PropertiesInterface<PropertyType> = {
  serverURL: {
    type: String.name,
    friendlyName: 'Server URL',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  username: {
    type: String.name,
    friendlyName: 'Username',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  password: {
    type: Password.name,
    friendlyName: 'Password',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  companyId: {
    type: String.name,
    friendlyName: 'Company ID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  customerCode: {
    type: String.name,
    friendlyName: 'Customer Code',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  emailTitleNumber: {
    friendlyName: 'Email title number',
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    validators: [
      {
        name: 'In',
        value: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
        ],
      },
    ],
  },
};

export const BrivoServiceProperties: PropertiesInterface<PropertyType> = {
  username: {
    type: String.name,
    friendlyName: 'Username',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  password: {
    type: Password.name,
    friendlyName: 'Password',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  clientId: {
    type: String.name,
    friendlyName: 'Client ID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  clientSecret: {
    type: String.name,
    friendlyName: 'Client Secret',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  apiKey: {
    type: String.name,
    friendlyName: 'Api Key',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  loadSettingsButton: {
    friendlyName: 'Load Settings',
    type: String.name,
    validators: [],
  },
  cardFormat: {
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    friendlyName: 'Card Format',
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export const DatawatchServiceProperties: PropertiesInterface<PropertyType> = {
  userId: {
    type: String.name,
    friendlyName: 'User Id',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  password: {
    type: Password.name,
    friendlyName: 'Password',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  accessToken: {
    type: String.name,
    friendlyName: 'Access Token',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  bldgId: {
    type: String.name,
    friendlyName: 'Building Id',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  sitecode: {
    type: String.name,
    friendlyName: 'Sitecode',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export const MaxxessServiceProperties: PropertiesInterface<PropertyType> = {
  serverUrl: {
    type: String.name,
    friendlyName: 'Server hostname',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  username: {
    type: String.name,
    friendlyName: 'Username',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  password: {
    type: Password.name,
    friendlyName: 'Password',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  emailType: {
    friendlyName: 'Select option to Sync Email',
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: MaxxessUserEmailSyncOptions,
      },
    ],
  },
  userEmailField: {
    type: String.name,
    friendlyName: 'Name of UDF field for Email',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  loadSettingsButton: {
    friendlyName: 'Load Settings',
    type: String.name,
    validators: [],
  },
  templates: {
    packagedType: PackagedTypeEnum.MultiSelect,
    type: 'Option',
    friendlyName: 'Select Template(s)',
    tags: ['metadataFieldResponse'],
    validators: [],
  },
};

export const GenetecVisitorConfigProperties: IntegrationsPropertiesInterface = {
  heading: {
    type: String.name,
    validators: [],
    friendlyName: 'Visitor access configuration',
  },
  visitorCardFormat: {
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    friendlyName: 'Visitor Card Format',
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  visitorCredentialRange: {
    type: String.name,
    validators: [],
    friendlyName: 'Credential range',
  },
  visitorAccessGroupMappings: {
    friendlyName: 'Access control mapping',
    type: String.name,
    tags: ['metadataFieldResponse'],
    validators: [],
    accessGroupIDs: [],
    vtsTenantIDs: [],
  },
};

export const GenetecServiceProperties: PropertiesInterface<PropertyType> = {
  username: {
    type: String.name,
    friendlyName: 'Username',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  password: {
    type: Password.name,
    friendlyName: 'Password',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  serverUrl: {
    type: String.name,
    friendlyName: 'Server URL',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  loadSettingsButton: {
    friendlyName: 'Load Settings',
    type: String.name,
    validators: [],
  },
  cardFormat: {
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    friendlyName: 'Card Format',
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  facilityCode: {
    type: String.name,
    friendlyName: 'Facility Code',
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  mobAccessUdf: {
    friendlyName: 'UDF for mobile access',
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    tags: ['metadataFieldResponse'],
    validators: [],
  },
  cardholderGroupsFilterEnabled: {
    type: 'Boolean',
    friendlyName: 'Enable Partition by Cardholder Group',
    default: false,
  },
  cardholderGroups: {
    friendlyName: 'Cardholder Group',
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  visitorManagementEnabled: {
    type: 'Boolean',
    friendlyName: 'Visitor management',
    tags: ['metadataFieldResponse'],
    default: false,
  },
  ...GenetecVisitorConfigProperties,
};

export const GallagherServiceProperties: PropertiesInterface<PropertyType> = {
  apiUrl: {
    type: String.name,
    friendlyName: 'Server URL',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  apiKey: {
    type: String.name,
    friendlyName: 'Api Key',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  certificate: {
    type: 'File',
    friendlyName: 'Client certification',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  passphrase: {
    type: String.name,
    friendlyName: 'Certification passphrase',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  clientCertificate: {
    type: String.name,
    friendlyName: 'Certificate',
    hidden: true,
    forDisplay: false,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  privateKey: {
    type: String.name,
    friendlyName: 'Private Key',
    hidden: true,
    forDisplay: false,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  loadSettingsButton: {
    friendlyName: 'Load Settings',
    type: String.name,
    validators: [],
  },
  emailPdf: {
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    friendlyName: 'Email personal data field',
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  cardFormat: {
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    friendlyName: 'Card format',
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  division: {
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    friendlyName: 'Division',
    tags: ['metadataFieldResponse'],
    validators: [],
  },
};

export const DsxServiceProperties: PropertiesInterface<PropertyType> = {
  serverUrl: {
    type: String.name,
    friendlyName: 'Server URL',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  username: {
    type: String.name,
    friendlyName: 'Username',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  password: {
    type: Password.name,
    friendlyName: 'Password',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  sharedFilePath: {
    type: String.name,
    friendlyName: 'Shared File Path',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  loadSettingsButton: {
    friendlyName: 'Load Settings',
    type: String.name,
    validators: [],
  },
  locGrp: {
    packagedType: PackagedTypeEnum.Dropdown,
    type: 'Option',
    friendlyName: 'Location Group',
    tags: ['metadataFieldResponse'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export const SaltoSvnServiceProperties: PropertiesInterface<PropertyType> = {
  serverUrl: {
    type: String.name,
    friendlyName: 'Server URL',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  username: {
    type: String.name,
    friendlyName: 'Username',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  password: {
    type: Password.name,
    friendlyName: 'Password',
    tags: ['metadataFieldRequest'],
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
    secure: {
      read: [
        {
          type: SecurityRuleTypeEnum.ChannelPermission,
          value: PERMISSION_INTEGRATION_READ,
        },
      ],
    },
  },
  emailGpf: {
    type: String.name,
    friendlyName: 'Email GPF',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  loadSettingsButton: {
    friendlyName: 'Load Settings',
    type: String.name,
    validators: [],
  },
  partitionIdEnabled: {
    type: 'Boolean',
    friendlyName: 'Enable Partition Ids',
    default: false,
  },
  partitionId: {
    friendlyName: 'Partition Id',
    packagedType: PackagedTypeEnum.Dropdown,
    tags: ['metadataFieldResponse'],
    type: 'Option',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export const AccessControlServiceDefinitions: {
  [key in AccessControlServiceEntity]: IntegrationsPropertiesInterface;
} = {
  [AccessControlServiceEntity.CCure]: CCureServiceProperties,
  [AccessControlServiceEntity.Braxos]: BraxosServiceProperties,
  [AccessControlServiceEntity.Genea]: GeneaServiceProperties,
  [AccessControlServiceEntity.Openpath]: OpenpathServiceProperties,
  [AccessControlServiceEntity.Amag]: AmagServiceProperties,
  [AccessControlServiceEntity.Brivo]: BrivoServiceProperties,
  [AccessControlServiceEntity.Datawatch]: DatawatchServiceProperties,
  [AccessControlServiceEntity.Maxxess]: MaxxessServiceProperties,
  [AccessControlServiceEntity.Genetec]: GenetecServiceProperties,
  [AccessControlServiceEntity.Gallagher]: GallagherServiceProperties,
  [AccessControlServiceEntity.Dsx]: DsxServiceProperties,
  [AccessControlServiceEntity.SaltoSvn]: SaltoSvnServiceProperties,
  [AccessControlServiceEntity.Sipass]: SipassServiceProperties,
  [AccessControlServiceEntity.Kastle]: KastleServiceProperties,
};
