import { useState, useRef } from 'react';

import castForUpdate from '../helpers/castForUpdate';

/**
 * Helper hook to manage updating data objects that simplifies some common
 * patterns.
 *
 * 1. takes a data object and returns a setter that allows modyfing that object
 * with a delta object passed in.
 *
 * 2. tracks if this object has changed at all
 *
 * 3. tracks the list of properties that have changed on this object.
 *
 * 4. provides a delta to supply only the values that have changed.
 *
 * @param data
 * @returns {[unknown, function(*=): unknown, *, boolean, Array]}
 */
export default function useUpdatedData<T>(data: any) {
  const initialData = useRef(castForUpdate(data)).current;
  // the object that we are storing
  const [_data, setData] = useState<T>(initialData);
  // has this object changed at all?
  const [hasChanged, setHasChanged] = useState(false);
  // a list of keys that have changed
  const [updatedProperties, setUpdatedProperties] = useState<any[]>([]);

  /**
   * returns a set state handler to the component using this hook.
   * allows passing in a delta object to patch the current data with.
   *
   * optionally allows a reset to set the state back to unchanged.
   *
   * @param update the update to patch the data with
   * @param reset reset the state back to unchanged.
   * @returns {unknown} the updated object that will hit the state on next render
   */
  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  function onDataUpdated(update: Partial<T>, reset = false) {
    if (reset) {
      resetChanges();
      setData(castForUpdate(update));

      return update;
    }

    setData(_data => {
      // merge the object that is passed in with the existing one.
      const updatedData = {
        ..._data,
        ...update,
      };

      // note that we have changed.
      setHasChanged(true);

      // keep a list of keys have changed.
      setUpdatedProperties([
        ...updatedProperties,
        ...Object.keys(update).filter(key => !updatedProperties.includes(key)),
      ]);

      return updatedData;
    });
  }

  /**
   * gets the delta object, i.e. the object that can be passed into an update
   * on graphql.
   */
  function getDelta() {
    const ret = {};

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    updatedProperties.forEach(key => (ret[key] = _data[key]));

    return castForUpdate(ret);
  }

  function resetChanges() {
    setHasChanged(false);
    setUpdatedProperties([]);
    setData(initialData);
  }

  return [
    _data as T,
    onDataUpdated,
    hasChanged,
    getDelta,
    updatedProperties,
    resetChanges,
    setHasChanged,
  ] as const;
}
