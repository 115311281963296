import React, { useState } from 'react';

import { AdminPage, Button, ControlMenu, ErrorMessage } from 'components';
import { H3 } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { useOrganizationSettingsData } from 'lane-shared/domains/visitorManagement/hooks/useOrganizationSettingsData';
import {
  ActivateEmailTemplate,
  EmailTemplateType,
  VisitorConfiguration,
} from 'lane-shared/domains/visitorManagement/types';
import { OrganizationSettings } from 'lane-shared/domains/visitorManagement/types/OrganizationSettingsType';
import {
  getVisitorEmailTemplate,
  upsertVisitorEmailTemplate,
  upsertVisitorManagementOrganizationSettings,
} from 'lane-shared/graphql/visitorManagement';
import { castForUpdate, pause } from 'lane-shared/helpers';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import TabStrip from 'lane-web/src/components/general/TabStrip';
import { useQueryString } from 'lane-web/src/hooks';

import history from '../../../../helpers/history';
import LeaveConfirmationPrompt from '../../components/LeaveConfirmationPrompt/LeaveConfirmationPrompt';
import { VisitorManagementEmailTemplateTab } from './tabs/emailTemplate';
import { VisitorManagementGeneralTab } from './tabs/general';
import { VisitorManagementNotificationsTab } from './tabs/notifications';
import { VisitorManagementSchedulingTab } from './tabs/scheduling';

import styles from './styles.scss';

const defaultTab = 'notifications';
const TabsMap = {
  general: {
    label: 'web.admin.visitorManagement.configuration.tab.general',
    value: 'general',
    Component: VisitorManagementGeneralTab,
  },
  scheduling: {
    label: 'web.admin.visitorManagement.configuration.tab.scheduling',
    value: 'scheduling',
    Component: VisitorManagementSchedulingTab,
  },
  notifications: {
    label: 'web.admin.visitorManagement.configuration.tab.notifications',
    value: 'notifications',
    Component: VisitorManagementNotificationsTab,
  },
  emailTemplate: {
    label: 'web.admin.visitorManagement.configuration.tab.emailTemplate',
    value: 'emailTemplate',
    Component: VisitorManagementEmailTemplateTab,
  },
};

const getTabComponent = (
  selectedTab: keyof typeof TabsMap
): (({
  channel,
  onConfigUpdated,
}: {
  channel: any;
  config: VisitorConfiguration;
  onConfigUpdated: (update: any) => void;
  loading?: boolean;
}) => JSX.Element) =>
  selectedTab ? TabsMap[selectedTab].Component : TabsMap[defaultTab].Component;

type VisitorConfigurationControlMenuProps = {
  query: { tab: keyof typeof TabsMap };
  updatedConfig: VisitorConfiguration | null;
  disableButtons: boolean;
  resetData: () => void;
  loading: boolean;
  onSave: () => Promise<void>;
  goToUrl: (props: any) => void;
  t: (key: string) => string;
};

const VisitorConfigurationControlMenu = ({
  query,
  updatedConfig,
  disableButtons,
  resetData,
  loading,
  onSave,
  goToUrl,
  t,
}: VisitorConfigurationControlMenuProps) => {
  return (
    <ControlMenu>
      <TabStrip
        tabs={Object.values(TabsMap)}
        selected={{ value: query.tab }}
        onSelectTab={tab => goToUrl({ tab: tab.value })}
        className={styles.tabs}
      />
      <Button
        testId="resetButton"
        disabled={!updatedConfig || disableButtons}
        onClick={resetData}
        loading={loading}
      >
        {t('web.admin.channel.visitor.log.configuration.tabs.button.undo')}
      </Button>
      <Button
        testId="saveButton"
        disabled={!updatedConfig || disableButtons}
        onClick={onSave}
        loading={loading}
        variant="contained"
      >
        {t('web.admin.channel.visitor.log.configuration.tabs.button.save')}
      </Button>
    </ControlMenu>
  );
};

export function VisitorConfigurationPage({
  channel,
}: {
  channel: any;
}): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const { data: emailTemplateData, refetch: refetchEmailTemplate } = useQuery(
    getVisitorEmailTemplate,
    {
      variables: {
        channelId: convertToUUID(channel?._id),
        templateType: EmailTemplateType.INVITE,
      },
      fetchPolicy: 'network-only',
    }
  );

  const isGeneralSettingsEnabled = useFlag(
    FeatureFlag.VisitorManagementGeneralSettings,
    false
  );
  const isSchedulingSettingsEnabled = useFlag(
    FeatureFlag.VisitorManagementSchedulingSettings,
    false
  );

  if (!isGeneralSettingsEnabled) {
    // @ts-ignore The operand of a 'delete' operator must be optional.
    delete TabsMap.general;
  }

  if (!isSchedulingSettingsEnabled) {
    // @ts-ignore The operand of a 'delete' operator must be optional.
    delete TabsMap.scheduling;
  }

  const {
    organizationSettings: organizationSettingsData,
    refetch: refetchOrganizationSettings,
    loading: organizationSettingsLoading,
  } = useOrganizationSettingsData(convertToUUID(channel?._id));

  if (loading !== organizationSettingsLoading) {
    setLoading(organizationSettingsLoading);
  }

  const { t } = useTranslation();
  const [query, goToUrl] = useQueryString<{
    tab: keyof typeof TabsMap;
  }>({
    tab: defaultTab,
  });

  const [error, setError] = useState(null);
  const [
    updatedConfig,
    setUpdatedConfig,
  ] = useState<VisitorConfiguration | null>(null);
  const [
    updatedEmailTemplateProperties,
    setUpdatedEmailTemplateProperties,
  ] = useState<string[]>([]);

  const [
    updatedOrganizationSettingsProperties,
    setUpdatedOrganizationSettingsProperties,
  ] = useState<string[]>([]);

  const visitorConfiguration: VisitorConfiguration = {
    emailTemplate: {
      ...emailTemplateData?.visitorEmailTemplate,
      ...updatedConfig?.emailTemplate,
    },
    organizationSettings: {
      ...organizationSettingsData,
      ...updatedConfig?.organizationSettings,
    },
  };

  const onConfigUpdated = (update: any) => {
    setDisableButtons(false);
    setUpdatedConfig({
      emailTemplate: {
        ...(updatedConfig?.emailTemplate ||
          emailTemplateData?.visitorEmailTemplate),
        ...update?.emailTemplate,
      },

      organizationSettings: {
        ...updatedConfig?.organizationSettings,
        ...update?.organizationSettings,
      },
    });

    if (update.emailTemplate) {
      setUpdatedEmailTemplateProperties([
        'qrEnabled',
        ...updatedEmailTemplateProperties,
        ...Object.keys(update.emailTemplate).filter(
          key => !updatedEmailTemplateProperties.includes(key)
        ),
      ]);
    }

    if (update.organizationSettings) {
      setUpdatedOrganizationSettingsProperties([
        ...updatedOrganizationSettingsProperties,
        ...Object.keys(update.organizationSettings).filter(
          key => !updatedOrganizationSettingsProperties.includes(key)
        ),
      ]);
    }
  };

  const resetData = () => {
    setUpdatedEmailTemplateProperties([]);
    setUpdatedOrganizationSettingsProperties([]);
    setUpdatedConfig(null);
    refetchEmailTemplate();
    refetchOrganizationSettings();
  };

  const onSave = async () => {
    setDisableButtons(true);

    try {
      setLoading(true);
      setError(null);

      if (updatedConfig?.emailTemplate) {
        await saveEmailTemplate(updatedConfig?.emailTemplate);
      }

      if (updatedConfig?.organizationSettings) {
        await saveOrganizationSettings(updatedConfig?.organizationSettings);
      }

      await pause();

      await window.Toast.show(
        t('web.admin.visitorManagement.configuration.save.success')
      );
    } catch (err: any) {
      setError(err);
      window.Alert.alert({
        title: t('web.admin.visitorManagement.configuration.save.error.title'),
        message: t(
          'web.admin.visitorManagement.configuration.save.error.message'
        ),
        error: err,
      });
    }
  };

  async function saveEmailTemplate(
    updatedEmailTemplate: ActivateEmailTemplate
  ) {
    const update: Partial<ActivateEmailTemplate> = {};

    updatedEmailTemplateProperties.forEach((key: string) => {
      const keyTyped = key as keyof ActivateEmailTemplate;

      // @ts-ignore Type 'string | boolean | Date | undefined' is not assignable to type 'undefined'.
      update[keyTyped] = updatedEmailTemplate[keyTyped];
    });

    if (Object.keys(update).length !== 0) {
      const casted = castForUpdate(update);

      await getClient().mutate({
        mutation: upsertVisitorEmailTemplate,
        variables: {
          emailTemplate: {
            ...casted,
          },
        },
      });
    }
  }

  async function saveOrganizationSettings(
    updatedOrganizationSettings: OrganizationSettings
  ) {
    const update: OrganizationSettings = {};

    updatedOrganizationSettingsProperties.forEach((key: string) => {
      const keyTyped = key as keyof OrganizationSettings;

      // @ts-ignore Type 'string | boolean | Date | undefined' is not assignable to type 'undefined'.
      update[keyTyped] = updatedOrganizationSettings[keyTyped];
    });

    if (Object.keys(update).length !== 0) {
      const casted = castForUpdate(update);

      await getClient().mutate({
        mutation: upsertVisitorManagementOrganizationSettings,
        variables: {
          organizationSettings: {
            ...casted,
          },
        },
      });
    }
  }

  const CurrentTab = getTabComponent(query.tab);

  return (
    <>
      <LeaveConfirmationPrompt
        testId="visitorLeaveConfirmation"
        when={!!updatedConfig && !disableButtons}
        navigate={path => history.push(path)}
        title={t('web.admin.visitorManagement.configuration.leavePrompt.title')}
        description={t(
          'web.admin.visitorManagement.configuration.leavePrompt.description'
        )}
      />
      <AdminPage>
        <H3 as="h1">{t('web.admin.visitorManagement.configuration')}</H3>
        <VisitorConfigurationControlMenu
          updatedConfig={updatedConfig}
          disableButtons={disableButtons}
          loading={loading}
          query={query}
          resetData={resetData}
          onSave={onSave}
          goToUrl={goToUrl}
          t={t}
        />
        <ErrorMessage error={error} />
        <CurrentTab
          channel={channel}
          config={visitorConfiguration}
          onConfigUpdated={onConfigUpdated}
          loading={loading}
        />
      </AdminPage>
    </>
  );
}
