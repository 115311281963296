import { useContext, useEffect, useState, useMemo, useRef } from 'react';

import { DateTime } from 'luxon';
import { object } from 'yup';

import { UserContentInteractionContext, UserDataContext } from '../contexts';
import { getTimeZoneByGeoLocation } from '../helpers';
import {
  doDateRangesOverlap,
  getDateRangeDailyBookEnds,
  parseDateTime,
} from '../helpers/dates';
import {
  explodeFeatures,
  getDayUnavailableDateRangesFromTimeAvailability,
  getDefaultDatesForReservable,
  getMonthUnavailableDateRangesFromTimeAvailability,
  getReservableBestRule as getReservableBestRuleByGroupRole,
} from '../helpers/features';
import { getMinMaxDateByGroupRole } from '../helpers/features/getMinMaxDateByGroupRole';
import getBufferTimeDateRangesForReservations from '../helpers/reservableAvailability/getBufferTimeDateRangesForReservations';
import { DateRangeType } from '../types/baseTypes/DateRangeType';
import { ReservableUnitTypesEnum } from '../types/features/ReservableFeatureProperties';
import useReservableAvailability from './useReservableAvailability';
import { ReservableEventTypeEnum } from './useReservableAvailabilityByRange';

type ReservableInputProps = {
  value: DateRangeType | null | undefined;
  content: any;
  forAdmin: boolean;
  viewingDate?: DateTime;
  onInput: (dateRange: DateRangeType) => void;
  existingValue?: DateRangeType | null;
};

export default function useReservableInput({
  value,
  content,
  forAdmin,
  viewingDate = DateTime.local(),
  onInput,
  existingValue: _existingValue,
}: ReservableInputProps) {
  const existingValueRef = useRef(_existingValue);
  const existingValue = existingValueRef.current;

  const { user } = useContext(UserDataContext);
  const { validateAdditional } = useContext(UserContentInteractionContext);

  const userGroupRoleIds = useMemo(() => {
    if (!user?._id) {
      return [];
    }

    return user.roles.map(({ groupRole }) => groupRole._id);
  }, [user?._id]);

  // we will need a timezone for this
  const timeZone = useMemo(
    () =>
      getTimeZoneByGeoLocation({
        latitude: content?.geo?.[1],
        longitude: content?.geo?.[0],
      }),
    [content?._id]
  );

  // get the features from the content
  const { reservableFeature, timeAvailabilityFeature } = explodeFeatures(
    content?.features
  );

  const bestRule = getReservableBestRuleByGroupRole(
    reservableFeature,
    userGroupRoleIds
  );
  const { minDate, maxDate } = getMinMaxDateByGroupRole({
    bestRule,
    viewingDate,
    timeZone,
    dateRange: reservableFeature?.dateRange,
  });

  // reference date is what the user is currently looking at
  const [referenceDate, setReferenceDate] = useState<DateTime>(
    minDate && minDate > viewingDate.setZone(timeZone)
      ? minDate
      : viewingDate.setZone(timeZone)
  );

  // memo-ize so we aren't constantly recreating these dates
  const defaultRange = useMemo(
    () =>
      getDefaultDatesForReservable({
        units: reservableFeature?.units,
        unitType: reservableFeature?.unitType,
        minDate,
        maxDate,
        referenceDate,
      }),
    [reservableFeature, timeZone, referenceDate?.toMillis()]
  );

  const {
    unavailableDateRanges: unavailableDateRangesForReservations,
    loading,
    error,
  } = useReservableAvailability({
    timeZone,
    referenceDate,
    contentId: content?._id,
    reservableFeature,
  });

  // admins should have NO unavailable times regardless of actual availability.
  // Daily unavailable ranges are different than the monthly ranges.  Daily
  // will be used for reservable of units in hours.
  const timeAvailabilityUnavailableDateRanges = useMemo(
    () =>
      forAdmin
        ? []
        : getDayUnavailableDateRangesFromTimeAvailability(
            referenceDate ||
              parseDateTime(value?.startDate, timeZone) ||
              parseDateTime(defaultRange.startDate, timeZone),
            timeAvailabilityFeature,
            timeZone,
            user?.roles?.map(({ groupRole }) => groupRole._id) || []
          ),
    [
      forAdmin,
      value?.startDate?.getTime?.(),
      referenceDate?.toMillis?.(),
      timeAvailabilityFeature,
    ]
  );

  const { minTime, maxTime } = useMemo(
    () =>
      getDateRangeDailyBookEnds(
        [...timeAvailabilityUnavailableDateRanges],
        referenceDate?.toJSDate(),
        timeZone
      ),
    [timeAvailabilityUnavailableDateRanges, referenceDate, timeZone]
  );

  const unavailableDateRangesForBufferTime = useMemo(() => {
    const units = reservableFeature?.units;
    const bufferTime = reservableFeature?.bufferTime;
    const allowedCapacityPerSlotForBufferTime = 1;
    const maxQuantityPerSlot = reservableFeature?.maxQuantityPerSlot;

    if (
      !bufferTime ||
      !units ||
      !maxQuantityPerSlot ||
      maxQuantityPerSlot > allowedCapacityPerSlotForBufferTime
    ) {
      return [];
    }

    const reservations = unavailableDateRangesForReservations
      .filter(
        unavailable => unavailable.eventType !== ReservableEventTypeEnum.Buffer
      )
      .map(({ interval }) => interval);

    return getBufferTimeDateRangesForReservations({
      units,
      minTime,
      maxTime,
      reservations,
      referenceDate: referenceDate.toJSDate(),
      bufferTime,
      timeZone,
    });
  }, [
    reservableFeature,
    referenceDate,
    unavailableDateRangesForReservations,
    minTime,
    maxTime,
  ]);

  const reservableUnavailableDateRanges = useMemo(
    () =>
      unavailableDateRangesForReservations
        .map(({ interval }) => interval)
        .concat(unavailableDateRangesForBufferTime),
    [unavailableDateRangesForBufferTime]
  );

  const filteredUnavailableDateRanges = useMemo(() => {
    const isNotSavedReservation = (dr: DateRangeType) => {
      return (
        dr.startDate?.toISOString() !==
          existingValue?.startDate?.toISOString() &&
        dr.endDate?.toISOString() !== existingValue?.endDate?.toISOString()
      );
    };

    return reservableUnavailableDateRanges.filter(isNotSavedReservation);
  }, [reservableUnavailableDateRanges, existingValue]);

  useEffect(() => {
    const startDate = parseDateTime(
      value?.startDate || defaultRange.startDate,
      timeZone
    );

    if (!(startDate && referenceDate)) {
      return;
    }

    switch (reservableFeature?.unitType) {
      case ReservableUnitTypesEnum.Days:
      case ReservableUnitTypesEnum.Weeks:
      case ReservableUnitTypesEnum.Months:
        if (!startDate.hasSame(referenceDate, 'month')) {
          setReferenceDate(startDate);
        }

        break;
      case ReservableUnitTypesEnum.Minutes:
      default:
        if (!startDate.hasSame(referenceDate, 'day')) {
          setReferenceDate(startDate);
        }

        break;
    }
  }, [value?.startDate?.getTime?.(), value?.endDate?.getTime?.()]);

  const unavailableDates = useMemo(
    () =>
      (timeAvailabilityFeature?.unavailableDates || [])
        .filter(Boolean)
        .map(ud => ({
          startDate: parseDateTime(
            ud?.dateRange?.startDate,
            timeZone
          )?.toJSDate(),
          endDate: parseDateTime(ud?.dateRange?.endDate, timeZone)?.toJSDate(),
        })),
    [timeAvailabilityFeature?.unavailableDates]
  );

  // admins should have NO unavailable times regardless of actual availability.
  // monthly unavailable ranges are different than daily.  Monthly will be used
  // for reservable in hours showing a monthly calendar to switch days, or
  // a daily reservable to show its monthly calendar.
  const monthlyTimeAvailabilityUnavailableDateRanges = useMemo(
    () =>
      forAdmin
        ? []
        : [
            ...unavailableDates,
            ...getMonthUnavailableDateRangesFromTimeAvailability(
              referenceDate ||
                parseDateTime(value?.startDate, timeZone) ||
                parseDateTime(defaultRange.startDate, timeZone),
              timeAvailabilityFeature,
              timeZone,
              user?.roles?.map(({ groupRole }) => groupRole._id) || []
            ),
          ],
    [
      forAdmin,
      value?.startDate?.getTime?.(),
      referenceDate?.toMillis?.(),
      timeAvailabilityFeature,
    ]
  );

  function updateReferenceDate(date: Date | DateTime) {
    setReferenceDate(parseDateTime(date, timeZone) as DateTime);
  }

  const unavailableDateRanges: DateRangeType[] = useMemo(
    () => [...filteredUnavailableDateRanges],
    [filteredUnavailableDateRanges]
  );

  const unavailableMonthlyRanges = useMemo(() => {
    const pastDateRange: DateRangeType[] = [];

    pastDateRange.push({
      startDate: new Date(0),
      endDate: DateTime.now().setZone(timeZone).minus({ days: 1 }).toJSDate(),
    });

    return [
      ...reservableUnavailableDateRanges,
      ...monthlyTimeAvailabilityUnavailableDateRanges,
      ...pastDateRange,
    ];
  }, [
    reservableUnavailableDateRanges,
    monthlyTimeAvailabilityUnavailableDateRanges,
  ]);

  function validateDateRange(dateRange: DateRangeType) {
    const isValidDateRange = Boolean(dateRange.startDate && dateRange.endDate);

    if (!isValidDateRange) {
      return; // NOTE: Required validation should cover this case.
    }

    const hasOverlap = unavailableDateRanges.some((udr: DateRangeType) =>
      doDateRangesOverlap(dateRange, udr)
    );

    const additionalSchema = object({
      features: object({
        Reservable: object().test(
          'is-overlapping',
          'Your reservation cannot overlap with another.',
          () => {
            return !hasOverlap;
          }
        ),
      }),
    });

    validateAdditional(additionalSchema);
  }

  useEffect(() => {
    if (!value) {
      return;
    }

    if (value.startDate && value.endDate) {
      validateDateRange(value);
    }
  }, [unavailableDateRanges, value?.startDate, value?.endDate]);

  function handleChangeDateRange(dateRange: DateRangeType) {
    validateDateRange(dateRange);
    onInput(dateRange);
  }

  return {
    reservableFeature,
    timeAvailabilityFeature,
    defaultRange,
    loading,
    error,
    minDate: minDate?.toJSDate(),
    maxDate: maxDate?.toJSDate(),
    minTime,
    maxTime,
    maxSlots: bestRule.maxSlots,
    timeZone,
    referenceDate: referenceDate?.toJSDate(),
    setReferenceDate: updateReferenceDate,
    unavailableDateRanges,
    reservableUnavailableDateRanges,
    timeAvailabilityUnavailableDateRanges,
    monthlyTimeAvailabilityUnavailableDateRanges,
    unavailableMonthlyRanges,
    minMaxUnavailableDateRanges: [], // @deprecated, the value is always an empty list
    handleChangeDateRange,
  };
}
