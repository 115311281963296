import React from 'react';

import { useTranslation } from 'react-i18next';

import { constructFriendlyType } from 'lane-shared/helpers/properties';
import {
  FriendlyTypeOption,
  FriendlyType,
} from 'lane-shared/helpers/properties/friendlyTypeOptions';
import { useCurrentChannel } from 'lane-shared/hooks';
import { PropertyType } from 'lane-shared/types/properties/Property';
import { validateProperty } from 'lane-shared/validation';

import { MultiLanguageWrapper } from 'components/general/MultiLanguageWrapper';

import ValidationDropdown from '../../../../form/ValidatedDropdown';
import ValidatedInput from '../../../../form/ValidatedInput';
import Label from '../../../../general/Label';
import { FRIENDLY_PROPERTY_TEXTS } from './constants';

import styles from './FriendlyPropertyCreate.scss';

const INPUT_MAX_LENGTH = 30;

type FriendlyPropertyCreateProps = {
  friendlyType: FriendlyType | null;
  property: PropertyType;
  onPropertyUpdated: (
    property: Partial<PropertyType>,
    resetField?: boolean
  ) => void;
  setExample: (value: any) => void;
  availableFriendlyTypes: FriendlyTypeOption[];
  hasPlaceholder: boolean;
  isSelectUser?: number;
};

export default function FriendlyPropertyCreate({
  friendlyType,
  availableFriendlyTypes,
  hasPlaceholder,
  property,
  onPropertyUpdated,
  setExample,
  isSelectUser,
}: FriendlyPropertyCreateProps) {
  const { t } = useTranslation();
  const channel = useCurrentChannel();

  function changeType(type: any) {
    const newProperty = constructFriendlyType(type, property.name || '');

    onPropertyUpdated(newProperty, true);
    setExample(newProperty.isArray ? [] : null);
  }

  return (
    <>
      <fieldset className={styles.field} data-test="input-type-dropdown">
        <Label h1>{t(FRIENDLY_PROPERTY_TEXTS.label.inputType)}</Label>
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'validation' is missing in type '{ name: ... Remove this comment to see the full error message */}
        <ValidationDropdown
          name="property-type"
          className={styles.friendlyTypeSelector}
          items={availableFriendlyTypes}
          value={friendlyType}
          onValueChange={changeType}
        />
      </fieldset>
      <MultiLanguageWrapper
        channel={channel}
        model={property}
        column="friendlyName"
      >
        {({ labelMaker, onChangeUpdates, valueGetter, isPrimary }) => {
          const label = (
            <Label
              className={styles.inputLabel}
              mt={0}
              TooltipComponent={t(FRIENDLY_PROPERTY_TEXTS.tooltip.name)}
            >
              {t(FRIENDLY_PROPERTY_TEXTS.label.name)}
            </Label>
          );

          return (
            <fieldset className={styles.field} data-test="property-name">
              {labelMaker({ label, required: true })}
              <ValidatedInput
                testId={isPrimary ? 'name-input' : 'name_l10n-input'}
                className={styles.input}
                onChange={friendlyName => {
                  const updates = onChangeUpdates(friendlyName);

                  onPropertyUpdated(updates);
                }}
                value={valueGetter()}
                validation={isPrimary && validateProperty?.fields?.friendlyName}
                placeholder={t(FRIENDLY_PROPERTY_TEXTS.placeholder.name)}
              />
            </fieldset>
          );
        }}
      </MultiLanguageWrapper>
      {hasPlaceholder && (
        <MultiLanguageWrapper
          channel={channel}
          model={property}
          column="placeholder"
        >
          {({ labelMaker, onChangeUpdates, valueGetter, isPrimary }) => {
            const label = (
              <Label
                className={styles.inputLabel}
                mt={0}
                TooltipComponent={
                  isSelectUser
                    ? t(FRIENDLY_PROPERTY_TEXTS.tooltip.placeholderSelectUser)
                    : t(FRIENDLY_PROPERTY_TEXTS.tooltip.placeholder)
                }
              >
                {t(FRIENDLY_PROPERTY_TEXTS.label.placeholder)}
              </Label>
            );

            return (
              <fieldset
                className={styles.field}
                data-test="property-placeholder"
              >
                {labelMaker({ label, required: true })}
                <ValidatedInput
                  testId={
                    isPrimary ? 'placeholder-input' : 'placeholder_l10n-input'
                  }
                  className={styles.input}
                  onChange={placeholder => {
                    const updates = onChangeUpdates(placeholder);

                    onPropertyUpdated(updates);
                  }}
                  value={valueGetter()}
                  validation={
                    isPrimary && (validateProperty.fields as any)?.placeholder
                  }
                  placeholder={t(
                    FRIENDLY_PROPERTY_TEXTS.placeholder.placeholder
                  )}
                  maxLength={isSelectUser ? INPUT_MAX_LENGTH : undefined}
                />
              </fieldset>
            );
          }}
        </MultiLanguageWrapper>
      )}
    </>
  );
}
