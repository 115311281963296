import React, { useRef, useState } from 'react';
import {
  Checkbox,
  Dropdown,
  ErrorMessage,
  IconButton,
  Input,
} from 'components';
import { makeFileDownload } from 'helpers';

import Papa from 'papaparse';

import { getValidationMessages } from 'lane-shared/helpers';
import styles from './styles.scss';
import { useTranslation } from 'react-i18next';

import { Button, Loading, Multiselect, DropdownItem } from 'design-system-web';
import { Flex, PageHeader } from 'components/layout';
import { InputType, InputTypeEnum, AddUserType } from './types';
import { UsersCsvImportButton } from './UsersCsvImportButton';

export function BulkUsers({
  loading,
  channelInput,
  initialColumns,
  inputs,
  title,
  description = '',
  buttonTitle,
  onSubmit,
  onCancel,
  url,
  breadcrumbTitle,
}: {
  loading: boolean;
  channelInput: any;
  initialColumns: AddUserType;
  title: string;
  inputs: InputType[];
  description?: string;
  buttonTitle: string;
  onSubmit: (rows: AddUserType[]) => void;
  onCancel: () => void;
  url: string;
  breadcrumbTitle: string;
}) {
  const myRef = useRef(null);
  const { t } = useTranslation();

  // Channel info
  const channel = channelInput.channel;

  // User rows
  const [rows, setRows] = useState<AddUserType[]>(
    Array(5)
      .fill(1)
      .map(() => initialColumns)
  );

  const pageHeaderProps = {
    header: title,
    breadcrumbs: [
      {
        label: t('web.pages.portal.admin.users.index.header'),
        url,
      },
      {
        label: breadcrumbTitle,
      },
    ],
  };

  const areAllRowsEmpty =
    rows.filter((item: AddUserType) => {
      return item.name || item.email;
    }).length === 0;

  if (!channel)
    return <Loading testId="loadingBulkInvite" style={{ margin: 'auto' }} />;

  function handleOnChange({
    index,
    value,
    key,
  }: {
    index: number;
    value: string | boolean | string[];
    key: string;
  }) {
    setRows(
      rows.map((row: AddUserType, i: number) => {
        if (i === index) {
          return {
            ...row,
            [key]: value,
          };
        }

        return row;
      })
    );
  }

  // Skip empty rows
  const validRows = rows.filter(item => {
    return item.name && item.email;
  });

  const handleExportToCSV = () => {
    const csvTemplateColumns = [
      'Full name',
      'Email',
      'Phone Number',
      'Teams',
      'Marketing Opt-In',
      'User Status',
      'Tenant',
    ];
    const csvData = [];
    const headers = csvTemplateColumns.map(column => column);

    csvData.push(headers);

    const csv = Papa.unparse(csvData);
    const filename = `import-users-template.csv`;

    makeFileDownload({
      name: filename,
      contents: csv,
      type: 'application/csv',
    });
  };

  function getWidth(type: InputTypeEnum): string {
    if (type === InputTypeEnum.Boolean) {
      return '10em';
    }

    if (type === InputTypeEnum.MultiSelect || type === InputTypeEnum.Dropdown) {
      return '20em';
    }

    return '18em';
  }

  return (
    <div className={styles.outerWrapper}>
      <PageHeader
        {...pageHeaderProps}
        headerLevel="h3"
        description={description}
      />
      <Flex direction="column" m={[0, 5]} className={styles.wrapper}>
        <div className={styles.BulkAddUsers}>
          <div className={styles.actionButtons}>
            <div>
              <UsersCsvImportButton
                loading={false}
                setRows={setRows}
                inputs={inputs}
              />
              <Button
                variant="text"
                onClick={() => {
                  handleExportToCSV();
                }}
              >
                {t(
                  'web.admin.channel.teamManagement.team.BulkSendInviteV2.downloadTemplate.title'
                )}
              </Button>
            </div>
          </div>
          <ul
            ref={myRef}
            style={{
              maxHeight: 480,
              overflow: 'auto',
            }}
            data-test="user-input-list"
          >
            <li>
              {inputs.map(input => {
                const width = getWidth(input.type);

                return (
                  <div
                    key={input.label}
                    className={
                      input.type === InputTypeEnum.Boolean
                        ? styles.tableHeaderCenter
                        : styles.tableHeader
                    }
                    style={{ width }}
                  >
                    {t(input.label)}
                  </div>
                );
              })}
            </li>
            {rows.map((row: AddUserType, i: number) => (
              <li
                key={`row-${i}`}
                data-test="user-input-list-row"
                className={styles.inputRow}
              >
                <ErrorMessage error={row.error} />
                {inputs.map(({ key, label, placeholder, type, items }) => {
                  let component = <></>;
                  const width = getWidth(type);

                  if (type === InputTypeEnum.String) {
                    component = (
                      <Input
                        key={label}
                        className={styles.inputStyles}
                        error={getValidationMessages(row.validation, key)}
                        value={row[key as keyof AddUserType] as string}
                        placeholder={t(placeholder)}
                        disabled={row.wasSent}
                        onChange={value => {
                          handleOnChange({
                            index: i,
                            value: value as string,
                            key,
                          });
                        }}
                      />
                    );
                  }

                  if (type === InputTypeEnum.Boolean) {
                    component = (
                      <Checkbox<boolean>
                        key={label}
                        className={styles.inputCheckbox}
                        value={row[key as keyof AddUserType] as boolean}
                        selected={row[key as keyof AddUserType] as boolean}
                        onChange={value => {
                          handleOnChange({
                            index: i,
                            value: !value as boolean,
                            key,
                          });
                        }}
                      />
                    );
                  }

                  if (type === InputTypeEnum.Dropdown) {
                    component = (
                      <Dropdown
                        key={label}
                        className={styles.inputStyles}
                        placeholder={placeholder}
                        doTranslation
                        items={items}
                        value={row[key as keyof AddUserType] as string}
                        onValueChange={value => {
                          handleOnChange({
                            index: i,
                            value: value as string,
                            key,
                          });
                        }}
                      />
                    );
                  }

                  if (type === InputTypeEnum.MultiSelect) {
                    component = (
                      <Multiselect<DropdownItem<string>[]>
                        key={label}
                        className={styles.inputStyles}
                        items={items}
                        truncateSelectedItems
                        placeholder={placeholder}
                        value={
                          row[
                            key as keyof AddUserType
                          ] as DropdownItem<string>[]
                        }
                        onChange={value => {
                          handleOnChange({
                            index: i,
                            value,
                            key,
                          });
                        }}
                      />
                    );
                  }

                  return (
                    <div className={styles.inputContainer} style={{ width }}>
                      {component}
                    </div>
                  );
                })}
                <IconButton
                  icon="trash-alt"
                  data-test="removeRow"
                  className={styles.removeButton}
                  onClick={() =>
                    setRows(
                      rows.filter(
                        (row: AddUserType, index: number) => index !== i
                      )
                    )
                  }
                />
              </li>
            ))}
          </ul>
        </div>
        <hr />
        <div className={styles.actionButtonsBottom}>
          <Button
            testId="addUsers"
            variant="primary"
            size="large"
            className={styles.button}
            onClick={() => {
              onSubmit(validRows);
            }}
            loading={loading}
            disabled={areAllRowsEmpty}
          >
            {t(buttonTitle, { count: validRows.length })}
          </Button>

          <Button
            className={styles.button}
            variant="tertiary"
            size="large"
            onClick={onCancel}
          >
            {t(
              'web.admin.channel.teamManagement.team.BulkSendInviteV2.clearInvitesButtonText'
            )}
          </Button>
        </div>
      </Flex>
    </div>
  );
}
