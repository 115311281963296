import React, { useEffect, useState } from 'react';

import { MultiselectField } from 'components';

import { AccessGroupSelectItem } from '../../../types/AccessControl';

type props = {
  isLoading?: boolean;
  items: AccessGroupSelectItem[];
  onChange: (selected: AccessGroupSelectItem[]) => void;
  values: AccessGroupSelectItem[];
  isFullWidth?: boolean;
  placeholder?: string;
};

const orderItems = (items: AccessGroupSelectItem[]) => {
  return items
    .filter(item => item.isDisabled)
    .concat(items.filter(item => !item.isDisabled));
};

export function UserAccessMultiselect({
  isLoading,
  items,
  values,
  onChange,
  isFullWidth,
  placeholder,
}: props) {
  const [selected, setSelected] = useState<AccessGroupSelectItem[]>(
    orderItems(values)
  );

  useEffect(() => {
    setSelected(orderItems(values));
  }, [values]);

  const handleOnChange = (values: AccessGroupSelectItem[]) => {
    const disabledItems = items.filter(item => item.isDisabled);
    const selectedDisabledItems = selected.filter(value =>
      disabledItems.some(
        item =>
          item.value === value.value &&
          item.provider === value.provider &&
          item.channelId === value.channelId
      )
    );

    if (values.length === 0 && selectedDisabledItems.length > 0) {
      setSelected(selectedDisabledItems);
    } else {
      setSelected(orderItems(values));
    }

    onChange(values);
  };

  return (
    <MultiselectField
      isLoading={isLoading}
      items={orderItems(items)}
      onChange={handleOnChange}
      doTranslation={false}
      value={selected}
      isClearable={selected.some(select => !select.isDisabled)}
      isFullWidth={isFullWidth}
      placeholder={placeholder}
    />
  );
}
