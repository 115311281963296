import { trimStart } from 'lodash';
import { ValidationError } from 'yup';

import getValidationForPath from './getValidationForPath';

/**
 * getAggregatedValidationMessage gets all the errors for a path.subPath and aggregates them into a single string.
 * i.e: a list of errors:
 * - Name is required
 * - Name must have atleast 2 characters
 * - Name must contain only alphanumeric characters
 *
 * is aggregated to: Name is required, must have atleast 2 characters and must contain only alphanumeric characters
 * @param validation validation error from yup
 * @param path path of the field that was validated. ie. content.liveDate
 * @param subPath sub paths for deeply nested controls. ie. path = 'notifications[1]', subPath = 'title'
 * @returns {string | null} a formated string containing all the error messages for a path.subPath
 */
export const getAggregatedValidationMessage = (
  validation: ValidationError | ValidationError[] | null | undefined,
  path: string,
  subPath: string = ''
): string | null => {
  const errs = getValidationForPath(validation, path, subPath);

  if (!errs?.length) {
    return null;
  }

  const err = errs[0]!;
  const params = err.params ? (err.params as any) : {};

  const label = params.label || params.path;

  const errMessages = errs.map((error, index) => {
    // keep first message as is. The label is needed to start the aggregated error sentence.
    if (index === 0) return error.message;

    let message = error.message;

    if (label) {
      // remove label from message
      message = trimStart(message.replace(label, ''));
    }

    if (index === errs.length - 1) {
      message = `and ${message}`;
    }

    return message;
  });

  const separator = errMessages.length > 2 ? ', ' : ' ';

  return errMessages.join(separator);
};
