import gql from 'graphql-tag';

export default gql`
  mutation updateServiceRequestStatusByIdMutation(
    $id: String!
    $status: String!
  ) {
    updateServiceRequestStatusById(id: $id, status: $status)
  }
`;
