import { useQuery } from '@apollo/client';
import { getContentsOnChannels as optimizedContentsOnChannelsQuery } from '../graphql/query/getContentsOnChannels';
import { getContentsOnChannelsQuery } from '../graphql/query';
import { useMultiLanguage } from './useMultiLanguage';
import { useOptimizedContentsOnChannelsQuery } from './useOptimizedContentsOnChannelsQuery';

export const useMultiChannelContent = ({
  channelIds,
}: {
  channelIds: Array<string>;
}) => {
  const isOptimizedContentsOnChannelsEnabled = useOptimizedContentsOnChannelsQuery();
  const query = isOptimizedContentsOnChannelsEnabled
    ? optimizedContentsOnChannelsQuery
    : getContentsOnChannelsQuery;

  const { data } = useQuery(query, {
    variables: { channelIds },
  });

  const { translate } = useMultiLanguage();

  if (data?.me?.contentsOnChannels) {
    return translate({ model: [...data.me.contentsOnChannels] });
  }

  return [];
};
