import React, { useMemo, useState, useCallback, CSSProperties } from 'react';

import cx from 'classnames';
import { cloneDeep, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  PropertiesInterface,
  PropertyType,
} from 'lane-shared/types/properties/Property';
import { PropertiesInterfaceDependencies } from 'lane-shared/types/properties/propertyInterfaceOptions/propertiesInterfaceDependencies';
import { PropertyDependency } from 'lane-shared/types/properties/propertyInterfaceOptions/propertyDependency';

import Checkbox from '../../../form/Checkbox';
import Button from '../../../general/Button';
import ControlMenu from '../../../general/ControlMenu';
import ModalBackground from '../../../general/ModalBackground';
import ResizableWindow from '../../../general/ResizableWindow';
import FieldEdit from './FieldEdit';
import FriendlyPropertyCreate from './FriendlyPropertyCreate';

import styles from './FieldEditWindow.scss';

export type Props = {
  content?: any;
  channel: any;
  user: any;
  contexts: any;
  definition?: any;
  forCreate?: boolean;
  forSimple?: boolean;
  showSecurity?: boolean;
  showValidation?: boolean;
  field: PropertyType;
  className?: string;
  style?: CSSProperties;
  onSave: (
    field: PropertyType,
    propertyDependency?: PropertyDependency
  ) => void;
  onDelete: (field: PropertyType) => void;
  onCancel: any;
  properties?: PropertiesInterface;
  propertyDependency?: PropertyDependency;
  propertyDependencies?: PropertiesInterfaceDependencies;
  onContentUpdated?: (content: object) => void;
};

export default function FieldEditWindow({
  content,
  channel,
  user,
  contexts,
  definition = [],
  forCreate = false,
  forSimple = false,
  showSecurity = true,
  showValidation = true,
  field,
  className,
  style,
  onSave,
  onDelete,
  onCancel,
  properties,
  propertyDependency,
  propertyDependencies,
  onContentUpdated = () => {},
}: Props) {
  const [editingPropertyDependency, setEditingPropertyDependency] = useState(
    propertyDependency
  );
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);
  const [_field, setField] = useState<PropertyType>(field);
  const [_oldField] = useState<PropertyType>(cloneDeep(field));
  const [_oldContent] = useState(cloneDeep(content));
  const [originalPropertyDependencySerialized] = useState(
    cloneDeep(propertyDependency?.serialize())
  );
  const [isAdvancedMode, setIsAdvancedMode] = useState<boolean>(false);
  const changed = useMemo(() => {
    return (
      !isEqual(_field, _oldField) ||
      !isEqual(
        editingPropertyDependency?.serialize(),
        originalPropertyDependencySerialized
      ) ||
      !isEqual(_oldContent, content)
    );
  }, [_field, _oldField, editingPropertyDependency, content]);

  async function confirmCancel() {
    try {
      await window.Alert.confirm({
        title: `Discard unsaved changes?`,
        message: `Changes you've made so far will not be saved.`,
        confirmText: 'Discard',
      });
    } catch (err) {
      // user cancelled
      return;
    }

    onSave(
      _oldField,
      originalPropertyDependencySerialized
        ? PropertyDependency.fromJsonData(
            properties!,
            originalPropertyDependencySerialized
          )
        : undefined
    );
    onContentUpdated(_oldContent);
    onCancel();
  }

  function updateField(props: Partial<PropertyType>, resetField: boolean) {
    const field = resetField ? props : { ..._field, ...props };

    setField(field as PropertyType);
  }

  function updatePropertyDependency(
    updatedPropertyDependency: PropertyDependency
  ) {
    const newPropertyDependency = new PropertyDependency(
      updatedPropertyDependency.propertyRef,
      updatedPropertyDependency.rules
    );

    if (propertyDependencies) {
      const clonePropertyDependencies = PropertiesInterfaceDependencies.fromJsonData(
        propertyDependencies.propertiesInterface,
        propertyDependencies.serialize()
      );

      clonePropertyDependencies.addDependency(
        newPropertyDependency.propertyRef,
        newPropertyDependency
      );
      clonePropertyDependencies.validate();
    }

    setEditingPropertyDependency(newPropertyDependency);
  }

  const onClose = useCallback(() => {
    if (changed) {
      confirmCancel();
    } else {
      onCancel();
    }
  }, [changed]);

  return (
    <ModalBackground onClose={onClose} isOpen className={styles.modal}>
      <ResizableWindow
        showHeader
        onClose={onCancel}
        name="FieldEditWindow"
        defaultPosition={ResizableWindow.mostlyFullScreen()}
        className={cx(styles.window, className)}
        style={style}
      >
        <div className={styles.wrapper}>
          {isAdvancedMode && (
            <FieldEdit
              channel={channel}
              user={user}
              contexts={contexts}
              field={field}
              definition={definition}
              forCreate={forCreate}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ channel: any; user: any; contexts: any; fi... Remove this comment to see the full error message
              forSimple={forSimple}
              showSecurity={showSecurity}
              // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
              onFieldUpdated={field => updateField(field)}
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
              onValidation={errors => setErrors(errors)}
            />
          )}

          {!isAdvancedMode && (
            <FriendlyPropertyCreate
              user={user}
              contexts={contexts}
              property={_field}
              definition={definition}
              showSecurity={showSecurity}
              showValidation={showValidation}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '(props: Partial<PropertyType<PropertyOptionT... Remove this comment to see the full error message
              onPropertyUpdated={updateField}
              onValidation={(errors: any) => setErrors(errors)}
              properties={properties}
              propertyDependency={propertyDependency}
              propertyDependencies={propertyDependencies}
              onPropertyDependencyUpdated={updatePropertyDependency}
              content={content}
              onContentUpdated={onContentUpdated}
            />
          )}
        </div>

        <ControlMenu className={styles.controlMenu} mb={0}>
          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ className: ... Remove this comment to see the full error message */}
          <Checkbox
            className={styles.advancedMode}
            selected={isAdvancedMode}
            onChange={() => setIsAdvancedMode(!isAdvancedMode)}
            text={t(
              'web.admin.content.metatag.tabItem.edit.fieldEditWindow.useAdvancedMode'
            )}
          />
          <Button
            testId="button-cancel"
            onClick={() => {
              onSave(
                _oldField,
                originalPropertyDependencySerialized
                  ? PropertyDependency.fromJsonData(
                      properties!,
                      originalPropertyDependencySerialized
                    )
                  : undefined
              );
              onContentUpdated(_oldContent);
              onCancel();
            }}
          >
            <label>
              {t(
                'web.admin.content.metatag.tabItem.edit.fieldEditWindow.cancel'
              )}
            </label>
          </Button>

          {!forCreate && (
            <Button onClick={() => onDelete(_field)} testId="button-delete">
              <label>
                {t(
                  'web.admin.content.metatag.tabItem.edit.fieldEditWindow.delete'
                )}
              </label>
            </Button>
          )}

          <Button
            disabled={!changed || errors.length > 0}
            onClick={() => onSave(_field, editingPropertyDependency)}
            variant="contained"
            testId="button-create-or-save"
          >
            <label>
              {forCreate
                ? t(
                    'web.admin.content.metatag.tabItem.edit.fieldEditWindow.create'
                  )
                : t(
                    'web.admin.content.metatag.tabItem.edit.fieldEditWindow.save'
                  )}
            </label>
          </Button>
        </ControlMenu>
      </ResizableWindow>
    </ModalBackground>
  );
}
