import { useMemo } from 'react';

import { LaneType } from 'common-types';
import { useSortFn } from 'lane-shared/hooks';
import useSectionContentQuery from 'lane-shared/hooks/integrations/shopify/useSectionContentQuery';
import { SortByEnum } from 'lane-shared/types/blocks/Sort';
import { PresentContentFilterTimeEnum } from 'lane-shared/types/filters/PresentContentFilterTimeEnum';
import { PresetContentFilter } from 'lane-shared/types/filters/PresetContentFilter';
import { SortDirectionEnum } from 'lane-shared/types/graphql/search';

type Props = {
  channelId: string | undefined;
  sectionId: string | undefined;
  dateRange: LaneType.DateTimeRange;
};

function useSortedSectionContent({ channelId, sectionId, dateRange }: Props) {
  const searchOptions = {
    channelId,
    areFiltersApplied: true,
    filters: [
      {
        type: PresetContentFilter.FeatureReservableAvailableDays,
        filter: {
          ...dateRange,
          enabled: PresentContentFilterTimeEnum.Enabled,
        },
      },
    ],
  };

  const { sectionContent, loading } = useSectionContentQuery({
    sectionId,
    fetchPolicy: 'no-cache',
    requireChannelId: true,
    searchOptions,
  });

  const { sortFn } = useSortFn({
    sortBy: SortByEnum.EndDate,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'SortDirectionEnum.Asc' is not assignable to ... Remove this comment to see the full error message
    sortDirection: SortDirectionEnum.Asc,
  });

  const sortedSectionContent = useMemo(
    () =>
      sectionContent
        .map(({ content }) => content) // unnest the content to sort properly
        .sort(sortFn),
    [sectionContent]
  );

  return { sortedSectionContent, loading };
}

export default useSortedSectionContent;
