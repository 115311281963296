import { useState } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { getClient } from '../apollo';
import pause from '../helpers/pause';
import { PaymentMethod } from '../types/payment/PaymentAccount';

export default function usePaymentMethods(paymentAccountIds: string[]) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const { data, loading, error, refetch: fetchPaymentMethods } = useQuery(
    gql`
      query getPaymentMethods($paymentAccountIds: [UUID!]!) {
        payments {
          paymentMethods(paymentAccountIds: $paymentAccountIds)
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      skip: !paymentAccountIds || paymentAccountIds.length === 0,
      variables: { paymentAccountIds },
    }
  );

  async function addPaymentMethod(
    paymentAccountId: any,
    source: any
  ): Promise<PaymentMethod> {
    setIsUpdating(true);
    setUpdateError(null);

    let result;

    try {
      await pause();
      result = await getClient().mutate({
        mutation: gql`
          mutation addPaymentMethod(
            $paymentAccountId: UUID!
            $source: String!
          ) {
            payments {
              addPaymentMethod(
                paymentAccountId: $paymentAccountId
                source: $source
              )
            }
          }
        `,
        variables: {
          paymentAccountId,
          source,
        },
      });

      await fetchPaymentMethods();
    } catch (err) {
      setUpdateError(err);
    }

    setIsUpdating(false);

    return (result as any)?.payments?.addPaymentMethod as PaymentMethod;
  }

  async function removePaymentMethod(paymentAccountId: any, id: any) {
    setIsUpdating(true);
    setUpdateError(null);

    try {
      await pause();
      await getClient().mutate({
        mutation: gql`
          mutation removePaymentMethodMutation(
            $paymentAccountId: UUID!
            $id: String!
          ) {
            payments {
              removePaymentMethod(paymentAccountId: $paymentAccountId, id: $id)
            }
          }
        `,
        variables: {
          paymentAccountId,
          id,
        },
      });

      await fetchPaymentMethods();
    } catch (err) {
      setUpdateError(err);
    }

    setIsUpdating(false);
  }

  return {
    paymentMethods: data?.payments?.paymentMethods || [],
    loading: loading || isUpdating,
    error: error || updateError,
    removePaymentMethod,
    addPaymentMethod,
    fetchPaymentMethods,
  };
}
