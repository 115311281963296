/* istanbul ignore file */
import { useEffect, useState } from 'react';

import { LaunchDarklyContextType } from '../contexts/LaunchDarklyContext';
import ILaunchDarklyClient, { LDFlagSet } from '../types/LaunchDarkly';

export default function useLaunchDarklyContext(
  client: ILaunchDarklyClient
): LaunchDarklyContextType {
  const [flags, setFlags] = useState<LDFlagSet>({});

  useEffect(() => {
    function handleReady(flags: LDFlagSet | null) {
      if (flags) {
        setFlags(flags);
      }
    }

    function handleChange(ldFlagSet: LDFlagSet) {
      setFlags(prevFlags => {
        const nextFlags = Object.keys(ldFlagSet).reduce(
          (acc, k) => {
            acc[k] = ldFlagSet[k];

            return acc;
          },
          { ...prevFlags }
        );

        return nextFlags;
      });
    }

    client.on('change', handleChange);
    client.on('ready', handleReady);

    return () => {
      client.off('ready', handleReady);
      client.off('change', handleChange);
    };
  }, [client, setFlags]);

  return { flags, client };
}
