import React, { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { SearchBar } from 'components';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';
import { useDebouncedCallback } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';

import { queryChannelsForBlocksAndTargeting } from 'lane-shared/graphql/channel';
import { toSchema } from 'lane-shared/helpers';
import { shortAddress } from 'lane-shared/helpers/formatters';
import { useStoredState } from 'lane-shared/hooks';
import { ChannelType, ChannelTypeEnum } from 'lane-shared/types/ChannelType';

import Dropdown from 'components/form/Dropdown';
import MultiselectField from 'components/form/MultiselectField';
import ControlMenu from 'components/general/ControlMenu';
import IconButton from 'components/general/IconButton';
import Loading from 'components/general/Loading';
import Pagination from 'components/general/Pagination';
import ChannelSearchCircleListView from 'components/lane/ChannelSearchCircleListView';

import styles from './ChannelSearch.scss';

const DEBOUNCE_THROTTLE = 500;
const PER_PAGE = 25;

/**
 * What kinds of channels, based on heirachy, is the user allowed to search
 * for?
 */
export enum ChannelSearchHierarchiesEnum {
  All = 'All',
  Parent = 'Parent',
  Location = 'Location',
  SubChannel = 'Sub-Channel',
}

const TRANSLATION_KEYS = {
  All: 'web.admin.content.draftContent.target.channelModal.all',
  Parent: 'web.admin.content.draftContent.target.channelModal.parent',
  Location: 'web.admin.content.draftContent.target.channelModal.location',
  'Sub-Channel':
    'web.admin.content.draftContent.target.channelModal.subChannel',
  Property: 'web.admin.content.draftContent.target.channelModal.property',
  Company: 'web.admin.content.draftContent.target.channelModal.company',
  Service: 'web.admin.content.draftContent.target.channelModal.service',
  Restaurant: 'web.admin.content.draftContent.target.channelModal.restaurant',
  Retail: 'web.admin.content.draftContent.target.channelModal.retail',
  Entertainment:
    'web.admin.content.draftContent.target.channelModal.entertainment',
  Organization:
    'web.admin.content.draftContent.target.channelModal.organization',
  Charity: 'web.admin.content.draftContent.target.channelModal.charity',
  Meta: 'web.admin.content.draftContent.target.channelModal.meta',
  Curated: 'web.admin.content.draftContent.target.channelModal.curated',
};

/**
 * What types of channels is the user allowed to search for?
 */

type SearchType = {
  name: string;
  page: number;
  hierarchy: ChannelSearchHierarchiesEnum;
  types: ChannelTypeEnum[];
};

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  hierarchies?: ChannelSearchHierarchiesEnum[];
  types?: ChannelTypeEnum[];
  onChannelSelected?: (channel: ChannelType) => void;
  renderItem?: (channel: ChannelType) => React.ReactNode;
  storageKey?: string;
  disableStorage?: boolean;
  disableTypeSelect?: boolean;
  shouldHighlightOnClick?: boolean;
};

type Props = OwnProps;

export default function ChannelSearch({
  className,
  style,
  onChannelSelected = () => null,
  hierarchies = [
    ChannelSearchHierarchiesEnum.All,
    ChannelSearchHierarchiesEnum.Parent,
    ChannelSearchHierarchiesEnum.Location,
    ChannelSearchHierarchiesEnum.SubChannel,
  ],
  types = [
    ChannelTypeEnum.Property,
    ChannelTypeEnum.Company,
    ChannelTypeEnum.Service,
    ChannelTypeEnum.Restaurant,
    ChannelTypeEnum.Retail,
    ChannelTypeEnum.Entertainment,
    ChannelTypeEnum.Professional,
    ChannelTypeEnum.Charity,
    ChannelTypeEnum.Meta,
    ChannelTypeEnum.Curated,
  ],
  renderItem,
  storageKey = 'ChannelSearch',
  disableStorage = false,
  disableTypeSelect = false,
  shouldHighlightOnClick = false,
}: Props) {
  const { t } = useTranslation();

  const [selectedChannel, setSelectedChannel] = useState<ChannelType>();

  const hierarchyOptions = useMemo(
    () =>
      Object.values(ChannelSearchHierarchiesEnum)
        .map(value => ({
          label: TRANSLATION_KEYS[value],
          value,
        }))
        .filter(obj => hierarchies!.includes(obj.value)),
    [hierarchies!.join()]
  );

  const channelTypeOptions = useMemo(
    () =>
      Object.values(ChannelTypeEnum)
        .map(value => ({
          label: TRANSLATION_KEYS[value],
          value,
        }))
        .filter(obj => types!.includes(obj.value)),
    [types!.join()]
  );

  function getDefaultSearch(): SearchType {
    // only if all types are allowed will we select nothing.
    return {
      name: '',
      page: 0,
      hierarchy: hierarchies![0],
      types:
        types!.length === Object.keys(ChannelTypeEnum).length ? [] : types!,
    };
  }

  const [search, setSearch, isReady] = useStoredState<SearchType>(
    `ChannelSearch${storageKey}`,
    getDefaultSearch(),
    { disableStorage }
  );

  // when types allowed changes, set a new default.
  // when hierarchies allowed changes, set a new default.
  useEffect(() => {
    // updateSearch(getDefaultSearch());
  }, [types!.join(), hierarchies!.join(), isReady]);

  function updateSearch(props: Partial<SearchType>) {
    // if anything is changing other than page, we need to reset page to 0.

    if (!isReady) {
      return;
    }

    if (!Object.keys(props).includes('page')) {
      props.page = 0;
    }

    setSearch(prevState => {
      return {
        ...prevState,
        ...props,
      };
    });
  }

  const [fetchChannels, { data, loading }] = useLazyQuery(
    queryChannelsForBlocksAndTargeting,
    {
      variables: getVariables(),
    }
  );

  function getVariables() {
    const variables = {
      pagination: {
        start: search.page * PER_PAGE,
        perPage: PER_PAGE,
      },
      search: {
        name: { type: 'like', value: search.name },
      },
    };

    if (search.types?.length > 0) {
      (variables.search as any).type = { any: search.types };
    }

    switch (search.hierarchy) {
      case ChannelSearchHierarchiesEnum.All:
        break;
      case ChannelSearchHierarchiesEnum.Parent:
        (variables.search as any).isSub = false;
        (variables.search as any).parent = null;
        break;
      case ChannelSearchHierarchiesEnum.Location:
        (variables.search as any).isSub = false;
        break;
      case ChannelSearchHierarchiesEnum.SubChannel:
        (variables.search as any).isSub = true;
        break;
      default:
        break;
    }

    return variables;
  }

  const debouncedFetch = useDebouncedCallback(() => {
    const variables = getVariables();

    fetchChannels({
      variables,
    });
  }, DEBOUNCE_THROTTLE).callback;

  useEffect(() => {
    let didCancel = false;

    async function getResults() {
      if (didCancel) {
        return;
      }

      debouncedFetch();
    }

    getResults();

    return () => {
      didCancel = true;
    };
  }, [search]);

  const channels = data?.channelsForBlocksAndTargeting?.items || [];
  const pageInfo = data?.channelsForBlocksAndTargeting?.pageInfo || {
    total: 0,
  };

  const getHighlightClassName = (channel: ChannelType) => {
    if (shouldHighlightOnClick && channel._id === selectedChannel?._id) {
      return styles.highlight;
    }

    return '';
  };

  return (
    <div className={cx(styles.ChannelSearch, className)} style={style}>
      <ControlMenu className={styles.menu}>
        <SearchBar
          className={styles.input}
          searchOptions={{ search: search.name }}
          onSearchChange={name => updateSearch({ name })}
        />

        {channelTypeOptions.length > 1 && (
          <MultiselectField
            data-test="dropdownMenu"
            placeholder={t(
              'web.admin.content.draftContent.target.channelModal.selectTypes'
            )}
            className={styles.multiSelect}
            items={channelTypeOptions}
            value={search.types?.map(toSchema)}
            onChange={newTypes =>
              updateSearch({ types: newTypes.map(item => item.value) })
            }
            disabled={disableTypeSelect}
          />
        )}

        {hierarchyOptions.length > 1 && (
          <Dropdown
            className={styles.dropDown}
            onValueChange={hierarchy => updateSearch({ hierarchy })}
            items={hierarchyOptions}
            value={search.hierarchy}
          />
        )}

        <IconButton
          icon="times"
          inverted
          onClick={() => updateSearch(getDefaultSearch())}
        />
      </ControlMenu>

      {loading && <Loading className={styles.loading} />}

      <Pagination
        loading={loading}
        total={pageInfo.total}
        perPage={PER_PAGE}
        page={search.page}
        onPage={page => updateSearch({ page })}
      />

      <ul>
        {channels.map((channel: any) =>
          renderItem ? (
            renderItem(channel)
          ) : (
            <li
              key={channel._id}
              role="button"
              tabIndex={0}
              onKeyPress={e =>
                e.key === Key.Enter && onChannelSelected(channel)
              }
              onClick={() => {
                setSelectedChannel(channel);
                onChannelSelected(channel);
              }}
            >
              <ChannelSearchCircleListView
                channel={channel}
                className={cx(styles.channel, getHighlightClassName(channel))}
                description={shortAddress(channel.address!)}
              />
            </li>
          )
        )}
      </ul>
    </div>
  );
}
