import * as interaction from 'lane-shared/helpers/constants/interactions';
import { DateTime } from 'luxon';
import { useState, useRef, useContext, useMemo } from 'react';
import type FullCalendar from '@fullcalendar/react';
import { getEarliestJoinedCompanyOrEmptyString } from 'lane-web/src/domains/reservableManagement/utilities/getEarliestJoinedCompanyOrEmptyString';
import { reservableBookingColorMapper } from 'lane-web/src/domains/reservableManagement/utilities/reservableBookingColorMapper';
import tokens from '@viewthespace/style-dictionary/assets/json/tokens.json';
import { ContentType, ContentFeature } from 'graphql-query-contracts';
import UserDataContext from 'lane-shared/contexts/UserDataContext';
import { useReservablesOnChannel } from './useReservablesOnChannel';
import { useUnavailableRangesForContents } from './useUnavailableRangesForContents';
import {
  getBusinessHoursFromUnavailables,
  FullCalendarBusinessHour,
} from './helpers/getBusinessHoursFromUnavailables';
import { UserContentInteractionFeaturesType } from 'lane-shared/types/UserContentInteraction';

import { ReservableUnavailabilityRange } from 'lane-shared/hooks/useReservableAvailabilityByRange';
import { useTranslation } from 'react-i18next';
import { getUniqueTimeBookings } from 'lane-web/src/domains/reservableManagement/utilities/getUniqueTimeBookings';

export type FullCalendarResource = {
  id: string;
  title: string;
  type: ContentType;
  businessHours: FullCalendarBusinessHour[];
  extendedProps: {
    unavailableRanges: ReservableUnavailabilityRange[];
  };
  tags: string[];
};

export type FullCalendarEvent = {
  title: string;
  start: Date;
  end: Date;
  resourceId: string;
  backgroundColor: string;
  borderColor: string;
  textColor: string;
  extendedProps: {
    contentName: string;
    interactionId: string | undefined;
    bookingDuration: number;
    adminNote: string | undefined;
    features: UserContentInteractionFeaturesType;
    status: string;
    companyName: string;
    username: string;
    featureDefinition: ContentFeature[];
    timeZone: string;
    count: number;
  };
};

type Props = {
  channelId: string;
  channelTimeZone: string;
};

const TRANSLATION_KEYS = {
  multipleEntriesTitle: 'web.admin.channel.reservableCalendar.multipleEntries',
};

export function useReservableCalendarView({
  channelId,
  channelTimeZone,
}: Props) {
  const [referenceDate, setReferenceDate] = useState(() =>
    DateTime.now().setZone(channelTimeZone).startOf('day').toJSDate()
  );
  const { user } = useContext(UserDataContext);
  const {
    reservablesOnChannel,
    fetchReservablesOnChannel,
    error: reservablesFetchError,
  } = useReservablesOnChannel({
    channelId,
    referenceDate,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const {
    reservablesWithUnavailableRanges,
    error: unavailablesFetchError,
  } = useUnavailableRangesForContents({
    referenceDate,
    reservablesOnChannel,
    user,
  });
  const calendarRef = useRef<FullCalendar | null>(null);
  const resources: FullCalendarResource[] = [];
  const events = useMemo(() => {
    const events: FullCalendarEvent[] = [];
    const userRoleBase62Ids =
      user?.roles?.map(({ groupRole }) => groupRole._id) || [];

    reservablesWithUnavailableRanges.forEach(reservable => {
      const { unavailableRanges } = reservable;

      if (!reservable.features) {
        return;
      }

      const businessHours = getBusinessHoursFromUnavailables({
        features: reservable.features,
        referenceDate,
        userRoleBase62Ids,
        unavailableRanges,
        timeZone: reservable.timeZone ?? channelTimeZone,
      });

      resources.push({
        id: reservable.id,
        title: reservable.name,
        type: reservable.type,
        businessHours,
        extendedProps: {
          unavailableRanges,
        },
        tags: reservable?.tags as string[],
      });

      const uniqueTimeBookings = getUniqueTimeBookings(
        reservable.reservableBookings
      );

      uniqueTimeBookings.forEach(booking => {
        const companyName = getEarliestJoinedCompanyOrEmptyString(
          booking.companies
        );

        const startDate = DateTime.fromISO(booking.startDate);
        const endDate = DateTime.fromISO(booking.endDate);

        const differenceInMinutes: number = Math.abs(
          startDate.diff(endDate, 'minutes').minutes
        );

        const longBooking = differenceInMinutes >= 60;
        const showCompanyName = longBooking && companyName;

        let title = '';

        if (booking.count > 1) {
          title = t(TRANSLATION_KEYS.multipleEntriesTitle, {
            bookingCount: booking.count,
          });
        } else {
          title = showCompanyName
            ? `${booking.username}, ${companyName}`
            : `${booking.username}`;
        }

        let bookingStatus = '';

        if (booking.status.length > 1) {
          bookingStatus =
            new Set(booking.status).size > 1
              ? interaction.INTERACTION_CREATED
              : booking.status[0];
        } else {
          bookingStatus = booking.status[0];
        }

        events.push({
          title,
          start: new Date(booking.startDate),
          end: new Date(booking.endDate),
          resourceId: reservable.id,
          backgroundColor: reservableBookingColorMapper(bookingStatus)
            .background,
          borderColor: reservableBookingColorMapper(bookingStatus).border,
          textColor: tokens.color.text.primary,
          extendedProps: {
            contentName: booking.contentData?.name,
            status: booking.status[0],
            bookingDuration: differenceInMinutes,
            adminNote: booking.adminNote,
            features: booking.features,
            featureDefinition: booking.contentData?.features,
            interactionId: booking.interactionId,
            username: booking.username,
            companyName,
            timeZone: reservable.timeZone ?? channelTimeZone,
            count: booking.count,
          },
        });
      });
    });

    return events;
  }, [reservablesWithUnavailableRanges, channelTimeZone, resources, t]);

  const setCalendarDate = (date: Date) => {
    const calendarApi = calendarRef.current?.getApi();

    calendarApi?.gotoDate(date);
    setReferenceDate(date);
  };

  const refetchReservablesOnChannel = () => {
    fetchReservablesOnChannel();
  };

  return {
    data: {
      referenceDate,
      events,
      resources,
      calendarRef,
      error: reservablesFetchError || unavailablesFetchError,
    },
    handlers: {
      setCalendarDate,
      refetchReservablesOnChannel,
    },
  };
}
