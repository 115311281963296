import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { useMyPublicUsersQuery } from 'lane-shared/hooks';
import { PublicUserType } from 'lane-shared/hooks/useMyPublicUsersQuery';

import Input from 'components/form/Input';
import Button from 'components/general/Button';
import TabStrip, { TabItem } from 'components/general/TabStrip';
import CircleListView from 'components/lane/CircleListView';
import { Flex } from 'components/layout';
import { Modal } from 'components/lds';
import Alert, { AlertType } from 'components/lds/Alert';
import { H5, XS } from 'components/typography';

import Loading from '../general/Loading';

import styles from './SelectUser.scss';

enum SelectUserTabs {
  UsersList = 'UsersList',
  Selected = 'Selected',
}

function generateTabs(selectedAmount: number): TabItem[] {
  return [
    {
      value: SelectUserTabs.UsersList,
      label: 'Users',
    },

    {
      value: SelectUserTabs.Selected,
      label: `Selected (${selectedAmount})`,
    },
  ];
}

type Props = {
  modalTitle: string;
  actionButtonTitle: string;
  updateGuestList: (guests: PublicUserType[]) => void;
  attendees: PublicUserType[];
  maxCapacity: number;
  required?: boolean;
  onClose: () => void;
  maxCapacityAlertTitle?: string;
  maxCapacityToastTitle?: string;
  groupRoles: string[] | null;
  isAllUsersSelected?: boolean;
};

/**
 * Modal for adding guests to the page
 */

const DEFAULT_USER_SIZE_PER_PAGE = 100;

export default function SelectUserModal({
  onClose,
  updateGuestList,
  attendees,
  maxCapacity,
  required,
  modalTitle,
  actionButtonTitle,
  maxCapacityAlertTitle,
  maxCapacityToastTitle,
  groupRoles,
  isAllUsersSelected,
}: Props) {
  const isSingle = maxCapacity === 1;

  const { t } = useTranslation();
  const [guests, setGuests] = useState(attendees);
  const [selectedTab, setSelectedTab] = useState<SelectUserTabs>(
    SelectUserTabs.UsersList
  );

  const attendeesLimitReached = guests.length >= maxCapacity;

  const guestHash = guests.reduce<{ [id: string]: boolean }>((hash, guest) => {
    hash[guest._id] = true;

    return hash;
  }, {});

  const {
    users,
    search,
    updateSearch,
    debouncedSearch,
    loading,
  } = useMyPublicUsersQuery({
    take: DEFAULT_USER_SIZE_PER_PAGE,
    groupIds: groupRoles,
  });

  let filteredUsers = users;

  if (!debouncedSearch && isAllUsersSelected && loading) {
    filteredUsers = [];
  }

  useEffect(() => {
    if (guests.length === 0) {
      setSelectedTab(SelectUserTabs.UsersList);
    }
  }, [guests]);

  function toggleUser(user: PublicUserType) {
    if (isSingle) {
      setGuests([user]);

      return;
    }

    if (guests.some(g => g._id === user._id)) {
      removeGuest(user._id);
    } else {
      selectUser(user);
    }
  }

  function selectUser(user: PublicUserType) {
    if (maxCapacityToastTitle && attendeesLimitReached) {
      window.Toast.show(t(maxCapacityToastTitle, { maxCapacity }));

      return;
    }

    setGuests([...guests, user]);
  }

  function removeGuest(guestId: LaneType.UUID) {
    setGuests(guests.filter(g => g._id !== guestId));
  }

  function renderListOfUsers() {
    if (!debouncedSearch && isAllUsersSelected) return null;

    return (
      <Flex p={1} direction="column" gap={2} className={styles.guestsList}>
        {maxCapacityAlertTitle && attendeesLimitReached && (
          <Alert type={AlertType.warning} fullWidth>
            {t(maxCapacityAlertTitle)}
          </Alert>
        )}
        {loading ? (
          <Loading />
        ) : (
          filteredUsers.map(user => (
            <CircleListView
              key={user._id}
              className={styles.circleListView}
              image={imageUrl(user.profile.image)}
              logo={imageUrl(user.profile.logo)}
              name={getDisplayName(user)}
              onClick={() => toggleUser(user)}
              selected={isSingle ? guests[0]?._id : guestHash[user._id]}
              value={user._id}
              selectType={isSingle ? 'radio' : 'checkbox'}
              isSelectable
            >
              <H5 bold>{getDisplayName(user)}</H5>
              <XS variant="secondary">{user.profile.email}</XS>
            </CircleListView>
          ))
        )}
      </Flex>
    );
  }

  return (
    <Modal
      title={modalTitle}
      isOpen
      onClose={onClose}
      className={styles.modal}
      style={{
        width: '50rem',
        maxWidth: '50rem',
        minWidth: '50rem',
      }}
      actions={
        <Button
          onClick={() => updateGuestList(guests)}
          variant="contained"
          disabled={required && guests.length === 0}
        >
          {actionButtonTitle}
        </Button>
      }
    >
      {!isSingle && (
        <TabStrip
          tabs={generateTabs(guests.length)}
          selected={{ value: selectedTab }}
          onSelectTab={tab => setSelectedTab(tab.value as SelectUserTabs)}
          className={styles.tabStrip}
        />
      )}
      <Flex direction="column" className={styles.userList}>
        {selectedTab === SelectUserTabs.UsersList && (
          <>
            <Input
              value={search}
              onChange={updateSearch}
              placeholder={t('First name, Last name or email')}
              icon="search"
              className={styles.searchInput}
              showClear
            />

            {renderListOfUsers()}
          </>
        )}
        {selectedTab === SelectUserTabs.Selected && (
          <Flex direction="column" gap={2} p={1} className={styles.guestsList}>
            {guests.map(guest => (
              <CircleListView
                key={guest._id}
                className={styles.circleListView}
                image={imageUrl(guest.profile.image)}
                logo={imageUrl(guest.profile.logo)}
                name={getDisplayName(guest)}
                onClick={() => removeGuest(guest._id)}
                selected={Boolean(guest._id)}
                isSelectable
              >
                <H5 bold>{getDisplayName(guest)}</H5>
                <XS variant="secondary">{guest.profile.email}</XS>
              </CircleListView>
            ))}
          </Flex>
        )}
      </Flex>
    </Modal>
  );
}
