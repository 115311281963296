import useFlag from './useFlag';

const FLAG = 'ace.contents-on-channels.perf-improvements';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-11-04T22:03:57+0530.
 */
export function useOptimizedContentsOnChannelsQuery() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}
