import gql from 'graphql-tag';

export const onboardingCompanies = gql`
  query OnboardingCompanies(
    $onboardingChannelFilter: OnboardingCompaniesFilterInput
    $pageInfo: ConnectionPageInfoInput
  ) {
    onboardingCompanies(
      onboardingChannelFilter: $onboardingChannelFilter
      pageInfo: $pageInfo
    ) {
      edges {
        cursor
        node {
          id
          name
          thumbnailUrl
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;
