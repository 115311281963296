import useFlag from './useFlag';

const FLAG = 'ace.user-management.polish-users';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-12-04T17:59:53+0530.
 */
export function useUserManagementPolishUsers() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}
