import React, { useState } from 'react';

import { Button, Dropdown, ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';

import { DataValidationRuleType } from 'lane-shared/types/ContentWorkflowType';
import { Validator } from 'lane-shared/types/Validator';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';

import DataValidationRuleValue from 'components/workflows/dataValidationSchema/dataValidationRuleValue';
import { MaybeDraftDataValidationRuleType } from 'components/workflows/dataValidationSchema/type';

import style from './DataValidationRule.scss';

export type Props = {
  data: PropertiesInterface;
  maybeDraftRule: MaybeDraftDataValidationRuleType;
  onRuleUpdated: (rule: DataValidationRuleType) => void;
  onRuleDeleted: (maybeDraftRule: MaybeDraftDataValidationRuleType) => void;
  isDraft?: boolean;
  editMode?: boolean;
};

export default function DataValidationRule({
  data,
  maybeDraftRule,
  onRuleUpdated,
  onRuleDeleted,
  isDraft = false,
}: Props) {
  const { t } = useTranslation();
  const [draftRule, setDraftRule] = useState(maybeDraftRule);
  const [error, setError] = useState<Error | undefined>(undefined);

  const changed = JSON.stringify(maybeDraftRule) !== JSON.stringify(draftRule);

  function onDataFieldChange(item: any) {
    setDraftRule({
      ...draftRule,
      fieldId: item.value,
    });
    setError(undefined);
  }

  function onRuleValueUpdated(validator: Validator) {
    setDraftRule({
      ...draftRule,
      validator,
    });
    setError(undefined);
  }

  function onSaveRule() {
    if (draftRule.fieldId === undefined || draftRule.validator === undefined) {
      setError(
        new Error(t('web.admin.content.editor.dataValidationRule.invalid'))
      );

      return;
    }

    onRuleUpdated(draftRule as DataValidationRuleType);
    setError(undefined);
  }

  function onDeleteRule() {
    onRuleDeleted(draftRule);
  }

  function renderRuleValue() {
    if (!draftRule.fieldId || !data[draftRule.fieldId]) {
      return null;
    }

    return (
      <DataValidationRuleValue
        maybeDraftRule={draftRule}
        property={data[draftRule.fieldId]}
        onRuleValueUpdated={onRuleValueUpdated}
      />
    );
  }

  function renderDataFieldSelector() {
    const items = Object.keys(data).map(dataFieldId => {
      const dataProperty = data[dataFieldId];

      return {
        label: t('web.admin.content.editor.dataValidationRule.selector.label', {
          name: dataProperty?.friendlyName,
          type: dataProperty?.packagedType || dataProperty?.type,
          id: dataProperty?.name || dataFieldId,
        }),
        value: dataFieldId,
      };
    });

    return (
      <Dropdown
        testId="rule-data-field-dropdown"
        className={style.dataFieldSelector}
        items={items}
        onChange={onDataFieldChange}
        value={draftRule.fieldId}
        doTranslation={false}
        placeholder={t('Select Option')}
      />
    );
  }

  return (
    <div className={style.rule}>
      <div className={style.header}>
        <div className={style.title}>
          {isDraft
            ? t('web.admin.content.editor.dataValidationRule.draftTitle')
            : t('web.admin.content.editor.dataValidationRule.title')}
        </div>
        <div className={style.actions}>
          <Button
            onClick={onSaveRule}
            disabled={!changed}
            testId="button-rule-create-or-update"
          >
            {isDraft ? t('Create') : t('Update')}
          </Button>
          <Button onClick={onDeleteRule} testId="button-rule-delete">
            {t('Delete')}
          </Button>
        </div>
      </div>
      <div className={style.ruleBody} data-test="rule-body">
        <div className={style.row} data-test="rule-data-field-row">
          <div className={style.label}>
            {t('web.admin.content.editor.dataValidationRule.label')}
          </div>
          {renderDataFieldSelector()}
        </div>
        <div className={style.ruleValue} data-test="rule-value">
          {renderRuleValue()}
        </div>
      </div>
      <ErrorMessage error={error} />
    </div>
  );
}
