import {
  HTMLImage,
  MediaImageContentTypeEnum,
  ThumbnailType,
} from 'lane-shared/types/media';

export default function resizeImage({
  image,
  imageType = MediaImageContentTypeEnum.png,
  quality = 1,
  maxWidth,
  maxHeight,
  background,
}: {
  image: HTMLImage;
  imageType: 'image/png' | 'image/jpeg' | 'image/jpg';
  quality: number;
  maxWidth?: number;
  maxHeight?: number;
  background?: 'transparent';
}): ThumbnailType {
  if (maxWidth && image.width > maxWidth) {
    image.height *= maxWidth / image.width;
    image.width = maxWidth;
  }

  if (maxHeight && image.height > maxHeight) {
    image.width *= maxHeight / image.height;
    image.height = maxHeight;
  }

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;

  canvas.height = image.height;
  canvas.width = image.width;

  if (!background || background === 'transparent') {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  } else if (background) {
    ctx.fillStyle = background;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }

  ctx.drawImage(image, 0, 0, image.width, image.height);

  return {
    uri: canvas.toDataURL(imageType, quality),
    width: canvas.width,
    height: canvas.height,
    type: imageType,
    size: image.size,
    name: image.name,
  };
}
