import { users as usersClient } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client';

/**
 * Validates if the user logged in with the BuildingEngines Prism
 * token has access to the buildingId passed in
 * (The buildingId passed in will be the id in the
 * integration settings)
 */
export async function validateBEPrismBuildingIdAgainstCurrentUser({
  token,
  buildingId,
}: {
  token: string;
  buildingId: string;
}): Promise<boolean> {
  const userResp = await usersClient.currentUser(token);

  if (userResp.ok) {
    const currentUser: usersClient.User = await userResp.data;

    return (currentUser.building_ids || []).includes(buildingId);
  }

  return false;
}
