import isValidJsDate from './isValidJsDate';

const dateNames = [
  '_created',
  '_updated',
  'startDate',
  'liveDate',
  'endDate',
  'unpublishDate',
  'lastReset',
  'nextReset',
  'lastSeen',
  'lastLogin',
  'expires',
  'sendAt',
  'unsubscribed',
  'publishedAt',
  'tokenExpiresAt',
  'ratingOn',
  'viewedOn',
  'detailsOn',
  'hiddenOn',
  'deletedOn',
  'pinnedOn',
  'sharedOn',
  'starredOn',
  'likedOn',
  'subscriptionStartDate',
  'estimatedSubscriptionStartDate',
];

const stripKeys = [
  '_created',
  '_updated',
  '_updatedBy',
  '_createdBy',
  '__typename',
];

export default function castGraphQLObject(
  obj: any,
  forUpdate: boolean = false
): any {
  if (Array.isArray(obj)) {
    return obj.map(value => castGraphQLObject(value, forUpdate));
  }

  if (
    !obj ||
    ['boolean', 'string', 'number', 'function'].includes(typeof obj) ||
    isValidJsDate(obj)
  ) {
    return obj;
  }

  const ret = {};

  Object.entries(obj).forEach(([key, value]: [string, any]) => {
    if (forUpdate && stripKeys.includes(key)) {
      return;
    }

    if (key === '__typename') {
      return;
    }

    // strip nulls because this is a problem for mutations later.
    // graphql returns null for data that is not set, where is should be
    // undefined for our purposes of doing updates.
    if (!forUpdate && value === null) {
      return;
    }

    if (dateNames.includes(key) && value && !isValidJsDate(value)) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ret[key] = new Date(value);

      return;
    }

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ret[key] = castGraphQLObject(value, forUpdate);
  });

  return ret;
}
