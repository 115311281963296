import * as interaction from 'lane-shared/helpers/constants/interactions';
import tokens from '@viewthespace/style-dictionary/assets/json/tokens.json';

export function reservableBookingColorMapper(status: string) {
  const yellowBackground = tokens.color.general.yellow.quaternary;
  const greenBackground = tokens.color.general.green.quaternary;
  const grayBackground = tokens.color.general.gray.quaternary;
  const blueBackground = tokens.color.general.blue.quaternary;
  const redBackground = tokens.color.general.red.quaternary;

  const yellowBorder = tokens.color.general.yellow.tertiary;
  const greenBorder = tokens.color.general.green.tertiary;
  const grayBorder = tokens.color.general.gray.tertiary;
  const blueBorder = tokens.color.general.blue.tertiary;
  const redBorder = tokens.color.general.red.tertiary;

  const colorMap: Record<string, { border: string; background: string }> = {
    [interaction.INTERACTION_CREATED]: {
      border: yellowBorder,
      background: yellowBackground,
    },
    [interaction.INTERACTION_WAITING]: {
      border: yellowBorder,
      background: yellowBackground,
    },
    [interaction.INTERACTION_UPCOMING]: {
      border: greenBorder,
      background: greenBackground,
    },
    [interaction.INTERACTION_APPROVED]: {
      border: blueBorder,
      background: blueBackground,
    },
    [interaction.INTERACTION_CONFIRMED]: {
      border: blueBorder,
      background: blueBackground,
    },
    [interaction.INTERACTION_CANCELLED]: {
      border: redBorder,
      background: redBackground,
    },
    [interaction.INTERACTION_ON_HOLD]: {
      border: redBorder,
      background: redBackground,
    },
    [interaction.INTERACTION_OPEN]: {
      border: redBorder,
      background: redBackground,
    },
    [interaction.INTERACTION_ACCEPTED]: {
      border: grayBorder,
      background: grayBackground,
    },
    [interaction.INTERACTION_ARRIVED]: {
      border: grayBorder,
      background: grayBackground,
    },
    [interaction.INTERACTION_ASSIGNED]: {
      border: grayBorder,
      background: grayBackground,
    },
    [interaction.INTERACTION_NOT_ATTENDING]: {
      border: grayBorder,
      background: grayBackground,
    },
    [interaction.INTERACTION_CLOSED]: {
      border: grayBorder,
      background: grayBackground,
    },
    [interaction.INTERACTION_COMPLETE]: {
      border: grayBorder,
      background: grayBackground,
    },
    [interaction.INTERACTION_DECLINED]: {
      border: grayBorder,
      background: grayBackground,
    },
    [interaction.INTERACTION_FAILED]: {
      border: grayBorder,
      background: grayBackground,
    },
    [interaction.INTERACTION_IN_PROGRESS]: {
      border: grayBorder,
      background: grayBackground,
    },
    [interaction.INTERACTION_NEEDS_MORE_INFO]: {
      border: grayBorder,
      background: grayBackground,
    },
    [interaction.INTERACTION_REJECTED]: {
      border: grayBorder,
      background: grayBackground,
    },
    [interaction.INTERACTION_REOPEN]: {
      border: grayBorder,
      background: grayBackground,
    },
    [interaction.INTERACTION_RESENT]: {
      border: grayBorder,
      background: grayBackground,
    },
    [interaction.INTERACTION_UNASSIGNED]: {
      border: grayBorder,
      background: grayBackground,
    },
  };

  return (
    colorMap[status] || {
      border: grayBorder,
      background: grayBackground,
    }
  );
}
