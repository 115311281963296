import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import definition, {
  GuestInviteGuestType,
} from '../renderers/v5/features/types/GuestInviteGuestType';
import { PublicUserType } from '../types/User';
import useToast from './topLevelComponents/useToast';
import useMyPublicUsersQuery from './useMyPublicUsersQuery';

const emailValidator = yup.string().email().required();
const nameValidator = yup.string().required();
const companyValidator = yup.string().required();

export enum GuestInviteFeatureTabsEnum {
  Device = 'Device',
  Email = 'Email',
  Member = 'Member',
  Selected = 'Selected',
}

type UserDataAddedManually = {
  email: string;
  name: string;
  company: string;
  type: 'custom';
};

type Props = {
  onChange: (value: GuestInviteGuestType[]) => void;
  value: GuestInviteGuestType[] | null;
  maxGuests: number;
  platform: string;
};

export default function useGuestInvite({
  value,
  onChange,
  maxGuests,
  platform,
}: Props) {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [hasChanged, setHasChanged] = useState(false);
  const { users, search, updateSearch } = useMyPublicUsersQuery();
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]); // hack: saves previously searched and selected users

  // guests are actually guestIds
  const guests = (value || []).sort((a, b) => {
    if (a.user?._id && b.user?._id) {
      return 0;
    }

    return a.user?._id ? -1 : 1;
  });

  const usersQueryResultAndSelectedUsers = [...users, ...selectedUsers];
  const invitedGuestsFromUsers = guests.map((guest: any) => {
    const queryResultUser = usersQueryResultAndSelectedUsers.find(
      user => user?._id === guest?.user?._id
    );

    return queryResultUser !== undefined ? queryResultUser : guest;
  });

  const selectedIds = invitedGuestsFromUsers?.map(i => i?._id);

  function removeGuest(guest: GuestInviteGuestType) {
    if (guest.user) {
      const newSelectedUsers = selectedUsers.filter(
        u => u._id !== guest.user?._id
      );

      setSelectedUsers(newSelectedUsers);
    }

    onChange(guests.filter(({ _id }) => guest?._id !== _id));
  }

  function addGuest(
    guestData: Partial<GuestInviteGuestType> | UserDataAddedManually
  ) {
    if (
      (guestData as any).user?._id &&
      guestAlreadySelected((guestData as any).user?._id)
    ) {
      return;
    }

    if (guests.length === maxGuests) {
      if (platform === 'desktop') {
        (window as any).Toast.show(
          t('You may only invite {{maxGuests}} guests.', { maxGuests })
        );
      } else if (platform === 'mobile') {
        showToast({
          title: t('You may only invite {{maxGuests}} guests.', { maxGuests }),
        });
      }

      return;
    }

    if ((guestData as any).user?._id) {
      const selectedUser = users.find(
        (u: any) => u._id === (guestData as any).user?._id
      );

      setSelectedUsers([...selectedUsers, selectedUser]);
    }

    onChange([
      ...guests,
      {
        ...definition.default,
        ...guestData,
      },
    ]);

    setEmail('');
    setName('');
    setCompany('');
  }

  async function addManualGuest() {
    setHasChanged(false);

    try {
      await emailValidator.validate(email);
      await nameValidator.validate(name);
      await companyValidator.validate(company);
    } catch (err) {
      setHasChanged(true);

      return;
    }

    addGuest({ email, name, company, type: 'custom' });

    return true;
  }

  async function handleUserSelect(user: Pick<PublicUserType, '_id'>) {
    if (user?._id && guestAlreadySelected(user._id)) {
      const guestToRemove = guests.find(
        g => g?._id === user?._id || g?.user?._id === user?._id
      );

      if (guestToRemove) removeGuest(guestToRemove);
    } else if (user?._id) {
      addGuest({
        user: {
          _id: user?._id,
        },
      });
    }
  }

  function guestAlreadySelected(id: string): boolean {
    return invitedGuestsFromUsers.some(g => g?._id === id);
  }

  return {
    guests,
    selectedIds,
    invitedGuestsFromUsers,
    users,
    search,
    updateSearch,
    email,
    setEmail,
    emailValidator,
    name,
    setName,
    nameValidator,
    company,
    setCompany,
    companyValidator,
    hasChanged,
    handleUserSelect,
    addGuest,
    addManualGuest,
    removeGuest,
  };
}
