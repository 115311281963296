import {
  PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_ASSIGN,
  PERMISSION_ACCESS_CONTROL,
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
} from 'lane-shared/helpers/constants/permissions';
import { UserGroupRoleType } from 'lane-shared/types/UserGroupRole';
import hasPermission from 'lane-shared/helpers/hasPermission';
import {
  AccessGroupSelectItem,
  AccessControlGroup,
} from 'lane-web/src/domains/accessControl/types/AccessControl';
import {
  Data,
  DataStatus,
} from 'lane-web/src/domains/accessControl/components/user-access/MixedStateList/types';

import { Location } from '../../UserAccessLocationPicker';

export const computeAccessGroupsDiff = (
  selected: Data[],
  initialList: Data[]
) => {
  const assignAccessGroups: Data[] = selected.filter(
    (group, idx) =>
      group.status !== initialList[idx].status &&
      group.status === DataStatus.selected
  );
  const revokeAccessGroups: Data[] = selected.filter(
    (group, idx) =>
      group.status !== initialList[idx].status &&
      group.status === DataStatus.unselected
  );

  return {
    assignAccessGroups,
    revokeAccessGroups,
  };
};

export const flatLocationData = (locations: Location[]) => {
  let allLocations: Location[] = [];

  locations.forEach(location => {
    const childLocations = location?.childLocations || [];

    allLocations = [...allLocations, location, ...childLocations.flat()];
  });

  return allLocations;
};

export const filterAccessGroupsByPermissions = (
  accessGroups: AccessControlGroup[],
  channelId: string,
  userRoles: UserGroupRoleType[],
  userAccessGroupIds: string[]
): AccessGroupSelectItem[] => {
  const hasAccessAdminPermission = hasPermission(
    userRoles,
    [PERMISSION_ACCESS_CONTROL],
    channelId,
    true
  );
  const hasManageCompanyPermission = hasPermission(
    userRoles,
    [PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY],
    channelId
  );

  if (!hasAccessAdminPermission && !hasManageCompanyPermission) {
    return [];
  }

  if (hasAccessAdminPermission) {
    return accessGroups.map((accessGroup: AccessControlGroup) => {
      const canAssign = hasPermission(
        userRoles,
        [PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_ASSIGN],
        channelId,
        true
      );

      return {
        label: accessGroup.name,
        value: accessGroup.id,
        provider: accessGroup.provider,
        channelId: accessGroup.channelId,
        isDisabled: !canAssign,
      };
    });
  }

  const companyManagedAccessGroups = userAccessGroupIds.map(id => {
    const accessGroup = accessGroups.find(group => group.id === id);

    if (!accessGroup) return null;

    return {
      label: accessGroup.name,
      value: accessGroup.id,
      provider: accessGroup.provider,
      channelId: accessGroup.channelId,
      isDisabled: false,
    };
  });

  return companyManagedAccessGroups.filter(
    values => values !== null
  ) as AccessGroupSelectItem[];
};
