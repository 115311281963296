import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import { Button, Toggle } from 'components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { LaneType } from 'common-types';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import getDateTimesFromTimeRange from 'lane-shared/helpers/features/getDateTimesFromTimeRange';
import {
  ReservableFeatureProperties,
  ReservableUiTypesEnum,
} from 'lane-shared/types/features/ReservableFeatureProperties';

import TimeRangeInput from 'components/lane/TimeRangeInput';

import Label from '../../../general/Label';

import styles from './ReservableTabCustomTimeslots.scss';

const TRANSLATION_KEYS = {
  enable: 'web.content.features.reservable.ReservableTabCustomTimeslots.enable',
  addNewSlot:
    'web.content.features.reservable.ReservableTabCustomTimeslots.addNewSlot',
  customTimeSlots:
    'web.content.features.reservable.ReservableTabCustomTimeslots.customTimeSlots',
  add: 'web.content.features.reservable.ReservableTabCustomTimeslots.add',
};

function TimeSlotsEdit({
  slots,
  onChange,
  timeZone,
}: {
  slots: Array<LaneType.TimeRange>;
  onChange: (updatedSlots: Array<LaneType.TimeRange>) => void;
  timeZone: string;
}) {
  const { t } = useTranslation();
  const [draftSlot, setDraftSlot] = useState<{
    startTime?: string;
    endTime?: string;
  }>({});

  function insertSlot(
    newSlot: LaneType.TimeRange,
    dateTimeRangeForNewSlot: {
      start: DateTime;
      end: DateTime;
    }
  ) {
    for (let i = 0; i < slots.length; i++) {
      const dateTimeRangeForSlotI = getDateTimesFromTimeRange({
        referenceDate: DateTime.local(),
        timeRange: slots[i],
        timeZone,
      });

      if (
        dateTimeRangeForSlotI.end.startOf('minute') <=
        dateTimeRangeForNewSlot.start.startOf('minute')
      ) {
        continue;
      }

      if (
        dateTimeRangeForSlotI.start.startOf('minute') >=
        dateTimeRangeForNewSlot.end.startOf('minute')
      ) {
        slots.splice(i, 0, newSlot);
        onChange(slots);

        return;
      }

      throw new Error(
        `Cannot overlap with existing slot ${slots[i]?.startTime} - ${slots[i]?.endTime}`
      );
    }

    slots.push(newSlot);
    onChange(slots);
  }

  function addNewSlot() {
    const { startTime, endTime } = draftSlot;

    if (!startTime || !endTime) {
      window.Toast.show('Start time and end time must be set.');

      return;
    }

    let dateTimeRangeForSlot;

    try {
      dateTimeRangeForSlot = getDateTimesFromTimeRange({
        referenceDate: DateTime.local(),
        timeRange: {
          startTime,
          endTime,
        },
        timeZone,
      });
    } catch (e) {
      window.Toast.show(e.toString());

      return;
    }

    if (dateTimeRangeForSlot.start >= dateTimeRangeForSlot.end) {
      window.Toast.show('Start time must be before end time.');

      return;
    }

    try {
      insertSlot(
        {
          _id: uuid(),
          startTime,
          endTime,
        },
        dateTimeRangeForSlot
      );
    } catch (e) {
      window.Toast.show(e.toString());

      return;
    }

    setDraftSlot({});
  }

  function removeSlot(slotId: string) {
    onChange(slots.filter(slot => slot._id !== slotId));
  }

  return (
    <>
      <div>
        <Label h2>
          <span className={styles.subtitle}>
            {t(TRANSLATION_KEYS.addNewSlot)}
          </span>
        </Label>
        <TimeRangeInput
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ startTime?: string | undefined; endTime?: ... Remove this comment to see the full error message
          value={draftSlot}
          onChange={setDraftSlot}
          timeZone={timeZone}
          className={styles.timePicker}
        />
        <Button className={styles.addSlotButton} onClick={addNewSlot}>
          {t(TRANSLATION_KEYS.add)}
        </Button>
      </div>
      <div>
        <Label h2>
          <span className={styles.subtitle}>
            {t(TRANSLATION_KEYS.customTimeSlots)}
          </span>
        </Label>
        {slots.map(slot => (
          <div key={slot._id} className={styles.timeContainer}>
            <TimeSlotEdit key={slot._id} slot={slot} />
            <Icon
              className={styles.deleteSlotIcon}
              name="trash-alt"
              onClick={() => removeSlot(slot._id)}
              set={ICON_SET_FONTAWESOME}
              type="far"
            />
          </div>
        ))}
      </div>
    </>
  );
}

function TimeSlotEdit({ slot }: { slot: LaneType.TimeRange }) {
  return (
    <>
      {slot?.startTime.toString()} - {slot?.endTime.toString()}
    </>
  );
}

export default function ReservableTabCustomTimeslots({
  settings,
  onFeatureUpdated,
  timeZone,
}: {
  settings: ReservableFeatureProperties;
  onFeatureUpdated: (
    featureUpdate: Partial<ReservableFeatureProperties>
  ) => void;
  timeZone: string;
}) {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <Label h2>
          <span className={styles.subtitle}>{t(TRANSLATION_KEYS.enable)}</span>
        </Label>
        <Toggle
          value={settings.useCustomTimeSlots || false}
          onChange={value => {
            onFeatureUpdated({
              useCustomTimeSlots: value,
              customTimeSlots: settings.customTimeSlots || [],
              uiType: ReservableUiTypesEnum.TimeSlots,
            });
          }}
        />
      </div>
      {Boolean(settings.useCustomTimeSlots) && (
        <TimeSlotsEdit
          slots={settings.customTimeSlots || []}
          onChange={updatedSlots => {
            onFeatureUpdated({
              customTimeSlots: updatedSlots,
            });
          }}
          timeZone={timeZone}
        />
      )}
    </>
  );
}
