import * as interaction from 'lane-shared/helpers/constants/interactions';

import { ChipStyle } from 'components/ads';

export function reservableStatusColorMapper(status: string) {
  const statusColorMap: Record<string, ChipStyle> = {
    [interaction.INTERACTION_CREATED]: ChipStyle.Yellow,
    [interaction.INTERACTION_WAITING]: ChipStyle.Yellow,
    [interaction.INTERACTION_UPCOMING]: ChipStyle.Green,
    [interaction.INTERACTION_APPROVED]: ChipStyle.Blue,
    [interaction.INTERACTION_CONFIRMED]: ChipStyle.Blue,
    [interaction.INTERACTION_CANCELLED]: ChipStyle.Red,
    [interaction.INTERACTION_ON_HOLD]: ChipStyle.Red,
    [interaction.INTERACTION_OPEN]: ChipStyle.Red,
    [interaction.INTERACTION_ACCEPTED]: ChipStyle.Grey,
    [interaction.INTERACTION_ARRIVED]: ChipStyle.Grey,
    [interaction.INTERACTION_ASSIGNED]: ChipStyle.Grey,
    [interaction.INTERACTION_NOT_ATTENDING]: ChipStyle.Grey,
    [interaction.INTERACTION_CLOSED]: ChipStyle.Grey,
    [interaction.INTERACTION_COMPLETE]: ChipStyle.Grey,
    [interaction.INTERACTION_DECLINED]: ChipStyle.Grey,
    [interaction.INTERACTION_FAILED]: ChipStyle.Grey,
    [interaction.INTERACTION_IN_PROGRESS]: ChipStyle.Grey,
    [interaction.INTERACTION_NEEDS_MORE_INFO]: ChipStyle.Grey,
    [interaction.INTERACTION_REJECTED]: ChipStyle.Grey,
    [interaction.INTERACTION_REOPEN]: ChipStyle.Grey,
    [interaction.INTERACTION_RESENT]: ChipStyle.Grey,
    [interaction.INTERACTION_UNASSIGNED]: ChipStyle.Grey,
  };

  return statusColorMap[status] || ChipStyle.Grey;
}
