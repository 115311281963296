import type { ChannelHierarchyReturnType } from '../user/buildUserChannelHierarchy';

function getIdsFromChannels(channels: { _id: string }[]) {
  return channels.map(channel => channel._id);
}

export function getChildChannelIdsFromHierarchy(
  channelHierarchy: ChannelHierarchyReturnType[]
) {
  return channelHierarchy.flatMap((parent: ChannelHierarchyReturnType) => {
    if (!parent.subs.length && !parent.channelLocations.length) return [];

    const subsIds = getIdsFromChannels(parent.subs);
    const channelLocationsIds = getIdsFromChannels(parent.channelLocations);

    return [...subsIds, ...channelLocationsIds];
  });
}
