import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import { extractChannelLanguagesArray } from 'lane-shared/helpers/dynamicLanguages';
import { useMultiLanguage } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { SupportedLocaleEnum } from 'localization';

import { Well } from 'components/general';

import styles from './MultiLanguageWrapper.scss';

type MultiLanguageWrapperProps = {
  children: (
    multiTranslationElements: MultiTranslationElements
  ) => JSX.Element | null;
  channel: ChannelType | null;
  model: any;
  column: string;
  className?: string;
  primarySectionClassName?: string;
  localizationSectionClassName?: string;
  isHidden?: boolean;
};

type MultiTranslationElements = {
  labelMaker: ({
    label,
    required,
    requiredSpanClassName,
    whitespaceClassName,
    primaryLabelClassName,
    localizationLabelClassName,
  }: {
    label: string | JSX.Element;
    required?: boolean;
    requiredSpanClassName?: string;
    whitespaceClassName?: string;
    primaryLabelClassName?: string;
    localizationLabelClassName?: string;
  }) => JSX.Element;
  stringLabelMaker: (label: string) => string;
  valueGetter: () => any;
  onChangeUpdates: (value: any) => any;
  isPrimary: boolean;
  languageCode: string;
  languageFriendlyName: string;
};

type OnChangeUpdatesType = (
  languageCode: string,
  isPrimary: boolean,
  column: string
) => MultiTranslationElements['onChangeUpdates'];

type ValueGetterType = (
  isPrimary: boolean,
  languageCode: string,
  column: string
) => MultiTranslationElements['valueGetter'];

type LabelMakerType = (
  isPrimary: boolean,
  friendlyLangName: string,
  shouldSeeMultiLanguage: boolean
) => MultiTranslationElements['labelMaker'];

type StringLabelMakerType = (
  isPrimary: boolean,
  friendlyLangName: string,
  shouldSeeMultiLanguage: boolean
) => MultiTranslationElements['stringLabelMaker'];

/**
 * Creates additional components from the original component to allow an admin to provide translations.
 * If no channelLanguages are set on the channel settings, only a primary language component is returned
 */

export const MultiLanguageWrapper = ({
  children,
  channel,
  model,
  column,
  className,
  primarySectionClassName,
  localizationSectionClassName,
  isHidden = false,
}: MultiLanguageWrapperProps) => {
  const { t } = useTranslation();
  const { translate } = useMultiLanguage();

  if (!model || !column) return null;

  const channelLanguagesArr = extractChannelLanguagesArray(channel);

  const valueGetter: ValueGetterType = (
    isPrimary,
    languageCode,
    column
  ) => () => {
    const unlocalizedModel = translate({
      model: { ...model, channel },
      columns: [column],
      undoTranslation: true,
    });

    return isPrimary
      ? get(unlocalizedModel, column)
      : get(unlocalizedModel, `${column}_l10n.${languageCode}`);
  };

  const onChangeUpdates: OnChangeUpdatesType = (
    languageCode,
    isPrimary,
    column
  ) => value => {
    const column_l10n = `${column}_l10n`;
    const modelsColumn_l10nValue = get(model, column_l10n) || {};
    // in case the passed in column is "a.b.columnName", we need the "columnName"
    const actualColumnName = column.split('.').pop() || '';
    const actualColumn_l10nName = `${actualColumnName}_l10n`;
    const supportedLocalesAsStringArray = Object.values(
      SupportedLocaleEnum
    ) as Array<string>;
    const otherLanguageCodes = Object.keys(modelsColumn_l10nValue).filter(
      langCode => !supportedLocalesAsStringArray.includes(langCode)
    );

    const updatesTo_l10n = {
      [actualColumn_l10nName]: {
        ...modelsColumn_l10nValue,
      },
    };

    // when multi language is not enabled, the _l10n column looks something like
    // { 'en': 'the same text as regular column' }.
    // to make sure we update these values, we have to look through the
    // "otherLanguagesCodes" and update those.
    if (languageCode && supportedLocalesAsStringArray.includes(languageCode)) {
      updatesTo_l10n[actualColumn_l10nName][languageCode] = value;
    } else {
      otherLanguageCodes.forEach(langCode => {
        updatesTo_l10n[actualColumn_l10nName][langCode] = value;
      });
    }

    return isPrimary
      ? {
          [actualColumnName]: value,
          ...updatesTo_l10n,
        }
      : updatesTo_l10n;
  };

  const labelMaker: LabelMakerType = (
    isPrimary,
    friendlyLangName,
    shouldSeeMultiLanguage
  ) => ({
    label,
    required = false,
    requiredSpanClassName,
    whitespaceClassName,
    primaryLabelClassName,
    localizationLabelClassName,
  }) => {
    const requiredSpan = (
      <span className={cx(styles.required, requiredSpanClassName)}>*</span>
    );
    const whitespace = (
      <span className={cx(styles.whitespace, whitespaceClassName)} />
    );

    return isPrimary ? (
      <span className={cx(styles.labelSection, primaryLabelClassName)}>
        {label}
        {shouldSeeMultiLanguage ? ` (${friendlyLangName})` : ''}
        {required ? requiredSpan : ''}
      </span>
    ) : (
      <>
        <span className={cx(styles.labelSection, localizationLabelClassName)}>
          <Icon name="globe" className={styles.iconClass} size="medium" />
          {whitespace}
          {t('web.components.general.MultiLanguageWrapper.translation', {
            value: friendlyLangName,
          })}
          {whitespace}
          {label}
        </span>
        {whitespace}
        <div className={styles.languageText}>
          <label className={styles.fieldLabel}>{friendlyLangName}</label>
        </div>
      </>
    );
  };

  const stringLabelMaker: StringLabelMakerType = (
    isPrimary,
    friendlyLangName,
    shouldSeeMultiLanguage
  ) => label => {
    let primaryLabel = label;

    if (shouldSeeMultiLanguage) {
      primaryLabel += ` (${friendlyLangName})`;
    }

    const localizationLabel = `${t(
      'web.components.general.MultiLanguageWrapper.translation'
    )}${label} (${friendlyLangName})`;

    return isPrimary ? primaryLabel : localizationLabel;
  };

  return (
    <div className={className}>
      {channelLanguagesArr.map(
        (
          { isPrimary, languageCode, friendlyName: friendlyLangName },
          _index,
          array
        ) => {
          const shouldSeeMultiLanguage = array.length > 1;
          const renderedChildren = children({
            onChangeUpdates: onChangeUpdates(languageCode, isPrimary, column),
            labelMaker: labelMaker(
              isPrimary,
              friendlyLangName,
              shouldSeeMultiLanguage
            ),
            stringLabelMaker: stringLabelMaker(
              isPrimary,
              friendlyLangName,
              shouldSeeMultiLanguage
            ),
            valueGetter: valueGetter(isPrimary, languageCode, column),
            isPrimary,
            languageCode,
            languageFriendlyName: friendlyLangName,
          });

          if (isPrimary) {
            return (
              <div className={primarySectionClassName} key={languageCode}>
                {renderedChildren}
              </div>
            );
          }

          if (!isHidden) {
            return (
              <Well
                className={cx(styles.Well, localizationSectionClassName)}
                key={languageCode}
              >
                {renderedChildren}
              </Well>
            );
          }

          return null;
        }
      )}
    </div>
  );
};
