import { useState, useMemo } from 'react';
import {
  FullCalendarResource,
  FullCalendarEvent,
} from './useReservableCalendarView';
import { useTags } from './useTags';

interface Props {
  channelId: string;
  unfilteredResources: FullCalendarResource[];
  unfilteredEvents: FullCalendarEvent[];
}
type MultiFilterType = { label: string; value: string };

export const useReservableCalendarFilters = ({
  channelId,
  unfilteredResources,
  unfilteredEvents,
}: Props) => {
  const [selectedTags, setSelectedTags] = useState<MultiFilterType[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { tags } = useTags({ channelId });

  const { filteredResources, filteredEvents } = useMemo(() => {
    const tagSet = new Set(selectedTags.map(item => item.value));
    const validResourceIds = new Set<string>();

    let filteredResources = [...unfilteredResources];
    let filteredEvents = [...unfilteredEvents];

    if (selectedTags.length !== 0) {
      filteredResources = filteredResources.filter(resource => {
        const valid = filterTagSet(resource, tagSet);

        if (valid) {
          validResourceIds.add(resource.id);
        }

        return valid;
      });
      filteredEvents = filteredEvents.filter(event =>
        validResourceIds.has(event.resourceId)
      );
    }

    if (searchTerm) {
      const filterResults = searchFilterResourcesAndEvents(
        searchTerm,
        filteredEvents,
        filteredResources
      );

      filteredEvents = filterResults.filteredEvents;
      filteredResources = filterResults.filteredResources;
    }

    return { filteredResources, filteredEvents };
  }, [unfilteredResources, unfilteredEvents, selectedTags, searchTerm]);

  return {
    selectedTags,
    setSelectedTags,
    resources: filteredResources,
    events: filteredEvents,
    tags,
    searchTerm,
    setSearchTerm,
  };
};

const filterTagSet = (resource: FullCalendarResource, tagSet: Set<string>) => {
  for (const tag of resource.tags) {
    if (tagSet.has(tag)) {
      return true;
    }
  }

  return false;
};

const searchFilterResourcesAndEvents = (
  searchTerm: string,
  events: FullCalendarEvent[],
  resources: FullCalendarResource[]
) => {
  const addedResourceSet = new Set<string>();

  events.forEach(event => {
    const eventValid = event.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase().trim());

    if (eventValid) {
      addedResourceSet.add(event.resourceId);
    }
  });

  const filteredResources = resources.filter(resource => {
    const lowerCaseSearch = resource.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase().trim());
    const resourceValid = addedResourceSet.has(resource.id) || lowerCaseSearch;

    if (lowerCaseSearch) {
      addedResourceSet.add(resource.id);
    }

    return resourceValid;
  });

  const filteredEvents = events.filter(event => {
    const lowerCaseSearch = searchTerm.toLowerCase().trim();
    const eventValid =
      event.title.toLowerCase().includes(lowerCaseSearch) ||
      addedResourceSet.has(event.resourceId);

    return eventValid;
  });

  return { filteredEvents, filteredResources };
};
