import i18next from 'i18next';
import { DateTime } from 'luxon';

import dateFormatter from 'lane-shared/helpers/formatters/dateFormatter';

import { MEDIUM_DATE_TIME, SHORT_TIME, TIMEZONE } from '../constants/dates';
import parseDateTime from '../dates/parseDateTime';

export default function getFriendlyInteractionDate(
  interaction:
    | {
        startDate: string | Date | DateTime | null;
        endDate: string | Date | DateTime | null;
      }
    | undefined
    | null,
  timeZone?: string,
  translator: (key: string, params?: any) => string = i18next.t,
  locale?: string
): string {
  if (!interaction || !interaction?.startDate || !interaction?.endDate) {
    return '';
  }

  const start = parseDateTime(interaction.startDate, timeZone) as DateTime;
  const end = parseDateTime(interaction.endDate, timeZone) as DateTime;

  const today = DateTime.local();
  const differentTimeZones = start?.offset !== today.offset;
  const timeZoneDisplay = differentTimeZones ? start.toFormat(TIMEZONE) : '';

  const startTime = dateFormatter(start, SHORT_TIME, timeZone, locale);
  const endTime = dateFormatter(end, SHORT_TIME, timeZone, locale);
  const startDateTime = dateFormatter(
    start,
    MEDIUM_DATE_TIME,
    timeZone,
    locale
  );
  const endDateTime = dateFormatter(end, MEDIUM_DATE_TIME, timeZone, locale);

  // if interaction.startDate and interaction.endDate were set to be the same, only return startDateTime string:
  if (startDateTime === endDateTime) {
    // if it is today, show a time
    if (start.hasSame(today, 'day')) {
      const friendlyDate = translator(
        'Today {{startTime}} {{timeZoneDisplay}}',
        {
          startTime,
          timeZoneDisplay,
        }
      );

      return friendlyDate;
    }

    return `${startDateTime} ${timeZoneDisplay}`;
  }

  // if it is today, show a time
  if (start.hasSame(today, 'day') && end.hasSame(today, 'day')) {
    const friendlyDate = translator(
      'Today {{startTime}} to {{endTime}} {{timeZoneDisplay}}',
      {
        startTime,
        endTime,
        timeZoneDisplay,
      }
    );

    return friendlyDate;
  }

  if (start.hasSame(end, 'day')) {
    // else show the long dates
    return `${startDateTime} - ${endTime} ${timeZoneDisplay}`;
  }

  // else show the long dates
  return `${startDateTime} - ${endDateTime} ${timeZoneDisplay}`;
}
