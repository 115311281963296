import * as yup from 'yup';

import { isThisError, Storage } from '.';
import { SingleSignOnIdTokenRejectedError } from 'activate-errors';

export const GOOGLE_ISSUER = 'https://accounts.google.com';

export const DEFAULT_SCOPES = ['openid', 'profile', 'email'];

export const MICROSOFT_TENANT = '9188040d-6c67-4c5b-b112-36a304b66dad';

export type NativeTokenResult = {
  accessToken: string;
  accessTokenExpirationDate: string;
  idToken: string;
  refreshToken: string | null;
  tokenType: string;
};

export enum WebClientAppAuthErrors {
  USER_CANCELLED_LOGIN = 'access_denied: The user has denied access to the scope requested by the client application.',
  UNRECOGNIZED_ERROR = 'Invalid time value',
  FRAME_FAILURE = 'idpiframe_initialization_failed',
  POPUP_CLOSED = 'popup_closed_by_user',
  ACCESS_DENIED = 'access_denied',
  IMMEDIATE_FAILED = 'immediate_failed',
}

export enum ReactNativeAppAuthErrors {
  // User initiated failures
  USER_CANCELLED_FLOW = 'User cancelled flow',
  USER_CANCELLED = 'User cancelled.',
  AUTHENTICATION_FAILED = 'authentication_failed',
  AUTHENTICATION_ERROR = 'authentication_error',
  DENIED_SCOPE = 'The user has denied access to the scope requested by the client application.',
  USER_DENIED_ENTRY = 'The operation couldn’t be completed. (org.openid.appauth.general error -3.)',
}

export const OAUTH_IGNORED_ERRORS: string[] = [
  ...[
    ReactNativeAppAuthErrors.USER_CANCELLED_FLOW,
    ReactNativeAppAuthErrors.USER_CANCELLED,
    ReactNativeAppAuthErrors.AUTHENTICATION_FAILED,
    ReactNativeAppAuthErrors.AUTHENTICATION_ERROR,
    ReactNativeAppAuthErrors.DENIED_SCOPE,
    ReactNativeAppAuthErrors.USER_DENIED_ENTRY,
  ],
  ...[
    WebClientAppAuthErrors.UNRECOGNIZED_ERROR,
    WebClientAppAuthErrors.USER_CANCELLED_LOGIN,
    WebClientAppAuthErrors.FRAME_FAILURE,
    WebClientAppAuthErrors.POPUP_CLOSED,
    WebClientAppAuthErrors.ACCESS_DENIED,
    WebClientAppAuthErrors.IMMEDIATE_FAILED,
  ],
];

export function canHandleError(err: Error | null) {
  if (isThisError && isThisError(err!, SingleSignOnIdTokenRejectedError)) {
    Storage.clear();
  }

  return !OAUTH_IGNORED_ERRORS.includes(err?.message || '');
}

export const oAuthConfigScheme = yup.object().shape({
  issuer: yup.string().trim().required(),
  clientId: yup.string().trim().required(),
  redirectUrl: yup.string().trim().required(),
  scopes: yup.array().of(yup.string().required()).required(),
  provider: yup.string().trim(),
  clientSecret: yup.string().trim().optional(),
  inviteId: yup.string().nullable().trim().optional(),
});

export type OAuthConfigShape = yup.Shape<
  object | undefined,
  {
    issuer: string;
    clientId: string;
    redirectUrl: string;
    scopes: string[];
    provider?: string;
    clientSecret?: string;
  }
>;
