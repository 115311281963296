import { useCallback, useEffect, useMemo, useState } from 'react';

import { DateTime, Duration, Interval } from 'luxon';

import { IntervalRange, TimeOption } from './types';

export type DateRange = {
  startDate: Date;
  endDate: Date;
} | null;

interface Props {
  intervals: IntervalRange[];
  onDateRangeChange: (dateRange: DateRange) => void;
  timeZone?: string;
}

const EMPTY_INTERVAL = {
  startTimes: [] as TimeOption[],
  endTimes: [] as TimeOption[],
};

function getDefaultDateRange(intervals: IntervalRange[]) {
  const earliestCompleteInterval = intervals.find(
    i => !i.startTime.disabled && i.endTimes.some(e => !e.disabled)
  );

  if (!earliestCompleteInterval) {
    return null;
  }

  const endTime = earliestCompleteInterval.endTimes.find(e => !e.disabled)!;

  return {
    startDate: earliestCompleteInterval.startTime.date,
    endDate: endTime.date,
  };
}

function getNewDateRange(
  dateRange: NonNullable<DateRange>,
  intervals: IntervalRange[],
  duration?: Duration
) {
  const targetInterval = intervals.find(
    i =>
      i.startTime.date.getTime() === dateRange.startDate.getTime() &&
      i.endTimes.some(e => !e.disabled)
  );

  if (!!targetInterval?.endTimes || targetInterval) {
    const startDate = targetInterval.startTime.date;

    const baseEndDate = targetInterval.endTimes.find(e => !e.disabled)!.date;

    const desiredEndDateTime = duration
      ? DateTime.fromJSDate(startDate).plus(duration).toJSDate()
      : baseEndDate;

    const endDate =
      targetInterval.endTimes.find(
        e => !e.disabled && e.date.getTime() === desiredEndDateTime.getTime()
      )?.date ?? baseEndDate;

    return {
      startDate,
      endDate,
    };
  }

  return getDefaultDateRange(intervals);
}

function isValid(dateRange: DateRange | null, intervals: IntervalRange[]) {
  if (dateRange === null) {
    return false;
  }

  const { startDate, endDate } = dateRange;

  const currentInterval = intervals
    .filter(
      i =>
        i.startTime.date.getTime() === startDate.getTime() &&
        !i.startTime.disabled
    )
    .filter(i =>
      i.endTimes.some(
        e => e.date.getTime() === endDate.getTime() && !e.disabled
      )
    );

  return currentInterval.length > 0;
}

export function useDateRangeInput({ intervals, onDateRangeChange }: Props) {
  const [dateRange, setDateRange] = useState<DateRange | null>(null);

  const onTimeChange = useCallback(
    (nextDateRange: DateRange) => {
      setDateRange(prevDateRange => {
        if (!nextDateRange) {
          return prevDateRange;
        }

        if (!prevDateRange) {
          const dr = getNewDateRange(nextDateRange, intervals);

          onDateRangeChange(dr);

          return dr;
        }

        const hasChangedStartTime =
          nextDateRange.startDate.getTime() !==
          prevDateRange.startDate.getTime();

        if (hasChangedStartTime) {
          const duration = Interval.fromDateTimes(
            DateTime.fromJSDate(prevDateRange.startDate),
            DateTime.fromJSDate(prevDateRange.endDate)
          ).toDuration();

          const dr = getNewDateRange(nextDateRange, intervals, duration);

          onDateRangeChange(dr);

          return dr;
        }

        onDateRangeChange(nextDateRange);

        return nextDateRange;
      });
    },
    [intervals, onDateRangeChange]
  );

  useEffect(() => {
    if (!isValid(dateRange, intervals)) {
      const defaultDateRange = getDefaultDateRange(intervals);

      onTimeChange(defaultDateRange);
      setDateRange(defaultDateRange);
    }
  }, [dateRange, intervals, onTimeChange]);

  const { startTimes, endTimes } = useMemo(() => {
    if (intervals.length === 0) {
      return EMPTY_INTERVAL;
    }

    const startTimes: TimeOption[] = intervals.map(i => ({
      date: i.startTime.date,
      disabled: i.startTime.disabled,
    }));

    const fallBackInterval = intervals.find(i => !i.startTime.disabled)!;

    const currentInterval = intervals.find(
      i => i.startTime.date.getTime() === dateRange?.startDate.getTime()
    );

    const interval = currentInterval ?? fallBackInterval;

    if (!interval) {
      return EMPTY_INTERVAL;
    }

    const endTimes: TimeOption[] = interval.endTimes.map(e => ({
      date: e.date,
      disabled: e.disabled,
    }));

    return { startTimes, endTimes };
  }, [dateRange, intervals]);

  return {
    dateRange,
    displayableTimes: {
      startTimes,
      endTimes,
    },
    onTimeChange,
  };
}
