import { convertTo62 } from 'lane-shared/helpers/convertId';
import { ContentWorkflowType } from 'lane-shared/types/ContentWorkflowType';
import { WorkflowTargetEnum } from 'lane-shared/types/Workflows';

export default function createWorkflowTargetOptionValue(
  target: WorkflowTargetEnum,
  workflow?: Partial<ContentWorkflowType>
) {
  let value: Partial<ContentWorkflowType> & {
    target: WorkflowTargetEnum;
  } = { target };

  if (target === WorkflowTargetEnum.SelectUser) {
    value = {
      target,
      dataField: {
        _id: convertTo62(workflow!.dataField!._id),
      },
    };
  }

  return value;
}
