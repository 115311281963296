import { DateTime } from 'luxon';

import { TimeAvailabilityFeatureProperties } from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';

import { mapWeeklyAvailabilitiesToOperatingHours } from './mapWeeklyAvailabilitiesToOperatingHours';

export function getWeeklyAvailabilitiesByMembership(
  availabilities: TimeAvailabilityFeatureProperties['availabilities'],
  base62GroupRoleIds: string[]
) {
  return availabilities.filter(wa => {
    if (!wa.groupRole?._id) {
      return false;
    }

    return base62GroupRoleIds.includes(wa.groupRole._id);
  });
}

export function getHoursOfOperationByWeeklyAvailabilities(
  availabilities: TimeAvailabilityFeatureProperties['availabilities'],
  userRoleBase62Ids: string[],
  referenceDateTime: DateTime
) {
  const userWeeklyAvailabilities = getWeeklyAvailabilitiesByMembership(
    availabilities,
    userRoleBase62Ids
  );

  if (userWeeklyAvailabilities.length) {
    return mapWeeklyAvailabilitiesToOperatingHours(
      userWeeklyAvailabilities,
      referenceDateTime
    );
  }

  const baseWeeklyAvailability = availabilities.find(wa => wa.allGroupRoles);

  if (!baseWeeklyAvailability) {
    throw new Error(
      'Bad TimeAvailability configuration, perhaps try republishing.'
    );
  }

  return mapWeeklyAvailabilitiesToOperatingHours(
    [baseWeeklyAvailability],
    referenceDateTime
  );
}
