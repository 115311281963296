import { ResidentTopic, ScimTopic, WorkOrderTopic } from '../kafka/constants';

const config = {
  author: 'JoinLane',
  version: '5.0.69',
  mode: 'development',
  aws_accounts: [
    '825726157430', // development
    '126972385114', // uat
    '988939350691', // staging
    '083514931081', // production
    '686888558054', // legacy Lane AWS account
  ],
  jwt: {
    expiresIn: '180d',
    issuer: 'com.lane.lane',
    algorithm: 'HS512',
  },
  graphql: {
    depthLimit: 10,
    maximumComplexity: 100,
    introspection: 'false',
    playground: 'false',
    csrfPrevention: 'false',
  },
  uploads: {
    maxFileSize: 20000000, // 20MBs
    maxFiles: 20,
  },
  firebase: {
    credential: {
      type: 'service_account',
      project_id: 'autonomous-team-109119',
      private_key_id: 'dde6285a2307b89443f5214b2b5b3e468dd652fd',
      private_key:
        '-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCsZ1YKxoObb1yE\ndd1PwnZOORmkx91VcpzzK4lgrsVp59SGIHQ5m5QOJ0fK5gwv9hXNJLlwdJPsBMzL\nxhmG/lKahWcCRAoYoCh2HMD9ig2dYjT3KA/EMpqh2UobfHqhH7+8CZX58ovZO2oh\nxNF95EZKcpSRiUEAY7M9aBW143Op8633CRgY7Rhhjzv0J5zEVPExMQv1KKUc6IaP\n9r1cm3MMejIWul26/STPoujqO4u6KW+3YUFqAVDlxEhOQc9W0gM4JCgnBtiHIWPz\nUrpQWTM8cKaKeEtvKh4CMdvo+EPP9bnIfmhQrCSYGEHZbsgEGgrm1lf/p3issFAG\n71GJ2gVTAgMBAAECggEAVemW6H69Za9McNvVYeI7l0aAH2vTwbmAOfOHnYzqZpi3\nAPHaSTzcvmLXtWYHS707ZwO2fzIw5owLzD4XSXyiCEuTJf9m8peZIia5C3+IwlWG\npGWr2a8H/MBt3B2fdHyATQGP4cL66tFfVZ2hcbVYS9AmdhHqOPn1/5NHQmEqqdUp\nbgI8H5QdrKQ8iLQYzs/l75191lopAwAuIxxJv/c29nGi/8dZCzLbzVTwiAR3vnn9\nxZnKjtg/VHMKTysSXtyiVYjCp3oWZL+kMV1HfV4ILaBpSFd7T8qTmmh0WhMDE6nD\nBGHZvadkXb/4x+UgHS8vypDKDGkN2MXqJdF2akwsBQKBgQDitt8aQRVwqodLwo1h\nsNYBEE4d61hzCd11zylaWpg9Q054ZPwa+njQlbkbGlToHYXIyqL7bt/cKBVaU2G8\nSpREqIAeEPYP2U1sPaSYXDTNdavqGD/QRCpsgLPk9TwjCOGeLLMpg2fTy7b8xksb\nRjXLNhXGwMSNP4sbF7zDOPAUXwKBgQDCrHxUA+7lFwU7hysrCvY5rceaweGqSpAt\nWa9SkXD61WwBzJiJ1jwacBisLjdTHOLkh0JUomTvHfqRKw+IkekVvuNEzNGj67XW\nDN/Ht6eb7sfJPKghX6LyYAQ2RtmAmrR4buod9yrlAuM5LzvlcceJLRzK6dHxY5eC\n4GivJ3xTjQKBgDqh2r8ad+YEZCsmrJPIPQBXbCx2shBGS9zs75/rcBXf80pVq5F6\nl4YyQoYM4EqLYA8WwOHPwdtZDnSmvc8JZqD/Bus/rbNZmplUs92ncwxz0zsefFpr\nVqFrA5tCBQR2hor2unsQ/egbdj6XNdNe7Aia+zTEo9B3z4z5LZs7fXN3AoGBAIVQ\n/D/JBcQrdLDki2KJuj+by9MmrXUiZQQ5pABGi/mrSLEevOPALB7q32qip86qPJPG\nMV2mbLr4a80YkSW0zsvOkvNd1L+OP1OcM3AhTI5UAsvEV1urk/qqWJgXAwK4b5j+\nVCQ7TUvSI2vY9grWVZCSrWIkqFrIGk1Ng0NYJiNNAoGBAJOx3+chOnHgmEGX9zYg\nywCOf81FvZBNu1+1kzstOarnLchdYrZNkYbYF7ewcJi4qTCOUB8Nb61Rx6LMVBzp\neaie4FxH6/7o/Avg+NkJpyQvD62ZlFtVSdQRemh3RLNgtc9PiHK8OF8GqySGiNgG\nkxbz28Z7dd6VKI4Zdaw4RRMu\n-----END PRIVATE KEY-----\n',
      client_email:
        'firebase-adminsdk-38rhq@autonomous-team-109119.iam.gserviceaccount.com',
      client_id: '117605827787492847276',
      auth_uri: 'https://accounts.google.com/o/oauth2/auth',
      token_uri: 'https://accounts.google.com/o/oauth2/token',
      auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
      client_x509_cert_url:
        'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-38rhq%40autonomous-team-109119.iam.gserviceaccount.com',
    },
    databaseURL: 'https://autonomous-team-109119.firebaseio.com',
  },
  ses: {
    region: 'us-east-1', // only AWS service which runs in this region to make cross-account SES sending work
    rateLimit: 40, // rate limit (40) = global 80 emails/sec, divided by two notification-workers
  },
  kms: {
    retryDelay: 100, // 100 milliseconds
    keyId: process.env.APP_KMS_KEY,
  },
  google: {
    key: 'AIzaSyBvDrPPo-m7bkr-0q4SmSp0ujSvSgBmB5k',
  },
  twilio: {
    accountSid: process.env.TWILIO_ACCOUNT_SID || 'ACchangeme',
    serviceId: process.env.TWILIO_SERVICE_ID || 'changeme',
    authToken: process.env.TWILIO_AUTH_TOKEN || 'changeme',
    messagingServiceSid: process.env.TWILIO_MESSAGING_SERVICE_SID || 'changeme',
  },
  oAuth: {
    linkedIn: {
      client_secret: 'lTx5YgxfQPurKekk',
      client_id: '869mh6mp75sm82',
      redirect_uri: 'https://931b57cd.ngrok.io/api/v5/oauth/__goauth',
      grant_type: 'authorization_code',
    },
  },
  essensys: {
    baseURL: 'https://operate-integration.essensys.tech',
    client_secret: '8bf2d32e-f624-49c5-b6a9-38c2fc413858',
    username: 'tishmanlanetest2',
    password: 'T1shm@nL@n3',
    useCustomEndpoints: true,
    uploadReports: false,
  },
  outlook: {
    loginURL: 'https://login.microsoftonline.com',
    baseURL: 'https://graph.microsoft.com/v1.0',
  },
  looker: {
    vtsLookerUrl: process.env.VTS_LOOKER_URL,
    vtsLookerSecret: process.env.VTS_LOOKER_SECRET,
    defaultModels: ['lane'],
    sessionLength: 30 * 60, // 30 minutes
  },
  proxyclick: {
    baseUrl: 'https://api.proxyclick.com/',
    applicationName: 'Joinlane',
    applicationNameActivate: 'VTS Activate',
  },
  stripe: {
    publicKey:
      'pk_test_51D5IZUK54uNU04sVIfWehFqgJQurn2Fd2beQw4Eh0pHsWXoMNjHei1sGq6JvA4VG1ut9fKhzm6xHODLXldUlTZPz00hXSrHeI0',
    clientId: 'ca_E0yhpjzPqrjtBZOKoUIOtB6BOZ9wYQJp',
  },
  placePay: {
    publicKey: 'test_public_key_-JyB8zkZE3R6XhZJvc-EFyPk',
    privateKey: 'test_private_key_UheQcD2N24l2KI7g3UCOyZy',
  },
  googleRecaptcha: {
    siteKey: '6LdNefwUAAAAAIhh5FDLEMhY5lDAzkei_qKwrz7J',
    secret: '6LdNefwUAAAAAM0PbhGuA2glbnAw-7r8Ec9Ulava',
    scoreThreshold: 0.5,
  },
  saml: {
    identityProvider: {
      issuer: process.env.SAML_ISSUER,
      key: process.env.SAML_IDENTITY_PROVIDER_KEY,
      cert: process.env.SAML_IDENTITY_PROVIDER_CERT,
    },
    datawatch: {
      accessToken: process.env.DATAWATCH_ACCESS_TOKEN,
      userId: process.env.DATAWATCH_USER_ID,
      password: process.env.DATAWATCH_PASSWORD,
    },
  },
  apiKey: {
    secret: process.env.API_KEY_SECRET,
  },
  logLevel: 'warning',
  useCluster: false,
  gsaEventBusHosts: 'localhost:9092',
  kafkaPartitions: 1,
  kafkaReplicationFactor: 1,
  gsaKafkaProducerOptions: {
    retries: 5,
    factor: 2,
    minTimeout: 5 * 1000,
    maxTimeout: 60 * 1000,
  },
  acsIntegrationGroupId: 'lane-integrations-management-consumer',
  visitorManagementGroupId: 'lane-visitor-management-consumer',
  notificationAPIGroupId: 'lane-notification-api-consumer',
  billingPaymentsGroupId: 'lane-billing-payments-consumer',
  workOrderGroupId: 'lane-work-order-consumer',
  residentsGroupId: 'lane-residents-consumer',
  scimGroupId: 'lane-scim-consumer',
  acsKafkaTopics: ['events.integrations.management'],
  gsaEventKafkaTopics: ['events.activate.lane'],
  billingPaymentsKafkaTopics: ['event.billingPayments.accounts'],
  visitorManagementKafkaTopics: ['event.visitors.management'],
  notificationAPIKafkaTopics: ['event.notification.api'],
  workOrderKafkaTopics: [WorkOrderTopic.event],
  residentsKafkaTopics: [ResidentTopic.event],
  scimKafkaTopics: [ScimTopic.event],
  laneEventProducerTopic: 'events.activate.lane',
  kafkaTopics: [
    'integration',
    'event',
    'retry_5m',
    'retry_30m',
    'retry_1h',
    'dataset.record',
  ],
  shopifyMultipass: {
    maxRetry: 6,
  },
  mixpanel: {
    gdprToken: process.env.MIXPANEL_GDPR_TOKEN,
  },
  pendo: {
    apiKey: process.env.PENDO_API_KEY,
  },
  grpc: {
    tenex: {
      host: 'localhost:50051',
      tlsAuthority: process.env.GRPC_GATEWAY_TLS_AUTHORITY,
      tlsCertificate: process.env.GRPC_GATEWAY_TLS_CERTIFICATE,
      tlsKey: process.env.GRPC_GATEWAY_TLS_PRIVATE_KEY,
    },
    gsa: {
      host: 'localhost:50051',
      tlsAuthority: process.env.GRPC_GATEWAY_TLS_AUTHORITY,
      tlsCertificate: process.env.GRPC_GATEWAY_TLS_CERTIFICATE,
      tlsKey: process.env.GRPC_GATEWAY_TLS_PRIVATE_KEY,
    },
  },
  sso: {
    apple: {
      privateKeyWeb: process.env.SSO_APPLE_ACTIVATE_WEB_PRIVATE_KEY,
      privateKeyIdWeb: process.env.SSO_APPLE_ACTIVATE_WEB_PRIVATE_KEY_ID,
    },
  },
  bilt: {
    paymentBaseUrl: 'https://www.biltrewards.com/paywithbilt',
  },
  temporal: {
    address: process.env.TEMPORAL_ADDRESS ?? 'localhost:7233',
    namespace: process.env.TEMPORAL_NAMESPACE ?? 'default',
  },
  cdn: {
    attachments: {
      keyPairId: process.env.CLOUDFRONT_ATTACHMENTS_KEY_ID,
      privateKey: process.env.CLOUDFRONT_ATTACHMENTS_PRIVATE_KEY,
    },
    visitorManagement: {
      keyPairId: process.env.CLOUDFRONT_VISITOR_MANAGEMENT_KEY_ID,
      privateKey: process.env.CLOUDFRONT_VISITOR_MANAGEMENT_PRIVATE_KEY,
    },
  },
  appleWalletPass: {
    teamId: process.env.APPLE_TEAM_ID ?? '',
    wwdr: process.env.APPLE_WWDR_CERT ?? '',
    signerCert: process.env.APPLE_SIGNER_CERT ?? '',
    signerKey: process.env.APPLE_SIGNER_KEY ?? '',
    signerKeyPassphrase: process.env.APPLE_SIGNER_KEY_PASSPHRASE ?? '',
  },
} as const;

export default config;
