import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { VisitorType } from 'lane-shared/domains/visitorManagement/types/VisitorType';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { formatIncompletePhoneNumber } from 'lane-shared/helpers/phoneNumbers';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import { Button, Thumbnail } from 'components/general';
import { H5, S } from 'components/typography';

import styles from './VisitorListItem.scss';

type VisitorListItemProps = {
  visitor: VisitorType;
  properties: VisitorManagementFeatureProperties;
  allowEdit?: boolean;
  onEditSave?: (visitor: VisitorType) => void;
  onDelete?: (visitor: VisitorType) => void;
  receipt?: boolean;
};

const formatPhone = (phoneNumber: string): string => {
  return formatIncompletePhoneNumber(phoneNumber);
};

const generateAdditionalVisitorInfo = (visitor: VisitorType): string => {
  const visitorInfoList: string[] = [];
  const joinPattern = ' | ';

  if (visitor.email) visitorInfoList.push(visitor.email);

  if (visitor.phone) visitorInfoList.push(formatPhone(visitor.phone));

  if (visitor.company) visitorInfoList.push(visitor.company);

  return visitorInfoList.length ? visitorInfoList.join(joinPattern) : '';
};

export function VisitorListItem({
  visitor,
  allowEdit = false,
  onDelete,
  receipt = false,
}: VisitorListItemProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation();

  const visitorName = [visitor?.firstName, visitor?.lastName]
    .filter(value => Boolean(value))
    .join(' ');
  const visitorInfo = generateAdditionalVisitorInfo(visitor);

  const handleOnDelete = () => {
    if (onDelete) {
      onDelete(visitor);
    }
  };

  const renderDeleteButton = () => (
    <div>
      <Button
        testId="deleteButtonTrigger"
        className={styles.button}
        size="small"
        onClick={() => setIsDeleting(true)}
        startIcon={
          <Icon
            name="trash-alt"
            testId="deleteIcon"
            set={ICON_SET_FONTAWESOME}
          />
        }
      >
        {t('Remove')}
      </Button>
    </div>
  );

  const renderDeleteActionButtons = () => (
    <>
      <div>
        <Button
          testId="deleteButton"
          className={styles.button}
          size="small"
          variant="contained"
          onClick={handleOnDelete}
        >
          {t('Remove')}
        </Button>
      </div>
      <div>
        <Button
          testId="cancelDeleteButton"
          className={styles.button}
          size="small"
          onClick={() => setIsDeleting(false)}
        >
          {t('web.admin.hardware.management.hardware.button.cancel')}
        </Button>
      </div>
    </>
  );

  return (
    <div className={cs(styles.visitorListItem, { [styles.receipt]: receipt })}>
      <div className={styles.user}>
        <Thumbnail className={styles.thumbnail} name={visitorName} />
        <div className={styles.textWrapper}>
          <H5>{visitorName}</H5>
          {visitorInfo && <S>{visitorInfo}</S>}
        </div>
      </div>
      {allowEdit && (
        <>
          {!isDeleting && renderDeleteButton()}
          {isDeleting && renderDeleteActionButtons()}
        </>
      )}
    </div>
  );
}
