import { MutableRefObject, useEffect } from 'react';

const rippleAnimation = () => `
@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}`;

export default function useRippleEffect(
  buttonRef: MutableRefObject<HTMLElement | null>,
  containerRef: MutableRefObject<HTMLElement | null>,
  rippleColor: string = 'rgba(15, 18, 31, 0.16)'
) {
  useEffect(() => {
    const style = document.createElement('style');

    style.id = 'rippleButtonAnimation';
    style.innerHTML = rippleAnimation();
    const head = document.querySelector('head');

    if (head) {
      const styleTag = document.getElementById('rippleButtonAnimation');

      if (!styleTag) {
        head.appendChild(style);
      }
    }

    const mousedownListener = (event: any) => {
      const button = event.currentTarget;
      const circle = document.createElement('span');
      const diameter = Math.max(
        button?.clientWidth ?? 0,
        button?.clientHeight ?? 0
      );
      const radius = diameter / 2;

      circle.style.width = `${diameter}px`;
      circle.style.height = `${diameter}px`;
      circle.style.left = `${
        event.clientX - button.offsetLeft - radius - 10
      }px`;
      circle.style.top = `${event.clientY - button.offsetTop - radius - 10}px`;
      circle.style.backgroundColor = rippleColor;
      circle.style.position = 'absolute';
      circle.style.borderRadius = '50%';
      circle.style.transform = 'scale(0)';
      circle.style.animation = 'ripple 600ms linear';
      circle.style.display = 'inline-block';

      circle.classList.add(`ripple-effect-lane`);

      const ripple = button.getElementsByClassName(`ripple-effect-lane`)[0];

      if (ripple) {
        ripple.remove();
      }

      if (containerRef.current) {
        containerRef.current.appendChild(circle);
      } else if (buttonRef.current) {
        buttonRef.current.appendChild(circle);
      }
    };

    if (buttonRef.current && buttonRef.current.addEventListener) {
      buttonRef.current.addEventListener('mousedown', mousedownListener);
    }

    return () => {
      if (buttonRef.current) {
        buttonRef.current.removeEventListener('mousedown', mousedownListener);
      }
    };
  }, []);
}
