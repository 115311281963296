import React from 'react';

import { LaneType } from 'common-types';
import { MjmlSection, MjmlColumn, MjmlWrapper } from '../MjmlComponentsV2';
import EmailLabelWithValue from './EmailLabelWithValue';
import EmailText from './EmailText';

type Props = {
  guests: LaneType.GuestInviteGuestType[];
  i18n: any;
};

export default function EmailGuestInviteReceiptGuests({ guests, i18n }: Props) {
  return (
    <>
      <MjmlSection>
        <MjmlColumn>
          <EmailText>
            <h3>{i18n.t('Guests')}</h3>
          </EmailText>
        </MjmlColumn>
      </MjmlSection>
      {guests.map((guest: any) => (
        <MjmlWrapper key={guest._id}>
          <EmailLabelWithValue key={guest._id} label={i18n.t('Name')}>
            {guest.name}
          </EmailLabelWithValue>
          {guest.company && (
            <EmailLabelWithValue label={i18n.t('Company')}>
                {guest.company}
              </EmailLabelWithValue>
          )}
        </MjmlWrapper>
      ))}
    </>
  );
}
