import { LaneType } from 'common-types';
import { getBatchPublicClient, getClient } from 'lane-shared/apollo';
import createUserContentInteraction from 'lane-shared/graphql/mutation/createUserContentInteraction';
import updateUserContentInteraction from 'lane-shared/graphql/mutation/updateUserContentInteraction';
import { INTERACTION_CANCELLED } from 'lane-shared/helpers/constants/interactions';
import { constructInteraction } from 'lane-shared/helpers/content';
import { ContentType } from 'lane-shared/types/content/Content';

import { IntegrationProviderEnum } from '../../../types/integrations/IntegrationEnums';

export async function createReservableInteraction(
  content: ContentType,
  reservation: LaneType.DateRange
): Promise<LaneType.UserContentInteraction> {
  const newInteraction = constructInteraction(content);
  const response = await getClient().mutate({
    mutation: createUserContentInteraction,
    variables: {
      contentId: content._id,
      interaction: {
        ...newInteraction,
        features: {
          ...newInteraction.features,
          Reservable: {
            reservation,
          },
        },
        state: {
          [`_${IntegrationProviderEnum.ShopifyBurst}`]: {
            _id: undefined,
          },
        },
      },
    },
  });

  return response.data.createContentInteraction;
}

export async function cancelReservableInteraction(
  interaction: LaneType.UserContentInteraction
): Promise<LaneType.UserContentInteraction> {
  await getBatchPublicClient().mutate({
    mutation: updateUserContentInteraction,
    variables: {
      interaction: {
        _id: interaction._id,
        status: INTERACTION_CANCELLED,
      },
    },
  });

  return interaction;
}
