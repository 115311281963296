import React from 'react';

import { longAddress } from '../../helpers/formatters';
import { PublicUserType } from '../../hooks/useMyPublicUsersQuery';
import Types from '../../properties/Types';
import EmailGuestInviteGuest from './EmailGuestInviteGuest';

export default function EmailPropertyValue({
  field,
  value,
  moreInfoLink,
}: any) {
  if ([null, undefined].includes(value)) {
    return null;
  }

  if (field.isArray && Array.isArray(value)) {
    return value.map((value, ix) => (
      <EmailPropertyValue
        key={value?._id || value || ix}
        field={field}
        value={value}
        moreInfoLink={moreInfoLink}
      />
    ));
  }

  const Type = Types.getType(field.type);
  const types = Types.getTypes();
  const baseTypes = Types.getBaseTypes();

  // Types can define their own rendering.
  switch (field.type) {
    case types.GuestInviteGuestType.name:
      return <EmailGuestInviteGuest guest={value} />;
    case types.Address.name:
      return longAddress(value);
    case baseTypes.SelectUser.name:
      return value.users.map((guest: PublicUserType) => guest.name).join(', ');
    case types.Image.name:
      // todo: this can point to an image
      return null;
  }

  // Is there a string formatter for this type? if so use it.
  if (Type.formatter) {
    return Type.formatter(value);
  }

  if (typeof value === 'object') {
    if (!moreInfoLink) {
      const objectValues = Object.values(value);

      return objectValues.join(' ');
    }

    return moreInfoLink || null;
  }

  return value;
}
