import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './ReceiptReservables.scss';

import { M, H5, H4 } from 'components/typography';

import {
  longDateNoTime,
  shortTimeTimeZone,
} from 'lane-shared/helpers/formatters';

type Props = {
  startDate: Date;
  endDate: Date;
  timeZone: string | undefined;
};

export function ReceiptReservables({ startDate, endDate, timeZone }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.reservableDates}>
      <div>
        <H4 mb={5}>
          {t('shared.content.interaction.receipt.reservationDetails')}
        </H4>
      </div>

      <div className={styles.flexRow}>
        <div>
          <H5>
            {t(
              'shared.content.interaction.receipt.reservationDetails.startDate'
            )}
          </H5>
          <M>
            {longDateNoTime(startDate, timeZone)}
            <br />
            {shortTimeTimeZone(startDate, timeZone)}
          </M>
        </div>
        <div>
          <H5>
            {t('shared.content.interaction.receipt.reservationDetails.endDate')}
          </H5>
          <M>
            {longDateNoTime(endDate, timeZone)}
            <br />
            {shortTimeTimeZone(endDate, timeZone)}
          </M>
        </div>
      </div>
    </div>
  );
}
