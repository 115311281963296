import {
  MarkupAmountType,
  TaxAmountType,
  Product,
} from 'lane-shared/domains/billingPayments/types';
import { PriceComponents } from 'lane-shared/domains/productCatalog/types';

export function productToPriceComponents(priceInput: Product): PriceComponents {
  const { amount, markup, tax, netPrice } = priceInput;

  return {
    amount: Number(amount),
    markup: {
      value: Number(markup?.value || 0),
      markupAmountType:
        markup?.markupAmountType || MarkupAmountType.MarkupAmountTypePercentage,
    },
    tax: {
      value: Number(tax?.value || 0),
      taxAmountType:
        tax?.taxAmountType || TaxAmountType.TaxAmountTypePercentage,
    },
    netPrice: Number(netPrice),
  };
}
