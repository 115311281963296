import { useContext } from 'react';

import { AnalyticsContext } from '../../contexts';
import { Collectable } from './collectables';
import { FRIENDLY_CONTENT_TYPES } from 'lane-shared/helpers/constants/content';

type ContentType = keyof typeof FRIENDLY_CONTENT_TYPES;
type DraftContentHistoryType = {
  _updated: string;
} & {
  [key: string]: any;
};

export const useDraftContentHistoryAnalytics = ({
  contentType,
}: {
  contentType?: string;
} = {}) => {
  const { track } = useContext(AnalyticsContext);
  const friendlyContentType = contentType
    ? FRIENDLY_CONTENT_TYPES[contentType as ContentType]
    : undefined;

  const trackViewed = (step: string, collectable: Collectable | null) => {
    track(`DraftContentHistory.View.${step}`, {
      extraInformation: collectable,
    });
  };

  const trackSelected = (step: string, collectable: Collectable | null) => {
    track(`DraftContentHistory.Select.${step}`, {
      extraInformation: collectable,
    });
  };

  const draftContentHistoryTracker = {
    View: {
      Index: () => {
        trackViewed('Index', { contentType: friendlyContentType });
      },
    },
    Select: {
      Dropdown: () => {
        trackSelected('Dropdown', {
          contentType: friendlyContentType,
        });
      },
      Restore: (draftContentHistory: DraftContentHistoryType) => {
        trackSelected('Restore', {
          contentType: friendlyContentType,
          selectedVersionUpdated: draftContentHistory._updated,
        });
      },
    },
  };

  return { draftContentHistoryTracker };
};
