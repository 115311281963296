import React, { useState, useEffect } from 'react';
import { getClient } from 'lane-shared/apollo';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { routes } from 'lane-shared/config';
import {
  Relay,
} from 'lane-shared/domains/hardwareManagement/types/Relay';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { Chip, ChipStyle } from 'components/ads';
import { Button, Table, PageSizeType } from 'design-system-web';
import styles from './styles.scss';
import { exportCSV, getStatusTranslation } from '../../../../../helpers';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { relayListQuery } from 'lane-shared/graphql/hardware/relay/relay';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_START,
  RELAY_MODULE_STATUS_CONNECTED,
} from '../relay';

export function RelayListPage({ channel }: { channel: ChannelType }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [page, setPage] = useState(DEFAULT_PAGE_START);
  const [pageSize, setPageSize] = useState<PageSizeType>(DEFAULT_PAGE_SIZE);
  const [keyword, setKeyword] = useState('');

  function goToDetail(relayId: string) {
    history.push(
      routes.channelAdminRelayDetails
        .replace(':id', channel?.slug)
        .replace(':relayId', relayId),
      {}
    );
  }

  function goToEdit(relayId: string) {
    history.push(
      routes.channelAdminRelayDetails
        .replace(':id', channel?.slug)
        .replace(':relayId', relayId),
      { goToEdit: true }
    );
  }

  const viewDetailsRowAction = {
    label: t(
      'web.admin.hardware.management.hardware.list.row.action.view.details'
    ),
    onClick: (row: Relay) => goToDetail(row.id),
  };

  const editRowAction = {
    label: t('web.admin.hardware.management.hardware.profile.edit'),
    onClick: (row: Relay) => goToEdit(row.id),
  };

  const rowActions = [viewDetailsRowAction, editRowAction];

  const exportAll = () => {
    exportCSV(
      mappedRelayList,
      columns.filter(column => column.key !== 'unlock'),
      `${t`web.admin.serviceRequest.equipment.csvName`}.csv`
    );
  };

  const exportCurrentPage = (currentPageData: any) => {
    exportCSV(
      currentPageData,
      columns.filter(column => column.key !== 'unlock'),
      `${t`web.admin.serviceRequest.equipment.csvName`}.csv`
    );
  };

  const exportOptions = [
    {
      label: t('web.admin.serviceRequest.exportPage'),
      onClick: exportCurrentPage,
    },
    { label: t('web.admin.serviceRequest.exportAll'), onClick: exportAll },
  ];

  const columns = [
    {
      key: 'name',
      header: t('web.admin.hardware.management.hardware.list.column.name'),
      disableSorting: false,
      renderCell: (_: any, row: Relay) => {
        return (
          <Link
            to={routes.channelAdminRelayDetails
              .replace(':id', channel?.slug)
              .replace(':relayId', row.id)}
            className={cx(styles.linkText, styles.numberOfCharacters)}
          >
            {row.name}
          </Link>
        );
      },
      renderForCSV: (name: any) => name,
      disableVisibilityToggle: true,
    },
    {
      key: 'access_point_name',
      header: t('web.admin.hardware.management.relay.accessPoint'),
      disableSorting: true,
    },
    {
      key: 'unlock',
      header: t('web.admin.hardware.management.relay.list.column.unlock'),
      disableSorting: true,
      renderCell: (_: any) => {
        return (
          <Button variant="secondary" size="large" onClick={() => {}}>
            {t('web.admin.hardware.management.relay.list.column.unlock')}
          </Button>
        );
      },
    },
    {
      key: 'status',
      header: t('web.admin.hardware.management.hardware.list.column.status'),
      disableSorting: true,
      renderCell: (_: any, row: Relay) => {
        return (
          <Chip
            value={getStatusTranslation(row.relayModule.status, t)}
            type={
              row.relayModule.status === RELAY_MODULE_STATUS_CONNECTED
                ? ChipStyle.Green
                : ChipStyle.Red
            }
            doTranslate={false}
            withStatusIcon
          />
        );
      },
      renderForCSV: (_: any, row: Relay) =>
        getStatusTranslation(row.relayModule.status, t),
    },
    {
      key: 'description',
      header: t('web.admin.hardware.management.relay.notes'),
      disableSorting: true,
    },
  ];

  function getRelayListParams(
    channel: ChannelType,
    keyword: string,
    page: number,
    pageSize: PageSizeType
  ) {
    return {
      buildingId: convertToUUID(channel?._id),
      search: {
        query: keyword,
      },
      pagination: {
        start: page * pageSize,
        perPage: pageSize,
      },
    };
  }

  const { data, loading: isLoading, refetch: refetchRelays } = useQuery(
    relayListQuery,
    {
      client: getClient(),
      variables: getRelayListParams(channel, keyword, page, pageSize),
    }
  );
  const relays = data?.getRelayOutputList?.relayOutputs || [];
  const mappedRelayList = relays
    ? relays.map((relay: any) => ({
        ...relay,
        access_point_name: relay?.accessPoint?.name || '',
        unlock: true,
      }))
    : [];
  const handleKeywordFilterChange = (keyword: string) => {
    setKeyword(keyword);
    setPage(DEFAULT_PAGE_START);
  };

  useEffect(() => {
    refetchRelays(getRelayListParams(channel, keyword, page, pageSize));
  }, [page, pageSize, keyword]);

  return (
    <div className={styles.Relays}>
      <hr />
      <div>
        <Table
          columns={columns}
          pagination="server"
          totalRows={data?.getRelayOutputList?.pageInfo?.total || 0}
          pageSize={pageSize}
          page={page}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          data={mappedRelayList}
          rowActions={rowActions}
          showColumnVisibility
          hasKeywordFilter
          onKeywordFilterChange={handleKeywordFilterChange}
          keywordFilterTooltip="Search by name or access point"
          exportOptions={exportOptions}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
