import type { FeatureInstance } from '../../../types/FeatureInstance';
import type { EnabledFeaturesForAnalyticsType } from '../../../types/Analytics';
import _ from 'lodash';
import { FeatureNameEnum } from '../../../types/features/FeatureNameEnum';
import { ReservableFeatureProperties } from '../../../types/features/ReservableFeatureProperties';

// Note: while we (for now) just collect an object with all the included feature names,
// we additionally collect the bufferTime and relatesSpaces for the Reservable feature
// See: hooks/contentInteraction/useFormatInteractionForAnalytics.ts

export const contentFeatureCollectables = (features: FeatureInstance[]) => {
  if (!features.length) return;

  const enabledFeatures: EnabledFeaturesForAnalyticsType = {};

  for (const contentFeature of features) {
    if (contentFeature) {
      const { type, feature } = contentFeature;

      if (type === FeatureNameEnum.Reservable) {
        const {
          bufferTime,
          relatedSpaces,
        } = feature as ReservableFeatureProperties;

        enabledFeatures[FeatureNameEnum.Reservable] = {
          bufferTime: Boolean(bufferTime?.isEnabled),
          relatedSpaces: !_.isEmpty(relatedSpaces),
        };
      } else {
        enabledFeatures[type] = true;
      }
    }
  }

  return enabledFeatures;
};
