import gql from 'graphql-tag';

export const getChannelAddress = gql`
  query getChannelAddress($channelId: UUID) {
    channel(_id: $channelId) {
      _id
      name
      address {
        name
        street1
        street2
        street3
        city
        state
        code
        country
      }
    }
  }
`;
