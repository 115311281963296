import { useContext } from 'react';

import { AnalyticsContext } from '../../contexts';
import { Collectable, draftContentCollectables } from './collectables';
import type { DraftContentType } from '../../types/content/DraftContent';

export type StepperTrackerType = ReturnType<
  ReturnType<typeof useDraftContentAnalytics>['bootstrapStepper']
>;

export const useDraftContentAnalytics = ({
  draftContent,
}: {
  draftContent?: DraftContentType;
} = {}) => {
  const { track } = useContext(AnalyticsContext);

  const trackViewed = (step: string, collectable?: Collectable) => {
    track(`DraftContent.View.${step}`, { extraInformation: collectable });
  };

  const trackCreated = (step: string, collectables?: Collectable) => {
    track(`DraftContent.Create.${step}`, {
      extraInformation: collectables,
    });
  };

  const trackUpdated = (step: string, collectables?: Collectable) => {
    track(`DraftContent.Update.${step}`, {
      extraInformation: collectables,
    });
  };

  const bootstrapStepper = (forCreate: boolean) => {
    const correctTrack = forCreate ? trackCreated : trackUpdated;

    return {
      Info: {
        IsEvent: (newDraftContent?: Partial<DraftContentType>) => {
          correctTrack(
            'Stepper.Info.isEvent',
            draftContentCollectables(newDraftContent || draftContent)
          );
        },
        EventStartDate: (newDraftContent?: Partial<DraftContentType>) => {
          correctTrack(
            'Stepper.Info.EventStartDate',
            draftContentCollectables(newDraftContent || draftContent)
          );
        },
        EventEndDate: (newDraftContent?: Partial<DraftContentType>) => {
          correctTrack(
            'Stepper.Info.EventEndDate',
            draftContentCollectables(newDraftContent || draftContent)
          );
        },
      },
      Editor: (newDraftContent?: Partial<DraftContentType>) =>
        correctTrack(
          `Stepper.Editor`,
          draftContentCollectables(newDraftContent || draftContent)
        ),
      Targeting: {
        Add: (
          placementType: string,
          newDraftContent?: Partial<DraftContentType>
        ) => {
          correctTrack(`Stepper.Targeting.Add`, {
            ...draftContentCollectables(newDraftContent || draftContent),
            placementType,
          });
        },
        Select: (
          placementType: string,
          newDraftContent?: Partial<DraftContentType>
        ) => {
          correctTrack(`Stepper.Targeting.Select`, {
            ...draftContentCollectables(newDraftContent || draftContent),
            placementType,
          });
        },
        Remove: (
          placementType: string,
          newDraftContent?: Partial<DraftContentType>
        ) => {
          correctTrack(`Stepper.Targeting.Remove`, {
            ...draftContentCollectables(newDraftContent || draftContent),
            placementType,
          });
        },
      },
      Publish: {
        PublishDate: (newDraftContent?: Partial<DraftContentType>) => {
          correctTrack(
            `Stepper.Publish.PublishDate`,
            draftContentCollectables(newDraftContent || draftContent)
          );
        },
        UnpublishDate: (newDraftContent?: Partial<DraftContentType>) => {
          correctTrack(
            `Stepper.Publish.UnpublishDate`,
            draftContentCollectables(newDraftContent || draftContent)
          );
        },
        InteractionEndDate: (newDraftContent?: Partial<DraftContentType>) => {
          correctTrack(
            `Stepper.Publish.InteractionEndDate`,
            draftContentCollectables(newDraftContent || draftContent)
          );
        },
        AutoNotificationIsSet: (
          newDraftContent?: Partial<DraftContentType>
        ) => {
          correctTrack(
            `Stepper.Publish.AutoNotificationIsSet`,
            draftContentCollectables(newDraftContent || draftContent)
          );
        },
        AddNotification: (newDraftContent?: Partial<DraftContentType>) => {
          correctTrack(
            `Stepper.Publish.AddNotification`,
            draftContentCollectables(newDraftContent || draftContent)
          );
        },
        DeleteNotification: (newDraftContent?: Partial<DraftContentType>) => {
          correctTrack(
            `Stepper.Publish.DeleteNotification`,
            draftContentCollectables(newDraftContent || draftContent)
          );
        },
      },
    };
  };

  const draftContentTracker = {
    View: {
      Index: (newDraftContent?: Partial<DraftContentType>) => {
        trackViewed(
          `Index`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
    },
    Create: {
      SideBarCreate: (newDraftContent?: Partial<DraftContentType>) => {
        trackCreated(
          `SideBarCreate`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
      Start: {
        New: (newDraftContent?: Partial<DraftContentType>) => {
          trackCreated(
            `Start.New`,
            draftContentCollectables(newDraftContent || draftContent)
          );
        },
        FromTemplate: (newDraftContent?: Partial<DraftContentType>) => {
          trackCreated(
            `Start.FromTemplate`,
            draftContentCollectables(newDraftContent || draftContent)
          );
        },
      },
      ...bootstrapStepper(true),
      Save: (newDraftContent?: Partial<DraftContentType>) => {
        trackCreated(
          `Save`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
      Publish: (newDraftContent?: Partial<DraftContentType>) => {
        trackCreated(
          `Publish`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
      Delete: (newDraftContent?: Partial<DraftContentType>) => {
        trackCreated(
          `Delete`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
      Import: (newDraftContent?: Partial<DraftContentType>) => {
        trackUpdated(
          `Import`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
      Export: (newDraftContent?: Partial<DraftContentType>) => {
        trackUpdated(
          `Export`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
    },
    Update: {
      Resume: (newDraftContent?: Partial<DraftContentType>) => {
        trackUpdated(
          `Resume`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
      ...bootstrapStepper(false),
      Save: (newDraftContent?: Partial<DraftContentType>) => {
        trackUpdated(
          `Save`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
      Publish: (newDraftContent?: Partial<DraftContentType>) => {
        trackUpdated(
          `Publish`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
      Unpublish: (newDraftContent?: Partial<DraftContentType>) => {
        trackUpdated(
          `Unpublish`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
      Delete: (newDraftContent?: Partial<DraftContentType>) => {
        trackUpdated(
          `Delete`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
      Import: (newDraftContent?: Partial<DraftContentType>) => {
        trackUpdated(
          `Import`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
      Export: (newDraftContent?: Partial<DraftContentType>) => {
        trackUpdated(
          `Export`,
          draftContentCollectables(newDraftContent || draftContent)
        );
      },
    },
  };

  return { draftContentTracker, bootstrapStepper };
};
