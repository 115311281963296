import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

import { colors } from 'lane-shared/config';
import { visitorManagementContentBuilder } from 'lane-shared/domains/visitorManagement/helpers/visitorManagementContentBuilder';
import {
  workOrderRequiredFeatures,
  DEFAULT_WORK_ORDER_BLOCK,
} from 'lane-shared/domains/workOrder';
import { ChannelNotSetupError } from 'activate-errors';
import {
  DEFAULT_BACKGROUND,
  DEFAULT_COLOR,
} from 'lane-shared/helpers/constants';
import {
  CONTENT_CARD_ID,
  CONTENT_TYPES,
  CONTENT_CARD_STANDARD,
  NOTICE_CARD,
  CONTENT_CARD_HERO,
  CONTENT_CARD_HORIZONTAL,
} from 'lane-shared/helpers/constants/content';
import {
  EARLIEST_DATE,
  LATEST_DATE,
} from 'lane-shared/helpers/content/getResetTimes';
import { SubtitleOptions } from 'lane-shared/helpers/content/subtitleHelpers';
import Features from 'lane-shared/renderers/v5/features';
import { TimeResetFrequencyEnum } from 'lane-shared/renderers/v5/features/Reset';
import { ThemeType } from 'lane-shared/types/Theme';
import { ContentCategoryEnum } from 'lane-shared/types/content/ContentCategoryEnum';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import {
  DEFAULT_WO_CONTENT_ICON,
  DEFAULT_WO_CONTENT_ICON_SET,
} from '../../domains/workOrder/constants';

type Params = {
  // TODO: this should probably be updated to support all ContentTypeEnum's
  contentType:
    | ContentTypeEnum.Notice
    | ContentTypeEnum.Content
    | ContentTypeEnum.Perk
    | ContentTypeEnum.Promotion
    | ContentTypeEnum.Static
    | ContentTypeEnum.WorkOrder
    | ContentTypeEnum.VisitorManagement;
  channel: {
    address: { geo: [number, number] };
    _id: string;
    profile: {
      _id: string;
      theme: ThemeType;
    };
  };
  baseBlock?: any;
  isContentGenerator?: boolean;
  generator?: { datasetSchema?: { _id: string } } | null;
};

export default function constructContent({
  contentType,
  channel,
  baseBlock,
  isContentGenerator = false,
  generator = {},
}: Params) {
  const contentId = uuid();

  if (!channel.address.geo) {
    throw new ChannelNotSetupError();
  }

  const [longitude, latitude] = channel.address.geo;

  const startOfHour = DateTime.local().startOf('hour');

  const contentValues = {
    actions: [],
    card: {
      _id: null,
    },
    channel: {
      _id: channel._id,
    },
    theme: {
      _id: channel.profile.theme._id,
    },
    category: ContentCategoryEnum.None,
    reportingGroup: ContentCategoryEnum.None,
    data: {},
    description: '',
    features: [],
    relatedChannelIntegrationIds: [],
    geo: [longitude, latitude],
    isEvent: false,
    isPublished: false,
    isSyndicateable: false,
    lastReset: EARLIEST_DATE,
    logo: null,
    name: '',
    nextReset: LATEST_DATE,
    notifications: [],
    contentMetatags: [],
    placements: [
      {
        _id: uuid(),
        type: 'Published',
        content: {
          _id: contentId,
        },
        channel: {
          _id: channel._id,
        },
      },
    ],
    properties: {},
    renderer: 5,
    resetPeriod: TimeResetFrequencyEnum.Never,
    slug: null,
    state: {},
    subtitle: null,
    tags: [],
    version: 0,
  };

  const theme = channel.profile.theme || null;

  switch (contentType) {
    case ContentTypeEnum.Notice:
      return {
        ...contentValues,
        _id: contentId,
        color: DEFAULT_COLOR,
        backgroundColor: DEFAULT_BACKGROUND,
        backgroundImage: null,
        tags: ['Notice'],
        isInteractive: false,
        liveDate: startOfHour.toJSDate(),
        unpublishDate: startOfHour.plus({ days: 1 }).toJSDate(),
        startDate: null,
        endDate: null,
        type: CONTENT_TYPES.NOTICE,
        block: {},
        card: {
          _id: CONTENT_CARD_ID[NOTICE_CARD],
        },
        subtitle: SubtitleOptions.NONE,
      };
    case ContentTypeEnum.Content:
      return {
        ...contentValues,
        _id: contentId,
        color: DEFAULT_COLOR,
        backgroundColor: theme?.palette?.primary || DEFAULT_BACKGROUND,
        backgroundImage: null,
        isInteractive: false,
        liveDate: startOfHour.toJSDate(),
        startDate: null,
        endDate: null,
        interactionEndDate: null,
        type: CONTENT_TYPES.CONTENT,
        block: { ...baseBlock },
        card: {
          _id: CONTENT_CARD_ID[CONTENT_CARD_STANDARD],
        },
      };
    case ContentTypeEnum.Perk:
      return {
        ...contentValues,
        _id: contentId,
        color: DEFAULT_COLOR,
        backgroundColor: DEFAULT_BACKGROUND,
        backgroundImage: null,
        isInteractive: true,
        liveDate: startOfHour.toJSDate(),
        startDate: null,
        endDate: null,
        interactionEndDate: null,
        type: CONTENT_TYPES.PERK,
        block: { ...baseBlock },
        card: {
          _id: CONTENT_CARD_ID[CONTENT_CARD_HERO],
        },
        features: [
          {
            type: Features.Entries.name,
            feature: {
              allowAnonymous: false,
              unlimited: false,
              entries: 1,
            },
          },
        ],
      };
    case ContentTypeEnum.Promotion:
      return {
        ...contentValues,
        _id: contentId,
        color: DEFAULT_COLOR,
        backgroundColor: DEFAULT_BACKGROUND,
        backgroundImage: null,
        isInteractive: true,
        liveDate: startOfHour.toJSDate(),
        startDate: null,
        endDate: null,
        interactionEndDate: null,
        type: CONTENT_TYPES.PROMOTION,
        block: { ...baseBlock },
        card: {
          _id: CONTENT_CARD_ID[CONTENT_CARD_HERO],
        },
        features: [
          {
            type: Features.Entries.name,
            feature: {
              allowAnonymous: false,
              unlimited: false,
              entries: 1,
            },
          },
        ],
      };
    case ContentTypeEnum.Static: {
      return {
        ...contentValues,
        _id: contentId,
        backgroundColor: theme?.palette?.primary || colors.darkCharcoal,
        color: theme?.palette?.secondary || colors.white,
        backgroundImage: null,
        isInteractive: false,
        type: CONTENT_TYPES.STATIC,
        block: { ...baseBlock },
        card: {
          _id: CONTENT_CARD_ID[CONTENT_CARD_HORIZONTAL],
        },
        ...(isContentGenerator ? { generator } : {}),
      };
    }

    case ContentTypeEnum.WorkOrder: {
      return {
        ...contentValues,
        _id: contentId,
        backgroundColor: theme?.palette?.primary || colors.darkCharcoal,
        color: theme?.palette?.secondary || colors.white,
        backgroundImage: null,
        isInteractive: true,
        description: 'Submit and view requests',
        name: 'Work Orders',
        interactionEndDate: null,
        liveDate: startOfHour.toJSDate(),
        type: ContentTypeEnum.WorkOrder,
        block: { ...baseBlock, ...DEFAULT_WORK_ORDER_BLOCK },
        card: {
          _id: CONTENT_CARD_ID[CONTENT_CARD_HORIZONTAL],
        },
        features: workOrderRequiredFeatures(),
        icon: DEFAULT_WO_CONTENT_ICON,
        iconSet: DEFAULT_WO_CONTENT_ICON_SET,
      };
    }

    case ContentTypeEnum.VisitorManagement: {
      return {
        ...contentValues,
        ...visitorManagementContentBuilder(contentId, baseBlock, theme),
      };
    }
  }
}
