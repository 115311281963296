import {
  cancelReservableInteraction,
  createReservableInteraction,
} from 'lane-shared/graphql/integrations/ShopifyBurst/reservableInteractions';
import {
  ShopifyBurstInteractionType,
  BurstDraftInteractionData,
} from 'lane-shared/types/integrations/ShopifyBurst';

type Props = {
  data: ShopifyBurstInteractionType;
  content: any;
  update?: BurstDraftInteractionData;
  onDataUpdated: (data: BurstDraftInteractionData) => void;
  onClose: () => void;
};

export default async function handleSave({
  data,
  content,
  update,
  onDataUpdated,
  onClose,
}: Props) {
  try {
    const { isDiyBurst } = data;
    const dataToUpdate = {
      ...data,
      ...update,
    };
    let interaction;

    if (!isDiyBurst) {
      await cancelReservableInteraction(data.primaryRoomBooking!);
      interaction = await createReservableInteraction(content!, {
        startDate: data?.dateRange?.startDate!,
        endDate: data?.dateRange?.endDate!,
      });
      dataToUpdate.primaryRoomBooking = interaction;
    }

    onDataUpdated(dataToUpdate);
  } catch (err) {
    window.Toast.show(err.message.split(':')[1]);
  } finally {
    onClose();
  }
}
