import React, { useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ContentWorkflowPayloadType } from 'lane-shared/types/ContentWorkflowType';

import styles from './index.scss';
import { Button, H4, Icon, M } from 'design-system-web';
import { EmailBuilderV2Preview } from './EmailBuilderV2Preview';
import { EmailBuilderV2Editor } from './EmailBuilderV2Editor';
import { ChannelType } from 'lane-shared/types/ChannelType';
import {
  EMAIL_CHECKBOXES,
  EmailCheckboxesType,
  EmailEditableEnum,
} from '../helpers/constants';
import { ContentType } from 'lane-shared/types/content/Content';
import { DraftContentType } from 'lane-shared/types/content/DraftContent';
import { ValidationError } from 'yup';
import { Workflow } from 'lane-shared/types/workflow';

type Props = {
  className?: string;
  style: React.CSSProperties;
  workflow: Workflow;
  channel: ChannelType;
  content: ContentType | DraftContentType;
  timeZone: string;
  onClose: () => void;
  onEmailUpdated: (email: Partial<ContentWorkflowPayloadType>) => void;
  validation: ValidationError | null;
  saveContent?: () => Promise<any>;
  onEmailOptionsClose?: () => void;
  onEmailOptionsOpen?: () => void;
};

export function EmailBuilderV2({
  className,
  workflow,
  channel,
  content,
  style,
  timeZone,
  onClose,
  onEmailUpdated,
  validation,
  saveContent,
  onEmailOptionsClose,
  onEmailOptionsOpen,
}: Props) {
  const { t } = useTranslation();
  const [
    optionInEditingMode,
    setOptionInEditingMode,
  ] = useState<EmailEditableEnum>(EmailEditableEnum.None);

  const [
    draftPayloadForEmailSettings,
    setDraftPayloadForEmailSettings,
  ] = useState<ContentWorkflowPayloadType>(workflow.payload || {});

  const [emailCheckboxes, setEmailCheckboxes] = useState<EmailCheckboxesType>(
    EMAIL_CHECKBOXES
  );

  const updateDraftPayloadForEmailSettings = (
    updates: Partial<ContentWorkflowPayloadType>
  ) => {
    setDraftPayloadForEmailSettings({
      ...draftPayloadForEmailSettings,
      ...updates,
    });
  };

  const handleSave = async () => {
    const updatedDraftPayloadForEmailSettings = {
      ...draftPayloadForEmailSettings,
    };

    if (
      draftPayloadForEmailSettings.outtro &&
      draftPayloadForEmailSettings.outtro_l10n &&
      !emailCheckboxes.outtro
    ) {
      delete updatedDraftPayloadForEmailSettings.outtro;
      delete updatedDraftPayloadForEmailSettings.outtro_l10n;
    }

    onEmailUpdated(updatedDraftPayloadForEmailSettings);

    if (saveContent) {
      await saveContent();
    }

    onClose();
  };

  return (
    <div className={cx(styles.emailBuilderContainer, className)} style={style}>
      <div className={cx(styles.emailSettingsHeader, className)} style={style}>
        <H4 className={cx(styles.emailSettingsText, className)} style={style}>
          {t('web.admin.channel.content.workflow.editor.emailSettings.title')}
        </H4>
        <Button
          onClick={onClose}
          className={cx(styles.closeButton, className)}
          style={style}
          variant="text"
          testId="closeButton"
        >
          <Icon
            className={cx(styles.icon, className)}
            name="x"
            set="Feather"
            style={style}
          />
        </Button>
      </div>
      <div
        className={cx(styles.emailSettingsContainer, className)}
        style={style}
      >
        <EmailBuilderV2Preview
          workflow={workflow}
          channel={channel}
          content={content}
          style={style}
          timeZone={timeZone}
          className={styles.emailBuilderV2}
          setOptionInEditingMode={setOptionInEditingMode}
          draftPayloadForEmailSettings={draftPayloadForEmailSettings}
          updateDraftPayloadForEmailSettings={
            updateDraftPayloadForEmailSettings
          }
          emailCheckboxes={emailCheckboxes}
          setEmailCheckboxes={setEmailCheckboxes}
          onEmailOptionsClose={onEmailOptionsClose}
          onEmailOptionsOpen={onEmailOptionsOpen}
        />
        <EmailBuilderV2Editor
          workflow={workflow}
          channel={channel}
          style={style}
          className={styles.emailBuilderV2}
          emailEditable={optionInEditingMode}
          validation={validation}
          draftPayloadForEmailSettings={draftPayloadForEmailSettings}
          updateDraftPayloadForEmailSettings={
            updateDraftPayloadForEmailSettings
          }
          contentName={content.name}
        />
      </div>
      <div className={cx(styles.actionsContainer, className)} style={style}>
        <Button
          className={cx(styles.saveButton, className)}
          variant="primary"
          onClick={handleSave}
        >
          <M className={cx(styles.saveText, className)} style={style}>
            {t('web.admin.channel.content.workflow.editor.emailSettings.save')}
          </M>
        </Button>
        <Button
          className={cx(styles.cancelButton, className)}
          variant="secondary"
          onClick={onClose}
        >
          {t('web.admin.channel.content.workflow.editor.emailSettings.cancel')}
        </Button>
      </div>
    </div>
  );
}
