import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiselectField, Flex } from 'components';
import { Item } from 'components/form/Dropdown/Dropdown';
import { listUnits } from 'lane-shared/graphql/units';
import { useQuery } from '@apollo/client';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import { ListUnitsQuery } from 'graphql-query-contracts';

export type SuitesInputFields = Item<string>[];

export type SuitesFields = {
  suiteIds: string[];
};

type Props = {
  suites?: Item<string>[];
  onSuitesUpdate?: (data: SuitesFields) => void;
  direction?: 'row' | 'column';
};

const TRANSLATION_KEYS = {
  genericServerErrorMessage: 'web.components.lane.suitesMultiselect.errors.generic',
  titleInputPlaceholder: 'web.components.lane.suitesMultiselect.noSuitesAssociated',
  suitesLabel: 'web.components.lane.suitesMultiselect.label',
  suitesPlaceholder: 'web.components.lane.suitesMultiselect.placeholder',
};

export const SuitesMultiSelectDropdown: React.FC<Props> = ({
  suites = [],
  onSuitesUpdate = () => {},
  direction = 'row',
}) => {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const [inputSuites, setInputFields] = useState<SuitesInputFields>(suites);

  const {
    loading: isFetchingSuites,
    error: suitesError,
    data: suitesData,
  } = useQuery<ListUnitsQuery>(listUnits, {
    variables: {
      propertyId: channel?._id,
    },
  });

  const suitesDropdownNoOptionsMessage = suitesError
    ? TRANSLATION_KEYS.genericServerErrorMessage
    : TRANSLATION_KEYS.titleInputPlaceholder;

  const suiteOptions = useMemo(() => {
    return (
      suitesData?.property?.units?.map(unit => ({
          label: unit?.name,
          value: unit?.id,
        })) || []
    );
  }, [suitesData]);

  const handleOnChange = (
    value: Item<string>[]
  ) => {
    setInputFields(value);
    const updatedSuites: SuitesFields = {
      suiteIds: value.map(item => item.value),
    };

    onSuitesUpdate(updatedSuites);
  };

  return (
    <Flex direction={direction} justify="space-between" gap={5}>
      <Flex direction="column" className="flex-1 w-2/5">
        <MultiselectField
          label={t(TRANSLATION_KEYS.suitesLabel)}
          fixedLabel
          isFullWidth
          isSearchable
          testId="suitesDropdown"
          placeholder={t(TRANSLATION_KEYS.suitesPlaceholder)}
          noOptionsMessage={t(suitesDropdownNoOptionsMessage)}
          doTranslation={false}
          value={inputSuites}
          onChange={value => handleOnChange(value)}
          items={suiteOptions}
          isLoading={isFetchingSuites}
        />
      </Flex>
    </Flex>
  );
};
