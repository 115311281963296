import getTimeZoneByGeoLocation from 'lane-shared/helpers/getTimeZoneByGeoLocation';
import { ContentType } from 'lane-shared/types/content/Content';
import { DateTime } from 'luxon';

export const validateVisitorEndTime = (
  content: ContentType,
  interaction: any
) => {
  if ((interaction?.features as any)?.VisitorManagement?.schedule?.AllDay) {
    return true;
  }

  const endDate = (interaction?.features as any)?.VisitorManagement?.schedule
    ?.endDate;

  if (!endDate) return false;

  const timeZone = getTimeZoneByGeoLocation({
    latitude: content?.geo[1],
    longitude: content?.geo[0],
  });
  const currentDateTime = DateTime.local().setZone(timeZone);
  const parsedEndDateTime = DateTime.fromJSDate(endDate, { zone: timeZone });

  return currentDateTime.toMillis() <= parsedEndDateTime.toMillis();
};
