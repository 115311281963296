import { ENVIRONMENTS } from 'activate-constants';
import env from 'lane-shared/config/getEnv';
import * as AccessManagementProviders from 'lane-shared/helpers/integrations/AccessManagement/accessControl';
import * as AccessManagementIdentityProviders from 'lane-shared/helpers/integrations/AccessManagement/identityProvider';
import { IntegrationDefinition } from 'lane-shared/types/integrations/IntegrationDefinition';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';

import * as AccessManagement from './AccessManagement';
import * as AngusServiceRequests from './AngusServiceRequests';
import * as Auth0SAML from './Auth0SAML';
import * as Bilt from './Bilt';
import * as BuildingEnginesClassic from './BuildingEnginesClassic';
import * as BuildingEnginesPrism from './BuildingEnginesPrism';
import * as BuildingEnginesPrismVisitors from './BuildingEnginesPrismVisitors';
import * as Captivate from './Captivate';
import * as Cineplex from './Cineplex';
import * as ControlByWeb from './Controlbyweb';
import * as Entrata from './Entrata';
import * as Essensys from './Essensys';
import * as FCPBot from './FCPBot';
import * as FloorMaps from './FloorMaps';
import * as Gallagher from './Gallagher';
import * as GoogleCalendar from './GoogleCalendar';
import * as GoogleWorkplace from './GoogleWorkplace';
import * as HID from './HID';
import * as HOM from './HOM';
import * as Kastle from './Kastle';
import * as LifeStart from './LifeStart';
import * as LiveSafe from './LiveSafe';
import * as Mailchimp from './Mailchimp';
import * as MicrosoftAzure from './MicrosoftAzure';
import * as Newstand from './Newstand';
import * as OIDC from './OIDC';
import * as Openpath from './Openpath';
import * as OutlookCalendar from './OutlookCalendar';
import * as ParkWhiz from './ParkWhiz';
import * as Pattison from './Pattison';
import * as ProxyClick from './ProxyClick';
import * as RealPage from './RealPage';
import * as Ritual from './Ritual';
import * as SAML from './SAML';
import * as Safetrust from './Safetrust';
import * as SaltoSVN from './SaltoSVN';
import * as ServiceRequest from './ServiceRequest';
import * as ShopifyBurst from './ShopifyBurst';
import * as ShopifyMultipass from './ShopifyMultipass';
import * as ShopifyRsvp from './ShopifyRsvp';
import * as SwiftConnect from './SwiftConnect';
import * as ThreeTenServ from './ThreeTenServ';
import * as Yardi from './Yardi';
import * as YardiCommercial from './YardiCommercial';
import * as YouRHere from './YouRHere';

type IntegrationProvider = IntegrationProviderEnum;

const mapIntegrationToDefinition: {
  [key in IntegrationProvider]: IntegrationDefinition;
} = {
  AccessManagement: AccessManagement.definition,
  AngusServiceRequests: AngusServiceRequests.definition,
  Captivate: Captivate.definition,
  Cineplex: Cineplex.definition,
  ControlByWeb: ControlByWeb.definition,
  Essensys: Essensys.definition,
  FCPBot: FCPBot.definition,
  FloorMaps: FloorMaps.definition,
  Gallagher: Gallagher.definition,
  GoogleWorkplace: GoogleWorkplace.definition,
  GoogleCalendar: GoogleCalendar.definition,
  HID: HID.definition,
  HOM: HOM.definition,
  Kastle: Kastle.definition,
  LiveSafe: LiveSafe.definition,
  Newstand: Newstand.definition,
  MicrosoftAzure: MicrosoftAzure.definition,
  Openpath: Openpath.definition,
  OutlookCalendar: OutlookCalendar.definition,
  ParkWhiz: ParkWhiz.definition,
  Pattison: Pattison.definition,
  ProxyClick: ProxyClick.definition,
  SAML: SAML.definition,
  Auth0SAML: Auth0SAML.definition,
  Ritual: Ritual.definition,
  Safetrust: Safetrust.definition,
  ServiceRequest: ServiceRequest.definition,
  ShopifyBurst: ShopifyBurst.definition,
  ShopifyRsvp: ShopifyRsvp.definition,
  ShopifyMultipass: ShopifyMultipass.definition,
  ThreeTenServ: ThreeTenServ.definition,
  YouRHere: YouRHere.definition,
  BuildingEnginesPrism: BuildingEnginesPrism.definition,
  BuildingEnginesClassic: BuildingEnginesClassic.definition,
  BuildingEnginesPrismVisitors: BuildingEnginesPrismVisitors.definition,
  LifeStart: LifeStart.definition,
  Yardi: Yardi.definition,
  YardiCommercial: YardiCommercial.definition,
  Entrata: Entrata.definition,
  RealPage: RealPage.definition,
  [IntegrationProviderEnum.SaltoSVN]: SaltoSVN.definition,
  MailChimp: Mailchimp.definition,
  Bilt: Bilt.definition,
  OIDC: OIDC.definition,
  SwiftConnect: SwiftConnect.definition,
};

const nestedIntegrationDefinitions: Partial<
  {
    [key in IntegrationProvider]: PropertiesInterface;
  }
> = {
  ...AccessManagementIdentityProviders.IdentityProviderDefinitions,
  ...AccessManagementProviders.AccessControlServiceDefinitions,
};

export function getNestedIntegrationDefinition(
  provider: IntegrationProviderEnum
): PropertiesInterface {
  const nestedDefinition = nestedIntegrationDefinitions[provider];

  if (env === ENVIRONMENTS.LOCAL) {
    if (!nestedDefinition) {
      throw new Error(
        `[Dev error]: The nested definitions for the integration provider ${provider} was not found.`
      );
    }
  }

  return nestedDefinition || {};
}

/**
 * Get the definition for any of our integration providers.
 */
export default function getIntegrationDefinition(
  provider: IntegrationProviderEnum
): IntegrationDefinition {
  const definition = mapIntegrationToDefinition[provider];

  if (env === ENVIRONMENTS.LOCAL) {
    if (!definition) {
      throw new Error(
        `[Dev error]: The definition for the integration provider ${provider} was not found.`
      );
    }
  }

  return definition;
}

export function getIntegrationDisplayName(integrationData: any): string {
  let integration;

  try {
    integration = getIntegrationDefinition(integrationData.integration.name);
  } catch (e) {
    console.error(e);
  }

  if (integration!.getChannelIntegrationName) {
    return integration!.getChannelIntegrationName(integrationData);
  }

  return integration!.friendlyName;
}
