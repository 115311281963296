export const buildProviderToACGsMap = (
  acgs: { provider: string; value: string }[]
) => {
  const providerToACGMap = Object.entries(
    acgs.reduce((acc: any, acg) => {
      acc[acg.provider] = acc[acg.provider] || [];
      acc[acg.provider].push(acg.value);

      return acc;
    }, {})
  ).map(([provider, accessControlGroupIds]) => ({
    provider,
    accessControlGroupIds,
  }));

  return providerToACGMap;
};
