import { useEffect, useState, useContext } from 'react';

import { getClient } from 'lane-shared/apollo';
import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { ChannelsContext, UserDataContext } from 'lane-shared/contexts';
import {
  getBuildingChannelIntegration,
  getChannelAccessControlGroups,
} from 'lane-shared/graphql/accessControl';
import { hasPermission } from 'lane-shared/helpers';
import { PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY } from 'lane-shared/helpers/constants/permissions';

import { useAccessGroups } from './useAccessGroups';

export const useMobileAccessData = (
  channelId: string,
  integrationChannelId: string
) => {
  const { channels } = useContext(ChannelsContext);
  const currentUserContext = useContext(UserDataContext);
  const { accessGroups, loading } = useAccessGroups(channelId);

  const [connectedChannelData, setConnectedChannelData] = useState<any | null>(
    null
  );
  const [loadingData, setLoadingData] = useState(loading);

  const currentChannel = channels.find(channel => channel._id === channelId);

  useEffect(() => {
    (async function () {
      if (
        currentChannel?.type === ChannelTypeEnum.Company &&
        integrationChannelId === channelId
      ) {
        try {
          setLoadingData(true);
          const { data } = await getClient().query({
            query: getBuildingChannelIntegration,
            variables: {
              channelId,
            },
          });
          const { integration } = data.getBuildingChannelIntegration;

          if (
            integration.channelId &&
            hasPermission(
              currentUserContext.user?.roles,
              [PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY],
              integration.channelId,
              true
            )
          ) {
            const accessGroups = await fetchAccessGroups(integration.channelId);

            setConnectedChannelData({
              channelId: integration.channelId,
              accessGroups,
            });
          }
        } catch (error) {
          console.error('Error fetching building data:', error);
        } finally {
          setLoadingData(false);
        }
      }
    })();
  }, [channelId]);

  return {
    accessGroups,
    connectedChannel: connectedChannelData,
    loading: loading || loadingData,
    fetchAccessGroups,
  };
};

const fetchAccessGroups = async (channelId: string) => {
  const { data } = await getClient().query({
    query: getChannelAccessControlGroups,
    variables: {
      channelId,
    },
    fetchPolicy: 'no-cache',
  });

  return data.getChannelAccessControlGroups;
};
