import React, { useEffect, useMemo } from 'react';

import cx from 'classnames';
import { Toggle } from 'components';
import { useTranslation } from 'react-i18next';

import {
  INTERACTION_COMPLETE,
  INTERACTION_CREATED,
} from 'lane-shared/helpers/constants/interactions';
import toSchema from 'lane-shared/helpers/toSchema';
import { QRCodeCheckinFeatureCheckInOutRuleType } from 'lane-shared/types/features/QRCodeCheckinFeatureProperties';
import { ReservableUnitTypesEnum } from 'lane-shared/types/features/ReservableFeatureProperties';
import { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';

import Dropdown from 'components/form/Dropdown';
import ReservableFeatureTimeUnitSlider from 'components/renderers/features/Reservable/ReservableFeatureTimeUnitSlider';
import { H5 } from 'components/typography';

import styles from './QRCodeCheckinCheckInOutRuleEdit.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  rule: QRCodeCheckinFeatureCheckInOutRuleType;
  isAutoCancel?: boolean;
  statusesFeature: StatusesFeatureProperties;
  onRuleUpdated: (
    update: Partial<QRCodeCheckinFeatureCheckInOutRuleType>
  ) => void;
};

const TRANSLATION_KEYS = {
  toStatus: 'web.content.features.qrCodeScanning.toStatus',
  fromStatus: 'web.content.features.qrCodeScanning.fromStatus',
  autoUpdateStatus: 'web.content.features.qrCodeScanning.autoUpdateStatus',
  autoUpdateStatusEnabled:
    'web.content.features.qrCodeScanning.autoUpdateStatus.enabled',
  autoUpdateToStatus: 'web.content.features.qrCodeScanning.autoUpdateToStatus',
};

export default function QRCodeCheckinCheckInOutRuleEdit({
  className,
  style,
  rule,
  isAutoCancel,
  statusesFeature,
  onRuleUpdated,
}: Props) {
  const { t } = useTranslation();

  function autoStatusHandler(autoStatus: string) {
    onRuleUpdated({
      _id: rule._id,
      autoStatus,
    });
  }

  function fromStatusHandler(fromStatus: string) {
    onRuleUpdated({
      _id: rule._id,
      toStatus: undefined,
      fromStatus,
    });
  }

  function toStatusHandler(toStatus: string) {
    onRuleUpdated({
      _id: rule._id,
      toStatus,
    });
  }

  function unitsHandler(autoStatusUnits: number) {
    onRuleUpdated({
      _id: rule._id,
      autoStatusUnits,
    });
  }

  function unitTypeHandler(autoStatusUnitType: ReservableUnitTypesEnum) {
    onRuleUpdated({
      _id: rule._id,
      autoStatusUnitType,
    });
  }

  function toggleAutoStatusHandler() {
    onRuleUpdated({
      autoStatus: !rule.autoStatus ? INTERACTION_CREATED : null,
      autoStatusUnits: 15,
      autoStatusUnitType: ReservableUnitTypesEnum.Minutes,
    });
  }

  useEffect(() => {
    if (!isAutoCancel) {
      return;
    }

    onRuleUpdated({
      autoStatus: rule.autoStatus ? rule.autoStatus : INTERACTION_COMPLETE,
      autoStatusUnits: rule.autoStatusUnits,
      autoStatusUnitType: rule.autoStatusUnitType,
    });
  }, [isAutoCancel]);

  const fromItems = useMemo(
    () =>
      statusesFeature.rules
        .filter(({ nextStatuses }) => nextStatuses?.length > 0)
        .map(rule => toSchema(rule.status)),
    [statusesFeature]
  );

  const toItems = useMemo(() => {
    const applicableRules = statusesFeature.rules.filter(
      ({ status }) => status === rule.fromStatus
    );

    const statuses = applicableRules.reduce(
      (arr, rule) => Array.from(new Set([...arr, ...rule.nextStatuses])),
      [] as string[]
    );

    return statuses.map(toSchema);
  }, [statusesFeature, rule?.fromStatus]);

  return (
    <li
      className={cx(styles.QRCodeCheckinFeatureCheckInOutRule, className)}
      style={style}
    >
      <div className={styles.row}>
        <div className={styles.group}>
          <H5 mb={2}>{t(TRANSLATION_KEYS.fromStatus)}</H5>
          <Dropdown
            className={styles.dropDown}
            onValueChange={fromStatusHandler}
            value={rule.fromStatus}
            items={fromItems}
          />
        </div>

        {rule.fromStatus && (
          <div className={styles.group}>
            <H5 mt={4} mb={2}>
              {t(TRANSLATION_KEYS.toStatus)}
            </H5>
            <div className={styles.row}>
              <Dropdown
                className={styles.dropDown}
                onValueChange={toStatusHandler}
                value={rule.toStatus}
                items={toItems}
              />
            </div>
          </div>
        )}
      </div>

      {!isAutoCancel && (
        <>
          <H5 mt={4} mb={2}>
            {t(TRANSLATION_KEYS.autoUpdateStatus)}
          </H5>
          <Toggle
            value={Boolean(rule.autoStatus)}
            onChange={toggleAutoStatusHandler}
            text={t(TRANSLATION_KEYS.autoUpdateStatusEnabled)}
          />
        </>
      )}

      {(rule.autoStatus || isAutoCancel) && (
        <>
          <div className={styles.group}>
            <H5 mt={4} mb={2}>
              {t(TRANSLATION_KEYS.autoUpdateToStatus)}
            </H5>
            <div className={styles.row}>
              <Dropdown
                className={styles.dropDown}
                onValueChange={autoStatusHandler}
                value={rule.autoStatus}
                items={toItems}
              />
            </div>
          </div>

          <ReservableFeatureTimeUnitSlider
            unitType={rule.autoStatusUnitType}
            units={rule.autoStatusUnits}
            onUnitsUpdated={unitsHandler}
            onUnitTypeUpdated={unitTypeHandler}
          />
        </>
      )}
    </li>
  );
}
