import { CodedError } from '../CodedError';
import { errorCodeTypes } from './errorCodeTypes';

export class UnitTypeRequiredError extends CodedError {
  static get errorName() {
    return errorCodeTypes.unitTypeRequiredError.code;
  }

  constructor(message = errorCodeTypes.unitTypeRequiredError.message) {
    super(message);
    this.code = errorCodeTypes.unitTypeRequiredError.code;
    this.message = `${message} ${errorCodeTypes.unitTypeRequiredError.code}`;
    this.name = errorCodeTypes.unitTypeRequiredError.name;
  }
}
