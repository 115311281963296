import { useEffect, useState } from 'react';
import { UserType } from 'lane-shared/types/User';

import { CreditWallet } from 'graphql-resolver-contracts';
import { getUserCreditWallets } from './helpers';

type Props = {
  user: Pick<UserType, '_id'> | null;
};

export function useUserCreditWallets({ user }: Props) {
  const [error, setError] = useState<Error | null>(null);
  const [wallets, setWallets] = useState<CreditWallet[]>([]);
  const [pageInfo, setPageInfo] = useState(null);

  const fetchUserCreditWallets = async (userId: string) => {
    try {
      const data = await getUserCreditWallets(userId);

      if (data?.accounts?.getUserCreditWallets?.creditWallets) {
        setWallets(data?.accounts?.getUserCreditWallets?.creditWallets);
      }

      if (data?.accounts?.getUserCreditWallets?.pageInfo) {
        setPageInfo(data?.accounts?.getUserCreditWallets?.pageInfo);
      }
    } catch (e) {
      console.error(e);
      setError(e);
    }
  };

  useEffect(() => {
    if (user?._id) {
      fetchUserCreditWallets(user?._id);
    }
  }, [user]);

  return {
    wallets,
    pageInfo,
    userCreditWalletsError: error,
  } as const;
}
