import { DateTime, Interval } from 'luxon';
import { LaneType } from 'common-types';
import {
  ReservableEventTypeEnum,
  ReservableUnavailabilityRange,
} from 'lane-shared/hooks/useReservableAvailabilityByRange';
import { getUnavailableRangesFromBufferTime } from './getUnavailableRangesFromBufferTime';

function mapReservableUnavailabilityRangeToInterval(
  ur: ReservableUnavailabilityRange
): Interval {
  const { startDate, startTime, endDate, endTime } = ur.interval;

  if (startTime && endTime) {
    return Interval.fromDateTimes(
      DateTime.fromJSDate(startTime),
      DateTime.fromJSDate(endTime)
    );
  }

  if (startDate && endDate) {
    return Interval.fromDateTimes(
      DateTime.fromJSDate(startDate),
      DateTime.fromJSDate(endDate)
    );
  }

  throw new Error('Received unmappedable reservable range.');
}

function mapIntervalToReservableUnavailabilityRange(
  interval: Interval
): ReservableUnavailabilityRange {
  return {
    eventType: ReservableEventTypeEnum.TimeUnavailable,
    interval: {
      startDate: interval.start.toJSDate(),
      endDate: interval.end.toJSDate(),
    },
  };
}

type OperatingHours = {
  startDate: Date;
  endDate: Date;
  availableIntervals: Interval[];
};

type Props = {
  reservationDateRanges: ReservableUnavailabilityRange[];
  referenceDate: Date;
  operatingHours: OperatingHours | null;
  bufferTimeConfig: LaneType.BufferTime | undefined;
};

function getUnavailableOperatingHours({
  referenceDateTime,
  endDateTime,
  operatingHours,
}: {
  referenceDateTime: DateTime;
  endDateTime: DateTime;
  operatingHours: OperatingHours | null;
}) {
  const dayInterval = Interval.fromDateTimes(referenceDateTime, endDateTime);

  if (!operatingHours) {
    return [dayInterval];
  }

  return Interval.xor([dayInterval, ...operatingHours.availableIntervals]);
}

function getUnavailableDateRangesFromBufferTime({
  bufferTimeConfig,
  reservationDateRanges,
  availableIntervals,
}: {
  reservationDateRanges: ReservableUnavailabilityRange[];
  bufferTimeConfig: LaneType.BufferTime | undefined;
  availableIntervals: Interval[];
}) {
  if (!bufferTimeConfig) {
    return [];
  }

  const unavailableRangesFromBufferTimeConfig: ReservableUnavailabilityRange[] = [];
  const reservationBookingRanges = reservationDateRanges.filter(
    ({ eventType }) => eventType === ReservableEventTypeEnum.Reservation
  );

  availableIntervals?.forEach(interval => {
    const unavailableRanges = getUnavailableRangesFromBufferTime(
      interval.start.toJSDate(),
      interval.end.toJSDate(),
      reservationBookingRanges,
      bufferTimeConfig
    );

    unavailableRangesFromBufferTimeConfig.push(...unavailableRanges);
  });

  return unavailableRangesFromBufferTimeConfig.map(
    mapReservableUnavailabilityRangeToInterval
  );
}

export function getReservableUnavailableRanges({
  reservationDateRanges,
  referenceDate,
  operatingHours,
  bufferTimeConfig,
}: Props) {
  const referenceDateTime = DateTime.fromJSDate(referenceDate);
  const endDateTime = referenceDateTime.plus({ hours: 24 });
  const unavailableBookings = reservationDateRanges.map(
    mapReservableUnavailabilityRangeToInterval
  );

  const unavailableOperatingHours = getUnavailableOperatingHours({
    referenceDateTime,
    endDateTime,
    operatingHours,
  });

  const unavailableDateRangesFromBufferTime = getUnavailableDateRangesFromBufferTime(
    {
      bufferTimeConfig,
      reservationDateRanges,
      availableIntervals: operatingHours?.availableIntervals || [
        Interval.fromDateTimes(referenceDateTime, endDateTime),
      ],
    }
  );

  const unavailableRanges = Interval.merge([
    ...unavailableBookings,
    ...unavailableDateRangesFromBufferTime,
    ...unavailableOperatingHours,
  ]).map(mapIntervalToReservableUnavailabilityRange);

  return unavailableRanges;
}
