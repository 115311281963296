import { Button, ButtonLinkProps } from 'design-system-web';
import { Link } from 'react-router-dom';

import React from 'react';

type Props = Omit<ButtonLinkProps, 'as'> & {
  as?: React.ComponentType<any> | 'a';
};

export const ButtonLink = ({ as = Link, target, ...props }: Props) => {
  if (as === 'a') {
    return <Button as={as} target={target} {...props} />;
  }

  return <Button as={as} {...props} />;
};
