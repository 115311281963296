import i18next from 'i18next';
import { DateTime } from 'luxon';

import { SHORT_MONTH, MONTH_YEAR, LONG_MONTH } from '../../constants/dates';
import parseDateTime from '../../dates/parseDateTime';
import dateFormatter from '../dateFormatter';

export default function formatDateRangeMonth({
  _start,
  _end,
  i18n = i18next,
  timeZone,
  locale,
}: {
  _start: DateTime | Date | string;
  _end: DateTime | Date | string;
  i18n?: any;
  timeZone?: string;
  locale?: string;
}): string {
  const start = parseDateTime(_start, timeZone)!;
  const end = parseDateTime(_end, timeZone)!;

  if (start.hasSame(end, 'month')) {
    return `${dateFormatter(start, LONG_MONTH, timeZone, locale)}`;
  }

  if (start.hasSame(end, 'year')) {
    return i18n.t('shared.helpers.formatters.dateRange.range', {
      startTime: dateFormatter(start, SHORT_MONTH, timeZone, locale),
      endTime: dateFormatter(end, SHORT_MONTH, timeZone, locale),
    });
  }

  const startFormatted = dateFormatter(start, MONTH_YEAR, timeZone, locale);
  const endFormatted = end
    ? dateFormatter(end, MONTH_YEAR, timeZone, locale)
    : '…';

  return i18n.t('shared.helpers.formatters.dateRange.range', {
    startTime: startFormatted,
    endTime: endFormatted,
  });
}
