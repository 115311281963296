import { useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { UserDataContext } from '../../../contexts';
import { getTenantChannelsByUser } from '../../../graphql/visitorManagement';
import { TenantType } from '../types';
import { getClient } from '../../../apollo';

export const useTenantInfo = (channelId?: string) => {
  const { user } = useContext(UserDataContext);
  const [fetchInfo, { data }] = useLazyQuery<{
    tenantChannelsByUser: TenantType[];
  }>(getTenantChannelsByUser, {
    client: getClient(),
  });

  useEffect(() => {
    if (!channelId || !user?._id) return;

    fetchInfo({
      variables: {
        userId: user?._id,
        channelId,
      },
    });
  }, [channelId, user?._id, fetchInfo]);

  const channels = data?.tenantChannelsByUser;

  return channels?.length === 1 ? channels[0] : undefined;
};
