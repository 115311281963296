import React from 'react';

import { Button, Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { StripePaymentMethod } from 'components/features/Payment/PaymentSummaryV2/PaymentOptions/StripePaymentMethod';
import { M } from 'components/typography';

import styles from './invoiceAutopayment.scss';
import { PaymentSource } from 'graphql-query-contracts';

interface Props {
  paymentSources: PaymentSource[];
  setSelectedPaymentMethod: (selectedPaymentMethod: string) => void;
  selectedPaymentMethod: string;
  setIsAddNewCardClicked: (isAddNewCardClicked: boolean) => void;
}

export function UseExistingCardForAutopayment({
  paymentSources,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  setIsAddNewCardClicked,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.chooseExistingCard}>
      <M mb={5} mt={5}>
        {t(
          'abp.routes.accountInvoices.autopayment.modal.useExistingCreditCardSubtitle'
        )}
      </M>
      <div className={styles.paymentSources}>
        {paymentSources.map(paymentSource => (
          <div key={paymentSource.paymentMethodId}>
            <StripePaymentMethod
              paymentMethod={paymentSource}
              onSelectPaymentMethod={setSelectedPaymentMethod}
              selectable
              selected={paymentSource.paymentMethodId === selectedPaymentMethod}
            />
          </div>
        ))}
      </div>

      <Button
        className={styles.addNewCardButton}
        testId="AddNewCardForAutopaymentButton"
        onClick={() => setIsAddNewCardClicked(true)}
        variant="secondary"
        startIcon={<Icon name="plus" />}
        size="large"
      >
        {t('abp.routes.accountInvoices.autopayment.modal.addNewCard')}
      </Button>
    </div>
  );
}
