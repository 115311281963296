import React, { useState, useEffect, useContext } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { useMutation } from '@apollo/client';

import { routes } from 'lane-shared/config';
import AppContext from 'lane-shared/contexts/AppContext';
import forgotPasswordMutation from 'lane-shared/graphql/user/forgotPassword';

import Button from 'components/general/Button';
import Image from 'components/general/Image';
import { S, H4 } from 'components/typography';

import imgSentEmail from 'static/img/imgSentEmail.svg';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import ForgotPasswordForm, { EmailValidation } from './ForgotPasswordForm';

import styles from './ForgotPassword.scss';

const validateEmail = yup.object().shape({
  email: yup.string().trim().email().required(),
});

type Props = {
  className?: string;
  style?: React.CSSProperties;
  onForgotPassword?: () => void;
  isModal?: boolean;
};

export default function ForgotPassword({
  className,
  onForgotPassword,
  isModal,
}: Props) {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [validation, setValidation] = useState<EmailValidation | null>(null);
  const [loading, setLoading] = useState(false);
  const [forgotPassword] = useMutation(forgotPasswordMutation);
  const { t } = useTranslation();
  const history = useHistory();
  const { whitelabel } = useContext(AppContext);
  const shortOperatingName = whitelabel.shortOperatingName;

  async function validate(): Promise<boolean> {
    try {
      // After updating the state, see if its valid.
      await validateEmail.validate({ email });
      setValidation(null);

      return true;
    } catch (emailValidation) {
      setValidation(emailValidation);

      return false;
    }
  }

  useEffect(() => {
    validate();
  }, [email]);

  function handleSubmit() {
    setLoading(true);
    setError(null);

    async function doSubmit() {
      try {
        await forgotPassword({
          variables: { email },
        });

        setSubmitted(true);
      } catch (err) {
        setError(err);
      }

      setLoading(false);
    }

    doSubmit();
  }

  return (
    <div className={cx(styles.forgotPasswordForm, className)}>
      {!isModal ? (
        <Button
          variant="outlined-light"
          size="small"
          color="inherit"
          className={welcomeFormStyles.returnButton}
          startIcon={<Icon name="chevron-left" size="small" />}
          onClick={() => !onForgotPassword && history.push(routes.login)}
        >
          {t('Back to log in')}
        </Button>
      ) : null}

      {submitted && (
        <>
          <H4 mb={4} className={welcomeFormStyles.formTitle} v2>
            {t('Check your email')}
          </H4>
          <S className={welcomeFormStyles.formDescription}>
            {t(
              'We sent an email to {{email}} with a link to verify your address. Follow the next steps in your email to continue.',
              { email }
            )}
          </S>
          <Image src={imgSentEmail} alt={imgSentEmail} />
        </>
      )}
      {!submitted && (
        <>
          <H4 mb={4} className={welcomeFormStyles.formTitle} v2>
            {t('Reset your password')}
          </H4>
          <S mt={4} mb={4} className={welcomeFormStyles.formDescription}>
            {t(
              'Tell us the email address associated with your {{shortOperatingName}} account, and we’ll send you an email with a link to reset your password.',
              { shortOperatingName }
            )}
          </S>
          <ForgotPasswordForm
            email={email}
            onChangeEmail={email => setEmail(email)}
            onSubmit={handleSubmit}
            validation={validation}
            error={error}
            loading={loading}
          />
        </>
      )}
    </div>
  );
}
