import React from 'react';

import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import styles from './DaySelector.scss';

type Props = {
  selected?: number[];
  onChange: (days: number[]) => void;
  className?: string;
  daySelectorClass?: string;
  testId?: string;
};

const DAYS = [
  {
    label: 'shared.day.short.sunday',
    value: 6,
  },
  {
    label: 'shared.day.short.monday',
    value: 0,
  },
  {
    label: 'shared.day.short.tuesday',
    value: 1,
  },
  {
    label: 'shared.day.short.wednesday',
    value: 2,
  },
  {
    label: 'shared.day.short.thursday',
    value: 3,
  },
  {
    label: 'shared.day.short.friday',
    value: 4,
  },
  {
    label: 'shared.day.short.saturday',
    value: 5,
  },
];

export function DaySelector({
  selected = [],
  onChange,
  className,
  daySelectorClass,
  testId,
}: Props) {
  const { t } = useTranslation();

  function handleClick(dayValue: number) {
    const dayIndex = selected.indexOf(dayValue);

    if (dayIndex === -1) {
      onChange(selected.concat(dayValue).sort());
    } else {
      onChange(selected.filter(selectedDay => selectedDay !== dayValue));
    }
  }

  return (
    <div
      className={cx(styles.daySelector, daySelectorClass)}
      data-test={testId}
    >
      {DAYS.map((day, index) => {
        return (
          <button
            className={cx(styles.day, className)}
            key={index}
            onClick={() => {
              handleClick(day.value);
            }}
            data-selected={selected.includes(day.value)}
            data-test={`${testId}.day.${day.value}`}
          >
            <span className={styles.label}>{t(day.label)}</span>
          </button>
        );
      })}
    </div>
  );
}
