import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import AppContext from 'lane-shared/contexts/AppContext';
import SignUpContext from 'lane-shared/contexts/SignUpContext';
import UserDataContext from 'lane-shared/contexts/UserDataContext';
import { emitter } from 'lane-shared/helpers';
import * as staleChannelNotificationManager from 'lane-shared/helpers/channel/staleChannelNotification';
import { EVENT_AUTH_TOKEN } from 'lane-shared/helpers/emitter';
import getValidationMessages from 'lane-shared/helpers/getValidationMessages';
import { SESSIONSTORAGE_ESSENSYS_RESERVABLE_VALUE } from 'lane-shared/helpers/integrations/Essensys/getCachedEssensysReservableValue';
import { ContentType } from 'lane-shared/types/content/Content';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';
import { validateEssensysSignup } from 'lane-shared/validation';

import ForgotPassword from 'components/authV2/ForgotPassword';
import Login from 'components/authV2/Login';
import { Input } from 'components/form';
import { Button, ErrorMessage, Loading } from 'components/general';
import { Modal } from 'components/lds';

import WhitelabelTermsAndPolicy from 'pages/user/SignupV2/WhitelabelTermsAndPolicy';

import {
  LOGGED_IN_VIA_LOGIN,
  LOGGED_IN_VIA_SIGNUP,
  MODAL_LOGIN,
} from '../constants';

import styles from './AuthPurchaseWrapper.scss';

type AuthPurchaseWrapperProps = {
  isOpen: boolean;
  onClose: () => void;
  content: ContentType;
  children: React.ReactNode;
  interaction: any;
};

const SIGNUP_TRANSITION_DURATION = 10000;

export default function AuthPurchaseWrapper({
  isOpen,
  onClose,
  content,
  children,
  interaction,
}: AuthPurchaseWrapperProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validation, setValidation] = useState(null);
  const { transition } = useContext(AppContext);

  const [
    showLoginForAuthPurchaseWrapper,
    setShowLoginForAuthPurchaseWrapper,
  ] = useState(false);
  const [
    showForgetPasswordForAuthPurchaseWrapper,
    setShowForgetPasswordForAuthPurchaseWrapper,
  ] = useState(false);
  const { user } = useContext(UserDataContext);

  const { t } = useTranslation();
  const {
    name,
    password,
    email,
    companyName,
    updateSignUp,
    doSignUp,
    metadata,
  } = useContext(SignUpContext);
  const { whitelabel } = useContext(AppContext);

  async function validate() {
    try {
      await validateEssensysSignup.validate({
        name,
        email,
        password,
        companyName,
      });
      setValidation(null);
    } catch (validation) {
      setValidation(validation);
    }
  }

  useEffect(() => {
    validate();
  }, [email, name, password, companyName]);

  useEffect(() => {
    updateSignUp({
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<ChannelType>' is not assignable to t... Remove this comment to see the full error message
      company: content.channel,
      metadata: {
        ...metadata,
        contentId: content._id,
      },
    });
  }, [content?._id, content?.channel?._id]);

  async function createAccount() {
    setLoading(true);

    try {
      const { jti, token, warnings } = await doSignUp();

      // Show the stale notification message
      staleChannelNotificationManager.show();
      // Regarding to TM-3477
      // storing any pre-selected reservation time in sessionStorage
      // in this way, after page reloads, user doesn't need to re-select the time slot again
      sessionStorage.setItem(
        SESSIONSTORAGE_ESSENSYS_RESERVABLE_VALUE,
        JSON.stringify(interaction.features[FeatureNameEnum.Reservable])
      );
      sessionStorage.setItem(MODAL_LOGIN, LOGGED_IN_VIA_SIGNUP);
      emitter.emit(EVENT_AUTH_TOKEN, {
        authToken: { jti, token },
        warnings,
      });
      window.Toast.show(
        <>
          <p>Creating your Account.</p>
          <p>This might take a moment</p>
        </>,
        SIGNUP_TRANSITION_DURATION
      );
      transition(SIGNUP_TRANSITION_DURATION, () => {
        window.location.reload();
      });
    } catch (submitError) {
      setError(submitError);
      sessionStorage.removeItem(MODAL_LOGIN);
    }

    setLoading(false);
  }

  function storePrevInteractionData() {
    // Show the stale notification message
    staleChannelNotificationManager.show();

    // Same usage as the above for createAccount
    sessionStorage.setItem(
      SESSIONSTORAGE_ESSENSYS_RESERVABLE_VALUE,
      JSON.stringify(interaction.features[FeatureNameEnum.Reservable])
    );
  }

  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  async function loginSuccess(authToken: any) {
    onClose();
    sessionStorage.setItem(MODAL_LOGIN, LOGGED_IN_VIA_LOGIN);
    storePrevInteractionData();
    transition();

    if (authToken) {
      return () => authToken;
    }
  }

  if (user) {
    return <>{children}</>;
  }

  function toggleShowLoginForAuthPurchaseWrapper() {
    setShowLoginForAuthPurchaseWrapper(!showLoginForAuthPurchaseWrapper);
  }

  function displayForgetPasswordForAuthPurchaseWrapper() {
    setShowForgetPasswordForAuthPurchaseWrapper(true);
  }

  function modalContent() {
    if (showForgetPasswordForAuthPurchaseWrapper) {
      return <ForgotPassword isModal />;
    }

    if (showLoginForAuthPurchaseWrapper) {
      return (
        // @ts-expect-error ts-migrate(2741) FIXME: Property 'navigateToMagicLink' is missing in type ... Remove this comment to see the full error message
        <Login
          header="Log in"
          onLogin={loginSuccess}
          isAuthPurchaseWrapper={showLoginForAuthPurchaseWrapper}
          navigateToSignUp={toggleShowLoginForAuthPurchaseWrapper}
          displayForgetPasswordForAuthPurchaseWrapper={
            displayForgetPasswordForAuthPurchaseWrapper
          }
        />
      );
    }

    return (
      <div className={styles.authPurchaseWrapper}>
        <Input
          type="text"
          label={t('Full Name')}
          onChange={name =>
            updateSignUp({
              name,
            })
          }
          value={name}
          error={getValidationMessages(validation, 'name')}
        />
        <Input
          onChange={email =>
            updateSignUp({
              email,
              metadata: {
                ...metadata,
                email,
              },
            })
          }
          value={email}
          type="email"
          label={t('Enter your email')}
          error={getValidationMessages(validation, 'email')}
        />

        <Input
          onChange={password =>
            updateSignUp({
              password,
            })
          }
          type="password"
          value={password}
          label={t('Choose a password')}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'false | string[] | null' is not assignable t... Remove this comment to see the full error message
          error={
            Boolean(password) && getValidationMessages(validation, 'password')
          }
        />
        <Input
          type="text"
          label={t('Company Name')}
          onChange={companyName =>
            updateSignUp({
              companyName,
              metadata: {
                ...metadata,
                companyName,
              },
            })
          }
          value={companyName}
          error={getValidationMessages(validation, 'companyName')}
        />
        <WhitelabelTermsAndPolicy whitelabel={whitelabel} />
        {loading && <Loading />}
        <div>
          {t('Already have an account? ')}
          <span
            className={styles.login}
            onClick={toggleShowLoginForAuthPurchaseWrapper}
            role="link"
            tabIndex={0}
          >
            {t('Log In')}
          </span>
        </div>
        <ErrorMessage error={error} />
      </div>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="small"
      title={
        !showLoginForAuthPurchaseWrapper &&
        !showForgetPasswordForAuthPurchaseWrapper
          ? t('This interaction requires you to be a user')
          : ''
      }
      actions={
        !showLoginForAuthPurchaseWrapper &&
        !showForgetPasswordForAuthPurchaseWrapper && (
          <Button
            disabled={loading || Boolean(validation)}
            fullWidth
            variant="contained"
            onClick={createAccount}
          >
            {t('Create Account')}
          </Button>
        )
      }
    >
      {modalContent()}
    </Modal>
  );
}
