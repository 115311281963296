import { useState } from 'react';

import { ErrorCodeType } from 'activate-errors';
import { getErrorCodes, getErrorMessagesFromCodes } from 'lane-shared/helpers';

type Props = {
  errorCodeTypes: { [key: string]: ErrorCodeType };
  errorCodeToFormFieldMap: { [key: string]: string };
  show?: (message: string) => void;
  defaultErrorMessage?: string;
};

/**
 * extracts user friendly error messages into a key value pair of form field -> error messages using the error codes
 */
export const useFormServerErrorHandler = ({
  errorCodeTypes,
  errorCodeToFormFieldMap,
  show = () => {},
  defaultErrorMessage = '',
}: Props) => {
  const [serverFormFieldErrors, setServerFormFieldErrors] = useState<{
    [key: string]: string;
  }>({});

  const resetServerFormFieldErrors = () => {
    setServerFormFieldErrors({});
  };

  const handleServerError = (error: any) => {
    const codes = getErrorCodes(error);

    if (!codes.length && defaultErrorMessage) {
      show(defaultErrorMessage);

      return;
    }

    if (!codes.length) return;

    const fieldToErrorCodesMap: { [key: string]: string[] } = {};

    // error codes with no matching form field
    const errorCodesNoField: string[] = [];

    codes.forEach((code: string) => {
      if (errorCodeToFormFieldMap[code]) {
        const fieldName = errorCodeToFormFieldMap[code];

        fieldToErrorCodesMap[fieldName] = fieldToErrorCodesMap[fieldName]
          ? [...fieldToErrorCodesMap[fieldName], code]
          : [code];
      } else {
        errorCodesNoField.push(code);
      }
    });

    const fieldToErrorMessageMap: { [key: string]: string } = {};

    // get error messages for each form field using error codes
    Object.keys(fieldToErrorCodesMap).forEach(field => {
      const fieldErrorCodes = fieldToErrorCodesMap[field];
      const errorMessages = getErrorMessagesFromCodes(
        fieldErrorCodes,
        errorCodeTypes
      );

      if (errorMessages) {
        fieldToErrorMessageMap[field] = errorMessages;
      }
    });

    setServerFormFieldErrors(fieldToErrorMessageMap);

    // handle errors not linked to any form field
    if (errorCodesNoField.length) {
      const errorMessage = getErrorMessagesFromCodes(
        codes,
        errorCodeTypes,
        defaultErrorMessage
      );

      show(errorMessage);
    }
  };

  return {
    serverFormFieldErrors,
    resetServerFormFieldErrors,
    handleServerError,
  };
};
