/* istanbul ignore file */
import * as yup from 'yup';
import { ValidationError } from 'yup';

import config from 'lane-shared/config';
import getVisibleContentData from 'lane-shared/helpers/content/getVisibleContentData';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';

import { constructInteraction } from '../../helpers/content';
import { isFeatureVisible } from '../../helpers/features';
import { createShapeFromProperties } from '../../properties';
import {
  SecurityModesEnum,
  SecurityRuleTypeEnum,
} from '../../types/properties/PropertySecurity';
import { PropertiesInterfaceDependencies } from '../../types/properties/propertyInterfaceOptions/propertiesInterfaceDependencies';
import Features from './features';

type ValidateInteractionOptions = {
  content: any;
  interaction: ReturnType<typeof constructInteraction>;
  security: {
    mode: SecurityModesEnum;
    type: SecurityRuleTypeEnum;
  };
};

type GetInteractionValidationSchemaOptions = {
  content: any;
  security: {
    mode: SecurityModesEnum;
    type: SecurityRuleTypeEnum;
  };
};

export type InteractionValidationSchema = ReturnType<typeof yup.object>;

export function getInteractionValidationSchema({
  content,
  security,
}: GetInteractionValidationSchemaOptions):
  | InteractionValidationSchema
  | undefined {
  if (!content) {
    return undefined;
  }

  const visibleProperties = getVisibleContentData(content);
  let hasFeatures = false;

  const filteredData = visibleProperties?.data
    ? visibleProperties?.data.reduce((data, key) => {
        if (content.data[key]) {
          data[key] = content.data[key];
        }

        return data;
      }, {} as any)
    : content.data;

  let data = yup
    .object()
    .shape(createShapeFromProperties(filteredData, security))
    .default(undefined);

  if (security?.mode !== SecurityModesEnum.Update) {
    data = data.required();
  }

  const featuresShape: Record<any, any> = {};

  if (content.features) {
    const filteredFeatures = visibleProperties?.features
      ? content.features.filter(({ type }: { type: FeatureNameEnum }) =>
          isFeatureVisible(visibleProperties.features, type)
        )
      : content.features;

    filteredFeatures.forEach((feature: any) => {
      const Feature = (Features as any)[feature.type];

      if (!Feature) {
        throw new Error(`Unknown feature name="${feature.type}"`);
      }

      const interactionData = Feature.getInteractionData
        ? Feature.getInteractionData(feature)
        : Feature.interactionData;
      // if this is an update, only validate features that have been updated.

      if (interactionData) {
        const featureShape = createShapeFromProperties(
          interactionData,
          security
        );

        featuresShape[Feature.name] =
          security?.mode === SecurityModesEnum.Update
            ? yup.object().shape(featureShape)
            : yup.object().shape(featureShape).required();

        hasFeatures = true;
      }
    });
  }

  let features: any;

  if (hasFeatures) {
    // if this is for an update, not all features may be required.
    if (security?.mode === SecurityModesEnum.Update) {
      features = yup.object().shape(featuresShape);
    } else {
      features = yup.object().shape(featuresShape).required();
    }
  }

  return yup.object().shape({
    data,
    features,
  });
}

export default async function validateInteraction({
  content,
  interaction,
  security,
}: ValidateInteractionOptions) {
  const schema = getInteractionValidationSchema({
    content,
    security,
  });

  if (!schema) {
    return false;
  }

  try {
    await schema.validate(interaction, { abortEarly: false });
  } catch (error) {
    if (error instanceof ValidationError) {
      // eslint-disable-next-line no-unused-expressions
      error.inner?.sort((a, b) => a.path.length - b.path.length);
    }

    throw error;
  }

  if (config.enableConditionalWorkorder) {
    const dependencies = PropertiesInterfaceDependencies.fromJsonData(
      content.data,
      content.propertiesOptions?.dependencies || []
    );

    if (!dependencies.isSatisfied(interaction.data)) {
      throw new Error('Data dependency is not satisfied');
    }
  }

  return true;
}
