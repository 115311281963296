import { useEffect, useContext } from 'react';

import { GoogleRecaptchaContext } from 'components/misc/GoogleRecaptchaProvider';

export default function useGoogleRecaptcha() {
  /** Recaptcha badge is invisible by default but let's make it
   *  visible whenever we use the recaptcha api */
  useEffect(() => {
    const id = 'recaptcha-badge';
    const recaptchaBadgeStyle = document.getElementById(id);

    if (!recaptchaBadgeStyle) {
      const style = document.createElement('style');

      style.id = id;
      style.innerText = `.grecaptcha-badge { visibility: visible; }</style>`;
      document.head.appendChild(style);
    }

    return () => document.getElementById(id)?.remove();
  }, []);

  return useContext(GoogleRecaptchaContext);
}
