import { ApolloQueryResult, useQuery } from '@apollo/client';

import {
  getInvitesByChannel,
  GetInvitesByChannelResponse,
} from 'lane-shared/graphql/query/getInvitesByChannel';

import {
  queryReformedChannel,
  ReformedChannelResponse,
} from 'lane-shared/graphql/query/getReformedChannel';

import type { PendingInvitesCountResponse } from 'lane-shared/graphql/query/getPendingInvitesCount';

import { ReformedChannelInvites } from '../view/PendingInvites';

/**
 * @description (to be refactored) these are explicit violation of module boundary rule to assert types for compliance with gql schema
 */
import { GroupRole as GroupRoleGraphType } from 'lane-shared/types/GroupRole';

export const usePendingInvites = (channelId: string, groupRoleId?: string) => {
  const {
    data: invites,
    loading,
    refetch,
  } = useQuery<GetInvitesByChannelResponse>(getInvitesByChannel, {
    variables: {
      channelId,
      ...(groupRoleId && { search: { groupRole: { _id: groupRoleId } } }),
      skipPagination: true,
    },
    fetchPolicy: 'network-only',
  });

  const fetchedInvites = invites ? invites.invitesOnChannel.items : [];

  /**
   * @description (to be refactored) this modification needs to happen here beacuse the underscore field name drift in GroupRoles[] field's GroupRole type. Resolver needs to remain untouched to suppport the new page which is correcrtly implemented. Below is an incorrect information.
   */
  const modifiedInvites = fetchedInvites.map(invite => ({
    ...invite,
    /**
     * @description this field is a fake field existing merely to support the new graph type. Do not use this information
     */
    groupRoles: invite.groupRoles.map(groupRole => {
      return {
        ...groupRole,
        _created: '',
        description: '',
        permissions: [],
        stats: '',
        externalId: groupRole._id,
        externalType: '',
        isSystem: false,
        _createdBy: invite._createdBy._id,
        _updated: '',
        _updatedBy: invite._updatedBy._id,
        isPublic: false,
      } as GroupRoleGraphType;
    }),
  }));

  return {
    invites: {
      invitesOnChannel: {
        items: modifiedInvites,
      },
    },
    loading,
    refetch,
  };
};

export const useNewPendingInvites = (
  channelId: string,
  groupRoleId?: string,
  refetchCount?: () => Promise<ApolloQueryResult<PendingInvitesCountResponse>>
) => {
  const {
    data: queryData,
    loading,
    refetch,
  } = useQuery<ReformedChannelResponse>(queryReformedChannel, {
    variables: {
      channelId,
    },
    onCompleted: async () => {
      if (refetchCount) {
        await refetchCount();
      }
    },
  });

  let fetchedInvites =
    queryData && queryData.reformedChannel?.channelInvites
      ? queryData.reformedChannel.channelInvites
      : [];

  if (groupRoleId) {
    fetchedInvites = fetchedInvites.filter(invite => {
      for (const item of invite.groupRoles) {
        if (item._id === groupRoleId) {
          return true;
        }
      }

      return false;
    });
  }

  const modifiedInvites = (fetchedInvites.map(invite => ({
    _id: invite._id,
    _created: invite._created,
    _updated: invite._updated,
    _createdBy: invite._createdBy._id,
    _updatedBy: invite._updatedBy._id,
    result: invite.result,
    name: invite.name,
    expires: invite.expires,
    groupRole: (invite.groupRole as unknown) as GroupRoleGraphType,
    email: invite.email,
    sent: invite.sent,
    status: invite.status,
    company: invite.company,
    groupRoles: invite.groupRoles.map(groupRole => {
      return {
        ...groupRole,
        description: '',
        stats: '',
        _created: '',
        _createdBy: invite._createdBy._id,
        _updated: '',
        _updatedBy: invite._updatedBy._id,
        isPublic: false,
        isSystem: false,
      };
    }),
  })) as unknown) as ReformedChannelInvites[];

  return {
    channelInvites: modifiedInvites,
    loading,
    refetch,
  };
};
