import React, { useState } from 'react';

import cx from 'classnames';
import { PDFDocumentProxy } from 'pdfjs-dist';

import checkerboardImage from 'static/img/checkerboard.png';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

import styles from './PDFPreview.scss';

// To avoid performance issues, we limit the number of PDF pages rendered to 100
// for phase 1 of this epic.
const MAX_NUM_PDF_PAGES_TO_RENDER = 100;

export const PDFPreview = ({
  media,
  error,
  loading,
}: {
  media?: {
    url?: string;
  };
  error?: React.ReactElement | string;
  loading?: React.ReactElement | string;
}) => {
  const [pdfNumPages, setPdfNumPages] = useState<number>(1);

  const eachPage = (pageNumber: number) => (
    <Page
      renderAnnotationLayer={false}
      renderTextLayer={false}
      key={`page_${pageNumber}`}
      pageNumber={pageNumber}
      canvasRef={canvRef => {
        if (!canvRef) return;

        setTimeout(() => {
          canvRef.style.width = '100%';
          canvRef.style.height = '100%';
        });
      }}
    />
  );
  const pageDivider = <div className={styles.pageDivider} />;

  function onDocumentLoadSuccess({ numPages }: PDFDocumentProxy) {
    setPdfNumPages(numPages);
  }

  return (
    <Document
      file={media}
      onLoadSuccess={onDocumentLoadSuccess}
      className={styles.PDFPreview}
      onLoadError={e => {
        console.error(e);
      }}
      error={error}
      loading={loading}
    >
      <section
        className={cx(styles.media, styles.documentSection)}
        style={{ backgroundImage: `url(${checkerboardImage})` }}
        onDoubleClick={() => window.open(media?.url, '_blank')}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        {Array(Math.min(pdfNumPages, MAX_NUM_PDF_PAGES_TO_RENDER))
          .fill(null)
          .map((_, index) => (
            <div key={`page_${index + 1}`}>
              {eachPage(index + 1)}
              {index + 1 !== pdfNumPages && pageDivider}
            </div>
          ))}
      </section>
    </Document>
  );
};
