import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'design-system-web';
import { AddPaymentOptionButton } from 'lane-web/src/components/features/Payment/PaymentSummaryV2/PaymentOptions/AddPaymentOptionButton';
import { AddStripeCard } from 'lane-web/src/components/features/Payment/PaymentSummaryV2/PaymentOptions/AddStripeCard';
import { PaymentOptionsList } from 'lane-web/src/components/features/Payment/PaymentSummaryV2/PaymentOptions/PaymentOptionsList';
import {
  InvoiceAutopaymentDetails,
  PaymentSource,
} from 'graphql-query-contracts';
import styles from '../PaymentOptions.scss';

interface Props {
  updatePaymentMethod: (id: string) => void;
  createUser: () => Promise<string>;
  savePaymentMethod: (paymentMethodId: string) => Promise<void>;
  removePaymentMethod: (paymentMethodId: string) => Promise<void>;
  savedPaymentMethods: PaymentSource[];
  removeAutopaymentMethod: (paymentMethodId: string) => Promise<void>;
  invoiceAutopayment?: InvoiceAutopaymentDetails;
  selectedPaymentMethod: string | undefined;
  isWallet?: boolean;
  loading: boolean;
  onSubmit: () => void;
}
export function CreditCardPaymentOptions({
  updatePaymentMethod,
  createUser,
  savePaymentMethod,
  savedPaymentMethods,
  selectedPaymentMethod,
  removeAutopaymentMethod,
  removePaymentMethod,
  invoiceAutopayment,
  isWallet,
  onSubmit,
  loading,
}: Props) {
  const { t } = useTranslation();
  const [showStripeCardForm, setShowStripeCardForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [cards, setCards] = useState<PaymentSource[]>(savedPaymentMethods);
  const handleClickAddPayment = async () => {
    if (await createUser()) setShowStripeCardForm(true);
  };

  const handleSubmitStripeCard = () => {
    setFormSubmitted(false);
    setShowStripeCardForm(false);
  };

  const handleGoBack = () => {
    setShowStripeCardForm(false);
  };

  const handleRemovePayment = async (paymentMethodId: string) => {
    try {
      const isAutopaymentMethod =
        invoiceAutopayment?.paymentSource?.paymentMethodId === paymentMethodId;

      await window.Alert.confirm(
        getCopyForRemovePayment(t, isAutopaymentMethod)
      );

      if (isAutopaymentMethod) await removeAutopaymentMethod(paymentMethodId);

      await removePaymentMethod(paymentMethodId);
    } catch (err) {
      if (err === false) {
        // user cancelled.
        return;
      }

      const FIVE_SECONDS = 5000;

      window.Toast.show(err.message, FIVE_SECONDS);
    }
  };

  useEffect(() => {
    setCards(savedPaymentMethods);
  }, [savedPaymentMethods]);

  return (
    <>
      {!showStripeCardForm && (
        <PaymentOptionsList
          cards={cards}
          updatePaymentMethod={updatePaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
          onRemovePaymentMethod={handleRemovePayment}
          isWallet={isWallet}
          handleClickAddPayment={handleClickAddPayment}
        />
      )}

      {!showStripeCardForm && !formSubmitted && !cards.length && (
        <AddPaymentOptionButton onClick={handleClickAddPayment} />
      )}

      {showStripeCardForm && !formSubmitted && (
        <AddStripeCard
          handleSubmit={handleSubmitStripeCard}
          goBack={handleGoBack}
          cards={cards}
          updateCards={setCards}
          updatePaymentMethod={updatePaymentMethod}
          savePaymentMethod={savePaymentMethod}
          isWallet={isWallet}
        />
      )}
      {selectedPaymentMethod && !showStripeCardForm && (
        <Button
          className={styles.purchaseButton}
          fullWidth
          disabled={!selectedPaymentMethod}
          onClick={onSubmit}
          loading={loading}
          testId="purchaseButton"
        >
          {t('abp.payment.orderSummary.purchase')}
        </Button>
      )}
    </>
  );
}

function getCopyForRemovePayment(t: any, withAutopayment: boolean) {
  return withAutopayment
    ? {
        title: t(
          'abp.routes.accountInvoices.autopayment.modal.removePaymentAndAutopaymentTitle'
        ),
        message: t(
          'abp.routes.accountInvoices.autopayment.modal.removePaymentAndAutopayment'
        ),
      }
    : {
        title: t('abp.wallet.removeCardTitle'),
        message: t('abp.wallet.removeCardMessage'),
      };
}
