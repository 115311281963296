import React from 'react';

import cx from 'classnames';

import { LaneType } from 'common-types';
import { longDate } from 'lane-shared/helpers/formatters';

import PublicUserLink from './PublicUserLink';

import styles from './CreatedBy.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  object: {
    _createdBy: {
      _id: LaneType.UUID;
    };
    _updatedBy: {
      _id: LaneType.UUID;
    };
    _created: string | Date;
    _updated: string | Date;
  };
  forAdmin?: boolean;
};

export default function CreatedBy({
  className,
  style,
  object,
  forAdmin,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.CreatedBy, className)} style={style}>
      <p>
        <span>
          {t('Created')} {longDate(object?._created)}
        </span>
        <PublicUserLink
          userId={(object?._createdBy as any)?._id}
          forAdmin={forAdmin}
        />
      </p>
      <p>
        <span>
          {t('Updated')} {longDate(object?._updated)}
        </span>
        <PublicUserLink
          userId={(object?._updatedBy as any)?._id}
          forAdmin={forAdmin}
        />
      </p>
    </div>
  );
}
