import React from 'react';

import { AdminPage, TabStrip } from 'components';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';

import {
  ChannelType,
  ChannelExperienceTypeEnum,
} from 'lane-shared/types/ChannelType';

import { H3 } from 'lane-web/src/components/typography';

import {
  useAllUsers,
  useCompaniesForChannel,
  usePendingInvitesCount,
} from './hooks';
import { AllUsersView, PendingInvitesView } from './view/index';

import styles from './styles.scss';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

type Props = {
  channel: ChannelType;
};

export enum UsersPageTabEnum {
  TAB_ACTIVE = 'active',
  TAB_Pending_Invites = 'pending-invites',
}

export function UserManagementUsersPage({ channel }: Props) {
  const isAddManualResidentsEnabled = useFlag(
    FeatureFlag.AddManualResidents,
    false
  );

  const isMFExperience =
    channel.experienceType === ChannelExperienceTypeEnum.multifamily;

  const shouldIncludeResidentInfo =
    isAddManualResidentsEnabled && isMFExperience;

  const { t } = useTranslation();

  const [
    {
      tab,
      company,
      keyword,
      groupRoles: groupRolesSearch,
      sortBy,
      sortDirection,
      page,
      pageSize,
    },
    goToUrl,
  ] = useQueryString<{ [x: string]: string }>({
    tab: UsersPageTabEnum.TAB_ACTIVE,
  });

  const { companyOptions, isLoadingCompanies } = useCompaniesForChannel(
    channel._id
  );

  const {
    users,
    total,
    groupRoles,
    loading: usersLoading,
    refetch,
  } = useAllUsers(
    channel._id,
    shouldIncludeResidentInfo,
    {
      company,
      keyword,
      groupRoles: groupRolesSearch,
      sortBy,
      sortDirection,
    },
    {
      page: page ?? '0',
      pageSize: pageSize ?? '25',
    }
  );

  const {
    count: pendingInvitesCount,
    refetch: refetchCount,
  } = usePendingInvitesCount(channel?._id);

  const tabs = [
    {
      label: t(
        'web.admin.channel.userManagement.users.view.tabActivity.active'
      ),
      value: UsersPageTabEnum.TAB_ACTIVE,
    },
    {
      label: t(
        'web.admin.channel.userManagement.users.view.tabActivity.pendingInvites',
        { pendingInvitesCount }
      ),
      value: UsersPageTabEnum.TAB_Pending_Invites,
    },
  ];

  const selectedTab = tab || UsersPageTabEnum.TAB_ACTIVE;

  const renderTables = () => {
    if (selectedTab === UsersPageTabEnum.TAB_ACTIVE) {
      return (
        <AllUsersView
          usersData={users}
          totalUsers={total}
          loading={usersLoading || isLoadingCompanies}
          groupRoles={groupRoles?.channel?.groupRoles}
          companies={companyOptions}
          channelId={channel._id}
          channelSlug={channel.slug}
          refetch={refetch}
          isMFExperience={isMFExperience}
        />
      );
    }

    return (
      <PendingInvitesView
        refetchCount={refetchCount}
        channelId={channel._id}
        groupRoles={groupRoles?.channel?.groupRoles.map(
          (g: { name: string }) => g.name
        )}
        channelSlug={channel?.slug || ''}
        isMFExperience={isMFExperience}
      />
    );
  };

  return (
    <AdminPage className={styles.adminPage}>
      <div className={styles.channelUsers}>
        <div className={styles.header}>
          <div className={styles.subheader}>
            <H3>{t('web.pages.portal.admin.users.index.header')}</H3>
          </div>
          <TabStrip
            tabs={tabs}
            selected={tabs.find(tab => tab.value === selectedTab)}
            onSelectTab={tab => goToUrl({ tab: tab.value })}
            className={styles.tabStrip}
            skipLabelTranslation
            fullWidth
          />
          {renderTables()}
        </div>
      </div>
    </AdminPage>
  );
}
