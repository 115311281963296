/**
 * Checks if a given rich text field json is empty
 * @param richTextJSON
 * @returns true if empty
 */
export function isEmptyRichTextField(richTextJSON: any): boolean {
  for (const child of richTextJSON) {
    if (child.children) {
      return isEmptyRichTextField(child.children);
    }

    return child.text.length === 0;
  }

  return true;
}
