import { LocationType } from '../types/LocationType';

export default function mapGeolocationPositionToLocationType(
  // eslint-disable-next-line no-undef
  position: GeolocationPosition
): Partial<LocationType> {
  const { coords } = position;

  return {
    latitude: coords.latitude,
    longitude: coords.longitude,
  };
}
