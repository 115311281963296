import React, { useState, useContext, useEffect, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import * as Sentry from '@sentry/browser';

import { ThemeContext } from 'lane-shared/contexts';
import { FORGOT_EMAIL_URL } from 'lane-shared/helpers/constants/integrations/BuildingEnginesClassic';
import * as BuildingEnginesClassic from 'lane-shared/helpers/integrations/BuildingEnginesClassic';
import {
  getToken,
  storeToken,
} from 'lane-shared/helpers/integrations/BuildingEnginesClassic/handleToken';
import { ContentType } from 'lane-shared/types/content/Content';

import { history } from '../../../helpers';
import ValidatedInput from '../../form/ValidatedInput';
import { Button, ErrorMessage, Loading } from '../../general';

import styles from './Login.scss';

interface LoginProps {
  content: ContentType;
  children?: ReactNode;
}

const emailValidator = yup.string().email().nullable().required();

export default function Login({ content, children }: LoginProps) {
  const { settings } = content.integration;
  const [storedToken, setStoredToken] = useState<undefined | any>(undefined);

  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const [error, setError] = useState('');
  const [validation, setValidation] = useState(null);

  function validate() {
    try {
      emailValidator.validateSync(email);
      setValidation(null);
    } catch (err) {
      setValidation(err);
    }
  }

  async function login() {
    setLoginLoading(true);
    setError('');

    try {
      const response = await BuildingEnginesClassic.requestHelpers.login(
        content.integration.settings.baseUrl,
        email
      );

      const { tenx_token, user_token } = await response.json();

      if (!(tenx_token && user_token)) {
        setLoginLoading(false);
        setError(t('You’ve entered an invalid email.'));

        return;
      }

      const tokens = { user: user_token, tenx: tenx_token };

      await storeToken(tokens);
      setStoredToken(tokens);

      if (children) {
        setLoginLoading(false);
      } else {
        history.goBack();
      }
    } catch (err) {
      setLoginLoading(false);
      Sentry.captureException(err, {
        contexts: {
          extraData: { contentName: content.name },
        },
      });

      if (err.message) {
        setError(err);
      } else {
        window.Alert.alert({
          title: t('Whoops something went wrong!'),
          message: t('Please try again later.'),
        });
      }
    }
  }

  useEffect(() => {
    async function tokenHandler() {
      const token = await getToken();

      setStoredToken(token);
    }

    tokenHandler();
  }, []);

  useEffect(() => {
    validate();
  }, [email]);

  if (storedToken?.user && children) {
    return <>{children}</>;
  }

  if (storedToken === undefined) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <h2 style={{ color: theme.primary }}>{settings.name}</h2>
      <p>{settings.description}</p>
      <ValidatedInput
        type="email"
        placeholder={t('Enter your {{name}} email', { name: settings.name })}
        onChange={value => setEmail(value)}
        validation={emailValidator}
        isPristine={!email}
        value={email}
        icon="mail"
        className={styles.inputEmail}
      />
      {error && <ErrorMessage error={error} />}
      <a href={FORGOT_EMAIL_URL} target="_blank" rel="noopener noreferrer">
        {t('Forgot email?')}
      </a>
      <Button
        disabled={loginLoading || Boolean(validation)}
        className={styles.button}
        variant="contained"
        onClick={login}
        loading={loginLoading}
        testId="BEClassicSubmitButton"
      >
        {t('Submit')}
      </Button>
      {settings.supportEmail && (
        <p>
          {t('Don’t have a {{name}} account?', { name: settings.name })}{' '}
          <a href={`mailto:${settings.supportEmail}`}>
            {t('Contact your administrator.')}
          </a>
        </p>
      )}
    </div>
  );
}
