import React from 'react';

import { useTranslation } from 'react-i18next';

import { invalidStatuses } from 'lane-shared/domains/visitorManagement/constants';
import {
  EventStatus,
  VisitorPassQueryResponse,
} from 'lane-shared/domains/visitorManagement/types';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import Pill from 'components/general/Pill';

import { StatusListItems } from '../../types/EventStatus';

import styles from './VisitorManagementStatusChip.scss';

type VisitorManagementServiceData = {
  visitorPassesBySubmissionId: {
    items: VisitorPassQueryResponse[];
  };
};

export function VisitorManagementStatusChip({
  visitorManagementServiceData: data,
  pillClassname = '',
}: {
  visitorManagementServiceData: VisitorManagementServiceData | undefined;
  pillClassname?: string;
}) {
  const { t } = useTranslation();
  const visitorManagementFlag = useFlag(FeatureFlag.VisitorManagement, false);

  if (!visitorManagementFlag) return null;

  if (!data) return null;

  const isEmpty = data.visitorPassesBySubmissionId.items.length === 0;

  const allPassesCancelled =
    !isEmpty &&
    data.visitorPassesBySubmissionId.items.every(pass =>
      invalidStatuses.includes(pass.status as EventStatus)
    );

  if (!allPassesCancelled) return null;

  const cancelledStatus = StatusListItems.find(
    st => st.value === EventStatus.EVENT_STATUS_CANCELLED
  ) || { label: 'web.admin.channel.visitor.log.status.cancelled' };

  return (
    <div className={pillClassname}>
      <Pill
        text={t(cancelledStatus.label)}
        color="red"
        size="medium"
        className={styles.cancelled}
      />
    </div>
  );
}
