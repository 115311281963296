import { LaneType } from 'common-types';
import {
  ContentWorkflowType,
  DataValidationRuleType,
} from 'lane-shared/types/ContentWorkflowType';

export default function getConditionalWorkflows(
  actions: ContentWorkflowType[],
  option: LaneType.Option
): ContentWorkflowType[] {
  if (!option) {
    return [];
  }

  const optionWorkflows = actions.filter(workflow => {
    const workflowValidationSchema: DataValidationRuleType[] =
      workflow?.dataValidationSchema || [];
    const workflowHasOption: boolean = workflowValidationSchema.some(schema => {
      if (Array.isArray(schema.validator.value)) {
        return schema.validator.value.some(
          validatorOption => validatorOption._id === option._id
        );
      }

      return false;
    });

    return workflowHasOption;
  });

  return optionWorkflows;
}
