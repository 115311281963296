import React from 'react';

import { Key } from 'ts-key-enum';

import byName from 'lane-shared/helpers/sort/byName';
import { ContentType } from 'lane-shared/types/content/Content';

import MiniContentCard from 'components/cards/MiniContentCard';

import styles from './VisitorFormsSelector.scss';

type Props = {
  forms: ContentType[];
  onFormSelected: (form: ContentType) => void;
  selectedForm?: ContentType;
};

export function VisitorFormsSelector({
  forms,
  onFormSelected,
  selectedForm,
}: Props) {
  return (
    <div className={styles.formsContainer}>
      {forms
        .slice()
        .sort((a: ContentType, b: ContentType) => byName(a, b))
        .map((form: ContentType) => {
          const isSelectedForm = form === selectedForm;

          return (
            <div
              key={form._id}
              onClick={() => onFormSelected(form)}
              className={styles.contentCard}
              role="button"
              tabIndex={0}
              onKeyPress={e => e.key === Key.Enter && onFormSelected(form)}
            >
              <MiniContentCard isSelected={isSelectedForm} content={form} />
            </div>
          );
        })}
    </div>
  );
}
