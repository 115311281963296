import React, { useState, useRef } from 'react';

import { Key } from 'ts-key-enum';

import fileReaderResolver, {
  FileReturnType,
  FileReturnTypeEnum,
} from 'helpers/fileReaderResolver';

import styles from './FileInput.scss';

type OwnProps = {
  accept: string;
  type: FileReturnTypeEnum;
  loading?: boolean;
  disabled?: boolean;
  enableMultiUpload?: boolean;
  onFilesSelected?: (files: FileReturnType[]) => void;
  onFileSelected?: (file: FileReturnType, name: string) => void;
  children: any;
  testId?: string;
};

type Props = OwnProps;

export default function FileInput({
  accept = 'image/*',
  type = FileReturnTypeEnum.Image,
  disabled = false,
  enableMultiUpload = false,
  onFileSelected = () => null,
  onFilesSelected = () => null,
  children,
  testId = 'fileInput',
}: Props) {
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const inputRef = useRef(null);

  function fileSelected(file: FileReturnType, name: string) {
    setLoading(false);
    onFileSelected(file, name);
    reset();
  }

  function filesSelected(files: FileReturnType[]) {
    setLoading(false);
    onFilesSelected(files);
    reset();
  }

  function reset() {
    if (formRef.current) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      formRef.current.reset();
    }

    setLoading(false);
  }

  async function resolveFile(file: File) {
    setLoading(true);

    try {
      const resolvedFile = await fileReaderResolver(file, type);

      fileSelected(resolvedFile, file.name);
    } catch (err) {
      reset();
    }
  }

  async function resolveFiles(files: File[]) {
    setLoading(true);

    try {
      const resolved = await Promise.all(
        files.map(file => fileReaderResolver(file, type))
      );

      filesSelected(resolved);
    } catch (err) {
      reset();
    }
  }

  function onChange() {
    const fileInput = inputRef.current;

    // Clear out the data.
    if (!((fileInput as any)?.files && (fileInput as any)?.files[0])) {
      reset();

      return;
    }

    const files = Array.from((fileInput as any).files) as File[];

    if (!enableMultiUpload) {
      const file = files[0];

      if (type === 'file') {
        fileSelected(file, file.name);

        return;
      }

      resolveFile(file);

      return;
    }

    if (type === 'file') {
      filesSelected(files);

      return;
    }

    resolveFiles(files);
  }

  return (
    <div className={styles.FileInput}>
      <form
        ref={formRef}
        role="button"
        onKeyPress={e => {
          if (e.key === Key.Enter) {
            e.stopPropagation();

            if (inputRef.current) {
              // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
              inputRef.current.click();
            }
          }
        }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          if (inputRef.current) {
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            inputRef.current.click();
          }
        }}
      >
        <input
          disabled={disabled || loading}
          ref={inputRef}
          accept={accept}
          type="file"
          onClick={e => e.stopPropagation()}
          onChange={() => onChange()}
          multiple={enableMultiUpload}
          data-test={testId ?? 'fileInput'}
        />
        {children}
      </form>
    </div>
  );
}
