import { useEffect, useState, useContext } from 'react';

import { LazyQueryResult, OperationVariables, useQuery } from '@apollo/client';

import { AnalyticsContext } from 'lane-shared/contexts';
import jwtDecode from 'jwt-decode';

import { LaneType } from 'common-types';
import { getClient } from '../apollo';
import LaunchDarklyContext from '../contexts/LaunchDarklyContext';
import { initializeUser } from '../graphql/query';
import { updateUser } from '../graphql/user';
import { isNotLoggedInError } from '../helpers';
import Storage from '../helpers/Storage';
import { createAndUploadMedia } from '../helpers/createAndUploadMedia';
import emitter, {
  EVENT_AUTH_TOKEN_INVALID,
  EVENT_LOGOUT,
  EVENT_AUTH_TOKEN,
  EVENT_UPLOAD_PROFILE_PHOTO,
} from '../helpers/emitter';
import hasPermission from '../helpers/hasPermission';
import i18n from 'localization';
import { ImageType } from '../types/ImageType';
import { UserType } from '../types/User';
import { LibraryTypeEnum } from '../types/libraries';
import { MediaImageContentTypeEnum, MediaTypeEnum } from '../types/media';

type UseUserDataProps = {
  jti?: string;
  jwt?: string;
  blocks?: any[] | null;
  fetchBlocks?:
    | (() => Promise<LazyQueryResult<any, OperationVariables>>)
    | null;
};

let instances = 0;

/**
 * Helper hook to set the current user, this should only be used once per app
 * . i.e. at the top level of the mobile app, or the top level of the web app.
 *
 * If you are looking for the current logged in user, use the UserDataContext
 *
 * This needs to
 *  - check for an existing authToken
 *  - get the user data from the API using that authToken
 *  - poll for the user data periodically to keep it up to date.
 *  - shut off when a logout event occurs
 *  - turn on when a login event occurs.
 */
export default function useUserData(
  { jwt, jti, blocks, fetchBlocks }: UseUserDataProps = {
    blocks: null,
    fetchBlocks: null,
  }
) {
  const [user, setUser] = useState<UserType | null>(null);
  const [sessionId, setSessionId] = useState<LaneType.UUID | null>(null);
  const [route, setRoute] = useState<string | null>(null);
  const [authWarnings, setAuthWarnings] = useState<string[] | null>(null);
  const [authToken, setAuthToken] = useState<{ jti: string } | null>(null);
  // has the app initialized, i.e. checked the stored for an existing auth token
  const [isInitialized, setIsInitialized] = useState(false);
  // have we attempted a login yet
  const [hasAttemptedLogin, setHasAttemptedLogin] = useState(false);
  // is there a current login attempt happening now
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  // is there a current login
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { client: lDClient } = useContext(LaunchDarklyContext);
  const analytics = useContext(AnalyticsContext);

  const { loading, error, refetch: refetchUser } = useQuery<{
    me: { user: UserType; sessionId: LaneType.UUID };
  }>(initializeUser, {
    client: getClient(),
    skip: !authToken,
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (data?.me?.user) {
        // when the user changes, update the state.
        setUser(data.me.user);
        setSessionId(data?.me?.sessionId);
        setIsLoggedIn(true);
        setIsLoggingIn(false);

        if (blocks == null || blocks.length === 0) {
          if (fetchBlocks != null) {
            fetchBlocks();
          }
        }

        lDClient.setUser(data?.me?.user?._id);
        analytics.identify(data?.me?.user?._id);
        analytics.setMixpanelSessionId(data?.me?.sessionId);
      }
    },
    onError: error => {
      if (isNotLoggedInError(error)) {
        // a not logged in error here should also trigger a logout event
        // in the app
        if (authToken && typeof authToken === 'string') {
          try {
            const { exp } = jwtDecode<{
              exp: number;
            }>(authToken);
            const expiringTime = exp * 1000;
            const isTokenExpired = Date.now() > expiringTime;

            if (isTokenExpired) {
              emitter.emit(EVENT_AUTH_TOKEN_INVALID);
            }
          } catch (error) {
            emitter.emit(EVENT_AUTH_TOKEN_INVALID);
          }
        } else {
          emitter.emit(EVENT_AUTH_TOKEN_INVALID);
        }
      }

      setIsLoggingIn(false);
    },
  });

  useEffect(() => {
    if (authToken && !user) {
      setIsLoggingIn(true);
      setHasAttemptedLogin(true);
    }

    if (!authToken) {
      return; // no need to refetch if there is no token
    }

    refetchUser().catch(() => undefined /* ignore */);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  useEffect(() => {
    // todo: this locale logic may be more suitable elsewhere?
    async function updateUserLocale() {
      if (user?.locale) {
        try {
          await i18n.changeLanguage(user.locale);
          await Storage.setItem(Storage.USER_LOCALE, user.locale);
        } catch (err) {
          // error occurred changing locales not worth crashing the app over.
        }
      }
    }

    updateUserLocale();
  }, [user?.locale]);

  async function onAuthToken({ authToken, route, warnings, resolve }: any) {
    await Storage.setItem(Storage.AUTH_TOKEN, authToken);
    setAuthToken(authToken);

    if (route) {
      setRoute(route);
    }

    if (warnings) {
      setAuthWarnings(warnings);
    }

    // callback function used to have control over the execution flow
    if (resolve) {
      resolve();
    }
  }

  async function onAuthTokenInvalid() {
    setUser(null);
    setAuthToken(null);
    lDClient.unsetUser();

    await Storage.removeItem(Storage.AUTH_TOKEN);
  }

  async function onLogout() {
    setHasAttemptedLogin(false);
    onAuthTokenInvalid();

    setRoute(null);
    setIsLoggingIn(false);
    setIsLoggedIn(false);

    await Storage.removeItem(Storage.PRIMARY_CHANNEL);
    await Storage.removeItem(Storage.SECONDARY_CHANNEL);
  }

  useEffect(() => {
    let cancel = false;

    async function updateOAuthUserProfile({ profilePicture }: any) {
      // If profile picture doesn't exists return
      // the user didn't set one in their oAuth Profile
      if (!user || !profilePicture) {
        return;
      }

      const { name } = user.profile;

      try {
        const media = await createAndUploadMedia({
          selectedLibrary: {
            _id: user._id,
            type: LibraryTypeEnum.User,
            name: user.profile.name,
          },
          type: MediaTypeEnum.Image,
          name,
          media: { type: MediaImageContentTypeEnum.png } as ImageType,
          mediaBlob: profilePicture,
          thumbnail: { type: MediaImageContentTypeEnum.jpeg } as ImageType,
          thumbnailBlob: profilePicture,
        });

        if (media?._id) {
          await getClient().mutate({
            mutation: updateUser,
            variables: {
              user: {
                _id: user._id,
                profile: {
                  _id: user.profile._id,
                  image: media._id,
                },
              },
            },
          });
          await refetchUser();
        }
      } catch (ex) {
        // we can swallow this, failure is tolerated
      }
    }

    // listen for login and logout events throughout the app
    emitter.addListener(EVENT_LOGOUT, onLogout);
    emitter.addListener(EVENT_AUTH_TOKEN_INVALID, onAuthTokenInvalid);
    emitter.addListener(EVENT_AUTH_TOKEN, onAuthToken);
    emitter.addListener(EVENT_UPLOAD_PROFILE_PHOTO, updateOAuthUserProfile);

    // see if there is an auth token on load.
    async function checkStorage() {
      try {
        const token = (await Storage.getItem(Storage.AUTH_TOKEN)) || null;

        if (cancel) {
          return;
        }

        setAuthToken(token);
        setIsInitialized(true);

        return;
      } catch (err) {
        // Tokens not found, no action required.
      }

      if (cancel) {
        return;
      }

      if (jti && jwt) {
        const searchParamToken: any = { jti, token: jwt };

        await Storage.setItem(Storage.AUTH_TOKEN, searchParamToken);

        if (cancel) {
          return;
        }

        setAuthToken(searchParamToken);
      } else {
        setAuthToken(null);
      }

      setIsInitialized(true);
    }

    checkStorage();

    instances++;

    if (instances > 1) {
      // this is a warning to developers if they use this hook
      console.warn(
        'You should only have one instances of of useUserData running'
      );
    }

    return () => {
      cancel = true;
      instances--;
      emitter.removeListener(EVENT_LOGOUT, onLogout);
      emitter.removeListener(EVENT_AUTH_TOKEN_INVALID, onAuthTokenInvalid);
      emitter.removeListener(EVENT_AUTH_TOKEN, onAuthToken);
      emitter.removeListener(
        EVENT_UPLOAD_PROFILE_PHOTO,
        updateOAuthUserProfile
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function hasAnyPermission(
    permissions: string[],
    channelId?: string
  ): boolean {
    // We need a user and a channelId
    if (!user || !channelId) {
      return false;
    }

    return (
      user!.isSuperUser || hasPermission(user!.roles, permissions, channelId)
    );
  }

  return {
    refetch: refetchUser,
    user,
    loading,
    error,
    route,
    authToken,
    isInitialized,
    isLoggingIn,
    isLoggedIn,
    hasAttemptedLogin,
    authWarnings,
    setAuthWarnings,
    hasAnyPermission,
    sessionId,
  } as const;
}
