import { validateCreateGroupRole } from 'lane-shared/validation';

import { GroupDTO } from '../models/Group';

export type ValidationFailed = {
  key: 'validationFailed';
  err: Error | string;
};

export async function validateGroupModel(
  groupDTO: GroupDTO
): Promise<{ key: 'success' } | ValidationFailed> {
  try {
    await validateCreateGroupRole.validate(groupDTO, { abortEarly: false });

    return { key: 'success' };
  } catch (err) {
    return {
      key: 'validationFailed',
      err,
    };
  }
}
