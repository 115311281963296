import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'design-system-web';
import { ListView, SearchBar } from 'lane-web/src/components';
import styles from './styles.scss';
import { useTranslation } from 'react-i18next';
import {
  PrinterInfo,
  VisitorPassQueryResponse,
} from 'lane-shared/domains/visitorManagement/types';
import { LAST_USED_VISTORS_PASS_PRINTER } from 'lane-shared/domains/visitorManagement/constants';

interface PrinterSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  connectedPrinters: PrinterInfo[];
  printPass: (
    visitorPass: VisitorPassQueryResponse,
    printer: PrinterInfo
  ) => Promise<void>;
  visitorPass: VisitorPassQueryResponse;
}

export const PrinterSelectionModal = ({
  isOpen,
  onClose,
  connectedPrinters,
  printPass,
  visitorPass,
}: PrinterSelectionModalProps) => {
  const { t } = useTranslation();
  const [printPassInProgress, setPrintPassInProgress] = useState(false);
  const [selectedPrinterIndex, setSelectedPrinterIndex] = useState<number>(-1);
  const [searchPrintersVal, setSearchPrintersVal] = useState('');
  const [printerOptions, setPrinterOptions] = useState(connectedPrinters);

  useEffect(() => {
    const lastUsedPrinterFromStorage = localStorage.getItem(
      LAST_USED_VISTORS_PASS_PRINTER
    );

    if (lastUsedPrinterFromStorage) {
      const lastUsedPrinterIndex = connectedPrinters.findIndex(
        printer => lastUsedPrinterFromStorage === printer.name.toString()
      );

      if (lastUsedPrinterIndex !== -1) {
        setSelectedPrinterIndex(lastUsedPrinterIndex);
      }
    }
  }, [connectedPrinters]);

  useEffect(() => {
    setPrinterOptions(
      connectedPrinters.filter(printer =>
        printer.name.toLowerCase().includes(searchPrintersVal.toLowerCase())
      )
    );
  }, [searchPrintersVal, connectedPrinters]);

  const resetPrintModalSelections = () => {
    setSelectedPrinterIndex(-1);
    setSearchPrintersVal('');
    setPrinterOptions([]);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      testId="selectPrinterModal"
      onClose={resetPrintModalSelections}
      size="large"
      closeButtonSize="large"
      includeHeader
      disableCloseOnBackgroundClick
      title={t(
        'web.admin.channel.visitor.log.printVisitorPass.printModal.title'
      )}
      className={styles.selectPrinterModal}
      isLoading={printPassInProgress}
      actions={
        <>
          <Button
            type="button"
            style={{ padding: '10px 24px' }}
            variant="primary"
            disabled={selectedPrinterIndex === -1 || printPassInProgress}
            onClick={async () => {
              setPrintPassInProgress(true);
              await printPass(
                visitorPass,
                connectedPrinters[selectedPrinterIndex]
              );
              setPrintPassInProgress(false);
              resetPrintModalSelections();
            }}
          >
            {t(
              'web.admin.channel.visitor.log.printVisitorPass.printModal.printButton'
            )}
          </Button>

          <Button
            type="button"
            style={{ padding: '10px 24px' }}
            variant="secondary"
            onClick={resetPrintModalSelections}
          >
            {t(
              'web.admin.channel.visitor.log.printVisitorPass.printModal.cancelButton'
            )}
          </Button>
        </>
      }
    >
      <SearchBar
        onSearchChange={val => setSearchPrintersVal(val)}
        searchOptions={{ search: searchPrintersVal }}
        searchInputPlaceholder={t(
          'web.admin.channel.visitor.log.printVisitorPass.printModal.searchPlaceholder'
        )}
      />
      {printerOptions.map((printer, index) => (
        <ListView
          key={index}
          tabIndex={index}
          text={printer.name}
          containerClassName={`${styles.printerSelectionArea} ${
            selectedPrinterIndex === index ? styles.selectedPrinter : ''
          }`}
          type="ContentListView"
          icon={false}
          onSelection={() => setSelectedPrinterIndex(index)}
        />
      ))}
    </Modal>
  );
};
