import React, { createContext, useReducer } from 'react';

export const AttachmentContext = createContext([]);
export const AttachmentDispatchContext = createContext<any>(null);

export function AttachmentReducer(attachments: any, action: any) {
  switch (action.type) {
    case 'added': {
      return [...attachments, ...action.payload];
    }

    case 'deleted': {
      return attachments.filter((t: any) => t.id !== action.id);
    }

    case 'clear': {
      return [];
    }

    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

export function AttachmentProvider({ children }: { children: any }) {
  const [attachments, dispatch] = useReducer(AttachmentReducer, []);

  return (
    <AttachmentContext.Provider value={attachments}>
      <AttachmentDispatchContext.Provider value={dispatch}>
        {children}
      </AttachmentDispatchContext.Provider>
    </AttachmentContext.Provider>
  );
}
