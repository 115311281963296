export default function groupResults<T>(
  arr: T[] | undefined | null,
  getGroupKey: (t: T) => string
) {
  const grouped: { [key: string]: T[] } = {};

  if (!arr) {
    return grouped;
  }

  arr.forEach(element => {
    const groupKey = getGroupKey(element);

    if (!grouped[groupKey]) {
      grouped[groupKey] = [];
    }

    grouped[groupKey].push(element);
  });

  return grouped;
}
