import React, { useEffect, useState } from 'react';

import ChannelCircleListView from '../lane/ChannelCircleListView';

import { getClient } from 'lane-shared/apollo';

import gql from 'graphql-tag';
import { ChannelType } from 'lane-shared/types/ChannelType';

const getChannel = gql`
  query channel($id: UUID) {
    channel(_id: $id) {
      _id
      name
      profile {
        name
        logo
        image
      }
    }
  }
`;

type ChannelTypeValueProps = {
  className?: string;
  style?: React.CSSProperties;
  value: { _id: string };
};

export default function ChannelTypeValue({
  className,
  style,
  value,
}: ChannelTypeValueProps) {
  const [channel, setChannel] = useState<Partial<ChannelType> | null>(null);

  const doFetch = async () => {
    const { data } = await getClient().query({
      fetchPolicy: 'network-only',
      query: getChannel,
      variables: {
        id: value._id,
      },
    });

    setChannel(data?.channel);
  };

  useEffect(() => {
    if (value._id) {
      doFetch();
    }
  }, [value?._id]);

  return channel ? (
    <ChannelCircleListView
      style={{ fontSize: '0.75em', ...style }}
      className={className}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string'.
      channel={channel}
    />
  ) : null;
}
