import React, { useEffect, useMemo, useState } from 'react';
import {
  Modal,
  M,
  H4,
  IconButton,
  Button,
  S,
  Flex,
  Icon,
} from 'design-system-web';
import styles from './UpdateUserCompanyModal.scss';
import { FONT_AWESOME_REGULAR } from 'lane-shared/helpers/constants/icons';
import { CircleListView, Label, MultiselectField } from 'components';
import { useTranslation } from 'react-i18next';
import { BuildingTenantChannel } from 'graphql-query-contracts';
import { getBuildingTenantChannels } from 'lane-shared/graphql/query';
import {
  ApolloQueryResult,
  OperationVariables,
  useQuery,
} from '@apollo/client';
import { getClient } from 'lane-shared/apollo';
import { manageUserCompanies } from 'lane-shared/graphql/mutation';
import { Alert } from 'components/lds';
import { useChannelAdminContext } from 'hooks';
import { useUserDataContext } from 'lane-shared/hooks';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import {
  ANALYTIC_USER_MANAGEMENT_UPDATE_COMPANY_CANCEL,
  ANALYTIC_USER_MANAGEMENT_UPDATE_COMPANY_UPDATE,
} from 'lane-shared/helpers/constants/analytics';

type Props = {
  isModalOpen: boolean;
  setModalClose: (value: boolean) => void;
  selectedUser: {
    id: string;
    name: string;
    email: string;
    company: { _id: string; name: string }[];
  };
  channelId: string;
  canUserAddTenants: boolean;
  canUserRemoveTenants: boolean;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
};

type UserTenant = {
  value: string;
  label: string;
  selected?: boolean;
  isItemDisabled?: boolean;
  isValueDisabled?: boolean;
};

const TRANSLATION_KEYS = {
  modalTitle: 'web.admin.channel.users.all.table.updateCompany.modal.title',
  dropdownLabel:
    'web.admin.channel.users.all.table.updateCompany.modal.companies.dropdown.label',
  recommendText:
    'web.admin.channel.users.all.table.updateCompany.modal.content.recommend.text',
  hasEmailFilterText:
    'web.admin.channel.users.all.table.updateCompany.modal.content.hasEmailFilter.text',
  resourcesText:
    'web.admin.channel.users.all.table.updateCompany.modal.content.resources.text',
  emailFiltersText:
    'web.admin.channel.users.all.table.updateCompany.modal.content.emailFilters.text',
  updateButton: 'Update',
  cancelButton: 'Cancel',
  addPermissionText:
    'web.admin.channel.users.all.table.updateCompany.modal.no.add.permission.text',
  removePermissionText:
    'web.admin.channel.users.all.table.updateCompany.modal.no.remove.permission.text',
  toastMessage:
    'web.admin.channel.users.all.table.updateCompany.modal.toast.message',
  errorMessage:
    'web.admin.channel.users.all.table.updateCompany.modal.update.error',
};

export const UpdateUserCompanyModal = ({
  isModalOpen,
  setModalClose,
  selectedUser,
  channelId,
  canUserAddTenants,
  canUserRemoveTenants,
  refetch,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState<UserTenant[]>([]);
  const [addedChannelIds, setAddedChannelIds] = useState<string[]>([]);
  const [removedChannelIds, setRemovedChannelIds] = useState<string[]>([]);
  const { user: adminUser } = useUserDataContext();
  const { channel } = useChannelAdminContext();

  const simpleTrack = useSimpleTrack();

  const onCloseModal = () => {
    setModalClose(false);
    setAddedChannelIds([]);
    setRemovedChannelIds([]);
  };

  const onCancel = () => {
    simpleTrack(ANALYTIC_USER_MANAGEMENT_UPDATE_COMPANY_CANCEL, {
      channelId: channel?._id,
      channelName: channel?.name,
      me: adminUser?._id,
      userId: selectedUser.id,
    });
  };

  const handleSelectCompanies = (values: UserTenant[]) => {
    const currentCompanyIds = selectedUser.company.map(company => company._id);
    const newSelectedIds = values.map(value => value.value);

    const addedIds = newSelectedIds.filter(
      id => !currentCompanyIds.includes(id)
    );
    const removedIds = currentCompanyIds.filter(
      id => !newSelectedIds.includes(id)
    );

    setAddedChannelIds(addedIds);
    setRemovedChannelIds(removedIds);
    setSelectedCompanies(values);
  };

  const onUpdate = async () => {
    setLoading(true);

    try {
      await getClient().mutate({
        mutation: manageUserCompanies,
        variables: {
          addedChannelIds,
          removedChannelIds,
          userId: selectedUser.id,
          propertyChannelId: channelId,
        },
      });
      await refetch();
      window.Toast.show(t(TRANSLATION_KEYS.toastMessage), 5000);
      onCloseModal();
    } catch (err) {
      await window.Alert.alert({
        title: t(TRANSLATION_KEYS.errorMessage),
        error: err,
      });
    }

    simpleTrack(ANALYTIC_USER_MANAGEMENT_UPDATE_COMPANY_UPDATE, {
      channelId: channel?._id,
      channelName: channel?.name,
      me: adminUser?._id,
      userId: selectedUser.id,
    });

    setLoading(false);
  };

  const { data: buildingTenants } = useQuery(getBuildingTenantChannels, {
    variables: { channelId },
    skip: !isModalOpen,
  });

  const data = useMemo(() => {
    return (
      buildingTenants?.getBuildingTenantChannels
        .filter((channel: BuildingTenantChannel) => !channel.inviteOnly)
        .map((channel: BuildingTenantChannel) => ({
          value: channel.channelId,
          label: `${channel.name!}${
            channel.hasFilters
              ? ` (${t(TRANSLATION_KEYS.hasEmailFilterText)})`
              : ''
          }`,
          selected: selectedUser.company.some(
            company => company._id === channel.channelId
          ),
        })) || []
    );
  }, [buildingTenants, selectedUser.company]);

  useEffect(() => {
    if (isModalOpen) {
      const initialSelection = data
        .filter((company: UserTenant) => company.selected)
        .map((company: UserTenant) => ({ ...company }));

      setSelectedCompanies(initialSelection);
    }
  }, [isModalOpen, data]);

  const filteredData = (data: UserTenant[]) => {
    return data.map(company => {
      const obj: UserTenant = { ...company };

      if (company.selected && !canUserRemoveTenants) {
        obj.isValueDisabled = true;
        obj.isItemDisabled = true;
      } else if (!company.selected && !canUserAddTenants) {
        obj.isItemDisabled = true;
      }

      return obj;
    });
  };

  return (
    <Modal
      className={styles.UpdateUserCompanyModal}
      isOpen={isModalOpen}
      onClose={onCloseModal}
      isCloseButtonHidden
    >
      <Flex justify="space-between" align="center">
        <H4>{t(TRANSLATION_KEYS.modalTitle)}</H4>
        <IconButton
          className={styles.closeButton}
          type={FONT_AWESOME_REGULAR}
          onClick={onCloseModal}
          icon="times"
          data-test="closeButton"
          size="small"
        />
      </Flex>

      {!(canUserAddTenants && canUserRemoveTenants) && (
        <Alert className="mt-3" type="info" fullWidth>
          {canUserAddTenants
            ? t(TRANSLATION_KEYS.removePermissionText)
            : t(TRANSLATION_KEYS.addPermissionText)}
        </Alert>
      )}

      <CircleListView
        image={null}
        logo={null}
        name={selectedUser.name}
        className={styles.circleListView}
      >
        <M>{`${selectedUser.name} (${selectedUser.email})`}</M>
      </CircleListView>

      <Flex align="center">
        <Label>{t(TRANSLATION_KEYS.dropdownLabel)}</Label>
        <span className={styles.required}>*</span>
      </Flex>

      <MultiselectField
        testId="companiesSelector"
        items={filteredData(data)}
        value={selectedCompanies}
        onChange={handleSelectCompanies}
        isFullWidth
        isClearable={false}
      />

      <S className={styles.recommendText}>
        {t(TRANSLATION_KEYS.recommendText)}
      </S>

      <Flex direction="column" m={[4, 0, 0, 0]}>
        <Label>{t(TRANSLATION_KEYS.resourcesText)}</Label>
        <a
          href="https://help.vts.com/hc/en-us/articles/9401061271451-Channels"
          target="_blank"
          rel="noreferrer"
        >
          <S
            style={{ display: 'flex' }}
            variant="secondary"
            as="span"
            underlined
          >
            {t(TRANSLATION_KEYS.emailFiltersText)} <Icon name="external-link" />
          </S>
        </a>
      </Flex>

      <div className={styles.divider} />

      <Flex>
        <Button
          variant="primary"
          size="large"
          className={styles.updateButton}
          onClick={onUpdate}
          disabled={
            loading || (!addedChannelIds.length && !removedChannelIds.length)
          }
        >
          {t(TRANSLATION_KEYS.updateButton)}
        </Button>
        <Button
          variant="secondary"
          size="large"
          className={styles.cancelButton}
          onClick={() => {
            onCloseModal();
            onCancel();
          }}
        >
          {t(TRANSLATION_KEYS.cancelButton)}
        </Button>
      </Flex>
    </Modal>
  );
};
