import { LaneType } from 'common-types';
import { UserGroupRoleType } from '../types/UserGroupRole';
import { PERMISSION_ADMIN } from './constants/permissions';

/**
 * Filter down to the roles that have the listed permissions.
 **
 * @param userGroupRoles {UserGroupRoleType[]} an array of UserGroupRoles
 * @param permissions {string[]} an array of permissions
 * @param channelId {string?} a specific channel to search for
 * @param includeAdmin {boolean} check if user has PERMISSION_ADMIN. Default value is true.
 * @returns {boolean}
 */
export default function hasPermission(
  userGroupRoles: UserGroupRoleType[] = [],
  permissions: string | string[] = [],
  channelId?: LaneType.UUID,
  includeAdmin: boolean = true
): boolean {
  // Wrap the array, allows for passing in just one permission more easily.
  if (!Array.isArray(permissions)) {
    permissions = [permissions];
  }

  return userGroupRoles?.some(userGroupRole => {
    if (
      channelId &&
      !(
        userGroupRole.groupRole?.channel?.hierarchy?.hierarchyDown?.includes(
          channelId
        ) || channelId === userGroupRole.groupRole?.channel?._id
      )
    ) {
      return false;
    }

    return checkRolePermissions(
      userGroupRole,
      permissions as string[],
      includeAdmin
    );
  });
}

/**
 * Check if a user has permissions on a specific role
 **
 * @param userGroupRoles {UserGroupRoleType[]} an array of UserGroupRoles
 * @param permissions {string[]} an array of permissions
 * @param roleId {string} a specific role to search for
 * @param includeAdmin {boolean} check if user has PERMISSION_ADMIN. Default value is true.
 * @returns {boolean}
 */
export function hasPermissionOnSpecificRole(
  userGroupRoles: UserGroupRoleType[] = [],
  permissions: string | string[] = [],
  roleId: LaneType.UUID,
  includeAdmin: boolean = true
): boolean {
  // Wrap the array, allows for passing in just one permission more easily.
  if (!Array.isArray(permissions)) {
    permissions = [permissions];
  }

  const userGroupRole = userGroupRoles.find(
    userGroupRole => userGroupRole.groupRole._id === roleId
  );

  if (!userGroupRole) {
    return false;
  }

  return checkRolePermissions(userGroupRole, permissions, includeAdmin);
}

function checkRolePermissions(
  userGroupRole: UserGroupRoleType,
  permissions: string[],
  includeAdmin: boolean
): boolean {
  const rolePermissions = userGroupRole.groupRole?.permissions || [];

  if (includeAdmin && rolePermissions.includes(PERMISSION_ADMIN)) {
    // Admin basically does mean match any or all permissions.
    return true;
  }

  return (permissions as any).some((permission: any) =>
    rolePermissions.includes(permission)
  );
}
