import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Icon } from 'design-system-web';
import { DateTime } from 'luxon';
import { Marker } from 'react-google-maps';
import { useTranslation } from 'react-i18next';
import urlJoin from 'url-join';

import * as Sentry from '@sentry/browser';

import { LaneType } from 'common-types';
import { routes } from 'lane-shared/config';
import { AppContext } from 'lane-shared/contexts';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { parseDateTime } from 'lane-shared/helpers/dates';
import * as formater from 'lane-shared/helpers/formatters';
import getCheckInOutMessages from 'lane-shared/helpers/interaction/getCheckInOutMessages';
import {
  useChannelProfileQuery,
  useUserContentInteractionReceipt,
} from 'lane-shared/hooks';
import useCancelUCI from 'lane-shared/hooks/features/cancel/useCancelUCI';
import useInteractionQRCodeCheckIn from 'lane-shared/hooks/features/qrCodeCheckIn/useInteractionQRCodeCheckIn';
import { QRCodeCheckInMode } from 'lane-shared/hooks/features/qrCodeCheckIn/useQRCodeCheckIn';
import useGuestInviteToIcsAttendees from 'lane-shared/hooks/features/useGuestInviteToIcsAttendees';
import { UserContentInteractionFeaturesType } from 'lane-shared/types/UserContentInteraction';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';
import { ContentType } from 'lane-shared/types/content/Content';
import { InteractionCreatorType } from 'lane-shared/types/server/WorkflowTypes';

import Button from 'components/general/Button';
import Image from 'components/general/Image';
import LinkButton from 'components/general/LinkButton';
import Map from 'components/general/Map';
import StatusPill from 'components/general/StatusPill';
import { BreadCrumbs } from 'components/lds';
import { H4, H3, H5, M } from 'components/typography';

import downloadICS from 'helpers/downloadICS';
import openGoogleCalendarLink from 'helpers/openGoogleCalendarLink';

import styles from './QRCodeCheckInOutUCIReceipt.scss';

const mapOptions = {
  streetViewControl: false,
  scaleControl: false,
  zoomControl: false,
  panControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
};

type Props = {
  interaction: {
    _id: LaneType.UUID;
    _created: string;
    _createdBy: InteractionCreatorType;
    startDate: string | Date | null;
    endDate: string | Date | null;
    status: string;
    geo: GeoCoordinateType;
    features: UserContentInteractionFeaturesType;
    contentData: ContentType;
  };
  mode?: QRCodeCheckInMode;
  onInteractionUpdated?: (interaction: any) => void;
  onInteractionCancelled?: () => void;
};

export default function QRCodeCheckInOutUCIReceipt({
  interaction,
  mode = undefined,
  onInteractionUpdated = () => null,
  onInteractionCancelled = () => null,
}: Props) {
  const { _id, status, startDate, endDate } = interaction;

  const { whitelabel } = useContext(AppContext);
  const { t } = useTranslation();

  async function confirmCancelUCI() {
    return window.Alert.confirm({
      title: t('Cancel your "{{- contentName}}" entry?', {
        contentName: content.name,
      }),
      message: t(
        `Are you sure you want to cancel "{{- contentName}}"?\n\nYou won't be able to undo this once it is done.`,
        { contentName: content.name }
      ),
    });
  }

  const [isMapOpen, setIsMapOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const mapPosition = {
    lat: interaction?.contentData?.geo?.[1],
    lon: interaction?.contentData?.geo?.[0],
  };

  const {
    canUserCancel,
    canUserCheckOut,
    inClosedState,
    hasStatuses,
    timeZone,
  } = useUserContentInteractionReceipt({ interaction });

  const timeExpired = DateTime.local() > parseDateTime(endDate, timeZone)!;

  if (!mode) {
    mode = canUserCheckOut
      ? QRCodeCheckInMode.CHECK_OUT
      : QRCodeCheckInMode.CHECK_IN;
  }

  const {
    loading: checkInOutLoading,
    error: checkInOutError,
    isCancelled,
    submitCheckIn,
    ...interactionQRCodeCheckInResults
  } = useInteractionQRCodeCheckIn({
    interaction,
    mode,
  });
  const content = interactionQRCodeCheckInResults.content!;

  const [, attendees] = useGuestInviteToIcsAttendees(
    interaction?.features?.GuestInvite
  );

  const {
    loading: cancelLoading,
    error: cancelError,
    cancel,
    reset,
  } = useCancelUCI(interaction, confirmCancelUCI, Sentry);

  const {
    title,
    description,
    actionButtonTitle,
    showAddToCalendar,
    successMessage,
  } = useMemo(
    () => getCheckInOutMessages(isCancelled, inClosedState, timeExpired, mode),
    [status, isCancelled, inClosedState, mode]
  );

  const companyData = useChannelProfileQuery({
    channelId: content?.channel?._id,
  });

  const loading = cancelLoading || checkInOutLoading;

  async function submit() {
    try {
      await window.Alert.confirm({
        title: t(
          mode === QRCodeCheckInMode.CHECK_IN ? 'Check In' : 'Check Out'
        ),
        message: t(
          mode === QRCodeCheckInMode.CHECK_IN
            ? 'Check in to {{- contentName}}?'
            : 'Check out of {{- contentName}}?',
          {
            contentName: content?.name,
          }
        ),
        confirmText: t('Confirm'),
      });
    } catch (err) {
      // user cancelled
      return;
    }

    try {
      await submitCheckIn();

      onInteractionUpdated(_id);

      if (successMessage) {
        window.Toast.show(t(successMessage));
      }
    } catch (error) {
      setError({
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ title: string; message: string... Remove this comment to see the full error message
        title: t(`We weren't able to do this right now.`),
        message: t(`See the error below and please try again`),
        error,
      });
    }
  }

  function onCancelPressed() {
    cancel();
  }

  useEffect(() => {
    if (!isCancelled) {
      return;
    }

    onInteractionUpdated(_id);
    onInteractionCancelled();
  }, [isCancelled]);

  useEffect(() => {
    const errorToShow = checkInOutError || cancelError || error;

    if (!errorToShow) {
      return;
    }

    async function showAlert() {
      await window.Alert.alert({ error: errorToShow });

      if (checkInOutError) {
        reset();
      }
    }

    showAlert();
  }, [checkInOutError, cancelError, error]);

  function downloadIcsHandler() {
    if (!(startDate && endDate)) {
      return;
    }

    const interactionCreatedBy = interaction?._createdBy?.profile;
    const organizer = interactionCreatedBy
      ? { email: interactionCreatedBy.email, name: interactionCreatedBy.name }
      : undefined;

    downloadICS({
      id: interaction._id,
      name: content.name,
      description: content.description,
      url: urlJoin(
        whitelabel.url,
        routes.interaction.replace(':id', interaction._id)
      ),
      status: interaction.status,
      geo: interaction.geo,
      organizer,
      attendees,
      startDate,
      endDate,
      timeZone,
    });
  }

  return (
    <div className={styles.wrapper}>
      <BreadCrumbs
        links={[
          { label: t('Home'), url: routes.home },
          {
            label: content?.name,
            url: routes.contentInteractions.replace(':id', content?._id),
          },
          {
            label: t('Booking'),
            url: routes.interaction.replace(':id', interaction?._id),
          },
        ]}
      />
      <H3 className={styles.title} mt={4} mb={2}>
        {t(title)}
      </H3>
      <M className={styles.subtitle} mb={6}>
        {t(description)}
      </M>
      <div className={styles.contentRow}>
        <div>
          {hasStatuses ? (
            <StatusPill
              status={status}
              text={status}
              size="medium"
              className={styles.statusPill}
            />
          ) : null}
          <H4 mt={4}>{content?.name} </H4>
          <M className={styles.channelAddress} mt={2}>
            {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'AddressType | undefined' is not ... Remove this comment to see the full error message */}
            {formater.shortAddress(companyData?.channel?.address)}
          </M>
          <div className={styles.checkDateRow}>
            <div>
              <H5>{t('Check In')}</H5>
              <M className={styles.checkDate}>
                {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | Date | null' is not ass... Remove this comment to see the full error message */}
                {formater.longDateNoTime(startDate, timeZone)}
              </M>
              <br />
              <M className={styles.checkDate}>
                {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | Date | null' is not ass... Remove this comment to see the full error message */}
                {formater.shortTimeTimeZone(startDate, timeZone)}
              </M>
            </div>
            <div>
              <H5>{t('Check Out')}</H5>
              <M className={styles.checkDate}>
                {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | Date | null' is not ass... Remove this comment to see the full error message */}
                {formater.longDateNoTime(endDate, timeZone)}
              </M>
              <br />
              <M className={styles.checkDate}>
                {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | Date | null' is not ass... Remove this comment to see the full error message */}
                {formater.shortTimeTimeZone(endDate, timeZone)}
              </M>
            </div>
          </div>
          <div className={styles.buttonsRow}>
            {!timeExpired && showAddToCalendar && (
              <>
                <Button
                  startIcon={
                    <Icon name="calendar" set={ICON_SET_FONTAWESOME} />
                  }
                  color="tertiary"
                  onClick={downloadIcsHandler}
                  fullWidth
                >
                  {t('Add to Calendar')}
                </Button>
                <Button
                  fullWidth
                  startIcon={<Icon name="google" set={ICON_SET_FONTAWESOME} />}
                  color="tertiary"
                  onClick={() =>
                    openGoogleCalendarLink(
                      startDate,
                      endDate,
                      content.name,
                      content.description
                    )
                  }
                >
                  {t('Add to Google Calendar')}
                </Button>
              </>
            )}
            {mapPosition.lon && (
              <Button
                startIcon={<Icon name="map" set={ICON_SET_FONTAWESOME} />}
                color="tertiary"
                onClick={() => setIsMapOpen(!isMapOpen)}
              >
                {isMapOpen ? t('Hide Map') : t('Show Map')}
              </Button>
            )}
          </div>
        </div>
        {content?.backgroundImage ? (
          <Image
            src={formater.imageUrl(content.backgroundImage)}
            alt=""
            className={styles.image}
          />
        ) : (
          <div className={styles.emptyImage}>
            <Icon
              className={styles.emptyImageIcon}
              set={ICON_SET_FONTAWESOME}
              name="image"
              type="fal"
            />
          </div>
        )}
      </div>
      {isMapOpen ? (
        <Map
          center={mapPosition}
          zoom={19}
          options={mapOptions}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; center: { lat: number; ... Remove this comment to see the full error message
          className={styles.map}
        >
          <Marker position={mapPosition} title={title} />
        </Map>
      ) : null}
      {!inClosedState &&
      canUserCancel &&
      mode === QRCodeCheckInMode.CHECK_IN ? (
        // @ts-expect-error ts-migrate(2741) FIXME: Property 'iconName' is missing in type '{ children... Remove this comment to see the full error message
        <LinkButton onClick={onCancelPressed} className={styles.cancelButton}>
          {t('Cancel Booking')}
        </LinkButton>
      ) : null}
      {actionButtonTitle ? (
        <Button
          loading={loading}
          variant="contained"
          onClick={submit}
          className={styles.submitButton}
        >
          {t(actionButtonTitle)}
        </Button>
      ) : null}
    </div>
  );
}
