import React from 'react';
import { M } from 'design-system-web';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'components/general/ConfirmationModal';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  bulkSelectedUsers: {
    id: string;
    email: string;
  }[];
  channelId: string;
  onSuccess?: () => void;
};

const TRANSLATION_KEYS = {
  modalTitle: 'web.admin.channel.users.all.table.bulkResetPassword.modal.title',
  confirmButtonLabel:
    'web.admin.channel.users.all.table.bulkResetPassword.modal.confirmButton.label',
  cancelButtonLabel:
    'web.admin.channel.users.all.table.bulkResetPassword.modal.cancelButton.label',
  promptText:
    'web.admin.channel.users.all.table.bulkResetPassword.modal.prompt.text',
  messageText:
    'web.admin.channel.users.all.table.bulkResetPassword.modal.message.text',
  toastText:
    'web.admin.channel.users.all.table.bulkResetPassword.success.toast.text',
};

export const ResetPasswordModal = ({
  isOpen,
  onClose,
  bulkSelectedUsers,
}: Props) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    window.Toast.show(t(TRANSLATION_KEYS.toastText), 5000);
    onClose();
  };

  const bulkSelectedUsersCount = bulkSelectedUsers.length;

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      handleConfirm={handleConfirm}
      title={t(TRANSLATION_KEYS.modalTitle)}
      confirmText={t(TRANSLATION_KEYS.confirmButtonLabel)}
      cancelText={t(TRANSLATION_KEYS.cancelButtonLabel)}
    >
      <M>
        <Trans
          components={[<b />]}
          i18nKey={TRANSLATION_KEYS.promptText}
          count={bulkSelectedUsersCount}
          values={{
            count: bulkSelectedUsersCount,
          }}
        />
      </M>
      <M>
        <Trans components={[<b />]} i18nKey={TRANSLATION_KEYS.messageText} />
      </M>
    </ConfirmationModal>
  );
};
