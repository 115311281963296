export const KAFKA_INTEGRATION_TOPIC = 'integration';
export const KAFKA_RETRY_5M_TOPIC = 'retry_5m';
export const KAFKA_RETRY_30M_TOPIC = 'retry_30m';
export const KAFKA_RETRY_1H_TOPIC = 'retry_1h';

export enum KafkaEventTopics {
  event = 'event',
  datasetRecord = 'dataset.record',
}
export enum AcsKafkaIntegrationTopic {
  event = 'events.integrations.management',
}

export enum VisitorManagementTopic {
  event = 'event.visitors.management',
}

export enum NotificationAPITopic {
  event = 'event.notification.api',
}

export enum WorkOrderTopic {
  event = 'event.work.order',
}

export enum ResidentTopic {
  event = 'event.resident',
}

export enum BillingPaymentsAccountsTopic {
  event = 'event.billingPayments.accounts',
}

export enum ScimTopic {
  event = 'event.scim',
}

export enum ActivateLaneKafkaEvents {
  USER_INVITATION_CLAIMED = 'userInvitationClaimedEvent',
  USER_UPDATED_EVENT = 'userUpdatedEvent',
  USER_DELETED_EVENT = 'userDeletedEvent',
  USER_MOBILE_ACCESS_ENABLE_REQUESTED_EVENT = 'userMobileAccessEnableRequestedEvent',
  USER_MOBILE_ACCESS_DISABLE_REQUESTED_EVENT = 'userMobileAccessDisableRequestedEvent',
  RETRY_USER_SYNC_REQUESTED_EVENT = 'retryUserSyncRequestedEvent',
}

export const SYNC_TO_PROVIDER = true;

// Times in ms
export const RETRY_TIMES_FOR_TOPICS = {
  [KAFKA_RETRY_5M_TOPIC]: 300000,
  [KAFKA_RETRY_30M_TOPIC]: 1800000,
  [KAFKA_RETRY_1H_TOPIC]: 3600000,
};

export const RETRY_TOPICS_FOR_RETRY_NUMBER = {
  1: KAFKA_RETRY_5M_TOPIC,
  2: KAFKA_RETRY_5M_TOPIC,
  3: KAFKA_RETRY_30M_TOPIC,
  4: KAFKA_RETRY_30M_TOPIC,
  5: KAFKA_RETRY_30M_TOPIC,
  6: KAFKA_RETRY_1H_TOPIC,
};
