import { AddressType } from '../../../types/AddressType';

export const addressCollectables = (address?: AddressType) => {
  if (!address) return {};

  return {
    city: address.city,
    country: address.country,
  };
};
