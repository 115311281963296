import { FeatureInstance } from 'lane-shared/types/FeatureInstance';
import { BlockRenderInterface } from 'lane-shared/types/blocks/BlockInterface';
import { ContentType } from 'lane-shared/types/content/Content';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';
import {
  SurveysFeatureProperties,
  SurveyTypeEnum,
} from 'lane-shared/types/features/SurveysFeatureProperties';

import { getSurveyContentToAdd } from './getSurveyContentToAdd';
import { getSurveyContentToRemove } from './getSurveyContentToRemove';

export function getSurveyContentOnFeatureUpdate({
  content,
  updatedFeature,
  blocks,
}: {
  content: ContentType;
  updatedFeature: FeatureInstance<SurveysFeatureProperties>;
  blocks: { [key: string]: BlockRenderInterface };
}): ContentType {
  const surveyFeatureIndex = content.features.findIndex(
    (cf: any) => cf.type === FeatureNameEnum.Surveys
  );
  const surveyFeature = content.features[surveyFeatureIndex];

  if (surveyFeature) {
    ((surveyFeature.feature as unknown) as FeatureInstance<SurveysFeatureProperties>) = updatedFeature;
  }

  const surveyType = (updatedFeature.type as unknown) as SurveyTypeEnum;

  // Remove all survey related info from content
  const contentWithoutSurvey = getSurveyContentToRemove({
    content,
  });

  // Add survey related info, given new survey type
  const surveyContent = getSurveyContentToAdd({
    content: {
      ...content,
      data: contentWithoutSurvey.data,
      block: contentWithoutSurvey.block,
    },
    surveyType,
    blocks,
  });

  return surveyContent;
}
