import { useState, useRef, useEffect } from 'react';

const DRAG_TIMEOUT = 125; // ms

export default function useSimpleDragHelper({
  onDrop,
}: {
  onDrop?: (e: Event) => void;
} = {}) {
  const [isDropping, setIsDropping] = useState(false);
  const dragTimerRef = useRef(null);

  function updateIsDropping(value: boolean) {
    setIsDropping(value);
  }

  function clearDragTimer() {
    if (dragTimerRef.current) {
      clearTimeout(dragTimerRef.current);
    }
  }

  function setDragLeaveTimer(time = DRAG_TIMEOUT) {
    // the drag leave and drag enter events fire rapidly in succession.
    // use a timer to determine once the user has left for a period of time
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Timeout' is not assignable to type 'null'.
    dragTimerRef.current = setTimeout(() => setIsDropping(false), time);
  }

  function dragOverHandler(e: any) {
    e.preventDefault();
    clearDragTimer();
  }

  function dragEnterHandler(e: any) {
    e.preventDefault();
    setIsDropping(true);
  }

  function dragLeaveHandler(e: any) {
    e.preventDefault();
    setDragLeaveTimer();
  }

  function dropHandler(e: any) {
    e.preventDefault();
    setIsDropping(false);

    if (onDrop) {
      onDrop(e);
    }
  }

  useEffect(() => {
    return clearDragTimer;
  });

  return {
    isDropping,
    updateIsDropping,
    dragOverHandler,
    dragEnterHandler,
    dragLeaveHandler,
    dropHandler,
  };
}
