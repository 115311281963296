export default function getInitials(
  string: string,
  isCompany: boolean = false
): string {
  if (!string) {
    return '';
  }

  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    if (isCompany) {
      initials += names[1].substring(0, 1).toUpperCase();

      return initials;
    }

    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
}
