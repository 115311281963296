import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import plusIcon from 'lane-web/src/static/img/plus-icon.svg';

import { Button } from 'design-system-web';
import { StripePaymentMethod } from './StripePaymentMethod';

import styles from './PaymentOptionsList.scss';
import { PaymentSource } from 'graphql-query-contracts';

const MORE_THAN_ONE_CARD = 1;

type Props = {
  cards: PaymentSource[] | null;
  updatePaymentMethod: (id: string) => void;
  selectedPaymentMethod: string | undefined;
  onRemovePaymentMethod: (paymentMethodId: string) => Promise<void>;
  isWallet?: boolean;
  handleClickAddPayment: () => void;
};

export function PaymentOptionsList({
  cards,
  updatePaymentMethod,
  selectedPaymentMethod,
  onRemovePaymentMethod,
  isWallet,
  handleClickAddPayment,
}: Props) {
  const { t } = useTranslation();

  async function removePaymentMethod(paymentMethodId: string) {
    await onRemovePaymentMethod(paymentMethodId);

    if (paymentMethodId === selectedPaymentMethod) {
      updatePaymentMethod('');
    }
  }

  return (
    <div className={cx(styles.paymentOptionsListContainer)}>
      {cards?.length ? (
        <>
          {cards.map(card => (
            <div
              key={card.paymentMethodId}
              className={cx(styles.radioCardGroup)}
            >
              <StripePaymentMethod
                paymentMethod={card}
                onSelectPaymentMethod={updatePaymentMethod}
                selectable={!isWallet && cards.length > MORE_THAN_ONE_CARD}
                selected={card.paymentMethodId === selectedPaymentMethod}
                onRemovePaymentMethod={removePaymentMethod}
              />
            </div>
          ))}
          <Button
            variant="text"
            className={styles.addAdditionalPaymentMethod}
            startIcon={<img src={plusIcon} alt="add-card-plus-icon" />}
            onClick={handleClickAddPayment}
            testId="addPaymentOptionButton"
          >
            {t('abp.payment.orderSummary.addPaymentMethod')}
          </Button>
        </>
      ) : (
        <p className={styles.noPaymentMethodText}>
          {t('abp.payment.youHaveNoPaymentMethodsStored')}
        </p>
      )}
    </div>
  );
}
