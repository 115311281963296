import React from 'react';

import { useTranslation } from 'react-i18next';

import DefensiveImage from '../../static/img/defensive-image.svg';

import styles from './CaughtUpMessage.scss';

export default function CaughtUpMessage() {
  const { t } = useTranslation();

  return (
    <div className={styles.CaughtUpMessage}>
      <img src={DefensiveImage} alt={t('You’re all caught up!')} />
      <aside>
        <h3>{t('You’re all caught up!')}</h3>
      </aside>
    </div>
  );
}
