import gql from 'graphql-tag';

export default gql`
  mutation createCredentialGroup(
    $channelId: UUID!
    $provider: String!
    $credentialGroupType: CredentialGroupType!
  ) {
    createCredentialGroup(
      channelId: $channelId
      provider: $provider
      credentialGroupType: $credentialGroupType
    ) {
      issuanceToken
      expiryDate
    }
  }
`;
