import React, { useContext, useState } from 'react';

import { Icon } from 'design-system-web';
import { Button, StandardContentCard } from 'components';
import { useTranslation } from 'react-i18next';

import { spacing, colors } from 'lane-shared/config';
import { ChannelsContext } from 'lane-shared/contexts';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import addressFormatter from 'lane-shared/helpers/formatters/addressFormatter';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import { useShopifyPortsQuery } from 'lane-shared/hooks/integrations/shopify/useShopifyPortsQuery';
import {
  BurstDraftInteractionData,
  ShopifyBurstInteractionType,
  ShopifyChannelType,
} from 'lane-shared/types/integrations/ShopifyBurst';

import SelectableCardWrapper from 'components/cards/SelectableCardWrapper';
import { Box, Flex } from 'components/layout';
import StandardContentCardSkeleton from 'components/loading/StandardContentCardSkeleton';
import { H4, H5, L, M } from 'components/typography';

import RoomBookingModal from '../../RoomBookingModal';
import handleSave from './helpers/saveRoomHelper';
import useSortedSectionContent from './hooks/useSortedSectionContent';
import { useModalContext } from 'contexts/ModalContext';

import styles from './BurstEditRoom.scss';

type Props = {
  burstData: ShopifyBurstInteractionType;
  onDataUpdated: (data: BurstDraftInteractionData) => void;
  onClose: () => void;
};

export default function BurstEditPort({
  onDataUpdated,
  burstData,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const { burstIntegrationSettings } = useShopifyBurstContext();
  const [selectedRoom, setSelectedRoom] = useState();
  const [selectedPort, setSelectedPort] = useState<
    ShopifyChannelType | undefined
  >();
  const [step, setStep] = useState(1);
  const { showModal, closeModal } = useModalContext();
  const channelData = useContext(ChannelsContext);
  const shopifyParentChannelId = channelData?.primaryChannel?.parent?._id;

  const { ports } = useShopifyPortsQuery(shopifyParentChannelId);

  function onSave() {
    const [longitude, latitude] = selectedPort?.address?.geo || [null, null];
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
    const timeZone = getTimeZoneByGeoLocation({ longitude, latitude });

    if (selectedRoom)
      handleSave({
        data: burstData,
        content: selectedRoom,
        update: { port: selectedPort, portTimeZone: timeZone },
        onDataUpdated,
        onClose,
      });
  }

  const { sortedSectionContent, loading } = useSortedSectionContent({
    channelId: selectedPort?._id || shopifyParentChannelId,
    sectionId: burstIntegrationSettings?.roomBookingSection?._id,
    dateRange: burstData.dateRange,
  });

  function handleRoomOpenPreview(content: any) {
    showModal(
      <RoomBookingModal
        onClose={closeModal}
        contentId={content._id}
        handleRoomSelect={setSelectedRoom}
      />
    );
  }

  return (
    <>
      {step === 1 ? (
        <Box className={styles.container}>
          <Flex direction="column" mb={2} className={styles.header}>
            <H4 mb={2}>{t('Please choose a location')}</H4>
          </Flex>
          <Flex gap={4} mb={4} className={styles.list}>
            {ports.map(port => (
              <SelectableCardWrapper
                key={port?._id}
                onClick={() => setSelectedPort(port)}
                isSelected={port?._id === selectedPort?._id}
              >
                <StandardContentCard
                  content={
                    {
                      name: port?.name,
                      backgroundImage: port?.profile?.image,
                      description: addressFormatter(port?.address),
                    } as any
                  }
                />
              </SelectableCardWrapper>
            ))}
          </Flex>
          <hr />
          <Box width="full" className={styles.buttons}>
            <Button
              variant="contained"
              disabled={!selectedPort}
              onClick={() => setStep(2)}
            >
              {t('Next')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box className={styles.container}>
          <Flex direction="column" mb={2} className={styles.header}>
            <H5 mb={2}>{t('Which team room best suits your burst?')}</H5>
            <M mb={2} variant="secondary">
              {t(
                'Please choose a room that best fits the number of attendees without going over the max capacity.'
              )}
            </M>
          </Flex>
          <Flex gap={4} mb={4} className={styles.list}>
            {loading && <StandardContentCardSkeleton count={3} />}
            {sortedSectionContent.map(content => (
              <SelectableCardWrapper
                key={content?._id}
                onClick={() => handleRoomOpenPreview(content)}
                isSelected={content._id === (selectedRoom as any)?._id}
              >
                <StandardContentCard content={content} />
              </SelectableCardWrapper>
            ))}
            {sortedSectionContent.length === 0 && !loading && (
              <Flex
                p={4}
                direction="column"
                justify="center"
                align="center"
                style={{ width: '100%' }}
              >
                <Icon
                  name="calendar-exclamation"
                  set="FontAwesome"
                  type="far"
                  style={{
                    margin: spacing[4],
                    color: colors.charcoal[6],
                  }}
                  size="large"
                />
                <L variant="secondary">
                  {t(
                    'There are no available rooms on selected port and dates.'
                  )}
                </L>
              </Flex>
            )}
          </Flex>
          <hr />
          <Box width="full" className={styles.buttons}>
            <Button variant="outlined" onClick={() => setStep(1)}>
              {t('Back')}
            </Button>
            <Button
              variant="contained"
              disabled={!selectedRoom}
              onClick={onSave}
            >
              {t('Save')}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
