import gql from 'graphql-tag';

export default gql`
  query getChannelGroupRolesLimitedInfo($id: UUID!) {
    channel(_id: $id) {
      groupRoles {
        _id
        name
      }
    }
  }
`;
