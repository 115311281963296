/**
 * Looks for the operation name of each graphql request so we can see it in the network tab.
 * Only enabled in development and local environments.
 *
 * @param options
 * @returns the graphql operation name as a param, ie. `?o=queryChannelUsersByGroupRole` or '' if not found.
 */
export function getOperationNameParam(options: any): string {
  const bodyJson = String(options?.body);

  if (!bodyJson?.includes('operationName')) {
    return '';
  }

  const body = bodyJson ? JSON.parse(bodyJson) : null;

  if (!body) {
    return '';
  }

  const operationName = Array.isArray(body)
    ? `batch-query(${body.length}-operations)`
    : body.operationName;

  if (!operationName) {
    return '';
  }

  return `?o=${operationName}`;
}
