import React, { useEffect } from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import { removeToken } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/token';
import { ContentType } from 'lane-shared/types/content/Content';

import { Loading } from 'components/general';

import Login from './Login';
import { OpenRequests } from './components';

function Fallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  useEffect(() => {
    if (error.message === '401') {
      removeToken().then(() => resetErrorBoundary());
    } else {
      resetErrorBoundary();
    }
  });

  return <Loading />;
}

type Props = {
  content: ContentType;
};

export default function BuildingEnginesPrism({ content }: Props) {
  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      <Login content={content}>
        <OpenRequests content={content} />
      </Login>
    </ErrorBoundary>
  );
}
