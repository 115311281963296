import { UnsupportedMediaTypeError } from 'activate-errors';
import {
  MediaDocumentContentTypeEnum,
  MediaImageContentTypeEnum,
  MediaTypeEnum,
} from 'lane-shared/types/media';

import type { FileReturnType } from 'helpers/fileReaderResolver';

export const getMediaType = (media: FileReturnType) => {
  const type = (media as File).type;

  if (
    Object.values(MediaImageContentTypeEnum).includes(
      type as MediaImageContentTypeEnum
    )
  ) {
    return MediaTypeEnum.Image;
  }

  if (
    Object.values(MediaDocumentContentTypeEnum).includes(
      type as MediaDocumentContentTypeEnum
    )
  ) {
    return MediaTypeEnum.Document;
  }

  throw new UnsupportedMediaTypeError();
};
