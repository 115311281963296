import { AddressType } from './AddressType';

export const EMPTY_ADDRESS: AddressType = {
  _id: '',
  name: '',
  street1: '',
  street2: '',
  street3: '',
  city: '',
  country: '',
  code: '',
  state: '',
  geo: [0, 0],
};
