import i18next from 'i18next';
import { DateTime } from 'luxon';

import { SIMPLE_DATE } from '../../constants/dates';
import parseDateTime from '../../dates/parseDateTime';
import dateFormatter from '../dateFormatter';

export default function formatDateRangeWeek({
  _start,
  _end,
  i18n = i18next,
  timeZone,
  locale,
}: {
  _start: DateTime | Date | string;
  _end: DateTime | Date | string;
  i18n?: any;
  timeZone?: string;
  locale?: string;
}): string {
  // todo: not sure how to better display weeks yet.
  const start = parseDateTime(_start, timeZone)!;
  const end = parseDateTime(_end, timeZone)!;

  const startFormatted = dateFormatter(start, SIMPLE_DATE, timeZone, locale);
  const endFormatted = end
    ? dateFormatter(end, SIMPLE_DATE, timeZone, locale)
    : '…';

  return i18n.t('shared.helpers.formatters.dateRange.range', {
    startTime: startFormatted,
    endTime: endFormatted,
  });
}
