import { DateTime } from 'luxon';

import { parseDateTime } from '../../../helpers/dates';

export function isToday(date: Date, timeZone?: string) {
  const now = DateTime.fromObject({ zone: timeZone }).startOf('day');
  const dateTime = parseDateTime(date, timeZone);

  if (!dateTime) return false;

  return (
    now.day === dateTime.day &&
    now.month === dateTime.month &&
    now.year === dateTime.year
  );
}
