import React, { useContext, useRef, useState } from 'react';
import {
  Checkbox,
  Dropdown,
  ErrorMessage,
  IconButton,
  Input,
  PageNavigationAlert,
} from '../../../../../components';
import { useHistory, useParams } from 'react-router-dom';
import { LaneType } from 'common-types';

import { getValidationMessages, hasPermission } from 'lane-shared/helpers';
import { H4, H5 } from '../../../../../components/typography';
import styles from './styles.scss';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { validateCreateInviteWithUniqueEmail } from 'lane-shared/validation';
import { getClient } from 'lane-shared/apollo';
import * as mutations from 'lane-shared/graphql/mutation';
import CardContainer from '../../../../../components/cards/CardContainer';
import { Button, Icon, Loading, M } from 'design-system-web';
import { Flex, PageHeader } from 'components/layout';
import { routes } from 'lane-shared/config';
import { AcgMultiSelect } from './AcgMultiSelect';

import { isTenantLocationChannel } from 'lane-shared/helpers/channel';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { useQuery } from '@apollo/client';
import {
  getBuildingTenantChannels,
  getGroupRole,
} from 'lane-shared/graphql/query';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BuildingTenantChannel } from 'graphql-resolver-contracts';
import { UserDataContext } from 'lane-shared/contexts';
import { FONT_AWESOME_LIGHT } from 'lane-shared/helpers/constants/icons';
import {
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE,
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
} from 'lane-shared/helpers/constants/permissions';
import cx from 'classnames';
import { Invite } from './types';
import { TeamCsvImportButton } from './TeamCsvImportButton';
import {
  getTenantBuildingIntegrationChannel,
  getBuildingChannelIntegration,
} from 'lane-shared/graphql/accessControl';
import { AccessGroupSelectItem } from '../../../../../domains/accessControl/types/AccessControl';
import { TeamMultiSelect } from './TeamMultiSelect';
import {
  doUsersExistByEmail,
  upsertUserGroupRoles,
} from 'lane-shared/graphql/user';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import Papa from 'papaparse';
import { makeFileDownload } from 'helpers';
import { SPECIAL_GROUP_ROLES } from 'lane-shared/helpers/constants/channel';

type Props = {
  channel: any;
};

export const TeamBulkInvite = ({ channel }: Props) => {
  const intuitiveUserManagementEnabled = useFlag(
    FeatureFlag.IntuitiveUserManagement,
    false
  );

  const myRef = useRef(null);
  const { t } = useTranslation();
  const history = useHistory();
  const [, setValidation] = useState(null);
  const [loading, setLoading] = useState(false);
  const { groupRoleId } = useParams<{ groupRoleId: string }>();
  const groupRoleResponse = useQuery(getGroupRole, {
    variables: {
      id: groupRoleId,
      skip: !intuitiveUserManagementEnabled, // if the ff is on, we fetch all of the channels teams in a child component
    },
  });

  let groupRole = null;

  if (groupRoleResponse?.data?.groupRole) {
    groupRole = groupRoleResponse?.data?.groupRole;
  }

  const channelId = channel?._id;

  const [
    enableMobileAccessOption,
    setEnableMobileAccessOption,
  ] = useState<boolean>(false);
  const [accessControlGroups, setAccessControlGroups] = useState<
    Map<string, string[]>
  >(new Map<string, string[]>([]));
  const [ACGValidationError, setACGValidationError] = useState<string | null>(
    null
  );

  // Tenant access
  const [tenantAccess, setTenantAccess] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [
    TenantAccessValidationError,
    setTenantAccessValidationError,
  ] = useState<string | null>(null);
  const isCompanyChannel = Boolean(channel && isTenantLocationChannel(channel));
  const { data: tenantBuildingInfo } = useQuery(
    getTenantBuildingIntegrationChannel,
    {
      variables: { channelId },
    }
  );
  const {
    data: buildingTenants,
  }: {
    data?: { getBuildingTenantChannels: BuildingTenantChannel[] };
  } = useQuery(getBuildingTenantChannels, {
    variables: { channelId },
    skip: isCompanyChannel,
  });

  // Mobile access
  const { user } = useContext(UserDataContext);
  const hasChannelPermission = (channelId: string, permission: string) =>
    hasPermission(user?.roles, [permission], channelId, true);

  const hasIntegrationChannelPermission = (integrationChannelId: string) =>
    channelId === integrationChannelId ||
    hasPermission(
      user?.roles,
      [PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY],
      integrationChannelId,
      true
    );

  // Handling cross channel access for v4.3
  const canAssignMobileAccess =
    channel?.settings?.hasAccessControlEnabled &&
    hasChannelPermission(
      channelId,
      PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE
    ) &&
    hasIntegrationChannelPermission(
      tenantBuildingInfo?.getTenantBuildingIntegrationChannel?._id
    );

  const hasOwnChannelIntegration =
    tenantBuildingInfo?.getTenantBuildingIntegrationChannel?._id === channelId;

  const { data: buildingChannelInfo } = useQuery(
    getBuildingChannelIntegration,
    {
      variables: { channelId },
      skip: !isCompanyChannel || !hasOwnChannelIntegration,
    }
  );

  const canAssignBuildingMobileAccessOnCompanyChannel = buildingChannelInfo?.getBuildingChannelIntegration
    ? hasChannelPermission(
        buildingChannelInfo.getBuildingChannelIntegration.channelId,
        PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY
      ) &&
      hasIntegrationChannelPermission(
        buildingChannelInfo.getBuildingChannelIntegration.channelId
      )
    : false;

  // User invites
  const [invites, setInvites] = useState<Invite[]>(
    Array(intuitiveUserManagementEnabled ? 1 : 5)
      .fill(1)
      .map(() => ({
        name: '',
        email: '',
        groupRoleId,
        _id: uuid(),
      }))
  );

  // Used for teams selected through
  const [selectedGroupRoles, setSelectedGroupRoles] = useState<
    { label: string; value: string }[]
  >([]);

  const [successfulInvite, setSuccessfulInvite] = useState(false);

  const [groupRoleValidationError, setGroupRoleValidationError] = useState<
    string | null
  >(null);

  const path = {
    defaultPath: '',
    submitPath: '',
  };

  if (intuitiveUserManagementEnabled) {
    path.defaultPath = routes.channelAdminUsersList.replace(':id', channelId);
    path.submitPath = routes.channelAdminUsersListPendingInviteTab.replace(
      ':id',
      channelId
    );
  } else {
    path.defaultPath = routes.channelAdminTeamsView
      .replace(':id', channelId)
      .replace(':groupRoleId', groupRoleId);

    path.submitPath = path.defaultPath;
  }

  const onCancel = () => {
    history.push(path.defaultPath);
  };

  const onSubmit = () => {
    history.push(path.submitPath);
  };

  function addInvite({ name = '', email = '', _id = uuid() }) {
    setInvites([
      ...invites,
      {
        name,
        email,
        ...(groupRoleId && { groupRoleId }),
        _id,
      },
    ]);

    // using setTimeout to scroll to last item in list after render
    setTimeout(() => {
      if (myRef && myRef.current) {
        (myRef.current as HTMLElement).lastElementChild?.scrollIntoView(true);
      }
    }, 0);
  }

  function updateInvite(update: Invite) {
    setInvites(prevState => {
      const ix = prevState.findIndex(invite => invite._id === update._id);

      prevState[ix] = {
        ...update,
      };

      return [...prevState];
    });
  }

  function scrollToInvite(id: LaneType.UUID) {
    const inviteInput = document.getElementById(id);

    if (inviteInput) {
      inviteInput.scrollIntoView();
    }
  }

  async function sendInvites() {
    setValidation(null);

    let failedToSend = 0;
    let successfulSend = 0;
    const createInviteValidator = validateCreateInviteWithUniqueEmail();

    let valid = true;

    for (const invite of invites) {
      try {
        invite.validation = null;

        if (invite.name || invite.email) {
          await createInviteValidator.validate(invite, { abortEarly: false });
        }
      } catch (error) {
        valid = false;
        invite.validation = error;
        setValidation(error);

        scrollToInvite(invite._id);
      }
    }

    const acgValidation =
      !accessControlGroups?.size ||
      [...accessControlGroups.values()].some(acgIds => acgIds.length === 0) ||
      (canAssignBuildingMobileAccessOnCompanyChannel &&
        accessControlGroups.size < 2);

    if (enableMobileAccessOption && acgValidation) {
      setACGValidationError(
        t(
          'web.admin.channel.teamManagement.team.BulkSendInviteV2.bulkSettings.accessGroups.errorMessage'
        )
      );
      valid = false;
    }

    if (tenantAccess && !companyId) {
      setTenantAccessValidationError(
        t(
          'web.admin.channel.teamManagement.team.BulkSendInviteV2.bulkSettings.tenantAdd.errorMessage'
        )
      );
      valid = false;
    }

    if (intuitiveUserManagementEnabled && selectedGroupRoles.length === 0) {
      setGroupRoleValidationError(
        t(
          'web.admin.channel.teamManagement.team.BulkSendInviteV2.bulkSettings.team.errorMessage'
        )
      );
      valid = false;
    }

    if (!valid) {
      setInvites([...invites]);

      return;
    }

    let accessControlGroupsFormulated: Array<any> = [];

    for (const [key, value] of accessControlGroups) {
      accessControlGroupsFormulated = [
        ...accessControlGroupsFormulated,
        { channelId: convertToUUID(key), acgIds: value },
      ];
    }

    setLoading(true);

    const validInvites = invites.filter(item => {
      return item.name && item.email;
    });

    const existingUsers: { [key: string]: string } = {};

    if (intuitiveUserManagementEnabled) {
      const emails = validInvites.map(invite => invite.email);

      // Check if any of the emails belong to an existing Activate user
      const { data } = await getClient().query({
        query: doUsersExistByEmail,
        variables: { emails },
      });

      data?.doUsersExistByEmail.forEach(
        (u: { userId: string; email: string }) =>
          (existingUsers[u.email] = u.userId)
      );
    }

    for (const inviteData of validInvites) {
      if (inviteData.wasSent || !inviteData.email || !inviteData.name) {
        continue;
      }

      const invitePayload: {
        name: string;
        email: string;
        groupRoleId?: string;
        mobileAccess: boolean;
        accessControlGroups: any[];
        companyId?: string;
        groupRoleIds?: string[];
      } = {
        name: inviteData.name,
        email: inviteData.email,
        groupRoleId: inviteData.groupRoleId,
        mobileAccess: enableMobileAccessOption,
        accessControlGroups: accessControlGroupsFormulated,
      };

      if (companyId) {
        invitePayload.companyId = companyId.value;
      }

      if (intuitiveUserManagementEnabled) {
        invitePayload.groupRoleIds = selectedGroupRoles.map(
          (groupRole: { value: string }) => groupRole.value
        );
      }

      try {
        if (existingUsers[inviteData.email] && intuitiveUserManagementEnabled) {
          // If the person we're trying to invite is already an Activate user upsert them to the specified group roles
          await getClient().mutate({
            mutation: upsertUserGroupRoles,
            variables: {
              userId: existingUsers[inviteData.email],
              groupRoleIds: invitePayload.groupRoleIds,
              ...(companyId && { companyId: companyId.value }),
            },
          });
        } else {
          // Otherwise invite them to the platform
          await getClient().mutate({
            mutation: mutations.sendInvite,
            variables: invitePayload,
          });
        }

        updateInvite({ ...inviteData, wasSent: true, error: null });
        successfulSend++;
      } catch (error) {
        updateInvite({ ...inviteData, wasSent: false, error });
        failedToSend++;
      }

      await window.Alert.loading({
        title: t(
          'web.admin.channel.teamManagement.team.BulkSendInvite.sending'
        ),
        children: (
          <progress
            style={{ width: '100%' }}
            value={successfulSend + failedToSend}
            max={validInvites.length}
          />
        ),
      });
    }

    scrollToInvite(validInvites[validInvites.length - 1]._id);

    setLoading(false);

    setTimeout(() => {
      window.Alert.hide();
    }, 1000);

    if (successfulSend > 0) {
      window.Toast.show(
        t(
          'web.admin.channel.teamManagement.team.BulkSendInvite.successfulSend',
          {
            count: successfulSend,
          }
        ),
        2000
      );
    }

    if (failedToSend > 0) {
      window.Toast.show(
        t('web.admin.channel.teamManagement.team.BulkSendInvite.failedToSend', {
          count: failedToSend,
        })
      );
    }

    if (failedToSend === 0) {
      setSuccessfulInvite(true);
      setTimeout(() => {
        window.Alert.hide();
        onSubmit();
      }, 0);
    }
  }

  const setSelectedAccessControlGroups = (
    acgItems: AccessGroupSelectItem[],
    channelId: string
  ) => {
    const acgIds = acgItems.map(acgItem => acgItem.value);
    const integrationChannelId = !hasOwnChannelIntegration
      ? tenantBuildingInfo.getTenantBuildingIntegrationChannel._id
      : channelId;

    accessControlGroups.set(integrationChannelId, acgIds);

    setAccessControlGroups(accessControlGroups);
    setACGValidationError(null);
  };

  const csvTemplateColumns = ['Full name', 'Email'];

  const handleExportToCSV = () => {
    const csvData = [];
    const headers = csvTemplateColumns.map(column => column);

    csvData.push(headers);

    const csv = Papa.unparse(csvData);
    const filename = `invite-users-template.csv`;

    makeFileDownload({
      name: filename,
      contents: csv,
      type: 'application/csv',
    });
  };

  // Render variables
  const pageHeaderProps = {
    header: intuitiveUserManagementEnabled
      ? t('web.admin.channel.teamManagement.team.BulkSendInviteV2.header')
      : t(
          'web.admin.channel.teamManagement.team.BulkSendInviteV2.titleWithName',
          {
            groupRoleName: groupRole?.name,
          }
        ),
    breadcrumbs: [
      {
        label: t('web.pages.portal.admin.users.index.header'),
        url: path.defaultPath,
      },
      {
        label: intuitiveUserManagementEnabled
          ? t('web.admin.channel.teamManagement.team.BulkSendInviteV2.header')
          : t(
              'web.admin.channel.teamManagement.team.BulkSendInviteV2.breadCrumbTitle'
            ),
      },
    ],
  };

  const handleTeamMultiSelect = (
    options: { label: string; value: string }[]
  ) => {
    setGroupRoleValidationError(null);
    setSelectedGroupRoles(options);
  };

  const areAllInvitesEmpty =
    invites.filter(item => {
      if (intuitiveUserManagementEnabled) {
        return item.name && item.email;
      }

      return item.name || item.email;
    }).length === 0;

  if (!channel)
    return <Loading testId="loadingBulkInvite" style={{ margin: 'auto' }} />;

  const removeIconProps = intuitiveUserManagementEnabled
    ? {
        icon: 'trash-alt',
        className: styles.trashIcon,
        disabled: invites.length === 1,
      }
    : { icon: 'times', inverted: true, className: styles.removeButton };

  const bulkSettingsCards = (
    <>
      {(canAssignMobileAccess ||
        canAssignBuildingMobileAccessOnCompanyChannel) && (
        <CardContainer
          className={styles.bulkSettingsInnerCard}
          style={{
            marginBottom: intuitiveUserManagementEnabled ? 0 : 16,
          }}
        >
          <Checkbox
            onChange={() => {
              if (enableMobileAccessOption === true) {
                setAccessControlGroups(new Map());
              }

              setEnableMobileAccessOption(!enableMobileAccessOption);
              setACGValidationError(null);
            }}
            text={t(
              'web.admin.channel.teamManagement.team.BulkSendInviteV2.bulkSettings.enableMobileAccess'
            )}
            selected={enableMobileAccessOption}
            value={enableMobileAccessOption}
            testId="bulkInviteMobileAccessCheckbox"
          />

          {enableMobileAccessOption &&
            canAssignBuildingMobileAccessOnCompanyChannel && (
              <div
                className={styles.bulkSettingsInnerCardSpacing}
                data-test="select-access-groups-section-v5-building"
              >
                <H5>
                  {t(
                    'web.admin.channel.teamManagement.team.BulkSendInviteV2.bulkSettings.accessGroups.title'
                  )}
                </H5>
                <AcgMultiSelect
                  channelId={channelId}
                  channelName={
                    buildingChannelInfo.getBuildingChannelIntegration
                      .channelName
                  }
                  integrationChannelId={
                    buildingChannelInfo.getBuildingChannelIntegration.channelId
                  }
                  onChange={setSelectedAccessControlGroups}
                />
              </div>
            )}
          {enableMobileAccessOption && (
            <div
              className={styles.bulkSettingsInnerCardSpacing}
              data-test="select-access-groups-section"
            >
              <H5>
                {t(
                  'web.admin.channel.teamManagement.team.BulkSendInviteV2.bulkSettings.accessGroups.title'
                )}
              </H5>
              <AcgMultiSelect
                channelId={channelId}
                channelName={
                  tenantBuildingInfo.getTenantBuildingIntegrationChannel.name
                }
                onChange={setSelectedAccessControlGroups}
              />
            </div>
          )}
          <ErrorMessage error={ACGValidationError} />
        </CardContainer>
      )}
      {!isCompanyChannel && (
        <CardContainer className={styles.bulkSettingsInnerCard}>
          <Checkbox
            onChange={() => {
              setTenantAccess(!tenantAccess);
              setCompanyId(null);
              setTenantAccessValidationError(null);
            }}
            text={t(
              'web.admin.channel.teamManagement.team.BulkSendInviteV2.bulkSettings.tenantAdd.toggle'
            )}
            selected={tenantAccess}
            value={tenantAccess}
            testId="bulkInviteTenantAccessCheckbox"
          />
          {tenantAccess && (
            <div
              className={styles.bulkSettingsInnerCardSpacing}
              data-test="tenant-access-dropdown-section"
            >
              <Dropdown
                placeholder="web.admin.channel.teamManagement.team.BulkSendInviteV2.bulkSettings.tenantAdd.dropdownPlaceholder"
                items={
                  buildingTenants?.getBuildingTenantChannels
                    ? buildingTenants?.getBuildingTenantChannels?.map(item => ({
                        label: item.name!,
                        value: item.channelId,
                      }))
                    : []
                }
                value={companyId}
                onChange={(value: any) => {
                  setCompanyId(value);
                  setTenantAccessValidationError(null);
                }}
                isFullWidth
              />
            </div>
          )}
          <ErrorMessage error={TenantAccessValidationError} />
        </CardContainer>
      )}
    </>
  );

  const bulkSettingsContainer = intuitiveUserManagementEnabled ? (
    <div className={styles.bulkSettingsSeparateCardsContainer}>
      <TeamMultiSelect
        channelId={channel?._id}
        groupRoles={selectedGroupRoles}
        onChange={handleTeamMultiSelect}
        errorMessage={groupRoleValidationError}
        defaultSelectionGroupRoleNames={[SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER]}
      />
      {bulkSettingsCards}
    </div>
  ) : (
    (!isCompanyChannel || canAssignMobileAccess) && (
      <CardContainer className={cx(styles.BulkSendInvite, styles.bulkSettings)}>
        <div className={styles.actionButtons}>
          <div>
            <Icon
              className={styles.gearIcon}
              name="cog"
              size="medium"
              type={FONT_AWESOME_LIGHT}
            />
            <H5 className={styles.bulkSettingsTitle}>
              {t(
                'web.admin.channel.teamManagement.team.BulkSendInviteV2.bulkSettings.title'
              )}
            </H5>
            <M className={styles.subtitle}>
              {t(
                'web.admin.channel.teamManagement.team.BulkSendInviteV2.bulkSettings.subtitle'
              )}
            </M>
          </div>
        </div>
        {bulkSettingsCards}
      </CardContainer>
    )
  );

  const isPristine = () => {
    const areAllInvitesEmpty =
      invites.filter(invite => {
        return invite.name || invite.email;
      }).length === 0;

    return (
      areAllInvitesEmpty &&
      !enableMobileAccessOption &&
      !tenantAccess &&
      selectedGroupRoles.length === 0
    );
  };

  return (
    <div className={styles.outerWrapper}>
      {intuitiveUserManagementEnabled && (
        <PageNavigationAlert
          when={!isPristine() && !successfulInvite}
          testId="pageNavigationAlert"
        />
      )}
      <PageHeader
        {...pageHeaderProps}
        headerLevel="h3"
        description={
          !intuitiveUserManagementEnabled
            ? t(
                'web.admin.channel.teamManagement.team.BulkSendInviteV2.description'
              )
            : undefined
        }
      />
      <div className={styles.cardContainer}>
        <div
          className={cx(
            styles.cards,
            intuitiveUserManagementEnabled && styles.reverse
          )}
        >
          <Flex
            direction="column"
            m={[0, 5]}
            className={!intuitiveUserManagementEnabled && styles.wrapper}
          >
            {bulkSettingsContainer}
          </Flex>
          <Flex direction="column" m={[0, 5]}>
            <CardContainer
              className={cx(
                styles.BulkSendInvite,
                styles.bulkSettings,
                intuitiveUserManagementEnabled && styles.inviteUserColor
              )}
            >
              {intuitiveUserManagementEnabled ? (
                <div className={styles.inviteUserHeader}>
                  <div>
                    <H4 style={{ marginRight: 10 }}>
                      {t(
                        'web.admin.channel.teamManagement.team.BulkSendInviteV2.inviteUsers.userInformation'
                      )}
                    </H4>
                  </div>
                  <div className={styles.headerButtons}>
                    <Button
                      variant="text"
                      onClick={() => {
                        handleExportToCSV();
                      }}
                    >
                      {t(
                        'web.admin.channel.teamManagement.team.BulkSendInviteV2.downloadTemplate.title'
                      )}
                    </Button>
                    <TeamCsvImportButton
                      groupRoleId={groupRoleId}
                      loading={loading}
                      setInvites={setInvites}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.actionButtons}>
                  <div>
                    <H4 style={{ marginRight: 10 }}>
                      {t(
                        'web.admin.channel.teamManagement.team.BulkSendInviteV2.inviteUsers.title'
                      )}
                    </H4>
                    <M className={styles.subtitle}>
                      {t(
                        'web.admin.channel.teamManagement.team.BulkSendInviteV2.inviteUsers.subtitle',
                        { numberOfRows: invites.length }
                      )}
                    </M>
                  </div>
                  <div>
                    <TeamCsvImportButton
                      groupRoleId={groupRoleId}
                      loading={loading}
                      setInvites={setInvites}
                    />
                    <Button
                      className={styles.button}
                      variant="tertiary"
                      startIcon={<Icon size="small" name="plus" />}
                      onClick={() => addInvite({})}
                      testId="add-row-to-user-input-list"
                    >
                      {t(
                        'web.admin.channel.teamManagement.team.BulkSendInviteV2.inviteUsers.addInviteButtonText'
                      )}
                    </Button>
                  </div>
                </div>
              )}
              <ul
                ref={myRef}
                style={{
                  maxHeight: 480,
                  overflow: 'auto',
                }}
                data-test="user-input-list"
              >
                {invites.map((invite, i) => (
                  <li key={i} id={invite._id} data-test="user-input-list-row">
                    <ErrorMessage error={invite.error} />
                    <Input
                      className={styles.input}
                      label="Full name"
                      error={getValidationMessages(invite.validation, 'name')}
                      value={invite.name}
                      placeholder="Full name"
                      disabled={invite.wasSent}
                      onChange={name => {
                        invite.name = name;
                        setInvites([...invites]);
                      }}
                      fixedLabel={intuitiveUserManagementEnabled}
                    />
                    <Input
                      className={styles.input}
                      error={getValidationMessages(invite.validation, 'email')}
                      label="Email"
                      value={invite.email}
                      placeholder="Email"
                      disabled={invite.wasSent}
                      onChange={email => {
                        invite.email = email.replace(/\s/g, '');
                        setInvites([...invites]);
                      }}
                      fixedLabel={intuitiveUserManagementEnabled}
                    />
                    {invite.wasSent ? (
                      <IconButton
                        icon="check"
                        testId="removeEmail"
                        inverted
                        className={styles.checkButton}
                        onClick={() =>
                          setInvites(invites.filter(i => i._id !== invite._id))
                        }
                      />
                    ) : (
                      <IconButton
                        {...removeIconProps}
                        testId="removeEmail"
                        onClick={() =>
                          setInvites(invites.filter(i => i._id !== invite._id))
                        }
                      />
                    )}
                  </li>
                ))}
              </ul>
              {intuitiveUserManagementEnabled && (
                <>
                  <div className={styles.addMoreButtonBorder} />
                  <Button
                    className={cx(styles.button)}
                    variant="secondary"
                    startIcon={<Icon size="small" name="plus" />}
                    onClick={() => addInvite({})}
                    testId="add-row-to-user-input-list"
                  >
                    {t(
                      'web.admin.channel.teamManagement.team.BulkSendInviteV2.inviteUsers.addMoreUsers'
                    )}
                  </Button>
                </>
              )}
            </CardContainer>
          </Flex>
        </div>
        <div className={styles.actionButtonsBottom}>
          <Button
            testId="sendInvite"
            variant="primary"
            size="large"
            className={styles.button}
            onClick={() => sendInvites()}
            loading={loading}
            disabled={areAllInvitesEmpty}
          >
            {t(
              'web.admin.channel.teamManagement.team.BulkSendInviteV2.sendInvitations'
            )}
          </Button>

          <Button
            className={styles.button}
            variant="tertiary"
            size="large"
            onClick={onCancel}
          >
            {t(
              'web.admin.channel.teamManagement.team.BulkSendInviteV2.clearInvitesButtonText'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
