import * as yup from 'yup';
import i18next from 'i18next';
import { isValidPhoneNumber } from '../helpers/phoneNumbers';

const commonCreateInviteValidations = {
  name: yup
    .string()
    .trim()
    .min(
      2,
      i18next.t('web.admin.users.invitation.validationError.nameMinLength')
    )
    .required(),
  groupRoleId: yup.string(),
  companyId: yup.string().notRequired(),
  expires: yup.date().min(new Date()),
  mobileAccess: yup.boolean().notRequired(),
  accessControlGroups: yup.array().of(
    yup.object({
      channelId: yup.string(),
      acgIds: yup.string(),
    })
  ),
  groupRoleIds: yup.array().of(yup.string()),
  phone: yup
    .string()
    .trim()
    .test(
      'phone-validation',
      i18next.t('web.admin.users.invitation.validationError.invalidPhone'),
      value => {
        if (!value) {
          return true;
        }

        return value != null && isValidPhoneNumber(value, 'US');
      }
    ),
};

const validateCreateInvite = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email(i18next.t('web.admin.users.invitation.validationError.email'))
    .required(),
  ...commonCreateInviteValidations,
});

const validateCreateInviteWithUniqueEmail = <T extends { email: string }>() => {
  const emailMap = new Map<string, T>();

  return yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(i18next.t('web.admin.users.invitation.validationError.email'))
      .required()
      .test('is-duplicate', 'Duplicate value', function (value) {
        if (!value) return true;

        const normalizedVal = value.toLowerCase();

        if (emailMap.has(normalizedVal)) {
          const originalVal = emailMap.get(normalizedVal)?.email;

          return this.createError({
            message: i18next.t(
              'web.admin.users.invitation.validationError.email.repeated',
              { email: originalVal }
            ),
            path: 'email',
          });
        }

        emailMap.set(normalizedVal, this.parent);

        return true;
      }),
    ...commonCreateInviteValidations,
  });
};

export default validateCreateInvite;
export { validateCreateInviteWithUniqueEmail };
