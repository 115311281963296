import { DateTime } from 'luxon';

export function getJoinedDateStringFromGroupRoles(groupRoles: any) {
  if (groupRoles.length === 0) {
    return null;
  }

  let earliestDate = groupRoles[0]._created;

  groupRoles.forEach((groupRole: any) => {
    if (Date.parse(groupRole._created) < Date.parse(earliestDate)) {
      earliestDate = groupRole._created;
    }
  });

  return DateTime.fromISO(earliestDate, { zone: 'utc' }).toFormat(
    "MMMM dd, yyyy';' hh:mm a"
  );
}
