import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { FeatureDefinition } from 'lane-shared/types/FeatureDefinition';

import Checkbox from 'components/form/Checkbox';

import styles from './FeatureMenuItem.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  feature: FeatureDefinition;
  isSelected: boolean;
  isActive: boolean;
  hasError?: boolean;
  onFeatureSelected: (featureName: string) => void;
  onFeatureEnabled: (isActive: boolean) => void;
  disableToggle?: boolean;
};

export default function FeatureMenuItem({
  className,
  style,
  feature,
  isSelected,
  isActive,
  onFeatureSelected,
  onFeatureEnabled,
  hasError = false,
  disableToggle = false,
}: Props) {
  const { t } = useTranslation();

  return (
    <div
      className={cx(styles.FeatureMenuItem, className)}
      style={style}
      data-is-active={isActive}
      data-is-selected={isSelected}
      data-has-error={hasError}
    >
      <Checkbox
        selected={isActive}
        name={feature.name}
        onChange={() => onFeatureEnabled(isActive)}
        testId={`featureCheckbox${feature.name}`}
        value
        disabled={disableToggle}
      />
      <button onClick={() => onFeatureSelected(feature.name)}>
        <span>{t(feature.friendlyName || '')}</span>
        <Icon name="chevron-right" className={styles.chevronRight} />
      </button>
    </div>
  );
}
