// NOTE: Performs sort in an ascending order
export default function sortByCreated(a: any, b: any) {
  if (a._created < b._created) {
    return -1;
  }

  if (b._created < a._created) {
    return 1;
  }

  return 0;
}
