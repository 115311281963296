import React, { useState, useContext } from 'react';

import { Button, ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { AppContext, UserDataContext } from 'lane-shared/contexts';
import { getValidationMessages } from 'lane-shared/helpers';
import { getPrimaryEmail } from 'lane-shared/helpers/user';
import { AccountContactPair } from 'lane-shared/types/Essensys';

import { Input } from 'components/form';
import { XS } from 'components/typography';

import styles from './styles/AccountForm.scss';

const validationRules = yup.object().shape({
  email: yup.string().trim().email('Must be a valid email').required(),
  companyName: yup.string().trim().required('Company name is required'),
});

type Props = {
  createAccountContactPair: (
    companyName: string,
    email: string
  ) => Promise<AccountContactPair>;
  buttonLabel: string;
  className?: string;
  defaultCompanyName?: string;
};

export default function AccountForm({
  createAccountContactPair,
  buttonLabel,
  className,
  defaultCompanyName,
}: Props) {
  const { t } = useTranslation();

  const userData = useContext(UserDataContext);
  const primaryEmail = getPrimaryEmail(userData.user?.logins);
  const userName = userData.user?.profile.name;

  const app = useContext(AppContext);

  const [email, setEmail] = useState(primaryEmail);
  const [companyName, setCompanyName] = useState(
    defaultCompanyName ?? `${userName} via ${app.whitelabel.name}`
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [validation, setValidation] = useState<yup.ValidationError | null>(
    null
  );

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await validationRules.validate({ email, companyName });
    } catch (error) {
      setValidation(error);
      setLoading(false);

      return;
    }

    try {
      // email is validated to be a string on line 62^
      await createAccountContactPair(companyName, email as string);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  }

  const emailError =
    Boolean(email) && getValidationMessages(validation, 'email');
  const companyNameError =
    Boolean(companyName) && getValidationMessages(validation, 'companyName');
  const isButtonDisabled = !email || !companyName || Boolean(validation);

  return (
    <div className={className}>
      <form className="control-group" onSubmit={submit}>
        <Input
          className={styles.input}
          onChange={email => {
            setValidation(null);
            setEmail(email);
          }}
          value={email}
          label={t('Email')}
          error={emailError || null}
        />
        <Input
          className={styles.input}
          onChange={companyName => {
            setValidation(null);
            setCompanyName(companyName);
          }}
          value={companyName}
          label={t('Company name')}
          error={companyNameError || null}
        />
        <ErrorMessage error={error} />
        <Button
          fullWidth
          variant="contained"
          disabled={isButtonDisabled}
          loading={loading}
          type="submit"
        >
          {buttonLabel}
        </Button>
      </form>
      <XS variant="secondary" className={styles.poweredBy}>
        {t('Powered by Operate')}
      </XS>
    </div>
  );
}
