import { DateTime, Zone } from 'luxon';

import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import { ReservableFeatureRuleType } from 'lane-shared/types/features/ReservableFeatureProperties';

import { parseDateTime } from '../dates';

export function getMinMaxDateByGroupRole({
  dateRange,
  bestRule,
  viewingDate,
  timeZone,
}: {
  bestRule: ReservableFeatureRuleType;
  viewingDate?: DateTime;
  timeZone?: string | Zone;
  dateRange: DateRangeType | null | undefined;
}): { minDate: DateTime | null; maxDate: DateTime | null } {
  const now = viewingDate || DateTime.local();
  const yesterday = now.minus({ day: 1 }).endOf('day');
  // min date should be the earlier of the start date on the reservable, or
  // today (because people can't book things in the past anyways.)
  let minDate = parseDateTime(dateRange?.startDate || now, timeZone);

  // minDate may also be effected by the rules for unitsAfterNow
  if (bestRule?.unitsAfterNow) {
    const possibleMinDate = now.plus({
      [bestRule.unitsAfterNowType]: bestRule.unitsAfterNow,
    });

    if (!minDate || possibleMinDate > minDate) {
      minDate = possibleMinDate;
    }
  }

  // if no start date was set or that start date is too far in the past
  if (!minDate || minDate < yesterday) {
    minDate = yesterday;
  }

  let maxDate = parseDateTime(dateRange?.endDate, timeZone);

  // maxDate could be effected by the rules for unitsBeforeStart.
  // this will be the max amount of time that the user can book from now.
  if (bestRule?.unitsBeforeStart) {
    const possibleMaxDate = now.plus({
      [bestRule.unitsBeforeStartType]: bestRule.unitsBeforeStart,
    });

    if (!maxDate || maxDate > possibleMaxDate) {
      maxDate = possibleMaxDate;
    }
  }

  return {
    minDate,
    maxDate,
  };
}
