import { appUrl } from '../../../config';

export function constructFullAuth0SAMLUrl(integrationId: string) {
  const samlParameters = new URLSearchParams({
    integrationId,
    ts: Date.now().toString(),
  });

  return `${appUrl}/sso/auth0/saml?${samlParameters.toString()}`;
}
