import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import cx from 'classnames';

import { M } from 'components/typography';
import { Button } from 'design-system-web';

import MixedStateList from 'lane-web/src/domains/accessControl/components/user-access/MixedStateList';
import {
  Data,
  DataStatus,
} from 'lane-web/src/domains/accessControl/components/user-access/MixedStateList/types';

import styles from './styles.scss';

type props = {
  availableLocations: Data[];
  onClose: () => void;
  onSubmit: (locations: Data[]) => void;
};

export function LocationsList({
  availableLocations,
  onClose,
  onSubmit,
}: props) {
  const { t } = useTranslation();

  const [locations, setLocations] = useState(availableLocations);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSelectChange = (selectedLocations: Data[]) => {
    setLocations(selectedLocations);
    const isDisabled = selectedLocations.every(
      location => location.status === DataStatus.unselected
    );

    setIsDisabled(isDisabled);
  };

  const handleSubmit = () => {
    onSubmit(locations);
  };

  return (
    <div className={styles.locationsListContainer}>
      <MixedStateList
        mixedStateData={locations}
        onChange={handleSelectChange}
        titleComponent={
          <div>
            <M>
              {t(
                'web.admin.accessControl.userAccess.modal.enableMobileAccess.selectLoaction.description'
              )}
            </M>
          </div>
        }
        showLogo
        title={t('Locations')}
        searchPlaceholder={t(
          'web.admin.accessControl.userAccess.mobileAccess.searchLocations.placeholder'
        )}
        enableSelectAll
      />

      <div className={cx(styles.buttons)}>
        <Button
          variant="primary"
          testId="locationsListEnableButton"
          onClick={handleSubmit}
          disabled={isDisabled}
          size="large"
        >
          {t('web.admin.accessControl.userAccess.modal.enable.continue')}
        </Button>
        <Button
          variant="text"
          onClick={onClose}
          className={styles.cancelButton}
          testId="locationsListCancelButton"
          size="large"
        >
          {t('Cancel')}
        </Button>
      </div>
    </div>
  );
}
