import Storage from 'lane-shared/helpers/Storage';
import { Charge } from '../../types';

const getStoredCharges = async (storageKey: string) => {
  let storedCharges: Charge[] = [];

  try {
    const item = await Storage.getItem(storageKey);

    if (item) {
      storedCharges = (item as unknown) as Charge[];
    }
  } catch (e) {
    // none saved
  }

  return storedCharges as Charge[];
};

const appendStoredCharge = async (storageKey: string, charge: Charge) => {
  const storedCharges = await getStoredCharges(storageKey);

  storedCharges.push(charge);
  await Storage.setItem(storageKey, storedCharges);
};

const filterStoredCharges = async (
  storageKey: string,
  loadedCharges: Charge[]
) => {
  const chargeIds = loadedCharges.map(charge => charge.id);

  const storedCharges = await getStoredCharges(storageKey);
  // need to remove stored charges that are being loaded by list charges
  const filteredStoredCharges = storedCharges.filter(
    storedCharge => !chargeIds.includes(storedCharge.id)
  );

  await Storage.setItem(storageKey, filteredStoredCharges);

  return filteredStoredCharges;
};

export { getStoredCharges, appendStoredCharge, filterStoredCharges };
