import { checkSecurityRulesAgainstObject } from '..';
import { UserGroupRoleType } from '../../types/UserGroupRole';
import { IntegrationProviderEnum } from '../../types/integrations/IntegrationEnums';
import { SecurityModesEnum } from '../../types/properties/PropertySecurity';
import { NestedPropertyRules } from 'lane-shared/types/properties/Property';
import getIntegrationDefinition, {
  getNestedIntegrationDefinition,
} from './getIntegrationDefinition';
import { get } from 'lodash';

type CheckIntegrationSecurityRulesProps = {
  // the channel integration object that we are securing
  readonly channelIntegration: any;
  // channel that this integration belongs to
  readonly channelId: string;
  // which action is being performed? read, update, etc?
  readonly action: SecurityModesEnum;
  // an array of group roles this user has, will check against the rules
  readonly userRoles: UserGroupRoleType[];
  // the id of the user making this secure request
  readonly userId: string;
};

type CheckIntegrationSecurityRulesOnNestedFieldsProps = CheckIntegrationSecurityRulesProps & {
  readonly nestedPropertyRules: NestedPropertyRules;
  readonly nestedSettings: any;
};

function checkIntegrationSecurityRulesOnNestedFields({
  channelIntegration,
  userId,
  userRoles,
  channelId,
  action,
  nestedPropertyRules,
  nestedSettings,
}: CheckIntegrationSecurityRulesOnNestedFieldsProps) {
  const nestedFields: { [key: string]: any } = {};

  if (!nestedPropertyRules?.dynamicProviderKey) {
    return nestedFields;
  }

  // We will try to fetch the provider from the settings, if it's not there, we will use the key directly.
  const provider = get(
    channelIntegration.settings,
    nestedPropertyRules.dynamicProviderKey,
    nestedPropertyRules.dynamicProviderKey
  );

  if (!provider) {
    return nestedFields;
  }

  const nestedDefinitions = getNestedIntegrationDefinition(
    provider as IntegrationProviderEnum
  );

  if (Object.keys(nestedSettings).length === 0) {
    return nestedFields;
  }

  Object.entries(nestedSettings).forEach(([key, value]) => {
    const nestedFieldDefinition = nestedDefinitions[key];

    if (!nestedFieldDefinition) {
      nestedFields[key] = value;

      return;
    }

    const isAllowed =
      !nestedFieldDefinition.secure ||
      checkSecurityRulesAgainstObject({
        permissions: [],
        user: { _id: userId, roles: userRoles },
        rules: nestedFieldDefinition.secure?.[action] ?? [],
        sourceId: channelId,
        ownerId:
          channelIntegration._createdBy?._id || channelIntegration._createdBy,
      });

    if (isAllowed) {
      nestedFields[key] = value;
    }
  });

  return nestedFields;
}

export default function checkIntegrationSecurityRules({
  channelIntegration,
  channelId,
  action = SecurityModesEnum.Read,
  userRoles,
  userId,
}: CheckIntegrationSecurityRulesProps): object {
  // Don't modify the original object, return a new one.
  const ret: { [key: string]: any } = {};

  if (!channelIntegration) {
    return ret;
  }

  const definition = getIntegrationDefinition(
    channelIntegration.integration.name as IntegrationProviderEnum
  );

  if (!definition?.properties) {
    return ret;
  }

  // Iterate over the integration properties definition.
  Object.entries(channelIntegration.settings).forEach(([key, value]) => {
    const fieldDefinition = definition.properties[key];

    if (fieldDefinition?.nestedPropertyRules?.checkSecurity) {
      ret[key] = checkIntegrationSecurityRulesOnNestedFields({
        channelIntegration,
        userId,
        userRoles,
        channelId,
        action,
        nestedPropertyRules: fieldDefinition.nestedPropertyRules,
        nestedSettings: value,
      });
    } else {
      const isAllowed =
        !fieldDefinition?.secure ||
        checkSecurityRulesAgainstObject({
          permissions: [],
          user: { _id: userId, roles: userRoles },
          rules: fieldDefinition.secure?.[action] ?? [],
          sourceId: channelId,
          ownerId:
            channelIntegration._createdBy?._id || channelIntegration._createdBy,
        });

      if (isAllowed) {
        ret[key] = value;
      }
    }
  });

  return ret;
}
