import React, { useState, useEffect, useContext } from 'react';

import { graphql } from 'lane-shared';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import urlJoin from 'url-join';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import {
  AppContext,
  UserDataContext,
  AnalyticsContext,
} from 'lane-shared/contexts';
import { getDisplayName, pause } from 'lane-shared/helpers';
import { emitEmailAddress } from 'lane-shared/helpers/analytics/emitEmailAddress';
import { emitPhoneNumber } from 'lane-shared/helpers/analytics/emitPhoneNumber';
import { useMySecureId } from 'lane-shared/hooks';
import { validateUpdateUser } from 'lane-shared/validation';

import Box from 'components/lds/Box';

import Checkbox from '../form/Checkbox';
import Input from '../form/Input';
import PhoneNumberInput from '../form/PhoneNumberInput/index';
import Button from '../general/Button';
import ErrorMessage from '../general/ErrorMessage';
import ProfileQRCard from './ProfileQRCard';

import styles from './UserProfileSettings.scss';

function UserProfileSettings({ user }: any) {
  const [loading, setLoading] = useState(false);
  const { secureId } = useMySecureId();
  const { whitelabel } = useContext(AppContext);
  const { refetch: refetchUser } = useContext(UserDataContext);
  const analytics = useContext(AnalyticsContext);
  const [userProfile, setUserProfile] = useState({
    name: '',
    email: '',
    phone: '',
    isPublic: null,
  });
  const [validation, setValidation] = useState(null);
  const [requestError, setRequestError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (user?._id) {
      setUserProfile({
        name: getDisplayName(user),
        email: user.profile.email,
        phone: user.profile.phone,
        isPublic: user.profile.isPublic,
      });
    }
  }, [user?._id]);

  function validateUserForm() {
    setRequestError(null);
    setValidation(null);

    try {
      validateUpdateUser.validateSync(userProfile);
    } catch (err) {
      setValidation(err);
    }
  }

  function onChange(props: any) {
    setUserProfile({
      ...userProfile,
      ...props,
    });

    validateUserForm();
  }

  async function updateUserInfo() {
    setLoading(true);

    try {
      await getClient().mutate({
        mutation: graphql.user.updateUser,
        variables: {
          user: {
            _id: user._id,
            profile: {
              name: userProfile.name,
              email: userProfile.email,
              isPublic: userProfile.isPublic,
              phone: userProfile.phone || user.profile.phone,
            },
          },
        },
      });

      refetchUser();

      if (userProfile.phone !== user.profile.phone) {
        emitPhoneNumber({
          isPreviousPhoneNumber: Boolean(user.profile.phone),
          userId: user._id,
          analytics,
        });
      }

      if (userProfile.email !== user.profile.email) {
        emitEmailAddress({
          isPreviousEmail: Boolean(user.profile.email),
          userId: user._id,
          analytics,
        });
      }

      await pause(1000);
    } catch (err) {
      setRequestError(err);
    }

    setLoading(false);
  }

  function findErrors(field: any) {
    const errors = (validation as any)?.inner?.find(
      (err: any) => err.path === field
    );

    return errors ? errors.errors : null;
  }

  const isSaveButtonDisabled = !!validation;

  const url = urlJoin(
    whitelabel?.url,
    `/l/user/${user?._id}${secureId ? `?secureId=${secureId}` : ``}`
  );

  return (
    <Box className={styles.UserProfileSettings}>
      <div className={styles.imageContent}>
        <ProfileQRCard user={user} url={url} />
      </div>
      <Link className={styles.link} to={routes.user.replace(':id', user?._id)}>
        {t('See my public profile')}
      </Link>
      <div className={styles.form}>
        <Input
          label="Name"
          testId="name"
          className={styles.editableData}
          value={userProfile.name}
          onChange={name => onChange({ name })}
          error={findErrors('name')}
        />
        <Input
          testId="displayEmail"
          className={styles.editableData}
          value={userProfile.email}
          label="Display Email"
          onChange={email => onChange({ email })}
          error={findErrors('email')}
        />
        <PhoneNumberInput
          label={t('Phone Number')}
          value={userProfile.phone}
          testId="displayPhone"
          onChange={phone => onChange({ phone })}
          error={findErrors('phone')}
        />
        <Checkbox
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'boolean'.
          selected={userProfile.isPublic}
          onChange={() => onChange({ isPublic: !userProfile.isPublic })}
          text={t('Make your profile public?')}
          testId="isPublic"
          className={styles.checkbox}
        />
        <ErrorMessage error={requestError} />
      </div>

      <div className={styles.buttons}>
        <Button
          className={styles.button}
          loading={loading}
          disabled={isSaveButtonDisabled}
          onClick={updateUserInfo}
          variant="contained"
          testId="saveProfileButton"
        >
          {t('Save')}
        </Button>
      </div>
    </Box>
  );
}

export default UserProfileSettings;
