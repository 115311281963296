import React from 'react';

import { useTranslation } from 'react-i18next';

import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';
import { PropertyType } from 'lane-shared/types/properties/Property';

import DropdownDataValidationRuleValue from 'components/workflows/dataValidationSchema/dataValidationRuleValue/DropdownDataValidationRuleValue';
import { DataValidationRuleValueProps } from 'components/workflows/dataValidationSchema/type';

import style from '../DataValidationSchema.scss';

export function getRuleValueComponent(
  property: PropertyType
): React.FunctionComponent<DataValidationRuleValueProps> | undefined {
  if (property.packagedType === PackagedTypeEnum.Dropdown) {
    return DropdownDataValidationRuleValue;
  }

  return undefined;
}

export default function DataValidationRuleValue({
  maybeDraftRule,
  property,
  onRuleValueUpdated,
  editMode = true,
  validationError,
}: DataValidationRuleValueProps) {
  const { t } = useTranslation();
  const Component = getRuleValueComponent(property);

  if (!Component) {
    return (
      <div className={style.row}>
        <div className={style.label}>
          {t(
            'web.admin.content.editor.dataValidationRuleValue.unsupportedType'
          )}
        </div>
      </div>
    );
  }

  return (
    <Component
      maybeDraftRule={maybeDraftRule}
      property={property}
      onRuleValueUpdated={onRuleValueUpdated}
      editMode={editMode}
      validationError={validationError}
    />
  );
}
