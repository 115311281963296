import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { AppContext } from 'lane-shared/contexts';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import Checkbox from 'components/form/Checkbox';
import { S } from 'components/typography';

import styles from './EmailOptInLegacy.scss';

/**
 *
 * @deprecated
 */
export default function EmailOptInLegacy({ emailOptIn, onChange }: any) {
  const { t } = useTranslation();
  const { whitelabel } = useContext(AppContext);
  const { settings } = whitelabel;
  const { emailSubscriptionOptIn } = settings;
  const enableComplianceContact = useFlag(
    FeatureFlag.EnableComplianceContact,
    false
  );

  if (!emailSubscriptionOptIn) {
    return null;
  }

  const checkBoxDefaultText = `I agree to receive all marketing emails`;
  const checkBoxText =
    whitelabel?.complianceContact && enableComplianceContact
      ? `${checkBoxDefaultText} from ${whitelabel?.complianceContact}`
      : checkBoxDefaultText;

  return (
    <div className={styles.EmailOptIn}>
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ name: strin... Remove this comment to see the full error message */}
      <Checkbox
        name="EmailOptIn"
        text={t('{{-checkBoxText}}', {
          checkBoxText,
        })}
        className={styles.checkbox}
        selected={emailOptIn}
        onChange={onChange}
        testId="emailOptInCheckbox"
      />
      <S>
        {t(
          'This includes recommendations, members-only deals, things to do in your workplace, how {{name}} works, and promotions. You can opt out of receiving these at any time in your account settings or directly from the email.',
          { name: whitelabel.name }
        )}
      </S>
    </div>
  );
}
