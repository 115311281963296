import { useEffect, useMemo, useState } from 'react';

import Papa from 'papaparse';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { BLACKLISTED_TEMPLATE_VARIABLE_NAMES } from 'lane-shared/services/liquidEngineService';
import { DatasetRecord } from 'lane-shared/types/DatasetRecord';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';

import makeFileDownload from 'lane-web/src/helpers/makeFileDownload';
import getDatasetSchema from 'lane-web/src/pages/portal/admin/channel/library/queries/getDatasetSchema';

export function constructDatasetSchemaPropertiesAsLongText(
  propertyNames: Array<string>
): PropertiesInterface {
  const propertyNameOccurrence = propertyNames.reduce((acc, propertyName) => {
    if (propertyName in acc) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[propertyName] += 1;
    } else {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[propertyName] = 1;
    }

    return acc;
  }, {});

  const duplicatePropertyNames = Object.keys(propertyNameOccurrence).filter(
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    propertyName => propertyNameOccurrence[propertyName] > 1
  );

  if (duplicatePropertyNames.length > 0) {
    throw new Error(
      `Property names must be unique. Duplicates found: ${duplicatePropertyNames.toString()}`
    );
  }

  // remove _id as it is a special (blacklisted) column that we don't want in the properties
  const whitelistedPropertyNames = propertyNames.filter(
    name => !BLACKLISTED_TEMPLATE_VARIABLE_NAMES.includes(name)
  );

  return whitelistedPropertyNames.reduce((acc, propertyName) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    acc[propertyName] = {
      type: 'LongText',
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Max',
          value: 2 ** 16,
        },
      ],
      placeholder: propertyName,
      friendlyName: propertyName,
    };

    return acc;
  }, {});
}

type Options = {
  datasetRecordsPagination?: { perPage: number; start: number };
};

export default function useDatasetSchema(
  datasetSchemaId: string,
  { datasetRecordsPagination }: Options = {}
) {
  const [datasetRecords, setDatasetRecords] = useState<DatasetRecord[]>([]);
  const [
    totalDatasetRecordsCount,
    setTotalDatasetRecordsCount,
  ] = useState<number>(0);

  const { data, loading, error } = useQuery(getDatasetSchema, {
    variables: {
      id: datasetSchemaId,
      pagination: datasetRecordsPagination,
    },
    skip: !datasetSchemaId,
  });

  const {
    datasetSchema,
    datasetProperties = [],
    contentGenerator,
  } = useMemo(() => {
    if (!data?.datasetSchema) {
      return {};
    }

    const { datasetSchema } = data;

    const datasetProperties = Object.keys(datasetSchema.properties).map(
      propName => ({
        _id: propName, // used as a key in PropertiesDataTable
        ...datasetSchema.properties[propName],
        name: propName,
      })
    );

    const contentGenerator = data?.datasetSchema?.generator?.content;

    return {
      datasetSchema,
      datasetProperties,
      contentGenerator,
    };
  }, [data]);

  useEffect(() => {
    if (data?.datasetSchema?.datasetRecords?.items?.length > 0) {
      setDatasetRecords(data?.datasetSchema.datasetRecords.items);
    }

    setTotalDatasetRecordsCount(
      data?.datasetSchema?.datasetRecords?.pageInfo?.total || 0
    );
  }, [data]);

  async function exportCSVFile() {
    const { data } = await getClient().query({
      query: getDatasetSchema,
      fetchPolicy: 'network-only',
      variables: {
        id: datasetSchemaId,
        pagination: undefined,
      },
    });

    if (!data.datasetSchema) {
      throw new Error('Database does not exist.');
    }

    const records = data.datasetSchema.datasetRecords?.items.map(
      (item: any) => ({
        _id: item._id,
        ...item.value,
      })
    );

    const columns = ['_id', ...Object.keys(data.datasetSchema.properties)];

    const csv =
      records.length > 0
        ? Papa.unparse(records, { columns })
        : columns.join(',');

    const fileName = `${datasetSchema?.name.replace(/\s/g, '_')}.csv`;

    makeFileDownload({
      name: fileName,
      contents: csv,
      type: 'application/csv',
    });
  }

  return {
    exportCSVFile,
    loading,
    datasetRecords,
    totalDatasetRecordsCount,
    datasetSchema,
    datasetProperties,
    contentGenerator,
    error,
  };
}
