import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { XS } from 'components/typography';

import styles from './ContentPriceTag.scss';
import { ContentForCards } from 'lane-shared/types/content/ContentForCards';
import { explodeFeatures } from 'lane-shared/helpers/features';
import {
  useBillingPaymentSettingsForChannel,
  useListProductsByProductIdsQuery,
} from 'lane-shared/domains/billingPayments/hooks';
import useUserLocale from 'hooks/useUserLocale';
import { productToPriceComponents } from 'lane-shared/domains/billingPayments/helpers';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import {
  TaxPolicy,
  currencyToPaymentCurrency,
  Product,
} from 'lane-shared/domains/billingPayments/types';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { currencyFormatter } from 'lane-shared/helpers/formatters';

type Props = {
  content: ContentForCards;
  shortenInterval?: boolean;
};

export function ContentPriceTag({ content, shortenInterval = false }: Props) {
  const { paymentFeature, reservableFeature } = explodeFeatures(
    content?.features
  );

  const { t } = useTranslation();

  const ProductsInContent = useFlag(FeatureFlag.ProductsInContent, false);
  const [price, setPrice] = useState<number>(0);
  const [priceString, setPriceString] = useState<string>('');

  const { data } = useListProductsByProductIdsQuery({
    page: 0,
    pageSize: 100,
    productIds: paymentFeature?.productIds,
    channel: content.channel as ChannelType,
  });

  const locale = useUserLocale();
  const { currency, taxDetails } = useBillingPaymentSettingsForChannel({
    channelId: content.channel._id,
  });
  const inclusivePricing = taxDetails?.taxPolicy === TaxPolicy.INCLUSIVE;

  useEffect(() => {
    if (data && ProductsInContent) {
      const products = data?.listProducts?.products;

      let total = 0;

      products.map(product => {
        const priceComponents = productToPriceComponents(
          (product as unknown) as Product
        );
        const { netPrice } = getPricing({
          currency: currencyToPaymentCurrency(currency),
          locale,
          priceComponents,
        });

        total += netPrice;
      });
      setPrice(total);
      setPriceString(
        currencyFormatter({
          locale,
          currency: currencyToPaymentCurrency(currency),
          currencyDisplay: 'narrowSymbol',
          useGrouping: false,
        })(total)
      );
    } else {
      let total = 0;

      paymentFeature?.amounts.map(amount => {
        total += amount.amount;
      });
      setPrice(total / 100);
      setPriceString(
        currencyFormatter({
          locale,
          currency: paymentFeature?.amounts[0].currency,
          currencyDisplay: 'narrowSymbol',
          useGrouping: false,
        })(total / 100)
      );
    }
  }, [
    ProductsInContent,
    currency,
    data,
    data?.listProducts?.products,
    inclusivePricing,
    locale,
    paymentFeature?.amounts,
  ]);

  let tag;

  if (price === 0) {
    tag = (
      <div className={styles.container}>
        <XS variant="secondary">{t('abp.content.free')}</XS>
      </div>
    );
  } else if (paymentFeature?.subscriptionEnabled) {
    tag = (
      <div className={styles.container}>
        <div className={styles.priceText}>
          <XS>{priceString}</XS>
        </div>
        {shortenInterval ? (
          <div className={styles.text}>
            <XS variant="secondary">
              {t(
                `abp.content.subscription.timeFrame.${paymentFeature?.subscription?.frequency}`,
                { context: 'short' }
              )}
            </XS>
          </div>
        ) : (
          <div className={styles.text}>
            <XS variant="secondary">
              {t(
                `abp.content.subscription.timeFrame.${paymentFeature?.subscription?.frequency}`
              )}
            </XS>
          </div>
        )}
      </div>
    );
  } else if (reservableFeature) {
    tag = (
      <div className={styles.container}>
        <div>
          <XS variant="secondary">
            {t('abp.content.reservablePrice.from')}&nbsp;
          </XS>
        </div>
        <div>
          <XS>{priceString}</XS>
        </div>
      </div>
    );
  } else {
    tag = (
      <div className={styles.container}>
        <XS>{priceString}</XS>
      </div>
    );
  }

  return <div>{tag}</div>;
}
