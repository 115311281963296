import tzLookup from 'tz-lookup';

export const DEFAULT_TZ = 'America/New_York';

export default function getTimeZoneByGeoLocation({
  latitude,
  longitude,
}: {
  latitude?: number;
  longitude?: number;
}): string {
  if (latitude === undefined || longitude === undefined) {
    // need to return default when either latitude or longitude is undefined
    return DEFAULT_TZ;
  }

  try {
    return tzLookup(latitude, longitude);
  } catch (err) {
    // need to default to something when tzLookup fails
    return DEFAULT_TZ;
  }
}
