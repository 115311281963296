import {
  GroupType,
  MarkupAmountType,
  TaxAmountType,
} from 'graphql-query-contracts';

import { ProductType, Currency, TaxPolicy, TaxDetails } from './types';

export const CurrencyFormatMap = {
  [Currency.UNRECOGNIZED]: '',
  [Currency.CURRENCY_UNKNOWN]: '',
  [Currency.CURRENCY_USD]: 'USD',
  [Currency.CURRENCY_CAD]: 'CAD',
  [Currency.CURRENCY_AUD]: 'AUD',
  [Currency.CURRENCY_EUR]: 'EUR',
  [Currency.CURRENCY_GBP]: 'GBP',
  [Currency.CURRENCY_HKD]: 'HKD',
  [Currency.CURRENCY_HUF]: 'HUF',
  [Currency.CURRENCY_BRL]: 'BRL',
};

export const TAX_VALUE_CONVERSION = 100;

export const TAX_TYPE_OPTIONS = [
  {
    label: 'abp.billingAndPaymentSettings.tax.percentage',
    value: TaxAmountType.TaxAmountTypePercentage,
  },
  {
    label: 'abp.billingAndPaymentSettings.tax.fixed',
    value: TaxAmountType.TaxAmountTypeFixed,
  },
];

export const CREDIT_CATEGORY_NAME = 'Credit Bundles';
export const CREDIT_CATEGORY_DESCRIPTION = 'Category for credit bundles.';

export const DEFAULT_TAX_DETAILS = {
  taxName: 'Tax',
  taxAmountType: TaxAmountType.TaxAmountTypePercentage,
  taxAmountValue: 0,
  businessTaxName: '',
  businessTaxNumber: '',
  taxDisplayName: '',
  taxNumber: '',
  taxPolicy: TaxPolicy.EXCLUSIVE,
} as TaxDetails;

export const EMPTY_CATEGORY_DETAILS = {
  productCategoryId: '',
  name: '',
  description: '',
  groupId: '',
  groupType: GroupType.GroupTypeActivateChannel,
  productType: '' as ProductType,
};

export const EMPTY_PRODUCT_DETAILS = {
  productId: '',
  productGroupId: '',
  name: '',
  description: '',
  rate: '',
  markup: {
    type: MarkupAmountType.MarkupAmountTypePercentage,
    value: '',
  },
  tax: {
    type: TaxAmountType.TaxAmountTypePercentage,
    value: '',
  },

  productCategoryId: '',
  productCategoryName: '',
  productCategoryType: '' as ProductType,

  netPrice: '',
  quantity: '1',
  total: '',

  originalPrice: undefined,
  creditAmount: undefined,

  chargeCode: '',
  chargeCodeId: '',

  receivables: [],
};
