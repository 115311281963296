import React from 'react';

import { useTranslation } from 'react-i18next';
import { UserContentInteractionFeaturesType } from 'lane-shared/types/UserContentInteraction';
import { H5, M } from 'components/typography';

type Props = {
  quantityFeature: UserContentInteractionFeaturesType['Quantity'];
};

export function QuantityReceipt({ quantityFeature }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <H5>{t('shared.content.interaction.receipt.quantity')}</H5>
      <M>{quantityFeature?.quantity}</M>
    </div>
  );
}
