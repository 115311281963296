import { getClient } from '../apollo';
import { AppContext, UserDataContext } from '../contexts';
import { createDraftContent, updateDraftContent } from '../graphql/content';
import deleteDraftMutation from '../graphql/mutation/deleteDraft';
import publishDraftMutation from '../graphql/mutation/publishDraft';
import unPublishContentMutation from '../graphql/mutation/unPublishContent';
import {
  getTimeZoneByGeoLocation,
  pause,
  castForUpdate,
  castGraphQLObject,
} from '../helpers';
import {
  constructContent,
  constructNotification,
  constructWorkflow,
  copyTemplateForContent,
  SubtitleOptions,
  deriveSubtitleFromContent,
} from '../helpers/content';
import { ConstructWorkflowProps } from '../helpers/content/constructWorkflow';
import { undoDynamicTranslation } from '../helpers/dynamicLanguages';
import getDefaultMetatagValue from '../helpers/metatags/getDefaultMetatagValue';
import fetchTemplate from '../helpers/templates/fetchTemplate';
import migrateV1ToV5 from '../renderers/v5/helpers/migrateV1ToV5';
import { Audience } from '../types/audiences/Audience';
import { ContentTypeEnum } from '../types/content/ContentTypeEnum';
import {
  ContentNotificationType,
  DraftContentType,
} from '../types/content/DraftContent';
import { routes } from 'lane-shared/config';
import cloneDeep from 'lodash/cloneDeep';
import { useState, useContext, useEffect, useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { ValidationError } from 'yup';
import { LocalizationColumnType } from 'lane-shared/types/LocalizationColumnType';
import { useTranslation } from 'react-i18next';

type Params = {
  existingContent?: any;
  forCreate?: boolean;
  forEdit?: boolean;
  draft?: any;
  channel?: any;
  // TODO: this should probably be updated to support all ContentTypeEnum's
  contentType:
    | ContentTypeEnum.Notice
    | ContentTypeEnum.Content
    | ContentTypeEnum.Perk
    | ContentTypeEnum.Promotion
    | ContentTypeEnum.Static;
  stepValidation: any;
  step: any;
  baseBlock?: any;
};

export type AddNotificationType = (props?: {
  sendAt?: Date | null;
  title?: string | null;
  title_l10n?: LocalizationColumnType | null;
  contentGoesLiveNotification?: boolean | null;
}) => ContentNotificationType;

type updateContentProps = {
  _id?: string;
  name?: string;
  description?: string;
  tags?: any[];
  logo?: any[] | null | undefined;
  color?: string;
  backgroundImage?: any | null;
  icon?: any | null;
  iconSet?: any | null;
  iconWeight?: any | null;
  backgroundColor?: string;
  liveDate?: Date;
  startDate?: Date;
  endDate?: Date;
  unpublishDate?: Date;
  renderer?: number;
  block?: any;
  properties?: any;
  data?: any;
  state?: any;
  features?: any[];
  actions?: any[];
  notifications?: any[];
  placements?: any[];
  audiences?: Audience[];
  isEvent?: boolean;
  contentMetatags?: any;
};

export default function useCreateDraft({
  existingContent,
  forCreate,
  forEdit,
  draft,
  channel,
  contentType,
  stepValidation,
  step,
  baseBlock,
}: Params) {
  const { blocks } = useContext(AppContext);
  const { user } = useContext(UserDataContext);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState<ValidationError[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [content, setContent] = useState<any>(existingContent);
  const [timeZone, setTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const history = useHistory();
  const { t } = useTranslation();

  // TODO: needs translation, but translation within this hook (or any hook?) is not working
  const contentMissingTitleError = new Error('Content must have a title.');
  const contentMissingBodyError = new Error(
    t('web.admin.content.draftContent.missingContentBody.error')
  );
  const teamMissingTimeAvailabilityFeatureError = new Error(
    t(
      'web.admin.content.draftContent.timeAvailabilityFeature.teamMissing.error'
    )
  );

  useEffect(() => {
    if (existingContent) {
      setContent(cloneDeep(existingContent));
    }
  }, [existingContent]);

  // when timezone is changed, instead of changing content.geo coords, we're storing selected timezone as string on existed content.state column (in json format)
  const updateTimeZone = useCallback((newTimeZone: any) => {
    setTimeZone(newTimeZone);
    setContent((content: any) => ({
      ...content,
      state: {
        ...content.state,
        timezone: newTimeZone,
      },
    }));
  }, []);

  async function validateStep(targetStep?: string | null) {
    const stepName = targetStep ?? step;

    if (!stepValidation[stepName]) {
      return true;
    }

    // before we move to the next step, validate the current page.
    try {
      await stepValidation[stepName].validate(content, {
        abortEarly: false,
      });
      setValidation(null);

      return true;
    } catch (err) {
      setValidation(err as ValidationError[]);

      return false;
    }
  }

  function getNewNotice() {
    const data: any = constructContent({
      contentType: ContentTypeEnum.Notice,
      channel,
    });

    data.notifications = [
      constructNotification({
        contentId: data._id,
        sendAt: data.liveDate,
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        userId: user._id,
        title: data.name,
      }),
    ];

    // set the timeZone to the channel instead of the users time zone.
    const [longitude, latitude] = channel.address.geo;

    setTimeZone(getTimeZoneByGeoLocation({ longitude, latitude }));

    // storing channel timezone in content.state as default timezone while creating notice
    data.state = {
      ...data.state,
      timezone: getTimeZoneByGeoLocation({ longitude, latitude }),
    };

    return data;
  }

  useEffect(() => {
    if (forCreate && contentType === ContentTypeEnum.Notice) {
      try {
        setContent(getNewNotice());
      } catch (err) {
        setError(err as any);
      }
    }
  }, []);

  useEffect(() => {
    if (
      blocks?.length > 0 &&
      forCreate &&
      channel &&
      contentType !== ContentTypeEnum.Notice
    ) {
      try {
        if (!content) {
          const newContent = constructContent({
            channel,
            contentType,
            baseBlock: { ...baseBlock, name: `New ${contentType}` },
          });

          setContent({
            ...newContent,
            subtitle: SubtitleOptions.NONE,
          });
        }

        // set the timeZone to the channel instead of the users time zone.
        const [longitude, latitude] = channel.address.geo;

        setTimeZone(getTimeZoneByGeoLocation({ longitude, latitude }));

        setContent((content: any) => ({
          ...content,
          state: {
            ...content.state,
            timezone: getTimeZoneByGeoLocation({ longitude, latitude }),
          },
          subtitle: deriveSubtitleFromContent(content),
        }));
      } catch (err) {
        setError(err as any);
      }
    }
  }, [blocks, channel]);

  useEffect(() => {
    // if draft changes, it means it was re-fetched, or user user navigated to a new id.
    // reset the draft in the local state.

    if (forEdit && draft) {
      draft.subtitle = deriveSubtitleFromContent(draft);
      setContent(draft);

      if (draft?.state?.timezone) {
        setTimeZone(draft.state.timezone);
      } else if (draft.geo) {
        // set the timeZone to the content instead of the users time zone.
        const [longitude, latitude] = draft.geo;

        setTimeZone(getTimeZoneByGeoLocation({ longitude, latitude }));
      }

      setLoading(false);
      setHasChanged(false);
    }
  }, [draft?._id]);

  function updateContent(
    props: Partial<DraftContentType> | updateContentProps
  ) {
    setHasChanged(true);
    const updatedContent = { ...content, ...props };

    setContent(updatedContent);

    if (validation) {
      validateStep();
    }

    return updatedContent;
  }

  async function undoChanges() {
    setLoading(true);
    await pause();
    setContent(draft);
    setHasChanged(false);
    setLoading(false);
  }

  function sendUserToDataLibrary(content: any) {
    const datasetSchemaId = content?.generator?.datasetSchema?._id;

    if (datasetSchemaId) {
      history.push({
        pathname: generatePath(routes.channelAdminDatasetLibrary, {
          id: channel?.slug,
          datasetSchemaId,
        }),
        search: '?download=true',
      });
    }
  }

  function hasNullContent(obj: any): boolean {
    // Base case: if obj is null or undefined, return false
    if (!obj) {
      return false;
    }

    // Check if 'content' is null
    if (obj.content === null) {
      return true;
    }

    // Recursively check nested arrays or objects
    if (Array.isArray(obj)) {
      // If obj is an array, iterate over each element
      for (const item of obj) {
        if (hasNullContent(item)) {
          return true;
        }
      }
    } else if (typeof obj === 'object') {
      // If obj is an object, iterate over its keys
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          // Recursively check each property value
          if (hasNullContent(obj[key])) {
            return true;
          }
        }
      }
    }

    return false;
  }

  async function createDraft() {
    setValidation(null);
    setError(null);

    // Check for empty content blocks
    if (hasNullContent(content.block?.properties?.children)) {
      setLoading(false);

      return setError(contentMissingBodyError);
    }

    // TimeAvailability configuration causing room booking page to crash for members and admins
    // Check for features with type "TimeAvailability", groupRole not set or undefined, and allGroupRoles is false
    // i.e., Targeted Availability is on but no team is selected
    if (
      content.features?.some(
        (feature: any) =>
          feature?.type === 'TimeAvailability' &&
          feature?.feature?.availabilities?.some((availability: any) => {
            return (
              (availability.groupRole === undefined ||
                availability.groupRole === null) &&
              availability.allGroupRoles === false
            );
          })
      )
    ) {
      setLoading(false);

      return setError(teamMissingTimeAvailabilityFeatureError);
    }

    if (!content.name) {
      return setError(contentMissingTitleError);
    }

    const draftContent = cloneDeep(content);

    delete draftContent.generator?.datasetSchema?.datasetRecords;

    if (
      draftContent.contentMetatags &&
      draftContent.contentMetatags.length !== 0
    ) {
      draftContent.contentMetatags = draftContent.contentMetatags.map(function (
        contentMetatag: any
      ) {
        // The mutation accepts an ObjectMetatagValueInput, which only requires the 'id' and 'metatag value' fields.
        // Instead of deleting the keys, it is recommended to create new objects with these fields.
        // The other fields in the content metatag are not important as they are stored in the DB under their own entry.
        // The remaining fields are not supported by the GraphQL input schema, so they must be removed.
        return {
          metatag: {
            _id: contentMetatag.metatag._id,
          },
          value: contentMetatag.value,
          values: contentMetatag.values,
        };
      });
    }

    setLoading(true);

    try {
      // try to save.
      await pause();
      draftContent.notifications = draftContent.notifications?.map(
        (notification: ContentNotificationType) => ({
          ...notification,
          _new: undefined,
        })
      );
      const { data } = await getClient().mutate({
        mutation: createDraftContent,
        variables: {
          draft: castForUpdate(draftContent),
        },
      });

      if (data?.createDraft?.generator) {
        sendUserToDataLibrary(data?.createDraft);
      }

      setHasChanged(false);
      setLoading(false);

      return data.createDraft;
    } catch (err) {
      setLoading(false);
      setError(err as any);

      return false;
    }
  }

  async function saveDraft(onUpdateDraftCompleted?: () => void) {
    if (!content.name) {
      return setError(contentMissingTitleError);
    }

    if (forCreate) {
      return await createDraft();
    }

    try {
      const isDraftUpdated = await updateDraft();

      if (onUpdateDraftCompleted) {
        onUpdateDraftCompleted();
      }

      return isDraftUpdated;
    } catch (err) {
      // Do not refresh in case of error.
      // This logic needs to be changed
    }
  }

  async function updateDraft() {
    setValidation(null);
    setError(null);
    setLoading(true);

    // Check for empty content blocks
    if (hasNullContent(content.block?.properties?.children)) {
      setLoading(false);

      return setError(contentMissingBodyError);
    }

    // TimeAvailability configuration causing room booking page to crash for members and admins
    // Check for features with type "TimeAvailability", groupRole not set or undefined, and allGroupRoles is false
    // i.e., Targeted Availability is on but no team is selected
    if (
      content.features?.some(
        (feature: any) =>
          feature?.type === 'TimeAvailability' &&
          feature?.feature?.availabilities?.some((availability: any) => {
            return (
              (availability.groupRole === undefined ||
                availability.groupRole === null) &&
              availability.allGroupRoles === false
            );
          })
      )
    ) {
      setLoading(false);

      return setError(teamMissingTimeAvailabilityFeatureError);
    }

    if (!draft) draft = {};

    // The goal of this code is to either perform an upsert or delete operation on the metatags.
    // This is achieved by checking the differences in the inital state of the draft and just before saving.
    // If the metatag exists in the draft's initial state and just before saving, we update the metatag.
    // If it only exists just before saving, we create the metatag.
    // Otherwise, the metatag is deleted.
    const allMetatagsById = new Map(
      [
        ...(draft.contentMetatags ? draft.contentMetatags : []),
        ...(content.contentMetatags ? content.contentMetatags : []),
      ].map(contentMetatag => [contentMetatag._id, contentMetatag])
    );
    const draftMetatagsById = draft.contentMetatags
      ? new Map(
          draft.contentMetatags.map((contentMetatag: { _id: String }) => [
            contentMetatag._id,
            contentMetatag,
          ])
        )
      : new Map([]);
    const contentMetatagsById = content.contentMetatags
      ? new Map(
          content.contentMetatags.map((contentMetatag: { _id: String }) => [
            contentMetatag._id,
            contentMetatag,
          ])
        )
      : new Map([]);
    const contentMetatagsToUpdate = Array.from(allMetatagsById).map(
      ([_id, contentMetatag]) => {
        // Build payload to update metatag
        if (draftMetatagsById.get(_id) && contentMetatagsById.get(_id)) {
          return {
            _id: contentMetatag._id,
            metatag: {
              _id: contentMetatag.metatag._id,
            },
            value: contentMetatag.value,
            values: contentMetatag.values,
          };
        }

        // Build payload to create metatag
        if (contentMetatagsById.get(_id)) {
          return {
            metatag: {
              _id: contentMetatag.metatag._id,
            },
            value: contentMetatag.value,
            values: contentMetatag.values,
          };
        }

        // Build payload to delete metatag
        return {
          _pull: true,
          _id: contentMetatag._id,
        };
      }
    );

    const savedDraft = cloneDeep({
      ...content,
      isSyndicateable: false,
      _createdBy: undefined,
      _updatedBy: undefined,
      publishedBy: undefined,
      channel: {
        _id: content.channel._id,
      },
      card: {
        _id: content.card._id,
      },
      theme: content.theme
        ? {
            _id: content.theme._id,
          }
        : null,
      integration: content.integration
        ? {
            _id: content.integration._id,
          }
        : null,
      contentMetatags: contentMetatagsToUpdate,
    });

    // integrations only accept an _id
    if (savedDraft.integration) {
      savedDraft.integration = { _id: savedDraft.integration._id };
    }

    try {
      // remove some data during save..
      await pause();
      savedDraft.notifications = savedDraft.notifications?.map(
        (notification: ContentNotificationType) => ({
          ...notification,
          _new: undefined,
        })
      );
      const { data } = await getClient().mutate({
        mutation: updateDraftContent,
        variables: {
          draft: castForUpdate(savedDraft),
        },
      });

      // Reload content metatags
      updateContent({
        contentMetatags: castGraphQLObject(data?.updateDraft?.contentMetatags),
      });

      if (data?.updateDraft?.generator) {
        sendUserToDataLibrary(data?.updateDraft);
      }

      setHasChanged(false);
      setLoading(false);

      return data.updateDraft;
    } catch (err) {
      setError(err as any);
      setLoading(false);

      throw err;
    }
  }

  async function addMetatag(metatag: any) {
    setError(null);
    const defaultValue = getDefaultMetatagValue(metatag);

    try {
      setLoading(true);
      await pause();

      if (typeof metatag !== 'object')
        throw new Error(`Unsupported any value type: ${typeof metatag}`);

      const contentMetatag = {
        // This _id is a temporary placeholder used to display the metatag until it's finalized on save.
        _id: `+placeholder-${metatag._id}`,
        metatag: {
          ...metatag,
        },
        value: defaultValue,
      };

      const allContentMetatagsByMetatagId = new Map(
        [
          ...(content.contentMetatags ? content.contentMetatags : []),
          contentMetatag,
        ].map((contentMetatag: { metatag: any; _id: String }) => [
          contentMetatag.metatag._id,
          contentMetatag,
        ])
      );

      // Remove duplicate content metatags with the same ID
      const allContentMetatags = Array.from(allContentMetatagsByMetatagId).map(
        ([_id, contentMetatag]) => {
          return contentMetatag;
        }
      );

      updateContent({
        contentMetatags: allContentMetatags,
      });
    } catch (err) {
      setError(err as any);
    }

    setLoading(false);
  }

  async function removeMetatag(contentMetatag: any) {
    try {
      // @ts-ignore ts-migrate(2551) FIXME: Property 'Alert' does not exist on type 'Window & ... Remove this comment to see the full error message
      await window.Alert.confirm({
        title: `Remove this filter?`,
        message: 'Do you want to remove this filter?',
      });
    } catch (err) {
      // user cancelled
      return;
    }

    setError(null);

    try {
      setLoading(true);
      await pause();

      if (typeof contentMetatag !== 'object')
        throw new Error(`Unsupported any value type: ${typeof contentMetatag}`);

      const newContentMetatags = content.contentMetatags.filter(
        (contentMetatagt: any) => contentMetatagt._id !== contentMetatag._id
      );

      updateContent({
        contentMetatags: newContentMetatags,
      });
    } catch (err) {
      setError(err as any);
    }

    setLoading(false);
  }

  const addNotification: AddNotificationType = ({
    sendAt = content.liveDate,
    title = content.name,
    title_l10n = content.title_l10n,
    contentGoesLiveNotification = false,
  } = {}) => {
    const newNotification: ContentNotificationType = constructNotification({
      contentId: content._id,
      sendAt,
      userId: user?._id,
      title,
      title_l10n,
      contentGoesLiveNotification,
    });

    // constructNotification sets a default sendAt which effectively
    // makes it impossible to have a null sendAt without this if statement
    if (sendAt === null) {
      newNotification.sendAt = null;
    }

    updateContent({
      notifications: [...(content.notifications || []), newNotification],
    });

    return newNotification;
  };

  function addWorkflow(workflowOptions?: Partial<ConstructWorkflowProps>) {
    const workflow = constructWorkflow({
      contentId: content._id,
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      userId: user._id,
      ...workflowOptions,
    });

    updateContent({
      actions: [...(content.actions || []), workflow],
    });

    return workflow;
  }

  function migrateContent(oldContent: any) {
    const migratedContent = migrateV1ToV5(oldContent);

    updateContent({
      renderer: 5,
      block: migratedContent,
      data: {},
      state: {},
      features: [],
      actions: [],
    });
  }

  function upgradeContent() {
    const newContent = constructContent({
      channel,
      contentType,
      baseBlock: { ...baseBlock, name: 'New Content' },
    });

    updateContent({
      renderer: 5,
      block: newContent.block,
      data: newContent.data,
      state: newContent.state,
      features: newContent.features,
      actions: newContent.actions,
    });
  }

  async function deleteDraft() {
    try {
      setLoading(true);
      await pause(1000);
      await getClient().mutate({
        mutation: deleteDraftMutation,
        variables: {
          id: content._id,
        },
      });

      return true;
    } catch (err) {
      setError(err as any);
      setLoading(false);

      return false;
    }
  }

  async function loadTemplate(
    toLoad: any,
    {
      isContentGenerator = false,
      generator = {},
    }: { isContentGenerator?: boolean; generator?: any } = {}
  ) {
    setLoading(true);

    try {
      // get the full template
      const template = await fetchTemplate(toLoad._id);

      const templateContent = copyTemplateForContent({
        template,
        channel,
      });

      const untranslatedTemplateContent = undoDynamicTranslation({
        model: templateContent,
        columns: ['name', 'description', 'subtitle'],
        channelSettings: channel?.settings,
      });

      // Scrub the event dates so that old event dates from when the
      // template was created do not persist in the draft content.
      untranslatedTemplateContent.startDate = null;
      untranslatedTemplateContent.endDate = null;

      updateContent(untranslatedTemplateContent);
      setLoading(false);

      if (isContentGenerator) {
        return {
          ...untranslatedTemplateContent,
          generator,
        };
      }

      return untranslatedTemplateContent;
    } catch (err) {
      setLoading(false);
      setError(err as any);

      return false;
    }
  }

  async function publishDraft() {
    if (content) {
      // any null value fields in content object are removed by castGraphQLObject function upstream
      // if an integration is active then its deletedAt field has the value null
      // if an integration is deleted then its deletedAt field has a timestamp as a value
      // in the below condition if the key exists it means integration is deleted
      // and we don't want to publish content with deleted integration
      // exclude work order content as it is not a regular content type
      if (
        content.type !== ContentTypeEnum.WorkOrder &&
        'integration' in content &&
        'deletedAt' in content.integration
      ) {
        setError(
          new Error(
            t(
              'web.admin.content.draftContent.publish.deleted.integration.error'
            )
          )
        );

        return;
      }
    }

    try {
      await pause(2000);
      await getClient().mutate({
        mutation: publishDraftMutation,
        variables: {
          id: content._id,
        },
      });

      return true;
    } catch (err) {
      setError(err as any);

      return false;
    }
  }

  async function unpublishContent() {
    setLoading(true);

    try {
      await pause(2000);
      await getClient().mutate({
        mutation: unPublishContentMutation,
        variables: {
          id: content._id,
        },
      });

      setLoading(false);

      return true;
    } catch (err) {
      setLoading(false);
      setError(err as any);

      return false;
    }
  }

  return {
    content,
    loading,
    error,
    validation,
    timeZone,
    hasChanged,
    validateStep,
    updateContent,
    updateTimeZone,
    addNotification,
    addMetatag,
    removeMetatag,
    addWorkflow,
    upgradeContent,
    migrateContent,
    undoChanges,
    createDraft,
    saveDraft,
    publishDraft,
    deleteDraft,
    unpublishContent,
    loadTemplate,
  };
}
