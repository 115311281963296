import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/general';

export default function ModalCloseButton({ onClick }: any) {
  const { t } = useTranslation();

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '"primary"' is not assignable to type '"inher... Remove this comment to see the full error message
    <Button color="primary" onClick={onClick} fullWidth>
      {t('Close')}
    </Button>
  );
}
