import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { UnsupportedMediaTypeError } from 'activate-errors';
import { createAndUploadMedia } from 'lane-shared/helpers/createAndUploadMedia';
import { dataUriToBlob } from 'lane-shared/helpers/files';
import { LibraryType } from 'lane-shared/types/libraries/LibraryType';
import {
  AmazonS3Buckets,
  DocumentMediaOptions,
  HTMLImage,
  ImageMediaOptions,
  MediaDocumentContentTypeEnum,
  MediaTypeEnum,
  ThumbnailType,
} from 'lane-shared/types/media';

import { H3 } from 'components/typography';

import { createThumbnail, enforceSize } from '../helpers/images';
import type { FileReturnType } from 'helpers/fileReaderResolver';
import { getMediaType } from 'helpers/media/getMediaType';
import type { Media } from 'graphql-query-contracts';

type Props = {
  onMediaCreated: (media: Media) => void;
  mediaOptions?: ImageMediaOptions | DocumentMediaOptions;
  selectedLibrary?: LibraryType | null;
  path?: string | null;
  tags?: string[] | null;
  s3Bucket?: string;
};

type FullMediaForUpload = {
  media: HTMLImage | File;
  mediaBlob: Blob;
  thumbnail?: ThumbnailType;
  thumbnailBlob?: Blob;
};

export default function useMediaUpload({
  selectedLibrary,
  onMediaCreated,
  mediaOptions,
  tags,
  path,
  s3Bucket = AmazonS3Buckets.Lane,
}: Props) {
  const { t } = useTranslation();

  const [uploading, setUploading] = useState(false);

  async function filesSelectedHandler(files: FileReturnType[]) {
    setUploading(true);

    await window.Alert.loading({
      title: t('Uploading…'),
      children: <H3 style={{ textAlign: 'center' }}>0 / {files.length}</H3>,
    });

    for (let i = 0; i < files.length; i++) {
      const file = files[i]!;

      try {
        await uploadMedia(file);
      } catch (error) {
        console.error(error);
        window.Toast.show(
          <H3 style={{ textAlign: 'center' }}>
            {t('Failed to upload')}{' '}
            <span>{typeof file !== 'string' && file.name}</span>
          </H3>
        );
      }

      await window.Alert.loading({
        title: t('Uploading…'),
        children: (
          <H3 style={{ textAlign: 'center' }}>
            {i + i} / {files.length}
          </H3>
        ),
      });
    }

    setUploading(false);
    window.Alert.hide();
  }

  const processMediaForUpload: (
    media: FileReturnType,
    mediaType: MediaTypeEnum
  ) => FullMediaForUpload = (media, mediaType) => {
    let fullMediaForUpload: FullMediaForUpload;

    switch (mediaType) {
      case MediaTypeEnum.Image: {
        const image = media as HTMLImage;
        const resized = enforceSize({
          image: media as HTMLImage,
          ...mediaOptions,
          quality: 1,
        });
        const resizedBlob = dataUriToBlob(resized.uri);
        const thumbnail = createThumbnail({ image });
        const thumbnailBlob = dataUriToBlob(thumbnail.uri);

        fullMediaForUpload = {
          media: image,
          mediaBlob: resizedBlob,
          thumbnail,
          thumbnailBlob,
        };
        break;
      }

      case MediaTypeEnum.Document: {
        const document = media as File;

        fullMediaForUpload = {
          media: document,
          mediaBlob: document,
        };
        break;
      }

      default:
        throw new UnsupportedMediaTypeError();
    }

    return fullMediaForUpload;
  };

  const uploadMedia = async (media: FileReturnType) => {
    if (!selectedLibrary) return;

    const mediaType: MediaTypeEnum = getMediaType(media);

    // currently we only support PDF documents.
    if (
      mediaType === MediaTypeEnum.Document &&
      (media as File).type !== MediaDocumentContentTypeEnum.pdf
    ) {
      throw new UnsupportedMediaTypeError();
    }

    const mediasAndThumbnail = processMediaForUpload(media, mediaType);
    const newMedia = await createAndUploadMedia({
      selectedLibrary,
      name: (media as File).name,
      ...mediasAndThumbnail,
      type: mediaType,
      path,
      tags,
      s3Bucket,
      _id: media?._id,
    });

    onMediaCreated(newMedia);
  };

  return {
    filesSelectedHandler,
    uploading,
  };
}
