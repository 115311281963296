import React from 'react';
import { useTranslation } from 'react-i18next';

import { PopupMenu, TextButton, Column } from 'design-system-web';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import {
  Markup,
  MarkupAmountType,
  Product,
  ProductCategory,
  Tax,
  TaxAmountType,
  currencyToPaymentCurrency,
  ChargeCode,
} from 'lane-shared/domains/billingPayments/types';
import { CurrencyFormatMap } from 'lane-shared/domains/billingPayments/constants';

import { getPricing } from 'lane-shared/domains/productCatalog/helpers/getPricing';
import {
  currencyFormatter,
  simpleCurrencyFormatter,
} from 'lane-shared/helpers/formatters';

import { useGetActionsForProducts } from './useGetActionsForProducts';

import useUserLocale from 'hooks/useUserLocale';

export function useGetProductsServicesTableColumns(
  channelId: string | undefined,
  onDelete: (product: Product) => void,
  canManage?: boolean
): Column<Product>[] {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const { currency } = useBillingPaymentSettingsForChannel({ channelId });

  const renderFixedAmountCell = (cell: number) =>
    currencyFormatter({
      currency: CurrencyFormatMap[currency],
    })(cell);

  const productActions = useGetActionsForProducts({ onDelete });

  const columns: Column<Product>[] = [
    {
      key: 'name',
      header: t('abp.productsServices.table.productName'),
    },
    {
      key: 'productCategory',
      header: t('abp.productsServices.table.categoryName'),
      renderCell: (cell: ProductCategory) => cell.name,
    },
    {
      key: 'productType',
      header: t('abp.productsServices.table.productType'),
      renderCell: (_: undefined, row: Product) =>
        t(
          `abp.productsServices.productType.${row.productCategory.productType}`
        ),
    },
    {
      key: 'chargeCode',
      header: t('abp.productsServices.table.chargeCode'),
      renderCell: (cell: ChargeCode) => cell?.chargeCode || '',
    },
    {
      key: 'amount',
      header: t('abp.productsServices.table.rate'),
      renderCell: renderFixedAmountCell,
    },
    {
      key: 'markup',
      header: t('abp.productsServices.table.markup'),
      renderCell: (cell: Markup) =>
        cell.markupAmountType === MarkupAmountType.MarkupAmountTypePercentage
          ? `${cell.value} %`
          : renderFixedAmountCell(cell.value),
    },
    {
      key: 'tax',
      header: t('abp.productsServices.table.tax'),
      renderCell: (cell: Tax) =>
        cell.taxAmountType === TaxAmountType.TaxAmountTypePercentage
          ? `${cell.value} %`
          : renderFixedAmountCell(cell.value),
    },
    {
      key: 'total',
      header: t('abp.productsServices.table.total'),
      renderCell: (_: undefined, row: Product) => {
        const netPrice = getPricing({
          currency: currencyToPaymentCurrency(currency),
          locale,
          priceComponents: row,
        }).netPrice;

        return simpleCurrencyFormatter(
          netPrice,
          currencyToPaymentCurrency(currency)
        );
      },
    },
  ];

  const actionColumn: Column<Product> = {
    key: 'actions',
    header: t('abp.actions'),
    renderCell: (_cell: boolean, row: Product) => {
      return (
        <PopupMenu
          trigger={
            <TextButton iconName="angle-down">{t('abp.actions')}</TextButton>
          }
          items={productActions.map(({ label, onSelect }) => ({
            label,
            onSelect: () => {
              onSelect(row);
            },
            isDisabled: false,
          }))}
        />
      );
    },
  };

  if (canManage) columns.push(actionColumn);

  return columns;
}
