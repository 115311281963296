import React from 'react';

import { useTranslation } from 'react-i18next';

import MenuItemOption from './MenuItemOption';

import styles from './MenuItemOptionList.scss';

function MenuItemOptionList({
  disabled,
  loading,
  optionList,
  orderOptionList,
  onOrderOptionListUpdate,
  currencyFormat,
}: any) {
  const { t } = useTranslation();

  function updateOrderOptionList(props: any) {
    onOrderOptionListUpdate({
      ...orderOptionList,
      ...props,
    });
  }

  function onOrderOptionSelected(option: any) {
    if (optionList.maxChoices === 1) {
      updateOrderOptionList({
        options: [option._id],
      });

      return;
    }

    if (orderOptionList.options.includes(option._id)) {
      updateOrderOptionList({
        options: orderOptionList.options.filter((id: any) => id !== option._id),
      });

      return;
    }

    if (orderOptionList.options.length > optionList.maxChoices) {
      return;
    }

    updateOrderOptionList({
      options: [...orderOptionList.options, option._id],
    });
  }

  let message = (
    <h2>
      {t('Select up to {{choices}}', { choices: optionList.maxChoices })}.
    </h2>
  );

  if (optionList.minChoices > 0) {
    message = (
      <h2>
        {t('Select at least {{minChoices}} and up to {{maxChoices}}', {
          minChoices: optionList.minChoices,
          maxChoices: optionList.maxChoices,
        })}
      </h2>
    );
  }

  if (optionList.minChoices === 1 && optionList.maxChoices === 1) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Element'.
    message = null;
  }

  return (
    <div className={styles.MenuItemOptionList}>
      <h1>
        <span>{optionList.name}</span>
      </h1>
      {message}
      <div className={styles.options}>
        {optionList.options.map((option: any) => (
          <MenuItemOption
            key={option._id}
            disabled={disabled}
            loading={loading}
            option={option}
            selected={orderOptionList.options.includes(option._id)}
            currencyFormat={currencyFormat}
            onOrderOptionSelected={onOrderOptionSelected}
            isCheckbox={optionList.maxChoices > 1}
            isRadio={optionList.maxChoices === 1}
          />
        ))}
      </div>
    </div>
  );
}

export default MenuItemOptionList;
