import { useState, useEffect, useRef } from 'react';

import gql from 'graphql-tag';

import {
  ApolloError,
  useLazyQuery,
  WatchQueryFetchPolicy,
} from '@apollo/client';

import { UserContentInteractionType } from 'lane-shared/types/UserContentInteraction';

import { getClient } from '../apollo';
import {
  PublicUserFragment,
  ThemeFragment,
  FullProfileWithThemeFragment,
  FullContentFragment,
} from '../graphql/fragments';
import ContentCacheHelper from '../helpers/ContentCacheHelper';
import { ContentType } from '../types/content/Content';
import { useMultiLanguage } from './useMultiLanguage';

// 10 minutes
const CONTENT_POLL_INTERVAL = 10 * 60 * 1000;
// earliest date possible, will cause a cache reset if no publishedAt exists
const EARLIEST_DATE = new Date(1);

const publicContentQuery = gql`
  ${ThemeFragment}
  ${FullProfileWithThemeFragment}
  ${FullContentFragment}

  query getPublicContent($id: UUID!, $search: ContentSearch) {
    content(_id: $id, search: $search) {
      ...FullContentFragment
    }
  }
`;

const contentQuery = gql`
  ${ThemeFragment}
  ${FullProfileWithThemeFragment}
  ${FullContentFragment}

  query contentQuery($id: UUID!, $search: ContentSearch) {
    me {
      content(_id: $id, search: $search) {
        ...FullContentFragment
        userAccess
      }
    }
  }
`;

const interactionsQuery = gql`
  ${PublicUserFragment}

  query interactionsQuery($id: UUID!, $search: ContentSearch) {
    me {
      content(_id: $id, search: $search) {
        _id
        isInteractive

        userInteractions {
          pageInfo {
            total
            start
            perPage
          }

          items {
            _id
            _created
            _updated
            _createdBy {
              ...PublicUserFragment
            }
            _updatedBy {
              ...PublicUserFragment
            }
            user {
              _id
              name
            }
            channel {
              _id
            }
            startDate
            endDate
            geo
            data
            state
            features
            actions
            contentData
            version
            status
          }
        }
      }
    }
  }
`;

type Props = {
  /** the contentId in question */
  contentId: string | undefined | null;
  /** which query to use, either the public content query or the me.content graphql endpoint */
  forPublic?: boolean;
  /** include interactions related to this content? */
  includeInteractions?: boolean;
  /** disable in-memory cache */
  ignoreCache?: boolean;
  /** pass-thru for apollo-client onCompleted hook */
  onCompleted?: (content: ContentType | undefined) => void;
  /** pass-thru for apollo-client onError hook */
  onError?: (error: ApolloError) => void;
  /** pass-thru for apollo-client fetchPolicy */
  fetchPolicy?: WatchQueryFetchPolicy;
};

export type ContentQueryInteraction = Pick<
  UserContentInteractionType,
  | '_id'
  | '_created'
  | '_updated'
  | 'startDate'
  | 'endDate'
  | 'geo'
  | 'data'
  | 'state'
  | 'features'
  | 'contentData'
  | 'version'
  | 'status'
>;

export async function getContent(contentId: string, forPublic: boolean) {
  const cached = ContentCacheHelper.getSync(contentId);

  if (cached) {
    return cached;
  }

  const query = forPublic ? publicContentQuery : contentQuery;

  const { data } = await getClient().query({
    query,
    fetchPolicy: 'network-only',
    variables: { id: contentId },
  });

  const content = data?.me?.content || data?.content;

  if (content) {
    ContentCacheHelper.set(content);
  }

  return content;
}

/**
 * A useful hook for getting content.  This will cache data and only query
 * get data from the server if the Content has been updated since
 * the last update time.
 *
 * This will also get the interactions for the requested content if desired
 *
 * TODO: call onCompleted on initial load if content is already present because otherwise if content is retrieved from cache onCompleted never gets triggered
 */
export default function useContentQuery(
  {
    contentId,
    forPublic = false,
    includeInteractions = false,
    onCompleted,
    onError,
    fetchPolicy = 'cache-and-network',
    ignoreCache = false,
  }: Props,
  isPollingEnabled: boolean = true
): {
  error: ApolloError | undefined;
  content: ContentType | null | undefined;
  interactions: ContentQueryInteraction[];
  loading: boolean;
  getContent: () => Promise<void>;
  getContentInteractions: () => Promise<void>;
} {
  const [content, setContent] = useState<ContentType | null | undefined>(
    !ignoreCache ? ContentCacheHelper.getSync(contentId) : undefined
  );
  const { translate } = useMultiLanguage();

  const [interactions, setInteractions] = useState<ContentQueryInteraction[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const pollingIntervalId = useRef<NodeJS.Timeout | null>(null);

  function handleCompleted(
    data: Partial<{ content: ContentType; me: { content: ContentType } }>
  ) {
    const content = data?.me?.content || data?.content;

    if (content) {
      setContent(content);
      ContentCacheHelper.set(content);
    }

    if (onCompleted) {
      onCompleted(content);
    }
  }

  // anonymous / not logged in users can still access content if its publicly
  // posted, but its a slightly different end point (because those users won't
  // be able to ask for a list on interactions back as an example).
  const [loadPublicContent, publicContentResults] = useLazyQuery(
    publicContentQuery,
    {
      client: getClient(),
      fetchPolicy,
      onCompleted: handleCompleted,
      onError,
    }
  );

  const [loadContent, contentResults] = useLazyQuery(contentQuery, {
    client: getClient(),
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
    onCompleted: handleCompleted,
  });

  const [loadContentInteractions, interactionResults] = useLazyQuery(
    interactionsQuery,
    {
      client: getClient(),
      fetchPolicy: 'cache-and-network',
    }
  );

  useEffect(() => {
    if (interactionResults?.data?.me?.content?.userInteractions?.items) {
      setInteractions(
        translate({
          model: JSON.parse(
            JSON.stringify(
              interactionResults.data.me.content.userInteractions?.items
            )
          ),
        })
      );
    }
  }, [interactionResults?.data]);

  async function getContentInteractions() {
    loadContentInteractions({
      variables: {
        id: contentId,
      },
    });
  }

  async function getContent() {
    // NOTE: Previously we checked if there was a query in the
    // loading state. This was too hard a condition, and would
    // occasionally impact users who had logged in and logged
    // out. For the time being we are removing the defensive
    // load. A more correct way of doing this might be debouncing
    // the methods `loadContent` and `loadPublicContent`.

    // check the cache first.
    const cachedContent =
      contentId && !ignoreCache
        ? await ContentCacheHelper.get(contentId)
        : null;

    if (cachedContent) {
      setContent(cachedContent);
    } else {
      setLoading(true);
    }

    const variables = {
      id: contentId,
      search: cachedContent
        ? {
            publishedAt: {
              type: 'gt',
              value: cachedContent.publishedAt || EARLIEST_DATE,
            },
          }
        : undefined,
    };

    if (!forPublic) {
      loadContent({
        variables,
      });
    } else {
      loadPublicContent({
        variables,
      });
    }
  }

  useEffect(() => {
    if (Boolean(contentId) && contentId !== content?._id) {
      getContent();

      // setup a interval to update content periodically
      // note that getContent will use the last updated time stamp,
      // so if the content has not changed, this does not do anything
      if (isPollingEnabled) {
        clearPollingIntervalId();
        pollingIntervalId.current = setInterval(
          getContent,
          CONTENT_POLL_INTERVAL
        );
      }
    }

    return clearPollingIntervalId;
  }, [forPublic, contentId]);

  function clearPollingIntervalId() {
    if (pollingIntervalId.current) {
      clearInterval(pollingIntervalId.current);
      pollingIntervalId.current = null;
    }
  }

  useEffect(() => {
    if (!forPublic && contentId && includeInteractions) {
      getContentInteractions();
    }
  }, [forPublic, contentId, includeInteractions]);

  useEffect(
    function turnOffLoading() {
      const isAnythingLoading =
        contentResults.loading ||
        publicContentResults.loading ||
        interactionResults.loading;

      // only switch loading off, loading is switched on after checking cache
      if (!isAnythingLoading) {
        setLoading(false);
      }
    },
    [contentResults, publicContentResults, interactionResults]
  );
  const translatedContent = translate({
    model: content,
  });

  return {
    error: contentResults.error || publicContentResults.error,
    content: translatedContent,
    interactions,
    loading,
    getContent,
    getContentInteractions,
  };
}
