import React, { useContext, useEffect, useState, useMemo } from 'react';

import cx from 'classnames';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as Sentry from '@sentry/browser';

import { LaneType } from 'common-types';
import { getClient } from 'lane-shared/apollo';
import { colors, routes } from 'lane-shared/config';
import { ChannelsContext } from 'lane-shared/contexts';
import { useVisitorPassServiceData } from 'lane-shared/domains/visitorManagement/hooks/useVisitorPassServiceData';
import { updateUserContentInteraction } from 'lane-shared/graphql/mutation';
import { getFriendlyInteractionDate } from 'lane-shared/helpers/content';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { longDate } from 'lane-shared/helpers/formatters';
import { useUserContentInteractionReceipt, useFlag } from 'lane-shared/hooks';
import { useInteractionAnalytics } from 'lane-shared/hooks/analytics';
import useCancelUCI from 'lane-shared/hooks/features/cancel/useCancelUCI';
import useGuestInviteToIcsAttendees from 'lane-shared/hooks/features/useGuestInviteToIcsAttendees';
import { useUserNotesEnabled } from 'lane-shared/hooks/useUserNotesEnabled';
import { UserContentInteractionFeaturesType } from 'lane-shared/types/UserContentInteraction';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';
import { MenuFeatureOrderType } from 'lane-shared/types/features/MenuFeatureTypes';
import { PaymentFeatureStripeDataType } from 'lane-shared/types/features/PaymentFeatureInteractionData';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';
import { PaymentFeatureQuoteType } from 'lane-shared/types/payment/PaymentFeatureQuoteType';
import { InteractionCreatorType } from 'lane-shared/types/server/WorkflowTypes';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import Pill from 'components/general/Pill';
import { Typography } from 'components/lds';
import getPaymentDetailsWithQuote from 'lane-shared/helpers/getPaymentDetailsWithQuote';
import {
  VisitorManagementStatusChip,
  VisitorManagementCancelButton,
  VisitorManagementReceipt,
} from '../../domains/visitorManagement/components';
import PropertyValue from '../builder/properties/display/PropertyValue';
import ContentFeatureCalendarButton from '../features/ContentFeatureCalendarButton';
import ContentFeatureGoogleCalendarButton from '../features/ContentFeatureGoogleCalendarButton';
import GuestInviteGuestListItem from '../features/GuestInvite/GuestInviteGuestListItem';
import PaymentFeatureQuote from '../features/PaymentFeatureQuote';
import LinkButton from '../general/LinkButton';
import Loading from '../general/Loading';
import Request from '../integrations/AngusServiceRequests/components/Request';
import ParkWhizReceipt from '../integrations/ParkWhiz/ParkWhizReceipt';
import MeetingDetails from '../integrations/ProxyClick/components/MeetingDetails';
import BurstInteractionView from '../integrations/ShopifyBurst/pages/BurstInteractionView';
import MenuContentInteractionReceipt from './MenuContentInteractionReceipt';
import PaymentContentInteractionReceipt from './PaymentContentInteractionReceipt';
import QRCodeCheckInOutUCIReceipt from './userContentInteractionReceipts/QRCodeCheckInOutUCIReceipt';
import { QuantityReceipt } from './userContentInteractionReceipts/QuantityReceipt';
import { getInteractionColor } from 'helpers/getInteractionColor';
import useUserLocale from 'hooks/useUserLocale';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'qrco... Remove this comment to see the full error message
import QRCode from 'qrcode.react';
import { TranslationKeys } from 'localization';

import styles from './UserContentInteractionReceipt.scss';

const TRANSLATION_KEYS: { [key: string]: TranslationKeys } = Object.freeze({
  createdAt: 'web.content.interaction.receipt.createdDate',
  reservedFor: 'web.content.interaction.receipt.reservationLabel',
});

function PropertyValueWrapper({ name, value, property }: any) {
  if (value === undefined) {
    return null;
  }

  return (
    <p className={styles.verticalFlex}>
      <b>{property.friendlyName || property.name || name}:</b>
      <PropertyValue value={value} field={property} variant="receipt" />
    </p>
  );
}

type Props = {
  style?: React.CSSProperties;
  className?: string;
  interaction: {
    _id: LaneType.UUID;
    _created: string;
    _createdBy: InteractionCreatorType;
    startDate: string | Date | null;
    endDate: string | Date | null;
    status: string;
    geo: GeoCoordinateType;
    features: UserContentInteractionFeaturesType;
    contentData: {
      channelId?: string | null;
      geo?: GeoCoordinateType | null;
    };
  };
  onInteractionUpdated?: (interaction: any) => void;
  onInteractionCancelled?: () => void;
};

/**
 * @deprecated
 *
 * Use <NewUserContentInteractionReceipt /> component instead
 */
export default function UserContentInteractionReceipt({
  className,
  style,
  interaction: interactionData,
  onInteractionUpdated = () => {},
  onInteractionCancelled = () => {},
}: Props) {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const [searchParams] = useQueryString<{ setStatus: string }>();
  const { primaryId } = useContext(ChannelsContext);
  const [updateLoading, setUpdateLoading] = useState(false);
  const interaction = useMemo(() => {
    if (searchParams.setStatus && interactionData) {
      return {
        ...interactionData,
        status: searchParams.setStatus,
      };
    }

    return interactionData;
  }, [searchParams, interactionData]);

  // check cancellation refund setting
  const paymentFeatureV2FF = useFlag(FeatureFlag.PaymentFeatureV2, false);
  const hasPaymentFeature = interaction?.features.Payment;
  const RefundFF = useFlag(FeatureFlag.PaymentRefund, false);
  const { cancellationRefund } = useBillingPaymentSettingsForChannel({
    channelId: interaction?.contentData.channelId || '',
  });

  async function confirmCancelUCI() {
    // Payment feature does not exist
    if (
      hasPaymentFeature &&
      paymentFeatureV2FF &&
      RefundFF &&
      cancellationRefund
    ) {
      // refund on
      const paymentDetails = getPaymentDetailsWithQuote(
        interaction?.features?.Payment?.quote as PaymentFeatureQuoteType,
        locale
      );
      const withProduct =
        ((interaction?.features?.Payment?.quote as PaymentFeatureQuoteType)
          .products?.length ?? 0) > 0;

      return window.Alert.confirm({
        title: t(
          'shared.content.interaction.receipt.cancel.withPaymentFeature.refundOn.title',
          {
            contentName: content.name,
          }
        ),
        message: t(
          'shared.content.interaction.receipt.cancel.withPaymentFeature.refundOn.message',
          {
            amount: withProduct
              ? paymentDetails.cashTotal
              : paymentDetails.total,
          }
        ),
        cancelText: t(
          'shared.content.interaction.receipt.cancel.withPaymentFeature.cancelText'
        ),
        confirmText: t(
          'shared.content.interaction.receipt.cancel.withPaymentFeature.confirmText'
        ),
      });
    }

    // refund off
    if (hasPaymentFeature && paymentFeatureV2FF) {
      return window.Alert.confirm({
        title: t(
          'shared.content.interaction.receipt.cancel.withPaymentFeature.refundOff.title',
          {
            contentName: content.name,
          }
        ),
        message: t(
          'shared.content.interaction.receipt.cancel.withPaymentFeature.refundOff.message'
        ),
        cancelText: t(
          'shared.content.interaction.receipt.cancel.withPaymentFeature.cancelText'
        ),
        confirmText: t(
          'shared.content.interaction.receipt.cancel.withPaymentFeature.confirmText'
        ),
      });
    }

    // payment feature does not exist or paymentFeatureV2 flag is not on
    return window.Alert.confirm({
      title: t('Cancel your "{{- contentName}}" entry?', {
        contentName: content.name,
      }),
      message: t(
        `Are you sure you want to cancel "{{- contentName}}"?\n\nYou won't be able to undo this once it is done.`,
        { contentName: content.name }
      ),
    });
  }

  const {
    content,
    features,
    properties,
    integrationProvider,
    integrationData,
    integrationProperties,
    canUserCancel,
    isCheckInOut,
    inClosedState,
    qrCodeUrl,
    hasDates,
    hasStatuses,
    timeZone,
    userName,
  } = useUserContentInteractionReceipt({ interaction });

  const { qrCodeCheckinFeature } = explodeFeatures(content?.features);

  const contentChannel = {
    channel: {
      _id: content?.channelId || '',
      name: content?.channel?.name || '',
      address: content?.channel?.address || {
        street1: '',
        street2: '',
        city: '',
        country: '',
        code: '',
        state: '',
      },
    },
  };

  const {
    cancelableFeature,
    paymentFeature,
    reservableFeature,
    menuFeature,
    guestInviteFeature,
    essensysProductFeature,
    visitorManagementFeature,
    quantityFeature,
  } = features;

  const {
    data: visitorManagementServiceData,
    refetch,
  } = useVisitorPassServiceData({
    interactionId: interaction?._id,
    skip: !visitorManagementFeature,
  });

  const {
    loading: cancelLoading,
    error,
    isCancelled,
    cancel,
    reset,
  } = useCancelUCI(interaction, confirmCancelUCI, Sentry);

  const interactionCreatedBy = interaction?._createdBy?.profile;
  const organizer = interactionCreatedBy
    ? { email: interactionCreatedBy.email, name: interactionCreatedBy.name }
    : undefined;
  const [, attendees] = useGuestInviteToIcsAttendees(
    interaction?.features?.GuestInvite
  );

  const isUserNotesEnabled = useUserNotesEnabled();

  const interactionDate = hasDates
    ? getFriendlyInteractionDate(interaction, timeZone, t, locale)
    : longDate(interaction?.startDate || interaction?._created);

  const interactionCreated = longDate(interaction?._created, timeZone);

  useInteractionAnalytics('details', interaction);

  useEffect(() => {
    if (!isCancelled) {
      return;
    }

    onInteractionUpdated(interaction._id);
    onInteractionCancelled();
  }, [isCancelled]);

  useEffect(() => {
    if (!error) {
      return;
    }

    async function showError(err: Error) {
      await window.Alert.alert({
        title: t('An error occurred while cancelling this.'),
        message: err.message,
        error: err,
      });
      reset();
    }

    showError(error);
  }, [error]);

  useEffect(() => {
    if (
      !interaction?._id ||
      !searchParams.setStatus ||
      interactionData.status === searchParams.setStatus
    ) {
      return;
    }

    async function doUpdate() {
      setUpdateLoading(true);

      try {
        await getClient().mutate({
          mutation: updateUserContentInteraction,
          variables: {
            interaction: {
              status: searchParams.setStatus,
              _id: interaction._id,
            },
            meChannelId: primaryId,
          },
        });

        onInteractionUpdated(interaction._id);
      } finally {
        setUpdateLoading(false);
      }
    }

    doUpdate();
  }, [interaction?._id, searchParams]);

  function onCancelPressed() {
    cancel();
  }

  const loading = cancelLoading || updateLoading;

  // some integrations have special receipts.
  switch (integrationProvider) {
    case IntegrationProviderEnum.AngusServiceRequests:
      return (
        <Request
          className={cx(styles.UserContentInteractionReceipt, className)}
          style={style}
          interaction={interaction}
        />
      );
    case IntegrationProviderEnum.ParkWhiz:
      return (
        <ParkWhizReceipt
          className={cx(styles.UserContentInteractionReceipt, className)}
          style={style}
          parking={integrationData}
        />
      );
    case IntegrationProviderEnum.ProxyClick:
      return <MeetingDetails interaction={interaction} />;
    case IntegrationProviderEnum.ShopifyBurst:
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; _created: string; _createdBy:... Remove this comment to see the full error message
      return <BurstInteractionView interaction={interaction} />;
  }

  if (isCheckInOut) {
    return (
      <div
        className={cx(styles.UserContentInteractionReceipt, className)}
        style={style}
      >
        <QRCodeCheckInOutUCIReceipt
          // @ts-expect-error ts-migrate(2741) FIXME: Property 'contentData' is missing in type '{ _id: ... Remove this comment to see the full error message
          interaction={interaction}
          onInteractionUpdated={onInteractionUpdated}
          onInteractionCancelled={onInteractionCancelled}
        />
      </div>
    );
  }

  if (menuFeature) {
    return (
      <div
        className={cx(styles.UserContentInteractionReceipt, className)}
        style={style}
      >
        <MenuContentInteractionReceipt
          showSupport
          interactionId={interaction._id}
          contentChannelId={content.channelId}
          paymentFeature={paymentFeature}
          menuFeature={menuFeature}
          email={interaction.features.Payment?.receiptEmail}
          order={interaction.features.Menu!.order as MenuFeatureOrderType}
          stripeData={
            interaction.features.Payment
              ?.stripeData as PaymentFeatureStripeDataType
          }
          date={interactionCreated}
          loading={loading}
          // @ts-expect-error ts-migrate(2741) FIXME: Property 'contentData' is missing in type '{ _id: ... Remove this comment to see the full error message
          interaction={interaction}
          onCancel={onCancelPressed}
        />
      </div>
    );
  }

  if (paymentFeature && !essensysProductFeature) {
    return (
      <div
        className={cx(styles.UserContentInteractionReceipt, className)}
        style={style}
      >
        <PaymentContentInteractionReceipt
          email={interaction.features.Payment?.receiptEmail}
          contentDescription={content.description}
          contentName={content.name}
          contentChannelId={content.channelId}
          quote={interaction.features.Payment?.quote as PaymentFeatureQuoteType}
          stripeData={
            interaction.features.Payment
              ?.stripeData as PaymentFeatureStripeDataType
          }
          date={interactionCreated}
          reservable={Boolean(reservableFeature)}
          interaction={interaction}
          timeZone={timeZone}
          cancelable={Boolean(cancelableFeature)}
          loading={loading}
          disabled={inClosedState || !canUserCancel}
          onCancel={onCancelPressed}
          qrCode={Boolean(qrCodeCheckinFeature)}
          qrCodeUrl={qrCodeUrl}
          hasStatuses={hasStatuses}
        />
      </div>
    );
  }

  const isUserNotesAvailable =
    isUserNotesEnabled &&
    reservableFeature &&
    interaction.features.Reservable?.userNotes;

  return (
    <div
      className={cx(styles.UserContentInteractionReceipt, className)}
      style={style}
    >
      {visitorManagementFeature && (
        <VisitorManagementStatusChip
          visitorManagementServiceData={visitorManagementServiceData}
        />
      )}
      <Link
        to={routes.content.replace(':id', content?._id)}
        className={styles.contentTitleLink}
      >
        <Typography type="h4" mode="primary">
          {content?.name}
        </Typography>
      </Link>
      {qrCodeCheckinFeature && (
        <div className={styles.qrCode} data-test="qr-code-container">
          <QRCode renderAs="svg" value={qrCodeUrl} fgColor={colors.black} />
        </div>
      )}
      {hasStatuses && (
        <div className={styles.statusWrapper} data-test="statusPill">
          <Pill
            color={getInteractionColor(interaction?.status)}
            size="medium"
            text={t(interaction?.status)}
          />
        </div>
      )}
      <div data-test="interactionDate" className={styles.details}>
        <p className={styles.detailsDate}>
          <b>
            {t(
              hasDates
                ? TRANSLATION_KEYS.reservedFor
                : TRANSLATION_KEYS.createdAt
            )}
          </b>
          {interactionDate}
        </p>
        <hr />
        <div data-test="interactionName" className={styles.detailsItems}>
          <p className={cx(styles.verticalFlex, styles.noBorder)}>
            <b>{t('Name')}:</b>
            <span className={styles.name}>{userName}</span>
          </p>

          {isUserNotesAvailable ? (
            <p className={cx(styles.verticalFlex, styles.noBorder)}>
              <b>{reservableFeature?.userNotes?.labelText}:</b>
              <span className={styles.name}>
                {interaction?.features.Reservable?.userNotes}
              </span>
            </p>
          ) : null}

          <div className={styles.receiptInfo}>
            {properties.map(([key, property]) => (
              <PropertyValueWrapper
                key={key}
                value={(interaction as any)?.data[key]}
                name={key}
                property={property}
              />
            ))}
          </div>
          <div className={styles.integrationInfo}>
            {integrationProperties.map(([key, property]) => (
              <PropertyValueWrapper
                key={key}
                value={integrationData?.[key]}
                name={key}
                property={property}
              />
            ))}
          </div>
          <div className={styles.featuresWrapper}>
            <div className={styles.featuresInfo} data-loading={loading}>
              {guestInviteFeature && (
                <>
                  <p>
                    <b>{t('Guests')}</b>
                  </p>

                  {interaction.features.GuestInvite?.guests
                    ?.filter(guest => !!guest)
                    .map(guest => (
                      <GuestInviteGuestListItem
                        key={guest._id}
                        className={styles.guestListItem}
                        guest={guest}
                      />
                    ))}
                </>
              )}

              {visitorManagementFeature && (
                <VisitorManagementReceipt
                  key={interaction._id}
                  interaction={interaction}
                  properties={visitorManagementFeature}
                />
              )}

              {paymentFeature && !menuFeature && (
                <PaymentFeatureQuote
                  quote={
                    interaction.features.Payment!
                      .quote as PaymentFeatureQuoteType
                  }
                  stripeData={
                    interaction.features.Payment!
                      .stripeData as PaymentFeatureStripeDataType
                  }
                  channel={contentChannel}
                />
              )}

              {hasDates && (
                <>
                  <ContentFeatureCalendarButton
                    className={styles.linkButton}
                    name={content.name}
                    description={content.description}
                    startDate={interaction.startDate!}
                    endDate={interaction.endDate!}
                    interactionId={interaction._id}
                    attendees={attendees}
                    organizer={organizer}
                    status={t(interaction.status)}
                    geo={content.geo}
                    timeZone={timeZone}
                  />
                  <ContentFeatureGoogleCalendarButton
                    className={styles.linkButton}
                    name={content?.name}
                    description={content?.description}
                    startDate={interaction?.startDate ?? undefined}
                    endDate={interaction?.endDate ?? undefined}
                    timeZone={timeZone}
                  />
                </>
              )}

              {cancelableFeature && (
                <LinkButton
                  className={styles.linkButton}
                  disabled={inClosedState || !canUserCancel}
                  loading={loading}
                  onClick={onCancelPressed}
                  iconName="times"
                  doTranslate
                >
                  {t('Cancel')}
                </LinkButton>
              )}

              {quantityFeature && !paymentFeature && (
                <QuantityReceipt
                  quantityFeature={interaction.features.Quantity}
                />
              )}
            </div>

            {visitorManagementFeature && (
              <VisitorManagementCancelButton
                key={interaction._id}
                interaction={interaction}
                data={visitorManagementServiceData}
                refetch={refetch}
                title={content.name}
              />
            )}

            {loading && <Loading fullcover />}
          </div>
        </div>
      </div>
    </div>
  );
}
