import gql from 'graphql-tag';

export const getTenantSuites = gql`
  query GetTenantSuites($tenantId: UUID!, $propertyId: UUID!) {
    tenant(tenantId: $tenantId, propertyId: $propertyId) {
      id
      suites {
        id
        name
        floors {
          id
          name
        }
      }
    }
  }
`;

export const getTenantsByUser = gql`
  query GetTenantsByUser($propertyId: UUID!, $userId: UUID!) {
    tenantsByUser(propertyId: $propertyId, userId: $userId) {
      id
      suites {
        id
        name
        floors {
          id
          name
        }
      }
    }
  }
`;
