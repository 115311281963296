import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'components';
import { Location } from 'history';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { Modal } from 'components/lds';
import { H4, M } from 'components/typography';

import history from '../../../helpers/history';

import styles from './styles.scss';

export const PageNavigationAlert = ({
  when,
  testId,
}: {
  when: boolean;
  testId: string;
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [location, setLocation] = useState<Location | null>(null);
  const [isPageNavigationConfirmed, setIsPageNavigationConfirmed] = useState(
    false
  );

  const whenRef = useRef(when);

  useEffect(() => {
    whenRef.current = when;
  }, [when]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (whenRef.current) {
        e.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (location && isPageNavigationConfirmed) {
      const url = location.pathname + location.search;

      history.push(url);
    }

    setIsPageNavigationConfirmed(false);
  }, [location, isPageNavigationConfirmed]);

  const handlePageNavigationAlert = (location: Location) => {
    if (isPageNavigationConfirmed) {
      return true;
    }

    setIsPageNavigationConfirmed(false);
    setLocation(location);
    setIsModalOpen(true);

    return false;
  };

  const handleReturnToPage = () => {
    setLocation(null);
    setIsPageNavigationConfirmed(false);
    setIsModalOpen(false);
  };

  const handlePageNavigationConfirmation = () => {
    setIsPageNavigationConfirmed(true);
    setIsModalOpen(false);
  };

  const ReturnButton = (
    <Button
      key="1"
      onClick={handleReturnToPage}
      fullWidth
      testId="returnToPage"
    >
      {t('web.components.general.pageNavigationAlert.cancelText')}
    </Button>
  );

  const ConfirmButton = (
    <Button
      key="2"
      variant="contained"
      onClick={handlePageNavigationConfirmation}
      fullWidth
      testId="confirmButton"
    >
      {t('web.components.general.pageNavigationAlert.confirmText')}
    </Button>
  );

  return (
    <>
      <Prompt when={when} message={handlePageNavigationAlert} />
      <Modal
        isOpen={isModalOpen}
        onClose={handleReturnToPage}
        actions={[ReturnButton, ConfirmButton]}
        testId={testId}
      >
        <div className={styles.pageNavigationAlert}>
          <H4 className={styles.title}>
            {t('web.components.general.pageNavigationAlert.title')}
          </H4>
          <M className={styles.message}>
            {t('web.components.general.pageNavigationAlert.message')}
          </M>
        </div>
      </Modal>
    </>
  );
};
