import gql from 'graphql-tag';

import { ApolloError, useQuery } from '@apollo/client';

function composeQuery(contentIds: string[]) {
  if (contentIds.length === 0) {
    // noop query
    return gql`
      query checkInteractions {
        _
      }
    `;
  }

  const getQuery = (contentId: any, index: any) => `
    interaction${index}: me {
      contentInteractions(
        search: { content: { _id: "${contentId}" } }
        pagination: { start: 0, perPage: 1 }
      ) {
        items {
          content {
            _id
          }
        }
      }
    }
  `;

  return gql`
    query checkInteractions {
      ${contentIds.map(getQuery)}
    }
  `;
}

export default function useMyInteractionsOnContentsExist({
  contentIds,
}: {
  contentIds: string[];
}): {
  error?: ApolloError;
  result: Record<string, boolean>;
  loading: boolean;
  refetch: () => void;
} {
  const result = {};
  const { data, error, loading, refetch } = useQuery(composeQuery(contentIds), {
    fetchPolicy: 'network-only',
    skip: !contentIds.length,
    variables: {},
  });

  if (!contentIds.length) {
    return { result, loading: false, refetch: () => undefined };
  }

  console.log({ data });
  contentIds.forEach((id, index) => {
    const res = data?.[`interaction${index}`];

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    result[id] = Boolean(res?.contentInteractions?.items.length);
  });

  return { result, error, loading, refetch };
}
