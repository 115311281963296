import React from 'react';

import { PopupMenu, Icon, Button, Box, BoxSpacing } from 'design-system-web';

import { ChipSelect, ChipStyle } from 'components/ads';

import { BreadCrumbs } from '../lds';
import { Typography, M, S } from '../typography';

import styles from './PageHeader.scss';

export type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'text';

type Props = {
  header?: string;
  headerLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  headerSemanticLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  description?: React.ReactNode;
  breadcrumbs?: {
    label: string;
    url?: string;
  }[];
  externalPadding?: BoxSpacing;
  actionButtons?: {
    label: string;
    type?: 'primary' | 'secondary' | 'tertiary' | 'text';
    disabled?: boolean;
    onClick?: () => void;
    testId?: string;
    items?: {
      label: string;
      disabled?: boolean;
      onSelect: () => void;
    }[];
    size?: 'medium' | 'large';
    loading?: boolean;
    startIcon?: React.ReactNode;
  }[];
  customActions?: React.ReactNode;
  status?: string;
  statusType?: ChipStyle;
  promotedMetadata?: React.ReactNode;
  controls?: React.ReactNode;
};

export function PageHeader({
  externalPadding = [3, 5],
  header,
  headerLevel = 'h3',
  headerSemanticLevel = 'h1',
  description,
  breadcrumbs = [],
  actionButtons = [],
  customActions,
  status,
  statusType = ChipStyle.Grey,
  promotedMetadata,
  controls,
}: Props) {
  return (
    <Box p={externalPadding}>
      {breadcrumbs.length > 0 && (
        <div className={styles.BreadcrumbWrapper}>
          <BreadCrumbs links={breadcrumbs} />
        </div>
      )}
      {header && (
        <div className={styles.HeaderWrapper}>
          <div className={styles.StatusHeader}>
            <Typography level={headerLevel} as={headerSemanticLevel}>
              {header}
            </Typography>
            {status && (
              <ChipSelect.NonInteractive value={status} type={statusType} />
            )}
          </div>
          <div className={styles.CTAWrapper}>
            {actionButtons.length > 0 &&
              actionButtons.map((actionButton, index) =>
                actionButton.items && actionButton.items.length > 0 ? (
                  <PopupMenu
                    key={index}
                    trigger={
                      <Button
                        endIcon={<Icon name="chevron-down" />}
                        variant={actionButton.type || 'primary'}
                        size={actionButton.size || 'large'}
                        className={styles.ActionButton}
                      >
                        {actionButton.label}
                      </Button>
                    }
                    items={actionButton.items}
                  />
                ) : (
                  <Button
                    key={index}
                    variant={actionButton.type || 'primary'}
                    onClick={actionButton.onClick}
                    className={styles.ActionButton}
                    disabled={actionButton.disabled}
                    testId={actionButton.testId}
                    size={actionButton.size || 'large'}
                    loading={actionButton.loading}
                    startIcon={actionButton.startIcon}
                  >
                    {actionButton.label}
                  </Button>
                )
              )}
            {customActions}
          </div>
        </div>
      )}
      {description && (
        <div className={styles.DescriptionWrapper}>
          <M>{description}</M>
        </div>
      )}
      {promotedMetadata && (
        <div className={styles.PromotedMetadataWrapper}>
          <S variant="secondary">{promotedMetadata}</S>
        </div>
      )}
      {controls && <div className={styles.ControlsWrapper}>{controls}</div>}
    </Box>
  );
}
