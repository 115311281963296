import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  PresetContentSort,
  PresetContentSortFriendlyName,
} from 'lane-shared/types/filters/PresetContentSort';

import Chip from 'components/general/Chip';
import DropdownContextMenu from 'components/general/DropdownContextMenu';
import OptionsMenu from 'components/general/OptionsMenu';

interface Props {
  selectedSorting?: PresetContentSort;
  selected: boolean;
  onChange: (value: PresetContentSort) => void;
  sortings: PresetContentSort[];
}

export default function SortingFilter({
  onChange,
  selectedSorting,
  selected,
  sortings,
}: Props) {
  const [isOpen, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const handleChange = (e: any) => {
    onChange(e);
    setOpen(false);
  };

  const items = React.useMemo(() => {
    return sortings
      ? sortings.map(sort => ({
          label: PresetContentSortFriendlyName[sort],
          value: sort,
        }))
      : [];
  }, [sortings]);

  return (
    <DropdownContextMenu
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      title={
        <Chip
          onClick={() => setOpen(!isOpen)}
          label={
            selectedSorting
              ? PresetContentSortFriendlyName[selectedSorting]
              : t('Sort by')
          }
          selected={selected}
          deleteIcon={<Icon name="angle-down" set={ICON_SET_FONTAWESOME} />}
        />
      }
      hasRippleEffect
    >
      <OptionsMenu
        onChange={handleChange}
        items={items}
        value={selectedSorting}
      />
    </DropdownContextMenu>
  );
}
