import { useState, useEffect } from 'react';

/**
 * Helper hook to manage updating data objects that simplifies a common
 * validation pattern.
 *
 * takes in a data object and a validator, and when that data object changes
 * runs the validation and sets the state accordingly.
 *
 * @param data the object to validate
 * @param validator the yup validation schema
 * @returns {[unknown, validate]}
 */
export default function useValidation(data: any, validator: any) {
  const [validation, setValidation] = useState(null);

  async function validate() {
    try {
      await validator.validate(data, { abortEarly: false });
      setValidation(null);

      return true;
    } catch (err) {
      setValidation(err);

      return false;
    }
  }

  useEffect(() => {
    validate();
  }, [data, validator]);

  return [validation, validate];
}
