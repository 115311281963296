import { useRef, useState, useEffect, useMemo } from 'react';

import { useDebounce } from 'use-debounce';

import {
  useQuery,
  ApolloError,
  NetworkStatus,
  OperationVariables,
} from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import queryChannelsByRelationship from '../graphql/channel/queryChannelsByRelationship';
import { ChannelType } from '../types/ChannelType';
import { PageInfoType } from '../types/graphql/search';

const EMPTY_ARRAY = Object.freeze([]);

type Props = {
  skip?: boolean;
  perPage?: number;
  relatedTo?: any;
  channel?: any;
  autoHide?: boolean;
};

export type ItemType = {
  channel: ChannelType;
  relatedTo: ChannelType;
};

type HookReturnType = {
  items: ItemType[];
  page: number;
  pageInfo: PageInfoType;
  loading: boolean;
  error: ApolloError | undefined;
  called: boolean;
  isHidden: boolean;
  fetchNextPage: () => void;
  refetch: () => void;
  networkStatus: NetworkStatus;
};

export default function useChannelsByRelationshipQuery({
  skip = false,
  perPage = 10,
  relatedTo,
  channel,
}: // autoHide,
Props): HookReturnType {
  const [page, setPage] = useState(0);
  const [variables, setVariables] = useState<undefined | OperationVariables>(
    undefined
  );
  const pageRef = useRef({ page: 0 });

  useEffect(() => {
    const variables: any = {
      pagination: {
        start: 0,
        perPage,
      },
      search: {
        channel: channel || {
          sortBy: { key: 'name', dir: 'asc' },
          isSub: false,
        },
      },
    };

    if (relatedTo) {
      variables.search.relatedTo = relatedTo;
    }

    setVariables(variables);
    setPage(0);
  }, [perPage, JSON.stringify(channel), JSON.stringify(relatedTo)]);

  const [debouncedVariables] = useDebounce(variables, 250);

  const debouncedSearch = debouncedVariables?.search;
  const relatedToIdExists =
    debouncedSearch?.relatedTo?._id || debouncedSearch?.relatedTo?.parent?._id;
  const channelIdExists =
    debouncedSearch?.channel?._id || debouncedSearch?.channel?.parent?._id;

  const skipChannelRelationshipQuery =
    skip || !debouncedVariables || !(relatedToIdExists || channelIdExists);

  const {
    data,
    loading,
    fetchMore,
    error,
    called,
    refetch,
    networkStatus,
  } = useQuery(queryChannelsByRelationship, {
    fetchPolicy: 'network-only',
    skip: skipChannelRelationshipQuery,
    variables: debouncedVariables,
  });

  const pageInfo = useMemo(
    () =>
      data?.channelsByRelationship?.pageInfo || { total: 0, start: 0, perPage },
    [data?.channelsByRelationship]
  );

  async function doFetchMore() {
    await fetchMore({
      variables: {
        ...(variables || {}),
        pagination: {
          start: pageInfo.start + perPage,
          perPage,
        },
      },
      updateQuery: (prev: any, { fetchMoreResult }) => {
        // Don't do anything if there weren't any new items
        if (
          !fetchMoreResult ||
          // prevent appending page #2 twice into items
          prev.channelsByRelationship.pageInfo.start ===
            fetchMoreResult.channelsByRelationship.pageInfo.start
        ) {
          return prev;
        }

        return {
          // Concatenate the new feed results after the old ones
          channelsByRelationship: {
            __typename: 'channelsByRelationship',
            pageInfo: fetchMoreResult.channelsByRelationship.pageInfo,
            items: [
              ...prev.channelsByRelationship.items,
              ...fetchMoreResult.channelsByRelationship.items,
            ],
          },
        };
      },
    });
  }

  useEffect(() => {
    if (called && !loading && page !== pageRef.current.page) {
      pageRef.current.page = page;
      doFetchMore();
    }
  }, [page, called, loading]);

  function fetchNextPage() {
    // prefer eager returns so page is better in sync with pageInfo
    if (loading || error) {
      return;
    }

    if (perPage + pageInfo.start >= pageInfo.total) {
      // No more results.
      return;
    }

    setPage(page => page + 1);
  }

  const items: ItemType[] = useMemo(
    () =>
      !skip
        ? cloneDeep(data?.channelsByRelationship?.items) || EMPTY_ARRAY
        : EMPTY_ARRAY,
    [skip, data?.channelsByRelationship?.items]
  );

  // isHidden should only take effect if there is no search.
  const fastIsHidden = false;
  // todo: disabled for now
  //  ((autoHide && !called) || (called && !loading && items?.length === 0));

  const [isHidden] = useDebounce(fastIsHidden, 100);

  return {
    items,
    page,
    pageInfo,
    loading,
    called,
    error,
    isHidden,
    fetchNextPage,
    refetch,
    networkStatus,
  } as const;
}
