import { Workflow, WorkflowTypeEnum } from 'lane-shared/types/workflow';
import {
  WorkflowActionEnum,
  WorkflowTargetEnum,
  WorkflowWhenContextEnum,
  WorkflowWhenEnum,
} from 'lane-shared/types/Workflows';
import { v4 as uuid } from 'uuid';

export type Props = {
  channelId: string;
  when?: WorkflowWhenEnum;
  whenContext: WorkflowWhenContextEnum;
  action?: WorkflowActionEnum;
  target?: WorkflowTargetEnum;
  targetType?: string;
  event?: string;
  type?: WorkflowTypeEnum;
  options?: { [key: string]: any };
  order: number;
  userId: string;
  inStatus?: string[] | undefined | null;
  name?: string;
  workflow?: { on: string; value: string };
  time?: number;
};

export function constructWorkflow({
  when = WorkflowWhenEnum.Immediate,
  whenContext,
  action = WorkflowActionEnum.Email,
  target = WorkflowTargetEnum.All,
  targetType = 'ALL',
  channelId,
  event,
  type,
  options,
  order,
  userId,
  inStatus,
  name,
  workflow,
  time,
}: Props) {
  const emptyWorkflow = {
    _id: uuid(),
    when,
    whenContext,
    action,
    target,
    event,
    type,
    targetType,
    options,
    channelId,
    inStatus,
    workflow,
    time,
    payload: {},
    _created: new Date(),
    _updated: new Date(),
    _createdBy: userId,
    _updatedBy: userId,
    _order: order,
    name: name || '',
    deletedAt: undefined,
    ...options,
  } as Workflow;

  return emptyWorkflow;
}
