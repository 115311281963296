import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
} from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { UserDataContext } from 'lane-shared/contexts';
import { snapToGrid } from 'lane-shared/helpers';
import { parseDateTime } from 'lane-shared/helpers/dates';
import findClosestLocale from 'lane-shared/helpers/findClosestLocale';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import {
  getDisplayTime,
  getMinutesFromSlider,
  isRangeUnavailable,
  getPositionFromTimeRange,
} from 'lane-shared/helpers/timeRangeSlider';
import useTimeRangePicker, {
  TimeRangePickerProps,
} from 'lane-shared/hooks/useTimeRangePicker';

import { getBoundingClientRect } from '../../../helpers';
import { M, S } from '../../typography/index';
import DatePickerButton from './DatePickerButton';

import styles from './TimeRangePicker.scss';

type Position = {
  left: number;
  width: number;
};

type Side = 'slider' | 'left' | 'right';

type Props = TimeRangePickerProps & {
  className?: string;
  style?: React.CSSProperties;
  testId?: string;
};

// we will compute the actual option width later. But use a guess to start.
export const OPTION_WIDTH_GUESS = 32;
const ON_CHANGE_THROTTLE = 500;

/**
 * @deprecated use TimeRangePicker from design-system-web instead
 */
export default function TimeRangePicker({
  value,
  existingValue,
  timeZone,
  disabled = false,
  loading = false,
  onChange,
  onDayChange = () => {},
  slotSize = 30,
  minRangeSize,
  maxRangeSize,
  minTime = 0,
  maxTime = 24 * 60,
  minDate,
  maxDate,
  displayAllOptions = true,
  unavailableDateRanges = [],
  timeAvailabilityFeature,
  showDatePicker = true,
  showTimeSlider = true,
  className,
  style,
  testId,
  disabledWeekDays,
}: Props) {
  const { t } = useTranslation();
  const sliderWrapperRef = useRef<HTMLDivElement>(null);

  const [sliderUpdated, setSliderUpdated] = useState(Date.now());

  const {
    sliderInfo,
    optionWidth,
    calcMinWidth,
    calcMaxWidth,
    setOptionWidth,
    isInvalid,
    setIsInvalid,
    options,
    minSlot,
    maxSlot,
    enforcePositionConstraints,
    changeDay,
    existingValuePosition,
    updateCalendarReferenceDate,
  } = useTimeRangePicker({
    defaultOptionWidth: OPTION_WIDTH_GUESS,
    timeAvailabilityFeature,
    existingValue,
    onChange,
    onDayChange,
    slotSize,
    minTime,
    maxTime,
    minRangeSize,
    maxRangeSize,
    unavailableDateRanges,
    displayAllOptions,
    timeZone,
  });

  const debouncedOnChange = useDebouncedCallback(
    props => onChange(props),
    ON_CHANGE_THROTTLE
  ).callback;

  const uiSlotSize = slotSize / 30;

  function setPosition(position: Position) {
    if (position.left >= 0 && position.width > 0) {
      const { left, width } = enforcePositionConstraints(position);

      if (
        Math.floor(left) !== Math.floor(sliderInfo.position.left) ||
        Math.floor(width) !== Math.floor(sliderInfo.position.width)
      ) {
        sliderInfo.position = { left, width };
        setSliderUpdated(Date.now());
      }
    }
  }

  useEffect(() => {
    if (sliderInfo.isDragging) {
      return;
    }

    const minWidth = calcMinWidth(sliderInfo.optionWidth);
    const maxWidth = calcMaxWidth(sliderInfo.optionWidth);
    const startDate = parseDateTime(value?.startDate, timeZone);
    const endDate = parseDateTime(value?.endDate, timeZone);

    if (startDate) {
      sliderInfo.startDate = startDate;
    }

    if (endDate) {
      sliderInfo.endDate = endDate;
    }

    if (startDate && endDate) {
      const position = getPositionFromTimeRange({
        startDate,
        endDate,
        optionWidth,
        slotSize,
        minWidth,
        maxWidth,
        minSlot,
      });

      setPosition(position);
    }
  }, [value?.startDate?.getTime?.(), value?.endDate?.getTime?.(), optionWidth]);

  useEffect(() => {
    setSliderUpdated(Date.now());
  }, [value?.startDate?.getDate?.(), value?.endDate?.getDate?.()]);

  useEffect(() => {
    // use a window listener because the user may slide their mouse off
    // of this component as they resize.
    window.addEventListener('mousemove', onNubMove);
    window.addEventListener('mouseup', onNubMouseUp);
    window.addEventListener('touchmove', onNubMove);
    window.addEventListener('touchend', onNubMouseUp);

    return () => {
      window.removeEventListener('mousemove', onNubMove);
      window.removeEventListener('mousemove', onNubMouseUp);
      window.removeEventListener('touchmove', onNubMove);
      window.removeEventListener('touchend', onNubMouseUp);
    };
  }, []);

  useEffect(() => {
    if (!sliderInfo.isReady) {
      sliderInfo.isReady = true;

      return;
    }

    const lastStartDate = sliderInfo.startDate;
    const lastEndDate = sliderInfo.endDate;
    const minWidth = calcMinWidth(sliderInfo.optionWidth);

    // snap slider to slot sizes
    const snapPosition = {
      ...sliderInfo.position,
    };

    snapPosition.left = snapToGrid(minWidth, sliderInfo.position.left);
    snapPosition.width = snapToGrid(minWidth, sliderInfo.position.width);

    const { startMinutes, endMinutes } = getMinutesFromSlider({
      sliderPosition: snapPosition,
      slotSize,
      optionWidth,
      minSlot,
    });

    const startHours = Math.floor(startMinutes / 60);
    const endHours = Math.floor(endMinutes / 60);

    const startDate = sliderInfo.startDate.set({
      hour: startHours,
      minute: startMinutes - startHours * 60,
    });

    const endDate = sliderInfo.startDate.set({
      hour: endHours,
      minute: endMinutes - endHours * 60,
    });

    sliderInfo.startDate = startDate;
    sliderInfo.endDate = endDate;

    if (!sliderInfo.isDragging) {
      setPosition(snapPosition);
    }

    const invalid = isRangeUnavailable({
      start: startDate,
      end: endDate,
      maxTime,
      minTime,
      unavailableDateRanges: unavailableDateRanges ?? [],
    });

    setIsInvalid(invalid);

    // only fire onchange if the start and end date have actually changed
    if (
      startDate.toMillis() !== lastStartDate.toMillis() ||
      endDate.toMillis() !== lastEndDate.toMillis()
    ) {
      debouncedOnChange({
        startDate: startDate.toJSDate(),
        endDate: endDate.toJSDate(),
      });
    }
  }, [sliderUpdated, unavailableDateRanges]);

  useLayoutEffect(() => {
    const maxWidth = calcMaxWidth(optionWidth);
    const minWidth = calcMinWidth(optionWidth);

    const position = getPositionFromTimeRange({
      startDate: sliderInfo.startDate,
      endDate: sliderInfo.endDate,
      optionWidth,
      slotSize,
      minWidth,
      maxWidth,
      minSlot,
    });

    setPosition(position);
  }, [optionWidth]);

  useLayoutEffect(() => {
    // if no value has been provided, move the slider to the default
    // time set on mount

    if (!value?.startDate || !value?.endDate) {
      setTimeout(
        () =>
          onChange({
            startDate: sliderInfo.startDate.toJSDate(),
            endDate: sliderInfo.endDate.toJSDate(),
          }),
        1000
      );
    }
  }, [value?.startDate, value?.endDate]);

  function onNubMouseDown(e: React.TouchEvent | React.MouseEvent, side: Side) {
    // because we are using a window listener, regular state won't
    // work to keep track of this info.
    sliderInfo.isDragging = true;
    sliderInfo.lastPosition = { ...sliderInfo.position };

    const eventCoordinates =
      e.nativeEvent instanceof MouseEvent
        ? e.nativeEvent
        : e.nativeEvent.touches[0];

    if (eventCoordinates) {
      sliderInfo.lastX = eventCoordinates?.clientX;
    }

    sliderInfo.side = side;
    e.stopPropagation();
  }

  function onNubMouseUp() {
    sliderInfo.isDragging = false;
    setTimeout(() => setSliderUpdated(Date.now()), 0);
  }

  function onNubMove(e: MouseEvent | TouchEvent) {
    if (!sliderInfo.isDragging) {
      return;
    }

    const eventCoordinates = e instanceof MouseEvent ? e : e.touches[0];
    const { lastPosition, lastX, side, optionWidth } = sliderInfo;

    if (eventCoordinates) {
      const dx = eventCoordinates.clientX - lastX;
      const newPosition = {
        ...lastPosition,
      };

      if (side === 'right') {
        const maxWidth = calcMaxWidth(optionWidth);

        if (newPosition.width + dx <= maxWidth) {
          newPosition.width = lastPosition.width + dx;
        } else {
          newPosition.width = lastPosition.width + maxWidth;
        }
      } else if (side === 'left') {
        newPosition.left = lastPosition.left + dx;
        newPosition.width = lastPosition.width - dx;
      } else if (side === 'slider') {
        newPosition.left = lastPosition.left + dx;
      }

      setPosition(newPosition);
    }

    e.stopPropagation();
  }

  useEffect(() => {
    // get the width of an option box, we will need this to determine
    // position of the slider nubs, since all options are the same width
    // we can get it once and store it.
    if (!sliderWrapperRef.current) {
      return;
    }

    const { width: wrapperWidth } = getBoundingClientRect(
      sliderWrapperRef.current
    );
    const width = wrapperWidth / options.length;
    const minWidth = calcMinWidth(width);

    // round these guys, we only care when they change enough that it matters.
    // anything less than a tenth of a pixel does not matter
    if (Math.floor(width * 10) !== Math.floor(optionWidth * 10)) {
      // set the state to re-render the control
      setOptionWidth(width);
    }

    // set the slider to at least the minimum size. if its not already set.
    if (sliderInfo.position.width <= minWidth) {
      setPosition({
        ...sliderInfo.position,
        width: minWidth,
      });
    }

    // but also update our ref object, since callback functions
    // won't have access to the state value.
    sliderInfo.optionWidth = width;
  }, [optionWidth, sliderInfo, sliderWrapperRef, options.length]);

  const showSlider = minSlot !== maxSlot;
  const { user } = useContext(UserDataContext);
  const locale = findClosestLocale(user?.locale);

  return (
    <div
      data-test={testId}
      className={cx(styles.TimeRangePicker, className)}
      style={style}
      data-is-disabled={disabled}
      data-is-loading={loading}
    >
      {showTimeSlider && (
        <div className={styles.timeSlider}>
          <div className={styles.times}>
            {options.map(({ startTime }, i) => (
              <div
                key={startTime.toISO()}
                className={styles.time}
                style={{ width: `${uiSlotSize}em` }}
              >
                {i % Math.ceil(options.length / 8) === 0 && (
                  <span>{dateFormatter(startTime, 'h:mm a', timeZone)}</span>
                )}
              </div>
            ))}
          </div>
          <div
            className={styles.options}
            ref={sliderWrapperRef}
            data-test="sliderTimeSlots"
          >
            {options.map(({ slot, slotSize, unavailable }, ix) => (
              <div
                key={`${slot}_${slotSize}`}
                className={cx(styles.option, {
                  [styles.lastOption]: ix + 1 === options.length,
                })}
                style={{ width: `${uiSlotSize}em` }}
                data-is-unavailable={unavailable}
                data-test="optionSlot"
              />
            ))}

            {existingValuePosition && (
              <div
                className={cx(
                  styles.slider,
                  disabled && styles.existingValueDisabled
                )}
                style={existingValuePosition}
                data-is-existing="true"
              >
                <div className={styles.sliderInner} />
              </div>
            )}

            {showSlider && (
              <div
                className={styles.slider}
                style={sliderInfo.position}
                data-is-invalid={isInvalid}
                onMouseDown={e => onNubMouseDown(e, 'slider')}
                onTouchStart={e => onNubMouseDown(e, 'slider')}
                role="slider"
                tabIndex={0}
                aria-valuemin={0}
                aria-valuenow={0}
                aria-valuemax={options.length}
                data-test="slider"
              >
                <div className={styles.sliderInner}>
                  <div
                    className={cx(styles.nub, styles.nubLeft)}
                    onMouseDown={e => onNubMouseDown(e, 'left')}
                    onTouchStart={e => onNubMouseDown(e, 'left')}
                    role="slider"
                    data-test="sliderExpandLeft"
                    tabIndex={0}
                    aria-valuemin={0}
                    aria-valuenow={0}
                    aria-valuemax={options.length}
                  >
                    <span className={styles.doubleLine} />
                  </div>
                  <div
                    className={cx(styles.nub, styles.nubRight)}
                    onMouseDown={e => onNubMouseDown(e, 'right')}
                    onTouchStart={e => onNubMouseDown(e, 'right')}
                    role="slider"
                    data-test="sliderExpandRight"
                    tabIndex={0}
                    aria-valuemin={0}
                    aria-valuenow={0}
                    aria-valuemax={options.length}
                  >
                    <span className={styles.doubleLine} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {showDatePicker && (
        <DatePickerButton
          loading={loading}
          buttonClassName={styles.datePicker}
          onSubmit={changeDay}
          includeTime={false}
          minDate={minDate}
          maxDate={maxDate}
          unavailableDateRanges={unavailableDateRanges}
          timeZone={timeZone}
          onFocusChange={updateCalendarReferenceDate}
          value={sliderInfo.startDate?.toJSDate()}
          disabledWeekDays={disabledWeekDays}
          ButtonComponent={({ onOpen }: any) => (
            <div className={styles.summary}>
              <div className={styles.row} data-test="startTime">
                <M className={styles.rowTitle}>{t('Start')}</M>
                <button
                  data-test="datePicker"
                  className={styles.dateSelectButton}
                  onClick={e => onOpen(e)}
                >
                  {dateFormatter(
                    sliderInfo.startDate,
                    'EEE MMM d, yyyy',
                    timeZone,
                    locale
                  )}
                </button>
                <M>{dateFormatter(sliderInfo.startDate, 'h:mm a', timeZone)}</M>
              </div>
              <div className={styles.row} data-test="endTime">
                <M className={styles.rowTitle}>{t('End')}</M>
                <M>{dateFormatter(sliderInfo.endDate, 'h:mm a', timeZone)}</M>
              </div>
            </div>
          )}
        />
      )}
      <div className={styles.displayTime}>
        <S>
          {t('Duration')}
          {': '}
          <span className={styles.timeValue}>
            {getDisplayTime(
              getMinutesFromSlider({
                sliderPosition: sliderInfo.position,
                optionWidth,
                slotSize,
                minSlot,
              })
            )}
          </span>
        </S>
      </div>
    </div>
  );
}
