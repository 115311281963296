import React, { useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Radio, IconButton } from '@material-ui/core';

import { BRAND_LOGO, NO_LOGO } from 'lane-shared/helpers/constants/cards';
import { CreditCardType } from 'lane-shared/types/Stripe';

import { Loading } from 'components/general';
import { Typography } from 'components/lds';

import styles from './StripePaymentMethod.scss';

type Props = {
  paymentMethod: CreditCardType;
  onSelectPaymentMethod: (paymentMethod: CreditCardType) => void;
  onRemovePaymentMethod?: (
    paymentAccountId: string | undefined,
    paymentMethod: CreditCardType
  ) => Promise<void>;
  selectable?: boolean;
  selected?: boolean;
};

export default function StripePaymentMethod({
  paymentMethod,
  onSelectPaymentMethod,
  onRemovePaymentMethod,
  selectable,
  selected,
}: Props) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);

  async function handleRemoveCard(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.stopPropagation();
    e.preventDefault();

    if (onRemovePaymentMethod) {
      setDisabled(true);

      try {
        await onRemovePaymentMethod(
          paymentMethod.paymentAccountId,
          paymentMethod
        );
      } catch (err) {
        setDisabled(false);
      }
    }
  }

  // TODO: POD-142 This should be removed later and replaced
  // w/ a disabled state for the below component
  if (disabled) {
    return (
      <div
        className={cx(styles.stripePaymentMethod, disabled && styles.disabled)}
        role="button"
        tabIndex={0}
        onKeyPress={e =>
          e.key === Key.Enter &&
          selectable &&
          onSelectPaymentMethod(paymentMethod)
        }
        onClick={() => selectable && onSelectPaymentMethod(paymentMethod)}
      >
        <Loading />
      </div>
    );
  }

  return (
    <div
      className={cx(
        styles.stripePaymentMethod,
        selectable && styles.selectable
      )}
      role="button"
      tabIndex={0}
      onKeyPress={e =>
        e.key === Key.Enter &&
        selectable &&
        onSelectPaymentMethod(paymentMethod)
      }
      onClick={() => selectable && onSelectPaymentMethod(paymentMethod)}
    >
      {selectable && (
        <Radio
          color="primary"
          checked={selected}
          value={paymentMethod}
          name="stripe-card"
        />
      )}
      <img
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        src={BRAND_LOGO[paymentMethod.brand] || NO_LOGO}
        alt={paymentMethod.brand}
        className={cx(styles.brand)}
      />
      <div className={cx(styles.cardInfo)}>
        <Typography type="s" mode="secondary">
          {t('Ending: {{last4}}', { last4: paymentMethod.last4 })}
        </Typography>
        <Typography type="s" mode="secondary">
          {t('Exp: {{expMonth}}/{{expYear}}', {
            expMonth: paymentMethod.exp_month,
            expYear: paymentMethod.exp_year,
          })}
        </Typography>
      </div>
      {onRemovePaymentMethod && (
        <IconButton
          color="secondary"
          onClick={handleRemoveCard}
          className={cx(styles.removeCardButton)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      )}
    </div>
  );
}
