import commonConfig from './config.common';
import localConfig from './config.local';

const config = {
  ...commonConfig,
  ...localConfig,
  apiKey: {
    secret: 'test-api-secret',
  },
} as const;

export default config;
