import { useQuery } from '@apollo/client';
import { getChannelAddress } from 'lane-shared/graphql/query/getChannelAddress';
import { ChannelBillingDetailsType } from 'lane-shared/types/ChannelType';

export function useChannelBillingDetails(channelId: string | undefined) {
  const { data, loading, error } = useQuery<ChannelBillingDetailsType>(
    getChannelAddress,
    {
      variables: {
        channelId,
      },
    }
  );

  return {
    loading,
    error,
    channelBillingDetails: data,
  };
}
