import { gql } from 'graphql-query-contracts';

export const userStatusUpdateAuditsQuery = gql(/* GraphQL */ `
  query queryUserStatusUpdateAudits(
    $userId: UUID!
    $pagination: PaginationInput!
  ) {
    userStatusUpdateAudits(userId: $userId, pagination: $pagination) {
      items {
        id
        userId
        created
        actionType
        source
        sourceId
        newData
        oldData
        sourceUser {
          name
          id
        }
      }
      pageInfo {
        start
        perPage
        total
      }
    }
  }
`);
