import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { ErrorMessage } from 'components';
import getSsoLink from 'lane-shared/helpers/integrations/Bilt/getSsoLink';

import useIsAdminView from 'hooks/useIsAdminView';

export default function Bilt({ content }: any) {
  const history = useHistory();
  const [isAdminView] = useIsAdminView();
  const { data, error, loading } = useQuery(getSsoLink, {
    variables: { channelId: content.channel._id },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!isAdminView && !loading && data) {
      const url = data.biltSsoLink.ssoLink;

      window.open(url, '_blank');
      history.goBack();
    }
  }, [isAdminView, loading, data]);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return null;
}
