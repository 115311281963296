import React from 'react';

import { Control, Controller } from 'react-hook-form';

import { Input, InputProps } from 'design-system-web';
import { useTranslation } from 'react-i18next';

type Props = {
  control: Control<any>;
  errorMessage?: string[] | null;
  name: string;
  isRequired?: boolean;
  testId?: string;
  ariaLabel?: string;
  readOnly?: boolean;
  inputClassName?: string;
  label?: string;
  className?: string;
  minLength?: number;
  pattern?:
    | {
        value?: RegExp;
        message?: string;
      }
    | string
    | undefined;
} & Omit<InputProps, 'onChange' | 'value' | 'min' | 'max'>;

const RegexPattern = /(?!\s*$).+/;

export function HookFormInput({
  control,
  name,
  errorMessage,
  minLength,
  isRequired = false,
  pattern,
  ...inputProps
}: Props) {
  const { t } = useTranslation();

  const processedPattern =
    typeof pattern === 'string'
      ? { value: RegexPattern, message: '' }
      : {
          value: pattern?.value || RegexPattern,
          message:
            pattern?.message || t('web.admin.serviceRequest.field.invalid'),
        };

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: isRequired,
        minLength,
        pattern: processedPattern,
      }}
      render={({ field: { onChange, value, onBlur, ref } }) => (
        <Input
          {...inputProps}
          ref={ref}
          fieldName={name}
          onChange={onChange}
          value={value}
          error={errorMessage}
          onBlur={onBlur}
        />
      )}
    />
  );
}
