import React from 'react';
import { Dropdown } from 'design-system-web';
import { useCategoryListQuery } from 'pages/portal/admin/channel/products-services/helpers';
import { ProductCategory, ProductType } from 'graphql-query-contracts';
import { Item } from 'components/form/Dropdown/Dropdown';

export function ProductCategoryDropdown({
  productType = ProductType.ProductTypeUnknown,
  value,
  handleCategorySelection,
  disabled = false,
  label = '',
  placeholder = '',
  fixedLabel = false,
  className,
  isRequired = false,
  isFullWidth = false,
}: {
  value: any;
  handleCategorySelection: Function;
  productType?: ProductType;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  fixedLabel?: boolean;
  className?: string | string[];
  isRequired?: boolean;
  isFullWidth?: boolean;
}) {
  const { data: categoryList } = useCategoryListQuery({
    page: 0,
    pageSize: 100,
    productType,
  });

  function getCategoryItems(): Item<string>[] | undefined {
    const listOfCategories = categoryList?.listProductCategories
      .productCategory as ProductCategory[] | undefined;

    if (listOfCategories) {
      const dropdownCategoryItems = listOfCategories.map(productCategory => {
        return {
          label: productCategory.name,
          value: productCategory.productCategoryId,
        };
      });

      return dropdownCategoryItems;
    }

    return undefined;
  }

  const onCategorySelection = (item: Item<string>) => {
    const listOfCategories = categoryList?.listProductCategories
      .productCategory as ProductCategory[];
    const selectedCategory = listOfCategories.find(
      productCategory => productCategory.productCategoryId === item.value
    );

    if (selectedCategory) {
      handleCategorySelection(selectedCategory);
    }
  };

  return (
    <Dropdown
      testId="categoryDropdown"
      doTranslation={false}
      value={value}
      items={getCategoryItems()}
      onChange={onCategorySelection}
      label={label}
      placeholder={placeholder}
      fixedLabel={fixedLabel}
      disabled={disabled}
      className={className}
      isRequired={isRequired}
      isFullWidth={isFullWidth}
    />
  );
}
