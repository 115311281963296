import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { FONT_AWESOME_REGULAR } from 'lane-shared/helpers/constants/icons';
import { explodePresetContentFilters } from 'lane-shared/helpers/content';
import { PresetContentFilterLocation } from 'lane-shared/types/filters/PresetContentFilterLocation';
import { SearchOptions } from 'lane-shared/types/filters/SearchOptions';

import ChannelLocationsSelectorDropdown from 'components/lane/ChannelLocationsSelectorDropdown';
import ChannelSelectorDropdownLegacy from 'components/lane/ChannelSelectorDropdownLegacy';

import Input from '../form/Input';
import { updateSearchOptionsWithNewLocationFilter } from 'lane-shared/helpers/filters/searchBarLocationChangeHandlerHelper';

import styles from './SearchBar.scss';

type Props = {
  searchOptions: SearchOptions | null | undefined;
  hasChannelSelector?: boolean;
  hasChannelLocationsSelector?: boolean;
  allLocationsSelected?: boolean;
  selectedChannelId?: string;
  channels?: any[];
  onClear?: () => void;
  showAllLocations?: boolean;
  showFilters?: boolean;
  showSearch: boolean;
  searchType?: 'normal' | 'minimal';
  isFiltersEnabled?: boolean;
  className?: string;
  searchInputClassName?: string;
  searchInputPlaceholder?: string;
  onOpenFilters?: () => void;
  style?: React.CSSProperties;
  onSearchChange: (search: string) => void;
  onChannelSelected?: (channel: any) => void;
  onAllLocationsSelected?: () => void;
  onSearchOptionsUpdated?: (options: Partial<SearchOptions>) => void;
  iconType?: string;
  disabledSearch?: boolean;
  errors?: string[];
  interfaceStyle?: 'light' | 'dark';
};

/**
 * @deprecated
 *
 * This component is deprecated and should not be used in new code.
 */
export default function SearchBar({
  className,
  searchInputClassName,
  style,
  searchOptions,
  selectedChannelId,
  searchInputPlaceholder,
  hasChannelSelector = false,
  hasChannelLocationsSelector = false,
  channels,
  showAllLocations = false,
  showFilters = false,
  showSearch = false,
  searchType = 'normal',
  isFiltersEnabled = false,
  onOpenFilters,
  onSearchChange,
  onChannelSelected,
  onAllLocationsSelected,
  onSearchOptionsUpdated,
  iconType,
  disabledSearch,
  interfaceStyle,
  errors,
}: Props) {
  const { t } = useTranslation();
  const [animated, setAnimated] = useState(false);
  const { filterByChannelLocations } = explodePresetContentFilters(
    searchOptions?.filters
  );

  function locationsChangeHandler(filterToUpdate: PresetContentFilterLocation) {
    if (searchOptions && onSearchOptionsUpdated) {
      const updatedSearchOptionsFilters = updateSearchOptionsWithNewLocationFilter(
        searchOptions,
        filterToUpdate
      );

      onSearchOptionsUpdated({
        areFiltersApplied: true,
        filters: [...updatedSearchOptionsFilters],
      });
    }
  }

  return (
    <div
      className={cx(styles.SearchBar, className)}
      style={style}
      data-has-search={showSearch}
      data-has-filters={showFilters}
    >
      {hasChannelLocationsSelector && (
        <div className={styles.channelLocationsWrapper}>
          <ChannelLocationsSelectorDropdown
            className={styles.channelLocationsSelector}
            filter={filterByChannelLocations}
            channels={channels}
            onFilterUpdated={locationsChangeHandler}
            showAllLocations
          />
        </div>
      )}
      {hasChannelSelector && (
        <ChannelSelectorDropdownLegacy
          wrapperClassName={styles.channelSelector}
          channelId={selectedChannelId}
          channels={channels}
          showAllLocations={showAllLocations}
          onChannelSelected={onChannelSelected}
          onAllLocationsSelected={onAllLocationsSelected}
        />
      )}
      {showSearch && searchType === 'normal' && (
        <div className={styles.searchContainer}>
          <Input
            className={cx(styles.searchInput, searchInputClassName)}
            inputWrapperClassName={styles.searchInputWrapper}
            icon="search"
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            iconType={iconType}
            error={errors}
            hasError={Boolean(errors)}
            value={searchOptions?.search || ''}
            onChange={search => onSearchChange(search)}
            placeholder={searchInputPlaceholder || t('Search')}
            disabled={disabledSearch}
            showClear
          />
        </div>
      )}
      {showSearch && searchType === 'minimal' && (
        <div
          className={cx(styles.searchContainer)}
          onClick={() => !disabledSearch && setAnimated(true)}
          role="button"
          tabIndex={0}
        >
          <Input
            className={cx(
              styles.minimalSearch,
              interfaceStyle === 'dark' && styles.darkMode
            )}
            inputWrapperClassName={styles.searchInputWrapper}
            inputClassName={cx(styles.input, animated && styles.animation)}
            icon="search"
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            iconType={iconType}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'false | string[] | undefined' is not assigna... Remove this comment to see the full error message
            error={animated && errors}
            hasError={animated && Boolean(errors)}
            value={searchOptions?.search || ''}
            onChange={search => onSearchChange(search)}
            disabled={disabledSearch}
          />
        </div>
      )}
      {!hasChannelSelector && !hasChannelLocationsSelector && !showSearch && (
        <div className={styles.spacer} />
      )}
      {showFilters && (
        <button
          className={styles.showOptions}
          data-active={isFiltersEnabled}
          onClick={onOpenFilters}
        >
          <Icon
            className={styles.icon}
            set="FontAwesome"
            name="sliders-v"
            type={FONT_AWESOME_REGULAR}
          />
          <span>{t('Filters')}</span>
        </button>
      )}
    </div>
  );
}
