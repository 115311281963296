import React from 'react';

import { ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';

import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { PaymentDetailsType } from 'lane-shared/helpers/getPaymentDetailsWithQuote';
import {
  useCreditCards,
  useMyChannelsPaymentAccountsQuery,
  useMyPaymentAccountsQuery,
  useStoredState,
} from 'lane-shared/hooks';
import { ContentCategoryEnum } from 'lane-shared/types/content/ContentCategoryEnum';
import { PaymentAccountType } from 'lane-shared/types/payment/PaymentAccount';
import { PaymentProviderEnum } from 'lane-shared/types/payment/PaymentProviderEnum';

import Option from '../form/Option';
import Button from '../general/Button';
import CircleListView from '../lane/CircleListView';

import styles from './PaymentMethodSelect.scss';

type PaymentMethodSelectProps = {
  style: any;
  error?: Error;
  selectedCard?: any;
  selectedPaymentAccount?: PaymentAccountType;
  onCardSelected: (card: any) => void;
  onPaymentAccountSelected: (paymentAccount: PaymentAccountType | null) => void;
  onSetupPayment: () => void;
  disabled?: boolean;
  timeZone?: string;
  contentCategory?: ContentCategoryEnum;
  paymentDetails?: PaymentDetailsType;
};

export default function PaymentMethodSelect({
  error,
  selectedCard,
  selectedPaymentAccount,
  timeZone,
  contentCategory,
  paymentDetails,
  disabled = false,
  onCardSelected,
  onPaymentAccountSelected,
  onSetupPayment,
}: PaymentMethodSelectProps) {
  const { t } = useTranslation();
  const { myPaymentAccounts } = useMyPaymentAccountsQuery();
  const { myChannelsPaymentAccounts } = useMyChannelsPaymentAccountsQuery({
    timeZone,
    contentCategory,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | number' is not assignable to type '... Remove this comment to see the full error message
    purchasePrice: paymentDetails?.total || 0,
  });

  const stripeAccount = (myPaymentAccounts || []).find(
    paymentAccount => paymentAccount.type === PaymentProviderEnum.Stripe
  );

  const stripeAccountIds: string[] = [];

  if (stripeAccount?._id) {
    stripeAccountIds.push(stripeAccount._id);
  }

  const { cards, loading } = useCreditCards(stripeAccountIds);

  const [usePersonalAccount, setUsePersonalAccount] = useStoredState(
    'PaymentMethodSelectPersonal',
    true
  );

  function toggleAccounts(value: any) {
    setUsePersonalAccount(value);
    onCardSelected(null);
    onPaymentAccountSelected(null);
  }

  const hasChannelAccounts = myChannelsPaymentAccounts?.length > 0;

  return (
    <div className={styles.container}>
      <h1>{t('Paying with')}</h1>

      <ErrorMessage error={error} />

      {hasChannelAccounts && (
        <menu className={styles.paymentOptions}>
          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'hasIcon' is missing in type '{ children:... Remove this comment to see the full error message */}
          <Option
            selected={usePersonalAccount}
            onClick={() => toggleAccounts(true)}
          >
            <h2>{t('Personal Account')}</h2>
          </Option>

          <Option
            selected={!usePersonalAccount}
            onClick={() => toggleAccounts(false)}
            hasIcon
          >
            <h2>{t('Business Account')}</h2>
          </Option>
        </menu>
      )}

      {hasChannelAccounts && !usePersonalAccount && (
        <div className={styles.accounts}>
          {myChannelsPaymentAccounts.map(paymentAccount => (
            <Option
              key={paymentAccount._id}
              onClick={() => onPaymentAccountSelected(paymentAccount)}
              selected={paymentAccount._id === selectedPaymentAccount?._id}
              hasIcon={false}
            >
              <CircleListView
                image={imageUrl((paymentAccount.channel as any).profile.image)}
                logo={imageUrl((paymentAccount.channel as any).profile.logo)}
                className={styles.listView}
              >
                <h2>{paymentAccount.name}</h2>
                <p>{getDisplayName(paymentAccount.channel)}</p>
              </CircleListView>
            </Option>
          ))}
        </div>
      )}
      {(!hasChannelAccounts || usePersonalAccount) && (
        <>
          {!loading && cards.length === 0 && (
            <div className={styles.setupPayment}>
              <p>{t('You have no payment methods setup yet.')}</p>
              <Button
                disabled={disabled}
                className={styles.button}
                onClick={onSetupPayment}
              >
                {t('Setup payment')}
              </Button>
            </div>
          )}

          {cards.length > 0 && (
            <div className={styles.accounts}>
              {cards.map((card: any) => (
                <Option
                  key={card.id}
                  onClick={() => {
                    onCardSelected(card);
                    onPaymentAccountSelected(selectedCard);
                  }}
                  selected={card.id === selectedCard?.id}
                  hasIcon
                >
                  <h2>{card.brand}</h2>
                  <p>**** **** **** {card.last4}</p>
                </Option>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}
