import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { createShapeFromProperties } from 'lane-shared/properties';
import Types from 'lane-shared/properties/Types';
import {
  FeatureDefinition,
  ContentTypeRequirementEnum,
} from 'lane-shared/types/FeatureDefinition';
import { ObjectTypeBase } from 'lane-shared/types/baseTypes/ObjectTypeBase';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { CustomQuestionInput } from 'graphql-query-contracts';

export interface ServiceRequestType {
  location?: string;
  description?: string;
  floor?: string;
  suite?: string;
  category?: string;
  issue?: string;
  channel?: string;
  customQuestions?: CustomQuestionInput[];
  attachmentRefId?: string;
  equipment?: string[];
  assigneeGroups?: string[];
  assignee?: string;
  suiteIds?: string[];
}

export const workOrderFeatureDefinition: FeatureDefinition = {
  name: FeatureNameEnum.WorkOrder,
  description: 'Submit and view Service Requests',
  superUserOnly: false,
  friendlyName: 'Work Order',
  requiresInteraction: true,
  properties: {
    ServiceRequest: {
      friendlyName: 'Service request',
      description: 'Information about the service request',
      type: 'ServiceRequestType',
      editable: true,
      isArray: false,
      forDisplay: true,
      showControl: true,
    },
  },
  interactionData: {
    ServiceRequest: {
      friendlyName: 'Service request',
      description: 'Information about the service request',
      type: 'ServiceRequestType',
      editable: true,
      isArray: false,
      forDisplay: true,
      showControl: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  contentTypeRequirements: [
    {
      type: ContentTypeEnum.VisitorManagement,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.Static,
      requirement: ContentTypeRequirementEnum.Optional,
    },
    {
      type: ContentTypeEnum.Notice,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.Content,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.ScheduledContent,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.ScheduledNotice,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.Promotion,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.Perk,
      requirement: ContentTypeRequirementEnum.Forbidden,
    },
    {
      type: ContentTypeEnum.WorkOrder,
      requirement: ContentTypeRequirementEnum.Required,
    },
  ],
};

const properties: PropertiesInterface = {
  location: {
    name: 'Location',
    type: 'String',
    editable: true,
    default: '',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  floor: {
    name: 'Floor',
    type: 'String',
    editable: true,
    default: '',
    validators: [],
  },
  suite: {
    name: 'Suite',
    type: 'String',
    editable: true,
    default: '',
    validators: [],
  },

  category: {
    name: 'Category',
    type: 'String',
    editable: true,
    default: '',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  issue: {
    name: 'Issue',
    type: 'String',
    editable: true,
    default: '',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  description: {
    name: 'Description',
    type: 'String',
    editable: true,
    default: '',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class ServiceRequestObjectType extends ObjectTypeBase {
  name = 'ServiceRequestType';

  friendlyName = 'Information about the service request';

  properties = properties;

  contexts = [];

  get example(): ServiceRequestType {
    return {
      location: 'Location of a service request',
      description: 'Description of a service request',
      suite: '2',
      floor: '1',
      category: 'Category of a service request',
      issue: 'Issue of a service request',
      channel: uuid(),
    };
  }

  get default(): any {
    return {};
  }

  buildSchema() {
    const shape = createShapeFromProperties(properties);

    return yup.object().shape(shape).nullable();
  }
}

const serviceRequestType = new ServiceRequestObjectType();

Types.addType(serviceRequestType);
