import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';

export type GuestInviteGuestType = {
  _id: string;
  email?: string;
  name?: string;
  company?: string;
  user?: {
    _id: string;
  };
  type?: string;
};

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    editable: false,
    default: null,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  email: {
    type: 'Email',
    default: null,
    validators: [],
  },
  name: {
    type: 'String',
    default: '',
    validators: [],
  },
  company: {
    type: 'String',
    default: '',
    validators: [],
  },
  user: {
    type: 'User',
    default: null,
    validators: [],
  },
};

export class GuestInviteGuestTypeType extends ObjectTypeBase {
  name = 'GuestInviteGuestType';

  friendlyName =
    'web.content.feature.guestInvite.guestInviteGuestType.friendlyName';

  contexts = [];

  get example(): GuestInviteGuestType {
    return {
      _id: uuid(),
      email: 'lanedoe@joinlane.com',
      name: 'Lane Doe',
      company: 'Company Inc.',
    };
  }

  get default(): any {
    return {
      _id: uuid(),
    };
  }

  buildSchema() {
    const shape = createShapeFromProperties(properties);

    return yup.object().shape(shape).nullable();
  }

  properties = properties;
}

const definition = new GuestInviteGuestTypeType();

Types.addType(definition);
export default definition;
