import { useEffect, useMemo, useState } from 'react';

import gql from 'graphql-tag';
import { useDebounce } from 'use-debounce';

import { cloneDeep } from '@apollo/client/utilities';

import { getClient } from '../apollo';
import {
  mediaOnChannelQuery,
  mediaOnLibraryQuery,
  mediaOnUserQuery,
} from '../graphql/media';
import { LibraryType, LibraryTypeEnum } from '../types/libraries';
import {
  MediaAudioContentTypeEnum,
  MediaDocumentContentTypeEnum,
  MediaImageContentTypeEnum,
  MediaTypeEnum,
  MediaVideoContentTypeEnum,
} from '../types/media';
import useLibraryPattern from './useLibraryPattern';
import useStoredState from './useStoredState';

const DEBOUNCE_THROTTLE = 500;

const libraryInfoQueries = {
  Channel: gql`
    query libraryChannelInfo($channelId: UUID!) {
      channel(_id: $channelId) {
        _id
        libraryTags
        libraryPaths
      }
    }
  `,
  User: gql`
    query libraryUserInfo {
      me {
        user {
          _id
          libraryTags
          libraryPaths
        }
      }
    }
  `,
  Library: gql`
    query libraryInfo($libraryId: UUID!) {
      library(_id: $libraryId) {
        _id
        tags
        paths
      }
    }
  `,
};

export const SORT_BY = [
  { label: 'Created', value: '_created' },
  { label: 'Updated', value: '_updated' },
  { label: 'Name', value: 'name' },
];

export type MediaImageFileType = {
  name?: string;
  size: number;
  type: MediaImageContentTypeEnum;
  width?: number;
  height?: number;
};

export type MediaDocumentFileType = {
  name?: string;
  size: number;
  type: MediaDocumentContentTypeEnum;
};

export type LibraryItemType = {
  _id: string;
  _updated: Date;
  tags: string[];
  path?: string;
  media: MediaType;
};

type MediaBaseType = {
  _id: string;
  _created: string;
  _updated: string;
  name: string;
  description: string;
  type: MediaTypeEnum;
  inUse: boolean;
};

export type MediaImageType = MediaBaseType & {
  file: MediaImageFileType;
  thumbnail: MediaImageFileType;
  contentType: MediaImageContentTypeEnum;
};

export type MediaDocumentType = MediaBaseType & {
  file: MediaDocumentFileType;
  previewUrl?: string;
  thumbnail: {};
  contentType: MediaDocumentContentTypeEnum;
};

export type MediaType = {
  contentType:
    | MediaImageContentTypeEnum
    | MediaVideoContentTypeEnum
    | MediaAudioContentTypeEnum
    | MediaDocumentContentTypeEnum;
} & (MediaDocumentType | MediaImageType);

export type MediaWithCreatedByInfo = MediaDocumentType & {
  _created: Date;
  _createdBy: { _id: string };
  _updated: Date;
  _updatedBy: { _id: string };
};

export const sortOrders = ['asc', 'desc'] as const;
export const [SORT_ASC, SORT_DESC] = sortOrders;

const queryTypes = {
  User: {
    query: mediaOnUserQuery,
    name: 'mediaOnUser',
  },
  Channel: {
    query: mediaOnChannelQuery,
    name: 'mediaOnChannel',
  },
  Library: {
    query: mediaOnLibraryQuery,
    name: 'mediaOnLibrary',
  },
};

type SearchType = {
  search: string;
  page: number;
  sortBy: string;
  sortOrder: string;
  perPage: number;
  tags: string[];
  path: string | null;
};

const EMPTY_ARRAY = Object.freeze([]);

export default function useMediaLibrary({
  libraries,
  library,
  withPagination = true,
  storageKey,
  mediaTypes = [MediaTypeEnum.Image], // setting a default to ensure no changes to existing functionality
}: {
  libraries?: LibraryType[] | null;
  library: LibraryType | null;
  withPagination?: boolean;
  storageKey?: string;
  mediaTypes?: MediaTypeEnum[];
}) {
  const {
    user,
    selectedLibrary,
    setSelectedLibrary,
    availableLibraries,
  } = useLibraryPattern({
    libraries,
    library,
    userLibraryEnabled: true,
    storageKey: `useMediaLibrary${storageKey}`,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [items, setItems] = useState<LibraryItemType[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [paths, setPaths] = useState<string[]>([]);

  const [pageInfo, setPageInfo] = useState({ total: 0, page: 0 });

  const [search, setSearch, isReady] = useStoredState<SearchType>(
    `useMediaLibrary${storageKey}`,
    {
      search: '',
      page: 0,
      sortBy: SORT_BY[0].value,
      sortOrder: SORT_DESC,
      perPage: 25,
      tags: [],
      path: '',
    },
    {
      disableStorage: true,
    }
  );

  function updateSearch(update: Partial<SearchType>) {
    const resetPage: Partial<SearchType> = {};

    if (update.search || update.tags || update.path) {
      resetPage.page = 0;
    }

    if (update.path) {
      // if the path changed, clear the items and the page info
      setItems([]);
      setPageInfo({ total: 0, page: 0 });
    }

    setSearch(prevState => ({
      ...prevState,
      ...update,
      ...resetPage,
    }));
  }

  const [debouncedSearch] = useDebounce(search, DEBOUNCE_THROTTLE);

  function onChangeLibrary(value: any) {
    const newLibrary = availableLibraries.find(
      library => library._id === value
    );

    updateSearch({
      tags: [],
      path: '',
      page: 0,
    });

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'LibraryType | undefined' is not ... Remove this comment to see the full error message
    setSelectedLibrary(newLibrary);
  }

  function fetchMore() {
    if (!loading && pageInfo.total > search.page * search.perPage) {
      updateSearch({
        page: search.page + 1,
      });
    }
  }

  async function fetchMedias(reset: any) {
    if (loading) {
      return;
    }

    const variables: any = {
      pagination: {
        start: search.page * search.perPage,
        perPage: search.perPage,
      },
      search: {
        sortBy: { key: search.sortBy, dir: search.sortOrder },
      },
    };

    if (search.search) {
      variables.search.name = { type: 'like', value: search.search };
    }

    if (search.tags) {
      // StringListSearchType
      variables.tags = {
        any: search.tags,
      };
    }

    if (mediaTypes?.length) {
      variables.mediaTypes = mediaTypes;
    }

    if (search.path) {
      variables.path = search.path;
    }

    const queryInfo = queryTypes[selectedLibrary?.type || 'Channel'];

    switch (selectedLibrary!.type) {
      case 'Library':
        variables.libraryId = selectedLibrary!._id;
        break;
      case 'Channel':
        variables.channelId = selectedLibrary!._id;
        break;
      case 'User':
        variables.userId = selectedLibrary!._id;
        break;
      default:
        variables.userId = user!._id;
        break;
    }

    setLoading(true);
    setError(null);

    try {
      const { data } = await getClient().query({
        query: queryInfo.query,
        fetchPolicy: 'network-only',
        variables,
      });

      const newMedias = data?.[queryInfo.name]?.items || EMPTY_ARRAY;

      if (reset) {
        setItems(newMedias);
      } else {
        setItems(medias => [...medias, ...newMedias]);
      }

      setPageInfo(
        data?.[queryInfo.name]?.pageInfo || {
          total: 0,
          page: 0,
        }
      );
    } catch (err) {
      setError(error);
    }

    setLoading(false);
  }

  async function refetchMedia() {
    updateSearch({
      page: 0,
      search: '',
    });
  }

  async function getLibraryInfo() {
    let paths;
    let tags;

    try {
      switch (selectedLibrary?.type) {
        case LibraryTypeEnum.Library: {
          const { data } = await getClient().query({
            query: libraryInfoQueries.Library,
            fetchPolicy: 'network-only',
            variables: { libraryId: selectedLibrary._id },
          });

          tags = data.library.tags;
          paths = data.library.paths;
          break;
        }

        case LibraryTypeEnum.User: {
          const { data } = await getClient().query({
            query: libraryInfoQueries.User,
            fetchPolicy: 'network-only',
          });

          paths = data.me.user.libraryPaths;
          tags = data.me.user.libraryTags;
          break;
        }

        case LibraryTypeEnum.Channel: {
          const { data } = await getClient().query({
            query: libraryInfoQueries.Channel,
            fetchPolicy: 'network-only',
            variables: { channelId: selectedLibrary._id },
          });

          paths = data.channel.libraryPaths;
          tags = data.channel.libraryTags;
          break;
        }

        default:
        // no default
      }
    } catch (err) {
      // error here is ok, just ignore
    }

    setPaths(cloneDeep(paths) || EMPTY_ARRAY);
    setTags(cloneDeep(tags) || EMPTY_ARRAY);
  }

  useEffect(() => {
    if (selectedLibrary?._id) {
      getLibraryInfo();
    }
  }, [selectedLibrary?._id]);

  useEffect(() => {
    if (selectedLibrary?._id && !loading && isReady) {
      fetchMedias(withPagination);
    }
  }, [
    selectedLibrary?._id,
    selectedLibrary?.type,
    user?._id,
    isReady,
    debouncedSearch,
  ]);

  // what are the currently available paths given the current selected path
  const availablePaths = useMemo<string[]>(
    () =>
      paths.filter(path => {
        // selected path should be filtered out
        if (path === search.path) {
          return false;
        }

        // if a path is set, then available paths would start with this one
        // and have only one more . than this one
        if (search.path) {
          const currentDepth = search.path.match(/\./g)?.length || 0;

          return (
            path.startsWith(search.path) &&
            path.match(/\./g)?.length === currentDepth + 1
          );
        }

        // or if there is no selected path, return just the top level ones
        return !path.includes('.');
      }),
    [search.path, paths]
  );

  return {
    items,
    tags,
    paths,
    availablePaths,
    pageInfo,
    loading,
    error,
    search,
    updateSearch,
    onChangeLibrary,
    selectedLibrary,
    setSelectedLibrary,
    availableLibraries,
    refetchMedia,
    fetchMore,
    getLibraryInfo,
  };
}
