import { isEqual } from 'lodash';

import getChangedFilters from 'lane-shared/helpers/content/getChangedFilters';
import { SearchOptions } from 'lane-shared/types/filters/SearchOptions';

import { filtersOnPills } from 'components/integrations/FloorMaps/constants';

export default function getNumberOfAdditionalFiltersChanged(
  searchOptions: SearchOptions | null | undefined,
  initialSearchOptions: SearchOptions | null | undefined
) {
  if (!searchOptions || !initialSearchOptions) {
    return 0;
  }

  let count = 0;

  const changedFilters = getChangedFilters(searchOptions, initialSearchOptions);

  count += changedFilters.filter(filter => !filtersOnPills.includes(filter))
    .length;

  searchOptions.metatagFilters.forEach(metatagFilter => {
    const initialMetatagFilter = initialSearchOptions.metatagFilters.find(
      f => f.metatagId === metatagFilter.metatagId
    );

    if (
      !initialMetatagFilter || metatagFilter.metatagType === 'Simple'
        ? !isEqual(metatagFilter.value, initialMetatagFilter?.value)
        : !isEqual(metatagFilter.values!, initialMetatagFilter.values!)
    ) {
      count++;
    }
  });

  return count;
}
