import React, { useState } from 'react';

import { Button, ControlMenu } from 'components';
import { H3 } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import {
  queryGetVMContent,
  queryContentOnChannel,
} from 'lane-shared/graphql/channel';
import useChannelTheme from 'lane-shared/hooks/useChannelTheme';
import { ThemeType } from 'lane-shared/types/Theme';
import { ContentType } from 'lane-shared/types/content/Content';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { VisitorFormsPickerModal } from 'lane-web/src/domains/visitorManagement/pages/visitor-forms/VisitorFormsPickerModal/VisitorFormsPickerModal';
import { useQueryString } from 'lane-web/src/hooks';

import ModalBackground from 'components/general/ModalBackground';
import TabStrip from 'components/general/TabStrip';
import { AdminPage } from 'components/layout';
import { VisitorManagementUrlSearchParams } from 'components/lds/table/types';

import { PastVisitsComponent } from './tabs/past-visits';
import { VisitorScheduledComponent } from './tabs/scheduled';
import styles from './styles.scss';
import { UserType } from 'lane-shared/types/User';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { VisitCreatePanel } from '../../components/VisitCreatePanel/VisitCreatePanel';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

const defaultTab = 'scheduled';

const TabsMap = Object.freeze({
  scheduled: {
    label: 'web.admin.channel.visitor.log.tab.scheduled.visits',
    value: 'scheduled',
    Component: VisitorScheduledComponent,
  },
  pastVisits: {
    // FIXME: translation already done in TabStrip component
    // sending translated label here is then again looked for key in translation again - packages/localization/locales/en-US/translation.json
    label: 'web.admin.channel.visitor.log.tab.past.visits',
    value: 'pastVisits',
    Component: PastVisitsComponent,
  },
});

const getTabComponent = (
  selectedTab: keyof typeof TabsMap
): (({
  user,
  channel,
  searchParams,
  updateSearchParams,
}: {
  user: UserType;
  channel: ChannelType;
  searchParams: VisitorManagementUrlSearchParams;
  updateSearchParams: (prop: any) => void;
}) => JSX.Element | null) =>
  selectedTab ? TabsMap[selectedTab].Component : TabsMap[defaultTab].Component;

export function VisitorLogPage({
  user,
  channel,
}: {
  user: UserType;
  channel: ChannelType;
}) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const securityStaffViewFlagEnabled = useFlag(
    FeatureFlag.VisitorManagementSecurityStaffView,
    false
  );
  const { data: content } = useQuery(
    securityStaffViewFlagEnabled ? queryGetVMContent : queryContentOnChannel,
    {
      variables: {
        channelId: channel?._id,
        search: {
          type: {
            any: ContentTypeEnum.VisitorManagement,
          },
        },
      },
    }
  );

  const history = useHistory();
  const theme: ThemeType = useChannelTheme(channel);
  const visitorForms: ContentType[] | undefined = securityStaffViewFlagEnabled
    ? content?.getVMContent?.items
    : content?.contentOnChannel?.items;
  const [query, goToUrl] = useQueryString<
    VisitorManagementUrlSearchParams<keyof typeof TabsMap>
  >({
    tab: defaultTab,
  });

  const CurrentTab = getTabComponent(query.tab);

  function onCloseModal() {
    setShowModal(false);
  }

  function onOpenForm(content?: ContentType) {
    if (content?._id) {
      history.push(
        routes.channelAdminContent
          .replace(':id', channel.slug)
          .replace(':contentId', content._id)
      );
    }
  }

  function onCreateNewVisit() {
    if (content?.contentOnChannel?.items?.length && channel) {
      if (content?.contentOnChannel?.items?.length === 1) {
        const lastestContent =
          content?.contentOnChannel?.items[
            content?.contentOnChannel?.items?.length - 1
          ];

        onOpenForm(lastestContent);
      } else {
        setShowModal(true);
      }
    } else {
      history.push(
        `/l/channel/${channel?.slug}/admin/visitor-management/forms`
      );
    }
  }

  const onPanelClose = () => {
    setIsPanelOpen(false);
  };

  return (
    <AdminPage>
      <div className={styles.visitorTitleContainer}>
        <H3 as="h1">{t('web.admin.channel.visitor.log.title')}</H3>
        <Button
          color="inherit"
          variant="contained"
          testId="createNewVisit"
          onClick={
            securityStaffViewFlagEnabled
              ? () => setIsPanelOpen(true)
              : onCreateNewVisit
          }
        >
          {t('web.admin.channel.visitor.log.create.new.visit.button')}
        </Button>
      </div>

      {!securityStaffViewFlagEnabled && showModal && (
        <ModalBackground
          onClose={() => {}}
          isOpen={Boolean(showModal)}
          className={styles.background}
          hasOpaqueBackground
        >
          <VisitorFormsPickerModal
            onClose={onCloseModal}
            visitorForms={visitorForms!}
            theme={theme}
            onOpenForm={onOpenForm}
          />
        </ModalBackground>
      )}
      <ControlMenu>
        <TabStrip
          tabs={Object.values(TabsMap)}
          selected={{ value: query.tab }}
          onSelectTab={tab => goToUrl({ tab: tab.value })}
          className={styles.visitorTabs}
        />
      </ControlMenu>
      <CurrentTab
        user={user}
        channel={channel}
        searchParams={query}
        updateSearchParams={goToUrl}
      />
      <VisitCreatePanel
        isPanelOpen={isPanelOpen}
        onPanelClose={onPanelClose}
        visitorForms={visitorForms!}
      />
    </AdminPage>
  );
}
