export function getContentMetatagsWithUpdatedComplexValues({
  values,
  contentMetatag,
  content,
}: any) {
  // updating a complex metatag can involve pushing or pulling values
  // from the values array.

  const removedMetatagValues: any[] = [];
  const remainingMetatagIds: any[] = [];
  const remainingMetatagValues: any[] = [];

  contentMetatag.values?.forEach((value: any) => {
    if (!value._id || !value.metatagValue._id) return;

    const metatagValueId = value.metatagValue._id;

    if (values.includes(metatagValueId)) {
      remainingMetatagIds.push(metatagValueId);
      remainingMetatagValues.push({
        _id: value._id,
        metatagValue: {
          _id: value.metatagValue._id,
        },
      });
    } else {
      removedMetatagValues.push({
        _id: value._id,
        _pull: true,
        metatagValue: {
          _id: value.metatagValue._id,
        },
      });
    }
  });

  const newMetatagValues = values
    .filter((id: any) => !remainingMetatagIds.includes(id))
    .map((_id: any) => ({
      metatagValue: {
        _id,
      },
    }));

  const update = [
    ...removedMetatagValues,
    ...remainingMetatagValues,
    ...newMetatagValues,
  ];

  return (content.contentMetatags || []).map(function (
    previousContentMetatag: any
  ) {
    if (contentMetatag.metatag._id === previousContentMetatag.metatag._id) {
      return {
        ...contentMetatag,
        values: update,
      };
    }

    return previousContentMetatag;
  });
}
