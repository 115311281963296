import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { PropertyType } from 'lane-shared/types/properties/Property';

import Label from '../../../general/Label';
import ValidatorEdit from './ValidatorEdit';

import styles from './FieldValidators.scss';

type Props = {
  definition: any; // TODO: fix type
  field: PropertyType;
  onFieldUpdated?: (arg0: any) => void;
};

export default function FieldValidators({
  definition,
  field,
  onFieldUpdated = () => {},
}: Props) {
  const { t } = useTranslation();

  function addValidator() {
    onFieldUpdated({
      validators: [
        ...(field.validators || []),
        {
          name: 'Required',
          value: true,
        },
      ],
    });
  }

  return (
    <section className={styles.FieldValidators}>
      <Label h1>
        {t('web.admin.content.editor.fieldValidators.label')}
        <Icon
          name="plus-circle"
          set={ICON_SET_FONTAWESOME}
          className={styles.addIcon}
          onClick={addValidator}
          testId="add-validator"
        />
      </Label>

      <ul>
        {field.validators?.map((validator, i) => (
          <ValidatorEdit
            key={validator.name + i}
            validator={validator}
            definition={definition}
            field={field}
            onUpdate={newValidator => {
              if (field.validators) {
                field.validators[i] = newValidator;

                onFieldUpdated({
                  validators: [...field.validators],
                });
              }
            }}
            onRemove={() => {
              onFieldUpdated({
                validators: field.validators?.filter(v => v !== validator),
              });
            }}
          />
        ))}
      </ul>
    </section>
  );
}
