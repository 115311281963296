import React, { useContext } from 'react';

import cx from 'classnames';
import { Key } from 'ts-key-enum';

import ThemeContext from 'lane-shared/contexts/ThemeContext';

import styles from './Typography.scss';

/**
 * This file is included for backwards compatibility only.
 *
 * @deprecated
 */

enum Mode {
  primary = 'primary',
  secondary = 'secondary',
  disabled = 'disabled',
}

type BaseProps = {
  className?: string;
  children: React.ReactNode;
  type: 'h4' | 'h5' | 'h6' | 'm' | 's' | 'xs';
  mode: 'primary' | 'secondary' | 'disabled';
  color?: string;
  onClick?: (...args: any[]) => void;
};

type Props = {
  display?: 'block' | 'inline';
} & BaseProps;

function BlockElement({ children, className, type, mode, onClick }: BaseProps) {
  return (
    <div
      className={cx(styles.typography, styles[type], styles[mode], className)}
      role="button"
      tabIndex={0}
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onKeyPress={e => e.key === Key.Enter && onClick(e)}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

function InlineElement({
  children,
  className,
  type,
  mode,
  onClick,
}: BaseProps) {
  return (
    <span
      className={cx(styles.typography, styles[type], styles[mode], className)}
      role="button"
      tabIndex={0}
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onKeyPress={e => e.key === Key.Enter && onClick(e)}
      onClick={onClick}
    >
      {children}
    </span>
  );
}

const contentElementMap: Record<string, (...args: any) => React.ReactNode> = {
  block: BlockElement,
  inline: InlineElement,
};

export default function Typography(props: Props) {
  const { text } = useContext(ThemeContext);
  const { display, mode, onClick } = props;

  function handleClick(e: any) {
    if (onClick && mode !== Mode.disabled) {
      onClick(e);
    }
  }

  const ContentElement = contentElementMap[display || 'block'];

  return <ContentElement {...props} color={text} onClick={handleClick} />;
}
