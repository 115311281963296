import { DateTime } from 'luxon';

import { LONG_TIME } from '../constants/dates';

/**
 * Give a string of type Time (see packages/lane-shared/properties/baseTypes/Time.js)
 * it will return the total number of minutes from the start of a day.
 *
 * Note this does not account for time zones, or even what the day is today.
 * It simply takes in 12:30 pm and returns 12 * 60 + 30;
 */
export default function parseMinutesFromTime(
  //  time the string to parse like "12:30 pm"
  time: string,
  // format the format to uses
  format: string = LONG_TIME,
  // the time zone this is in
  timeZone?: string
): number {
  const date: DateTime = DateTime.fromFormat(time, format, { zone: timeZone });

  return date.hour * 60 + date.minute;
}
