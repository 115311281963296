import { gql } from '@apollo/client';

export const updatePaymentMethod = gql`
  mutation updatePaymentMethod(
    $updatePaymentMethodRequest: UpdatePaymentMethodRequest
  ) {
    accounts {
      updatePaymentMethod(
        updatePaymentMethodRequest: $updatePaymentMethodRequest
      ) {
        paymentProcessorSourceId
      }
    }
  }
`;
