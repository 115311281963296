import * as yup from 'yup';

import i18n from 'localization';

import slugify from '../../helpers/formatters/slugify';
import { ContentCategoryEnum } from '../../types/content/ContentCategoryEnum';
import { CONTENT_TYPES_WITH_DATE } from '../../types/content/ContentTypeEnum';

/**
 * Everything that should be included in the info section of creating content.
 */

const TRANSLATION_KEYS = {
  minChars: 'shared.validation.validateContentInfo.minChars',
};

export default yup.object().shape({
  tags: yup
    .array()
    .of(yup.string())
    .max(6, i18n.t(`Maximum of {{ count }} tags is allowed`, { count: 6 })),
  name: yup
    .string()
    .trim()
    .test(
      'min-length',
      // https://stackoverflow.com/questions/49389202/yup-doesnt-work-properly-with-i18n
      // translation using i18n with variables (e.g. {{ count }} ) within yup is broken, making it static for now:
      i18n.t(TRANSLATION_KEYS.minChars),
      function (value) {
        const min = 3;

        if (!value) return true; // Skip validation if field is empty

        return value.length >= min;
      }
    )
    .max(75)
    .required(
      i18n.t(`This field is required. Please input a title here to continue.`)
    ),
  description: yup
    .string()
    .trim()
    .test('min-length', i18n.t(TRANSLATION_KEYS.minChars), function (value) {
      const min = 3;

      if (!value) return true; // Skip validation if field is empty

      return value.length >= min;
    })
    .max(255)
    .required(
      i18n.t(
        'This field is required. Please input a description here to continue.'
      )
    ),
  subtitle: yup.string().max(34).nullable().notRequired(),
  category: yup.string().nullable().oneOf(Object.values(ContentCategoryEnum)),
  reportingGroup: yup
    .string()
    .nullable()
    .oneOf(Object.values(ContentCategoryEnum)),
  slug: yup
    .string()
    .trim()
    .nullable()
    .test('is-slug', '${path} is not a valid slug', value =>
      value ? value === slugify(value) : true
    ),
  type: yup.string().trim().required(),
  channel: yup
    .object()
    .shape({
      __typename: yup.string().strip(true),
      _id: yup.string().required(),
    })
    .required(),
  card: yup
    .object()
    .shape({
      __typename: yup.string().strip(true),
      _id: yup
        .string()
        .typeError(i18n.t('a card type is required.'))
        .required(i18n.t('a card type is required.')),
    })
    .required(i18n.t('a card type is required.')),
  color: yup.string().required(),
  backgroundColor: yup.string().required(),
  isEvent: yup.boolean(),
  startDate: yup
    .date()
    .nullable()
    .when(['isEvent', 'type'], {
      is: (isEvent, type) => isEvent && CONTENT_TYPES_WITH_DATE.includes(type),
      then: yup.date().required(i18n.t('This field is required.')),
      otherwise: yup.date(),
    }),
  endDate: yup
    .date()
    .nullable()
    .min(yup.ref('startDate'), i18n.t('End date cannot be before start date.'))
    .when(['isEvent', 'type'], {
      is: (isEvent, type) => isEvent && CONTENT_TYPES_WITH_DATE.includes(type),
      then: yup.date().required(i18n.t('This field is required.')),
      otherwise: yup.date(),
    }),
});
