/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useRef } from 'react';

import { Icon, InputWrapper, M, Loading, Checkbox } from 'design-system-web';
import cx from 'classnames';
import { isEqual } from 'lodash';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Select, { components } from 'react-select';
import { v4 as uuid } from 'uuid';

import { FONT_AWESOME_REGULAR } from 'lane-shared/helpers/constants/icons';

import ValidationMessage from 'design-system-web/components/ValidationMessage/ValidationMessage';
import {
  getStyles,
  primaryColors,
  darkModeColors,
} from 'design-system-web/components/Dropdown/colors';
import styles from './ChannelDropdown.scss';

type Color = 'primary' | 'secondary' | 'invisible';

export type DropdownItem<T> = {
  label: string;
  value: T;
  isDisabled?: boolean;
};

export type BaseProps<T> = {
  /** optional if you want to add an identification to be returned in the onChange() */
  name?: string;
  /** dropdown theme */
  color?: Color;
  /** dropdown items */
  items?: DropdownItem<T>[];
  initialTouched?: boolean;
  label?: string;
  className?: string | string[];
  icon?: string;
  invalid?: boolean;
  disabled?: boolean;
  errors?: string[] | null;
  success?: boolean;
  error?: boolean;
  id?: string;
  interfaceStyle?: 'dark' | 'light';
  isFullWidth?: boolean;
  menuPosition?: 'absolute' | 'fixed';
  testId?: string;
  ariaLabel?: string;
  closeMenuOnSelect?: boolean;
  isClearable?: boolean;
  Menu?: React.FC<any>;
  onChannelRemoved: (channelId: string) => void;
  onClear: () => void;
  isMenuOpen?: boolean;
  onMenuOpen: () => void;
  onMenuClose: () => void;
};

type DropdownProps<T> = BaseProps<T> & {
  isMulti?: false;
  value?: T | null; // value should expect this type
  onChange?: (item: DropdownItem<T>) => void;
  onValueChange?: (value: T) => void;
};

type MultiDropdownProps<T> = BaseProps<T> & {
  isMulti?: true;
  value?: DropdownItem<T>[] | null;
  onChange?: (item: DropdownItem<T>[]) => void;
  onValueChange?: (value: T[]) => void;
};

export type Props<T> = DropdownProps<T> | MultiDropdownProps<T>;

const isMultiDropdown = <T extends unknown>(
  props: Props<T>
): props is MultiDropdownProps<T> => !!props.isMulti;

BaseDropdown.defaultProps = {
  name: `Dropdown-${uuid()}`,
  color: 'primary',
  onKeyPress: () => null,
  onChange: () => null,
  onValueChange: () => null,
  initialTouched: false,
  value: null,
  errors: null,
  success: false,
  error: false,
  id: 'dropdown',
  interfaceStyle: 'light',
  isFullWidth: false,
  menuPosition: 'fixed',
  testId: 'dropdown',
};

export function BaseDropdown<T>(props: Props<T>) {
  const {
    items = [],
    name,
    color,
    value,
    disabled,
    errors,
    success,
    invalid,
    initialTouched,
    label,
    icon,
    interfaceStyle,
    id,
    isFullWidth,
    menuPosition,
    testId,
    ariaLabel,
    isMulti = false,
    closeMenuOnSelect = true,
    isClearable,
    isMenuOpen,
    onChannelRemoved,
    onClear,
    onMenuClose,
    onMenuOpen,
  } = props;
  const [isTouched, setIsTouched] = React.useState(initialTouched);

  const dropdownStyles = useMemo(() => {
    return getStyles({
      colors: interfaceStyle === 'light' ? primaryColors : darkModeColors,
      success,
      invalid,
      disabled,
      icon: !!icon,
    });
  }, [color, success, errors, value, disabled]);

  const options = useMemo(
    () =>
      items.map(item => {
        if (item === undefined) {
          return;
        }

        return {
          label: item.label,
          value: item.value,
        };
      }),
    [items]
  );

  const { Option } = components;

  function IconOption(props: any) {
    return (
      <Option {...props}>
        {icon ? <Icon set="FontAwesome" name={icon} /> : null}
        {props?.data?.label}
      </Option>
    );
  }

  function CustomIndicatorError() {
    return (
      <div className={styles.CustomIndicatorError}>
        <Icon set="FontAwesome" name="exclamation-circle" />
      </div>
    );
  }

  function CustomIndicator() {
    return (
      <div className={cx(styles.CustomIndicator)}>
        <Icon
          set="FontAwesome"
          name="angle-down"
          type={FONT_AWESOME_REGULAR}
          testId="angle-down"
        />
      </div>
    );
  }

  function CustomMultiValueContainer({
    children,
    innerProps,
  }: components.MultiValueContainerProps) {
    return (
      <span {...innerProps} className={styles.MultiValueContainer}>
        {children}
      </span>
    );
  }

  function CustomMultiOption(props: components.OptionProps) {
    return (
      !props.data?.isDisabled && (
        <M
          ref={props.innerRef}
          {...props.innerProps}
          style={props.getStyles('option', props)}
        >
          <Checkbox
            style={dropdownStyles.optionCheckboxContainer()}
            selected={props.isSelected}
            value={props.getValue()}
            onChange={() => props.selectOption(props.data)}
          />
          {props.children}
        </M>
      )
    );
  }

  function CustomMultiValueRemove(props: components.MultiValueRemoveProps) {
    return (
      <div
        style={{ backgroundColor: 'none' }}
        {...props.innerProps}
        className=""
      >
        <Icon
          name="times"
          onClick={() => {
            onChannelRemoved(props.data.value._id);
          }}
        />
      </div>
    );
  }

  function CustomClearIndicator(props: components.ClearIndicatorProps) {
    return (
      <div {...props.innerProps}>
        <Icon set="FontAwesome" name="times" onClick={() => onClear()} />
      </div>
    );
  }

  const getValue = () => {
    if (isMulti) {
      if (options.length === 0) {
        return value;
      }

      return Array.isArray(value)
        ? options.filter(option =>
            value.some(
              selectValue =>
                (selectValue as DropdownItem<T>).value === option?.value
            )
          )
        : [];
    }

    return value && options.find(option => isEqual(option?.value, value));
  };

  const containerRef = useRef(null);

  return (
    <InputWrapper id={id}>
      <div
        className={styles.mainContainer}
        ref={containerRef}
        data-test={testId}
      >
        <Select
          minMenuHeight={`${Math.min(options.length, 5) * 41}px`}
          loadingMessage={Loading}
          placeholder=""
          id={id}
          isDisabled={disabled}
          className={cx(styles.Dropdown, isFullWidth && styles.fullWidth)}
          value={getValue()}
          onChange={(selectedOption: DropdownItem<T> | DropdownItem<T>[]) => {
            if (isMultiDropdown(props)) {
              if (props.onChange)
                props.onChange(selectedOption as DropdownItem<T>[]);

              if (props.onValueChange)
                props.onValueChange(
                  selectedOption
                    ? ([selectedOption] as DropdownItem<T>[]).map(
                        option => option.value
                      )
                    : selectedOption
                );
            }
          }}
          tabSelectsValue={!isMulti}
          onMenuOpen={() => {
            onMenuOpen();
          }}
          onMenuClose={() => {
            onMenuClose();
          }}
          onFocus={() => {
            if (!isTouched) setIsTouched(true);
          }}
          name={name}
          options={options}
          components={{
            Menu: props.Menu,
            Option: IconOption,
            IndicatorSeparator: invalid && CustomIndicatorError,
            DropdownIndicator: CustomIndicator,
            ...(isMulti
              ? {
                  Option: CustomMultiOption,
                  ValueContainer: components.ValueContainer,
                  MultiValueContainer: CustomMultiValueContainer,
                  MultiValueRemove: CustomMultiValueRemove,
                  ClearIndicator: CustomClearIndicator,
                }
              : {}),
          }}
          styles={dropdownStyles}
          closeMenuOnScroll={(e: any) => {
            return e.target.contains(containerRef.current);
          }}
          menuPosition={menuPosition}
          menuShouldScrollIntoView={false}
          menuPlacement="auto"
          aria-label={ariaLabel}
          closeMenuOnSelect={closeMenuOnSelect}
          isMulti={isMulti}
          isClearable={isClearable}
          menuIsOpen={isMenuOpen}
        />
        <label
          htmlFor={id}
          className={styles.label}
          data-interface-style={interfaceStyle}
        >
          {label}
        </label>
        {invalid && <ValidationMessage errors={errors} withoutIcon />}
      </div>
    </InputWrapper>
  );
}

export const ChannelDropdown = <T,>(props: DropdownProps<T>) => (
  <BaseDropdown {...props} />
);
