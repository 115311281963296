import gql from 'graphql-tag';

export const queryMyChannelsIntegrations = gql`
  query getMyChannelIntegrations(
    $channelId: UUID!
    $search: ChannelSearch
    $relationshipSearch: ChannelRelationshipSearch
    $skipPagination: Boolean
  ) {
    me {
      switchChannel(channelId: $channelId, search: $search) {
        focusOnChannels {
          integrations {
            _id
            settings
            channel {
              _id
              name
              profile {
                _id
                name
                logo
              }
            }
            integration {
              _id
              name
              type
              category
            }
          }
        }
      }
      channels {
        _id
      }
    }
    channelsByRelationship(
      search: $relationshipSearch
      skipPagination: $skipPagination
    ) {
      items {
        channel {
          _id
          _created
          integrations {
            _id
            settings
            channel {
              _id
              name
              profile {
                _id
                name
                logo
              }
            }
            integration {
              _id
              name
              type
              category
            }
          }
        }
      }
    }
  }
`;
