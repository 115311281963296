import React, { useState, useEffect, createContext } from 'react';

import * as Sentry from '@sentry/browser';

import { config } from 'lane-shared/config';
import env from 'lane-shared/config/getEnv';
import { ENVIRONMENTS } from 'activate-constants';

const RECAPTCHA_KEY = config.googleRecaptcha.siteKey;

type ContextValues = {
  reCaptchaKey: typeof RECAPTCHA_KEY;
  executeRecaptcha: (action?: string) => Promise<string>;
};

export const GoogleRecaptchaContext = createContext<ContextValues>({
  reCaptchaKey: RECAPTCHA_KEY,
  executeRecaptcha: () => new Promise(resolve => resolve('token')),
});

type GreCaptcha = {
  ready: any;
  render: any;
  reset: any;
  getResponse: any;
  execute: any;
};

declare global {
  interface Window {
    grecaptcha: GreCaptcha;
  }
}

type Props = {
  children: React.ReactNode;
};

/**
 * Context Provider for handling the injection of the google recaptcha
 * script into the dom.
 */
function GoogleRecaptchaProvider({ children }: Props) {
  const [grecaptcha, setGrecaptcha] = useState<GreCaptcha>({} as GreCaptcha);

  useEffect(() => {
    /* Inject recaptcha script into dom */
    const recaptchaScript = document.createElement('script');

    recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`;
    recaptchaScript.onload = () => setGrecaptcha(window.grecaptcha);

    const head = document.getElementsByTagName('head')[0];

    head.appendChild(recaptchaScript);
  }, []);

  function executeRecaptcha(action?: string): Promise<string> {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(RECAPTCHA_KEY, { action })
          .then((token: any) => resolve(token))
          .catch(() => {
            if ([ENVIRONMENTS.LOCAL, ENVIRONMENTS.DEVELOPMENT].includes(env)) {
              const errorMessage =
                '[Dev Error] Failed to get Google Recaptcha Token.' +
                `Are you sure the domain ${window.location.hostname} is registered for Lane's Google Recaptcha account`;

              console.error(errorMessage);
              reject(errorMessage);
            }

            Sentry.captureException(
              new Error(
                `Failed to get Recaptcha token with domain ${window.location.hostname}.`
              )
            );
          });
      });
    });
  }

  return (
    <GoogleRecaptchaContext.Provider
      value={{ reCaptchaKey: RECAPTCHA_KEY, executeRecaptcha }}
    >
      {children}
    </GoogleRecaptchaContext.Provider>
  );
}

export default GoogleRecaptchaProvider;
