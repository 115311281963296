import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Checkbox } from 'components';

import Loading from './Loading';

import styles from './MultiselectContextMenu.scss';

export interface MCMenuOptionItem {
  label: string;
  value: string;
  icon?: string;
  iconType?: string;
}

export default function MultiselectContextMenu({
  values = [],
  items,
  onChange,
  header,
  footer,
  disabled,
}: {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  items: MCMenuOptionItem[];
  onChange: (v: string[]) => void;
  values: string[];
  disabled?: boolean;
}) {
  function handleClick(value: string) {
    if (disabled) {
      return;
    }

    if (values.includes(value)) {
      onChange(values.filter(v => v !== value));
    } else {
      onChange([...values, value]);
    }
  }

  return (
    <div className={styles.MultiselectableContextMenu}>
      {header}
      <div className={styles.items}>
        {items.length ? (
          items.map(data => (
            <div
              className={cx(styles.option)}
              key={data.value}
              data-disabled={disabled}
              data-active={values.includes(data.value)}
              onClick={() => handleClick(data.value)}
              role="button"
              tabIndex={0}
            >
              {data.icon && (
                <Icon
                  className={styles.optionIcon}
                  set="FontAwesome"
                  name={data.icon}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  type={data.iconType}
                />
              )}
              <span className={styles.optionText}>{data.label}</span>
              <Checkbox
                disabled={disabled}
                className={styles.checkbox}
                value=""
                selected={values.includes(data.value)}
              />
            </div>
          ))
        ) : (
          <Loading />
        )}
      </div>
      {footer}
    </div>
  );
}
