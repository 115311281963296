import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { PublicUserType } from 'lane-shared/hooks/useMyPublicUsersQuery';

import { Flex } from 'components/layout';
import { M } from 'components/typography';

import ReviewData from './ReviewData';

type Props = {
  onClick?: () => void;
  l9: PublicUserType;
};

export default function ReviewL9({ onClick, l9 }: Props) {
  const { t } = useTranslation();

  return (
    <ReviewData
      title={t`M6+ approver`}
      titleIcon={<Icon set="FontAwesome" name="users" type="far" />}
      onClick={onClick}
      summary={
        <Flex direction="column">
          {l9?.profile.name && (
            <M variant="secondary">
              {t`Name`}: {l9?.profile.name}
            </M>
          )}
          {l9?.profile.email && (
            <M variant="secondary">
              {t`Email`}: {l9?.profile.email}
            </M>
          )}
        </Flex>
      }
      isEditable
    />
  );
}
