import { LaneType } from 'common-types';
import { DateRangeType } from '../../types/baseTypes/DateRangeType';
import { parseDateTime } from '../dates';

/**
 * When you have reservations and buffer time is applicable
 * we need to find out possible buffer time ranges so that
 * we can prevent users selecting the timeslot by making them as
 * unavailable ranges
 * @param reservations ReservableUnavailabilityRange[]
 * @param units number (in minutes)
 * @param bufferTime LaneType.BufferTime
 * @return DateRangeType[]
 */
export default function calculateBufferTimeRangesForReservations({
  reservations,
  units,
  bufferTime,
}: {
  reservations: DateRangeType[];
  units: number;
  bufferTime: LaneType.BufferTime;
}): DateRangeType[] {
  const bufferTimeRanges: DateRangeType[] = [];

  if (!bufferTime.isEnabled) {
    return [];
  }

  reservations.forEach(unavailable => {
    const reservationStart = parseDateTime(unavailable.startDate);

    if (reservationStart && bufferTime.afterDuration) {
      for (let i = 0; i < bufferTime.afterDuration / units; i += 1) {
        const bufferStart = {
          startDate: reservationStart
            .minus({ minute: units * (i + 1) })
            .toJSDate(),
          endDate: reservationStart.minus({ minute: units * i }).toJSDate(),
        };

        bufferTimeRanges.push(bufferStart);
      }
    }

    const reservationEnd = parseDateTime(unavailable.endDate);

    if (reservationEnd && bufferTime.beforeDuration) {
      for (let i = 0; i < bufferTime.beforeDuration / units; i += 1) {
        const bufferEnd = {
          startDate: reservationEnd.plus({ minute: units * i }).toJSDate(),
          endDate: reservationEnd.plus({ minute: units * (i + 1) }).toJSDate(),
        };

        bufferTimeRanges.push(bufferEnd);
      }
    }
  });

  return bufferTimeRanges;
}
