import jwtDecode from 'jwt-decode';

type Auth0Token = {
  activate_session_id: string;
};

type Token = {
  jti: string;
};
const getSessionId = (token: Token | string) => {
  let sessionId;

  if (typeof token === 'string') {
    try {
      const decodedToken: Auth0Token = jwtDecode(token);

      sessionId = decodedToken.activate_session_id;
    } catch (e) {
      console.error('Error decoding token', e, token);
    }
  } else {
    sessionId = token.jti;
  }

  return sessionId;
};

export { getSessionId };
export type { Token, Auth0Token };
