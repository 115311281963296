import { LaneType } from 'common-types';
import addressFormatter from 'lane-shared/helpers/formatters/addressFormatter';
import getSimpleMetatagValueByKey from 'lane-shared/helpers/getSimpleMetatagValueByKey';
import { ShopifyChannelType } from 'lane-shared/types/integrations/ShopifyBurst';

export default function useShopifyBurstLocation(
  port: ShopifyChannelType,
  primaryRoomBooking: LaneType.UserContentInteraction
): {
  address: string;
  longitude: number;
  latitude: number;
} {
  const portAddress = addressFormatter(port?.address, [
    'street1',
    'street2',
    'street3',
    'city',
    'state',
    'country',
  ]);
  const roomContentData = primaryRoomBooking?.contentData as any;

  const [portLongitude, portLatitude] = port?.address?.geo || [null, null];

  if (roomContentData) {
    const roomAddress = getSimpleMetatagValueByKey(
      roomContentData.contentMetatags,
      'address',
      'String'
    );
    const roomLatitude = getSimpleMetatagValueByKey(
      roomContentData.contentMetatags,
      'geolocation-latitude',
      'Number'
    );
    const roomLongitude = getSimpleMetatagValueByKey(
      roomContentData.contentMetatags,
      'geolocation-longitude',
      'Number'
    );

    if (roomAddress && roomLongitude && roomLatitude) {
      return {
        address: roomAddress,
        longitude: roomLongitude,
        latitude: roomLatitude,
      };
    }
  }

  return {
    address: portAddress,
    longitude: portLongitude,
    latitude: portLatitude,
  };
}
