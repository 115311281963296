export const SHORT_MONTH = 'MMM';
export const LONG_MONTH = 'MMMM';
export const MONTH_YEAR = 'MMM yyyy';
export const LONG_MONTH_YEAR = 'MMMM yyyy';
export const SHORT_DAY = 'EEE';
export const VERY_SHORT_DAY = 'EEEEE';
export const LONG_DAY = 'EEEE';
export const TIME_OF_DAY = 't';
export const SHORT_DATE = `MMM d`;
export const SHORT_DATE_FULL_MONTH = 'MMMM d';
export const MEDIUM_DATE = `dd MMM yyyy`;
export const INVOICE_DATE = `MM/dd/yyyy`;
export const RECENT_DATE = 'EEEE, d MMM';
export const RECENT_DATE_V2 = 'EEEE, MMM d';
export const RECENT_DATE_FULL_MONTH = 'EEEE, MMMM d';
export const LONG_TIME = 'h:mm a';
export const SHORT_TIME = 'h:mm a';
export const SIMPLE_DATE = 'MMM d, yyyy';
export const MEDIUM_DAY_DATE = 'EEE MMM d';
export const LONG_DATE = 'EEE MMM d, yyyy';
export const MEDIUM_DATE_TIME = 'EEE MMM d h:mm a';
export const LONG_DATE_TIME = 'EEE MMM d, yyyy - h:mm a';
export const DATE_TIME = 'dd-MMM-yyyy h:mm:ssa';
export const DAY_AND_TIME = 'EEEE, t';
export const SIMPLE_DATE_TIME = 'yyyy-MM-dd h:mm a';
export const TIMEZONE = 'ZZZZ';
export const LONG_DATE_TIME_WITH_TZ = `${LONG_DATE_TIME} ${TIMEZONE}`;
export const LONG_TIME_WITH_TZ = `${LONG_TIME} ${TIMEZONE}`;
export const SHORT_TIME_WITH_TZ = `${SHORT_TIME} ${TIMEZONE}`;
export const LONG_MONTH_DATE_YEAR = 'MMMM d, yyyy';
export const LONG_DAY_LONG_DATE = `${LONG_DAY}, ${SIMPLE_DATE}`;
export const LONG_DAY_MONTH_YEAR = `${LONG_DAY}, ${LONG_MONTH_DATE_YEAR}`;
export const DATE_ISO_8601 = 'yyyy-MM-dd';

export const WEEKDAY_IN_WORD = 'cccc';
