import { DateTime } from 'luxon';

import parseDateTime from './parseDateTime';

export default function areDatesEqual(
  dateA?: Date | DateTime | string | null | undefined,
  dateB?: Date | DateTime | string | null | undefined
): boolean {
  if (!dateA || !dateB) {
    return false;
  }

  const parsedDateA = parseDateTime(dateA);
  const parsedDateB = parseDateTime(dateB);

  if (!parsedDateA || !parsedDateB) {
    return false;
  }

  return parsedDateA.equals(parsedDateB);
}
