// @ts-nocheck FIXME: Ignored due failing CI after React update
import React, { useEffect } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  mapStatusToLaneStatus,
  PRIORITY_MAP,
  PRIORITY_COLOR_MAP,
} from 'lane-shared/helpers/constants/integrations/BuildingEnginesPrismWorkOrders';
import { simpleDate } from 'lane-shared/helpers/formatters';
import { WorkOrder } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client/workOrders';
import { getIssueText } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/getIssueText';
import useStatusColor from 'lane-shared/hooks/useStatusColor';

import { M, S, H5 } from 'components/typography';

import Pill from '../../../general/Pill';
import { getPillCircleColor } from './OpenRequests';

import styles from './RequestCard.scss';

export default function RequestCard({
  interaction,
  isActive,
}: {
  interaction: WorkOrder;
  isActive: boolean;
}) {
  const { t } = useTranslation();

  const issue = getIssueText(interaction);

  const laneStatus = mapStatusToLaneStatus(interaction.status);

  const statusColor = useStatusColor({
    status: laneStatus,
  });

  const pillCircleColor = getPillCircleColor(statusColor);

  useEffect(() => {
    const abortController = new AbortController();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className={cx(styles.container, isActive ? styles.isActive : '')}>
      <div className={cx(styles.section, styles.borderRadiusTop)}>
        <div className={styles.row}>
          <S style={{ color: 'gray' }}>{interaction.identifier}</S>
          {interaction.priority ? (
            <Pill
              text={t(laneStatus)}
              size="small"
              className={styles.pill}
              color={statusColor}
              iconName="circle"
              iconColor={pillCircleColor}
            />
          ) : null}
        </div>

        <div className={styles.row}>
          <H5 mt={2}>{issue}</H5>
        </div>
        <div className={styles.description}>
          <M>{interaction.description}</M>
        </div>
      </div>
      <div
        className={cx(
          isActive ? styles.activeSection : styles.section,
          styles.borderRadiusBottom
        )}
      >
        <div className={styles.row}>
          <div>
            <S style={{ color: 'gray' }}>{t('Requested')}</S>
            <div>
              <S>{`${interaction.requester?.name || t('Unassigned')}`}</S>
              <S style={{ color: 'gray' }}>
                &nbsp;{`- ${simpleDate(interaction.created_at)}`}
              </S>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'right',
            }}
          >
            <S style={{ color: 'gray' }}>{t('Priority')}</S>
            <S
              style={{ color: PRIORITY_COLOR_MAP[interaction.priority] }}
              className={styles.priority}
            >
              {PRIORITY_MAP[interaction.priority]}
            </S>
          </div>
        </div>
      </div>
    </div>
  );
}
