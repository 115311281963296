import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';
import { explodeFeatures } from 'lane-shared/helpers/features';
import useHasMetRequirements from 'lane-shared/hooks/features/useHasMetRequirements';
import { ContentType } from 'lane-shared/types/content/Content';
import { RequirementsEnum } from 'lane-shared/types/features/RequirementsFeatureProperties';

import ContentInteractionRequirementRows from './ContentInteractionRequirementRows';
import ProfilePictureRow from './ProfilePictureRow';
import RequirementRow from './RequirementRow';

import styles from './RequirementsList.scss';

type Props = {
  content: ContentType;
  editMode?: boolean;
  onClickRequirement: (requirement: RequirementsEnum) => void;
  verificationEmailSentLabelText?: string;
  verificationEmailSentLabelDescriptionText?: string;
  isVerificationEmailSent?: boolean;
  emailVerificationCheck?: () => void;
  setIsVerificationEmailSent?: (flag: boolean) => void;
  isVerificationEmailSubmitting?: boolean;
  setIsVerificationEmailSubmitting?: (flag: boolean) => void;
};

// When adding a new requirement here, also be sure to update
// RequirementsBlock and RequirementsModal to make use of the
// new requirement.
export default function RequirementsList({
  content,
  onClickRequirement,
  editMode,
  verificationEmailSentLabelText,
  verificationEmailSentLabelDescriptionText,
  isVerificationEmailSent,
  emailVerificationCheck,
  setIsVerificationEmailSent,
  isVerificationEmailSubmitting,
  setIsVerificationEmailSubmitting
}: Props) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const { requirementsFeature } = explodeFeatures(content?.features);
  const [
    verificationEmailButtonText,
    setVerificationEmailButtonText,
  ] = useState<string>(
    t(
      'web.admin.channel.content.layout.editor.components.requirementsBlock.send'
    )
  );

  const {
    fullNameRequirementMet,
    phoneNumberRequirementMet,
    profilePictureRequirementMet,
    verifiedEmailRequirementMet,
    contentInteractionsRequirementMet,
    allContentInteractionRequirementsMet,
    companyNameRequirementMet,
  } = useHasMetRequirements({
    content,
    user,
    isEditMode: editMode,
  });


  useEffect(() => {
    setVerificationEmailButtonText(
      isVerificationEmailSent
        ? 'web.admin.channel.content.layout.editor.components.requirementsBlock.resend'
        : 'web.admin.channel.content.layout.editor.components.requirementsBlock.send'
    );

    if (isVerificationEmailSent && setIsVerificationEmailSubmitting) {
      setIsVerificationEmailSubmitting(false);
    }
  }, [isVerificationEmailSent, isVerificationEmailSubmitting]);

  const onClickVerificationEmailButton = (): void => {
    onClickRequirement(RequirementsEnum.VerifiedEmail);

    if (setIsVerificationEmailSent) setIsVerificationEmailSent(false);

    if (setIsVerificationEmailSubmitting) setIsVerificationEmailSubmitting(true);

    setVerificationEmailButtonText(
      'web.admin.channel.content.layout.editor.components.requirementsBlock.resend'
    );
  };

  if (!content) return null;

  return (
    <div className={styles.RequirementsList}>
      {!fullNameRequirementMet ? (
        <RequirementRow
          header={t(
            'web.admin.channel.content.layout.editor.components.fullNameForm.title'
          )}
          description={t(
            'web.admin.channel.content.layout.editor.components.fullNameForm.description'
          )}
          buttonLabel={t(
            'web.admin.channel.content.layout.editor.components.requirementsBlock.add'
          )}
          onClick={() => onClickRequirement(RequirementsEnum.FullName)}
        />
      ) : null}

      {!profilePictureRequirementMet ? (
        <ProfilePictureRow
          onSave={() => {
            window.Toast.show(
              t(
                'web.admin.channel.content.layout.editor.components.profilePicture.addMessage'
              )
            );
          }}
        />
      ) : null}

      {!phoneNumberRequirementMet ? (
        <RequirementRow
          header={t(
            'web.admin.channel.content.layout.editor.components.phoneForm.title'
          )}
          description={t(
            'web.admin.channel.content.layout.editor.components.phoneForm.description'
          )}
          buttonLabel={t(
            'web.admin.channel.content.layout.editor.components.requirementsBlock.add'
          )}
          onClick={() => onClickRequirement(RequirementsEnum.PhoneNumber)}
        />
      ) : null}

      {!companyNameRequirementMet ? (
        <RequirementRow
          header={t(
            'web.admin.channel.content.layout.editor.components.companyForm.title'
          )}
          description={t(
            'web.admin.channel.content.layout.editor.components.companyForm.description'
          )}
          buttonLabel={t(
            'web.admin.channel.content.layout.editor.components.requirementsBlock.add'
          )}
          onClick={() => onClickRequirement(RequirementsEnum.CompanyName)}
        />
      ) : null}

      {!verifiedEmailRequirementMet ? (
        <RequirementRow
          header={verificationEmailSentLabelText}
          description={verificationEmailSentLabelDescriptionText}
          buttonLabel={t(verificationEmailButtonText)}
          onClick={() => onClickVerificationEmailButton()}
          loading={isVerificationEmailSubmitting}
          emailVerificationCheck={isVerificationEmailSent ? emailVerificationCheck : null}
        />
      ) : null}

      {!allContentInteractionRequirementsMet &&
        requirementsFeature?.contentInteractionRequired ? (
        <ContentInteractionRequirementRows
          requirementsMet={contentInteractionsRequirementMet}
          contentInteractionRequired={
            requirementsFeature.contentInteractionRequired
          }
        />
      ) : null}
    </div>
  );
}
