import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { FloorMapType } from 'lane-shared/helpers/integrations/FloorMaps/types/FloorMap';

import Chip from 'components/general/Chip';
import DropdownContextMenu from 'components/general/DropdownContextMenu';
import OptionsMenu from 'components/general/OptionsMenu';

interface Props {
  selectedFloor?: FloorMapType | undefined | null;
  items: {
    label: string;
    value: string;
  }[];
  selected: boolean;
  selectedIcon?: string | null;
  onChange: (value: string) => void;
}

export default function FloorFilter({
  onChange,
  items,
  selectedFloor,
  selected,
  selectedIcon,
}: Props) {
  const [isOpen, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const handleChange = (e: any) => {
    onChange(e);
    setOpen(false);
  };

  return (
    <DropdownContextMenu
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      title={
        <Chip
          onClick={() => setOpen(!isOpen)}
          deleteIcon={<Icon name="angle-down" set={ICON_SET_FONTAWESOME} />}
          label={selectedFloor?.name || t('Floor')}
          selected={selected}
          selectedIcon={selectedIcon}
        />
      }
      hasRippleEffect
    >
      <OptionsMenu
        searchPlaceholder={t('Floor number')}
        onChange={handleChange}
        hasSearch
        items={items}
        value={selectedFloor?._id}
      />
    </DropdownContextMenu>
  );
}
