import { gql } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import convertToUUID from 'lane-shared/helpers/convertId/convertToUUID';

import {
  CREDIT_CATEGORY_NAME,
  CREDIT_CATEGORY_DESCRIPTION,
} from 'lane-shared/domains/billingPayments/constants';

import { ProductType, GroupType } from 'graphql-resolver-contracts';

const createCreditCategoryMutation = gql`
  mutation createCreditCategory(
    $createProductCategoryRequest: CreateProductCategoryRequest
  ) {
    createProductCategory(
      createProductCategoryRequest: $createProductCategoryRequest
    ) {
      productCategoryId
    }
  }
`;

export async function createCreditCategory(channelId: string) {
  // Hard coded values for the credit category
  const { data } = await getClient().mutate({
    mutation: createCreditCategoryMutation,
    variables: {
      createProductCategoryRequest: {
        name: CREDIT_CATEGORY_NAME,
        description: CREDIT_CATEGORY_DESCRIPTION,
        productType: ProductType.ProductTypeCreditBundle,
        groupId: convertToUUID(channelId),
        groupType: GroupType.GroupTypeActivateChannel,
      },
    },
  });

  return data.createProductCategory;
}
