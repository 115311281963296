import { v4 as uuid } from 'uuid';

import {
  QuestionsType,
  SURVEY_TYPE_TO_CONTENT_MAPPING,
} from 'lane-shared/domains/surveys/constants/questions';
import { objectToArray } from 'lane-shared/helpers';
import { ValidatorNameOptions } from 'lane-shared/types/Validator';
import { SurveyTypeEnum } from 'lane-shared/types/features/SurveysFeatureProperties';
import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';
import {
  PropertiesInterface,
  PropertyType,
} from 'lane-shared/types/properties/Property';

/**
 * Gets PropertyType fields for survey specific inputs
 */
export function getSurveysDataInputs({
  contentData,
  surveyType,
}: {
  contentData: PropertiesInterface | null;
  surveyType: SurveyTypeEnum;
}): PropertiesInterface {
  const definition = objectToArray(
    contentData as Record<string, { name?: string }> | null,
    true
  );
  let i = Object.keys(definition).length;
  let name = `field_${i}`;

  const inputs = {} as PropertiesInterface;

  const questions = SURVEY_TYPE_TO_CONTENT_MAPPING?.[surveyType]?.questions;

  if (!questions) {
    throw new Error(`Invalid survey type: ${surveyType}`);
  }

  // create field for each survey related question
  // these questions appear in the Data tab of the content builder
  questions.forEach((question: QuestionsType) => {
    let newField = {
      _id: uuid(),
      isArray: false,
      friendlyName: question.key,
      name,
      placeholder: '',
      hideEditFromBuilder: true,
      hideDeleteFromBuilder: true,
    } as PropertyType;

    if (question.type === PackagedTypeEnum.Dropdown) {
      newField = {
        ...newField,
        isArray: false,
        type: 'Option',
        packagedType: PackagedTypeEnum.Dropdown,
        validators: [
          {
            name: 'In' as ValidatorNameOptions,
            value: getFormattedDropdownOptions(question?.dropdowns ?? []),
          },
        ],
      };
    } else if (question.type === 'LongText') {
      newField = {
        ...newField,
        type: question.type,
        validators: [{ name: 'Max' as ValidatorNameOptions, value: 16384 }],
      };
    }

    inputs[name] = newField;
    i++;
    name = `field_${i}`;
  });

  return inputs;
}

function getFormattedDropdownOptions(dropdowns: string[]) {
  const numDropdowns = dropdowns.length;

  return dropdowns.map((dropdown, i) => {
    return {
      _id: uuid(),
      name: dropdown,
      value: `${numDropdowns - i}`,
    };
  });
}
