import { FRIENDLY_CONTENT_TYPES } from '../../../types/content/ContentTypeEnum';
import { channelCollectables } from './channel.collectable';
import { contentNotificationCollectables } from './contentNotification.collectable';
import type { DraftContentType } from '../../../types/content/DraftContent';
import { contentFeatureCollectables } from './contentFeature.collectable';
import { contentDataFieldCollectables } from './contentDataField.collectable';

export const draftContentCollectables = (
  draftContent?: Partial<DraftContentType>
) => {
  if (!draftContent) return;

  return {
    id: draftContent._id,
    contentType: FRIENDLY_CONTENT_TYPES[draftContent?.type!],
    name: draftContent.name,
    name_l10n: draftContent.name_l10n,
    description: draftContent.description,
    description_l10n: draftContent.description_l10n,
    contentIsEvent: draftContent.isEvent,
    publishDate: draftContent.liveDate,
    unpublishDate: draftContent.unpublishDate,
    eventLiveDate: draftContent.startDate,
    eventEndDate: draftContent.endDate,
    channel: channelCollectables(draftContent.channel),
    notifications: draftContent.notifications?.map(
      contentNotificationCollectables
    ),
    templateName: draftContent.templateName,
    isInteractive: draftContent.isInteractive,
    enabledFeatures: contentFeatureCollectables(draftContent.features || []),
    data: contentDataFieldCollectables(draftContent.data),
  };
};
