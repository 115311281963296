import {
  IntervalTypeEnum,
  MonthIntervalTypeEnum,
} from 'lane-shared/domains/visitorManagement/types/VisitorManagementFeatureProperties';

import { DateRangeType } from '../../../types/baseTypes/DateRangeType';
import {
  combineDateAndTime,
  getMatchingMonthIntervalTypes,
  isEarlierTime,
  removeTime,
} from '../helpers';

export enum ScheduleActionType {
  ToggleAllDay,
  SetDate,
  SetDates,
  SetDateRange,
  SetStartTime,
  SetEndTime,
  SetIntervalType,
  SetEndRepeatDate,
  SetIntervalCount,
  SetWeekdayRepeats,
  SetMonthIntervalType,
  Reset,
}

export type ScheduleReducerType = {
  isAllDay: boolean;
  startDate: Date;
  endDate: Date;
  dates?: Date[];
  startTime?: Date;
  endTime?: Date;
  intervalType?: IntervalTypeEnum;
  endRepeatDate?: Date;
  intervalCount?: number;
  weekdayRepeats?: number[];
  monthIntervalType?: MonthIntervalTypeEnum;
};

export type InternalScheduleType = {
  internalStartDate: Date;
  internalEndDate: Date;
  startTime: Date;
  endTime: Date;
} & ScheduleReducerType;

export interface ScheduleAction {
  type: ScheduleActionType;
  value?: {
    string?: string;
    number?: number;
    numbers?: number[];
    date?: Date;
    dates?: Date[];
    dateRange?: DateRangeType;
  };
}

const actionHandlers = {
  [ScheduleActionType.Reset]: (
    state: InternalScheduleType,
    action: ScheduleAction,
    defaultValues: ScheduleReducerType,
    timeZone?: string
  ) => {
    return {
      ...state,
      isAllDay: defaultValues.isAllDay,
      startDate: defaultValues.startDate,
      endDate: defaultValues.endDate,
      internalStartDate: removeTime(defaultValues.startDate, timeZone),
      internalEndDate: removeTime(defaultValues.endDate, timeZone),
      startTime: defaultValues.startDate,
      endTime: defaultValues.endDate,
      dates: defaultValues.dates,
      intervalType: defaultValues.intervalType,
      endRepeatDate: defaultValues.endRepeatDate,
      intervalCount: defaultValues.intervalCount,
      weekdayRepeats: defaultValues.weekdayRepeats,
      monthIntervalType: defaultValues.monthIntervalType,
    };
  },
  [ScheduleActionType.ToggleAllDay]: (
    state: InternalScheduleType,
    action: ScheduleAction,
    defaultValues: ScheduleReducerType
  ) => {
    const isAllDay = !state.isAllDay;

    return {
      ...state,
      isAllDay,
      startTime: isAllDay ? defaultValues.startDate : state.startTime,
      endTime: isAllDay ? defaultValues.endDate : state.endTime,
    };
  },
  [ScheduleActionType.SetDate]: (
    state: InternalScheduleType,
    action: ScheduleAction,
    defaultValues: ScheduleReducerType,
    timeZone?: string
  ) => {
    const setDateState = {
      ...state,
      internalStartDate: action.value?.date
        ? action.value.date
        : defaultValues.startDate,
    };

    setDateState.internalEndDate = setDateState.internalStartDate;
    setDateState.startTime = combineDateAndTime(
      setDateState.internalStartDate,
      state.startTime,
      timeZone
    );
    setDateState.endTime = combineDateAndTime(
      setDateState.internalEndDate,
      state.endTime,
      timeZone
    );
    const date = action.value?.date;

    if (date) {
      const dateValue = new Date(date).getTime();

      if (
        setDateState.endRepeatDate &&
        dateValue > setDateState.endRepeatDate.getTime()
      ) {
        setDateState.endRepeatDate = setDateState.internalStartDate;
      }

      if (setDateState.monthIntervalType) {
        const options = getMatchingMonthIntervalTypes(date, timeZone);

        setDateState.monthIntervalType = options.includes(
          setDateState.monthIntervalType
        )
          ? setDateState.monthIntervalType
          : options[0];

        if (
          setDateState.intervalType === IntervalTypeEnum.Monthly &&
          setDateState.monthIntervalType !== MonthIntervalTypeEnum.OnDate
        ) {
          setDateState.weekdayRepeats = [
            setDateState.internalStartDate.getDay(),
          ];
        }
      }
    }

    return setDateState;
  },
  [ScheduleActionType.SetDates]: (
    state: InternalScheduleType,
    action: ScheduleAction,
    defaultValues: ScheduleReducerType,
    timeZone?: string
  ) => {
    const dates = action?.value?.dates?.sort(
      (a, b) => a.getTime() - b.getTime()
    );

    if (dates && dates.length > 0) {
      const setDatesState = {
        ...state,
        internalStartDate: dates[0] ?? removeTime(defaultValues.startDate),
        internalEndDate: dates[0] ?? removeTime(defaultValues.endDate),
        dates,
      };

      setDatesState.startTime = combineDateAndTime(
        setDatesState.internalStartDate,
        state.startTime,
        timeZone
      );
      setDatesState.endTime = combineDateAndTime(
        setDatesState.internalEndDate,
        state.endTime,
        timeZone
      );

      return setDatesState;
    }

    return { ...state };
  },
  [ScheduleActionType.SetDateRange]: (
    state: InternalScheduleType,
    action: ScheduleAction,
    defaultValues: ScheduleReducerType,
    timeZone?: string
  ) => {
    const dateRange = action?.value?.dateRange;

    if (dateRange) {
      const dateRangeState = {
        ...state,
        internalStartDate:
          dateRange.startDate ?? removeTime(defaultValues.startDate, timeZone),
        internalEndDate:
          dateRange.endDate ?? removeTime(defaultValues.endDate, timeZone),
        endRepeatDate: state.internalEndDate,
      };

      dateRangeState.startTime = combineDateAndTime(
        dateRangeState.internalStartDate,
        state.startTime,
        timeZone
      );
      dateRangeState.endTime = combineDateAndTime(
        dateRangeState.internalEndDate,
        state.endTime,
        timeZone
      );

      return dateRangeState;
    }

    return { ...state };
  },
  [ScheduleActionType.SetEndRepeatDate]: (
    state: InternalScheduleType,
    action: ScheduleAction
  ) => {
    return {
      ...state,
      endRepeatDate: action.value?.date
        ? new Date(action.value.date)
        : undefined,
    };
  },
  [ScheduleActionType.SetStartTime]: (
    state: InternalScheduleType,
    action: ScheduleAction,
    defaultValues: ScheduleReducerType,
    timeZone?: string
  ) => {
    const setStartTimeState = {
      ...state,
      startTime: action.value?.date
        ? new Date(action.value.date)
        : defaultValues.startDate,
    };

    if (isEarlierTime(setStartTimeState.startTime, state.endTime, timeZone)) {
      setStartTimeState.endTime = combineDateAndTime(
        state.endTime,
        setStartTimeState.startTime,
        timeZone
      );
    }

    return setStartTimeState;
  },
  [ScheduleActionType.SetEndTime]: (
    state: InternalScheduleType,
    action: ScheduleAction,
    defaultValues: ScheduleReducerType,
    timeZone?: string
  ) => {
    const setEndTimeState = {
      ...state,
      endTime: action.value?.date
        ? new Date(action.value.date)
        : defaultValues.endDate,
    };

    if (isEarlierTime(state.startTime, setEndTimeState.endTime, timeZone)) {
      setEndTimeState.startTime = combineDateAndTime(
        state.startTime,
        setEndTimeState.endTime,
        timeZone
      );
    }

    return setEndTimeState;
  },
  [ScheduleActionType.SetIntervalCount]: (
    state: InternalScheduleType,
    action: ScheduleAction,
    defaultValues: ScheduleReducerType
  ) => {
    return {
      ...state,
      intervalCount: action.value?.number ?? defaultValues.intervalCount,
    };
  },
  [ScheduleActionType.SetIntervalType]: (
    state: InternalScheduleType,
    action: ScheduleAction,
    defaultValues: ScheduleReducerType,
    timeZone?: string
  ) => {
    const intervalType = action.value?.string
      ? (action.value.string as IntervalTypeEnum)
      : defaultValues.intervalType;
    const newState = {
      ...state,
      intervalType,
      weekdayRepeats: [] as number[],
      intervalCount: 1,
      monthIntervalType:
        getMatchingMonthIntervalTypes(state.startDate, timeZone)[0] ??
        defaultValues.monthIntervalType,
    };

    if (newState.intervalType === IntervalTypeEnum.EveryWeekday) {
      newState.weekdayRepeats = [0, 1, 2, 3, 4];
    }

    return newState;
  },
  [ScheduleActionType.SetWeekdayRepeats]: (
    state: InternalScheduleType,
    action: ScheduleAction,
    defaultValues: ScheduleReducerType
  ) => {
    return {
      ...state,
      weekdayRepeats: action.value?.numbers
        ? action.value.numbers
        : defaultValues.weekdayRepeats,
    };
  },
  [ScheduleActionType.SetMonthIntervalType]: (
    state: InternalScheduleType,
    action: ScheduleAction,
    defaultValues: ScheduleReducerType
  ) => {
    const setMonthIntervalTypeState = { ...state };

    if (action.value?.string !== 'on_date') {
      setMonthIntervalTypeState.weekdayRepeats = [
        setMonthIntervalTypeState.startDate.getDay(),
      ];
    }

    setMonthIntervalTypeState.monthIntervalType = action.value?.string
      ? (action.value.string as MonthIntervalTypeEnum)
      : defaultValues.monthIntervalType;

    return setMonthIntervalTypeState;
  },
};

export const createScheduleReducer = (
  defaultValues: ScheduleReducerType,
  onChange: (state: ScheduleReducerType) => void,
  timeZone?: string
) => (
  state: InternalScheduleType,
  action: ScheduleAction
): InternalScheduleType => {
  const handler = actionHandlers[action.type];

  if (!handler) {
    // If there's no handler for this action type, return the current state.
    return state;
  }

  const newState = handler(state, action, defaultValues, timeZone);

  // all actions will update the output start/end date
  newState.startDate = combineDateAndTime(
    newState.internalStartDate,
    newState.startTime,
    timeZone
  );
  newState.endDate = combineDateAndTime(
    newState.internalEndDate,
    newState.endTime,
    timeZone
  );

  if (action.type !== ScheduleActionType.Reset) {
    onChange({
      startDate: newState.startDate,
      endDate: newState.endDate,
      isAllDay: newState.isAllDay,
      startTime: newState.startTime,
      endTime: newState.endTime,
      dates: newState.dates,
      intervalType: newState.intervalType,
      endRepeatDate: newState.endRepeatDate,
      intervalCount: newState.intervalCount,
      weekdayRepeats: newState.weekdayRepeats,
      monthIntervalType: newState.monthIntervalType,
    });
  }

  return newState;
};
