import gql from 'graphql-tag';

import BatchHelper from './BatchHelper';
import {
  ANALYTIC_CHANNEL_DETAILS,
  ANALYTIC_CHANNEL_VIEW,
  ANALYTIC_CONTENT_DETAILS,
  ANALYTIC_CONTENT_INTERACTION_DETAIL,
  ANALYTIC_CONTENT_INTERACTION_VIEW,
  ANALYTIC_CONTENT_VIEW,
  ANALYTIC_MEDIA_DETAILS,
  ANALYTIC_MEDIA_VIEW,
  ANALYTIC_SECTION_DETAILS,
  ANALYTIC_SECTION_VIEW,
  ANALYTIC_USER_DETAILS,
  ANALYTIC_USER_VIEW,
  MAX_ANALYTICS_PER_REQUEST,
} from './constants/analytics';

const TIMEOUT = 10 * 1000; // x ms seconds.
// prevent some common events on objects from firing too quickly.
const cache = {};

function isCacheHit(event: any, key: any) {
  // lazy setup some cache timeouts
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (!cache[event]) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    cache[event] = {};
  }

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (cache[event][key] && cache[event][key] > Date.now()) {
    return true;
  }

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  cache[event][key] = Date.now() + TIMEOUT;

  return false;
}

export default class AnalyticsHelper extends BatchHelper {
  client: any;

  constructor({ batchSize = MAX_ANALYTICS_PER_REQUEST, client }: any) {
    super({ batchSize, maxTimeout: 10 * 1000 });

    this.client = client;
  }

  track(event: any, data: any) {
    // if this is a analytic that gets fired often and quickly,
    // we will remember that we recently sent one and throttle
    // them.
    switch (event) {
      case ANALYTIC_CONTENT_VIEW:
      case ANALYTIC_CONTENT_DETAILS:
        if (!data.contentId || isCacheHit(event, data.contentId)) {
          return;
        }

        break;
      case ANALYTIC_CHANNEL_VIEW:
      case ANALYTIC_CHANNEL_DETAILS:
        if (!data.channelId || isCacheHit(event, data.channelId)) {
          return;
        }

        break;
      case ANALYTIC_MEDIA_VIEW:
      case ANALYTIC_MEDIA_DETAILS:
        if (!data.mediaId || isCacheHit(event, data.mediaId)) {
          return;
        }

        break;
      case ANALYTIC_CONTENT_INTERACTION_VIEW:
      case ANALYTIC_CONTENT_INTERACTION_DETAIL:
        if (!data.interactionId || isCacheHit(event, data.interactionId)) {
          return;
        }

        break;
      case ANALYTIC_USER_VIEW:
      case ANALYTIC_USER_DETAILS:
        if (!data.userId || isCacheHit(event, data.userId)) {
          return;
        }

        break;
      case ANALYTIC_SECTION_VIEW:
      case ANALYTIC_SECTION_DETAILS:
        if (!data.sectionId || isCacheHit(event, data.sectionId)) {
          return;
        }

        break;
      default:
        break;
    }

    data.event = event;

    this.push({ event, data });
  }

  async doAction(items: any) {
    // don't do anything if there are no items.
    if (items.length === 0) {
      return;
    }

    return this.client.mutate({
      variables: { track: items },
      fetchPolicy: 'no-cache',
      mutation: gql`
        mutation TrackAnalytics($track: [TrackAnalytic!]!) {
          trackAnalytics(track: $track)
        }
      `,
    });
  }
}
