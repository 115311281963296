import { FeatureInstance } from '../../types/FeatureInstance';
import { CancelableFeatureProperties } from '../../types/features/CancelableFeatureProperties';
import { EntriesFeatureProperties } from '../../types/features/EntriesFeatureProperties';
import { EssensysProductFeatureProperties } from '../../types/features/EssensysProductFeatureProperties';
import { FeatureNameEnum } from '../../types/features/FeatureNameEnum';
import { GoogleCalendarFeatureProperties } from '../../types/features/GoogleCalendarFeatureProperties';
import { GuestInviteFeatureProperties } from '../../types/features/GuestInviteFeatureProperties';
import { MenuFeatureProperties } from '../../types/features/MenuFeatureTypes';
import { OutlookCalendarFeatureProperties } from '../../types/features/OutlookCalendarFeatureProperties';
import { PaymentFeatureProperties } from '../../types/features/PaymentFeatureProperties';
import { QRCodeCheckinFeatureProperties } from '../../types/features/QRCodeCheckinFeatureProperties';
import { QuantityFeatureProperties } from '../../types/features/QuantityFeatureProperties';
import { RequirementsFeatureProperties } from '../../types/features/RequirementsFeatureProperties';
import { ReservableFeatureProperties } from '../../types/features/ReservableFeatureProperties';
import { ScheduledFeatureProperties } from '../../types/features/ScheduledFeatureProperties';
import { SocialOptionsFeatureProperties } from '../../types/features/SocialOptionsFeatureProperties';
import { StatusesFeatureProperties } from '../../types/features/StatusesFeatureProperties';
import { SurveysFeatureProperties } from '../../types/features/SurveysFeatureProperties';
import { TimeAvailabilityFeatureProperties } from '../../types/features/TimeAvailabilityFeatureProperties';
import { UseCompanyPermissionsFeatureProperties } from '../../types/features/UseCompanyPermissionsFeatureProperties';
import { VisitorManagementFeatureProperties } from '../../types/features/VisitorManagementFeatureProperties';

export type ExplodeFeaturesResult = {
  cancelableFeature?: CancelableFeatureProperties;
  entriesFeature?: EntriesFeatureProperties;
  essensysProductFeature?: EssensysProductFeatureProperties;
  deliverableFeature?: any;
  inventoryFeature?: any;
  menuFeature?: MenuFeatureProperties;
  outlookCalendarFeature?: OutlookCalendarFeatureProperties;
  googleCalendarFeature?: GoogleCalendarFeatureProperties;
  paymentFeature?: PaymentFeatureProperties;
  qrCodeCheckinFeature?: QRCodeCheckinFeatureProperties;
  quantityFeature?: QuantityFeatureProperties;
  remoteFetchFeature?: any;
  reservableFeature?: ReservableFeatureProperties;
  resetFeature?: any;
  scheduledFeature?: ScheduledFeatureProperties;
  shippingFeature?: any;
  socialOptionsFeature?: SocialOptionsFeatureProperties;
  statusesFeature?: StatusesFeatureProperties;
  timeAvailabilityFeature?: TimeAvailabilityFeatureProperties;
  submitOnBehalfOfFeature?: any;
  useCompanyPermissionsFeature?: UseCompanyPermissionsFeatureProperties;
  guestInviteFeature?: GuestInviteFeatureProperties;
  requirementsFeature?: RequirementsFeatureProperties;
  visitorManagementFeature?: VisitorManagementFeatureProperties;
  surveysFeature?: SurveysFeatureProperties;
};
/**
 * Helper method that takes an array of features and returns named
 * variables for each feature.
 *
 * @param features
 */
export default function explodeFeatures(
  features: Pick<FeatureInstance, 'type' | 'feature'>[] | null | undefined
): ExplodeFeaturesResult {
  const result: ExplodeFeaturesResult = {};

  const _features = features || [];

  for (const feature of _features) {
    if (feature == null) {
      continue;
    }

    switch (feature.type) {
      case FeatureNameEnum.Cancelable:
        result.cancelableFeature = feature.feature as CancelableFeatureProperties;
        break;
      case FeatureNameEnum.Entries:
        result.entriesFeature = feature.feature as EntriesFeatureProperties;
        break;
      case FeatureNameEnum.EssensysProduct:
        result.essensysProductFeature = feature.feature as EssensysProductFeatureProperties;
        break;
      case FeatureNameEnum.Deliverable:
        result.deliverableFeature = feature.feature;
        break;
      case FeatureNameEnum.Inventory:
        result.inventoryFeature = feature.feature;
        break;
      case FeatureNameEnum.Payment:
        result.paymentFeature = feature.feature as PaymentFeatureProperties;
        break;
      case FeatureNameEnum.QRCodeCheckin:
        result.qrCodeCheckinFeature = feature.feature as QRCodeCheckinFeatureProperties;
        break;
      case FeatureNameEnum.Quantity:
        result.quantityFeature = feature.feature as QuantityFeatureProperties;
        break;
      case FeatureNameEnum.RemoteFetch:
        result.remoteFetchFeature = feature.feature;
        break;
      case FeatureNameEnum.Reservable:
        result.reservableFeature = feature.feature as ReservableFeatureProperties;
        break;
      case FeatureNameEnum.Reset:
        result.resetFeature = feature.feature;
        break;
      case FeatureNameEnum.Scheduled:
        result.scheduledFeature = feature.feature as ScheduledFeatureProperties;
        break;
      case FeatureNameEnum.Shipping:
        result.shippingFeature = feature.feature;
        break;
      case FeatureNameEnum.SocialOptions:
        result.socialOptionsFeature = feature.feature as SocialOptionsFeatureProperties;
        break;
      case FeatureNameEnum.Statuses:
        result.statusesFeature = feature.feature as StatusesFeatureProperties;
        break;
      case FeatureNameEnum.TimeAvailability:
        result.timeAvailabilityFeature = feature.feature as TimeAvailabilityFeatureProperties;
        break;
      case FeatureNameEnum.SubmitOnBehalfOf:
        result.submitOnBehalfOfFeature = feature.feature;
        break;
      case FeatureNameEnum.UseCompanyPermissions:
        result.useCompanyPermissionsFeature = feature.feature as UseCompanyPermissionsFeatureProperties;
        break;
      case FeatureNameEnum.Menu:
        result.menuFeature = feature.feature as MenuFeatureProperties;
        break;
      case FeatureNameEnum.GuestInvite:
        result.guestInviteFeature = feature.feature as GuestInviteFeatureProperties;
        break;
      case FeatureNameEnum.OutlookCalendar:
        result.outlookCalendarFeature = feature.feature as OutlookCalendarFeatureProperties;
        break;
      case FeatureNameEnum.GoogleCalendar:
        result.googleCalendarFeature = feature.feature as GoogleCalendarFeatureProperties;
        break;
      case FeatureNameEnum.Requirements:
        result.requirementsFeature = feature.feature as RequirementsFeatureProperties;
        break;
      case FeatureNameEnum.VisitorManagement:
        result.visitorManagementFeature = feature.feature as VisitorManagementFeatureProperties;
        break;
      case FeatureNameEnum.Surveys:
        result.surveysFeature = feature.feature as SurveysFeatureProperties;
        break;
      default:
        break;
    }
  }

  return result;
}
