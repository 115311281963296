import { mapValues } from 'lodash';

import { request, Res } from './common';
import { FieldType } from './issueTypes';
import { Form } from './issueTypesJsonForms';

export interface ListParams {
  building_scope?: string;
  limit?: number;
  offset?: number;
  ordering?: string;
  timestamp?: number;
}

export interface ListResData {
  count: number;
  next?: URLSearchParams;
  results: WorkOrder[];
}

export enum WorkOrderStatusTypes {
  New = 'new',
  Open = 'open',
  OnHold = 'onhold',
  Cancelled = 'cancelled',
  Rejected = 'rejected',
  Completed = 'completed',
  Closed = 'closed',
}
export interface WorkOrder {
  id: string;
  assignee?: Person;
  assignee_id?: string;
  created_at: string;
  assigned_at?: string;
  updated_at?: string;
  custom_fields: CustomField[];
  description: string;
  identifier: string;
  issue_type: {
    id: string;
    description: string;
    name: string;
    issue_category: string;
  };
  priority: Priority;
  requester?: Person; // Maybe non-null?
  space?: {
    floor: { name: string };
    name: string;
  };
  status: WorkOrderStatusTypes;
  building: Building;
  /** UUID for new serdy form */
  on_new_serdy_form: string | null;
  /** UUID for completed serdy form */
  on_complete_serdy_form: string | null;
}

export enum Priority {
  Urgent = 1,
  High = 2,
  Medium = 3,
  Low = 4,
}

export interface Building {
  id: string;
  name: string;
}

interface Person {
  id: string;
  name: string;
  image_url?: string | null;
}
interface ICustomField {
  id: string;
  field: {
    display_name: string;
  };
}

interface GenericCustomField extends ICustomField {
  data_type:
    | FieldType.Choice
    | FieldType.Date
    | FieldType.Number
    | FieldType.Phone
    | FieldType.TextArea
    | FieldType.TextField;
  value: unknown;
}

interface CurrencyCustomField extends ICustomField {
  data_type: FieldType.Currency;
  value: { amount: number; amount_currency: string } | null;
}

export type CustomField = GenericCustomField | CurrencyCustomField;

export interface CreateData {
  building_id: string;
  description: string;
  issue_type_id: string;
  space_id: string;
  on_new_serdy_form: Form | null;
  default_assignment?: boolean;

  // In TS 4.4+, can do:
  //     [key: `question_${number}_${number}`]: unknown;
  // since we only want dynamic keys of the form question_0_0
  [key: string]: unknown;
}

export interface CreateError {
  on_new_serdy_form?: { [key: string]: string }; // Errors from custom form fields
  [key: string]: unknown;
}

export async function list(
  token: string,
  params: ListParams | URLSearchParams
): Promise<Res<ListResData>> {
  const res = await request({
    token,
    method: 'get',
    path: '/work_orders',
    params:
      params instanceof URLSearchParams
        ? params
        : mapValues(params, x => String(x)),
  });
  const data = await res.data;

  if (data.next) {
    // We assume only the params differs in the next URL
    const url = new URL(data.next);

    data.next = url.searchParams;
  }

  return res;
}

export async function create(
  token: string,
  data: CreateData
): Promise<Res<WorkOrder, CreateError>> {
  return request({
    token,
    method: 'post',
    path: '/work_orders',
    data,
  });
}

/**
 *
 * @param extraParams is required when changing status to onhold
 */
export async function updateStatus(
  token: string,
  workOrderId: string,
  status: WorkOrderStatusTypes,
  extraParams?: object
): Promise<Res<WorkOrder>> {
  return request({
    token,
    method: 'post',
    path: `/work_orders/${workOrderId}/status`,
    data: { status, ...extraParams },
  });
}
