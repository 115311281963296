import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CreditCardType } from 'lane-shared/types/Stripe';
import isStripeCreditCard from 'lane-shared/types/type-guards/isStripeCreditCard';

import Button from 'components/general/Button';
import StripePaymentMethod from 'components/payments/paymentAccountManagement/StripePaymentMethod';

import styles from './PaymentFeatureStripePaymentInformation.scss';

type Props<TPaymentMethod> = {
  selectedPaymentMethod?: TPaymentMethod;
  paymentMethods: TPaymentMethod[];
  onAddCard: () => void;
  onRemoveCard?: (
    paymentAccountId: string | undefined,
    paymentMethod: CreditCardType
  ) => Promise<void>;
  setSelectedPaymentMethod: (paymentMethod: CreditCardType) => void;
  onNoPaymentMethods: () => Promise<void>;
};

export default function PaymentFeatureStripePaymentInformation<TPaymentMethod>({
  selectedPaymentMethod,
  paymentMethods,
  onAddCard,
  onRemoveCard,
  setSelectedPaymentMethod,
  onNoPaymentMethods,
}: Props<TPaymentMethod>) {
  const { t } = useTranslation();

  if (paymentMethods.length === 0) {
    // They removed all their cards from this screen,
    // they should be redirected.
    onNoPaymentMethods();
  }

  const stripePaymentMethods: CreditCardType[] = [];

  paymentMethods.forEach(paymentMethod => {
    if (isStripeCreditCard(paymentMethod)) {
      stripePaymentMethods.push(paymentMethod);
    }
  });

  return (
    <div className={cx(styles.stripePaymentInformation)}>
      <div className={cx(styles.stripeCreditCards)} data-test="paymentMethods">
        {stripePaymentMethods.map((paymentMethod: CreditCardType) => (
          <StripePaymentMethod
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            selected={
              isStripeCreditCard(selectedPaymentMethod) &&
              selectedPaymentMethod?.id === paymentMethod.id
            }
            onSelectPaymentMethod={setSelectedPaymentMethod}
            onRemovePaymentMethod={onRemoveCard}
            selectable
          />
        ))}
      </div>
      <Button
        onClick={onAddCard}
        variant="contained"
        className={cx(styles.addCardButton)}
        style={{}}
        startIcon={<FontAwesomeIcon icon={faPlus} />}
      >
        {t('Add a card')}
      </Button>
    </div>
  );
}
