import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './ReceiptPaymentMethod.scss';

import { M } from 'components/typography';

type Props = {
  paymentSource?: {
    brand: string;
    last4: string;
  };
};

export function ReceiptPaymentMethod({ paymentSource }: Props) {
  const { t } = useTranslation();

  const cardBrand = paymentSource?.brand
    ? (paymentSource?.brand ?? '').charAt(0).toUpperCase() +
      (paymentSource?.brand ?? '').slice(1)
    : 'Visa';

  const cardDetails = `${cardBrand} **** **** **** ${paymentSource?.last4}`;

  return (
    <div>
      {paymentSource?.last4 && (
        <div className={styles.paymentDetails}>
          <M>
            {t(
              'shared.content.interaction.receipt.payment.summary.paymentMethod'
            )}
          </M>
          <M className={styles.cardDetails}>{cardDetails}</M>
        </div>
      )}
    </div>
  );
}
