import React, { useCallback, useMemo } from 'react';

import { Icon } from 'design-system-web';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { LaneType } from 'common-types';
import { arrayReorder } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { explodeValidators } from 'lane-shared/helpers/properties';
import { byOrder } from 'lane-shared/helpers/sort';
import { useCurrentChannel } from 'lane-shared/hooks';
import Option, {
  OPTION_NAME_MAXLENGTH,
  OPTION_VALUE_MAXLENGTH,
} from 'lane-shared/properties/baseTypes/Option';
import { ContentWorkflowType } from 'lane-shared/types/ContentWorkflowType';
import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';
import { PropertyType } from 'lane-shared/types/properties/Property';

import { IconButton, Tooltip } from 'components/general';
import { MultiLanguageWrapper } from 'components/general/MultiLanguageWrapper';
import {
  constructDefaultWorkflowToDataInputOption,
  getConditionalWorkflows,
} from 'components/workflows/helpers/index';

import ValidatedInput from '../../../../form/ValidatedInput';
import Label from '../../../../general/Label';
import { FRIENDLY_PROPERTY_TEXTS } from './constants';

import styles from './FriendlyPropertyCreate.scss';

type FriendlyPropertyOptionsCreateProps = {
  content?: { _id: LaneType.UUID; actions?: ContentWorkflowType[] };
  property: PropertyType;
  user?: { _id: LaneType.UUID; isSuperUser: boolean };
  onPropertyUpdated: (property: Partial<PropertyType>) => void;
  onContentUpdated: (content: object) => void;
};

export default function FriendlyPropertyOptionsCreate({
  user,
  property,
  onPropertyUpdated,
  onContentUpdated,
  content,
}: FriendlyPropertyOptionsCreateProps) {
  const { t } = useTranslation();
  const channel = useCurrentChannel();
  // explode additional validations that are required for correct ooption handling
  const { inValidator, arrayMaxValidator } = explodeValidators(
    property?.validators
  );

  const supportsPropertyDependency =
    property?.packagedType === PackagedTypeEnum.Dropdown;

  const actions = useMemo(() => (content?.actions || []).sort(byOrder), [
    content?.actions,
  ]);

  const updateWorkflow = useCallback(
    (id: string, update: Partial<ContentWorkflowType>) => {
      if (content?.actions) {
        const index = content.actions.findIndex(
          (workflow: ContentWorkflowType) => workflow._id === id
        );

        const originalWorkflow = content.actions[index];

        if (originalWorkflow) {
          content.actions[index] = { ...originalWorkflow, ...update };
          onContentUpdated({
            actions: [...actions],
          });
        }
      }
    },
    [content?.actions]
  );

  function addOption() {
    if (inValidator) {
      inValidator.value = [
        ...inValidator?.value,
        { _id: uuid(), name: '', value: '' },
      ];
    }

    onPropertyUpdated({
      // @ts-expect-error ts-migrate(2488) FIXME: Type 'Validator<any>[] | undefined' must have a '[... Remove this comment to see the full error message
      validators: [...property.validators],
    });
  }

  function updateOption(option: any, props: any) {
    if (inValidator) {
      const ix = inValidator?.value.findIndex(({ _id }) => option._id === _id);

      inValidator.value[ix] = { ...option, ...props };
    }

    onPropertyUpdated({
      validators: [...property.validators],
    });
  }

  function removeOption(option: LaneType.Option) {
    if (inValidator) {
      inValidator.value = inValidator?.value.filter(
        ({ _id }) => option._id !== _id
      );
    }

    // if this has an arrayMaxValidation, update that value.
    if (
      arrayMaxValidator &&
      inValidator?.value &&
      arrayMaxValidator?.value > inValidator?.value?.length
    ) {
      arrayMaxValidator.value = inValidator?.value.length;
    }

    onPropertyUpdated({
      validators: [...property.validators],
    });

    const relatedWorkflows = getConditionalWorkflows(actions, option);

    // Remove related workflow if required
    if (relatedWorkflows.length > 0) {
      const updatedActions = actions.filter(action => {
        const relatedWorkflow = relatedWorkflows.find(
          workflow => workflow._id === action._id
        );

        // We only want to remove workflows that are have one
        // data-validation rule linked to this option, the rest we keep.
        const hasMultipleRules =
          relatedWorkflow?.dataValidationSchema?.length !== 1;

        return hasMultipleRules;
      });

      onContentUpdated({
        actions: updatedActions,
      });

      window.Toast.show(
        `Removed ${relatedWorkflows.length} attached workflows`
      );
    }
  }

  function attachWorkflowToDataInputOption(
    option: LaneType.Option,
    numberOfWorkflowsForOption: number
  ) {
    const workflowName = `${property.friendlyName} + ${option.name} - ${numberOfWorkflowsForOption}`;
    const retDefaultWorkflow: ContentWorkflowType = constructDefaultWorkflowToDataInputOption(
      {
        user,
        content,
        property,
        option,
      }
    );

    actions.push(retDefaultWorkflow);

    updateWorkflow(retDefaultWorkflow._id, {
      name: workflowName,
    });

    window.Toast.show(`Created a new workflow`);
  }

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    if (inValidator) {
      inValidator.value = arrayReorder(
        inValidator.value,
        result.source.index,
        result.destination.index
      );
    }

    onPropertyUpdated({
      validators: [...property.validators],
    });
  }

  return (
    <fieldset className={styles.field} data-test="dropdown-options">
      <Label h2 TooltipComponent={t(FRIENDLY_PROPERTY_TEXTS.tooltip.options)}>
        {t(FRIENDLY_PROPERTY_TEXTS.label.options)}
        <Icon
          name="plus-circle"
          set={ICON_SET_FONTAWESOME}
          className={styles.addIcon}
          onClick={addOption}
        />
      </Label>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="options">
          {provided => (
            <ul className={styles.options} ref={provided.innerRef}>
              {inValidator?.value?.map((option, index: number) => {
                const numberOfWorkflows = getConditionalWorkflows(
                  actions,
                  option
                ).length;

                return (
                  <Draggable
                    key={option._id}
                    draggableId={option._id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <li
                        key={option._id}
                        ref={provided.innerRef}
                        data-is-dragging={snapshot.isDragging}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Icon
                          name="bars"
                          set={ICON_SET_FONTAWESOME}
                          className={styles.iconReorder}
                        />
                        <MultiLanguageWrapper
                          channel={channel}
                          model={option}
                          column="name"
                          className={styles.input}
                        >
                          {({
                            isPrimary,
                            stringLabelMaker,
                            valueGetter,
                            onChangeUpdates,
                          }) => {
                            const label = t(
                              FRIENDLY_PROPERTY_TEXTS.label.optionLabel
                            );

                            return (
                              /* show primary language field's label inline  and other language's field's label outside of the input (above) */
                              <>
                                {isPrimary
                                  ? undefined
                                  : stringLabelMaker(label)}
                                <ValidatedInput
                                  label={
                                    isPrimary
                                      ? stringLabelMaker(label)
                                      : undefined
                                  }
                                  value={valueGetter()}
                                  testId={
                                    isPrimary
                                      ? 'Display Text'
                                      : 'Display Text_l10n'
                                  }
                                  placeholder={t(
                                    FRIENDLY_PROPERTY_TEXTS.placeholder
                                      .optionLabel
                                  )}
                                  validation={
                                    isPrimary
                                      ? Option.buildSchema().fields?.name
                                      : Option.buildSchema().fields?.name_l10n
                                  }
                                  maxLength={OPTION_NAME_MAXLENGTH}
                                  onChange={name => {
                                    const updates = onChangeUpdates(name);

                                    updateOption(option, updates);
                                  }}
                                />
                              </>
                            );
                          }}
                        </MultiLanguageWrapper>
                        <ValidatedInput
                          className={styles.input}
                          label={FRIENDLY_PROPERTY_TEXTS.label.optionValue}
                          value={option?.value}
                          testId="Value"
                          placeholder={t(
                            FRIENDLY_PROPERTY_TEXTS.placeholder.optionValue
                          )}
                          validation={Option.buildSchema().fields?.value}
                          maxLength={OPTION_VALUE_MAXLENGTH}
                          onChange={value => updateOption(option, { value })}
                        />
                        <Icon
                          className={styles.removeIcon}
                          style={{ marginLeft: '1em' }}
                          name="times-circle"
                          set={ICON_SET_FONTAWESOME}
                          onClick={() => removeOption(option)}
                        />
                        {supportsPropertyDependency && (
                          <Tooltip
                            TooltipComponent={
                              getConditionalWorkflows(actions, option).length >
                              0 ? (
                                <div>
                                  <ul>
                                    {getConditionalWorkflows(
                                      actions,
                                      option
                                    ).map(workflow => (
                                      <li key={workflow._id}>
                                        {workflow.name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              ) : (
                                t(
                                  FRIENDLY_PROPERTY_TEXTS.tooltip
                                    .optionPropertyDependency
                                )
                              )
                            }
                            placement="right"
                          >
                            <IconButton
                              icon="plus-circle"
                              testId="add-workflow-circle"
                              onClick={() => {
                                attachWorkflowToDataInputOption(
                                  option,
                                  numberOfWorkflows + 1
                                );
                              }}
                              className={styles.addIcon}
                              style={{ marginLeft: '1em' }}
                              text={`${numberOfWorkflows}`}
                              size="small"
                            />
                          </Tooltip>
                        )}
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </fieldset>
  );
}
