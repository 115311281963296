import React, { useCallback, useState, useRef, useEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { createEditor, Editor, Text, Transforms, Range, Element } from 'slate';
import { withHistory } from 'slate-history';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react';

import {
  RichTextAlignEnum,
  RichTextBlockEnum,
  RichTextMarksEnum,
  RichTextToolsEnum,
} from 'lane-shared/properties/baseTypes/RichText';

import LinkEditor from './LinkEditor';
import defaultValue from './RichText.default.json';
import { countChars, withCharLimit } from './withCharLimit';
import withLinks, { unwrapLink, wrapLink } from './withLinks';

import styles from './styles.scss';

const NoFormat = 'remove-format';

RichText.defaultValue = defaultValue;

export default function RichText({
  className,
  inputClassName,
  toolBarClassName,
  style,
  value = null,
  toolbar = RichText.toolbar,
  onChange = () => null,
  maxCharacters = null,
  testId,
  readOnly = false,
}: any) {
  const [count, setCount] = useState(0);
  const [editor] = useState(() =>
    withCharLimit(
      withLinks(withHistory(withReact(createEditor()))),
      maxCharacters
    )
  );

  const lastSelectionRef = useRef<Range | null>(null);
  // just forces the UI to update when the user selects a node.
  const [, setLastRender] = useState<number>(Date.now());

  useEffect(() => {
    setCount(countChars(editor));
  }, [value]);

  const renderCharLimitCounter = () => {
    return `${count}/${maxCharacters}`;
  };

  const hasMark = useCallback((mark: string): boolean => {
    const [match] = Editor.nodes(editor, {
      match: n => n[mark] === true,
      universal: true,
    });

    return !!match;
  }, []);

  const hasBlock = useCallback((type: string): boolean => {
    const [match] = Editor.nodes(editor, {
      match: n => n.type === type,
    });

    return !!match;
  }, []);

  const hasAlign = useCallback((type: string): boolean => {
    const [match] = Editor.nodes(editor, {
      match: n => n?.align === type,
    });

    return !!match;
  }, []);

  function onClickMark(e: any, type: any) {
    e.preventDefault();

    const isActive = hasMark(type);

    Transforms.setNodes(
      editor,
      { [type]: isActive ? null : true },
      { match: n => Text.isText(n), split: true }
    );
  }

  function updateLink(url: URL, element: Element) {
    Transforms.setNodes(
      editor,
      { url },
      {
        at: ReactEditor.findPath(editor, element),
        match: n => n.type === RichTextBlockEnum.Link,
      }
    );
  }

  function removeLink() {
    editor.selection = lastSelectionRef.current;
    unwrapLink(editor);
  }

  function editableClicked() {
    // we will need this if the user moves off focus on the edit component
    lastSelectionRef.current = editor.selection;
    setLastRender(Date.now());
  }

  function onClickBlock(e: any, type: any) {
    e.preventDefault();

    const isActive = hasBlock(type) || hasAlign(type);

    if (type === RichTextBlockEnum.Link) {
      if (isActive) {
        unwrapLink(editor);

        return;
      }

      wrapLink(editor, 'http://example.com');

      return;
    }

    if (Object.values(RichTextAlignEnum).includes(type)) {
      Transforms.setNodes(
        editor,
        { align: isActive ? null : type },
        { match: n => Editor.isBlock(editor, n) }
      );

      return;
    }

    Transforms.setNodes(
      editor,
      { type: isActive ? null : type },
      { match: n => Editor.isBlock(editor, n) }
    );
  }

  function onClickClear(e: any) {
    e.preventDefault();
    Object.values(RichTextMarksEnum).forEach(type =>
      Transforms.setNodes(
        editor,
        { [type]: false },
        { match: n => Text.isText(n) }
      )
    );
    ['align', 'type'].forEach(key =>
      Transforms.setNodes(
        editor,
        { [key]: null },
        { match: n => Editor.isBlock(editor, n) }
      )
    );
  }

  function renderClearButton({ type, icon }: any) {
    return (
      <button
        key={type}
        className={
          readOnly ? styles.toolbarButtonDisabled : styles.toolbarButton
        }
        disabled={readOnly}
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        onClick={e => onClickClear(e, type)}
      >
        <Icon name={icon} />
      </button>
    );
  }

  function renderMarkButton({ type, icon }: any) {
    const isActive = hasMark(type);

    return (
      <button
        key={type}
        data-is-active={isActive}
        className={
          readOnly ? styles.toolbarButtonDisabled : styles.toolbarButton
        }
        disabled={readOnly}
        onClick={e => onClickMark(e, type)}
      >
        <Icon name={icon} />
      </button>
    );
  }

  // Moves the selector back to the start of the node on leaving focus. This is important as a race condition occurs as
  // we swap between two editors. If one is has more nodes than the other, this result in selector having an invalid position
  // resulting in a crash
  function moveSelectionToStart() {
    lastSelectionRef.current = editor.selection;
    Transforms.select(editor, Editor.start(editor, []));
  }

  function restoreSelection() {
    editor.selection = lastSelectionRef.current;
    lastSelectionRef.current = editor.selection;
  }

  function renderBlockButton({ type, icon }: any) {
    const isActive = hasBlock(type) || hasAlign(type);

    return (
      <button
        key={type}
        className={
          readOnly ? styles.toolbarButtonDisabled : styles.toolbarButton
        }
        disabled={readOnly}
        data-is-active={isActive}
        onClick={e => onClickBlock(e, type)}
      >
        <Icon name={icon} />
      </button>
    );
  }

  const renderLeaf = useCallback((props: RenderLeafProps) => {
    return (
      <span
        {...props.attributes}
        style={{
          fontWeight: props.leaf.bold ? 'bold' : 'normal',
          textDecoration: props.leaf.underlined ? 'underline' : 'none',
          fontStyle: props.leaf.italic ? 'italic' : 'normal',
        }}
      >
        {props.children}
      </span>
    );
  }, []);

  const renderElement = useCallback(function (props: RenderElementProps) {
    const { attributes, children, element } = props;

    const alignStyle = { textAlign: element?.align || RichTextAlignEnum.left };

    switch (props.element.type) {
      case RichTextBlockEnum.BlockQuote:
        return (
          // @ts-expect-error
          <blockquote style={alignStyle} {...attributes}>
            {children}
          </blockquote>
        );
      case RichTextBlockEnum.BulletedList:
        return <ul {...attributes}>{children}</ul>;
      case RichTextBlockEnum.H1:
        return (
          // @ts-expect-error
          <h1 style={{ ...alignStyle, fontSize: '2em' }} {...attributes}>
            {children}
          </h1>
        );
      case RichTextBlockEnum.H2:
        return (
          // @ts-expect-error
          <h2 style={alignStyle} {...attributes}>
            {children}
          </h2>
        );
      case RichTextBlockEnum.H3:
        return (
          // @ts-expect-error
          <h3 style={{ ...alignStyle, fontSize: '1.25em' }} {...attributes}>
            {children}
          </h3>
        );
      case RichTextBlockEnum.H4:
        return (
          // @ts-expect-error
          <h4 style={{ ...alignStyle, fontSize: '1.1em' }} {...attributes}>
            {children}
          </h4>
        );
      case RichTextBlockEnum.H5:
        return (
          // @ts-expect-error
          <h5 style={alignStyle} {...attributes}>
            {children}
          </h5>
        );
      case RichTextBlockEnum.H6:
        return (
          // @ts-expect-error
          <h6 style={alignStyle} {...attributes}>
            {children}
          </h6>
        );
      case RichTextBlockEnum.ListItem:
        return <li {...attributes}>{children}</li>;
      case RichTextBlockEnum.NumberedList:
        return <ol {...attributes}>{children}</ol>;
      case RichTextBlockEnum.Code:
        return (
          // @ts-expect-error
          <code style={alignStyle} {...attributes}>
            {children}
          </code>
        );
      case RichTextBlockEnum.Link:
        return (
          <LinkEditor
            {...attributes}
            element={element}
            onClear={removeLink}
            onChange={updateLink}
          >
            {children}
          </LinkEditor>
        );
      case RichTextBlockEnum.Paragraph:
      default:
        return (
          // @ts-expect-error
          <p style={{ ...alignStyle, margin: 0 }} {...attributes}>
            {children}
          </p>
        );
    }
  }, []);

  return (
    <div
      className={cx(styles.RichText, className)}
      style={style}
      data-test={testId}
    >
      <div className={inputClassName}>
        <div
          onBlur={moveSelectionToStart}
          onFocus={restoreSelection}
          className={cx(
            readOnly ? styles.ToolbarDisabled : styles.Toolbar,
            toolBarClassName
          )}
        >
          {toolbar.map(({ type, icon, toolType }: any) => {
            switch (toolType) {
              case RichTextToolsEnum.Mark:
                return renderMarkButton({ type, icon });
              case RichTextToolsEnum.Block:
                return renderBlockButton({ type, icon });
              case RichTextToolsEnum.Clear:
                return renderClearButton({ type, icon });
              default:
                return null;
            }
          })}
        </div>

        <Slate
          editor={editor}
          value={
            value || [
              {
                type: 'paragraph',
                children: [
                  {
                    text: '',
                  },
                ],
              },
            ]
          }
          onChange={newValue => onChange(newValue)}
        >
          <Editable
            data-test="bodyText"
            className={styles.editArea}
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            onBlur={moveSelectionToStart}
            onClick={_e => editableClicked()}
            readOnly={readOnly}
          />
        </Slate>
      </div>
      {maxCharacters && (
        <span className={styles.charCounter}>{renderCharLimitCounter()}</span>
      )}
    </div>
  );
}

RichText.toolbar = [
  {
    type: RichTextMarksEnum.Bold,
    icon: 'bold',
    toolType: RichTextToolsEnum.Mark,
  },
  {
    type: RichTextMarksEnum.Italic,
    icon: 'italic',
    toolType: RichTextToolsEnum.Mark,
  },
  {
    type: RichTextMarksEnum.Underlined,
    icon: 'underline',
    toolType: RichTextToolsEnum.Mark,
  },
  {
    type: RichTextBlockEnum.Link,
    icon: 'link',
    toolType: RichTextToolsEnum.Block,
  },
  { type: NoFormat, icon: 'remove-format', toolType: RichTextToolsEnum.Clear },
  { type: RichTextBlockEnum.H1, icon: 'h1', toolType: RichTextToolsEnum.Block },
  { type: RichTextBlockEnum.H2, icon: 'h2', toolType: RichTextToolsEnum.Block },
  { type: RichTextBlockEnum.H3, icon: 'h3', toolType: RichTextToolsEnum.Block },
  { type: RichTextBlockEnum.H4, icon: 'h4', toolType: RichTextToolsEnum.Block },
  {
    type: RichTextAlignEnum.left,
    icon: 'align-left',
    toolType: RichTextToolsEnum.Block,
  },
  {
    type: RichTextAlignEnum.center,
    icon: 'align-center',
    toolType: RichTextToolsEnum.Block,
  },
  {
    type: RichTextAlignEnum.right,
    icon: 'align-right',
    toolType: RichTextToolsEnum.Block,
  },
];
