import React from 'react';
import { H4, P, Button, Flex, Modal, IconButton } from 'design-system-web';

import styles from './ConfirmationModal.scss';
import { FONT_AWESOME_REGULAR } from 'lane-shared/helpers/constants/icons';

export function ConfirmationModal({
  isOpen,
  onClose,
  handleConfirm,
  title,
  description,
  cancelText,
  confirmText,
  testId,
  children,
}: {
  isOpen: boolean;
  onClose: Function;
  handleConfirm: Function;
  title: string;
  description?: string;
  cancelText: string;
  confirmText: string;
  testId?: string;
  children?: React.ReactNode;
}) {
  const hasDescription = Boolean(description);

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={() => onClose()}
      title={title}
      testId={testId}
      includeHeader={false}
      isCloseButtonHidden
      disableContentPadding
    >
      <Flex
        className={styles.modalHeader}
        justify="space-between"
        align="center"
      >
        <H4>{title}</H4>
        <IconButton
          type={FONT_AWESOME_REGULAR}
          onClick={() => onClose()}
          icon="times"
          data-test="closeButton"
          size="medium"
        />
      </Flex>

      <Flex className={styles.modalBody} direction="column" gap={4}>
        {hasDescription && <P className={styles.Description}>{description}</P>}
        {children}
      </Flex>

      <hr className={styles.divider} />

      <Flex className={styles.modalActions} gap={3}>
        <Button variant="primary" size="large" onClick={() => handleConfirm()}>
          {confirmText}
        </Button>
        <Button variant="secondary" size="large" onClick={() => onClose()}>
          {cancelText}
        </Button>
      </Flex>
    </Modal>
  );
}
