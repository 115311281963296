import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query findSchedules(
    $refIds: [String]!
    $search: FindSchedulesSearch
    $pagination: PaginationInput
    $filter: FindSchedulesFilter
    $forExport: Boolean
    $includeArchived: Boolean
  ) {
    findSchedules(
      refIds: $refIds
      search: $search
      pagination: $pagination
      filter: $filter
      forExport: $forExport
      includeArchived: $includeArchived
    ) {
      schedules {
        scheduleId
        extRefId {
          name
          id
        }
        title
        location
        nextDueDate
        untilDate
        repeats
        daysAhead
        weekday
        weekNo
        monthNo
        interval
        assignee {
          id
          name
        }
        equipmentIds
        notes
        timeToComplete
        completeWithin
        steps
        meterReading
        createdBy
        updatedBy
        createdAt
        updatedAt
        isArchived
        attachments
        assigneeGroups
      }
      pageInfo {
        start
        total
        perPage
      }
      filterOptions {
        assignees {
          _id
          name
          profile {
            name
          }
        }
        equipments {
          name
          id
        }
        locations
        recurrences
      }
    }
  }
`);
