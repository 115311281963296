import { BlockInstanceType } from '../../types/blocks/BlockInterface';
import { ContentType } from '../../types/content/Content';
import { FeatureNameEnum } from '../../types/features/FeatureNameEnum';

export default function getVisibleContentData(content?: ContentType | null) {
  const result: {
    features: (string | FeatureNameEnum)[];
    data: string[];
  } = {
    features: [],
    data: [],
  };

  if (!content?.block) {
    return undefined;
  }

  const getVisibleFields = (children: BlockInstanceType[]) => {
    children.forEach((child: any) => {
      if (child?.children && child?.children.length) {
        // some data fields may be nested so we need to iterate over them too
        getVisibleFields(child?.children);
      } else if (Array.isArray(child?.properties?.children)) {
        getVisibleFields(child.properties.children);
      } else if (child?.properties?.featureKey) {
        result.features.push(child?.properties.featureKey);
      } else if (child?.properties?.propertyKey) {
        result.data.push(child?.properties.propertyKey);
      }
    });
  };

  if (content && Array.isArray(content?.block?.properties?.children)) {
    getVisibleFields(content.block.properties.children);
  }

  return result;
}
