import React, { useState } from 'react';

import cx from 'classnames';
import {
  Button,
  DatePickerButton,
  Input,
  DatePickerButtonProps,
} from 'design-system-web';

import { useTranslation } from 'react-i18next';

import { Flex } from 'components/layout';
import { H5 } from 'components/typography';

import { MultiLanguageWrapper } from 'components/general/MultiLanguageWrapper';
import { useCurrentChannel } from 'lane-shared/hooks';
import { ContentNotificationType } from 'lane-shared/types/content/DraftContent';
import { LocalizationColumnType } from 'lane-shared/types/LocalizationColumnType';

import type { PillColor } from 'lane-shared/types/general/pill';
import Pill from 'components/general/Pill';
import tokens from '@viewthespace/style-dictionary/assets/json/tokens.json';
import IconButton from 'components/general/IconButton';

import styles from './DatePickerCard.scss';

type DatePickerCardProps = {
  onChange: ({
    date,
    updates,
    id,
  }: {
    date: Date | null;
    updates?: LocalizationColumnType;
    id?: string;
  }) => void;
  id?: string;
  datePickerButtonProps?: Partial<DatePickerButtonProps>;
  dateError?: string[];
  timeError?: string[];
  dateValue?: Date | null;
  messageLabel?: string;
  messageError?: string[];
  messageValue?: string;
  deletable?: boolean;
  onDelete?: (id?: string) => void;
  className?: string;
  style?: React.CSSProperties;
  inputMaxLength?: number;
  notification: ContentNotificationType;
};

const TRANSLATION_KEYS = {
  removeButton: 'web.components.cards.DatePickerCard.removeButton',
  selectDate: 'web.components.cards.DatePickerCard.selectDate',
  selectTime: 'web.components.cards.DatePickerCard.selectTime',
  deleteButton: 'web.components.cards.DatePickerCard.deleteButton',
};

export const DatePickerCard = ({
  onChange = () => {},
  id,
  datePickerButtonProps,
  messageLabel = 'Message',
  deletable = false,
  onDelete,
  className = '',
  style = {},
  dateError,
  timeError,
  dateValue,
  messageError,
  inputMaxLength = 200,
  notification,
}: DatePickerCardProps) => {
  const channel = useCurrentChannel();
  const [datePickerValue, updateDatePickerValue] = useState<Date | null>(
    dateValue || null
  );
  const { t } = useTranslation();

  const pillProps: {
    color: PillColor;
    text: string;
    iconName: string;
    iconColor: string;
  } = {
    color: 'gray',
    text: 'Draft',
    iconName: 'circle',
    iconColor: tokens.color.general.gray.secondary,
  };

  return (
    <Flex
      p={6}
      style={style}
      direction="column"
      className={cx(styles.container, className)}
    >
      <Flex
        direction="row"
        justify="space-between"
        align="center"
        className={styles.notificationTitleContainer}
      >
        <Flex direction="row" align="center">
          <IconButton icon="Bell" size="small" className={styles.iconButton} />
          <H5 className={styles.notificationText}>
            {t(
              'web.components.lane.DraftContent.DraftContentNotifications.editNotification'
            )}
          </H5>
        </Flex>
        <Pill size="medium" {...pillProps} />
      </Flex>
      <Flex
        direction="row"
        justify="space-between"
        className={styles.dateTimeContainer}
      >
        <Flex direction="row" className={styles.dateTimeSection}>
          <DatePickerButton
            dateInputLabel={t(TRANSLATION_KEYS.selectDate)}
            timeInputLabel={t(TRANSLATION_KEYS.selectTime)}
            fixedLabel
            dateError={dateError}
            timeError={timeError}
            {...datePickerButtonProps}
            includeTime
            value={datePickerValue}
            className={styles.datePicker}
            wrapperClassName={styles.datePickerWrapper}
            buttonClassName={styles.datePickerButton}
            onChange={(date: Date) => {
              updateDatePickerValue(date);

              if (
                datePickerButtonProps &&
                typeof datePickerButtonProps.onChange === 'function'
              ) {
                datePickerButtonProps.onChange(date);
              }

              onChange({ date, id });
            }}
          />
        </Flex>
      </Flex>
      <Flex
        direction="column"
        justify="space-between"
        className={styles.inputSection}
      >
        <MultiLanguageWrapper
          channel={channel}
          model={notification}
          column="title"
        >
          {({ labelMaker, isPrimary, onChangeUpdates, valueGetter }) => (
            <div>
              <H5>
                {labelMaker({
                  label: t(messageLabel),
                  required: true,
                })}
              </H5>
              <Flex>
                <Input
                  value={valueGetter()}
                  testId={
                    isPrimary
                      ? 'notificationMessage'
                      : 'notificationMessage_l10n'
                  }
                  onChange={title => {
                    const updates = onChangeUpdates(title);

                    onChange({ date: datePickerValue, updates, id });
                  }}
                  maxLength={inputMaxLength}
                  showLengthIndicator
                  error={isPrimary ? messageError : undefined}
                />
              </Flex>
            </div>
          )}
        </MultiLanguageWrapper>
      </Flex>
      {!!deletable && (
        <Flex direction="row" justify="flex-start">
          <Button
            className={styles.removeButton}
            testId="removeButton"
            onClick={() => !!onDelete && onDelete(id)}
          >
            {t(TRANSLATION_KEYS.deleteButton)}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
