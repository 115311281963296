import React, { useState, useContext } from 'react';

import { Icon } from 'design-system-web';
import { Button, ErrorMessage, ValidatedInput } from 'components';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext } from 'lane-shared/contexts';
import { updateUser } from 'lane-shared/graphql/user';
import { pause } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { useValidation } from 'lane-shared/hooks';
import validateFullName from 'lane-shared/validation/validateFullName';

import { H3, M } from 'components/typography';

import styles from './RequirementsBlock.scss';

export default function FullNameForm({ onSave }: any) {
  const { t } = useTranslation();
  const [fullName, setFullName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { user, refetch: refetchUser } = useContext(UserDataContext);

  const [validation] = useValidation(fullName, validateFullName);

  async function updateUserInfo() {
    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        mutation: updateUser,
        variables: {
          user: {
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            _id: user._id,
            profile: { name: fullName },
          },
        },
      });

      await refetchUser();
      setLoading(false);
      onSave();
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  }

  return (
    <div className={styles.requirementForm}>
      <div className={styles.requirementFormBody}>
        <div className={styles.ellipse} />
        <Icon
          className={styles.formIcon}
          name="user"
          set={ICON_SET_FONTAWESOME}
          type="fal"
          size="large"
        />

        <div className={styles.formTitleContainer}>
          <H3 className={styles.formTitle}>
            {t(
              'web.admin.channel.content.layout.editor.components.fullNameForm.title'
            )}
          </H3>
        </div>
        <M className={styles.formDescription}>
          {t(
            'web.admin.channel.content.layout.editor.components.fullNameForm.description'
          )}
        </M>

        <ValidatedInput
          className={styles.formInput}
          label={t('Name')}
          value={fullName}
          onChange={name => setFullName(name)}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(() => Promise<boolean>) | null | undefined'... Remove this comment to see the full error message
          validation={validation}
        />
        {error ? <ErrorMessage error={error} /> : null}
      </div>
      <div className={styles.requirementFormFooter}>
        <Button
          className={styles.saveButton}
          variant="contained"
          loading={loading}
          disabled={!!validation}
          onClick={() => updateUserInfo()}
          fullWidth
          testId="Save"
        >
          {t(
            'web.admin.channel.content.layout.editor.components.fullNameForm.save'
          )}
        </Button>
      </div>
    </div>
  );
}
