import React, { useMemo, useEffect, useState, ReactNode } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getBatchPublicClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import updateUserContentInteraction from 'lane-shared/graphql/mutation/updateUserContentInteraction';
import { INTERACTION_CLOSED } from 'lane-shared/helpers/constants/interactions';
import { simpleDate } from 'lane-shared/helpers/formatters';
import {
  SORT_CREATED,
  SORT_END,
  SORT_START,
  SORT_STATUS,
  SORT_UPDATED,
  SORT_USER,
  StandardColumnType,
  ContentFeatureColumnsType,
  SORT_EMAIL,
} from 'lane-shared/helpers/interactions/types';
import { getTimeZoneFromInteraction } from 'lane-shared/hooks/useInteractionTimeZone';
import { StatusesFeatureProperties } from 'lane-shared/types/features/StatusesFeatureProperties';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';
import { PropertyType } from 'lane-shared/types/properties/Property';
import { InteractionDataType } from 'lane-shared/types/server/WorkflowTypes';

import { Button } from 'components/general';
import CustomIntegrationPropertyRenderer from 'components/interactions/CustomIntegrationPropertyRenderer';
import Flex from 'components/layout/Flex';
import { NativeFilterTypes, PageSizeType, Table } from 'design-system-web';
import { M } from 'components/typography';

import PropertyValue from '../builder/properties/display/PropertyValue';
import StatusDropdown from './StatusDropdown';
import useIsAdminView from 'hooks/useIsAdminView';

import styles from './ContentUCITable.scss';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  items: InteractionDataType[];
  standardColumns: StandardColumnType[];
  columns: [string, PropertyType][];
  integrationProvider: IntegrationProviderEnum | null;
  integrationColumns: [string, PropertyType][];
  featureColumns: ContentFeatureColumnsType[];
  selectedOrder: string;
  selectedSort: string;
  onSortClick: (sort: string) => void;
  statusesFeature?: StatusesFeatureProperties;
  actions: any[];
  pageSize?: number;
  totalRows?: number;
  contentId: string;
  exportAllResults: () => void;
  exportCurrentPageResults: () => void;
  exportFilteredResults: () => void;
  query: {
    keyword: string;
  };
  loading: boolean;
  onRefresh: () => void;
  statusesDropdownConfiguration: { items: any; value?: string; key: string };
};

type ContentUCITableColumn = {
  header: string;
  key: string;
  disableSorting?: boolean;
  renderCell?: (column: string, uci: InteractionDataType) => ReactNode;
};

type ContentUCITableRowAction = {
  label: string;
  onClick: (uci: InteractionDataType) => void;
};

export default function ContentUCITable({
  items,
  standardColumns,
  columns,
  integrationProvider,
  integrationColumns,
  actions,
  featureColumns,
  pageSize,
  totalRows,
  contentId,
  exportAllResults,
  exportCurrentPageResults,
  exportFilteredResults,
  query,
  loading,
  statusesDropdownConfiguration,
  onRefresh,
}: Props) {
  const [isAdminView, channelSlug] = useIsAdminView();
  const history = useHistory();
  const [selectedInteractions, setSelectedInteractions] = useState<
    InteractionDataType[]
  >([]);
  const { t } = useTranslation();
  const [openInteractionsIds, setOpenInteractionsIds] = useState<string[]>([]);
  const isContentInteractionTableUpdatesEnabled = useFlag(
    FeatureFlag.ContentInteractionTableUpdates,
    false
  );

  const areAnyRowsSelected = selectedInteractions.length > 0;

  useEffect(() => {
    const openInteractions = Array.from(selectedInteractions.values())
      .filter(interaction => {
        return (
          items.find(item => item._id === interaction._id)?.status !==
          INTERACTION_CLOSED
        );
      })
      .map(interaction => interaction._id);

    setOpenInteractionsIds(openInteractions);
  }, [selectedInteractions]);

  const onSelectionChange = (selectedRowIds: string[]) => {
    const data = selectedRowIds.map((id: string) =>
      items.find((item: InteractionDataType) => (item._id as string) === id)
    );

    setSelectedInteractions(data as InteractionDataType[]);
  };

  async function handleUpdateStatus() {
    try {
      for (const interactionId of openInteractionsIds) {
        await getBatchPublicClient().mutate({
          mutation: updateUserContentInteraction,
          variables: {
            interaction: {
              _id: interactionId,
              status: INTERACTION_CLOSED,
            },
          },
        });
      }

      window.Toast.show(
        t(`{{openInteractionsIdsLength}} interactions closed`, {
          openInteractionsIdsLength: openInteractionsIds.length,
        })
      );
      setSelectedInteractions([]);
    } catch (err) {
      console.error(err);
      await window.Alert.alert({
        title: t(`Failed`),
        message: t(`Updating interactions failed`),
      });
    }
  }

  function getInteractionLink(interaction: InteractionDataType) {
    if (isAdminView) {
      // if we are on a channel admin page, use that slug and direct
      // to the team member page, that will have more info about this user.
      return (
        routes.channelAdminInteraction
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          .replace(':id', channelSlug)
          .replace(':interactionId', interaction._id)
      );
    }

    // other wise send them to the public user page.
    return routes.interaction.replace(':id', interaction._id);
  }

  const interactionDateRenderer = (uci: InteractionDataType, date: Date) => {
    // @ts-expect-error ts-migrate(2345) FIXME:Type 'string' is not assignable to type 'GeoCoordinateType'.
    const timeZone = getTimeZoneFromInteraction(uci);

    return simpleDate(date, timeZone);
  };

  const standardColumnsMap: ContentUCITableColumn[] = standardColumns.map(
    col => {
      const myCol = {
        header: col.label!,
        key: col.sort!,
        disableSorting: Boolean(col?.disableSort),
        disableVisibilityToggle: true,
      };

      switch (col.sort) {
        case SORT_EMAIL:
          return {
            ...myCol,
            key: 'user.profile.email',
            renderCell: (_: string, uci: any) => uci.user.profile.email,
          };
        case SORT_USER:
          return {
            ...myCol,
            key: 'user.profile.name',
            renderCell: (_: string, uci: any) => uci.user.profile.name,
          };
        case SORT_END:
          return {
            ...myCol,
            disableVisibilityToggle: false,
            renderCell: (_: string, uci: InteractionDataType) =>
              interactionDateRenderer(uci, uci.endDate),
          };
        case SORT_START:
          return {
            ...myCol,
            disableVisibilityToggle: false,
            renderCell: (_: string, uci: InteractionDataType) =>
              interactionDateRenderer(uci, uci.startDate),
          };
        case SORT_STATUS:
          return {
            ...myCol,
            renderCell: (_: string, uci: InteractionDataType) => (
              <StatusDropdown
                className={styles.statusDropdown}
                interaction={uci}
              />
            ),
          };
        case SORT_UPDATED:
          return {
            ...myCol,
            renderCell: (_: string, uci: InteractionDataType) =>
              interactionDateRenderer(uci, uci._updated),
          };
        case SORT_CREATED:
          return {
            ...myCol,
            renderCell: (_: string, uci: InteractionDataType) =>
              interactionDateRenderer(uci, uci._created),
          };
        default:
          break;
      }

      return myCol;
    }
  );

  const columnsMap: ContentUCITableColumn[] = columns.map(([key, col]) => ({
    header: col.friendlyName! || col.name!,
    key,
    disableSorting: true,
    disableVisibilityToggle: false,
    renderCell: (_: string, uci: InteractionDataType) => (
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      <PropertyValue field={col} value={uci.data[col.name]} />
    ),
  }));

  const interactionColumnsMap: ContentUCITableColumn[] = integrationColumns.map(
    ([key, col]) => ({
      header: col.friendlyName! || col.name!,
      key,
      disableVisibilityToggle: false,
      disableSorting: true,
      renderCell: (_: string, uci: any) => (
        <CustomIntegrationPropertyRenderer
          propertyDefinitionKey={key}
          field={col}
          uci={uci}
          providerName={integrationProvider}
        />
      ),
    })
  );

  const featureColumnsMap = featureColumns
    .map(feature =>
      feature.columns.map(col => ({
        header: t(col.friendlyName! || col.name! || ''),
        key: col.name!,
        disableSorting: true,
        disableVisibilityToggle: false,
        renderCell: (_: string, uci: any) => {
          const featureData = uci.features[feature.type];

          if (col.formatter) {
            return col.formatter(featureData, col);
          }

          return <PropertyValue field={col} value={featureData?.[col.name!]} />;
        },
      }))
    )
    .flat();

  const tableColumns: ContentUCITableColumn[] = useMemo(() => {
    return [
      ...standardColumnsMap,
      ...columnsMap,
      ...interactionColumnsMap,
      ...featureColumnsMap,
    ];
  }, [columns]);

  const rowActions: ContentUCITableRowAction[] = [
    ...actions.map(action => ({
      ...action,
      onClick: (uci: InteractionDataType) => action.onClick(uci),
    })),
    {
      label: 'Edit',
      onClick: (uci: InteractionDataType) => {
        history.push(getInteractionLink(uci));
      },
    },
  ];

  const exportOptions = [
    {
      label: t('web.admin.content.interaction.table.exportOptions.filtered'),
      onClick: exportFilteredResults,
    },
    {
      label: t('web.admin.content.interaction.table.exportOptions.page'),
      onClick: exportCurrentPageResults,
    },
    {
      label: t('web.admin.content.interaction.table.exportOptions.all'),
      onClick: exportAllResults,
    },
  ];

  const hasStartAndEndDatesColumns =
    standardColumns.filter(
      item => item.label === 'Starts' || item.label === 'Ends'
    ).length > 0;

  const FILTERS = isContentInteractionTableUpdatesEnabled
    ? [
        {
          key: 'interactionCreationDates',
          type: NativeFilterTypes.DateRange,
          label: t(
            'web.admin.content.interaction.table.filters.createdDateRange.label'
          ),
          isPromoted: true,
        },
        hasStartAndEndDatesColumns && {
          key: 'dateRangeFilter',
          type: NativeFilterTypes.DateRange,
          label: t(
            'web.admin.content.interaction.table.filters.startEndDateRange.label'
          ),
          isPromoted: true,
        },
        {
          key: statusesDropdownConfiguration.key,
          type: NativeFilterTypes.Select,
          options: statusesDropdownConfiguration.items,
          label: t('Status'),
          isPromoted: true,
          excludeFromClear: true,
        },
      ].filter(i => i)
    : [];

  return (
    <div className={styles.ContentUCITable}>
      <Flex
        align="center"
        gap={4}
        p={areAnyRowsSelected ? 4 : 0}
        className={cx(styles.selectedBox, areAnyRowsSelected && styles.active)}
      >
        {areAnyRowsSelected && (
          <>
            <M variant="secondary">{selectedInteractions.length} selected</M>
            {openInteractionsIds.length > 0 && (
              <Button onClick={handleUpdateStatus}>Close</Button>
            )}
          </>
        )}
      </Flex>

      <Table
        isLoading={loading}
        tableKey={`${contentId}-uci-table`}
        pagination="server"
        queryStringsEnabled
        columns={tableColumns}
        data={items}
        totalRows={totalRows}
        defaultPageSize={pageSize as PageSizeType}
        isSelectable
        rowSelection={selectedInteractions.map(item => item._id)}
        onSelectionChange={onSelectionChange}
        rowActions={rowActions}
        getRowId={(row: InteractionDataType) => row._id}
        showColumnVisibility
        showTotalItemsCount={isContentInteractionTableUpdatesEnabled}
        exportOptions={
          isContentInteractionTableUpdatesEnabled ? exportOptions : []
        }
        activeFilters={
          isContentInteractionTableUpdatesEnabled
            ? [
                {
                  id: statusesDropdownConfiguration.key,
                  value: statusesDropdownConfiguration.value,
                },
              ]
            : []
        }
        // @ts-ignore
        filters={FILTERS}
        hasKeywordFilter={isContentInteractionTableUpdatesEnabled}
        keywordFilter={
          isContentInteractionTableUpdatesEnabled
            ? query.keyword || ''
            : undefined
        }
        onRefreshClick={onRefresh}
      />
    </div>
  );
}
