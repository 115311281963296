import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ShopifyBurstReviewSectionTitle } from 'lane-shared/types/integrations/ShopifyBurst';

import { M } from 'components/typography';

import ReviewData from './ReviewData';

type Props = {
  numberOfAttendees: number;
  onClick?: () => void;
};

export default function ReviewAttendees({ onClick, numberOfAttendees }: Props) {
  const { t } = useTranslation();

  return (
    <ReviewData
      title={t(ShopifyBurstReviewSectionTitle.Attendees)}
      titleIcon={<Icon set="FontAwesome" name="users" type="far" />}
      onClick={onClick}
      summary={<M variant="secondary">{numberOfAttendees} Shopifolk</M>}
      isEditable
    />
  );
}
