import { matchPath, useLocation } from 'react-router-dom';

import { routes } from 'lane-shared/config';

export function useIsPortalManagement(): boolean {
  const location = useLocation();

  const channelMatch = matchPath(location.pathname, {
    path: routes.portalManagement,
    exact: false,
    strict: false,
  });

  return !!channelMatch;
}
