import { ApolloQueryResult, useQuery } from '@apollo/client';

import {
  getPendingInvitesCount,
  PendingInvitesCountResponse,
} from 'lane-shared/graphql/query/getPendingInvitesCount';

export const usePendingInvitesCount = (
  channelId: string,
  groupRoleId?: string,
  refetchCount?: () => Promise<ApolloQueryResult<PendingInvitesCountResponse>>
) => {
  const { data, loading, refetch } = useQuery<PendingInvitesCountResponse>(
    getPendingInvitesCount,
    {
      variables: {
        channelId,
        groupRoleId,
      },
      onCompleted: async () => {
        if (refetchCount) {
          await refetchCount();
        }
      },
    }
  );

  return {
    count: data?.reformedChannel?.channelInvitesCount,
    loading,
    refetch,
  };
};
