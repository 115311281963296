/* eslint-disable react/jsx-no-literals */
import React from 'react';
import EmailText from '../../../../emails/components/EmailText';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { colors } from '../../../../config';
import {
  MjmlButton,
  MjmlColumn,
  MjmlSection,
} from '../../../../emails/MjmlComponentsV2';
import { SHORT_TIME, SIMPLE_DATE } from '../../../constants/dates';
import {
  BORDER_INTERACTION_COLORS,
  INTERACTION_COLORS,
} from '../../../constants/interactions';
import { StatusChangedWebhookResponse, WorkOrder } from '../types';

const StatusMap: { [key: string]: string } = {
  new: 'Created',
  open: 'Open',
  onhold: 'On Hold',
  cancelled: 'Cancelled',
  completed: 'Completed',
  rejected: 'Rejected',
  closed: 'Closed',
};

type TemplateProps = {
  workOrderDetail: WorkOrder;
  webhookResData: StatusChangedWebhookResponse;
};

export const WoStatusChangedTemplate = ({
  workOrderDetail,
  webhookResData,
}: TemplateProps) => {
  return (
    <>
      <MjmlSection>
        <MjmlColumn>
          <EmailText>
            <h3>
              {`Work order ${workOrderDetail.identifier} for ${workOrderDetail.building.name} has changed`}
            </h3>
          </EmailText>
          <EmailText>
            <p
              data-test="wo-status-changed-label"
              style={{ textAlign: 'center', fontWeight: 'bold' }}
            >
              Status:
            </p>
          </EmailText>
        </MjmlColumn>
      </MjmlSection>
      <MjmlSection padding-top="12px">
        {renderStatusButton(StatusMap[webhookResData.old_status], true)}
        <MjmlColumn width="64px">
          <EmailText>
            <h3 style={{ textAlign: 'center' }}>&#8594;</h3>
          </EmailText>
        </MjmlColumn>
        {renderStatusButton(StatusMap[webhookResData.new_status], false)}
      </MjmlSection>
      <MjmlSection padding-top="24px">
        <MjmlColumn>
          <EmailText>
            <MjmlColumn>
              <p
                data-test="wo-status-changed-footer"
                style={{ textAlign: 'center', fontWeight: '400' }}
              >
                {`Changed by property management team on ${dateFormatter(
                  webhookResData.createdDate,
                  SIMPLE_DATE
                )} at ${dateFormatter(webhookResData.createdDate, SHORT_TIME)}`}
              </p>
            </MjmlColumn>
          </EmailText>
        </MjmlColumn>
      </MjmlSection>
    </>
  );
};

const renderStatusButton = (value: string, isOld: boolean) => {
  const statusColor = value as keyof typeof INTERACTION_COLORS;

  return (
    <MjmlColumn>
      <MjmlButton
        color={colors.gray1}
        borderRadius={25}
        border={`2px solid ${BORDER_INTERACTION_COLORS[statusColor]}`}
        backgroundColor={
          INTERACTION_COLORS[
            statusColor
          ] as React.CSSProperties['backgroundColor']
        }
        align={isOld ? 'right' : 'left'}
      >
        {value}
      </MjmlButton>
    </MjmlColumn>
  );
};
