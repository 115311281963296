import { DateTime } from 'luxon';

import { DateRangeType } from '../../types/baseTypes/DateRangeType';
import { ReservableUnitTypesEnum } from '../../types/features/ReservableFeatureProperties';

type Props = {
  units?: number;
  unitType?: ReservableUnitTypesEnum;
  referenceDate: DateTime;
  minDate?: DateTime | null;
  maxDate?: DateTime | null;
};

const DEFAULT_MINUTES = 30;

/**
 * Get's the default starting date range setting for a Reservable feature
 * given the reference date as a pivot point.  This will be used to select
 * something in a UI/UX control.
 *
 * i.e. if the unit is Days, and the number of units it 2 select tomorrow
 * and the next day.
 *
 * i.e. if the unit is Minutes and the number of units is 60, select 60 minutes
 * starting at the top of the next hour.
 */
export default function getDefaultDatesForReservable({
  units = DEFAULT_MINUTES,
  unitType = ReservableUnitTypesEnum.Minutes,
  minDate,
  maxDate,
  referenceDate = DateTime.local(),
}: Props): DateRangeType {
  const range: DateRangeType = {
    startDate: null,
    endDate: null,
  };

  if (!referenceDate) {
    return range;
  }

  let startDate: DateTime;
  let endDate: DateTime;

  // default to some helpful start and end dates
  switch (unitType) {
    case ReservableUnitTypesEnum.Days:
        startDate = referenceDate.startOf('day');
      
      // special case for reseverables of length 1 day
      if (units === 1) {
        endDate = startDate.endOf('day');
      } else {
        endDate = referenceDate.plus({ days: 1 + units }).endOf('day');
      }

      break;
    case ReservableUnitTypesEnum.Weeks:
      startDate = referenceDate.plus({ weeks: 1 }).startOf('week');
      endDate = referenceDate
        .plus({ weeks: 1 })
        .startOf('week')
        .plus({ weeks: units });
      break;
    case ReservableUnitTypesEnum.Months:
      startDate = referenceDate.plus({ months: 1 }).startOf('month');
      endDate = referenceDate
        .plus({ months: 1 })
        .startOf('month')
        .plus({ months: units });
      break;
    case ReservableUnitTypesEnum.Minutes:
    default:
      startDate = referenceDate.plus({ hours: 1 }).startOf('hour');
      endDate = referenceDate
        .plus({ hours: 1 })
        .startOf('hour')
        .plus({ minutes: units });
      break;
  }

  range.startDate = startDate.toJSDate();

  if (minDate && startDate < minDate) {
    if (unitType === ReservableUnitTypesEnum.Days) {
      range.startDate = minDate.startOf('day').toJSDate();
    } else {
      range.startDate = minDate.toJSDate();
    }
  }

  range.endDate = endDate.toJSDate();

  if (maxDate && endDate > maxDate) {
    range.startDate = maxDate.toJSDate();
  }

  return range;
}
