import { ChannelProviderType } from '../types';

export function onlyUniqueIntegrations(
  value: ChannelProviderType,
  index: number,
  array: ChannelProviderType[]
) {
  return (
    array.findIndex(
      item => item.integration.name === value.integration.name
    ) === index
  );
}
