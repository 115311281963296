import * as yup from 'yup';

import { createShapeFromProperties } from '../../../properties';
import Types from '../../../properties/Types';
import { ObjectTypeBase } from '../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../types/properties/Property';

export interface NotesType {
  noteToVisitor?: string;
  noteToStaff?: string;
  additionalRecipients?: string[];
}

const properties: PropertiesInterface = {
  noteToVisitor: {
    name: 'Note to Visitor',
    type: 'String',
    editable: true,
    default: '',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  noteToStaff: {
    name: 'Note to Staff',
    type: 'String',
    editable: true,
    default: '',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  additionalRecipients: {
    name: 'Additional recipients to notify',
    type: 'String',
    editable: true,
    default: [],
    isArray: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
};

export class NotesObjectType extends ObjectTypeBase {
  name = 'NotesType';

  friendlyName = 'Notes for staff and visitor';

  properties = properties;

  contexts = [];

  get example(): NotesType {
    return {
      noteToStaff: 'Note to staff',
      noteToVisitor: 'Note to visitor',
      additionalRecipients: []
    };
  }

  get default(): any {
    return {};
  }

  buildSchema() {
    const shape = createShapeFromProperties(properties);

    return yup.object().shape(shape).nullable();
  }
}

const definition = new NotesObjectType();

Types.addType(definition);
export default definition;
