import { AlreadyExistsError, NotFoundError } from 'activate-errors';
import { BaseType } from '../types/baseTypes/TypeBase';
import { TypeInterface } from '../types/baseTypes/TypeInterface';
import { PropertyType } from '../types/properties/Property';

export interface TypesCollection {
  [key: string]: TypeInterface;
}

export default class Types {
  static baseTypes: TypesCollection = {};

  static types: TypesCollection = {};

  static addBaseType(definition: TypeInterface) {
    if (this.baseTypes[definition.name]) {
      throw new AlreadyExistsError(
        `Base Type:${definition.name} already added.`
      );
    }

    this.baseTypes[definition.name] = definition;
    this.types[definition.name] = definition;
  }

  static addType(definition: TypeInterface) {
    if (this.types[definition.name]) {
      throw new AlreadyExistsError(`Type:${definition.name} already added.`);
    }

    // todo: validate the type definition?
    this.types[definition.name] = definition;
  }

  static create(config: PropertyType): BaseType {
    if (!this.types[config.type]) {
      throw new NotFoundError(`Type:${config.type} not found.`);
    }

    const cls = this.types[config.type].constructor;
    const type = new (cls as any)();

    type.config = config;

    return type;
  }

  static getType(name: string): TypeInterface {
    if (!this.types[name]) {
      throw new NotFoundError(`Type:${name} not found.`);
    }

    return this.types[name];
  }

  static getBaseTypes(): TypesCollection {
    return this.baseTypes;
  }

  static isType(name: any): boolean {
    return !!this.types[name];
  }

  static isBaseType(name: any): boolean {
    return !!this.baseTypes[name];
  }

  static isAdvancedType(name: any): boolean {
    return !!this.types[name] && !this.baseTypes[name];
  }

  static getTypes(): TypesCollection {
    return this.types;
  }
}
