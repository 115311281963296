/* Filters queries on category, name, and description. Returns all queries
 * if none apply. In the future, will also query by tags. */
import portalQueries from './portalQueries';

function getFilteredQueries(searchValue: any) {
  if (!searchValue) {
    return portalQueries;
  }

  searchValue = searchValue.toLowerCase().trim();

  return portalQueries.filter(query => {
    const name = query.name.toLowerCase().trim();
    const description = query.description.toLowerCase().trim();
    const category = query.category.toLowerCase().trim();

    return (
      name.includes(searchValue) ||
      description.includes(searchValue) ||
      category.includes(searchValue)
    );
  });
}

export default getFilteredQueries;
