import {
  SearchModeEnum,
  StringSearchTypeEnum,
} from 'lane-shared/types/graphql/search';
import { IntegrationCategoryEnum } from 'lane-shared/types/integrations/IntegrationEnums';

export function getQueryVariables(primaryId: string) {
  const allIntegrationsVar = {
    search: {
      integrations: {
        deleted: false,
        integration: {
          category: {
            type: StringSearchTypeEnum.Equal,
            value: IntegrationCategoryEnum.AccessControl,
          },
        },
      },
    },
  };

  const focusChannelIntegrationsVar = {
    channelId: primaryId,
    search: {
      _id: primaryId,
      integrations: {
        deleted: false,
        integration: {
          category: {
            type: StringSearchTypeEnum.Equal,
            value: IntegrationCategoryEnum.AccessControl,
          },
        },
      },
    },
    relationshipSearch: {
      relatedTo: {
        _id: primaryId,
      },
      channel: {
        integrations: {
          deleted: false,
          integration: {
            category: {
              type: StringSearchTypeEnum.Equal,
              value: IntegrationCategoryEnum.AccessControl,
            },
          },
        },
      },
      searchMode: SearchModeEnum.And,
    },
    skipPagination: true,
  };

  return { allIntegrationsVar, focusChannelIntegrationsVar };
}
