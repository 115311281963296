import { Audience } from '../../../types/audiences/Audience';
import { ChannelType } from '../../../types/ChannelType';
import { channelCollectables } from './channel.collectable';
import { targetingRuleCollectables } from './targetingRule.collectable';

const channelInfoByChannelId = (
  channelId?: string,
  channels?: ChannelType[]
) => {
  const channel = (channels || []).find(
    eachChannel => eachChannel._id === channelId
  );

  return channel;
};

export const audienceCollectables = (
  audience?: Audience,
  channelsWithinAudiences?: ChannelType[]
) => {
  if (!audience) return null;

  return {
    name: audience.name,
    channel_names: audience.channelTargetingRules?.map(
      channelTargetingRule =>
        channelCollectables(
          channelInfoByChannelId(
            channelTargetingRule?.channelId,
            channelsWithinAudiences
          )
        ) || targetingRuleCollectables(channelTargetingRule)
    ),
    team_names: audience.groupRoleTargetingRules?.map(groupRoleTargetingRule =>
      targetingRuleCollectables(groupRoleTargetingRule)
    ),
    ownership_channel: channelCollectables(
      channelInfoByChannelId(
        audience.createdByChannel,
        channelsWithinAudiences
      ) || audience.Channel
    ),
    audienceId: audience.id,
  };
};
