import { CodedError } from 'activate-errors';
import {
  SafetrustErrorCodeEnum,
  SafetrustErrorMessagesEnum,
} from './SafetrustEnums';

/**
 * Maps a Safetrust error to SafetrustErrorCodeEnum.  Since
 * Safetrust overloads error code 0 it is often not very helpful for
 * figuring out what is actually the error.
 *
 * We will take err.code and err.message to map to a relevant value from
 * SafetrustErrorCodeEnum
 */
export default function mapSafetrustErrorCode(
  err: CodedError | null
): SafetrustErrorCodeEnum {
  if (!err) {
    return SafetrustErrorCodeEnum.UNHANDLE;
  }

  const code = Number(err.code) as SafetrustErrorCodeEnum;

  switch (code) {
    case SafetrustErrorCodeEnum.UNHANDLE:
      switch (err.message) {
        case SafetrustErrorMessagesEnum.InvitationNotAccepted:
          return SafetrustErrorCodeEnum.INVITATION_NOT_ACCEPTED;
        case SafetrustErrorMessagesEnum.PinNotMatch:
          return SafetrustErrorCodeEnum.ACTIVATE_CODE_INVALID;
        case SafetrustErrorMessagesEnum.LoginNotSetup:
          return SafetrustErrorCodeEnum.LOGIN_NOT_SETUP;
        case SafetrustErrorMessagesEnum.WrongPassword:
          return SafetrustErrorCodeEnum.PASSCODE_INVALID;
        case SafetrustErrorMessagesEnum.AccountNotActivated:
          return SafetrustErrorCodeEnum.ACCOUNT_NOT_ACTIVE;
        case SafetrustErrorMessagesEnum.SessionInvalid:
          return SafetrustErrorCodeEnum.ACCOUNT_SESSION_NOT_FOUND;
        case SafetrustErrorMessagesEnum.LoginBlocked:
          return SafetrustErrorCodeEnum.ACCOUNT_BLOCKED;
        case SafetrustErrorMessagesEnum.DeviceLimitReached:
          return SafetrustErrorCodeEnum.DEVICE_LIMIT_REACHED;
        default:
          if (
            err.message.includes(
              SafetrustErrorMessagesEnum.AccountContextNotFound
            )
          ) {
            return SafetrustErrorCodeEnum.ACCOUNT_SESSION_NOT_FOUND;
          }

          // unhandled unhandled-error...
          return SafetrustErrorCodeEnum.UNHANDLE;
      }

    default:
      return code;
  }
}
