import React, { useEffect, useRef } from 'react';

import cx from 'classnames';
import { RadioGroup } from 'components';

import { START_HOUR } from 'lane-shared/domains/visitorManagement/features/VisitorManagementFeatureValues';
import { createDefaultDateTime } from 'lane-shared/domains/visitorManagement/helpers';
import { ScheduleReducerType } from 'lane-shared/domains/visitorManagement/reducers';
import { ScheduleType } from 'lane-shared/domains/visitorManagement/types/ScheduleType';
import { ScheduleTypeEnum } from 'lane-shared/domains/visitorManagement/types/VisitorManagementFeatureProperties';
import { useFlag, useVisitorScheduleForm } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { VisitorManagementFeatureProperties } from 'lane-shared/types/features/VisitorManagementFeatureProperties';

import ScheduleDateTime, {
  ScheduleDateTimeHandle,
} from '../ScheduleDateTime/ScheduleDateTime';
import ScheduleDateTimeRange from '../ScheduleDateTimeRange/ScheduleDateTimeRange';
import ScheduleMultiDateTime from '../ScheduleMultiDateTime/ScheduleMultiDateTime';
import ScheduleDateTimeRepeat from '../ScheduleRepeat/ScheduleDateTimeRepeat';

import styles from './VisitorScheduleForm.scss';

function ScheduleForm({
  className,
  contentClassName,
  properties,
  timeZone,
  value,
  submissionCompletedAt,
  onChange,
  isPreview = false,
}: {
  className?: string;
  contentClassName?: string;
  properties: VisitorManagementFeatureProperties;
  timeZone?: string;
  value: ScheduleType | null;
  submissionCompletedAt?: Date;
  onChange: (value: ScheduleType) => void;
  isPreview?: boolean;
}): React.ReactElement {
  const {
    options,
    isAllDay,
    startDate,
    endDate,
    dates,
    intervalType,
    endRepeatDate,
    intervalCount,
    weekdayRepeats,
    monthIntervalType,
    type,
    resetForm,
    handleDateTime,
    handleCustomDateTime,
    handleDateTimeRange,
    handleType,
    handleRepeat,
  } = useVisitorScheduleForm({
    properties,
    value,
    timeZone,
    onChange,
  });

  const dateTimeRef = useRef<ScheduleDateTimeHandle>(null);

  useEffect(() => {
    resetForm();

    if (dateTimeRef?.current) {
      dateTimeRef.current.reset();
    }
  }, [submissionCompletedAt]);

  const minDate = createDefaultDateTime(START_HOUR, 0, timeZone);
  const maxDate = new Date();

  maxDate.setMonth(
    maxDate.getMonth() + (properties.scheduleInAdvanceAmount ?? 12)
  );

  const allDayPassEnabled = useFlag(
    FeatureFlag.VisitorManagementAllDayPass,
    false
  );

  const isAllDayPassEnabled = Boolean(
    properties.allDayPassEnabled && allDayPassEnabled
  );

  // @ts-ignore ts(7030) Not all code paths return a value.
  function renderScheduleType() {
    switch (type) {
      case ScheduleTypeEnum.SpecificDate:
        return (
          <ScheduleDateTime
            allDayPassEnabled={isAllDayPassEnabled}
            defaultIsAllDay={isAllDay}
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            timeZone={timeZone}
            onChange={(value: ScheduleReducerType) => {
              handleDateTime(value);
            }}
            ref={dateTimeRef}
            minDate={minDate}
            maxDate={maxDate}
            isPreview={isPreview}
          />
        );
      case ScheduleTypeEnum.CustomDates:
        return (
          <ScheduleMultiDateTime
            allDayPassEnabled={isAllDayPassEnabled}
            defaultIsAllDay={isAllDay}
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            defaultDates={dates}
            timeZone={timeZone}
            onChange={(value: ScheduleReducerType) => {
              handleCustomDateTime(value);
            }}
            ref={dateTimeRef}
            minDate={minDate}
            maxDate={maxDate}
            isPreview={isPreview}
          />
        );
      case ScheduleTypeEnum.DateRange:
        return (
          <ScheduleDateTimeRange
            allDayPassEnabled={isAllDayPassEnabled}
            defaultIsAllDay={isAllDay}
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            timeZone={timeZone}
            onChange={(value: ScheduleReducerType) => {
              handleDateTimeRange(value);
            }}
            minDate={minDate}
            maxDate={maxDate}
            isPreview={isPreview}
          />
        );
      case ScheduleTypeEnum.Repeat:
        return (
          <ScheduleDateTimeRepeat
            allDayPassEnabled={isAllDayPassEnabled}
            defaultIsAllDay={isAllDay}
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            defaultIntervalCount={intervalCount}
            defaultEndRepeatDate={endRepeatDate}
            defaultInterval={intervalType}
            defaultWeekdayRepeats={weekdayRepeats}
            defaultMonthIntervalType={monthIntervalType}
            timeZone={timeZone}
            onChange={(value: ScheduleReducerType) => {
              handleRepeat(value);
            }}
            minDate={minDate}
            maxDate={maxDate}
            isPreview={isPreview}
          />
        );
    }
  }

  return (
    <div className={cx(styles.visitorScheduleForm, className)}>
      {options?.length > 1 && (
        <div className={styles.scheduleOptions}>
          <div className={styles.radioGroup}>
            <RadioGroup
              className={styles.radio}
              key="schedule"
              name="schedule"
              schema={{
                id: '_id',
                text: 'text',
                disabled: 'disabled',
              }}
              selected={type}
              items={options}
              onChange={value => handleType(value as ScheduleTypeEnum)}
              disabled={isPreview}
            />
          </div>
        </div>
      )}
      <div className={cx(styles.content, contentClassName)}>
        {renderScheduleType()}
      </div>
    </div>
  );
}

export default ScheduleForm;
