import React from 'react';

import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { useContentQuery } from 'lane-shared/hooks';
import { UserContentInteractionType } from 'lane-shared/types/UserContentInteraction';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';
import { PropertyType } from 'lane-shared/types/properties/Property';

import ShopifyPropertyRenderer from 'components/integrations/ShopifyBurst/components/ShopifyPropertyRenderer';

import PropertyValue from '../builder/properties/display/PropertyValue';
import useIsAdminView from 'hooks/useIsAdminView';

type CustomIntegrationPropertyRendererProps = {
  field: PropertyType;
  uci: UserContentInteractionType;
  propertyDefinitionKey: string;
  providerName: IntegrationProviderEnum | null;
};

export default function CustomIntegrationPropertyRenderer({
  field,
  uci,
  propertyDefinitionKey,
  providerName,
}: CustomIntegrationPropertyRendererProps) {
  const [, channelSlug] = useIsAdminView();
  const { content } = useContentQuery({
    contentId: uci?.contentData?._id,
  });

  const UCIDataOfPropertyDefinition =
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    uci.data[`_${providerName}`][propertyDefinitionKey];

  if (field?.hasCustomColumnRenderer) {
    switch (providerName) {
      case IntegrationProviderEnum.ShopifyBurst:
        if (propertyDefinitionKey === 'name') {
          return (
            <Link
              to={routes.channelAdminInteraction
                .replace(':id', channelSlug!)
                .replace(':interactionId', uci._id)}
            >
              <PropertyValue
                field={field}
                value={UCIDataOfPropertyDefinition}
              />
            </Link>
          );
        }

        return (
          <ShopifyPropertyRenderer
            content={content!}
            field={field}
            UCIDataOfPropertyDefinition={UCIDataOfPropertyDefinition}
          />
        );
    }
  }

  return <PropertyValue field={field} value={UCIDataOfPropertyDefinition} />;
}
