import gql from 'graphql-tag';

import {
  PublicUserFragment,
  ChannelSettingsRequirementsFragment,
} from '../fragments';

export default gql`
  ${PublicUserFragment}
  ${ChannelSettingsRequirementsFragment}
  query ContentOnChannel(
    $pagination: PaginationInput
    $search: ContentSearch
    $channelId: UUID!
  ) {
    contentOnChannel(
      channelId: $channelId
      search: $search
      pagination: $pagination
    ) {
      pageInfo {
        total
        start
        perPage
      }
      items {
        _id
        _created
        _updated
        name
        name_l10n
        description
        description_l10n
        subtitle
        subtitle_l10n
        slug
        type
        tags
        category
        reportingGroup
        logo
        color
        geo
        isInteractive
        backgroundImage
        icon
        iconSet
        iconWeight
        backgroundColor
        liveDate
        startDate
        endDate
        unpublishDate
        data
        interactionEndDate
        state
        version
        renderer
        block
        features {
          _id
          type
          feature
        }
        isSyndicateable
        resetPeriod
        card {
          _id
        }

        publishedBy {
          ...PublicUserFragment
        }
        channel {
          _id
          settings {
            requirements {
              ...ChannelSettingsRequirementsFragment
            }
            effectiveParentRequirements {
              ...ChannelSettingsRequirementsFragment
            }
            channelLanguages
            multiLanguageEnabled
            enabledLanguagesForMembers
          }
        }
        placements {
          _id
          _created
          _updated
          _createdBy {
            ...PublicUserFragment
          }
          _updatedBy {
            ...PublicUserFragment
          }
          groupRole {
            _id
          }
          type
          channel {
            _id
          }
          user {
            _id
          }
        }
      }
    }
  }
`;
