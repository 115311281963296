import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { simpleDateTime } from 'lane-shared/helpers/formatters';
import { WorkOrderAttachment } from 'lane-shared/helpers/integrations/BuildingEnginesPrism/client/fileOperations';

import { S, H5 } from 'components/typography';

import styles from './AttachmentModal.scss';

export type attachmentProps = WorkOrderAttachment & {
  error?: boolean;
  onDelete: (
    fileId?: string | number,
    fileAssociationId?: string
  ) => Promise<void>;
  testId?: string;
};

export default function Attachment({
  id,
  file_association_id,
  display_name,
  thumbnail,
  created_at,
  category,
  error = false,
  onDelete,
  testId,
}: attachmentProps) {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.attachment)}>
      {!error && id && (
        <div className={cx(styles.imageOutline)}>
          {thumbnail && (
            <img src={thumbnail} className={cx(styles.image)} alt="thumbnail" />
          )}

          {!thumbnail && <Icon name="file" className={styles.docIcon} />}
        </div>
      )}
      {error && (
        <div className={cx(styles.errorImageOutline)}>
          <Icon name="exclamation-circle" className={styles.errorIcon} />
        </div>
      )}
      {!error && !id && (
        <div className={cx(styles.imageOutline)}>
          <Icon name="circle-notch" className={styles.docIcon} />
        </div>
      )}
      <div className={cx(styles.textAndClose)}>
        <div className={cx(styles.content)}>
          <H5 className={cx(styles.displayName)}>{display_name}</H5>
          {error && <S>{t('Failed to upload')}</S>}
          {!error && id && (
            <>
              <S>
                {t('Category:')} {category?.name}
              </S>
              {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1. */}
              <S>{simpleDateTime(created_at)}</S>
            </>
          )}
        </div>
        <button
          data-test={testId}
          className={styles.close}
          onClick={() => onDelete(id, file_association_id)}
          type="button"
        >
          <Icon name="times" className={styles.closeIcon} />
        </button>
      </div>
    </div>
  );
}
