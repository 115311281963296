import Types from '../../properties/Types';
import { PackagedTypeEnum } from '../../types/properties/PackagedTypeEnum';
import { PropertyType } from '../../types/properties/Property';
import {
  MAX_RADIO_THRESHOLD,
  QUANTITY_INPUT_THRESHOLD,
  SLIDER_INPUT_THRESHOLD,
} from '../constants/blocks';
import explodeValidators from './explodeValidators';

/**
 * Given a property determine what kind of UI/UX we want to show this property
 * as.  If the property has the new packagedType field we will use that,
 * or we will use legacy code to determine what to render it as.
 *
 * @param property
 */
export default function getPackagedType(
  property: PropertyType
): PackagedTypeEnum {
  if (!property) {
    return PackagedTypeEnum.None;
  }

  if (property.packagedType) {
    return property.packagedType;
  }

  const baseTypes = Types.getBaseTypes();

  // legacy logic before 5.8 and the introduction of packaged types
  // we will try to determine the UI presentation without a hint from
  // the packaged type field
  const { minValidator, maxValidator, inValidator } = explodeValidators(
    property?.validators
  );

  switch (property.type) {
    case baseTypes.Em!.name:
    case baseTypes.Percentage!.name:
    case baseTypes.Currency!.name:
    case baseTypes.Number!.name: {
      const range = Number(maxValidator?.value) - Number(minValidator?.value);

      if (range < SLIDER_INPUT_THRESHOLD) {
        if (range < QUANTITY_INPUT_THRESHOLD) {
          return PackagedTypeEnum.Quantity;
        }

        return PackagedTypeEnum.Slider;
      }

      return PackagedTypeEnum.None;
    }

    case baseTypes.Email!.name:
    case baseTypes.Url!.name:
    case baseTypes.PhoneNumber!.name:
    case baseTypes.String!.name: {
      if (inValidator) {
        const value = inValidator.value;
        const optionsCount = Array.isArray(value) ? value.length : 0;

        if (optionsCount > 0 && property.isArray) {
          return PackagedTypeEnum.Checkboxes;
        }

        if (optionsCount <= MAX_RADIO_THRESHOLD) {
          return PackagedTypeEnum.Radios;
        }

        if (optionsCount > MAX_RADIO_THRESHOLD) {
          return PackagedTypeEnum.Dropdown;
        }
      }

      return PackagedTypeEnum.None;
    }

    default:
      return PackagedTypeEnum.None;
  }
}
