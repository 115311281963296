import localForage from 'localforage';

import { getClient } from 'lane-shared/apollo';
import { signOut } from 'lane-shared/graphql/user';
import { Storage } from 'lane-shared/helpers';
import { getSessionId } from 'lane-shared/helpers/auth0/getSessionId';

import emitter, {
  EVENT_LOGOUT,
  EVENT_AUTH_TOKEN_INVALID,
} from 'lane-shared/helpers/emitter';
import { StorageKeys } from 'lane-shared/helpers/Storage';
import { ENVIRONMENTS } from 'activate-constants';

type LogoutOptions = {
  emitLogout?: boolean;
  emitAuthTokenInvalid?: boolean;
};

export default async function logout({
  emitLogout = true,
  emitAuthTokenInvalid = true,
}: LogoutOptions = {}) {
  const disconnectFromAPI = async () => {
    const token = await Storage.getItem(StorageKeys.AUTH_TOKEN);

    const sessionId = getSessionId(token);

    await getClient().mutate({
      mutation: signOut,
      variables: { jti: sessionId },
    });
    getClient().cache.reset();
  };

  const isAuth0SimulatorEnabled =
    process.env.APP_ENV === ENVIRONMENTS.LOCAL &&
    process.env.AUTH0_SIMULATOR_ENABLED === 'true';

  if (!isAuth0SimulatorEnabled) {
    await disconnectFromAPI();
  }

  localForage.clear().catch(() => undefined);

  if (emitLogout) {
    emitter.emit(EVENT_LOGOUT);
  }

  if (emitAuthTokenInvalid) {
    emitter.emit(EVENT_AUTH_TOKEN_INVALID);
  }
}
