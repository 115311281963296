import { ChannelType } from 'lane-shared/types/ChannelType';

import {
  UserInfo,
  MultiIntegrationAcgs,
  AccessControlGroup,
} from 'lane-web/src/domains/accessControl/types/AccessControl';

import { SelectedAccessGroupsToChannel } from '../types';
import { computeAccessGroupsDiff } from './operators';

export const buildPayload = (
  accessGroupsToChannel: SelectedAccessGroupsToChannel[]
) => {
  const payload = accessGroupsToChannel.reduce(
    (acc: any[], accessGroupToChannel: SelectedAccessGroupsToChannel) => {
      const { assignAccessGroups } = computeAccessGroupsDiff(
        accessGroupToChannel.selectedValues,
        accessGroupToChannel.initialValues
      );

      return acc.concat(
        ...assignAccessGroups.map(group => ({
          id: group.value,
          provider: group.provider,
          channelId: group.channelId,
        }))
      );
    },
    []
  );

  return payload;
};

export const convertUsertoUserProfile = (
  user: UserInfo,
  channels: ChannelType[],
  channelId: string
) => {
  const accessGroupMap: { [key: string]: string[] } = {};

  accessGroupMap[channelId] = user.accessControlGroupIds || [];

  if (user?.multiIntegrationAcgs) {
    user.multiIntegrationAcgs.forEach(
      (integrationAccessGroups: MultiIntegrationAcgs) => {
        const channel = channels.find(
          channel => channel.name === integrationAccessGroups.channelName
        );

        if (channel) {
          accessGroupMap[
            channel._id
          ] = integrationAccessGroups.accessControlGroups.map(acg => acg.id);
        }
      }
    );
  }

  return {
    userId: user._id,
    userName: user.name,
    image: user.image || user.logo,
    accessGroups: accessGroupMap,
  };
};

export const convertChannelToChannelProfile = (
  channels: ChannelType[],
  accessGroups: AccessControlGroup[],
  channelId: string,
  selected: boolean
) => {
  const channel = channels.find(channel => channel._id === channelId);

  if (!channel) {
    return {
      channelId,
      channelName: '',
      image: '',
      accessGroups,
      selected: false,
    };
  }

  return {
    channelId,
    channelName: channel.name,
    image: channel?.profile?.image || channel?.profile?.logo || '',
    accessGroups,
    selected,
  };
};
