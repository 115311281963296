import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { UserLoginStatusEnum } from 'lane-shared/types/UserLogin';

import { resendVerificationEmail } from '../../helpers';
import Radio from '../form/Radio';
import IconButton from '../general/IconButton';

import styles from './UserLoginEdit.scss';

type OwnProps = {
  loading?: boolean;
  login: any;
  isEdit?: boolean;
  onLoginDelete?: (...args: any[]) => any;
  onLoginUpdated?: (...args: any[]) => any;
};

type Props = OwnProps;

function UserLoginEdit({
  loading = false,
  login,
  isEdit = false,
  onLoginDelete = () => null,
  onLoginUpdated = () => null,
}: Props) {
  const unverified = login.status !== UserLoginStatusEnum.Verified;

  const { t } = useTranslation();

  return (
    <div
      className={cx(styles.UserLoginEdit, login.isPrimary && styles.isPrimary)}
      key={login.key}
    >
      <div className={styles.email} data-test="emailAddress">
        {login.key}
      </div>
      {unverified && (
        <button
          className={styles.unverified}
          onClick={() => resendVerificationEmail(login.key)}
        >
          {t('unverified')}
        </button>
      )}
      {!isEdit && (
        <div className={styles.radio}>
          <Radio
            selected={login.isPrimary ? login.key : ''}
            disabled={loading || (unverified && !login.isPrimary)}
            text="primary"
            value={login.key}
            name={login.key}
            onChange={() =>
              !login.isPrimary &&
              onLoginUpdated({ ...login, isPrimary: !login.isPrimary })
            }
          />
        </div>
      )}
      {isEdit && (
        <div className={styles.radio}>
          <IconButton
            className={styles.removeIcon}
            icon="times"
            disabled={loading}
            data-test="removeEmailAddress"
            onClick={() => onLoginDelete(login)}
          />
        </div>
      )}
      <hr />
    </div>
  );
}

export default UserLoginEdit;
