import { useEffect, useState, useRef } from 'react';

import { v4 as uuid } from 'uuid';

import { getCurrencyByGeoLocation } from '../helpers';
import {
  explodeFeatures,
  getPaymentFeatureCurrency,
} from '../helpers/features';
import { currencyFormatter } from '../helpers/formatters';

export default function useMenuFeatureForOrder({
  existingOrder,
  onOrderUpdated,
  content,
  locale,
}: any) {
  const newOrder = useRef({
    _id: uuid(),
    items: [],
  }).current;

  const [hasUpdatedOrder, setHasUpdatedOrder] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const order = existingOrder || newOrder;

  const { menuFeature, paymentFeature } = explodeFeatures(content?.features);

  useEffect(() => {
    if (menuFeature) {
      // make sure a default category is selected.
      if (!selectedCategory && menuFeature.categories?.length > 0) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ value: string; }' is not assig... Remove this comment to see the full error message
        setSelectedCategory({ value: menuFeature.categories[0]._id });
      }
    }
  }, [menuFeature]);

  function onItemAdded(orderItem: any, item: any) {
    setHasUpdatedOrder(true);
    const hasOptions = Boolean(item?.options?.length);
    const updatedItems = [...(order.items || [])];
    const existingItem =
      !hasOptions &&
      updatedItems.find(
        currItem =>
          currItem.itemId === orderItem.itemId &&
          currItem.notes === orderItem.notes
      );

    if (existingItem) {
      existingItem.quantity += orderItem.quantity;
    } else {
      updatedItems.push(orderItem);
    }

    onOrderUpdated({
      ...order,
      items: updatedItems,
    });
  }

  // if no paymentFeature is defined, find the default currency.
  const currency = paymentFeature
    ? getPaymentFeatureCurrency(paymentFeature)
    : getCurrencyByGeoLocation({
        latitude: content.geo[1],
        longitude: content.geo[0],
      });

  const currencyFormat = currencyFormatter({
    locale,
    currency,
  });

  let items: any = [];

  if (menuFeature) {
    if (selectedCategory) {
      items = menuFeature.items.filter(
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        ({ category }) => category === selectedCategory.value
      );
    } else {
      items = menuFeature.items;
    }
  }

  return {
    hasUpdatedOrder,
    selectedCategory,
    order,
    items,
    menuFeature,
    paymentFeature,
    currency,
    currencyFormat,
    onItemAdded,
    setSelectedCategory,
  };
}
