import React, { useState } from 'react';
import cx from 'classnames';
import { RichTextNodeType } from 'lane-shared/properties/baseTypes/RichText';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { ContentWorkflowPayloadType } from 'lane-shared/types/ContentWorkflowType';
import { MultiLanguageWrapper } from 'components/general/MultiLanguageWrapper';
import styles from './EmailBuilderV2Editor.scss';
import { RadioGroup, RichText } from 'components/form';
import { EmailEditableEnum, TextOptionEnum } from '../helpers/constants';
import { LocalizationRichTextNodeType } from 'lane-shared/types/LocalizationColumnType';

type Props = {
  className?: string;
  style: React.CSSProperties;
  channel: ChannelType;
  selectedOption: TextOptionEnum;
  t: (key: string, option: {}) => string;
  setSelectedOption: (option: TextOptionEnum) => void;
  keyColumn:
    | EmailEditableEnum.Body
    | EmailEditableEnum.Intro
    | EmailEditableEnum.Outro;
  draftPayloadForEmailSettings: ContentWorkflowPayloadType;
  updateDraftPayloadForEmailSettings: (
    updates: Partial<ContentWorkflowPayloadType>
  ) => void;
};

type EditorStateType = {
  [key: string]: RichTextNodeType[] | undefined;
};

type EditorStateL10nType = {
  [key: string]: LocalizationRichTextNodeType | undefined;
} & Record<string, any>;

export const TextEditor = ({
  className,
  style,
  selectedOption,
  t,
  channel,
  setSelectedOption,
  keyColumn,
  draftPayloadForEmailSettings,
  updateDraftPayloadForEmailSettings,
}: Props) => {
  const [editorState, setEditorState] = useState<EditorStateType>({
    [keyColumn]: draftPayloadForEmailSettings[keyColumn],
  });
  const [editorStateL10n, setEditorStateL10n] = useState<EditorStateL10nType>({
    [keyColumn]: draftPayloadForEmailSettings[`${keyColumn}_l10n`],
  });

  return (
    <div className={cx(styles.bodyEditorContainer, className)} style={style}>
      {[EmailEditableEnum.Intro, EmailEditableEnum.Body].includes(
        keyColumn
      ) && (
        <div className={cx(styles.radioButtonContainer, className)}>
          <RadioGroup
            selected={selectedOption}
            showBorder
            items={[
              {
                id: TextOptionEnum.Standard,
                text:
                  'web.admin.channel.content.emailBuilder.toggle.standardText',
              },
              {
                id: TextOptionEnum.Custom,
                text:
                  'web.admin.channel.content.emailBuilder.toggle.customText',
              },
            ]}
            onChange={(selection: string) => {
              if (selection === TextOptionEnum.Custom) {
                updateDraftPayloadForEmailSettings({
                  [keyColumn]: editorState[keyColumn],
                  [`${keyColumn}_l10n`]: editorStateL10n[keyColumn],
                });
              } else if (selection === TextOptionEnum.Standard) {
                // Save the editor state so we can swap back to the custom state
                // without clearing the text
                setEditorState(prevState => ({
                  ...prevState,
                  [keyColumn]: draftPayloadForEmailSettings[
                    keyColumn
                  ] as RichTextNodeType[],
                }));
                setEditorStateL10n(prevState => ({
                  ...prevState,
                  // @ts-expect-error Element implicitly has an 'any' type...
                  [keyColumn]: draftPayloadForEmailSettings[
                    `${keyColumn}_l10n`
                  ] as LocalizationRichTextNodeType[],
                }));
                const updates = {
                  [keyColumn]: undefined,
                  [`${keyColumn}_l10n`]: undefined,
                };

                updateDraftPayloadForEmailSettings(updates);
              }

              setSelectedOption(selection as TextOptionEnum);
            }}
          />
        </div>
      )}
      <div className={cx(styles.languagesContainer, className)}>
        <MultiLanguageWrapper
          channel={channel}
          model={draftPayloadForEmailSettings}
          column={keyColumn}
          className={cx(styles.multiLanguageContainer, className)}
          primarySectionClassName={cx(
            styles.multilanguageSingleLanguageContainer,
            className
          )}
        >
          {({ isPrimary, valueGetter, labelMaker, onChangeUpdates }) => (
            <div
              className={
                selectedOption === TextOptionEnum.Standard &&
                styles.disabledText
              }
            >
              {labelMaker({
                label: t(
                  'web.admin.channel.content.emailBuilder.input.custom.label',
                  {
                    value: keyColumn,
                  }
                ),
                primaryLabelClassName: styles.bodyLabelText,
              })}
              <RichText
                className={cx(
                  isPrimary
                    ? styles.richTextEditorMainLanguage
                    : styles.richTextEditorSubLanguage,
                  className
                )}
                value={valueGetter()}
                maxCharacters={1500}
                inputClassName={cx(styles.richTextInput, className)}
                onChange={(text: any) => {
                  const updates = onChangeUpdates(text);

                  if (updates[`${keyColumn}_l10n`]) {
                    // Return the original object except for the l10n key with value ""
                    const updatesModified = {
                      ...updates,
                      [`${keyColumn}_l10n`]: Object.fromEntries(
                        Object.entries(updates[`${keyColumn}_l10n`]).filter(
                          ([key, _value]) => key !== ''
                        )
                      ),
                    };

                    updateDraftPayloadForEmailSettings(updatesModified);
                  } else {
                    updateDraftPayloadForEmailSettings(updates);
                  }
                }}
                readOnly={selectedOption === TextOptionEnum.Standard}
                testId={isPrimary ? 'richTextEditor' : 'richTextEditor_l10n'}
              />
            </div>
          )}
        </MultiLanguageWrapper>
      </div>
    </div>
  );
};
