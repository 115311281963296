import React, { memo } from 'react';

import cx from 'classnames';

import Thumbnail from '../general/Thumbnail';

import styles from './StackListView.scss';

function StackListView({
  image,
  logo,
  highlight,
  children,
  name,
  className,
  style,
}: any) {
  return (
    <div className={cx(styles.StackListView, className)} style={style}>
      <Thumbnail
        className={styles.logoWrapper}
        src={logo || image}
        name={name}
        highlight={highlight}
      />
      <div className={styles.textWrapper}>{children}</div>
    </div>
  );
}

export default memo(StackListView);
