import React, { useMemo, useState } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { colors } from 'lane-shared/config/colors';
import { SpacingType } from 'lane-shared/config/spacing';
import upgradeOutdatedBurstInteractionData from 'lane-shared/helpers/integrations/ShopifyBurst/upgradeOutdatedBurstInteractionData';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import { ContentType } from 'lane-shared/properties/baseTypes/Content';
import {
  BurstDraftInteractionData,
  CURRENT_SHOPIFY_BURST_INTERACTION_DATA_SCHEMA_VERSION,
  ShopifyBurstReviewSectionTitle,
  ShopifyBurstInteractionType,
} from 'lane-shared/types/integrations/ShopifyBurst';

import { IconButton } from 'components/general';
import {
  ReviewAttendees,
  ReviewInteractionData,
  ReviewDate,
  ReviewLocation,
  ReviewName,
  ReviewRoom,
} from 'components/integrations/ShopifyBurst/components/ReviewAndSubmit';
import BurstEditL9Modal from 'components/integrations/ShopifyBurst/components/ReviewAndSubmit/ModalEditView/BurstEditL9Modal';
import { Flex } from 'components/layout';

import BurstAttendeesModal from '../BurstAttendeesModal';
import BurstEditAdminModal from './ModalEditView/BurstEditAdminView';
import { useModalContext } from 'contexts/ModalContext';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  burstData: ShopifyBurstInteractionType | null;
  rsvpContent?: ContentType | null;
  mb?: SpacingType;
  onDataUpdated: (data: BurstDraftInteractionData) => void;
  isAdminView?: boolean;
};

export default function BurstReadOnlyView({
  burstData: possiblyOutdatedBurstData,
  rsvpContent,
  mb = 9,
  className,
  style,
  onDataUpdated,
  isAdminView,
}: Props) {
  const {
    questionnaireContent,
    specialRequestContent,
    diyQuestionsContent,
  } = useShopifyBurstContext();
  const [editTarget, setEditTarget] = useState<Partial<
    keyof ShopifyBurstInteractionType
  > | null>(null);
  const { t } = useTranslation();
  const { showModal, closeModal } = useModalContext();

  const burstData = useMemo(() => {
    if (
      questionnaireContent &&
      possiblyOutdatedBurstData &&
      possiblyOutdatedBurstData.schemaVersion !==
        CURRENT_SHOPIFY_BURST_INTERACTION_DATA_SCHEMA_VERSION
    ) {
      return upgradeOutdatedBurstInteractionData({
        interactionData: possiblyOutdatedBurstData,
        questionnaireContent,
      });
    }

    return possiblyOutdatedBurstData;
  }, [possiblyOutdatedBurstData, questionnaireContent]);

  if (burstData === null) {
    return null;
  }

  function openAttendeeModal() {
    showModal(
      <BurstAttendeesModal
        onClose={closeModal}
        attendees={burstData!.attendees || []}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'import("/Users/william/lane/lane-next/packag... Remove this comment to see the full error message
        rsvpContent={rsvpContent}
      />
    );
  }

  const renderEditIcon = (
    target: Partial<keyof ShopifyBurstInteractionType>
  ) => {
    return isAdminView ? (
      <IconButton
        iconSet="FontAwesome"
        icon="pencil"
        type="fal"
        onClick={() => setEditTarget(target)}
        style={{ color: colors.charcoal[4] }}
      />
    ) : null;
  };

  return (
    <Flex direction="column" mb={mb} className={className} style={style}>
      <Flex direction="row" align="center">
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
        <ReviewName burstName={burstData.name} />
        {renderEditIcon('name')}
      </Flex>

      <ReviewInteractionData
        interaction={burstData.questionnaireInteraction}
        content={burstData.questionnaireContent || questionnaireContent!}
        title={t(ShopifyBurstReviewSectionTitle.BurstQuestionnaire)}
        icon={<Icon set="FontAwesome" name="clipboard-check" type="far" />}
      />

      {!burstData.isDiyBurst && (
        <Flex direction="row" align="center">
          <ReviewLocation
            primaryRoomBooking={burstData.primaryRoomBooking!}
            port={burstData.port!}
          />

          {renderEditIcon('port')}
        </Flex>
      )}
      <Flex direction="row" align="center">
        <ReviewDate
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          starts={burstData.dateRange.startDate}
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          ends={burstData.dateRange.endDate}
          timeZone={burstData.portTimeZone}
        />
        {renderEditIcon('dateRange')}
      </Flex>

      {burstData.isDiyBurst === true && (
        <ReviewInteractionData
          interaction={burstData.diyQuestions}
          content={burstData.diyQuestionsContent || diyQuestionsContent!}
          title={t(ShopifyBurstReviewSectionTitle.BurstDIYQuestions)}
          icon={<Icon set="FontAwesome" name="construction" type="far" />}
        />
      )}

      <Flex direction="row" align="center">
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        <ReviewAttendees numberOfAttendees={burstData.attendees.length} />
        <IconButton
          iconSet="FontAwesome"
          icon="chevron-right"
          type="fal"
          onClick={openAttendeeModal}
          style={{ color: colors.charcoal[4] }}
        />
      </Flex>

      {burstData.primaryRoomBooking ? (
        <Flex direction="row" align="center">
          <ReviewRoom primaryRoomBooking={burstData.primaryRoomBooking} />
          {renderEditIcon('roomId')}
        </Flex>
      ) : null}
      <ReviewInteractionData
        interaction={burstData.specialRequestInteraction}
        content={burstData.specialRequestContent || specialRequestContent!}
        title={t(ShopifyBurstReviewSectionTitle.BurstSpecialRequest)}
        icon={<Icon set="FontAwesome" name="file-plus" type="far" />}
      />

      <BurstEditL9Modal
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<ShopifyBurstInteractionType>' is not... Remove this comment to see the full error message
        burstData={burstData}
        onDataUpdated={onDataUpdated}
        showModal={showModal}
        closeModal={closeModal}
        isAdminView={isAdminView}
      />

      <BurstEditAdminModal
        onDataUpdated={onDataUpdated}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<ShopifyBurstInteractionType>' is not... Remove this comment to see the full error message
        burstData={burstData}
        onClose={() => {
          setEditTarget(null);
        }}
        editTarget={editTarget}
      />
    </Flex>
  );
}
