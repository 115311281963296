/* eslint-disable no-restricted-globals */
import { ReservableUnitTypesEnum } from 'lane-shared/types/features/ReservableFeatureProperties';

export const getDropdownOptionsForUnitType = (
  unitsAfterNow: number,
  unitsAfterNowType: ReservableUnitTypesEnum,
  allowedMaxBufferTime: number
) => {
  const intervals: number[] = [];

  switch (unitsAfterNowType) {
    case 'Minutes':
      for (let i = 1; i * unitsAfterNow <= allowedMaxBufferTime; i++) {
        intervals.push(i * unitsAfterNow);
      }

      break;
    case 'Days':
    case 'Weeks':
    case 'Months':
      break;
    default:
      for (let i = 0; i * unitsAfterNow <= allowedMaxBufferTime; i++) {
        intervals.push(i * unitsAfterNow);
      }

      break;
  }

  return intervals;
};

export const getIsEnabled = (time?: number) => {
  return Boolean(time !== undefined && !isNaN(time) && time >= 0);
};

export default {
  getDropdownOptionsForUnitType,
};
