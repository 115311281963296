import React, { useState, useContext } from 'react';

import cx from 'classnames';
import { CircleListView } from 'components';
import { useTranslation } from 'react-i18next';

import { revokeAccessManager } from 'lane-shared/graphql/accessControl';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { ChannelsContext } from 'lane-shared/contexts';

import { H5 } from 'components/typography';

import { ErrorModal } from '../ErrorModal';
import { Button, M, Modal } from 'design-system-web';

import styles from './styles.scss';
import { useMutation } from '@apollo/client';
import { UserInfo } from 'lane-web/src/domains/accessControl/types/AccessControl';
import { SPECIAL_GROUP_ROLES } from 'lane-shared/helpers/constants/channel';

// define user companies type
type UserCompanies = {
  id: string;
  name: string;
};

type Props = {
  channelId: string;
  user: UserInfo;
  isOpen: boolean;
  onClose: (triggerRefresh?: boolean) => void;
};

export const RevokeAccessManagerModal = ({
  channelId,
  user,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { channels } = useContext(ChannelsContext);

  const channel = channels.find((channel: any) => channel._id === channelId);

  const [revokeAccessManagerMutation] = useMutation(revokeAccessManager);

  const isCompanyAccessAdmin = user?.roles?.includes(
    SPECIAL_GROUP_ROLES.COMPANY_ACCESS_ADMIN
  );

  const isAccessAdmin = user?.roles?.includes(
    SPECIAL_GROUP_ROLES.ACCESS_MANAGER
  );

  const getUserRoleDisplayText = () => {
    if (isAccessAdmin) {
      return t(
        'web.admin.accessControl.userAccess.modal.revokeAccessManager.accessAdmin'
      );
    }

    if (isCompanyAccessAdmin) {
      return t(
        'web.admin.accessControl.userAccess.modal.revokeAccessManager.companyAccessManager'
      );
    }

    return '';
  };

  const [hasError, setHasError] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
  };

  const handleSaveChanges = async () => {
    try {
      await revokeAccessManagerMutation({
        variables: {
          channelId,
          userId: user._id,
        },
      });
      setHasError(false);
      onClose(true);
      window.Toast.show(
        t(
          'web.admin.accessControl.userAccess.modal.revokeAccessManager.success',
          { fullName: user.name, groupRoleName: getUserRoleDisplayText() }
        )
      );
    } catch (err) {
      setHasError(true);
      console.error(err);
    }
  };

  if (hasError) {
    return (
      <ErrorModal
        title={t(
          'web.admin.accessControl.userAccess.modal.revokeAccessManager.error.title'
        )}
        description={t(
          'web.admin.accessControl.userAccess.modal.revokeAccessManager.error.description',
          { fullName: user.name, groupRoleName: getUserRoleDisplayText() }
        )}
        buttonTitle={t('Retry')}
        isOpen={hasError}
        onClose={onClose}
        onClick={handleSaveChanges}
      />
    );
  }

  const getUserChannels = (): UserCompanies[] => {
    const userChannels: UserCompanies[] = [];

    if (isAccessAdmin) {
      userChannels.push({
        id: channelId,
        name: channel?.profile?.name ?? '',
      });
    }

    if (isCompanyAccessAdmin && user?.companies?.length) {
      userChannels.push(...(user?.companies ?? []));
    }

    return userChannels;
  };

  const getChannelProfile = (channelId: string) => {
    return channels.find((channel: any) => channel._id === channelId);
  };

  return (
    <Modal
      isOpen={!!(isOpen && channel)}
      onClose={handleClose}
      title={t(
        !isAccessAdmin
          ? 'web.admin.accessControl.userAccess.modal.revokeAccessManager.company.title'
          : 'web.admin.accessControl.userAccess.modal.revokeAccessManager.property.title',
        {
          fullname: user.name,
        }
      )}
      className={cx(styles.modal)}
      size="large"
      testId="revokeAccessManagerModal"
    >
      <div data-test="access-control-modal">
        <M size="xlarge" variant="secondary" bold={false}>
          {t(
            isCompanyAccessAdmin
              ? 'web.admin.accessControl.userAccess.modal.revokeAccessManager.company.description'
              : 'web.admin.accessControl.userAccess.modal.revokeAccessManager.property.description'
          )}
        </M>
        {getUserChannels().map(company => {
          const channelProfileInfo = getChannelProfile(company.id);

          return (
            <CircleListView
              style={{ padding: '0px' }}
              image={imageUrl(channelProfileInfo?.profile?.image)}
              logo={imageUrl(channelProfileInfo?.profile?.logo)}
              name={company?.name}
              className="mt-4"
              key={company.id}
            >
              <H5>{company?.name}</H5>
            </CircleListView>
          );
        })}

        <div className={cx(styles.assignAccessManagerModalButtons)}>
          <Button
            testId="revoke-access-manager-submit-button"
            size="large"
            onClick={handleSaveChanges}
          >
            {t(
              'web.admin.accessControl.userAccess.modal.revokeAccessManager.actionButton'
            )}
          </Button>
          <Button
            variant="secondary"
            testId="revoke-access-manager-cancel-button"
            onClick={handleClose}
            size="large"
          >
            {t(
              'web.admin.accessControl.userAccess.modal.revokeAccessManager.cancel'
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
