import gql from 'graphql-tag';

export const queryAccessIntegrations = gql`
  query getAccessIntegrations($search: ChannelSearch) {
    me {
      channels(search: $search) {
        _id
        _created
        integrations {
          _id
          settings
          channel {
            _id
            name
            profile {
              _id
              name
              logo
            }
          }
          integration {
            _id
            name
            type
            category
          }
        }
      }
    }
  }
`;
