import React, { useState, useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext, AnalyticsContext } from 'lane-shared/contexts';
import { updateUserLoginsMutation } from 'lane-shared/graphql/user';
import { pause } from 'lane-shared/helpers';
import { emitEmailAddress } from 'lane-shared/helpers/analytics/emitEmailAddress';
import getValidationMessages from 'lane-shared/helpers/getValidationMessages';
import { UserLoginTypeEnum, UserLoginType } from 'lane-shared/types/UserLogin';
import { validateEmail } from 'lane-shared/validation';

import Input from 'components/form/Input';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';

import styles from './AddLoginEmail.scss';

export default function AddLoginEmail({
  className,
  style,
  onClose,
  isOpen,
}: any) {
  const { user, refetch } = useContext(UserDataContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [validation, setValidation] = useState(null);
  const [email, setEmail] = useState<string>('');
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);

  async function validate() {
    try {
      await validateEmail.validate({ email });
      setValidation(null);
    } catch (err) {
      setValidation(err);
    }
  }

  async function createLogin() {
    setLoading(true);

    try {
      await pause();
      const { data } = await getClient().mutate({
        mutation: updateUserLoginsMutation,
        variables: {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          userId: user._id,
          userLogin: {
            key: email,
            type: UserLoginTypeEnum.Email,
          },
        },
        fetchPolicy: 'no-cache',
      });

      await refetch();

      const userLogins = data?.updateUser?.logins;
      const newLogin = userLogins.find(
        (loginData: UserLoginType) =>
          loginData.key === email.trim().toLowerCase()
      );

      emitEmailAddress({
        isLoginEmailCreated: true,
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        userId: user._id,
        analytics,
        selectedLoginId: newLogin._id,
      });

      await window.Alert.alert({
        title: t('Email added!'),
        message: t('Check your email inbox for a confirmation email'),
      });

      setEmail('');

      if (onClose) {
        onClose();
      }
    } catch (error) {
      await window.Alert.alert({
        title: t('Unable to add email'),
        message: t(
          "I wasn't able to add this email address, please see the error below and try again."
        ),
        error,
      });
    }

    setLoading(false);
  }

  return (
    <ModalBackground
      onClose={onClose}
      isOpen={isOpen}
      className={styles.background}
    >
      <ResizableWindow
        name="channelSelectorButton"
        className={styles.window}
        onClose={onClose}
        defaultPosition={ResizableWindow.centerPosition()}
        showHeader
      >
        <div className={cx(styles.AddLoginEmail, className)} style={style}>
          <h1>{t('Add a new Email to your profile')}</h1>
          <div className={styles.emailForm}>
            <Input
              className={styles.input}
              placeholder={t('Email')}
              icon="mail"
              testId="addEmail"
              value={email}
              error={getValidationMessages(validation, 'email')}
              onChange={email => {
                setEmail(email);
                validate();
              }}
              onSubmit={() => createLogin()}
            />
          </div>

          <ControlMenu className={styles.bottomMenu}>
            <hr />
            <Button onClick={onClose}>Cancel</Button>

            <Button
              variant="contained"
              loading={loading}
              disabled={!email || !!validation}
              onClick={() => createLogin()}
            >
              {t('Add New Email')}
            </Button>
          </ControlMenu>
        </div>
      </ResizableWindow>
    </ModalBackground>
  );
}
