import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { constructProperty } from 'lane-shared/helpers/content';
import { getPackagedType } from 'lane-shared/helpers/properties';
import Types from 'lane-shared/properties/Types';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { ThemeType } from 'lane-shared/types/Theme';
import { LibraryType } from 'lane-shared/types/libraries/LibraryType';
import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';
import { PropertyType } from 'lane-shared/types/properties/Property';

import { ServiceRequestSubmissionForm } from 'lane-web/src/domains/workOrder/feature/components/serviceRequestSubmissionForm';

import GuestInviteFeature from '../../../features/GuestInviteFeature';
import Label from '../../../general/Label';

import PropertyArrayInput from './PropertyArrayInput';
import PropertyInput from './PropertyInput';

import styles from './Property.scss';

type PropertyProps = {
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  object: any;
  property: PropertyType;
  propertyKey: string;
  value: any;
  label?: string;
  description?: string;
  onChange: (value: any) => void;
  onPropertyChange: (args: { key: string; value: any }) => void;
  keys?: string[];
  channel: ChannelType;
  library?: LibraryType;
  theme?: ThemeType;
  timeZone?: string;
  workOrderVariant?: boolean;
};

export default function PropertyComponent({
  disabled,
  object,
  property,
  propertyKey,
  label,
  description,
  value,
  onChange,
  onPropertyChange,
  keys,
  channel,
  library,
  theme,
  timeZone,
  className,
  style,
  workOrderVariant,
}: PropertyProps) {
  const types = Types.getTypes();
  const type = Types.getType(property.type);
  const packagedType = getPackagedType(property);
  const { t } = useTranslation();

  function onArrayValueChange(value: any) {
    /* TM-3603 when an array value is inputted and removed by the user, it
    leaves '' which is truthy to the required validator. '' are replaced with
    null which is falsy and triggers the validator. */
    value = value.map((v: any) => (v === '' ? null : v));
    onChange(value);
  }

  // some special cases.
  switch (type.name) {
    case types.GuestInviteGuestType.name:
      return (
        <GuestInviteFeature
          onChange={value => onChange(value)}
          value={value}
          showLaneUsers
        />
      );
    case types.ServiceRequestType?.name:
      return (
        <ServiceRequestSubmissionForm
          onChange={value => onChange(value)}
          channelId={channel._id}
        />
      );
    default:
    // there will likely be more special cases in the future
  }

  // there is one special packagedType that doesn't get rendered as an array.
  if (property.isArray && packagedType !== PackagedTypeEnum.Checkboxes) {
    const arr = value || [];

    return (
      <fieldset
        className={cx(styles.Property, className)}
        style={style}
        disabled={disabled}
      >
        <Label TooltipComponent={t(property.description || '')}>
          {t(property.friendlyName || property.name || '')}
          <Icon
            disabled={disabled}
            className={styles.plusButton}
            name="plus-circle"
            set={ICON_SET_FONTAWESOME}
            testId="addRequiredContentField"
            onClick={() =>
              onChange([...arr, constructProperty(property, true)])
            }
          />
        </Label>
        {arr.map((value: any, i: any) => (
          <PropertyArrayInput
            key={value?._id || `${property.name}-${i}`}
            disabled={disabled}
            object={object}
            property={property}
            propertyKey={`${propertyKey}[${i}]`}
            value={value}
            onPropertyChange={onPropertyChange}
            onChange={onArrayValueChange}
            onDelete={() => onChange(arr.filter((v: any, ix: any) => ix !== i))}
            keys={keys}
            channel={channel}
            library={library}
            theme={theme}
            timeZone={timeZone}
            i={i}
            arr={arr}
            workOrderVariant={workOrderVariant}
          />
        ))}
      </fieldset>
    );
  }

  // if this is not a base type, we need to render a UI to allow inputting
  // all the sub-properties of this type.
  if (!Types.isBaseType(type.name)) {
    return (
      <fieldset className={styles.wrapper}>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        {Object.entries(type.properties).map(([key, subProperty]) => (
          <div key={key}>
            {(!subProperty.isArray ||
              subProperty.packagedType === PackagedTypeEnum.Checkboxes) && (
              <Label TooltipComponent={subProperty.description}>
                {t(subProperty.friendlyName || subProperty.name || key)}
              </Label>
            )}
            <PropertyComponent
              disabled={disabled}
              timeZone={timeZone}
              object={object}
              channel={channel}
              library={library}
              theme={theme}
              property={subProperty}
              value={value[key]}
              keys={keys}
              onChange={subValue => {
                value[key] = subValue;
                onChange(value);
              }}
              onPropertyChange={e => {
                value[e.key] = e.value;
                onPropertyChange({ key, value });
              }}
              propertyKey={propertyKey}
            />
          </div>
        ))}
      </fieldset>
    );
  }

  return (
    <fieldset
      className={cx(styles.Property, className)}
      style={style}
      data-test="validator-fieldset"
    >
      <PropertyInput
        disabled={disabled}
        timeZone={timeZone}
        object={object}
        channel={channel}
        library={library}
        label={label}
        description={description}
        theme={theme}
        property={property}
        propertyKey={propertyKey}
        value={value}
        onChange={onChange}
        onPropertyChange={onPropertyChange}
        keys={keys}
      />
    </fieldset>
  );
}
