import React, { useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { AnalyticsContext } from 'lane-shared/contexts';
import { syncGroupRoleForExistingUsers } from 'lane-shared/graphql/mutation';
import {
  ANALYTIC_CHANNEL_SETTINGS_CHANNEL_JOIN_RULE_SYNCED,
  ANALYTIC_CHANNEL_SETTINGS_CHANNEL_JOIN_RULE_SYNC_FAILED,
} from 'lane-shared/helpers/constants/analytics';
import { GroupRoleJoinRuleType } from 'lane-shared/types/GroupRoleJoinRuleType';

import { Button } from 'components/general';

import { Channel } from '../ChannelSettings';

import styles from '../ChannelSettings.scss';

export function SyncJoinRulesButton({
  channel,
  groupRoleJoinRule,
}: {
  channel: Channel;
  groupRoleJoinRule: Partial<GroupRoleJoinRuleType>;
}) {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function syncGroupRole() {
    const { groupRoleId, joinGroupRoleId } = groupRoleJoinRule;

    setIsLoading(true);

    try {
      await getClient().mutate({
        mutation: syncGroupRoleForExistingUsers,
        variables: {
          syncGroupRole: {
            groupRole: { _id: groupRoleId },
            joinGroupRole: { _id: joinGroupRoleId },
          },
        },
      });

      setIsLoading(false);

      analytics.track(ANALYTIC_CHANNEL_SETTINGS_CHANNEL_JOIN_RULE_SYNCED, {
        channelId: channel?._id,
        channelName: channel?.name,
        groupRoleId,
        joinGroupRoleId,
      });

      window.Toast.show(
        <p>{t('web.admin.channel.profile.settings.joinRules.sync.success')}</p>
      );
    } catch (ex) {
      setIsLoading(false);
      analytics.track(ANALYTIC_CHANNEL_SETTINGS_CHANNEL_JOIN_RULE_SYNC_FAILED, {
        channelId: channel?._id,
        channelName: channel?.name,
        groupRoleId,
        joinGroupRoleId,
        error: ex,
      });

      window.Toast.show(
        <p>
          {t('web.admin.channel.profile.settings.joinRules.sync.fail', {
            errorMessage: (ex as any).message,
          })}
        </p>
      );
    }
  }

  return (
    <Button
      loading={isLoading}
      testId={`sync-button-${groupRoleJoinRule._id}`}
      className={styles.syncButton}
      onClick={syncGroupRole}
    >
      {t('web.admin.channel.profile.settings.joinRules.syncButton')}
    </Button>
  );
}
