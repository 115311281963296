import { useEffect, useState } from 'react';
import { useRemainingQuantity } from 'lane-shared/hooks/contentState';
import { explodeFeatures } from 'lane-shared/helpers/features';

export function useContentQuantityState(content: any, changeState: any) {
  const { quantityFeature } = explodeFeatures(content?.features);
  const {
    quantityRemaining,
    fetchTotalQuantityClaimedCount,
    loading,
  } = useRemainingQuantity(content);

  const [isButtonDisabled, setIsButtonDisabled] = useState(
    quantityFeature ? quantityRemaining === 0 : false
  );

  useEffect(() => {
    const fetchData = async () => {
      await fetchTotalQuantityClaimedCount();
      setIsButtonDisabled(quantityFeature ? quantityRemaining === 0 : false);
    };

    if (quantityFeature) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchTotalQuantityClaimedCount();
      setIsButtonDisabled(quantityFeature ? quantityRemaining === 0 : false);
    };

    if (quantityFeature || changeState) {
      fetchData();
    }
  }, [changeState, quantityFeature, quantityRemaining]);

  return {
    isButtonDisabled,
    loading,
  };
}
