import { Metatag } from '../../types/Metatag';
import { META_TAG_TYPE_SIMPLE } from '../constants/metatags';

export default function getDefaultMetatagValue(
  metatag: Metatag
): object | void {
  if (metatag.type === META_TAG_TYPE_SIMPLE) {
    const valueType = metatag.properties.type;

    switch (valueType) {
      case 'Boolean':
        return { Boolean: false };
      case 'String':
        return { String: '' };
      case 'Number':
        return { Number: 0 };
      default:
        return { [valueType.toString()]: null };
    }
  }
}
