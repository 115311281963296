import { useMemo } from 'react';

import { DateTime, Interval } from 'luxon';

import { ReservableUnavailabilityRange } from '../../useReservableAvailabilityByRange';
import { IntervalRange } from './types';

type UnavailableTimes = Pick<ReservableUnavailabilityRange, 'interval'>[];

interface SanitizedUnavailableTime {
  startDate: Date;
  endDate: Date;
}

type Props = {
  startDate: Date | undefined;
  endDate: Date | undefined;
  intervalMinutes: number;
  unavailableTimes?: UnavailableTimes;
};

function isLeftOverInterval(intervalMinutes: number, interval?: Interval) {
  if (!interval) {
    return false;
  }

  const intervalDiffMinutes = interval.end.diff(interval.start, 'minutes')
    .minutes;

  return interval && intervalDiffMinutes !== intervalMinutes;
}

function isStartTimeDisabled(
  startTime: DateTime,
  intervalMinutes: number,
  unavailableTimes: SanitizedUnavailableTime[]
) {
  const endTime = startTime.plus({ minutes: intervalMinutes });

  for (const unavailableTime of unavailableTimes) {
    const unavailableStartTime = DateTime.fromJSDate(unavailableTime.startDate);
    const unavailableEndTime = DateTime.fromJSDate(unavailableTime.endDate);

    const reservableTimeInterval = Interval.fromDateTimes(startTime, endTime);
    const unavailableTimeInterval = Interval.fromDateTimes(
      unavailableStartTime,
      unavailableEndTime
    );

    if (reservableTimeInterval.overlaps(unavailableTimeInterval)) {
      return true;
    }
  }

  return false;
}

function isEndTimeDisabled(
  endTime: DateTime,
  unavailableTimes: SanitizedUnavailableTime[]
) {
  for (const unavailableTime of unavailableTimes) {
    const unavailableStartTime = DateTime.fromJSDate(unavailableTime.startDate);
    const unavailableEndTime = DateTime.fromJSDate(unavailableTime.endDate);

    if (endTime > unavailableStartTime && endTime <= unavailableEndTime) {
      return true;
    }
  }

  return false;
}

function mapReservableTime(date: Date, isDisabled: boolean) {
  return {
    date,
    disabled: isDisabled,
  };
}

function calculateAndMapReservableEndTimes(
  startDateTime: DateTime,
  endDateTime: DateTime,
  intervalMinutes: number,
  unavailableTimes: SanitizedUnavailableTime[]
) {
  const intervals = Interval.fromDateTimes(startDateTime, endDateTime).splitBy({
    minutes: intervalMinutes,
  });
  let isCurrentOrPreviousEndTimeDisabled = false;

  const lastIntervalOfTheDay = intervals[intervals.length - 1];

  if (isLeftOverInterval(intervalMinutes, lastIntervalOfTheDay)) {
    intervals.pop();
  }

  const endTimes = intervals.map(interval => {
    isCurrentOrPreviousEndTimeDisabled =
      isCurrentOrPreviousEndTimeDisabled ||
      isEndTimeDisabled(interval.end, unavailableTimes);

    const reservableTime = mapReservableTime(
      interval.end.toJSDate(),
      isCurrentOrPreviousEndTimeDisabled
    );

    return reservableTime;
  });

  return endTimes;
}

function calculateAndMapReservableTimes(
  startDate: Date,
  endDate: Date,
  intervalMinutes: number,
  unavailableTimes: SanitizedUnavailableTime[]
) {
  const startDateTime = DateTime.fromJSDate(startDate);
  const endDateTime = DateTime.fromJSDate(endDate);
  const dayIntervalRange = Interval.fromDateTimes(startDateTime, endDateTime);

  const intervalsForTheDay = dayIntervalRange.splitBy({
    minutes: intervalMinutes,
  });
  const lastIntervalOfTheDay =
    intervalsForTheDay[intervalsForTheDay.length - 1];

  if (isLeftOverInterval(intervalMinutes, lastIntervalOfTheDay)) {
    intervalsForTheDay.pop();
  }

  const reservableIntervals = intervalsForTheDay.map(interval => {
    const endTimes = calculateAndMapReservableEndTimes(
      interval.start,
      endDateTime,
      intervalMinutes,
      unavailableTimes
    );
    const isDisabled = isStartTimeDisabled(
      interval.start,
      intervalMinutes,
      unavailableTimes
    );
    const reservableTime = mapReservableTime(
      interval.start.toJSDate(),
      isDisabled
    );

    return { startTime: reservableTime, endTimes };
  });

  return reservableIntervals;
}

function sanitizeUnavailableTimes(unavailableTimes: UnavailableTimes) {
  const sanitizedTimes: SanitizedUnavailableTime[] = [];

  for (const time of unavailableTimes) {
    if (time.interval.startDate && time.interval.endDate) {
      sanitizedTimes.push({
        startDate: time.interval.startDate,
        endDate: time.interval.endDate,
      });
    }
  }

  return sanitizedTimes;
}

export function useReservableIntervals({
  startDate,
  endDate,
  intervalMinutes,
  unavailableTimes = [],
}: Props): {
  intervals: IntervalRange[];
} {
  const intervals = useMemo(() => {
    if (!startDate || !endDate) {
      return [];
    }

    const sanitizedUnavailableTimes = sanitizeUnavailableTimes(
      unavailableTimes
    );

    const reservableTimes = calculateAndMapReservableTimes(
      startDate,
      endDate,
      intervalMinutes,
      sanitizedUnavailableTimes
    );

    return reservableTimes;
  }, [startDate, endDate, unavailableTimes, intervalMinutes]);

  return {
    intervals,
  };
}
