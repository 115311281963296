import { ContentCategoryEnum } from '../../types/content/ContentCategoryEnum';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

// TODO: TypeScript string literal for these permissions would be ideal

export const PERMISSION_ADMIN = 'admin';
export const PERMISSION_ANALYTICS = 'analytics';
export const PERMISSION_ANALYTICS_ADVANCED = 'analytics.advanced';
export const PERMISSION_ANALYTICS_WORK_ORDERS = 'analytics.work.orders';
export const PERMISSION_ANALYTICS_ACCESS = 'analytics.access';
export const PERMISSION_ANALYTICS_VISITOR_MANAGEMENT =
  'analytics.visitor.management';
export const PERMISSION_PORTAL_ACCESS = 'portal';
export const PERMISSION_PORTAL_ACCESS_MOBILE = 'portal.mobile';
export const PERMISSION_PROFILE_UPDATE = 'profile.update';
export const PERMISSION_ADDRESS_UPDATE = 'address.update';
export const PERMISSION_SETTINGS_UPDATE = 'settings.update';
export const PERMISSION_SETTINGS_DEBUGGER = 'settings.debugger';
export const PERMISSION_CHANNEL_CREATE = 'channel.create';
export const PERMISSION_CHANNEL_DELETE = 'channel.delete';
export const PERMISSION_CHANNEL_RELATIONSHIP_READ = 'channel.relationship.read';
export const PERMISSION_CHANNEL_RELATIONSHIP_CREATE =
  'channel.relationship.create';
export const PERMISSION_CHANNEL_RELATIONSHIP_UPDATE =
  'channel.relationship.update';
export const PERMISSION_CHANNEL_RELATIONSHIP_DELETE =
  'channel.relationship.delete';
export const PERMISSION_CONTENT_PERK_CREATE = 'content.perk.create';
export const PERMISSION_CONTENT_PERK_PUBLISH = 'content.perk.publish';
export const PERMISSION_CONTENT_PERK_READ = 'content.perk.read';
export const PERMISSION_CONTENT_PERK_UPDATE = 'content.perk.update';
export const PERMISSION_CONTENT_PERK_DELETE = 'content.perk.delete';
export const PERMISSION_CONTENT_PROMOTION_CREATE = 'content.promotion.create';
export const PERMISSION_CONTENT_PROMOTION_PUBLISH = 'content.promotion.publish';
export const PERMISSION_CONTENT_PROMOTION_READ = 'content.promotion.read';
export const PERMISSION_CONTENT_PROMOTION_UPDATE = 'content.promotion.update';
export const PERMISSION_CONTENT_PROMOTION_DELETE = 'content.promotion.delete';
export const PERMISSION_CONTENT_NOTICE_CREATE = 'content.notice.create';
export const PERMISSION_CONTENT_URGENT_MESSAGE_CREATE =
  'content.urgent-message.create';
export const PERMISSION_CONTENT_NOTICE_PUBLISH = 'content.notice.publish';
export const PERMISSION_CONTENT_NOTICE_READ = 'content.notice.read';
export const PERMISSION_CONTENT_NOTICE_UPDATE = 'content.notice.update';
export const PERMISSION_CONTENT_NOTICE_DELETE = 'content.notice.delete';
export const PERMISSION_CONTENT_CREATE = 'content.create';
export const PERMISSION_CONTENT_PUBLISH = 'content.publish';
export const PERMISSION_CONTENT_READ = 'content.read';
export const PERMISSION_CONTENT_UPDATE = 'content.update';
export const PERMISSION_CONTENT_DELETE = 'content.delete';
export const PERMISSION_CONTENT_POST_CREATE = 'content.post.create';
export const PERMISSION_CONTENT_POST_PUBLISH = 'content.post.publish';
export const PERMISSION_CONTENT_POST_READ = 'content.post.read';
export const PERMISSION_CONTENT_POST_UPDATE = 'content.post.update';
export const PERMISSION_CONTENT_POST_DELETE = 'content.post.delete';
export const PERMISSION_CONTENT_INTERACTIONS_READ = 'content.interactions.read';
export const PERMISSION_CONTENT_INTERACTIONS_UPDATE =
  'content.interactions.update';
export const PERMISSION_CONTENT_INTERACTIONS_DELETE =
  'content.interactions.delete';
export const PERMISSION_CONTENT_FEATURE_RESERVABLE =
  'content.features.reservable';
export const PERMISSION_CONTENT_FEATURE_MENU = 'content.features.menu';
export const PERMISSION_CONTENT_FEATURE_ESSENSYS = 'content.features.essensys';

export const PERMISSION_CONTENT_PAGE_CREATE = 'content.page.create';
export const PERMISSION_CONTENT_PAGE_PUBLISH = 'content.page.publish';
export const PERMISSION_CONTENT_PAGE_READ = 'content.page.read';
export const PERMISSION_CONTENT_PAGE_UPDATE = 'content.page.update';
export const PERMISSION_CONTENT_PAGE_DELETE = 'content.page.delete';
export const PERMISSION_MERCHANT_CREATE = 'merchant.create';
export const PERMISSION_MERCHANT_READ = 'merchant.read';
export const PERMISSION_MERCHANT_UPDATE = 'merchant.update';
export const PERMISSION_MERCHANT_DELETE = 'merchant.delete';
export const PERMISSION_WORKPLACE_MEMBERS_INVITE = 'subscribers.invite';
export const PERMISSION_USERS_WORKPLACE_MEMBERS_INVITE =
  'users.subscribers.invite';
export const PERMISSION_USERS_INFO = 'users.info';
export const PERMISSION_USERS_WORKPLACE_MEMBERS_READ = 'users.subscribers.read';
export const PERMISSION_USERS_WORKPLACE_MEMBERS_SEARCH =
  'users.subscribers.search';
export const PERMISSION_USERS_WORKPLACE_MEMBERS_DELETE =
  'users.subscribers.delete';
export const PERMISSION_USERS_ROLE_INVITE = 'users.role.invite';
export const PERMISSION_USERS_ROLE_READ = 'users.role.read';
export const PERMISSION_USERS_ROLE_SEARCH = 'users.role.search';
export const PERMISSION_USERS_ROLE_DELETE = 'users.role.delete';
export const PERMISSION_USERS_VERIFY_PRIMARY_EMAIL =
  'users.primary-email.verify';
export const PERMISSION_USERS_CAN_ADD_OTHER_USERS_TO_TENANTS =
  'users.add.tenant.member';
export const PERMISSION_USERS_CAN_REMOVE_OTHER_USERS_FROM_TENANTS =
  'users.remove.tenant.member';
export const PERMISSION_USERS_PASSWORD_RESET = 'users.password.reset';
export const PERMISSION_USERS_INVITE = 'users.invite';
export const PERMISSION_USERS_READ = 'users.read';
export const PERMISSION_USERS_SEARCH = 'users.search';
export const PERMISSION_USERS_DELETE = 'users.delete';
export const PERMISSION_MEDIA_READ = 'media.read';
export const PERMISSION_MEDIA_CREATE = 'media.create';
export const PERMISSION_MEDIA_UPDATE = 'media.update';
export const PERMISSION_MEDIA_DELETE = 'media.delete';
export const PERMISSION_DOCUMENTS_READ = 'document.read';
export const PERMISSION_DOCUMENTS_CREATE = 'document.create';
export const PERMISSION_DOCUMENTS_UPDATE = 'document.update';
export const PERMISSION_DOCUMENTS_DELETE = 'document.delete';
export const PERMISSION_TEMPLATES_READ = 'templates.read';
export const PERMISSION_TEMPLATES_CREATE = 'templates.create';
export const PERMISSION_TEMPLATES_UPDATE = 'templates.update';
export const PERMISSION_TEMPLATES_DELETE = 'templates.delete';
export const PERMISSION_LIBRARY_READ = 'library.read';
export const PERMISSION_LIBRARY_CREATE = 'library.create';
export const PERMISSION_LIBRARY_UPDATE = 'library.update';
export const PERMISSION_LIBRARY_DELETE = 'library.delete';
export const PERMISSION_GROUPS_READ = 'groups.read';
export const PERMISSION_GROUPS_CREATE = 'groups.create';
export const PERMISSION_GROUPS_UPDATE = 'groups.update';
export const PERMISSION_GROUPS_DELETE = 'groups.delete';
export const PERMISSION_GROUP_PRIMARY_LOCATION_UPDATE =
  'groups.primaryLocation.update';
export const PERMISSION_EMAIL_CREATE = 'email.create';
export const PERMISSION_EMAIL_SEND = 'email.send';
export const PERMISSION_SECTIONS_CREATE = 'sections.create';
export const PERMISSION_SECTIONS_READ = 'sections.read';
export const PERMISSION_SECTIONS_UPDATE = 'sections.update';
export const PERMISSION_SECTIONS_DELETE = 'sections.delete';
export const PERMISSION_SECTIONS_FILTERS = 'sections.filters';
export const PERMISSION_PROPERTY_MANAGE_RETAIL = 'property.manage.retail';
export const PERMISSION_PROPERTY_MANAGE_COMPANY = 'property.manage.company';
export const PERMISSION_PROPERTY_GUESTS_CREATE = 'property.guests.create';
export const PERMISSION_PROPERTY_GUESTS_READ = 'property.guests.read';
export const PERMISSION_PROPERTY_GUESTS_UPDATE = 'property.guests.update';
export const PERMISSION_PROPERTY_GUESTS_DELETE = 'property.guests.delete';
export const PERMISSION_PROPERTY_MAINTENANCE_CREATE =
  'property.maintenance.create';
export const PERMISSION_PROPERTY_MAINTENANCE_READ = 'property.maintenance.read';
export const PERMISSION_PROPERTY_MAINTENANCE_UPDATE =
  'property.maintenance.update';
export const PERMISSION_PROPERTY_MAINTENANCE_DELETE =
  'property.maintenance.delete';
export const PERMISSION_PROPERTY_HARDWARE_VIEW_UPDATE =
  'property.hardware.view-update';
export const PERMISSION_PIN_MANAGEMENT_VIEW = 'pin-management.view';
export const PERMISSION_PIN_MANAGEMENT_CREATE_EDIT_DELETE =
  'pin-management.create.edit.delete';
export const PERMISSION_INTEGRATION_CREATE = 'integration.create';
export const PERMISSION_INTEGRATION_READ = 'integration.read';
export const PERMISSION_INTEGRATION_UPDATE = 'integration.update';
export const PERMISSION_INTEGRATION_DELETE = 'integration.delete';
export const PERMISSION_METATAG_CREATE = 'metatag.create';
export const PERMISSION_METATAG_READ = 'metatag.read';
export const PERMISSION_METATAG_UPDATE = 'metatag.update';
export const PERMISSION_METATAG_DELETE = 'metatag.delete';
export const PERMISSION_PAYMENT_ACCOUNT_CREATE = 'paymentaccount.create';
export const PERMISSION_PAYMENT_ACCOUNT_READ = 'paymentaccount.read';
export const PERMISSION_PAYMENT_ACCOUNT_UPDATE = 'paymentaccount.update';
export const PERMISSION_PAYMENT_ACCOUNT_DELETE = 'paymentaccount.delete';
export const PERMISSION_TAB_CREATE = 'tab.create';
export const PERMISSION_TAB_READ = 'tab.read';
export const PERMISSION_TAB_UPDATE = 'tab.update';
export const PERMISSION_TAB_DELETE = 'tab.delete';
export const PERMISSION_WEBHOOK_CREATE = 'webhook.create';
export const PERMISSION_WEBHOOK_READ = 'webhook.read';
export const PERMISSION_WEBHOOK_UPDATE = 'webhook.update';
export const PERMISSION_WEBHOOK_DELETE = 'webhook.delete';
export const PERMISSION_CHANNEL_MODULE_CREATE = 'channel-module.create';
export const PERMISSION_CHANNEL_MODULE_UPDATE = 'channel-module.update';
export const PERMISSION_CHANNEL_MODULE_DELETE = 'channel-module.delete';
export const PERMISSION_AUDIENCE_CREATE = 'audience.create';
export const PERMISSION_AUDIENCE_READ = 'audience.read';
export const PERMISSION_AUDIENCE_DELETE = 'audience.delete';
export const PERMISSION_AUDIENCE_UPDATE_USE = 'audience.update-use';
export const PERMISSION_AUDIENCE_UPDATE_MANAGE = 'audience.update-manage';
export const PERMISSION_VISITOR_MANAGEMENT_LOGS_READ_ALL =
  'visitor-management.logs.read.all';
export const PERMISSION_VISITOR_MANAGEMENT_LOGS_READ =
  'visitor-management.logs.read';
export const PERMISSION_VISITOR_MANAGEMENT_HOST_SELECT_ALL =
  'visitor-management.host.select.all';
export const PERMISSION_VISITOR_MANAGEMENT_LOGS_EDIT_ALL =
  'visitor-management.logs.edit.all';
export const PERMISSION_VISITOR_MANAGEMENT_LOGS_EDIT =
  'visitor-management.logs.edit';
export const PERMISSION_VISITOR_MANAGEMENT_LOGS_PRINT_VISITOR_PASS =
  'visitor-management.logs.print.visitorpass';
export const PERMISSION_PROPERTY_AUTO_SETUP = 'property.auto-setup';
export const PERMISSION_PROPERTY_CSV_UPLOAD = 'property.csv-upload';
export const PERMISSION_EQUIPMENT_CREATE = 'equipment.create';
export const PERMISSION_EQUIPMENT_UPDATE = 'equipment.update';
export const PERMISSION_EQUIPMENT_READ = 'equipment.read';
export const PERMISSION_EQUIPMENT_DELETE = 'equipment.delete';
export const PERMISSION_WORK_ORDERS_MODULE_ACCESS = 'work-orders.module.access';
export const PERMISSION_RESERVATIONS_MODULE_ACCESS =
  'reservations.module.access';
export const PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM =
  'work-orders.service-request.assignable.team';
export const PERMISSION_WORK_ORDERS_SERVICE_REQUEST_VIEW =
  'work-orders.service-request.view';
export const PERMISSION_WORK_ORDERS_SERVICE_REQUEST_CREATE =
  'work-orders.service-request.create';
export const PERMISSION_WORK_ORDERS_SERVICE_REQUEST_SETTINGS =
  'work-orders.service-request.settings';
export const PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS =
  'work-orders.service-request.edit.status';
export const PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNTEAM =
  'work-orders.service-request.edit.assign-team';
export const PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNEE =
  'work-orders.service-request.edit.assignee';
export const PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_DATA =
  'work-orders.service-request.edit.data';
export const PERMISSION_WORK_ORDERS_SERVICE_REQUEST_LINK_EQUIPMENT =
  'work-orders.service-request.link.equipment';
export const PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ATTACH_FILE =
  'work-orders.service-request.attach.file';
export const PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW =
  'work-orders.equipment.view';
export const PERMISSION_WORK_ORDERS_EQUIPMENT_SETTINGS =
  'work-orders.equipment.settings';
export const PERMISSION_WORK_ORDERS_EQUIPMENT_ADD_EDIT =
  'work-orders.equipment.add-edit';
export const PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW =
  'work-orders.pm.schedules.view';
export const PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE =
  'work-orders.pm.schedules.create';
export const PERMISSION_WORK_ORDERS_PM_TASK_VIEW = 'work-orders.pm.tasks.view';
export const PERMISSION_WORK_ORDERS_PM_TASK_EDIT_STATUS =
  'work-orders.pm.tasks.edit.status';
export const PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNTEAM =
  'work-orders.pm.tasks.edit.assign-team';
export const PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNEE =
  'work-orders.pm.tasks.edit.assignee';
export const PERMISSION_WORK_ORDERS_PM_TASK_ATTACH_FILE =
  'work-orders.pm.tasks.attach.file';
export const PERMISSION_WORK_ORDERS_PM_TASK_GENERATE_SERVICE_REQUEST =
  'work-orders.pm.tasks.generate.service-request';
export const PERMISSION_ACCESS_CONTROL = 'access-control.admin';
export const PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ASSIGN_ACCESS_MANAGER =
  'access-control.admin.access.assign-access-manager';
export const PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE =
  'access-control.admin.access.enable';
export const PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_DISABLE =
  'access-control.admin.access.disable';
export const PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY =
  'access-control.admin.access.manage-company';
export const PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_ASSIGN =
  'access-control.admin.groups.assign';
export const PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_REVOKE =
  'access-control.admin.groups.revoke';
export const PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY =
  'access-control.admin.groups.manage-company';
export const PERMISSION_ACCESS_CONTROL_HID = 'access-control.hid';
export const PERMISSION_ACCESS_CONTROL_CCURE = 'access-control.ccure';
export const PERMISSION_COMPANY_INVOICES =
  'billing-payments.invoices.companyInvoices';
export const PERMISSION_PRODUCTS_MANAGE = 'billing-payments.products.manage';
export const PERMISSION_PRODUCT_EXCEPTIONS_MANAGE =
  'billing-payments.exceptions.write';
export const PERMISSION_CREDITS_MANAGE = 'billing-payments.credits.manage';
export const PERMISSION_BILLING_PAYMENTS_CHARGE_CREATE =
  'billing-payments.charges.create';
export const PERMISSION_BILLING_PAYMENTS_CHARGE_VOID =
  'billing-payments.charges.void';
export const PERMISSION_BILLING_PAYMENTS_COMPANY_RECURRING_CHARGES =
  'billing-payments.charges.recurring-charges';
export const PERMISSION_CHARGE_CODE_MANAGE =
  'billing-payments.chargeCode.manage';
export const PERMISSION_CAN_CANCEL_MEMBERSHIPS =
  'billing-payments.memberships.cancel';
export const PERMISSION_CAN_REFUND_TRANSACTIONS =
  'billing-payments.transactions.refund';
export const PERMISSION_UNIT_MANAGE = 'unit.manage';
export const PERMISSION_FLOOR_MANAGE = 'floor.manage';
export const PERMISSION_MESSAGING_AND_MARKETPLACE_MANAGE = 'marketplace.manage';

// special permissions can be placed on a channel and determine if a user
// has rights on a related channel.
export const SPECIAL_PERMISSIONS = [
  PERMISSION_PROPERTY_MANAGE_COMPANY,
  PERMISSION_PROPERTY_MANAGE_RETAIL,
  PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
  PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY,
];

/* Routing Permissions */

export const permissions = [
  {
    key: PERMISSION_ADMIN,
    description: 'shared.permissions.admin',
  },
  {
    key: PERMISSION_ANALYTICS,
    description: 'shared.permissions.analytics',
  },
  {
    key: PERMISSION_ANALYTICS_ADVANCED,
    description: 'shared.permissions.analytics.advanced',
  },
  {
    key: PERMISSION_ANALYTICS_WORK_ORDERS,
    description: 'shared.permissions.analytics.workOrders',
  },
  {
    key: PERMISSION_ANALYTICS_ACCESS,
    description: 'shared.permissions.analytics.access',
  },
  {
    key: PERMISSION_ANALYTICS_VISITOR_MANAGEMENT,
    description: 'shared.permissions.analytics.visitor.management',
  },
  {
    key: PERMISSION_PORTAL_ACCESS,
    description: 'shared.permissions.portal.access',
  },
  {
    key: PERMISSION_PORTAL_ACCESS_MOBILE,
    description: 'shared.permissions.portal.access.mobile',
  },
  {
    key: PERMISSION_PROFILE_UPDATE,
    description: 'shared.permissions.profile.update',
  },
  {
    key: PERMISSION_ADDRESS_UPDATE,
    description: 'shared.permissions.address.update',
  },
  {
    key: PERMISSION_SETTINGS_UPDATE,
    description: 'shared.permissions.settings.update',
  },
  {
    key: PERMISSION_SETTINGS_DEBUGGER,
    description: 'shared.permissions.settings.debugger',
  },
  {
    key: PERMISSION_CHANNEL_CREATE,
    description: 'shared.permissions.channel.create',
  },
  {
    key: PERMISSION_CHANNEL_DELETE,
    description: 'shared.permissions.channel.delete',
  },
  {
    key: PERMISSION_CHANNEL_RELATIONSHIP_READ,
    description: 'shared.permissions.channel.relationship.read',
  },
  {
    key: PERMISSION_CHANNEL_RELATIONSHIP_CREATE,
    description: 'shared.permissions.channel.relationship.create',
  },
  {
    key: PERMISSION_CHANNEL_RELATIONSHIP_UPDATE,
    description: 'shared.permissions.channel.relationship.update',
  },
  {
    key: PERMISSION_CHANNEL_RELATIONSHIP_DELETE,
    description: 'shared.permissions.channel.relationship.delete',
  },
  {
    key: PERMISSION_CONTENT_PERK_CREATE,
    description: 'shared.permissions.content.perk.create',
  },
  {
    key: PERMISSION_CONTENT_PERK_PUBLISH,
    description: 'shared.permissions.content.perk.publish',
  },
  {
    key: PERMISSION_CONTENT_PERK_READ,
    description: 'shared.permissions.content.perk.read',
  },
  {
    key: PERMISSION_CONTENT_PERK_UPDATE,
    description: 'shared.permissions.content.perk.update',
  },
  {
    key: PERMISSION_CONTENT_PERK_DELETE,
    description: 'shared.permissions.content.perk.delete',
  },
  {
    key: PERMISSION_CONTENT_PROMOTION_CREATE,
    description: 'shared.permissions.content.promotion.create',
  },
  {
    key: PERMISSION_CONTENT_PROMOTION_PUBLISH,
    description: 'shared.permissions.content.promotion.publish',
  },
  {
    key: PERMISSION_CONTENT_PROMOTION_READ,
    description: 'shared.permissions.content.promotion.read',
  },
  {
    key: PERMISSION_CONTENT_PROMOTION_UPDATE,
    description: 'shared.permissions.content.promotion.update',
  },
  {
    key: PERMISSION_CONTENT_PROMOTION_DELETE,
    description: 'shared.permissions.content.promotion.delete',
  },
  {
    key: PERMISSION_CONTENT_NOTICE_CREATE,
    description: 'shared.permissions.content.notice.create',
  },
  {
    key: PERMISSION_CONTENT_URGENT_MESSAGE_CREATE,
    description: 'shared.permissions.content.urgent-message.create',
  },
  {
    key: PERMISSION_CONTENT_NOTICE_PUBLISH,
    description: 'shared.permissions.content.notice.publish',
  },
  {
    key: PERMISSION_CONTENT_NOTICE_READ,
    description: 'shared.permissions.content.notice.read',
  },
  {
    key: PERMISSION_CONTENT_NOTICE_UPDATE,
    description: 'shared.permissions.content.notice.update',
  },
  {
    key: PERMISSION_CONTENT_NOTICE_DELETE,
    description: 'shared.permissions.content.notice.delete',
  },
  {
    key: PERMISSION_CONTENT_POST_CREATE,
    description: 'shared.permissions.content.post.create',
  },
  {
    key: PERMISSION_CONTENT_POST_PUBLISH,
    description: 'shared.permissions.content.post.publish',
  },
  {
    key: PERMISSION_CONTENT_POST_READ,
    description: 'shared.permissions.content.post.read',
  },
  {
    key: PERMISSION_CONTENT_POST_UPDATE,
    description: 'shared.permissions.content.post.update',
  },
  {
    key: PERMISSION_CONTENT_POST_DELETE,
    description: 'shared.permissions.content.post.delete',
  },
  {
    key: PERMISSION_CONTENT_CREATE,
    description: 'shared.permissions.content.create',
  },
  {
    key: PERMISSION_CONTENT_PUBLISH,
    description: 'shared.permissions.content.publish',
  },
  {
    key: PERMISSION_CONTENT_READ,
    description: 'shared.permissions.content.read',
  },
  {
    key: PERMISSION_CONTENT_UPDATE,
    description: 'shared.permissions.content.update',
  },
  {
    key: PERMISSION_CONTENT_DELETE,
    description: 'shared.permissions.content.delete',
  },
  {
    key: PERMISSION_CONTENT_INTERACTIONS_READ,
    description: 'shared.permissions.content.interactions.read',
  },
  {
    key: PERMISSION_CONTENT_INTERACTIONS_UPDATE,
    description: 'shared.permissions.content.interactions.update',
  },
  {
    key: PERMISSION_CONTENT_INTERACTIONS_DELETE,
    description: 'shared.permissions.content.interactions.delete',
  },
  {
    key: PERMISSION_CONTENT_FEATURE_RESERVABLE,
    description: 'shared.permissions.content.feature.reservable',
  },
  {
    key: PERMISSION_CONTENT_FEATURE_MENU,
    description: 'shared.permissions.content.feature.menu',
  },
  {
    key: PERMISSION_CONTENT_FEATURE_ESSENSYS,
    description: 'shared.permissions.content.feature.essensys',
  },
  {
    key: PERMISSION_CONTENT_PAGE_CREATE,
    description: 'shared.permissions.content.page.create',
  },
  {
    key: PERMISSION_CONTENT_PAGE_PUBLISH,
    description: 'shared.permissions.content.page.publish',
  },
  {
    key: PERMISSION_CONTENT_PAGE_READ,
    description: 'shared.permissions.content.page.read',
  },
  {
    key: PERMISSION_CONTENT_PAGE_UPDATE,
    description: 'shared.permissions.content.page.update',
  },
  {
    key: PERMISSION_CONTENT_PAGE_DELETE,
    description: 'shared.permissions.content.page.delete',
  },
  {
    key: PERMISSION_MERCHANT_CREATE,
    description: 'shared.permissions.merchant.create',
  },
  {
    key: PERMISSION_MERCHANT_READ,
    description: 'shared.permissions.merchant.read',
  },
  {
    key: PERMISSION_MERCHANT_UPDATE,
    description: 'shared.permissions.merchant.update',
  },
  {
    key: PERMISSION_MERCHANT_DELETE,
    description: 'shared.permissions.merchant.delete',
  },
  {
    key: PERMISSION_PAYMENT_ACCOUNT_CREATE,
    description: 'shared.permissions.payment.account.create',
  },
  {
    key: PERMISSION_PAYMENT_ACCOUNT_READ,
    description: 'shared.permissions.payment.account.read',
  },
  {
    key: PERMISSION_PAYMENT_ACCOUNT_UPDATE,
    description: 'shared.permissions.payment.account.update',
  },
  {
    key: PERMISSION_PAYMENT_ACCOUNT_DELETE,
    description: 'shared.permissions.payment.account.delete',
  },
  {
    key: PERMISSION_WORKPLACE_MEMBERS_INVITE,
    description: 'shared.permissions.workplace.members.invite',
  },
  {
    key: PERMISSION_USERS_WORKPLACE_MEMBERS_INVITE,
    description: 'shared.permissions.users.workplace.members.invite',
  },
  {
    key: PERMISSION_USERS_INFO,
    description: 'shared.permissions.users.info',
  },
  {
    key: PERMISSION_USERS_WORKPLACE_MEMBERS_READ,
    description: 'shared.permissions.users.workplace.members.read',
  },
  {
    key: PERMISSION_USERS_WORKPLACE_MEMBERS_SEARCH,
    description: 'shared.permissions.users.workplace.members.search',
  },
  {
    key: PERMISSION_USERS_WORKPLACE_MEMBERS_DELETE,
    description: 'shared.permissions.users.workplace.members.delete',
  },
  {
    key: PERMISSION_USERS_ROLE_INVITE,
    description: 'shared.permissions.users.role.invite',
  },
  {
    key: PERMISSION_USERS_ROLE_READ,
    description: 'shared.permissions.users.role.read',
  },
  {
    key: PERMISSION_USERS_ROLE_SEARCH,
    description: 'shared.permissions.users.role.search',
  },
  {
    key: PERMISSION_USERS_ROLE_DELETE,
    description: 'shared.permissions.users.role.delete',
  },
  {
    key: PERMISSION_USERS_INVITE,
    description: 'shared.permissions.users.invite',
  },
  {
    key: PERMISSION_USERS_READ,
    description: 'shared.permissions.users.read',
  },
  {
    key: PERMISSION_USERS_SEARCH,
    description: 'shared.permissions.users.search',
  },
  {
    key: PERMISSION_USERS_DELETE,
    description: 'shared.permissions.users.delete',
  },
  {
    key: PERMISSION_USERS_VERIFY_PRIMARY_EMAIL,
    description: 'shared.permissions.users.primary-email.verify',
  },
  {
    key: PERMISSION_USERS_CAN_ADD_OTHER_USERS_TO_TENANTS,
    description: 'shared.permissions.users.add.tenant.member',
  },
  {
    key: PERMISSION_USERS_CAN_REMOVE_OTHER_USERS_FROM_TENANTS,
    description: 'shared.permissions.users.remove.tenant.member',
  },
  {
    key: PERMISSION_USERS_PASSWORD_RESET,
    description: 'shared.permissions.users.password.reset',
  },
  {
    key: PERMISSION_MEDIA_READ,
    description: 'shared.permissions.media.read',
  },
  {
    key: PERMISSION_MEDIA_CREATE,
    description: 'shared.permissions.media.create',
  },
  {
    key: PERMISSION_MEDIA_UPDATE,
    description: 'shared.permissions.media.update',
  },
  {
    key: PERMISSION_MEDIA_DELETE,
    description: 'shared.permissions.media.delete',
  },
  {
    key: PERMISSION_DOCUMENTS_READ,
    description: 'shared.permissions.document.read',
  },
  {
    key: PERMISSION_DOCUMENTS_CREATE,
    description: 'shared.permissions.document.create',
  },
  {
    key: PERMISSION_DOCUMENTS_UPDATE,
    description: 'shared.permissions.document.update',
  },
  {
    key: PERMISSION_DOCUMENTS_DELETE,
    description: 'shared.permissions.document.delete',
  },
  {
    key: PERMISSION_TEMPLATES_READ,
    description: 'shared.permissions.templates.read',
  },
  {
    key: PERMISSION_TEMPLATES_CREATE,
    description: 'shared.permissions.templates.create',
  },
  {
    key: PERMISSION_TEMPLATES_UPDATE,
    description: 'shared.permissions.templates.update',
  },
  {
    key: PERMISSION_TEMPLATES_DELETE,
    description: 'shared.permissions.templates.delete',
  },
  {
    key: PERMISSION_LIBRARY_READ,
    description: 'shared.permissions.library.read',
  },
  {
    key: PERMISSION_LIBRARY_CREATE,
    description: 'shared.permissions.library.create',
  },
  {
    key: PERMISSION_LIBRARY_UPDATE,
    description: 'shared.permissions.library.update',
  },
  {
    key: PERMISSION_LIBRARY_DELETE,
    description: 'shared.permissions.library.delete',
  },
  {
    key: PERMISSION_GROUPS_READ,
    description: 'shared.permissions.groups.read',
  },
  {
    key: PERMISSION_GROUPS_CREATE,
    description: 'shared.permissions.groups.create',
  },
  {
    key: PERMISSION_GROUPS_UPDATE,
    description: 'shared.permissions.groups.update',
  },
  {
    key: PERMISSION_GROUPS_DELETE,
    description: 'shared.permissions.groups.delete',
  },
  {
    key: PERMISSION_GROUP_PRIMARY_LOCATION_UPDATE,
    description: 'shared.permissions.groups.primaryLocation.update',
  },
  {
    key: PERMISSION_EMAIL_CREATE,
    description: 'shared.permissions.email.create',
  },
  {
    key: PERMISSION_EMAIL_SEND,
    description: 'shared.permissions.email.send',
  },
  {
    key: PERMISSION_SECTIONS_CREATE,
    description: 'shared.permissions.sections.create',
  },
  {
    key: PERMISSION_SECTIONS_READ,
    description: 'shared.permissions.sections.read',
  },
  {
    key: PERMISSION_SECTIONS_UPDATE,
    description: 'shared.permissions.sections.update',
  },
  {
    key: PERMISSION_SECTIONS_DELETE,
    description: 'shared.permissions.sections.delete',
  },
  {
    key: PERMISSION_SECTIONS_FILTERS,
    description: 'shared.permissions.sections.filters',
  },
  {
    key: PERMISSION_PROPERTY_MANAGE_RETAIL,
    description: 'shared.permissions.property.manage.retail',
  },
  {
    key: PERMISSION_PROPERTY_MANAGE_COMPANY,
    description: 'shared.permissions.property.manage.company',
  },
  {
    key: PERMISSION_PROPERTY_GUESTS_CREATE,
    description: 'shared.permissions.property.guests.create',
  },
  {
    key: PERMISSION_PROPERTY_GUESTS_READ,
    description: 'shared.permissions.property.guests.read',
  },
  {
    key: PERMISSION_PROPERTY_GUESTS_UPDATE,
    description: 'shared.permissions.property.guests.update',
  },
  {
    key: PERMISSION_PROPERTY_GUESTS_DELETE,
    description: 'shared.permissions.property.guests.delete',
  },
  {
    key: PERMISSION_PROPERTY_MAINTENANCE_CREATE,
    description: 'shared.permissions.property.maintenance.create',
  },
  {
    key: PERMISSION_PROPERTY_MAINTENANCE_READ,
    description: 'shared.permissions.property.maintenance.read',
  },
  {
    key: PERMISSION_PROPERTY_MAINTENANCE_UPDATE,
    description: 'shared.permissions.property.maintenance.update',
  },
  {
    key: PERMISSION_PROPERTY_MAINTENANCE_DELETE,
    description: 'shared.permissions.property.maintenance.delete',
  },
  {
    key: PERMISSION_PROPERTY_HARDWARE_VIEW_UPDATE,
    description: 'shared.permissions.property.hardware.view.update',
  },
  {
    key: PERMISSION_PIN_MANAGEMENT_CREATE_EDIT_DELETE,
    description: 'shared.permissions.property.pin.create.edit.delete',
  },
  {
    key: PERMISSION_PIN_MANAGEMENT_VIEW,
    description: 'shared.permissions.property.pin.view',
  },
  {
    key: PERMISSION_INTEGRATION_CREATE,
    description: 'shared.permissions.integration.create',
  },
  {
    key: PERMISSION_INTEGRATION_READ,
    description: 'shared.permissions.integration.read',
  },
  {
    key: PERMISSION_INTEGRATION_UPDATE,
    description: 'shared.permissions.integration.update',
  },
  {
    key: PERMISSION_INTEGRATION_DELETE,
    description: 'shared.permissions.integration.delete',
  },
  {
    key: PERMISSION_METATAG_CREATE,
    description: 'shared.permissions.metatag.create',
  },
  {
    key: PERMISSION_METATAG_READ,
    description: 'shared.permissions.metatag.read',
  },
  {
    key: PERMISSION_METATAG_UPDATE,
    description: 'shared.permissions.metatag.update',
  },
  {
    key: PERMISSION_METATAG_DELETE,
    description: 'shared.permissions.metatag.delete',
  },
  {
    key: PERMISSION_TAB_CREATE,
    description: 'shared.permissions.tab.create',
  },
  {
    key: PERMISSION_TAB_READ,
    description: 'shared.permissions.tab.read',
  },
  {
    key: PERMISSION_TAB_UPDATE,
    description: 'shared.permissions.tab.update',
  },
  {
    key: PERMISSION_TAB_DELETE,
    description: 'shared.permissions.tab.delete',
  },
  {
    key: PERMISSION_WEBHOOK_CREATE,
    description: 'shared.permissions.webhook.create',
  },
  {
    key: PERMISSION_WEBHOOK_READ,
    description: 'shared.permissions.webhook.read',
  },
  {
    key: PERMISSION_WEBHOOK_UPDATE,
    description: 'shared.permissions.webhook.update',
  },
  {
    key: PERMISSION_WEBHOOK_DELETE,
    description: 'shared.permissions.webhook.delete',
  },
  {
    key: PERMISSION_AUDIENCE_CREATE,
    description: 'shared.permissions.audience.create',
  },
  {
    key: PERMISSION_AUDIENCE_READ,
    description: 'shared.permissions.audience.read',
  },
  {
    key: PERMISSION_AUDIENCE_DELETE,
    description: 'shared.permissions.audience.delete',
  },

  {
    key: PERMISSION_AUDIENCE_UPDATE_USE,
    description: 'shared.permissions.audienceUpdate.use',
  },
  {
    key: PERMISSION_AUDIENCE_UPDATE_MANAGE,
    description: 'shared.permissions.audienceUpdate.manage',
  },
  {
    key: PERMISSION_CHANNEL_MODULE_CREATE,
    description: 'shared.permissions.channel.module.create',
  },
  {
    key: PERMISSION_CHANNEL_MODULE_UPDATE,
    description: 'shared.permissions.channel.module.update',
  },
  {
    key: PERMISSION_CHANNEL_MODULE_DELETE,
    description: 'shared.permissions.channel.module.delete',
  },
  {
    key: PERMISSION_VISITOR_MANAGEMENT_LOGS_READ,
    description: 'shared.permissions.visitorManagement.logs.read',
  },
  {
    key: PERMISSION_VISITOR_MANAGEMENT_LOGS_READ_ALL,
    description: 'shared.permissions.visitorManagement.logs.readAll',
  },
  {
    key: PERMISSION_VISITOR_MANAGEMENT_HOST_SELECT_ALL,
    description: 'shared.permissions.visitorManagement.host.selectAll',
  },
  {
    key: PERMISSION_VISITOR_MANAGEMENT_LOGS_EDIT,
    description: 'shared.permissions.visitorManagement.logs.edit',
  },
  {
    key: PERMISSION_VISITOR_MANAGEMENT_LOGS_EDIT_ALL,
    description: 'shared.permissions.visitorManagement.logs.editAll',
  },
  {
    key: PERMISSION_VISITOR_MANAGEMENT_LOGS_PRINT_VISITOR_PASS,
    description: 'shared.permissions.visitorManagement.logs.printVisitorPass',
  },
  {
    key: PERMISSION_PROPERTY_AUTO_SETUP,
    description: 'shared.permissions.property.autoSetup',
  },
  {
    key: PERMISSION_PROPERTY_CSV_UPLOAD,
    description: 'shared.permissions.property.csvUpload',
  },
  {
    key: PERMISSION_ACCESS_CONTROL,
    description: 'shared.permissions.accessControl.view',
  },
  {
    key: PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ASSIGN_ACCESS_MANAGER,
    description:
      'shared.permissions.accessControl.mobileAccess.assignAccessManager',
  },
  {
    key: PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE,
    description: 'shared.permissions.accessControl.mobileAccess.enable',
  },
  {
    key: PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_DISABLE,
    description: 'shared.permissions.accessControl.mobileAccess.disable',
  },
  {
    key: PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
    description: 'shared.permissions.accessControl.mobileAccess.manageCompany',
  },
  {
    key: PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_ASSIGN,
    description: 'shared.permissions.accessControl.accessGroups.assign',
  },
  {
    key: PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_REVOKE,
    description: 'shared.permissions.accessControl.accessGroups.revoke',
  },
  {
    key: PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY,
    description: 'shared.permissions.accessControl.accessGroups.manageCompany',
  },
  {
    key: PERMISSION_ACCESS_CONTROL_HID,
    description: 'shared.permissions.accessControl.hid',
  },
  {
    key: PERMISSION_ACCESS_CONTROL_CCURE,
    description: 'shared.permissions.accessControl.ccure',
  },
  {
    key: PERMISSION_WORK_ORDERS_MODULE_ACCESS,
    description: 'shared.permissions.workOrders.module.access',
  },
  {
    key: PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM,
    description: 'shared.permissions.workOrders.serviceRequest.assignable.user',
  },
  {
    key: PERMISSION_WORK_ORDERS_SERVICE_REQUEST_VIEW,
    description: 'shared.permissions.workOrders.serviceRequest.view',
  },
  {
    key: PERMISSION_WORK_ORDERS_SERVICE_REQUEST_SETTINGS,
    description: 'shared.permissions.workOrders.serviceRequest.settings',
  },
  {
    key: PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_DATA,
    description: 'shared.permissions.workOrders.serviceRequest.edit.data',
  },
  {
    key: PERMISSION_WORK_ORDERS_SERVICE_REQUEST_LINK_EQUIPMENT,
    description: 'shared.permissions.workOrders.serviceRequest.linkEquipment',
  },
  {
    key: PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNTEAM,
    description: 'shared.permissions.workOrders.serviceRequest.edit.assignTeam',
  },
  {
    key: PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNEE,
    description: 'shared.permissions.workOrders.serviceRequest.edit.assignee',
  },
  {
    key: PERMISSION_WORK_ORDERS_SERVICE_REQUEST_CREATE,
    description: 'shared.permissions.workOrders.serviceRequest.create',
  },
  {
    key: PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS,
    description: 'shared.permissions.workOrders.serviceRequest.edit.status',
  },
  {
    key: PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ATTACH_FILE,
    description: 'shared.permissions.workOrders.serviceRequest.attachFile',
  },
  {
    key: PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW,
    description: 'shared.permissions.workOrders.equipment.view',
  },
  {
    key: PERMISSION_WORK_ORDERS_EQUIPMENT_SETTINGS,
    description: 'shared.permissions.workOrders.equipment.settings',
  },
  {
    key: PERMISSION_WORK_ORDERS_EQUIPMENT_ADD_EDIT,
    description: 'shared.permissions.workOrders.equipment.addEdit',
  },
  {
    key: PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW,
    description: 'shared.permissions.workOrders.PM.schedules.view',
  },
  {
    key: PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE,
    description: 'shared.permissions.workOrders.PM.schedules.create',
  },
  {
    key: PERMISSION_WORK_ORDERS_PM_TASK_VIEW,
    description: 'shared.permissions.workOrders.PM.tasks.view',
  },
  {
    key: PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNTEAM,
    description: 'shared.permissions.workOrders.PM.tasks.edit.assignTeam',
  },
  {
    key: PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNEE,
    description: 'shared.permissions.workOrders.PM.tasks.edit.assignee',
  },
  {
    key: PERMISSION_WORK_ORDERS_PM_TASK_EDIT_STATUS,
    description: 'shared.permissions.workOrders.PM.tasks.edit.status',
  },
  {
    key: PERMISSION_WORK_ORDERS_PM_TASK_ATTACH_FILE,
    description: 'shared.permissions.workOrders.PM.tasks.attachFile',
  },
  {
    key: PERMISSION_PRODUCTS_MANAGE,
    description: 'shared.permissions.billingPayments.products.manage',
  },
  {
    key: PERMISSION_PRODUCT_EXCEPTIONS_MANAGE,
    description: 'shared.permissions.billingPayments.exceptions.write',
  },
  {
    key: PERMISSION_CREDITS_MANAGE,
    description: 'shared.permissions.billingPayments.credits.manage',
  },
  {
    key: PERMISSION_COMPANY_INVOICES,
    description: 'shared.permissions.billingPayments.invoices.companyInvoices',
  },
  {
    key: PERMISSION_BILLING_PAYMENTS_CHARGE_CREATE,
    description: 'shared.permissions.billingPayments.charges.create',
  },
  {
    key: PERMISSION_BILLING_PAYMENTS_CHARGE_VOID,
    description: 'shared.permissions.billingPayments.charges.void',
  },
  {
    key: PERMISSION_BILLING_PAYMENTS_COMPANY_RECURRING_CHARGES,
    description:
      'shared.permissions.billingPayments.charges.companySubscriptions',
  },
  {
    key: PERMISSION_CHARGE_CODE_MANAGE,
    description: 'shared.permissions.billingPayments.chargeCodes.manage',
  },
  {
    key: PERMISSION_CAN_CANCEL_MEMBERSHIPS,
    description: 'shared.permissions.billingPayments.subscriptions.cancel',
  },
  {
    key: PERMISSION_CAN_REFUND_TRANSACTIONS,
    description: 'shared.permissions.billingPayments.transactions.refund',
  },
  {
    key: PERMISSION_UNIT_MANAGE,
    description: 'shared.permissions.unit.manage',
  },
  {
    key: PERMISSION_FLOOR_MANAGE,
    description: 'shared.permissions.floor.manage',
  },
  {
    key: PERMISSION_MESSAGING_AND_MARKETPLACE_MANAGE,
    description: 'shared.permissions.marketplace.manage',
  },
  {
    key: PERMISSION_RESERVATIONS_MODULE_ACCESS,
    description: 'shared.permissions.reservations.module.access',
  },
];

Object.values(ContentCategoryEnum).forEach(category => {
  const permission = `category.interaction.${category}`;

  permissions.push({
    key: permission,
    description: `The user can interact with content with a category of "${category}"`,
  });
});

enum UnsortedPermissionCategoriesEnum {
  WorkOrders = 'work-orders',
}

export const INTEGRATION_DISABLED_PERMISSIONS: {
  [key: string]: { permissions: string[]; text: string };
} = {
  'work orders': {
    permissions: [
      PERMISSION_WORK_ORDERS_SERVICE_REQUEST_CREATE,
      PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM,
      PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS,
      PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNTEAM,
      PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNEE,
      PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_DATA,
      PERMISSION_WORK_ORDERS_SERVICE_REQUEST_LINK_EQUIPMENT,
      PERMISSION_WORK_ORDERS_EQUIPMENT_ADD_EDIT,
      PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ATTACH_FILE,
      PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW,
      PERMISSION_WORK_ORDERS_EQUIPMENT_SETTINGS,
      PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW,
      PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE,
      PERMISSION_WORK_ORDERS_PM_TASK_VIEW,
      PERMISSION_WORK_ORDERS_PM_TASK_EDIT_STATUS,
      PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNTEAM,
      PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNEE,
      PERMISSION_WORK_ORDERS_PM_TASK_ATTACH_FILE,
      PERMISSION_WORK_ORDERS_PM_TASK_GENERATE_SERVICE_REQUEST,
    ],
    text:
      'web.admin.channel.teamManagement.team.permissions.disabled.workOrders',
  },
};

export const UNSORTED_PERMISSION_CATEGORIES = [
  UnsortedPermissionCategoriesEnum.WorkOrders,
];

// Contains updates to the permissions for a channel's group roles when an integration is added or updated
export const CHANNEL_INTEGRATION_PERMISSION_UPDATES: {
  [key: string]: [{ permissions: string[]; action: string }];
} = {
  [IntegrationProviderEnum.Yardi]: [
    {
      permissions: [
        ...INTEGRATION_DISABLED_PERMISSIONS['work orders'].permissions,
      ],
      action: 'remove',
    },
  ],
  [IntegrationProviderEnum.RealPage]: [
    {
      permissions: [
        ...INTEGRATION_DISABLED_PERMISSIONS['work orders'].permissions,
      ],
      action: 'remove',
    },
  ],
  [IntegrationProviderEnum.Entrata]: [
    {
      permissions: [
        ...INTEGRATION_DISABLED_PERMISSIONS['work orders'].permissions,
      ],
      action: 'remove',
    },
  ],
};
