import { ShopifyRsvpContentStateType } from '../types/integrations/ShopifyRsvp';

type Props = {
  burstData: ShopifyRsvpContentStateType & {
    attendeesCount: number;
    attendingStatus?: any;
  };
};

export function useLocationNameInReadOnlyView({ burstData }: Props) {
  function getLocationName(locationName: string) {
    if (!burstData?.diyQuestionsContent?.data || !burstData?.diyQuestions?.data)
      return '';

    let location = '';

    Object.entries(burstData?.diyQuestionsContent?.data).forEach(field => {
      if (field[1].friendlyName?.trim() === locationName) {
        location = burstData.diyQuestions.data[field[0]];
      }
    });

    return location;
  }

  const address = {
    _id: '',
    name: '',
    street1: '',
    code: '',
    geo: [],
    city: getLocationName('City'),
    country: getLocationName('Country'),
    state: '',
    distance: '',
  };

  return {
    address,
  };
}
