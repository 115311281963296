import React, { useState } from 'react';

import { Button, ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';

import { DataValidationRuleType } from 'lane-shared/types/ContentWorkflowType';
import { PropertiesInterface } from 'lane-shared/types/properties/Property';
import { PropertyDependency } from 'lane-shared/types/properties/propertyInterfaceOptions/propertyDependency';
import { PropertyDependencyRule } from 'lane-shared/types/properties/propertyInterfaceOptions/propertyDependencyRule';

import DataValidationRule from 'components/workflows/dataValidationSchema/DataValidationRule';
import style from 'components/workflows/dataValidationSchema/DataValidationSchema.scss';
import { MaybeDraftDataValidationRuleType } from 'components/workflows/dataValidationSchema/type';

export type Props = {
  fieldId: string;
  properties: PropertiesInterface;
  propertyDependency?: PropertyDependency;
  onPropertyDependencyUpdated: (dependency: PropertyDependency) => void;
};

export default function FieldDependencyEdit({
  fieldId,
  properties,
  propertyDependency,
  onPropertyDependencyUpdated,
}: Props) {
  const { t } = useTranslation();
  const [
    editingPropertyDependency,
    setEditingPropertyDependency,
  ] = useState<PropertyDependency>(
    propertyDependency || new PropertyDependency(fieldId, new Map())
  );
  const [error, setError] = useState<Error | null>(null);

  const [draftDataValidationRules, setDraftDataValidationRules] = useState<
    Array<MaybeDraftDataValidationRuleType>
  >([]);

  function addDraftDataValidationRule() {
    setDraftDataValidationRules([
      ...draftDataValidationRules,
      { _id: draftDataValidationRules.length.toString() },
    ]);
  }

  function validateRule(rule: DataValidationRuleType) {
    if (rule.fieldId === editingPropertyDependency.propertyRef) {
      throw new Error(
        t('web.admin.content.editor.fieldDependencyEdit.validationError')
      );
    }
  }

  function onRuleUpdated(updatedRule: DataValidationRuleType) {
    try {
      validateRule(updatedRule);
      const cloneEditingPropertyDependency = PropertyDependency.fromJsonData(
        properties,
        editingPropertyDependency.serialize()
      );

      cloneEditingPropertyDependency.addRule(
        new PropertyDependencyRule(
          updatedRule.fieldId,
          properties[updatedRule.fieldId],
          updatedRule.validator
        )
      );
      onPropertyDependencyUpdated(cloneEditingPropertyDependency);
      setDraftDataValidationRules(
        draftDataValidationRules.filter(rule => rule._id !== updatedRule._id)
      );
      setEditingPropertyDependency(cloneEditingPropertyDependency);
      setError(null);
    } catch (e) {
      setError(e);
    }
  }

  function onRuleDeleted(deletedRule: MaybeDraftDataValidationRuleType) {
    setDraftDataValidationRules(
      draftDataValidationRules.filter(rule => rule._id !== deletedRule._id)
    );
    const cloneEditingPropertyDependency = PropertyDependency.fromJsonData(
      properties,
      editingPropertyDependency.serialize()
    );

    cloneEditingPropertyDependency.removeRule(deletedRule.fieldId!);
    onPropertyDependencyUpdated(cloneEditingPropertyDependency);
    setEditingPropertyDependency(cloneEditingPropertyDependency);
    setError(null);
  }

  return (
    <div className={style.container} role="list">
      {error && <ErrorMessage error={error} />}
      {Array.from(editingPropertyDependency?.rules?.values() || []).map(
        (rule, idx) => (
          <div key={idx} className={style.ruleContainer} role="listitem">
            <DataValidationRule
              data-test="rule-container"
              data={properties}
              maybeDraftRule={{
                _id: idx.toString(),
                fieldId: rule.targetPropertyRef,
                validator: rule.targetPropertyCondition,
              }}
              onRuleUpdated={onRuleUpdated}
              onRuleDeleted={onRuleDeleted}
            />
          </div>
        )
      )}
      {draftDataValidationRules.map(rule => (
        <div key={rule._id} className={style.ruleContainer} role="listitem">
          <DataValidationRule
            data={properties}
            maybeDraftRule={rule}
            onRuleUpdated={onRuleUpdated}
            onRuleDeleted={onRuleDeleted}
            isDraft
          />
        </div>
      ))}
      <div className={style.actions}>
        <Button
          className={style.addAction}
          onClick={addDraftDataValidationRule}
        >
          {t('web.admin.content.editor.fieldDependencyEdit.buttonText')}
        </Button>
      </div>
    </div>
  );
}
