import React from 'react';

import { i18n } from 'i18next';
import { z } from 'zod';

import { Mjml, MjmlBody } from 'lane-shared/emails/MjmlComponentsV2';
import {
  EmailHorizontalRule,
  EmailSpacer,
} from 'lane-shared/emails/components';

import { EventStatus } from '../types';
import {
  EmailVisitorHeader,
  EmailVisitorFooter,
  EmailVisitorHero,
  EmailVisitorGreeting,
  EmailVisitorDetails,
  EmailVisitorHostDetails,
  EmailVisitorNotesSummary,
} from './components';
import { AlertType, EmailVisitorAlert } from './components/EmailVisitorAlert';
import {
  buildingSchema,
  changeDetailsSchema,
  hostSchema,
  visitorPassSchema,
  visitorSchema,
  whitelabelSchema,
} from './schema';

const ActivateVisitorHostUpdateParams = z.object({
  building: buildingSchema,
  host: hostSchema,
  visitorPass: visitorPassSchema,
  oldVisitorPassData: changeDetailsSchema.optional(),
  visitor: visitorSchema,
  organizer: hostSchema.optional(),
  whitelabel: whitelabelSchema.optional(),
});

export type EmailHostUpdateProps = {
  title: string;
  params: z.infer<typeof ActivateVisitorHostUpdateParams>;
  // because this component runs server side, we need the i18n passed in
  // from the current running context.
  i18n: i18n;
};

export function EmailHostUpdate({ title, params, i18n }: EmailHostUpdateProps) {
  try {
    params = ActivateVisitorHostUpdateParams.parse(params);
  } catch (error) {
    console.error(error);

    throw error;
  }

  const passIsCancelled = [
    EventStatus.EVENT_STATUS_INVALID,
    EventStatus.EVENT_STATUS_CANCELLED,
    EventStatus.EVENT_STATUS_WITHDRAWN,
  ].includes(params.visitorPass.status);

  let details: JSX.Element | undefined;

  switch (params.visitorPass.status) {
    case EventStatus.EVENT_STATUS_CHECKED_IN:
      details = (
        <>
          {i18n.t('shared.email.visitorManagement.hostUpdate.checkIn', {
            firstName: params.visitor.firstName,
          })}
        </>
      );
      break;

    case EventStatus.EVENT_STATUS_INVALID:
    case EventStatus.EVENT_STATUS_CANCELLED:
    case EventStatus.EVENT_STATUS_WITHDRAWN:
    default:
      details = undefined;
  }

  const targetName = params.organizer?.firstName || params.host.firstName;

  return (
    <Mjml>
      <EmailVisitorHeader
        title={title}
        primaryColor={params.building.primaryColor}
      />
      <MjmlBody background-color="#F5F6F7">
        <EmailSpacer />

        <EmailVisitorHero backgroundUrl={params.building.visitorHeroUri} />

        <EmailVisitorGreeting
          salutation={i18n.t('shared.email.visitorManagement.greeting', {
            firstName: targetName,
          })}
          details={details}
        />

        {passIsCancelled && (
          <EmailVisitorAlert type={AlertType.error}>
            <b style={{ color: '#0F121F' }}>
              {i18n.t('shared.email.visitorManagement.hostUpdate.cancelled')}
            </b>
          </EmailVisitorAlert>
        )}

        {params.oldVisitorPassData?.changeNote && (
          <EmailVisitorAlert type={AlertType.success}>
            <b style={{ color: '#0F121F' }}>
              {i18n.t(
                'shared.email.visitorManagement.visitorAlert.pass.updated.host.line.one'
              )}
            </b>
            <br />
            <b style={{ color: '#0F121F' }}>
              {i18n.t(
                'shared.email.visitorManagement.visitorAlert.pass.updated.line.two'
              )}
            </b>
            <span style={{ textTransform: 'capitalize' }}>
              {params.oldVisitorPassData?.changeNote}
            </span>
          </EmailVisitorAlert>
        )}

        <EmailHorizontalRule />

        <EmailVisitorDetails
          i18n={i18n}
          visitor={params.visitor}
          visitorPass={params.visitorPass}
          building={params.building}
          oldVisitorPassData={params.oldVisitorPassData}
        />

        <EmailHorizontalRule />

        <EmailVisitorHostDetails i18n={i18n} host={params.host} />

        <EmailHorizontalRule />

        <EmailVisitorNotesSummary
          i18n={i18n}
          visitorPass={params.visitorPass}
        />
        <EmailVisitorFooter
          i18n={i18n}
          primaryColor={params.building.primaryColor}
          building={params.building}
          whitelabel={params.whitelabel}
        />
        <EmailSpacer />
      </MjmlBody>
    </Mjml>
  );
}
