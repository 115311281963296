import type { ContentNotificationType } from '../../../types/content/DraftContent';

export const contentNotificationCollectables = (
  notification?: Partial<ContentNotificationType>
) => {
  if (!notification) return {};

  return {
    title: notification.title,
    title_l10n: notification.title_l10n,
    willSendAt: notification.sendAt,
    isSent: notification.sent,
  };
};
