import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { FontAwesomeType, IconSet } from 'lane-shared/helpers/constants/icons';

import styles from './ButtonStrip.scss';

type ValueType = string | number | boolean | null;
const types = ['string', 'number', 'boolean'];

function isValueType<T extends ValueType>(value: unknown): value is T {
  return types.includes(typeof value);
}

function mapButtonValue<T extends ValueType>(button: Button<T> | T) {
  if (isValueType(button)) {
    return {
      value: button,
      name: String(button),
    };
  }

  return button;
}

export type Button<T extends ValueType> = {
  value: T;
  icon?: string;
  iconSet?: IconSet;
  iconType?: FontAwesomeType;
  name?: string;
};

type Props<T extends ValueType> = {
  buttons: Button<T>[] | T[];
  selected: T | null | undefined;
  onClick: (value: T) => void;
  className?: string;
  style?: React.CSSProperties;
  multiple?: boolean;
  handleMultipleOptions?: Function;
  multipleOptions?: T[] | undefined;
  doTranslate?: boolean;
};

export default function ButtonStrip<T extends ValueType>({
  buttons,
  selected,
  onClick,
  className,
  style,
  multiple,
  handleMultipleOptions = () => {},
  doTranslate = true,
  multipleOptions,
}: Props<T>) {
  const { t } = useTranslation();

  function addRemoveOptions(value: T) {
    const newOptions = multipleOptions?.includes(value)
      ? multipleOptions?.filter(str => str !== value)
      : [...(multipleOptions ?? []), value];

    handleMultipleOptions(newOptions);
  }

  return (
    <menu className={cx(styles.ButtonStrip, className)} style={style}>
      {buttons.map<Button<T>>(mapButtonValue).map(button => (
        <button
          key={String(button.value)}
          data-test={`button${button.value}`}
          data-is-selected={
            multiple
              ? multipleOptions?.includes(button.value)
              : selected === button.value
          }
          onClick={() =>
            multiple ? addRemoveOptions(button.value) : onClick(button.value)
          }
        >
          {button.icon && (
            <Icon
              type={button.iconType}
              name={button.icon}
              set={button.iconSet}
            />
          )}
          {button.name && (
            <span>{doTranslate ? t(button.name) : button.name}</span>
          )}
        </button>
      ))}
    </menu>
  );
}
