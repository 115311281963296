export async function convertImageToBase64(url: string): Promise<any> {
  const response = await fetch(url);
  const blob = await response.blob();

  return new Promise((onSuccess, onError) => {
    try {
      const reader = new FileReader();

      reader.onload = function () {
        onSuccess((<string>this.result)?.split(',')?.pop() ?? '');
      };

      reader.readAsDataURL(blob);
    } catch (e) {
      onError(e);
    }
  });
}
