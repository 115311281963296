function typeOfSpaceLabel(selectedLabels: string[]) {
  const length = selectedLabels.length;

  if (length) {
    if (length <= 2) {
      return selectedLabels.join(', ');
    }

    return `Type of space (${length})`;
  }

  return 'Type of space';
}

export default typeOfSpaceLabel;
