import * as yup from 'yup';

import {
  ScheduleTypeEnum,
  IntervalTypeEnum,
  MonthIntervalTypeEnum,
} from 'lane-shared/domains/visitorManagement/types/VisitorManagementFeatureProperties';

import Types from '../../../properties/Types';
import createShapeFromProperties from '../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../types/properties/Property';

export interface ScheduleType {
  type: ScheduleTypeEnum;
  startDate: Date;
  endDate: Date;
  isAllDay: boolean;
  startTime?: Date;
  endTime?: Date;
  dates?: Date[];
  intervalType?: IntervalTypeEnum;
  endRepeatDate?: Date;
  intervalCount?: number;
  weekdayRepeats?: number[];
  monthIntervalType?: MonthIntervalTypeEnum;
}

const properties: PropertiesInterface = {
  type: {
    type: 'String',
    editable: true,
    default: ScheduleTypeEnum.SpecificDate,
    validators: [],
  },
  startDate: {
    type: 'String',
    editable: true,
    default: new Date().toISOString,
    validators: [],
  },
  endDate: {
    type: 'String',
    editable: true,
    default: new Date().toISOString,
    validators: [],
  },
  isAllDay: {
    type: 'Boolean',
    editable: true,
    default: true,
    validators: [],
  },
  dates: {
    type: 'Date',
    editable: true,
    isArray: true,
    default: [new Date().toISOString],
    validators: [],
  },
  intervalType: {
    type: 'String',
    editable: true,
    default: IntervalTypeEnum.EveryDay,
    validators: [],
  },
  endRepeatDate: {
    type: 'String',
    editable: true,
    default: new Date().toISOString,
    validators: [],
  },
  intervalCount: {
    type: 'Number',
    editable: true,
    default: 1,
    validators: [],
  },
  weekdayRepeats: {
    type: 'Number',
    isArray: true,
    editable: true,
    default: [],
    validators: [],
  },
  monthIntervalType: {
    type: 'String',
    editable: true,
    default: MonthIntervalTypeEnum.OnDate,
    validators: [],
  },
};

export class ScheduleObjectType extends ObjectTypeBase {
  name = 'ScheduleType';

  friendlyName = 'Schedule options for visitor passes.';

  properties = properties;

  contexts = [];

  get example(): ScheduleType {
    return {
      type: ScheduleTypeEnum.SpecificDate,
      startDate: new Date(2023, 0, 15, 9),
      endDate: new Date(2023, 0, 15, 17),
      isAllDay: true,
      intervalType: IntervalTypeEnum.EveryDay,
      endRepeatDate: new Date(),
    };
  }

  get default(): any {
    return {
      type: ScheduleTypeEnum.SpecificDate,
      startDate: new Date(),
      endDate: new Date(),
      isAllDay: true,
      intervalType: IntervalTypeEnum.EveryDay,
      endRepeatDate: new Date(),
    };
  }

  buildSchema() {
    const shape = createShapeFromProperties(properties);

    return yup.object().shape(shape).nullable();
  }
}

const definition = new ScheduleObjectType();

Types.addType(definition);
export default definition;
