import React, { useState, useEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getDisplayName } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { imageUrl } from 'lane-shared/helpers/formatters';
import useGuestInvite, {
  GuestInviteFeatureTabsEnum,
} from 'lane-shared/hooks/useGuestInvite';
import useThemeColors from 'lane-shared/hooks/useThemeColors';
import { GuestInviteGuestType } from 'lane-shared/renderers/v5/features/types/GuestInviteGuestType';

import GuestInviteGuestListItem from 'components/features/GuestInvite/GuestInviteGuestListItem';
import GuestInviteMemberSearch from 'components/features/GuestInvite/GuestInviteMemberSearch';
import ValidatedInput from 'components/form/ValidatedInput';
import Alert from 'components/general/Alert';
import Button from 'components/general/Button';
import Label from 'components/general/Label';
import TabStrip from 'components/general/TabStrip';
import CircleListView from 'components/lane/CircleListView';
import { Modal } from 'components/lds';
import { H5, XS } from 'components/typography';

import styles from './GuestInviteFeature.scss';

/**
 * Allows an end user interacting with content to create a list of guests
 * to invite into this interaction.
 *
 */
export default function GuestInviteFeature({
  className,
  style,
  onChange,
  value,
  showLaneUsers = true,
  minGuests = 0,
  maxGuests = 64,
}: {
  className?: string;
  style?: React.CSSProperties;
  onChange: (value: GuestInviteGuestType[]) => void;
  value: GuestInviteGuestType[] | null;
  showLaneUsers: boolean;
  minGuests?: number;
  maxGuests?: number;
}) {
  const { t } = useTranslation();
  const theme = useThemeColors();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState({
    value: GuestInviteFeatureTabsEnum.Email,
  });
  const {
    guests,
    selectedIds,
    invitedGuestsFromUsers,
    users,
    search,
    updateSearch,
    email,
    setEmail,
    emailValidator,
    name,
    setName,
    nameValidator,
    company,
    setCompany,
    companyValidator,
    hasChanged,
    handleUserSelect,
    addManualGuest,
    removeGuest,
  } = useGuestInvite({
    value,
    onChange,
    maxGuests,
    platform: 'desktop',
  });
  const emptyEmailForm =
    name.length === 0 &&
    email.length === 0 &&
    company.length === 0 &&
    selectedTab.value === GuestInviteFeatureTabsEnum.Email;

  const tabs = [
    {
      label: t('Email'),
      value: GuestInviteFeatureTabsEnum.Email,
    },
  ];

  if (showLaneUsers) {
    tabs.splice(1, 0, {
      label: t('Workplace'),
      value: GuestInviteFeatureTabsEnum.Member,
    });
  }

  if (invitedGuestsFromUsers.length > 0) {
    tabs.push({
      label: t('Selected {{selected}}', { selected: guests.length }),
      value: GuestInviteFeatureTabsEnum.Selected,
    });
  }

  useEffect(() => {
    if (
      selectedTab.value === GuestInviteFeatureTabsEnum.Selected &&
      invitedGuestsFromUsers.length === 0
    ) {
      setSelectedTab({ value: GuestInviteFeatureTabsEnum.Email });
    }
  }, [selectedTab.value, invitedGuestsFromUsers.length]);

  return (
    <div className={cx(styles.GuestInviteFeature, className)} style={style}>
      <Label h1>
        {t('Invite Guests')}
        {guests.length < maxGuests && (
          <Icon
            name="plus-circle"
            className={styles.addIcon}
            style={{ color: theme.primary }}
            set={ICON_SET_FONTAWESOME}
            onClick={() => setIsAddOpen(true)}
          />
        )}
      </Label>

      <ul>
        {minGuests > 0 && guests.length < minGuests && (
          <Alert color="secondary">
            {t('Invite at least {{minGuests}} guest(s).', { minGuests })}
          </Alert>
        )}
        {guests.length >= maxGuests && (
          <Alert color="secondary">
            {t('No more guests can be added.', { minGuests })}
          </Alert>
        )}
        {guests.map(guest => (
          <GuestInviteGuestListItem
            key={guest._id}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; clsasName: any; guest: GuestI... Remove this comment to see the full error message
            clsasName={styles.guestListItem}
            guest={guest}
            onRemoveGuest={removeGuest}
          />
        ))}
      </ul>
      <Modal
        className={styles.window}
        title={t('Invite Guests')}
        isOpen={isAddOpen}
        onClose={() => setIsAddOpen(false)}
        actions={
          <Button
            onClick={async () => {
              if (emptyEmailForm) {
                return;
              }

              if (selectedTab.value === GuestInviteFeatureTabsEnum.Email) {
                const isGuestAdded = await addManualGuest();

                if (isGuestAdded) {
                  setIsAddOpen(false);
                }
              } else {
                setIsAddOpen(false);
              }
            }}
            variant="contained"
            disabled={emptyEmailForm}
          >
            {t('Add Guest')}
          </Button>
        }
      >
        {tabs.length > 1 && (
          <TabStrip
            tabs={tabs}
            selected={selectedTab}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Dispatch<SetStateAction<{ value: GuestInvite... Remove this comment to see the full error message
            onSelectTab={setSelectedTab}
          />
        )}

        {selectedTab.value === GuestInviteFeatureTabsEnum.Member && (
          <GuestInviteMemberSearch
            onUserSelected={handleUserSelect}
            selectedGuests={guests}
            users={users}
            search={search}
            setSearch={updateSearch}
          />
        )}

        {selectedTab.value === GuestInviteFeatureTabsEnum.Email && (
          <div>
            <ValidatedInput
              className={styles.input}
              value={email}
              label="web.content.feature.guestInvite.guestEmail.label"
              placeholder={t('Email')}
              onChange={email => {
                email = email.trim();
                setEmail(email);
              }}
              validation={emailValidator}
              isPristine={!hasChanged}
            />
            <ValidatedInput
              className={styles.input}
              value={name}
              label="web.content.feature.guestInvite.guestName.label"
              placeholder={t('Name')}
              onChange={name => setName(name)}
              validation={nameValidator}
              isPristine={!hasChanged}
            />
            <ValidatedInput
              className={styles.input}
              value={company}
              label={`${t('Enter guest company')}`}
              placeholder={t('Company')}
              onChange={company => setCompany(company)}
              validation={companyValidator}
              isPristine={!hasChanged}
            />
            <Button
              variant="outlined"
              startIcon={<Icon name="plus" />}
              onClick={addManualGuest}
              size="small"
            >
              {t('Add another')}
            </Button>
          </div>
        )}
        {selectedTab.value === GuestInviteFeatureTabsEnum.Selected &&
          invitedGuestsFromUsers.map(user => (
            <CircleListView
              key={user?._id}
              className={styles.listView}
              image={imageUrl(user?.profile?.image)}
              logo={imageUrl(user?.profile?.logo)}
              name={getDisplayName(user)}
              onClick={() => handleUserSelect(user)}
              selected={selectedIds?.some(id => id === user?._id)}
              isSelectable
            >
              <H5 bold>{getDisplayName(user)}</H5>
              <XS variant="secondary">{user?.profile?.email}</XS>
            </CircleListView>
          ))}
      </Modal>
    </div>
  );
}
