import { DateTime } from 'luxon';

/**
 * Opposite of @function toLocalDate.
 * Converts the given date to the date of the timezone.
 * It will always return the start of the date at the given timezone.
 * @param date
 * @param timeZone
 * @returns
 */
export function fromLocalDate(date?: Date, timeZone?: string) {
  if (!date || !timeZone) return date;

  const localDate = DateTime.fromJSDate(date);
  const tzDate = DateTime.fromObject({
    zone: timeZone,
    year: localDate.year,
    month: localDate.month,
    day: localDate.day,
  })
    .startOf('day')
    .toJSDate();

  return tzDate;
}
