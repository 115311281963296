import type { Column, RowAction } from './useTable';
import { CellRenderer } from '../CellRenderer';
import { IndeterminateCheckbox } from '../IndeterminateCheckbox';
import { RowActions } from '../RowActions';
import styles from '../Table.scss';
import { ColumnDef, CellContext, FilterFn } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import {
  NativeFilterTypes,
  FilterType,
  CustomFilterType,
  DateFilterType,
} from '../../Filters/Filters';

const getFilterFn = <TDataShape,>(
  filterModel?: CustomFilterType<TDataShape>
): { filterFn: FilterFn<TDataShape> } | {} => {
  if (filterModel?.customFilterFn) {
    return {
      filterFn: filterModel.customFilterFn,
    };
  }

  const dateRangeFilterFn: FilterFn<TDataShape> = (
    row,
    columnId,
    filterValues
  ) => {
    if (!filterValues) {
      return true;
    }
    const rowDateString: string = row.getValue(columnId);
    if (!rowDateString) {
      return false;
    }
    const { startDate, endDate } = filterValues;
    const rowDate = new Date(rowDateString);

    return rowDate >= startDate && rowDate <= endDate;
  };

  const dateFilterFn: FilterFn<TDataShape> = (row, columnId, filterValue) => {
    if (!filterValue) {
      return true;
    }

    const rowDateString: string = row.getValue(columnId);

    if (!rowDateString) {
      return false;
    }

    const timeZone = (filterModel as DateFilterType).timeZone;

    if (timeZone) {
      // Compare & filter dates in provided zone
      const rowDate = DateTime.fromISO(rowDateString, { zone: timeZone });
      const filterDate = DateTime.fromJSDate(filterValue, { zone: timeZone });

      return (
        rowDate.year === filterDate.year &&
        rowDate.month === filterDate.month &&
        rowDate.day === filterDate.day
      );
    }

    const rowDate = new Date(rowDateString);

    return (
      rowDate.getFullYear() === filterValue.getFullYear() &&
      rowDate.getMonth() === filterValue.getMonth() &&
      rowDate.getDate() === filterValue.getDate()
    );
  };

  const multiselectFilterFn: FilterFn<TDataShape> = (
    row,
    columnId,
    filterValueAny
  ) => {
    const filterValue = filterValueAny as string[];
    if (!filterValue || filterValue.length < 1) {
      return true;
    }

    const cellValue: string = row.getValue(columnId);
    if (!cellValue) {
      return false;
    }

    return filterValue.includes(cellValue);
  };

  switch (filterModel?.type) {
    case NativeFilterTypes.DateRange:
      return { filterFn: dateRangeFilterFn };
    case NativeFilterTypes.Date:
      return { filterFn: dateFilterFn };
    case NativeFilterTypes.Multiselect:
      return { filterFn: multiselectFilterFn };
    case NativeFilterTypes.ArrIncludesSome:
      return { filterFn: 'arrIncludesSome' };
    default:
      return {};
  }
};

type ColumnOptionType<TDataShape> = {
  disableSorting?: boolean;
  filters?: Array<FilterType | CustomFilterType<TDataShape>>;
  isSelectable?: boolean;
  rowActions?: RowAction<TDataShape>[];
  rowActionsLabel?: string;
};

export const useTableColumns = <TDataShape,>(
  columns: Column<TDataShape>[],
  {
    disableSorting,
    filters,
    isSelectable,
    rowActions,
    rowActionsLabel,
  }: ColumnOptionType<TDataShape>
): ColumnDef<TDataShape, any>[] => {
  const { t } = useTranslation();

  const memoizedColumns: ColumnDef<TDataShape, any>[] = useMemo(() => {
    const coreColumnDefinitions = columns.map(column => ({
      enableSorting: !disableSorting && !column.disableSorting,
      accessorKey: column.key,
      id: column.key, // by default, the id is the key, and replace . with _, such as payer.id => payer_id
      cell: (cell: CellContext<TDataShape, any>) => (
        <CellRenderer cell={cell} column={column} />
      ),
      header: column.header ? () => column.header : undefined,
      ...getFilterFn<TDataShape>(
        filters?.find(
          filter => filter.key === column.key
        ) as CustomFilterType<TDataShape>
      ),
    }));
    const selectionColumn: ColumnDef<TDataShape, any> = {
      id: 'select',
      header: ({ table }) => (
        <div className={styles.CheckboxCell}>
          <IndeterminateCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomePageRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            label={t('Select all')}
            hideLabel
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className={styles.CheckboxCell}>
          <IndeterminateCheckbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            label={t('Select row: {{row}}', { row: row.id })}
            hideLabel
          />
        </div>
      ),
    };

    const columnDefinitions: ColumnDef<TDataShape, any>[] = [];

    if (isSelectable) {
      columnDefinitions.push(selectionColumn);
    }

    columnDefinitions.push(...coreColumnDefinitions);

    if (rowActions && rowActions.length > 0) {
      const label = rowActionsLabel ?? t('web.table.actions');
      const actionsColumn: ColumnDef<TDataShape, any> = {
        id: 'actions',
        header: label,
        cell: ({ row }) => (
          <RowActions<TDataShape>
            row={row.original}
            label={label}
            actions={rowActions}
          />
        ),
      };

      columnDefinitions.push(actionsColumn);
    }

    return columnDefinitions;
  }, [rowActions, columns, disableSorting, filters, isSelectable]);
  return memoizedColumns;
};
