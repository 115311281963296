import { FilterType, NativeFilterTypes } from 'design-system-web';
import { StatusListItem } from '../types/EventStatus';
import { TFunction } from 'i18next';

interface Props {
  dateFilter: NativeFilterTypes.DateRange | NativeFilterTypes.Date;
  statusListItems: StatusListItem[];
  hostName: string[];
  registeredFrom: string[];
  minDate: Date;
  maxDate: Date;
  timeZone?: string;
  t: TFunction<'translation', undefined>;
}

function VisitorLogTableFilters({
  dateFilter,
  statusListItems,
  hostName,
  registeredFrom,
  minDate,
  maxDate,
  timeZone,
  t,
}: Props): FilterType[] {
  return [
    {
      key: 'startDatetime',
      type: dateFilter,
      label:
        dateFilter === NativeFilterTypes.DateRange
          ? t('Date Range')
          : t('Date'),
      isPromoted: true,
      isFixed: true,
      maxDate,
      minDate,
      excludeFromClear: true,
      timeZone,
    },
    {
      key: 'status',
      type: NativeFilterTypes.Multiselect,
      label: t('web.admin.channel.visitor.log.columns.status'),
      options: statusListItems.map(status => ({
        label: status.label,
        value: status.value,
      })),
      isPromoted: true,
      isFixed: true,
    },
    {
      key: 'tenantName',
      type: NativeFilterTypes.Select,
      label: t('web.admin.channel.visitor.log.columns.hostCompany'),
      options: hostName.map(item => ({
        label: item,
        value: item,
      })),
    },
    {
      key: 'eventName',
      type: NativeFilterTypes.Select,
      label: t('web.admin.channel.visitor.log.columns.registeredFrom'),
      options: registeredFrom.map(item => ({
        label: item,
        value: item,
      })),
    },
  ];
}

export default VisitorLogTableFilters;
