import createOperateAccountAndContact from 'lane-shared/graphql/essensys/createOperateAccountAndContact';

import { getClient } from '../apollo';
import { AccountContactPair } from '../types/Essensys';

type DataType = {
  email: string;
  companyName: string;
  contentId?: string;
};

export default async function createOperateAccountAndContactMutation(
  data: DataType
): Promise<AccountContactPair> {
  const variables = {
    email: data.email,
    companyName: data.companyName,
    contentId: data.contentId,
  };

  const response = await getClient().mutate<{
    essensys: { createOperateAccountAndContact: AccountContactPair };
  }>({
    mutation: createOperateAccountAndContact,
    variables,
  });
  const accountContactPair =
    response.data?.essensys.createOperateAccountAndContact;

  if (!accountContactPair) {
    throw new Error(
      'Your Operate account could not be created. Please contact support'
    );
  }

  return accountContactPair;
}
