import type { PropertiesInterface } from '../../../types/properties/Property';

type DataFieldCollectableType = {
  [key: string]: { name?: string; type: string };
};

export const contentDataFieldCollectables = (
  dataFields?: Partial<PropertiesInterface> | null
) => {
  if (!dataFields || Object.keys(dataFields).length === 0) return;

  const dataFieldsCollectables: DataFieldCollectableType = {};

  for (const dataFieldName of Object.keys(dataFields)) {
    const dataField = dataFields[dataFieldName];

    if (dataField) {
      dataFieldsCollectables[dataFieldName] = {
        name: dataField.name,
        type: dataField.type,
      };
    }
  }

  return dataFieldsCollectables;
};
