import { useQuery } from '@apollo/client';

import getMerchantAccountQuery from 'lane-shared/graphql/query/getMerchantAccountQuery';

export type useMerchantAccountStatusParams = {
  channelId: string;
};

export type MerchantAccountStatus = {
  configured: boolean;
};

/*
 * A hook for encapsulating logic for getting merchant account status
 *
 * * */
export default function useMerchantAccountStatus(
  params: useMerchantAccountStatusParams
): MerchantAccountStatus {
  const { data } = useQuery(getMerchantAccountQuery, {
    fetchPolicy: 'no-cache',
    skip: !params?.channelId,
    variables: {
      id: params.channelId,
    },
  });
  const merchantAccount = data?.channel?.merchantAccount;
  const isStripeConfigured = !!merchantAccount?.stripeAccount
    ?.details_submitted;

  if (isStripeConfigured) {
    return {
      configured: true,
    };
  }

  return {
    configured: false,
  };
}
