import React from 'react';
import { useTranslation } from 'react-i18next';
import { P } from 'components/typography';

type props = {
  suiteName: string;
  className?: string;
};

export function SingleSuiteText({
  suiteName,
  className
}: props) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <P className='mb-4'>
          {t('web.workOrder.serviceRequest.form.suite.label')}
      </P>
      <P style={{ fontSize: 'medium', display: 'block'}}>
          {suiteName}
      </P>
    </div>
  );
}
