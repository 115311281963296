// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { components } from 'react-select';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'components/form';
import { QuantityFeatureProperties } from 'lane-shared/types/features/QuantityFeatureProperties';
import { FeatureRendererPropsType } from './FeatureRendererPropsType';
import { Checkbox, Icon, Tooltip } from 'design-system-web';
import { LaneType } from 'common-types/src/lib/__generated__/staticTypes';
import { useChannelCache } from 'hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { TranslationKeys } from 'localization';
import { ChannelMultiselect } from 'components/lane/ChannelMultiselect';
import ChannelSelector, {
  ChannelSelectorModesEnum,
} from 'components/lane/ChannelSelector';
import { ResizableWindow } from 'components/general';

type QuantityAdvancedRuleProps = {
  quantityAllocation: LaneType.QuantityAllocations;
} & Pick<
  FeatureRendererPropsType<QuantityFeatureProperties>,
  'onFeatureUpdated' | 'contentFeature'
>;

const TRANSLATION_KEYS: { [key: string]: TranslationKeys } = Object.freeze({
  selectChannel:
    'web.admin.content.features.quantity.advancedRules.selectChannel',
  allotedUnits:
    'web.admin.content.features.quantity.advancedRules.allotedUnits',
  primaryLocationCheck:
    'web.admin.content.features.quantity.advancedRules.primaryLocationCheck',
  primaryLocationCheckTooltip:
    'web.admin.content.features.quantity.advancedRules.primaryLocationCheck.tooltip',
  claimAllAvailableQuantityCheck:
    'web.admin.content.features.quantity.advancedRules.claimAllQuantityCheck',
  claimAllAvailableQuantityCheckDescription:
    'web.admin.content.features.quantity.advancedRules.claimAllQuantityCheck.description',
});

export function QuantityAdvancedRule({
  contentFeature,
  onFeatureUpdated,
  quantityAllocation,
}: QuantityAdvancedRuleProps) {
  const { t } = useTranslation();
  const {
    addChannel,
    removeChannel,
    removeAllChannels,
    channels,
    isLoading,
    error,
  } = useChannelCache(quantityAllocation.channelIds);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const selectedChannels = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return quantityAllocation.channelIds.map(channelId =>
      channels.get(channelId)
    );
  }, [quantityAllocation.channelIds, channels, isLoading]);

  useEffect(() => {
    if (!isAllSelectedChannelsTypeProperty) {
      onFeatureUpdated({
        quantityAllocations: [
          { ...quantityAllocation, forPrimaryChannelUsersOnly: false },
        ],
      });
    }
  }, [selectedChannels]);

  const handleAllocationUnitsChange = (inputUnits: number | string) => {
    let allocationUnits = Number(inputUnits);

    if (allocationUnits < 0) {
      allocationUnits = 0;
    } else if (allocationUnits > contentFeature.feature.total) {
      allocationUnits = contentFeature.feature.total;
    } else if (allocationUnits > 0) {
      allocationUnits = Math.floor(allocationUnits);
    }

    onFeatureUpdated({
      quantityAllocations: [{ ...quantityAllocation, units: allocationUnits }],
    });
  };

  const handleChannelSelected = (selectedChannel: ChannelType) => {
    if (selectedChannel && selectedChannel._id) {
      onFeatureUpdated({
        quantityAllocations: [
          {
            ...quantityAllocation,
            channelIds: [
              ...new Set([
                ...quantityAllocation.channelIds,
                selectedChannel._id,
              ]),
            ],
          },
        ],
      });
      addChannel(selectedChannel);
    }
  };

  const handleChannelRemoved = (channelId: string) => {
    removeChannel(channelId);
    onFeatureUpdated({
      quantityAllocations: [
        {
          ...quantityAllocation,
          channelIds: quantityAllocation.channelIds.filter(
            id => id !== channelId
          ),
        },
      ],
    });
  };

  const handleOnClear = () => {
    removeAllChannels();
    onFeatureUpdated({
      quantityAllocations: [
        {
          ...quantityAllocation,
          channelIds: [],
        },
      ],
    });
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomMenu = (props: any) => {
    return (
      <components.Menu {...props}>
        <ResizableWindow
          showHeader
          name="storageKey"
          defaultPosition={ResizableWindow.centerPosition()}
          onClose={() => setIsMenuOpen(false)}
          className="bg-background-primary flex flex-col"
          style={{
            padding: '2em 1em 1em 1em',
            boxShadow: '0 0 8px 0 rgba(15, 18, 31, 0.24)',
            borderRadius: '0.25em',
            border: '1px solid rgba(0, 0, 0, 0.05)',
          }}
        >
          <ChannelSelector
            onChannelSelected={(channel: any) => {
              props.setValue(channel);
            }}
            modes={[ChannelSelectorModesEnum.Subscriptions]}
          />
        </ResizableWindow>
      </components.Menu>
    );
  };

  const isAllSelectedChannelsTypeProperty = selectedChannels
    .map(channel => channel?.type)
    .every(type => type === 'Property');

  const constructChannelToItem = (channel: ChannelType) => {
    return {
      label: channel.name,
      value: channel,
    };
  };

  const isError = !!error;

  return (
    <>
      <div className="flex flex-row w-full gap-3">
        <div className="basis-3/4">
          <ChannelMultiselect
            onChange={item => handleChannelSelected(item as any)}
            value={selectedChannels.map(constructChannelToItem as any)}
            Menu={CustomMenu}
            items={
              selectedChannels &&
              selectedChannels.map(constructChannelToItem as any)
            }
            onChannelRemoved={handleChannelRemoved}
            onClear={handleOnClear}
            onMenuClose={() => setIsMenuOpen(false)}
            onMenuOpen={() => setIsMenuOpen(true)}
            isMenuOpen={isMenuOpen}
            label={t(TRANSLATION_KEYS.selectChannel)}
            isError={isError}
          />
        </div>
        <div className="basis-1/4">
          <Input
            value={quantityAllocation.units.toString()}
            onChange={handleAllocationUnitsChange}
            type="number"
            label={TRANSLATION_KEYS.allotedUnits}
          />
        </div>
      </div>
      <div className="flex flex-col mt-2">
        <div className="flex flex-row">
          <Checkbox
            text={t(TRANSLATION_KEYS.primaryLocationCheck)}
            selected={!!quantityAllocation.forPrimaryChannelUsersOnly}
            disabled={!isAllSelectedChannelsTypeProperty}
            value={quantityAllocation.forPrimaryChannelUsersOnly}
            onChange={forPrimaryChannelUsersOnly =>
              onFeatureUpdated({
                quantityAllocations: [
                  {
                    ...quantityAllocation,
                    forPrimaryChannelUsersOnly: !forPrimaryChannelUsersOnly,
                  },
                ],
              })
            }
          />
          <Tooltip
            TooltipComponent={t(TRANSLATION_KEYS.primaryLocationCheckTooltip)}
          >
            <Icon name="info-circle" type="far" className="mt-1.5 -ml-0.5" />
          </Tooltip>
        </div>
        <Checkbox
          text={t(TRANSLATION_KEYS.claimAllAvailableQuantityCheck)}
          selected={!!quantityAllocation.canClaimBeyondAllocatedUnits}
          description={t(
            TRANSLATION_KEYS.claimAllAvailableQuantityCheckDescription
          )}
          value={quantityAllocation.canClaimBeyondAllocatedUnits}
          onChange={canClaimBeyondAllocatedUnits =>
            onFeatureUpdated({
              quantityAllocations: [
                {
                  ...quantityAllocation,
                  canClaimBeyondAllocatedUnits: !canClaimBeyondAllocatedUnits,
                },
              ],
            })
          }
        />
      </div>
    </>
  );
}
