import i18next from 'i18next';

import { formatDate } from './formatDate';

export function formatDateRange(
  startDate: Date | null | undefined,
  endDate: Date | null | undefined,
  translator: (key: string, params?: any) => string = i18next.t,
  displayDay: boolean = false,
  timeZone?: string
) {
  let formatted = '';

  if (startDate && endDate) {
    const isSameYear = startDate.getFullYear() === endDate.getFullYear();

    const formattedStartDate = formatDate(
      startDate,
      translator,
      !isSameYear,
      displayDay,
      timeZone
    );

    const formattedEndDateText = formatDate(
      endDate,
      translator,
      true,
      displayDay,
      timeZone
    );

    formatted = `${formattedStartDate} - ${formattedEndDateText}`;
  }

  return formatted;
}
