import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { H5, S } from 'design-system-web';

import * as cardIcons from '../helpers/acceptedCardIcons';

import styles from './CreditCardV2.scss';
import { CreditCardBrands } from 'lane-shared/domains/billingPayments/types';

interface CreditCardV2Props {
  expMonth: string | number;
  expYear: string | number;
  last4: string;
  brand: CreditCardBrands;
  className?: string;
}

export function CreditCardV2({
  expMonth,
  expYear,
  last4,
  brand,
  className,
}: CreditCardV2Props) {
  const { t } = useTranslation();

  function getCardIcon(brand: CreditCardBrands) {
    return cardIcons[brand];
  }

  return (
    <div className={cx(styles.creditCardWrapper, className)}>
      <img src={getCardIcon(brand)} alt={`${brand}-card-icon`} />
      <div className={styles.cardDetails}>
        <H5 className={styles.cardNumber}>**** {last4}</H5>
        <S className="text-text-subdued">
          {t('abp.payment.exp')} {expMonth}/{expYear}
        </S>
      </div>
    </div>
  );
}
