import { AccessIntegrationMeQueryData, ChannelProviderType } from '../types';
import { mapIntegration } from './mapIntegration';

export const getMyIntegrations = (
  isMobileAccessGlobalKeycardsEnabled: boolean,
  useTenantsAccessIntegrations: boolean,
  data?: AccessIntegrationMeQueryData
) => {
  if (isMobileAccessGlobalKeycardsEnabled) {
    const integrations = Array.from(data?.me?.channels ?? []).flatMap(
      (channel): ChannelProviderType[] =>
        channel.integrations?.map(mapIntegration) ?? []
    );

    return integrations;
  }

  // get a filtered list of all AccessControl channelIntegrations that
  // are active on the channels the user is focused on

  const integrations = Array.from(
    data?.me?.switchChannel?.focusOnChannels ?? []
  ).flatMap(
    (channel): ChannelProviderType[] =>
      channel.integrations?.map(mapIntegration) ?? []
  );

  const userChannelIds = Array.from(
    data?.me?.channels?.map(item => item._id) ?? []
  );

  if (useTenantsAccessIntegrations) {
    Array.from(data?.channelsByRelationship?.items ?? [])
      // Filtering out tenant channels that the user is not part of
      .filter(item => userChannelIds.includes(item.channel._id))
      // Sort from oldest to newest channel
      .sort((a, b) => a.channel._created.localeCompare(b.channel._created))
      .flatMap(
        (item): ChannelProviderType[] =>
          item.channel.integrations?.map(mapIntegration) ?? []
      )
      .forEach(tenantItem => {
        if (
          !integrations.find(
            channelItem =>
              channelItem.integration.name === tenantItem.integration.name
          )
        ) {
          // Add tenant integration to channel integrations if not already present
          integrations.push(tenantItem);
        }
      });
  }

  return integrations;
};
