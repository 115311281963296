import gql from 'graphql-tag';

export type PendingInvitesCountResponse = {
  reformedChannel: {
    id: string;
    channelInvitesCount?: number | null;
  };
};

export const getPendingInvitesCount = gql`
  query getChannelPendingInvitesCount($channelId: UUID!, $groupRoleId: UUID) {
    reformedChannel(channelId: $channelId) {
      id
      channelInvitesCount(groupRoleId: $groupRoleId)
    }
  }
`;
