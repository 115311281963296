import { useMutation, useQuery } from '@apollo/client';
import { Dropdown, Flex, Input } from 'components';
import { Button } from 'design-system-web';
import { Chip, ChipStyle } from 'components/ads';
import { H4 } from 'components/typography';
import { getClient } from 'lane-shared/apollo';
import { Relay } from 'lane-shared/domains/hardwareManagement/types/Relay';
import { updateRelayOutputMutation } from 'lane-shared/graphql/hardware/relay/updateRelay';
import { getAccessPointList } from 'lane-shared/graphql/hardware/accessPoint';
import { safeConvertToUUID } from 'lane-shared/helpers';
import { SHORT_TIME, SIMPLE_DATE } from 'lane-shared/helpers/constants/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { ChannelType } from 'lane-shared/types/ChannelType';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CreateAccessPointModal } from '../../../../../components/CreateAccessPointModal';
import { getStatusTranslation } from '../../../../../helpers';
import { RELAY_MODULE_STATUS_CONNECTED } from '../relay';
import styles from './styles.scss';

type UpdateRelayProps = {
  setShowUpdateComponent: React.Dispatch<React.SetStateAction<boolean>>;
  channel: ChannelType;
  relayData: Relay;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
};

const MAX_NAME_LENGTH = 100;
const MAX_NOTES_LENGTH = 200;
const MAX_DELAY = 300;
const MAX_PULSE = 300;

const DELAY_DEFAULT_VALUE = 0;
const PULSE_DEFAULT_VALUE = 10;

export function UpdateRelayDetail({
  setShowUpdateComponent,
  channel,
  relayData,
  setHasError,
}: UpdateRelayProps) {
  const { relayId } = useParams<{ relayId: string }>();
  const { data, loading, refetch } = useQuery(getAccessPointList, {
    client: getClient(),
    variables: {
      buildingId: safeConvertToUUID(channel?._id),
    },
  });

  const accessPointDropdownItems = data?.getAccessPointList?.map(
    (accessPoint: any) => ({
      label: accessPoint?.name,
      value: accessPoint?.id,
    })
  );

  const [updateRelayOutput] = useMutation(updateRelayOutputMutation, {
    client: getClient(),
  });

  const [name, setName] = useState(relayData.name);
  const [accessPointId, setAccessPointId] = useState(
    relayData?.accessPoint?.id
  );
  const [notes, setNotes] = useState(relayData.description);
  const [delay, setDelay] = useState(relayData.delay || DELAY_DEFAULT_VALUE);
  const [pulse, setPulse] = useState(relayData.pulse || PULSE_DEFAULT_VALUE);

  const [saveIsDisabled, setSaveIsDisabled] = useState<boolean>(false);

  const { t } = useTranslation();

  const validateForm = (field: string, value: string = '') => {
    switch (field) {
      case 'name': {
        if (value.trim() === '') {
          return [
            t(
              'web.admin.hardware.management.relay.validation.nameRequiredError'
            ),
          ];
        }

        if (value.length > MAX_NAME_LENGTH) {
          return [
            t('web.admin.hardware.management.relay.validation.nameLimitError'),
          ];
        }

        break;
      }

      case 'access_point': {
        if (value.trim() === '') {
          return [
            t(
              'web.admin.hardware.management.relay.validation.accessPointRequiredError'
            ),
          ];
        }

        break;
      }

      case 'notes': {
        if (value.length > MAX_NOTES_LENGTH) {
          return [
            t('web.admin.hardware.management.relay.validation.notesLimitError'),
          ];
        }

        break;
      }

      case 'delay': {
        if (value.trim() === '') {
          return [
            t(
              'web.admin.hardware.management.relay.validation.delayRequiredError'
            ),
          ];
        }

        if (Number(value) > MAX_DELAY) {
          return [
            t('web.admin.hardware.management.relay.validation.delayLimitError'),
          ];
        }

        break;
      }

      case 'pulse': {
        if (value.trim() === '') {
          return [
            t(
              'web.admin.hardware.management.relay.validation.pulseRequiredError'
            ),
          ];
        }

        if (Number(value) > MAX_PULSE) {
          return [
            t('web.admin.hardware.management.relay.validation.pulseLimitError'),
          ];
        }

        break;
      }

      default: {
        return null;
      }
    }

    return null;
  };

  const isFormValid = useMemo(() => {
    if (validateForm('name', name)) {
      return false;
    }

    if (validateForm('pulse', pulse.toString())) {
      return false;
    }

    if (validateForm('access_point', accessPointId)) {
      return false;
    }

    if (validateForm('delay', delay.toString())) {
      return false;
    }

    return true;
  }, [name, pulse, delay, accessPointId]);

  const handleCancel = () => {
    setHasError(false);
    setShowUpdateComponent(false);
  };

  const [isAccessPointModalOpen, setIsAccessPointModalOpen] = useState(false);
  const [accessPointName, setAccessPointName] = useState('');
  const handleAccessPointModal = (name?: string) => {
    if (isAccessPointModalOpen) {
      setIsAccessPointModalOpen(false);
      setAccessPointName('');
    } else {
      setIsAccessPointModalOpen(true);
      setAccessPointName(name || '');
    }
  };

  const handleRelayUpdate = async () => {
    try {
      setSaveIsDisabled(true);
      const response = await updateRelayOutput({
        variables: {
          updateRelayOutputId: relayId,
          input: {
            accessPointId,
            name,
            description: notes,
            pulse,
            delay,
          },
        },
      });

      if (response?.data?.updateRelayOutput?.id) {
        setHasError(false); // Hide Error if we get success response
        window.Toast.show(
          t('web.admin.hardware.management.relay.updateSuccessMsg')
        );
        setShowUpdateComponent(false);
      }
    } catch (e) {
      setHasError(true);
    } finally {
      setSaveIsDisabled(false);
    }
  };

  return (
    <Flex direction="column">
      <Flex>
        <div className={styles.item}>
          <div className={styles.itemValue}>
            <Input
              testId="input-relay-name"
              fieldName="name"
              value={name}
              label={t('web.admin.hardware.management.relay.name')}
              fixedLabel
              isRequired
              error={validateForm('name', name || '')}
              onChange={value => setName(value)}
            />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemValue}>
            <Dropdown
              items={accessPointDropdownItems}
              testId="input-relay-access-point"
              value={accessPointId}
              disabled={loading}
              isRequired
              onChange={selectedItem => setAccessPointId(selectedItem.value)}
              label={t('web.admin.hardware.management.relay.accessPoint')}
              fixedLabel
              invalid={!!validateForm('access_point', accessPointId || '')}
              errors={validateForm('access_point', accessPointId || '')}
              onCreateOption={(name: string) => {
                handleAccessPointModal(name);
              }}
            />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemValue}>
            <Input
              fieldName="notes"
              testId="input-relay-notes"
              value={notes}
              label={t('web.admin.hardware.management.relay.notes')}
              fixedLabel
              maxLength={MAX_NOTES_LENGTH}
              showLengthIndicator
              error={validateForm('notes', notes || '')}
              onChange={value => setNotes(value)}
            />
          </div>
        </div>
      </Flex>
      <Flex>
        <div className={styles.item}>
          <div className={styles.itemValue}>
            <Input
              fieldName="delay"
              testId="input-relay-delay"
              value={delay}
              label={t('web.admin.hardware.management.relay.delay')}
              fixedLabel
              isRequired
              error={validateForm('delay', delay?.toString() || '')}
              onChange={value => setDelay(Number(value))}
            />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemValue}>
            <Input
              fieldName="pulse"
              testId="input-relay-pulse"
              value={pulse}
              label={t('web.admin.hardware.management.relay.pulseDuration')}
              fixedLabel
              isRequired
              max={MAX_PULSE}
              error={validateForm('pulse', pulse?.toString() || '')}
              onChange={value => setPulse(Number(value))}
            />
          </div>
        </div>
      </Flex>
      <Flex>
        <div className={styles.item}>
          <div className={styles.itemName}>
            {t('web.admin.hardware.management.relay.type')}
          </div>
          <div className={styles.itemValue}>{relayData?.type ?? '-'}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemName}>
            {t('web.admin.hardware.management.relay.status')}:
          </div>
          <div>
            <Chip
              value={getStatusTranslation(relayData?.status, t)}
              type={
                relayData?.status === RELAY_MODULE_STATUS_CONNECTED
                  ? ChipStyle.Green
                  : ChipStyle.Red
              }
              doTranslate={false}
              withStatusIcon
            />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemName}>
            {t('web.admin.hardware.management.relay.lastSeen')}
          </div>
          <div className={styles.itemValue}>
            {`${dateFormatter(
              relayData?.relayModule?.lastSeen,
              SHORT_TIME
            )}, ${dateFormatter(
              relayData?.relayModule?.lastSeen,
              SIMPLE_DATE
            )}`}
          </div>
        </div>
      </Flex>
      <Flex gap={5} className={styles.item}>
        <Button
          variant="secondary"
          testId="btn-relay-cancel"
          onClick={handleCancel}
          className={styles.button}
        >
          {t('web.admin.hardware.management.relay.cancel')}
        </Button>
        <Button
          variant="primary"
          disabled={!isFormValid || saveIsDisabled}
          testId="btn-relay-save"
          onClick={handleRelayUpdate}
          className={styles.popUpButton}
        >
          {t('web.admin.hardware.management.relay.save')}
        </Button>
      </Flex>
      <Flex>
        <H4 data-test="relay-device-title" className={styles.H3}>
          {t('web.admin.hardware.management.relay.device')}
        </H4>
      </Flex>
      <Flex>
        <div className={styles.item}>
          <div data-test="relay-deviceId" className={styles.itemName}>
            {t('web.admin.hardware.management.relay.deviceId')}
          </div>
          <div className={styles.itemValue}>
            {relayData?.relayModule?.id ?? '-'}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemName}>
            {t('web.admin.hardware.management.relay.moduleName')}
          </div>
          <div className={styles.itemValue}>
            {relayData?.relayModule?.name ?? '-'}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemName}>
            {t('web.admin.hardware.management.relay.device.macAddress')}
          </div>
          <div className={styles.itemValue}>
            {relayData?.relayModule?.macAddress ?? '-'}
          </div>
        </div>
      </Flex>
      {isAccessPointModalOpen && (
        <CreateAccessPointModal
          modalVisible={isAccessPointModalOpen}
          onClose={() => handleAccessPointModal()}
          onSave={() => {
            refetch();
          }}
          name={accessPointName}
          channel={channel}
        />
      )}
    </Flex>
  );
}
