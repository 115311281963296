import { LaneType } from 'common-types';
import { UserGroupRoleType } from '../types/UserGroupRole';
import {
  PropertySecurityRule,
  SecurityRuleTypeEnum,
} from '../types/properties/PropertySecurity';
import compareUUIDs from './compareUUIDs';
import { SPECIAL_GROUP_ROLES } from './constants/channel';

type CheckSecurityRulesAgainstObjectProps = {
  rules: PropertySecurityRule[];
  ownerId?: LaneType.UUID;
  creatorId?: LaneType.UUID;
  sourceId?: LaneType.UUID;
  user: {
    _id: LaneType.UUID;
    roles: UserGroupRoleType[];
  };
  permissions: string[];
};

/**
 * Takes in an object with a security key, a related piece of content and the
 * current user trying to perform an action related to that content, and
 * returns true if the user has the correct permissions. Otherwise,
 * it returns false
 */
export default function checkSecurityRulesAgainstObject({
  rules,
  ownerId,
  creatorId,
  sourceId,
  user,
  permissions,
}: CheckSecurityRulesAgainstObjectProps): boolean {
  if (!rules || rules.length === 0) {
    return true;
  }

  for (const securityRule of rules) {
    const securityRuleId =
      (securityRule.value as any)?._id || securityRule.value;

    switch (securityRule.type) {
      case SecurityRuleTypeEnum.All:
        // anyone can do this.
        return true;

      case SecurityRuleTypeEnum.Owner:
        // the content owner (i.e. the user who created
        // the content) can do this action
        if (compareUUIDs(ownerId, user?._id)) {
          return true;
        }

        break;

      case SecurityRuleTypeEnum.Creator:
        // the content creator or last person to update can do this action
        if (compareUUIDs(creatorId, user?._id)) {
          return true;
        }

        break;

      case SecurityRuleTypeEnum.SpecificUser:
        if (compareUUIDs(securityRuleId, user?._id)) {
          return true;
        }

        break;

      case SecurityRuleTypeEnum.Source: {
        // anyone with permissions on the source channel that created
        // the content can do this action

        if (
          sourceId &&
          user.roles?.some(
            ({ groupRole }) =>
              compareUUIDs(groupRole.channel?._id, sourceId) &&
              groupRole.name !== SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER &&
              (!permissions ||
                permissions.length === 0 ||
                groupRole.permissions.some(permission =>
                  permissions.includes(permission)
                ))
          )
        ) {
          return true;
        }

        break;
      }

      case SecurityRuleTypeEnum.Channel:
        // anyone on this specific named channel can do this action
        if (
          user.roles?.some(
            ({ groupRole }) =>
              compareUUIDs(groupRole.channel?._id, securityRuleId) &&
              groupRole.name !== SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER &&
              (!permissions ||
                permissions.length === 0 ||
                groupRole.permissions.some(permission =>
                  permissions.includes(permission)
                ))
          )
        ) {
          return true;
        }

        break;

      case SecurityRuleTypeEnum.ChannelPermission:
        // anyone with specific permission on this channel
        if (
          user.roles?.some(
            ({ groupRole }) =>
              compareUUIDs(groupRole.channel?._id, sourceId) &&
              (groupRole.name === SPECIAL_GROUP_ROLES.ADMIN ||
                groupRole.permissions.some(
                  permission => securityRule.value === permission
                ))
          )
        ) {
          return true;
        }

        break;

      case SecurityRuleTypeEnum.GroupRole:
        // anyone with this specific group role can do this action
        if (
          user.roles?.some(role =>
            compareUUIDs(role.groupRole?._id, securityRuleId)
          )
        ) {
          return true;
        }

        break;

      case SecurityRuleTypeEnum.System:
        // only the system can do this, if you are the system don't
        // use this method.
        break;

      default:
        // no access is defined for this user.
        break;
    }
  }

  return false;
}
