export default function getContentMetatagsWithUpdatedSimpleValue({
  contentMetatag,
  value,
  content,
}: any) {
  return (content.contentMetatags || []).map(function (
    previousContentMetatag: any
  ) {
    if (contentMetatag.metatag._id === previousContentMetatag.metatag._id) {
      return {
        ...contentMetatag,
        value,
      };
    }

    return previousContentMetatag;
  });
}
