import { redirectToTeamsPage } from './redirectToTeamsPage';
import {
  DeleteGroupFailed,
  sendDeleteGroupRoleMutation,
} from './sendDeleteGroupRoleMutation';

type DeleteTeamResult = { key: 'success' } | DeleteGroupFailed;

export default async function deleteGroupFlow(
  groupRoleId: string,
  channelSlug: string,
  history: any
): Promise<DeleteTeamResult> {
  const deleteGroupResult = await sendDeleteGroupRoleMutation(groupRoleId);

  if (deleteGroupResult.key === 'deleteGroupFailed') {
    return {
      key: 'deleteGroupFailed',
      err: deleteGroupResult.err,
    };
  }

  redirectToTeamsPage(history, channelSlug);

  return { key: 'success' };
}
